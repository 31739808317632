import { postRequest } from '@/helpers/request';

export default {
  namespaced: true,
  state: () => ({
    configurations: [],
    loading: 0,
    yieldSpread: 10,
    dollarSpread: 0.5,
  }),
  getters: {
    isLoading(state) {
      return state.loading > 0;
    },
    yieldSpreadValue(state) {
      const yieldSpread = Number(state.yieldSpread);
      return Number.isNaN(yieldSpread) ? 0 : yieldSpread;
    },
    dollarSpreadValue(state) {
      const dollarSpread = Number(state.dollarSpread);
      return Number.isNaN(dollarSpread) ? 0 : dollarSpread;
    },
  },
  mutations: {
    setConfigurations(state, configurations) {
      state.configurations = configurations;
    },
    setLoading(state, loading) {
      state.loading = loading ? state.loading + 1 : state.loading - 1;
    },
    setYieldSpread(state, value) {
      state.yieldSpread = value;
    },
    setDollarSpread(state, value) {
      state.dollarSpread = value;
    },
  },
  actions: {
    async fetchConfigurations({ commit }) {
      try {
        commit('setLoading', true);
        const response = await postRequest('/budgeting/scenario_planner/configurations/');
        commit('setConfigurations', response.configurations);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setLoading', false);
      }
    },
    async upsertConfiguration({ commit, state }, configuration) {
      try {
        const response = await postRequest('/budgeting/scenario_planner/configurations/upsert/', configuration);
        const existingConfigs = [...state.configurations];
        const cfg = response;
        const existingConfig = existingConfigs.findIndex((c) => c.id === cfg.id);
        if (existingConfig >= 0) {
          existingConfigs[existingConfig] = cfg;
          commit('setConfigurations', existingConfigs);
        } else {
          existingConfigs.push(cfg);
          commit('setConfigurations', existingConfigs);
        }
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async deleteConfiguration({ commit, state }, configuration) {
      try {
        await postRequest('/budgeting/scenario_planner/configurations/delete/', configuration);
        commit('setConfigurations', state.configurations.filter((c) => c.id !== configuration.id));
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    resetSpread({ commit }) {
      commit('setYieldSpread', 10);
      commit('setDollarSpread', 0.5);
    },
  },
};
