export const PRECISION_TOLERANCE = 0.000001;

export function approxEqual(num1, num2) {
  return Math.abs(num2 - num1) < PRECISION_TOLERANCE;
}

export function approxLessOrEqual(num1, num2) {
  return num1 < num2 || approxEqual(num1, num2);
}

export function approxGreaterOrEqual(num1, num2) {
  return num1 > num2 || approxEqual(num1, num2);
}
