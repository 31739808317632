/**
 * Represents possible states for Smart Advisor
 * the value can either be 'current' or 'next',
 * referring to the active crop year.
 */
const SmartAdvisorContexts = {
  current: 'current',
  next: 'next',
};
export default SmartAdvisorContexts;
