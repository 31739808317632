<template>
  <v-form @submit.prevent="handleSaveClick">
    <v-card>
      <v-card-title>
        Provider Application
      </v-card-title>

      <v-card-text>
        <v-textarea
          v-model="providerApplication.description"
          :value="providerApplication.description"
          label="Description"
          outlined
          required
          maxlength="500"
          autofocus
        />

        <v-checkbox
          v-model="providerApplication.noticeAccepted"
          class="mt-0"
          :value="providerApplication.noticeAccepted"
          hide-details
          label="I accept the terms and conditions."
          :rules="[val => val]"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="handleCancelClick"
        >
          Cancel
        </v-btn>

        <v-btn
          type="submit"
          text
          color="primary"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  props: {
    providerApplication: {
      type: Object,
      required: true,
    },
  },

  computed: {
    valid() {
      return this.providerApplication.noticeAccepted;
    },
  },

  methods: {
    handleSaveClick() {
      if (this.valid) {
        this.$emit('save', this.providerApplication);
      }
    },

    handleCancelClick() {
      this.$emit('cancel', this.providerApplication);
    },
  },
};
</script>
