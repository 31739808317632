const GMAModule = () => import('@/pages/gma_module/GMAModule.vue');

const routes = [
  {
    path: '/gma_module/',
    meta: {
      title: 'Farm Advisor Portal',
    },
    component: GMAModule,
  },
];

export default routes;
