<template>
  <component
    :is="drawerComponent"
    v-bind="$props"
    :is-open="isOpen"
    :minimize="isMinimized"
    :peek-width="peekWidth"
    :drawer-classes="drawerClasses"
    @toggle-minimize="handleToggleMinimize"
    @close="handleClickClose"
  >
    <template
      v-for="(_, slot) in $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </component>
</template>

<script>
import SlideDrawerRight from './SlideDrawerRight.vue';
import SlideDrawerBottom from './SlideDrawerBottom.vue';

export default {
  name: 'SlideDrawer',

  components: {
    SlideDrawerRight,
    SlideDrawerBottom,
  },

  props: {
    value: {
      default: undefined,
      validator(value) {
        return typeof value === 'boolean' || typeof value === 'undefined';
      },
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    info: {
      type: String,
      default: '',
    },

    permanent: {
      type: Boolean,
      default: false,
    },

    minimize: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
      default: 520,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    nudgeTop: {
      type: Number,
      default: 0,
    },

    nudgeBottom: {
      type: Number,
      default: 0,
    },

    showToggle: {
      type: Boolean,
      default: true,
    },

    fitContent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      internalValue: true,
      isMinimized: this.minimize,
      peekWidth: 64,
    };
  },

  computed: {
    isOpen: {
      get() {
        return this.value ?? this.internalValue;
      },
      set(value) {
        this.$emit('input', value);
        this.internalValue = value;
      },
    },

    overlapAmount() {
      if (this.isOpen && !this.isMinimized) {
        return this.width;
      } if (this.isOpen && this.isMinimized) {
        return this.peekWidth;
      }
      return 0;
    },

    drawerComponent() {
      return this.bottom ? 'slide-drawer-bottom' : 'slide-drawer-right';
    },

    drawerClasses() {
      return {
        'slide-drawer--minimize': this.isMinimized,
        'slide-drawer--close': !this.isOpen,
      };
    },
  },

  watch: {
    overlapAmount(newValue) {
      this.$emit('change:overlap', newValue);
    },
  },

  mounted() {
    this.$emit('interface', {
      expand: () => { this.isMinimized = false; },
      collapse: () => { this.isMinimized = true; },
    });
  },

  methods: {
    handleToggleMinimize() {
      this.isMinimized = !this.isMinimized;
    },

    handleClickClose() {
      this.$emit('close');
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
@use "sass:map";
@use "@/colors/colors";
@use "vuetify/src/styles/settings/variables";

.slide-drawer {
  background-color: colors.$white;
  transition-duration: 0.2s;
  transition-timing-function: map.get(variables.$transition, 'fast-out-slow-in');
  transition-property: transform;
}
</style>
