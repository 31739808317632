<template>
  <g-dialog
    :value="showAdBlock"
    :title="title"
    disable-fullscreen
    :width="580"
    @cancel="markAdBlockSeen"
  >
    <div class="d-flex pt-6 pb-4">
      <v-img
        contain
        src="/static/dashboard/adblock.png"
        class="text-center"
        width="75"
        max-height="95"
        alt="adblock-icon"
      />
    </div>

    <v-card-text class="primary-grey--text text-subtitle-2 font-weight-regular pb-10">
      {{ subtitle }}
    </v-card-text>
    <v-divider class="" />
    <div class="text-center">
      <v-btn
        class="mt-4 text-body-2 white--text font-weight-bold"
        color="primary"
        @click="markAdBlockSeen"
      >
        GOT IT
      </v-btn>
    </div>
  </g-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GDialog from '../generic/GDialog.vue';

export default {
  name: 'AdblockDialog',
  components: {
    GDialog,
  },
  data() {
    return {
      title: 'Disable your adblock',
      subtitle: 'GrainFox works best when your browser\'s adblocker is turned off. Try disabling it in your browser to ensure the smoothest experience possible.',
    };
  },
  computed: {
    ...mapState('app', ['showAdBlock']),
  },
  methods: {
    ...mapActions('app', ['markAdBlockSeen']),
  },
};
</script>
