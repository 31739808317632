export default {
  state: () => ({
    id: null,
    hash: '',
    email: '',
    picture: '',
    firstName: '',
    lastName: '',
    fullName: '',
    isLoggedIn: false,
    isEarlyAccess: false,
    shownSecurityPrompt: true,
    shownPriceFinderPrompt: false,
    isInternalUser: false,
    isSecurityQuestionsSet: true, // whether the user's security questions are set.
    shouldShowNewFeature: false,
    shouldShowSecurityPrompt: false,
    shouldShowPriceFinderPrompt: false,
    shouldShowEULAPrompt: false,
    shouldShowTermsOfUsePrompt: false,
    isFocusGroup: false,
    isUsingOTP: false,
    isFarmlinkGMA: false,
    isGMA: false,
    isSubscriptionGMA: false,
    isChangingPassword: false, // if the user is currently on the password change page
    partnership: null,
    cobranding: null,
  }),

  getters: {
    isAuthenticated: (state) => state.id !== null,

    shouldShowPasswordPrompt(state) {
      // show the password prompt if the user is using a one-time password
      // and the user is not on the password change page
      return state.isUsingOTP && !state.isChangingPassword;
    },

    /**
     * Get whether the current user is a telus user.
     * @param {*} state Vuex store state
     * @returns Whether the user signed up via a Telus campaign
     */
    isTelus(state) {
      if (!state.partnership || state.partnership.type !== 'Partnership') {
        return false;
      }
      return state.partnership.partnership_type === 'telus';
    },

    /**
     * Get whether the current user is a fcc user.
     * @param {*} state Vuex store state
     * @returns Whether the user signed up via a FCC campaign
     */
    isFCC(state) {
      if (!state.partnership || state.partnership.type !== 'Partnership') {
        return false;
      }
      return state.partnership.partnership_type === 'fcc';
    },

    /**
     * Get whether the current user is a Syngenta user.
     * @param {*} state Vuex store state
     * @returns Whether the user signed up via a Syngenta campaign
     */
    isSyngenta(state) {
      if (!state.partnership || state.partnership.type !== 'Partnership') {
        return false;
      }
      return state.partnership.partnership_type === 'syngenta';
    },

    showTrialBanner(state) {
      return state.partnership.show_trial_banner;
    },
  },

  mutations: {
    setID(state, payload) {
      state.id = payload;
    },

    setIsChangingPassword(state, payload) {
      state.isChangingPassword = payload;
    },

    setHash(state, payload) {
      state.hash = payload;
    },

    setEmail(state, payload) {
      state.email = payload;
    },

    setPicture(state, payload) {
      state.picture = payload;
    },

    setFirstName(state, payload) {
      state.firstName = payload;
    },

    setLastName(state, payload) {
      state.lastName = payload;
    },

    setFullName(state, payload) {
      state.fullName = payload;
    },

    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },

    setIsSecurityQuestionsSet(state, payload) {
      state.isSecurityQuestionsSet = payload;
    },

    setShouldShowNewFeature(state, payload) {
      state.shouldShowNewFeature = payload;
    },
    setShouldShowSecurityPrompt(state, payload) {
      state.shouldShowSecurityPrompt = payload;
    },

    setShouldShowPriceFinderPrompt(state, payload) {
      state.shouldShowPriceFinderPrompt = payload;
    },

    setIsEarlyAccess(state, payload) {
      state.isEarlyAccess = payload;
    },

    setIsInternalUser(state, payload) {
      state.isInternalUser = payload;
    },

    setIsFocusGroup(state, payload) {
      state.isFocusGroup = payload;
    },

    setIsFarmlinkGMA(state, payload) {
      state.isFarmlinkGMA = payload;
    },

    setIsGMA(state, payload) {
      state.isGMA = payload;
    },

    setIsSubscriptionGMA(state, payload) {
      state.isSubscriptionGMA = payload;
    },

    setIsUsingOTP(state, payload) {
      state.isUsingOTP = payload;
    },
    setShownSecurityPrompt(state, payload) {
      state.shownSecurityPrompt = payload;
    },
    setShownPriceFinderPrompt(state, payload) {
      state.shownPriceFinderPrompt = payload;
    },
    setPartnership(state, payload) {
      state.partnership = payload;
    },
    setCobranding(state, payload) {
      state.cobranding = payload;
    },
    setShouldShowEULAPrompt(state, payload) {
      state.shouldShowEULAPrompt = payload;
    },

    setShouldShowTermsOfUsePrompt(state, payload) {
      state.shouldShowTermsOfUsePrompt = payload;
    },
  },

  actions: {
    loadUserState({ commit }, payload) {
      if (payload === null) {
        return;
      }

      const {
        id,
        hash,
        email,
        picture,
        firstName,
        lastName,
        fullName,
        isLoggedIn,
        isEarlyAccess,
        isInternalUser,
        isFocusGroup,
        isFarmlinkGMA,
        isGMA,
        isSubscriptionGMA,
        isSecurityQuestionsSet,
        isUsingOTP,
        shouldShowNewFeature,
        shouldShowSecurityPrompt,
        shouldShowPriceFinderPrompt,
        partnership,
        cobrandingSections,
        shouldShowEULAPrompt,
        shouldShowTermsOfUsePrompt,
      } = payload;
      commit('setID', id);
      commit('setHash', hash);
      commit('setEmail', email);
      commit('setPicture', picture);
      commit('setFirstName', firstName);
      commit('setLastName', lastName);
      commit('setFullName', fullName);
      commit('setIsLoggedIn', isLoggedIn);
      commit('setIsEarlyAccess', isEarlyAccess);
      commit('setIsInternalUser', isInternalUser);
      commit('setIsSecurityQuestionsSet', isSecurityQuestionsSet);
      commit('setIsFocusGroup', isFocusGroup);
      commit('setIsFarmlinkGMA', isFarmlinkGMA);
      commit('setIsGMA', isGMA);
      commit('setIsSubscriptionGMA', isSubscriptionGMA);
      commit('setIsUsingOTP', isUsingOTP);
      commit('setShouldShowNewFeature', shouldShowNewFeature);
      commit('setShouldShowSecurityPrompt', shouldShowSecurityPrompt);
      commit('setPartnership', partnership);
      commit('setCobranding', cobrandingSections);
      commit('setShouldShowEULAPrompt', shouldShowEULAPrompt);
      commit('setShouldShowTermsOfUsePrompt', shouldShowTermsOfUsePrompt);
    },

    async toggleSecurityPrompt({ commit }) {
      commit('setShownSecurityPrompt', false);
      await API.shownSecuritySetPrompt();
    },

    async togglePriceFinderPrompt({ commit }) {
      commit('setShownPriceFinderPrompt', true);
      await API.shownPriceFinderPrompt();
    },

    async toggleShouldShowEULAPrompt({ commit }) {
      await API.acceptEULA();
      commit('setShouldShowEULAPrompt', false);
    },

    async toggleShouldShowTermsOfUsePrompt({ commit }) {
      await API.acceptTermsOfUse();
      commit('setShouldShowTermsOfUsePrompt', false);
    },
  },

  namespaced: true,
};
