import Vue from 'vue';
import { postRequest } from '../../helpers/request';

/**
 * @typedef FarmLocation
 * @type {object}
 * @property {string} id
 * @property {boolean} default
 * @property {string} description
 * @property {number} latitude
 * @property {number} longitude
 * @property {string} name
 * @property {object} region
 * @property {number} subscription_id
 */

export default {
  state: () => ({
    /** @type {Array<FarmLocation>} */
    farmLocations: [],

    /** @type {number[]} */
    selectedFarmLocations: [],

    // TODO: UT-850 ///////////////////////////////////////////////

    /** Still being used, but should be replaced by the properties:
     * farmLocationDialogMode
     * farmLocationDialogOpen
     * farmLocationDialogId
     * onDone -> not needed
     */
    farmLocationDialog: {
      open: false,
      id: 0,
      delete: false,
      onDone: null,
    },

    /** @type {string} */
    farmLocationDialogDefaultName: '',

    /** @type {('create'|'update'|'delete')} */
    farmLocationDialogMode: 'create',

    /** @type {boolean} */
    farmLocationDialogOpen: false,

    /** @type {number} */
    farmLocationDialogId: 0,

    /** @type {boolean} */
    primaryFarmDialogOpen: false,

    // TODO: UT-850 ///////////////////////////////////////////////
  }),

  mutations: {
    /**
     * Sets the list of all farm locations
     * @param {object} state
     * @param {Array<FarmLocation>} farmLocations
     */
    setFarmLocations(state, farmLocations) {
      state.farmLocations = farmLocations;
    },

    /**
     * Adds a farm location to the list
     * @param {object} state
     * @param {FarmLocation} farmLocation
     */
    createFarmLocation(state, farmLocation) {
      // If the farm location was set as default, we need to set all other farm locations as not default
      if (farmLocation.default) {
        state.farmLocations.forEach((farm) => {
          // eslint-disable-next-line no-param-reassign
          farm.default = false;
        });
      }
      state.farmLocations = [...state.farmLocations, farmLocation];
    },

    /**
     * Updates a farm location in the list
     * @param {object} state
     * @param {FarmLocation} farmLocation
     */
    updateFarmLocation(state, farmLocation) {
      const index = state.farmLocations.findIndex((f) => f.id === farmLocation.id);
      // If the farm location was set as default, we need to set all other farm locations as not default
      if (farmLocation.default) {
        state.farmLocations.forEach((farm) => {
          // eslint-disable-next-line no-param-reassign
          farm.default = false;
        });
      }

      Vue.set(state.farmLocations, index, farmLocation);
    },

    /**
     * Deletes a farm location from the list
     * @param {object} state
     * @param {FarmLocation} farmLocation
     */
    deleteFarmLocation(state, farmLocation) {
      state.farmLocations = state.farmLocations.filter((f) => f.id !== farmLocation.id);
    },

    /**
     * Sets the list of selected
     * farm locations using the ID
     * @param {object} state
     * @param {number[]} farmLocations
     */
    setSelectedFarmLocations(state, farmLocations) {
      state.selectedFarmLocations = farmLocations;
    },

    /**
     * TODO: UT-850 Refactor to match individual properties
     */
    setFarmLocationDialogOpen(state, value) {
      state.farmLocationDialog.open = value;
    },
    setFarmLocationDialogFarm(state, value) {
      state.farmLocationDialog.id = value;
    },
    setFarmLocationDialogDelete(state, value) {
      state.farmLocationDialog.delete = value;
    },
    setFarmLocationDialogOnDone(state, value) {
      state.farmLocationDialog.onDone = value;
    },
    setFarmLocationDialogDefaultName(state, value) {
      state.farmLocationDialogDefaultName = value;
    },
    setPrimaryFarmDialogOpen(state, value) {
      state.primaryFarmDialogOpen = value;
    },
  },

  getters: {

    /**
     * Gets first selected farm location
     * @returns {FarmLocation}
     */
    firstSelectedFarmLocation(state) {
      return state.selectedFarmLocations[0];
    },

    /**
     * Gets a sorted list of farm locations for display in the UI
     * @returns {Array<FarmLocation>}
     */
    farmLocationsAlphabetical(state) {
      return state.farmLocations.sort((a, b) => a.name.localeCompare(b.name));
    },

    /**
     * Gets whether any farm locations exist or not
     * @returns {boolean}
     */
    hasFarmLocations(state) {
      return state.farmLocations.length > 0;
    },

    /**
     * Gets the first farm location marked as default
     * @returns {FarmLocation}
     */
    defaultFarmLocation(state) {
      return state.farmLocations.find((f) => f.default);
    },

    /**
     * Gets a farm location with the matching ID
     * @returns {(id:number): FarmLocation}
     */
    getFarmLocationById: (state) => (id) => state.farmLocations.find((f) => f.id === id),

    /**
     * Gets location province name by farm location ID
     * @returns {(id:number): string}
     */
    getLocationProvinceName: (state, getters) => (id) => {
      const farmLocation = getters.getFarmLocationById(id);
      return farmLocation?.region?.name ?? '';
    },

    /**
     * Returns a single farm location, with the following priority (if available): default farm
     * location, first alphabetical farm location, null
     * @returns {(id:number): string}
     */
    defaultSelectedLocation(state, getters) {
      if (getters.defaultFarmLocation) return getters.defaultFarmLocation;

      if (getters.farmLocationsAlphabetical.length > 0) return getters.farmLocationsAlphabetical[0];

      return null;
    },
  },

  actions: {
    async fetchFarmLocations({ commit }) {
      const farmLocations = await postRequest('/dashboard/api/read_farm_locations/');
      commit('setFarmLocations', farmLocations);
    },

    selectAllFarmLocations({ commit, state }) {
      commit('setSelectedFarmLocations', state.farmLocations.map((f) => f.id));
    },

    openFarmLocationDialog({ commit }, val) {
      commit('setFarmLocationDialogFarm', 0);
      commit('setFarmLocationDialogDelete', false);
      commit('setFarmLocationDialogOnDone', null);
      commit('setFarmLocationDialogOpen', true);

      if (typeof (val) === 'object') {
        if (val.defaultName) commit('setFarmLocationDialogDefaultName', val.defaultName);
        commit('setFarmLocationDialogFarm', val.id);
        commit('setFarmLocationDialogDelete', val.delete);
        commit('setFarmLocationDialogOnDone', val.onDone);
      } else {
        commit('setFarmLocationDialogFarm', val);
      }
    },

    closeFarmLocationDialog({ commit }) {
      commit('setFarmLocationDialogOpen', false);
    },

    async createFarmLocation({ commit }, farmLocation) {
      const response = await API.createFarmLocation(farmLocation);
      commit('createFarmLocation', response.data);
      commit('setSelectedFarmLocations', [response.data.id]);

      return response;
    },

    async updateFarmLocation({ commit }, farmLocation) {
      const response = await API.updateFarmLocation(farmLocation);
      commit('updateFarmLocation', response.data);

      return response;
    },

    async deleteFarmLocation({ commit }, farmLocation) {
      const response = await API.deleteFarmLocation(farmLocation.id);
      commit('deleteFarmLocation', farmLocation);

      return response;
    },
  },

  namespaced: true,
};
