<template>
  <v-dialog
    v-model="isCreateAccountDialogOpen"
    persistent
    max-width="480px"
    width="100%"
  >
    <confirm-card
      text="Do you want to create a new account?"
      @cancel="handleCancelClick"
      @confirm="handleConfirmClick"
    />
  </v-dialog>
</template>

<script>
export default {

  computed: {
    isCreateAccountDialogOpen: {
      get() {
        return this.$store.state.app.isCreateAccountDialogOpen;
      },
      set(value) {
        this.$store.commit('app/setCreateAccountDialogOpen', value);
      },
    },
  },

  methods: {
    async handleConfirmClick() {
      this.$store.commit('app/setCreateAccountDialogOpen', false);
      await this.createSubscription();
    },

    handleCancelClick() {
      this.$store.commit('app/setCreateAccountDialogOpen', false);
    },

    async createSubscription() {
      try {
        await API.createSubscription();
        window.location.href = '/dashboard/organization/profile/';
      } catch (e) {
        this.$snackbar.error(e);
      }
    },
  },
};
</script>
