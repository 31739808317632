<template>
  <v-card v-if="showCard">
    <v-card-title class="pb-0">
      <v-img
        v-if="item !== null"
        class="mr-2"
        :src="item.image"
        max-width="32px"
        max-height="32px"
      />
      Featured Content
    </v-card-title>
    <div class="pa-4 pt-0">
      <template v-if="isLoading">
        <div class="grey--text text-center my-8 py-8">
          Loading...
        </div>
      </template>
      <template v-else-if="item">
        <FeaturedLibraryItem
          :id="item.id"
          :name="item.name"
          :tagline="item.tagline"
          :snippet="item.snippet"
          :date="item.date_published"
          :content-icon="item.icon"
          :provider="item.provider"
          :content="item.content"
          :slug="item.slug"
          :description="item.description"
          :url="item.url"
        />
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FeaturedLibraryItem from '@/components/dashboard/FeaturedLibraryItem.vue';

export default {
  components: {
    FeaturedLibraryItem,
  },

  data() {
    return {
      isLoading: true,
      item: null,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),

    showCard() {
      return (this.item || this.isLoading);
    },
  },

  watch: {
    showCard(newValue) {
      this.$emit('widget-hidden', !newValue);
      this.setFeaturedContentTab(!newValue);
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions('dashboard', ['setFeaturedContentTab']),
    async fetchData() {
      try {
        this.isLoading = true;
        const data = await API.getFeaturedLibraryItem();
        this.item = data.data;
      } catch (e) {
        // Card should not display if endpoint fails
        this.item = null;
        this.$snackbar.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
