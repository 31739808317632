<template>
  <SlideDrawer
    v-model="showSlideout"
    title="NLG Helper"
    :nudge-top="39"
    :nudge-bottom="3"
    @close="handleCloseSlideout"
    @interface="emitInterface"
    @change:overlap="handleChangeOverlap"
  >
    <div class="d-flex flex-column h--100">
      <div class="pa-4 mb-4">
        <v-row class="pb-8">
          <GAutocomplete
            v-model="selectedReport"
            label="Report Name"
            :items="reportNames"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            dense
            clearable
            hide-details
            @click:clear="handleClear('report')"
          />
        </v-row>
        <v-row class="pb-8">
          <GAutocomplete
            v-model="selectedDataSetName"
            label="Data Set"
            :items="dataSets"
            :menu-props="{ bottom: true, offsetY: true }"
            :disabled="isDatasetDisabled"
            outlined
            dense
            clearable
            hide-details
            @click:clear="handleClear('dataset')"
          />
        </v-row>
        <v-row class="pb-8">
          <GAutocomplete
            v-model="selectedCropName"
            label="Crop of Interest"
            :items="crops"
            :menu-props="{ bottom: true, offsetY: true }"
            :disabled="isCropInterestDisabled"
            outlined
            dense
            clearable
            hide-details
            @click:clear="handleClear('crop')"
          />
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="6"
            class="px-0 pr-2"
          >
            <GAutocomplete
              v-model="selectedYearItem"
              label="Year"
              :items="years"
              :menu-props="{ bottom: true, offsetY: true }"
              :disabled="isYearAndCadenceDisabled"
              outlined
              dense
              clearable
              hide-details
              @click:clear="handleClear('year')"
            />
          </v-col>
          <v-col
            cols="6"
            class="px-0 pl-2"
          >
            <CadenceSelector
              v-model="selectedCadenceItem"
              :cadence="cadence['cadenceUnit']"
              :cadence-values="cadence['cadenceValues']"
              :selected-year="selectedYearItem"
              :disabled="isCadenceDisabled"
            />
          </v-col>
        </v-row>
      </div>
      <!-- summary -->
      <div class="flex-1 d-flex flex-column g-4 pb-4 suggestion-container">
        <span class="suggestion-title primary-grey--text">
          Suggestion:
        </span>
        <div class="flex-1 p-rel pa-4 grey lighten-5 rounded-lg overflow-hidden">
          <v-progress-linear
            v-if="loading"
            class="p-abs left-0 top-0"
            indeterminate
          />
          <div class="suggestion-text">
            <p
              v-for="(line, index) in suggestionLines"
              :key="index"
              class="text-body-2"
              :class="{['primary-grey--text']: suggestion,
                       ['grey--text text--darken-1 user-select--none']: !suggestion}"
            >
              {{ line }}
            </p>
          </div>
          <div class="p-abs bottom-0 right-0 pa-2">
            <span
              class="primary-grey-text text-caption user-select--none"
              :class="{ ['opacity--0 copy-message-out-transition']: !showCopiedMessage,
                        ['opacity--1']: showCopiedMessage }"
            >
              Copied!
            </span>
            <v-btn
              icon
              dense
              :disabled="!suggestion"
              class="primary-grey--text lighten-4 copy-message-button"
              @click="copyToClipboard"
            >
              <v-icon dense>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- "See Data" Button -->
      <v-btn
        class="mb-4"
        text
        color="primary"
        :disabled="seeDataButtonDisabled"
        @click="dataTableOpen = true"
      >
        See Data
      </v-btn>
      <NLGDataTable
        v-model="dataTableOpen"
      />
      <div class="d-flex justify-end">
        <v-btn
          class="mr-4 primary-grey--text lighten-4"
          :disabled="clearButtonDisabled"
          @click="clear"
        >
          Clear search
        </v-btn>

        <v-btn
          color="primary"
          :disabled="isAddPropertiesDisabled"
          @click="handleAddProperties"
        >
          Add properties
        </v-btn>
      </div>
    </div>
  </SlideDrawer>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import SlideDrawer from '@/components/drawers/SlideDrawer.vue';
import GAutocomplete from '@/components/generic/GAutocomplete.vue';
import CadenceSelector from '@/components/document/nlg/CadenceSelector.vue';
import NLGDataTable from '@/components/document/nlg/NLGDataTable.vue';

export default {
  name: 'NLGSlideout',

  components: {
    CadenceSelector,
    GAutocomplete,
    SlideDrawer,
    NLGDataTable,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      allYears: [],
      dataTableOpen: false,
      showCopiedMessage: false,
    };
  },

  computed: {
    ...mapState('nlg', [
      'selectedReportName',
      'selectedDataSet',
      'selectedCrop',
      'selectedYear',
      'selectedCadence',
      'loading',
      'failed',
    ]),
    ...mapGetters('nlg', [
      'reportNames',
      'dataSets',
      'crops',
      'years',
      'cadence',
      'suggestion',
      'key',
    ]),

    suggestionLines() {
      if (this.suggestion) {
        if (this.suggestion.includes('\n')) {
          return this.suggestion.split('\n');
        }
        return [this.suggestion];
      }
      if (!this.selectedCadence || !this.selectedCadence.length) {
        return [
          'Fill in the fields above to get a text suggestion.',
        ];
      }
      if (this.loading) {
        return [
          'Loading your suggestion...',
        ];
      }
      if (this.failed) {
        return [
          'There was an error loading your suggestion.',
        ];
      }
      return [''];
    },

    isDatasetDisabled() {
      return this.selectedReport === '';
    },

    isCropInterestDisabled() {
      return this.selectedReport === '' || this.selectedDataSet === '';
    },

    isYearAndCadenceDisabled() {
      return this.selectedReport === ''
      || this.selectedDataSet === ''
      || this.selectedCrop === '';
    },

    isAddPropertiesDisabled() {
      // By default, disabling add Properties for a future epic
      return true;
    },

    isCadenceDisabled() {
      return this.selectedYearItem === '' || this.cadenceType === 'yearly';
    },

    selectedReport: {
      get() {
        return this.selectedReportName;
      },
      set(newValue) {
        this.updateSelectedReportName(newValue === null ? '' : newValue);
      },
    },

    selectedDataSetName: {
      get() {
        return this.selectedDataSet;
      },
      set(newValue) {
        this.updateSelectedDataSet(newValue === null ? '' : newValue);
      },
    },

    selectedCropName: {
      get() {
        return this.selectedCrop;
      },
      set(newValue) {
        this.updateSelectedCrop(newValue === null ? '' : newValue);
      },
    },

    selectedYearItem: {
      get() {
        return this.selectedYear;
      },
      set(newValue) {
        this.updateSelectedYear(newValue === null ? '' : newValue);
        if (this.cadenceType === 'yearly') {
          this.updateSelectedCadence(newValue === null || newValue === undefined ? '' : newValue);
        }
      },
    },

    selectedCadenceItem: {
      get() {
        return this.selectedCadence;
      },
      set(newValue) {
        this.updateSelectedCadence(newValue === null || newValue === undefined ? '' : newValue);
      },
    },

    clearButtonDisabled() {
      return this.selectedReport === ''
      && this.selectedDataSetName === ''
      && this.selectedCropName === ''
      && this.selectedYearItem === ''
      && this.selectedCadenceItem === '';
    },
    seeDataButtonDisabled() {
      return this.selectedCadenceItem === '';
    },

    showSlideout: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    cadenceType() {
      return this.cadence.cadenceUnit;
    },
  },
  watch: {
    async selectedReport() {
      this.handleClear('report');
    },

    async selectedDataSetName() {
      this.handleClear('dataset');
    },

    async selectedCropName() {
      this.handleClear('crop');
    },

    async key() {
      if (this.key) {
        await this.generateSuggestion();
      }
    },

    async value() {
      if (this.value && !this.optionsLoaded) {
        await this.getOptionsTree();
        this.optionsLoaded = true;
      }
    },
  },
  methods: {
    ...mapMutations('nlg', ['updateSelectedReportName', 'updateSelectedDataSet', 'updateSelectedCrop', 'updateSelectedYear', 'updateSelectedCadence', 'clear']),
    ...mapActions('nlg', ['getOptionsTree', 'generateSuggestion', 'getAllData']),

    handleCloseSlideout() {
      this.$emit('close');
    },

    async copyToClipboard() {
      await window.navigator.clipboard.writeText(this.suggestion);
      this.showCopiedMessage = true;
      setTimeout(() => {
        // nextTick doesn't work as it is not shown long enough
        this.showCopiedMessage = false;
      }, 100);
    },

    handleClear(field = '') {
      if (field === 'report') {
        this.updateSelectedDataSet('');
        this.updateSelectedCrop('');
        this.updateSelectedYear('');
        this.updateSelectedCadence('');
      } else if (field === 'dataset') {
        this.updateSelectedCrop('');
        this.updateSelectedYear('');
        this.updateSelectedCadence('');
      } else if (field === 'crop') {
        this.updateSelectedYear('');
        this.updateSelectedCadence('');
      } else if (field === 'year') {
        this.updateSelectedCadence('');
      }
    },

    async handleAddProperties() {
      // TODO: Handle all the selected values when Add Properties is selected
      this.$emit('add-properties');
    },

    emitInterface(emittedInterface) {
      this.$emit('interface', emittedInterface);
    },

    handleChangeOverlap(value) {
      this.$emit('change:overlap', value);
    },
  },
};
</script>
<style lang="scss">
.suggestion-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

.suggestion-container {
  min-height: 276px;
}

.suggestion-text {
  height: 100%;
  max-height: calc(100% - 36px);
  overflow: auto;
}

.theme--light.copy-message-button.v-btn::before {
  background-color: rgba(225, 203, 49, 1);
}

.theme--light.copy-message-button.v-btn:hover::before {
  opacity: 0.3;
}

.copy-message-out-transition {
  transition: opacity 0.5s;
  transition-delay: 2.5s;
}
</style>
