export default {
  state: () => ({
    useFemaleVoice: false,
  }),

  mutations: {
    setUseFemaleVoice(state, payload) {
      state.useFemaleVoice = payload;
    },
  },

  actions: {
    loadPreferencesState({ commit }, payload) {
      if (payload === null) {
        return;
      }
      const { useFemaleVoice } = payload;
      commit('setUseFemaleVoice', useFemaleVoice);
    },
  },

  namespaced: true,
};
