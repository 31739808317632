import { formatUnit } from '../../../helpers/farm_profile';
import { formatNumberEnforceDecimals } from '../../../helpers/formatting';

export default {
  state: () => ({
    dataTableCropOrder: [],
    cropSuccessDialogOpen: false,
    lastAddedCrop: null, // commodity object
    multipleCropsAdded: false,
  }),
  getters: {
    units(state, getters, rootState, rootGetters) {
      return {
        area: rootGetters['farmProfile/currentUnitPreference']('Area'),
        yield: rootGetters['farmProfile/currentUnitPreference']('Yield'),
        production: rootGetters['farmProfile/currentUnitPreference']('Production'),
      };
    },
    getTableRowDataByYears(state, getters, rootState, rootGetters) {
      return (item) => rootGetters['farmProfile/filteredYearsLongName'].map((year, index) => {
        if (year in item.years) {
          const data = item.years[year];
          return {
            id: data.id,
            label: year,
            area: rootGetters['shared/convert'](
              data.harvested_area,
              data.area_unit_id,
              getters.units.area,
              item.commodity_id,
            ),
            yield: rootGetters['shared/convert'](
              data.prod_yield,
              data.yield_unit_id,
              getters.units.yield,
              item.commodity_id,
            ),
            production: rootGetters['shared/convert'](
              data.production,
              data.production_unit_id,
              getters.units.production,
              item.commodity_id,
            ),
          };
        }
        return {
          id: `null-${index}`,
          label: year,
          area: '-',
          yield: '-',
          production: '-',
        };
      });
    },
    getTableRowDataByFarms(state, getters, rootState, rootGetters) {
      const productionFarms = rootGetters['farmProfile/productionFarms/farms'];
      return (item) => productionFarms.map((farm, index) => {
        const found = item.farms.find((itemFarm) => itemFarm.id === farm.id);
        if (found) {
          return {
            id: farm.id,
            label: farm.name,
            area: rootGetters['shared/convert'](
              found.area,
              found.areaUnit,
              getters.units.area,
              item.commodity_id,
            ),
            yield: rootGetters['shared/convert'](
              found.prodYield,
              found.yieldUnit,
              getters.units.yield,
              item.commodity_id,
            ),
            production: rootGetters['shared/convert'](
              found.production,
              found.productionUnit,
              getters.units.production,
              item.commodity_id,
            ),
          };
        }
        return {
          id: `null-${index}`,
          label: farm.name,
          area: '-',
          yield: '-',
          production: '-',
        };
      });
    },
    getTableFormattedData(state, getters) {
      return (compareBy, item, context) => {
        let table = [];
        if (compareBy === 'years') {
          table = getters.getTableRowDataByYears(item);
        } else {
          table = getters.getTableRowDataByFarms(item);
        }
        return table.map((data) => {
          let value = '';
          if (context === 'Area') {
            value = formatUnit(formatNumberEnforceDecimals(data.area, 2), getters.units.area.abbreviation);
          } else if (context === 'Yield') {
            value = formatUnit(formatNumberEnforceDecimals(data.yield, 2), getters.units.yield.abbreviation);
          } else if (context === 'Production') {
            value = formatUnit(formatNumberEnforceDecimals(data.production, 2), getters.units.production.abbreviation);
          }
          return {
            id: data.id,
            label: data.label,
            value,
          };
        });
      };
    },
  },
  mutations: {
    setDataTableCropOrder(state, ordered) {
      state.dataTableCropOrder = ordered;
    },
    setCropSuccessDialogOpen(state, value) {
      state.cropSuccessDialogOpen = value;
    },
    setLastAddedCrop(state, crop) {
      state.lastAddedCrop = crop;
    },
    setMultipleCropsAdded(state, value) {
      state.multipleCropsAdded = value;
    },
  },
  namespaced: true,
};
