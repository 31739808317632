<template>
  <v-menu
    ref="CadenceSelector"
    v-model="menuState"
    :close-on-content-click="false"
    bottom
    offset-y
    :disabled="disabled"
  >
    <template
      #activator="{ on, attrs }"
    >
      <v-text-field
        v-model="selectedValue"
        placeholder="Cadence"
        label="Cadence"
        :disabled="disabled"
        readonly
        clearable
        outlined
        hide-details
        v-bind="attrs"
        dense
        v-on="on"
      />
    </template>

    <v-date-picker
      v-if="isDatePicker"
      :ref="pickerRefName"
      v-model="selectedMonth"
      :type="activePicker"
      :allowed-dates="allowedDates"
      :min="minDate"
      :max="maxDate"
      reactive
      :show-current="false"
      @input="onDatePicked"
    />
    <v-list
      v-else
      style="max-height: 300px; overflow: auto;"
    >
      <v-list-item
        v-for="item in items"
        :key="item.value"
        @click="select(item)"
      >
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { dateFormatDateString, dateFormatLongMonth } from '@/helpers/formatting';

export default {
  name: 'CadenceSelector',
  props: {
    cadence: {
      type: String,
      default: 'daily',
    },
    cadenceValues: {
      type: Array,
      default: () => [],
    },
    selectedYear: {
      type: String,
      default: () => new Date().getFullYear().toLocaleString(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedMonth: '',
      menuState: false,
    };
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.value === '') {
          this.selectedMonth = undefined;
        }
        this.$emit('input', value);
      },
    },
    pickerRefName() {
      return `cadence-selector-${this.cadence}`;
    },
    pickerRef() {
      return this.$refs[this.pickerRefName];
    },
    weeks() {
      // Return cadence values as labels Week 1, Week 2, etc.
      if (this.cadence === 'weekly') {
        return this.cadenceValues.map((weekNum) => `Week ${weekNum}`);
      }
      return [];
    },
    quarterly() {
      // Return cadence values as labels Q1, Q2, etc.
      if (this.cadence === 'quarterly') {
        return this.cadenceValues.map((quarterNum) => `Q${quarterNum}`);
      }
      return [];
    },
    isMonthly() {
      // Return true if the cadence is monthly
      return this.cadence === 'monthly';
    },
    items() {
      switch (this.cadence) {
        case 'weekly':
          return this.weeks.map((week, i) => ({
            text: week,
            value: Number(week.split(' ')[1]),
          }));
        case 'quarterly':
          return this.quarterly.map((quarter, i) => ({
            text: quarter,
            value: Number(quarter[1]),
          }));
        default:
          return [];
      }
    },
    isDatePicker() {
      if (this.selectedYear === '') {
        return false;
      }
      return this.cadence === 'daily' || this.isMonthly;
    },
    activePicker() {
      if (this.isMonthly) {
        return 'month';
      }
      return 'date';
    },
    minDate() {
      // Return the minimum date allowed for the picker
      if (this.selectedYear) {
        const first = new Date(this.parsedSelectedYear, 0, 1);
        return dateFormatDateString(first);
      }
      return undefined;
    },
    maxDate() {
      // Return the maximum date allowed for the picker
      if (this.selectedYear) {
        const last = new Date(this.parsedSelectedYear, 11, 31);
        return dateFormatDateString(last);
      }
      return undefined;
    },
    parsedSelectedYear() {
      if (this.selectedYear) {
        return parseInt(this.selectedYear, 10);
      }
      return undefined;
    },
    allowedMonths() {
      // Return the allowed months for the picker
      if (this.isMonthly) {
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];

        return this.cadenceValues.map((monthNum) => months[monthNum - 1]);
      }
      return [];
    },
    allowedDays() {
      // Return the allowed days for the picker
      if (this.cadence === 'daily') {
        return this.cadenceValues.map((dayNum) => this.getDateFromDayOfYear(dayNum, this.selectedYear));
      }
      return [];
    },
  },
  watch: {
    menuState() {
      // When the menu is closed, emit the selected value
      if (this.menuState) {
        this.clearArrows();
        if (!this.selectedMonth) {
          this.selectedMonth = `${this.selectedYear}-01-01`;
        }
      }
    },
    isDisabled(val) {
      // When the disabled prop changes, update the menu state
      if (val) {
        this.menuState = false;
        this.selectedValue = '';
        this.selectedMonth = '';
      }
    },
    selectedYear(val) {
      if (val) {
        this.selectedMonth = `${val}-01-01`;
      } else {
        this.selectedValue = undefined;
      }
    },
  },
  methods: {
    // Return true if the date is in the allowedMonths or allowedDays array
    allowedDates(val) {
      if (this.isMonthly && this.allowedMonths.length > 0) {
        // Get the year from the string 2020-01
        const year = parseInt(val.split('-')[0], 10);
        // Get the month from the string 2020-01
        const month = parseInt(val.split('-')[1], 10);
        // Create obj with index as key and month Name as value in accordance with the calendar
        const monthNames = {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };
        const monthName = monthNames[month];
        return this.allowedMonths.includes(monthName)
          && year === this.parsedSelectedYear;
      }
      if (this.cadence.toLowerCase() === 'daily' && this.allowedDays.length > 0) {
        return this.allowedDays.indexOf(val) !== -1;
      }
      return true;
    },
    clearArrows() {
      this.$nextTick(() => {
        if (this.pickerRef && this.isDatePicker) {
        // Remove the Ability to change year and months
        // because vuetify does not have the ability to disable them
          const header = this.pickerRef.$el.getElementsByClassName('v-date-picker-header')[0];
          const buttons = header.getElementsByTagName('button');
          if (buttons.length >= 3) {
          // For some reason, the buttons are not always in the correct order
            const [previous, year, next, nextnext] = buttons;
            if (nextnext !== undefined) {
              if (this.isMonthly) {
                previous.style.display = 'none';
                next.style.pointerEvents = 'none';
              }
              nextnext.style.display = 'none';
            } else {
              if (this.isMonthly) {
                previous.style.display = 'none';
                next.style.display = 'none';
              }
              year.style.pointerEvents = 'none';
            }
          }
        }
      });
    },
    select(item) {
      // Set the selected value and close the menu
      this.selectedValue = item.text;
      this.menuState = false;
      this.$emit('change', item.text);
    },
    onDatePicked(date) {
      // Set the selected value and close the menu
      if (date === undefined) {
        return;
      }
      if (this.isMonthly) {
        const dateObj = new Date(date);
        // Add a day to the date to make sure it is in the correct month
        dateObj.setDate(dateObj.getDate() + 1);
        this.selectedValue = dateFormatLongMonth(dateObj);
      } else {
        this.selectedValue = date;
        this.selectedMonth = date;
      }
      this.menuState = false;
      this.$emit('change', date);
    },
    // Takes the day of the year and the year and returns a string in the format YYYY-MM-DD
    // ex. (35, 2022) => '2022-02-04'
    getDateFromDayOfYear(dayOfYear, year) {
      const start = new Date(year, 0); // initialize a date in `year-01-01`
      const date = new Date(start.setDate(dayOfYear)); // add the number of days
      return dateFormatDateString(date);
    },
  },
};
</script>
