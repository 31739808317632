<template>
  <Editor
    :api-key="apiKey"
    cloud-channel="6"
    :init="config"
    :plugins="plugins"
    @onInit="handleInit"
    @onChange="handleInput"
    @onKeyDown="handleKeydown"
    @onExecCommand="handleExecCommand"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { isNumber } from '@/helpers/numeric';
import { getLocalDateTime } from '@/helpers/formatting';
import { registerSpeakButton, registerSpeakIcon } from '@/helpers/tts';
import { TINY_INIT, TINY_PLUGINS, LINK_INIT } from '@/config/editor';
import { TINYMCE_API_KEY } from '@/helpers/environment_variables';

function removeSpaces(str) {
  return str
    .replace(/ /g, '')
    .replace(/\n/g, '');
}

export default {
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    contentId: {
      type: Number,
      default: 0,
    },
    state: {
      type: Object,
      default: () => {},
    },
    slideDrawerOverlap: {
      type: Number,
      default: 0,
    },
    templates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      editor: null,
      hasContentChanged: false,
      plugins: TINY_PLUGINS,
      apiKey: TINYMCE_API_KEY,
    };
  },

  computed: {
    template() {
      return this.templates.find((x) => x.id === this.contentId)
        || { id: null, content: '' };
    },

    cropName() {
      if (this.state.crop === '') return '';
      return `${this.state.crop} `;
    },

    subheaderName() {
      return this.state.subheader;
    },

    headerMonth() {
      try {
        const date = getLocalDateTime(this.state.publishDate, this.state.publishTime);
        return Intl.DateTimeFormat('en', { month: 'long' }).format(date);
      } catch (e) {
        return '';
      }
    },

    headerDate() {
      return this.state.publishDate.split('-')[2] ?? '';
    },

    headerYear() {
      return this.state.publishDate.split('-')[0] ?? '';
    },

    headerFullDate() {
      return `${this.headerMonth} ${this.headerDate}, ${this.headerYear}`;
    },

    config() {
      return {
        ...TINY_INIT,
        ...LINK_INIT,
        templates: this.templates,
        template_replace_values: {
          crop: () => this.cropName,
          tagline: () => this.state.subheader || 'Tagline',
          month: () => this.headerMonth,
          date: () => this.headerDate,
          year: () => this.headerYear,
        },
        template_preview_replace_values: {
          crop: () => this.cropName,
          tagline: () => this.state.subheader || 'Tagline',
          month: () => this.headerMonth,
          date: () => this.headerDate,
          year: () => this.headerYear,
        },
        setup: (editor) => {
          registerSpeakIcon(editor);
          registerSpeakButton(editor);
        },
      };
    },
  },

  watch: {
    contentId() {
      if (!this.editor) return;
      this.editor.setContent('');
      this.resetDocumentFocus();
      tinymce.activeEditor.execCommand('mceInsertTemplate', false, this.template.content);
    },

    headerFullDate(newVal) {
      if (!this.editor) return;
      const headerDateElement = this.editor.dom.doc.getElementById('publishdate');
      if (headerDateElement) {
        headerDateElement.textContent = newVal;
        this.handleInput(null, this.editor);
      }
    },

    slideDrawerOverlap: {
      handler(newValue) {
        if (isNumber(newValue)) {
          document.documentElement.style.setProperty('--editor-slide-drawer-overlap', `${newValue}px`);
        }
      },
      immediate: true,
    },

    cropName(newVal) {
      if (!this.editor) return;
      const cropElement = this.editor.dom.doc.getElementById('crop');
      if (cropElement) {
        cropElement.textContent = newVal;
        this.handleInput(null, this.editor);
      }
    },

    subheaderName(newVal) {
      if (!this.editor) return;
      const subheaderElement = this.editor.dom.doc.getElementById('subheader');
      if (subheaderElement) {
        subheaderElement.textContent = newVal;
        this.handleInput(null, this.editor);
      }
    },
  },

  methods: {
    handleInit(event, editor) {
      this.editor = editor;

      if (this.value) {
        this.editor.setContent(this.value);
        this.hasContentChanged = true;
      }

      this.$emit('update-editor', this.editor);
    },
    handleKeydown(event, editor) {
      const element = editor.selection.getNode();

      // Prevent modifying protected elements
      if (element.classList.contains('mceNonEditable')) {
        // Allow certain keyboard keys
        if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Control', 'Shift', 'Tab'].includes(event.key)) {
          return true;
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
      }

      return true;
    },
    handleExecCommand(event, editor) {
      if (event.command === 'mceInsertTemplate') {
        if (event.value === '') return;

        const domParser = new DOMParser();
        const dom = domParser.parseFromString(event.value, 'text/html');
        const header = dom.getElementById('header').textContent;

        // If we find a header, try to find the matching template
        if (header) {
          const template = this.templates.find((t) => t.title.includes(header));
          // If we find a template, update the publication field
          if (template) {
            this.state.publicationId = template.id;
          }
        }
      }
    },
    handleInput(event, editor) {
      const contents = editor.getContent();
      const hasContent = Boolean(contents);
      const isContentSame = removeSpaces(contents) === removeSpaces(this.template.content);

      this.hasContentChanged = hasContent && !isContentSame;
      this.$emit('input', contents);
    },

    resetDocumentFocus() {
      this.$nextTick(() => {
        if (this.editor && this.editor.selection) {
          // Select the document <head> to remove selection
          this.editor.selection.select(this.editor.dom.doc.querySelector('head'));
        }
      });
    },
  },
};
</script>

<style lang="scss">
.tox.tox-tinymce {
  height: 100% !important;
  border: none;
  border-radius: 0;
}

.tox button.tox-button {
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  transition-duration: 0.3s;
}

.tox button.tox-button[title="Save"],
.tox button.tox-button[title="Insert"] {
  background-color: #F47721;
  border-color: #F47721;
}

.tox button.tox-button[title="Save"]:hover,
.tox button.tox-button[title="Insert"]:hover {
  background-color: #F47721;
  border-color: #F47721;
  opacity: 0.8;
}

.tox div.tox-dialog__body-nav-item--active {
  color: #F47721;
  border-bottom-color: #F47721;
}

.tox div.tox-dialog__body-nav-item--active:focus {
  background-color: #F477211a;
}

.tox .tox-edit-area__iframe {
  width: calc(100% - var(--editor-slide-drawer-overlap)) !important;
  transition: width 200ms ease-in-out !important;
}

.tox .tox-edit-area {
  background: #FAFAFA;
}

:root {
  --editor-slide-drawer-overlap: 0px;
}

.fedra-sans {
  font-family: 'fedra-sans';
}
</style>
