import { datadogLogs } from '@datadog/browser-logs';

/* eslint-disable */
(function () {
  let pageLoaded = false;
  let dataSent = false;
  class GrainFoxAnalytics {
    static ENDPOINT = '/api/gfa/'
    static pageViewEvents = [];
    static libraryViewEvents = {};
    static libraryListenEvents = {};
    static libraryClickEvents = [];
    static isMobileSafari = () => (/^((?!chrome|crios|android).)*safari/i.test(navigator.userAgent) && typeof window.safari === 'undefined');

    /* Page View Events */

    static getMostRecentPage() {
      if (this.pageViewEvents.length === 0) return null;
      return this.pageViewEvents[this.pageViewEvents.length - 1];
    }

    static updateLvpst() {
      let mostRecentPage = this.getMostRecentPage();
      if (mostRecentPage) {
        document.cookie = `lvpst=${mostRecentPage.enter_time};path=/`;
      }
    }

    static loadPageViewEvents() {
      if (sessionStorage.pve) {
        this.pageViewEvents = JSON.parse(sessionStorage.getItem('pve'));
      }
    }

    /* Library View Events */

    static getLibraryViewKey(id, timestamp) {
      return `${id}@${timestamp}`;
    }

    static debounce(func, wait) {
      let timeout;

      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    /* Library Listen Events */

    /**
     * Sets or nulls the backup segment in case we need to
     * update the listen percent before posting the data
     * @param {string} eventKey
     * @param {Array} backupSegment
     */
    static setLibraryBackupSegment(eventKey, backupSegment, currentTime) {
      if (this.libraryListenEvents[eventKey]) {
        this.libraryListenEvents[eventKey].state.backupSegment = backupSegment;
        this.libraryListenEvents[eventKey].state.mostRecentTime = currentTime;
      }
    }

    /**
     * Merges a new segment with existing segments, then
     * sums the total listening time, finally returning
     * the updated segments and listen percent
     * @param {Array} newSegment
     * @param {Array} segments
     * @param {Number} totalSeconds
     */
    static calculateMediaListenPercent(newSegment, segments, totalSeconds) {
      const combineSegments = (segments.concat([newSegment]));
      const mergedSegments = combineSegments
        .sort((a, b) => a[0] - b[0] || a[1] - b[1])
        .reduce(function (acc, current) {
            let last = acc[acc.length - 1] || [];
            if (last[0] <= current[0] && current[0] <= last[1]) {
              if (last[1] < current[1]) {
                last[1] = current[1];
              }
              return acc;
            }
            return acc.concat([current]);
        }, []);

      const secondsListened = mergedSegments.reduce(function(acc, current) {
        return acc + (current[1] - current[0]);
      }, 0);

      return {
        segments: mergedSegments,
        listenPercent: secondsListened / totalSeconds,
      };
    }

    /**
     * If a segment exists, use it to
     * update the event's listen percent
     * @param {Object} event
     * @param {Array} segment
     */
    static updateLibraryListenPercent(event, segment) {
      const { state: { segments, totalSeconds } } = event;
      if (segment) {
        const result = this.calculateMediaListenPercent(segment, segments, totalSeconds);
        event.state.segments = result.segments;
        event.payload.listen_percent = result.listenPercent;
      }
    }

    /**
     * Closes any open library listen events, then
     * updates the last_action time and listen percent
     * @param {*} finalTime
     */
    static finalizeLibraryListenEvents(finalTime) {
      Object.values(this.libraryListenEvents).forEach(event => {
        if (event.state.isOpen) {
          event.state.isOpen = false;
          event.payload.last_action = finalTime;
          this.updateLibraryListenPercent(event, event.state.backupSegment);
        }
      });
    }

    /* Update */

    static updatePageViewEventsBeforeUnload(unloadTime) {
      // Update latest page event leave_time
      let previousPage = this.getMostRecentPage();
      if (previousPage) {
        previousPage.leave_time = unloadTime;
      }
    }

    static updateLibraryViewEventsBeforeUnload(unloadTime) {
      // Update the last_action time
      Object.values(this.libraryViewEvents).forEach(event => {
        if (event.open) {
          event.last_action = unloadTime;
        }
      });
    }

    static updateLibraryListenEventsBeforeUnload(unloadTime) {
      // Update the last_action time and add the backup segment
      Object.values(this.libraryListenEvents).forEach(event => {
        if (event.state.isOpen) {
          event.payload.last_action = unloadTime;
          this.updateLibraryListenPercent(event, event.state.backupSegment);
          try {
            window.localStorage.setItem(`audio-${event.payload.id}`, event.state.mostRecentTime);
          } catch (e) {
            datadogLogs.logger.error('Analytics - Library listen event error', {}, e);
          }
        }
      });
    }

    /* Clean */

    static cleanPageViewEvents() {
      // On next page load, the page view event list
      // will load the cleaned list from sessionStorage
      sessionStorage.setItem('pve', JSON.stringify(this.pageViewEvents.slice(-1)));
    }

    static cleanLibraryViewEvents() {
      this.libraryViewEvents = Object.fromEntries(Object.entries(this.libraryViewEvents)
        .filter(([key, value]) => value.open === true));
    }

    static cleanLibraryListenEvents() {
      this.libraryListenEvents = Object.fromEntries(Object.entries(this.libraryListenEvents)
        .filter(([key, value]) => value.state.isOpen === true));
    }

    static cleanLibraryClickEvents() {
      this.libraryClickEvents = [];
    }

    /* Data Communication */

    static send(type, eventData) {
      let event = { 'event': type };
      switch (type) {
        case 'page_view':
          pageLoaded = true;

          let enter_page = window.location.pathname;
          let currentTime = new Date().toISOString();

          // Update previous pageview
          let previousPage = this.getMostRecentPage();
          if (previousPage) {
            previousPage.leave_page = previousPage.leave_page == null ? enter_page : previousPage.leave_page;
          }

          // Create new pageview event
          const pv = Object.assign(event, {
            'enter_page': enter_page,
            'leave_page': null,
            'enter_time': currentTime,
            'leave_time': null,
          });

          this.pageViewEvents.push(pv);
          sessionStorage.setItem('pve', JSON.stringify(this.pageViewEvents));

          // Post data
          const formData = new FormData();
          formData.append('pageViewEvents', JSON.stringify(this.pageViewEvents));
          formData.append('type', 'load');
          this.postData(formData);
          this.updateLvpst();
          break;
        case 'library_view':
          let key = this.getLibraryViewKey(eventData.id, eventData.first_action);
          let libraryViewEvent = this.libraryViewEvents[key];
          if (!libraryViewEvent) { // Create a new event
            this.libraryViewEvents[key] = Object.assign(event, {
              'id': eventData.id,
              'first_action': eventData.first_action,
              'last_action': eventData.first_action,
              'scroll_depth': eventData.scroll_depth,
              'open': eventData.open,
            });
          } else { // Update existing event
            this.libraryViewEvents[key].open = eventData.open;
            this.libraryViewEvents[key].last_action = eventData.last_action;
            this.libraryViewEvents[key].scroll_depth = Math.max(this.libraryViewEvents[key].scroll_depth, eventData.scroll_depth);
          }
          delete event.timestamp;

          // Since we can't post data when someone closes the tab in Mobile Safari
          // we'll post library view events when first created and when closed
          if (this.isMobileSafari && (!libraryViewEvent || this.libraryViewEvents[key].open == false)) {
            const formData = new FormData();
            formData.append('libraryViewEvents', JSON.stringify(this.libraryViewEvents));
            formData.append('type', 'safari')
            this.postData(formData);
          }
          break;
        case 'library_listen':
          let libraryListenEvent = this.libraryListenEvents[eventData.eventKey];
          if (!libraryListenEvent) { // Create a new event
            this.libraryListenEvents[eventData.eventKey] = {
              state: {
                totalSeconds: eventData.totalSeconds,
                segments: [],
                isOpen: true,
              },
              payload: {
                id: eventData.libraryID,
                first_action: eventData.firstAction,
                last_action: eventData.firstAction,
                listen_percent: 0,
              }
            };
          } else { // Update existing event
            libraryListenEvent.payload.last_action = eventData.lastAction;
            this.updateLibraryListenPercent(libraryListenEvent, eventData.segment);
            libraryListenEvent.state.isOpen = eventData.isOpen == false ? false : true;
          }
          break;
        case 'library_click':
          this.libraryClickEvents.push(eventData);
      }
    }

    static unload() {
      // Flag data as sent to prevent duplicates being fired
      // Chrome likes to fire two events the tab is closed
      dataSent = true;

      // Early exit if there is nothing to process
      if (this.pageViewEvents.length +
        Object.keys(this.libraryViewEvents).length === 0 +
        Object.keys(this.libraryListenEvents).length === 0 +
        Object.keys(this.libraryClickEvents).length === 0)
        return;

      // Update any pending events
      const unloadTime = new Date().toISOString();
      this.updatePageViewEventsBeforeUnload(unloadTime);
      this.updateLibraryViewEventsBeforeUnload(unloadTime);
      this.updateLibraryListenEventsBeforeUnload(unloadTime);

      // Post the data
      const formData = new FormData();
      formData.append('pageViewEvents', JSON.stringify(this.pageViewEvents));
      formData.append('libraryViewEvents', JSON.stringify(this.libraryViewEvents));
      formData.append('libraryListenEvents', JSON.stringify(Object.values(this.libraryListenEvents).map(e=>e.payload)));
      formData.append('libraryClickEvents', JSON.stringify(this.libraryClickEvents));
      formData.append('type', 'unload');
      this.postData(formData);

      // Clean up finalized events
      this.cleanPageViewEvents();
      this.cleanLibraryViewEvents();
      this.cleanLibraryListenEvents();
      this.cleanLibraryClickEvents();
    }

    static postData(formData) {
      if (navigator.sendBeacon) { // Prefer Beacon API where available
        navigator.sendBeacon(this.ENDPOINT, formData);
      } else { // Fallback to a post request with keepalive
        fetch(this.ENDPOINT, {
          method: 'post',
          body: formData,
          keepalive: true
        });
      }
    }
  }

  // Create a global reference
  window.gfa = GrainFoxAnalytics;

  // Load cached data from sessionStorage
  gfa.loadPageViewEvents();

  // Safari doesn't fire a visibilitychange event when you navigate to a new page
  // To capture the user data as they unload the page, we listen to the paghide event instead
  window.addEventListener('pagehide', function () {
    gfa.unload();
  });

  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden' && dataSent === false) {
      gfa.unload();
    }

    if (document.visibilityState === 'visible') {
      // Reset data transmission flag
      if (dataSent === true) {
        dataSent = false;
      }

      // Store a "last-viewed-page-start-time" value in localStorage
      // This gets used when someone logs out, to correctly identify
      // which page they logged out from (no other way to reliably figure this out)
      gfa.updateLvpst();

      // Handle the case where the page was loaded while hidden
      // This can happen when restoring a tab, starting up the browser with default tabs, etc.
      // We wait until the page becomes visible to create a new page view event
      if (pageLoaded === false) {
        gfa.send('page_view');
      }
    }
  });

  // Handle the case where the page was loaded while the page was visible
  if (document.visibilityState == 'visible' && pageLoaded === false) {
    gfa.send('page_view');
  }

  /* Notes */
  // There is currently no way to detect tab close in Mobile Safari
  // https://bugs.webkit.org/show_bug.cgi?id=199854
  // https://bugs.webkit.org/show_bug.cgi?id=151610

  /* Todo */
  // Break the send() method into more manageable functions
  // Pass event key for library view events, instead of constructing it
  // Standardize new object format, with nested payload and state objects
    // Payload: Data that maps back to an analytics event model
      // Properties should use Python variable naming convention e.g. last_action
    // State: Used for intermediary data storage and calculation of payload data
      // Properties should use JavaScript variable naming convention e.g. totalSeconds
})();
