import { postRequest } from '../../../helpers/request';

export default {
  state: () => ({
    factors: [],
    commodityFactorMapping: [],
  }),

  getters: {
    getCommodityFactors: (state) => (commodityId) => {
      const factorIds = state.commodityFactorMapping[commodityId];
      return factorIds.map((id) => state.factors[id]);
    },
  },

  mutations: {
    setFactors(state, factors) {
      state.factors = factors;
    },
    setCommodityFactorMapping(state, commodityFactorMapping) {
      state.commodityFactorMapping = commodityFactorMapping;
    },
  },

  actions: {
    async fetchFactors({ commit }) {
      const { results: factors } = await postRequest('/bids/api/get_factors/');

      commit('setFactors', factors);
    },

    async fetchCommodityFactorMapping({ commit }) {
      const { results: factorCommodityMapping } = await postRequest(
        '/bids/api/get_commodity_factor_mapping/',
      );

      commit('setCommodityFactorMapping', factorCommodityMapping);
    },
  },

  namespaced: true,
};
