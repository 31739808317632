<template>
  <!-- Breadcrumbs -->
  <span>
    <span
      v-for="p in path"
      :key="p.path"
    >
      <v-btn
        class="not-uppercase remove-opacity"
        plain
        text
        :ripple="false"
        :disabled="p.isLast"
        @click="goToRoute(p)"
      >
        <span
          class="text-body-2"
          :class="{
            'text--primary font-weight-bold': p.isLast,
            'text-decoration-underline': !p.isLast
          }"
        >
          {{ p.label }}
        </span>
      </v-btn>
      <span
        v-if="!p.isLast"
      >
        <v-icon
          color="primary"
        >
          mdi-chevron-right
        </v-icon>
      </span>
    </span>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FarmProfileBreadcrumbs',
  data() {
    return {
      contractId: null,
      deliveryId: null,
      parentContractId: null,
      storageId: null,
      path: [],
    };
  },
  computed: {
    ...mapState('farmProfile/deliveries', ['deliveries']),
    ...mapState('farmProfile/inventory', ['binGroups', 'bins']),
    ...mapState('farmProfile/sales', ['contractsIdMapping']),
    ...mapState('shared', ['commodities']),
    contractIdentifier() {
      return this.contractsIdMapping[this.contractId]?.contract_identifier;
    },
    parentContractIdentifier() {
      return this.contractsIdMapping[this.parentContractId]?.contract_identifier;
    },
    deliveryIdentifier() {
      return Object.values(this.deliveries).find((d) => d.id === this.deliveryId)?.delivery_identifier;
    },
    storageInfo() {
      if (this.storageId) {
        const storage = Object.values(this.bins).find((b) => b.id === this.storageId);
        const storageGroup = this.binGroups[storage.bin_group_id];
        return `${storage.name} (${storageGroup.name})`;
      }
      return '';
    },
    commodityName() {
      const commodityId = Object.values(this.deliveries)
        .find((d) => d.id === this.deliveryId)?.grade?.commodity_id;
      return this.commodities[commodityId]?.name;
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.updatePath();
      },
    },
  },
  mounted() {
    this.updatePath();
  },
  methods: {
    ...mapActions('farmProfile/sales', [
      'fetchContractsIdMapping',
    ]),
    goToRoute(p) {
      if (p.name === 'FarmProfileSalesContract' || p.name === 'FarmProfileCommoditiesContract') {
        const id = p.parent ? this.parentContractId : this.contractId;
        return this.$router.push({ name: p.name, params: { contractId: id } });
      }
      if (p.name === 'FarmProfileSalesContractDelivery' || p.name === 'FarmProfileCommoditiesContractDelivery') {
        return this.$router.push(
          { name: p.name, params: { contractId: this.contractId, deliveryId: this.deliveryId } },
        );
      }
      if (p.name === 'FarmProfileInventoryStorage') {
        return this.$router.push({ name: p.name, params: { storageId: this.storageId } });
      }
      return this.$router.push({ path: p.path });
    },
    addExtraInfo(name, parent = false) {
      if (name === 'FarmProfileSalesContract' || name === 'FarmProfileCommoditiesContract') {
        return parent ? `${this.parentContractIdentifier}` : `${this.contractIdentifier}`;
      }
      if (name === 'FarmProfileSalesContractDelivery' || name === 'FarmProfileCommoditiesContractDelivery') {
        return `${this.commodityName} ${this.deliveryIdentifier}`;
      }
      if (name === 'FarmProfileInventoryStorage') {
        return `${this.storageInfo}`;
      }
      return '';
    },
    async updatePath() {
      this.contractId = +(this.$router.currentRoute.params?.contractId ?? 0);
      this.deliveryId = +(this.$router.currentRoute.params?.deliveryId ?? 0);
      this.storageId = +(this.$router.currentRoute.params?.storageId ?? 0);
      this.path = [];
      if (this.contractId) {
        await this.fetchContractsIdMapping({ id: this.contractId });
      }
      this.parentContractId = this.contractsIdMapping[this.contractId]?.parent;
      const routeMatched = this.$router.currentRoute.matched.slice(1);
      const lastIndex = routeMatched.length - 1;
      routeMatched.forEach((cr, index) => {
        // if contractId has parentContractId, add parentContract information to path
        if (cr.name === 'FarmProfileSalesContract' && this.parentContractId) {
          this.path.push({
            name: cr.name,
            label: `${cr.meta.title} ${this.addExtraInfo(cr.name, true)}`,
            isLast: false,
            parent: true,
          });
        }
        this.path.push({
          name: cr.name,
          label: `${cr.meta.title} ${this.addExtraInfo(cr.name)}`,
          path: cr.path,
          isLast: lastIndex === index,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/colors/colors';
.not-uppercase {
  text-transform: none !important;
}
.remove-opacity::v-deep .v-btn__content{
  opacity: 1 !important;
}
</style>
