import {
  createCashFlow,
  copyCashFlow,
  deleteCashFlow,
  fetchCashFlowType,
  fetchCashFlowCategories,
  createCashFlowCategory,
  updateCashFlowCategory,
  deleteCashFlowCategory,
  fetchCashFlows,
  fetchCashFlowExpenses,
  createCashFlowExpense,
  createBulkCashFlowExpenses,
  deleteCashFlowExpense,
  updateCashFlow,
  getOrCreateCashFlowOnboardingProgress,
  updateExpensesFromContractChanges,
  updateOrCreateCashFlowOnboardingProgress,
  remindMeLater,
  apiGetAllCashFlowsClosingBalance,
} from '../../api/cashflow';

import COLOR_PALETTE from '../../config/palette';

import farm_profile from './farm_profile/farmProfile';

export default {
  namespaced: true,
  state: () => ({
    showCashFlowDetail: true,
    categories: [],
    categoriesType: [],
    userCashFlows: [],
    allCategories: [],
    // Expected data format for cashflowexpenses: { category_insights: [2], monthly_insights: Object, name: "CashFlow from Aug 22 to Jul 23", opening_balance: 100000 },
    cashFlowExpenses: [],
    cashFlowOpenStatus: {},
    cashFlowOpenBalance: [],
    cashFlowClosingBalanceArray: [],
    cashFlowTable: [],
    cashFlowMonthly: [],
    monthsShort: {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    },
    monthsLong: {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sep: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    },
    emptyToggleStatus: false,
    cashFlowColors: [],
    mini: false,
    monthlyData: {},
    inflowCategory: [],
    outflowCategory: [],
    isCurrentMonth: true,
    cellInput: 0,
    drawerType: '',
    filteredCategories: [],
    selectedCategoryType: 1,
    cashFlowChangesConfirmationPopupVisible: false,
    cashFlowChangesConfirmationPopupData: [],
    cashFlowDatatoBeUpdated: {},
    allCashFlowsClosingBalanceArray: {},
    cashFlowActiveMonthIndex: 0, // index of the month in the bar chart
    cashFlowBarChartScrollTo: 0, // scroll to the active month in the bar chart
    cashFlowExpectedPaymentDateDialogVisible: false,
    cashFlowFarmProfileConnectionFailedDialogVisible: false,
    selectedCashFlow: {},
    contractsWithoutExpectedPayout: {},
    newCashFlowChanges: {
      price: '',
      is_farm_profile_connected: false,
      is_roi_connected: false,
      start_month: '',
      end_month: '',
    },
    changedContractsAndDeliveries: [],
    showFPUpdateDialog: false,
    cashFlowOnBoardingSuccessDialogVisible: false,
    cashFlowOnboardingData: {},
    cashFlowOnboardingOrder: [
      'first_visit',
      'create_category',
      'click_cell',
      'select_recurring',
      'copy_spread_step',
      'other_time_interval',
      'hover_graph',
      'select_category',
      'edit_category',
      'select_month',
      'create_new_cf',
      'switch_between_cf',
      'copy_cf',
      'export_cf',
      'delete_cf',
      'cf_settings',
    ],
    cashFlowOnboardingStepIndex: 0,
    cashFlowEditRecurringTransactionDialogVisible: false,
  }),
  mutations: {
    setCashFlowColors(state, payload) {
      state.cashFlowColors = payload;
    },
    setDrawerType(state, payload) {
      state.drawerType = payload;
    },
    setCollapsibleDrawerStatus(state, payload) {
      state.mini = payload;
    },
    setMonthlyDrawerData(state, payload) {
      state.monthlyData = payload;
    },
    setCashFlowMonthly(state, payload) {
      state.cashFlowMonthly = payload;
    },
    setEmptyToggleStatus(state, payload) {
      state.emptyToggleStatus = payload;
    },
    setCashFlowType(state, payload) {
      state.categoriesType = payload;
    },
    setUserCashFlows(state, payload) {
      state.userCashFlows = payload;
    },
    cashFlowCategories(state, payload) {
      state.categories = payload;
    },
    cashFlowAllCategories(state, payload) {
      state.allCategories = payload;
    },
    setCashFlowExpenses(state, payload) {
      state.cashFlowExpenses = payload;
    },
    setCashFlowGridOpenStatus(state, payload) {
      state.cashFlowOpenStatus = payload;
    },
    setCashFlowOpeningBalance(state, payload) {
      state.cashFlowOpenBalance = payload;
    },
    setCashFlowClosingBalance(state, payload) {
      state.cashFlowClosingBalanceArray = payload;
    },
    setCashFlowTable(state, payload) {
      state.cashFlowTable = payload;
    },
    setCashFlowDetail(state, payload) {
      state.showCashFlowDetail = payload;
    },
    setIsCurrentMonth(state, payload) {
      state.isCurrentMonth = payload;
    },
    setCellInput(state, payload) {
      let data = payload;
      if (payload === '') {
        data = '0';
      }
      state.cellInput = data;
    },
    setSelectedCategoryType(state, payload) {
      state.selectedCategoryType = payload;
    },
    setFilteredCategories(state, payload) {
      state.filteredCategories = payload;
    },
    setCashFlowChangesConfirmationPopupVisible(state, payload) {
      state.cashFlowChangesConfirmationPopupVisible = payload;
    },
    setCashFlowChangesConfirmationPopupData(state, payload) {
      state.cashFlowChangesConfirmationPopupData = payload;
    },
    setCashFlowDatatoBeUpdated(state, payload) {
      state.cashFlowDatatoBeUpdated = payload;
    },
    setAllCashFlowsClosingBalanceArray(state, payload) {
      state.allCashFlowsClosingBalanceArray = payload;
    },
    setCashFlowActiveMonthIndex(state, payload) {
      state.cashFlowActiveMonthIndex = payload;
    },
    setCashFlowBarChartScrollTo(state, payload) {
      state.cashFlowBarChartScrollTo = payload;
    },
    setCashFlowExpectedPaymentDateDialogVisible(state, payload) {
      state.cashFlowExpectedPaymentDateDialogVisible = payload;
    },
    setCashFlowFarmProfileConnectionFailedDialogVisible(state, payload) {
      state.cashFlowFarmProfileConnectionFailedDialogVisible = payload;
    },
    setSelectedCashFlow(state, payload) {
      state.selectedCashFlow = payload;
    },
    setContractsWithoutExpectedPayout(state, payload) {
      state.contractsWithoutExpectedPayout = payload;
    },
    setNewCashFlowChanges(state, payload) {
      state.newCashFlowChanges = payload;
    },
    setChangedContractsAndDeliveries(state, payload) {
      state.changedContractsAndDeliveries = payload;
    },
    setShowFPUpdateDialog(state, payload) {
      state.showFPUpdateDialog = payload;
    },
    setChangesAsShown(state) {
      state.changedContractsAndDeliveries.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.shown = true;
      });
    },
    setCashFlowOnBoardingSuccessDialogVisible(state, payload) {
      state.cashFlowOnBoardingSuccessDialogVisible = payload;
    },
    setCashFlowOnboardingData(state, payload) {
      state.cashFlowOnboardingData = payload;
      state.cashFlowOnboardingStepIndex = state.cashFlowOnboardingOrder.findIndex((step) => !payload[step]);
    },
    setCashFlowEditRecurringTransactionDialogVisible(state, payload) {
      state.cashFlowEditRecurringTransactionDialogVisible = payload;
    },
  },

  getters: {
    isCashFlowEmpty(state) {
      return state.userCashFlows.length === 0;
    },
    hasNewCashFlowChanges(state) {
      const {
        price,
        is_farm_profile_connected,
        is_roi_connected,
        start_month,
        end_month,
      } = state.newCashFlowChanges;
      return price !== '' || is_farm_profile_connected || is_roi_connected || start_month !== '' || end_month !== '';
    },
    isCurrentCashFlowOnboardingStep: (state) => (step) => state.cashFlowOnboardingOrder[state.cashFlowOnboardingStepIndex] === step,
    isFarmProfileConnected(state) {
      return state.selectedCashFlow.is_farm_profile_connected;
    },
    hasFarmProfileChanges(state) {
      return state.changedContractsAndDeliveries.length > 0;
    },
    newUpdates(state) {
      // filter out updates that shown is true
      return state.changedContractsAndDeliveries.filter((item) => !item.shown);
    },
    shownUpdates(state) {
      // filter out updates that shown is false
      return state.changedContractsAndDeliveries.filter((item) => item.shown);
    },
    hasNewUpdates(state, getters) {
      return getters.newUpdates.length > 0;
    },
  },
  actions: {
    updateCashFlowColors({ commit, state }, payload) {
      payload.forEach((item) => {
        if (state.cashFlowColors.length < payload.length && item.colors === undefined) {
          const gap = payload.length - state.cashFlowColors.length;
          for (let i = 0; i < gap; i += 1) {
            state.cashFlowColors.push(COLOR_PALETTE[i]);
          }
        } else {
          state.cashFlowColors = COLOR_PALETTE;
        }
      });
      commit('setCashFlowColors', state.cashFlowColors);
    },
    setIsCurrentMonth({ commit }, payload) {
      commit('onSetIsCurrentMonth', payload);
    },
    setCashFlowOpenStatus({ commit }, payload) {
      commit('setCashFlowGridOpenStatus', payload);
    },
    setCollapsibleDrawer({ commit }, payload) {
      commit('setCollapsibleDrawerStatus', payload);
    },
    setMonthlyData({ commit, state }, payload) {
      state.inflowCategory = [];
      state.outflowCategory = [];
      state.cashFlowExpenses.category_insights.forEach((element) => {
        (element.child).forEach((childElement) => {
          (childElement.expense).forEach((exp) => {
            if (exp.name === payload.name && exp.price !== 0) {
              if (childElement.isInflow) {
                state.inflowCategory.push({ name: childElement.name, price: exp.price });
              } else {
                state.outflowCategory.push({ name: childElement.name, price: exp.price });
              }
            }
          });
        });
      });
      const inflowData = [];
      state.inflowCategory.forEach((item) => {
        inflowData.push({
          label: item.name,
          value: item.price,
        });
      });
      const inflowGraphData = { data: inflowData };

      const outflowData = [];
      state.outflowCategory.forEach((item) => {
        outflowData.push({
          label: item.name,
          value: item.price,
        });
      });
      const outflowGraphData = { data: outflowData };

      commit('setMonthlyDrawerData', { ...payload, inflowGraphData, outflowGraphData });
    },
    async getCashFlowExpense({ commit, dispatch, state }, payload) {
      try {
        const results = await fetchCashFlowExpenses({ id: payload });
        results.category_insights.map((item) => {
          dispatch('updateCashFlowColors', item.child);
          const aPos = item;
          aPos.open = item.id === state.cashFlowOpenStatus.id && state.cashFlowOpenStatus.status ? state.cashFlowOpenStatus.status : true;
          aPos.child.map((itemChild, i) => {
            const itemPos = itemChild;
            itemPos.show = true;
            itemPos.colors = state.cashFlowColors[i];
            const buildCustomArr = [];
            aPos.isInflow = itemPos.isInflow;
            Object.entries(itemPos.expense).forEach(([key, value]) => {
              buildCustomArr.push({
                name: key,
                price: value,
                catId: itemPos.id,
                label: itemPos.name,
              });
            });
            itemPos.expense = buildCustomArr;
            return itemPos;
          });
          return aPos;
        });
        commit('setCashFlowExpenses', results);
        dispatch('cashFlowClosingBalance');
        dispatch('cashFlowMonthlyArray', results && results.monthly_insights);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    cashFlowMonthlyArray({ commit, state }, payload) {
      const arr = [];
      Object.entries(payload).forEach(([key, value], index) => {
        const date = new Date(value.year, state.monthsShort[key] - 1, 10);
        const fullMonth = date.toLocaleString('default', { month: 'long' });
        arr.push({
          id: index + 1,
          month: key,
          year: value.year.trim(),
          fullMonth,
        });
      });
      commit('setCashFlowMonthly', arr);
    },
    cashFlowOpeningBalance({ commit, state }, payload) {
      const arr = [];
      const result = [];
      payload.forEach((value, index) => {
        if (index === 0) {
          arr.push({ price: state.cashFlowExpenses.opening_balance });
          arr.push({ price: value.price });
        } else {
          arr.push({ price: value.price });
        }
      });
      const newArr = [...arr];
      newArr.splice(-1);
      payload.forEach((p, index) => {
        newArr.forEach((r, ind) => {
          if (index === ind) {
            result.push({
              price: r.price,
              month: p.month,
            });
          }
        });
      });
      commit('setCashFlowOpeningBalance', result);
    },
    cashFlowClosingBalance({ commit, dispatch, state }) {
      const data = state.cashFlowExpenses.monthly_insights;
      const arr = [];
      Object.entries(data).forEach(([key, value], index) => {
        if (index === 0) {
          arr.push({
            price: ((state.cashFlowExpenses.opening_balance + value.cash_inflow) - value.cash_outflow),
            month: key,
          });
        } else {
          arr.push({
            price: (arr[index - 1].price + value.cash_inflow - value.cash_outflow),
            month: key,
          });
        }
      });
      commit('setCashFlowClosingBalance', arr);
      dispatch('cashFlowOpeningBalance', arr);
    },
    cashFlowEmptyRowStatus({ commit, state }, payload) {
      commit('setEmptyToggleStatus', payload);
      if (payload) {
        state.cashFlowExpenses.category_insights.map((item) => {
          // type is inflow or outflow
          const type = item;
          type.open = item.id === state.cashFlowOpenStatus.id && state.cashFlowOpenStatus.status ? state.cashFlowOpenStatus.status : true;
          type.child.map((itemCategory) => {
            const category = itemCategory;
            category.show = false;
            if (category.children.length > 0) {
              let childExpenseSum = 0;
              category.children.map((child) => {
                child.expense.map((expense) => {
                  childExpenseSum += Number(expense.price);
                  return expense;
                });
                if (childExpenseSum > 0) {
                  category.show = true;
                }
                return child;
              });
            } else {
              let childExpenseSum = 0;
              category.expense.map((expense) => {
                childExpenseSum += Number(expense.price);
                return expense;
              });
              if (childExpenseSum > 0) {
                category.show = true;
              }
            }
            return category;
          });
          return type;
        });
        commit('setCashFlowExpenses', state.cashFlowExpenses);
      } else {
        state.cashFlowExpenses.category_insights.map((item) => {
          const aPos = item;
          aPos.open = item.id === state.cashFlowOpenStatus.id && state.cashFlowOpenStatus.status ? state.cashFlowOpenStatus.status : true;
          aPos.child.map((itemChild) => {
            const itemPos = itemChild;
            itemPos.show = true;
            return itemPos;
          });
          return aPos;
        });
        commit('setCashFlowExpenses', state.cashFlowExpenses);
      }
    },
    async getAllCashFlowsClosingBalance({ commit }, payload) {
      const ids = payload.map((cashFlow) => cashFlow.id);
      const results = await apiGetAllCashFlowsClosingBalance(ids);

      commit('setAllCashFlowsClosingBalanceArray', results);
    },
    async createCashFlowExpenses({ dispatch }, payload) {
      try {
        await createCashFlowExpense(payload);
        dispatch('getCashFlowExpense', payload.cash_flow);
        if (typeof payload.delete === 'undefined' && Number(payload.balance) !== 0) {
          this._vm.$snackbar.success('Cash flow expense created successfully');
        }
      } catch (e) {
        dispatch('getCashFlowExpense', payload.cash_flow);
        this._vm.$snackbar.error(e);
      }
    },
    async createBulkCashFlowExpenses({ dispatch }, payload) {
      try {
        await createBulkCashFlowExpenses(payload);
        dispatch('getCashFlowExpense', payload.cash_flow);
        if (Number(payload.value) !== 0) {
          this._vm.$snackbar.success('Cash Flow Data Added Successfully');
        }
      } catch (e) {
        dispatch('getCashFlowExpense', payload.cash_flow);
        this._vm.$snackbar.error(e);
      }
    },
    async getUserCashFlows({ commit, dispatch }) {
      try {
        const results = await fetchCashFlows();
        commit('setUserCashFlows', results);
        dispatch('getAllCashFlowsClosingBalance', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async getCashFlowTable({ commit, state }) {
      if (state.userCashFlows.length > 0) {
        const resultsType = await fetchCashFlowType();
        const resultsCategories = await fetchCashFlowCategories();
        if (resultsType && resultsCategories) {
          resultsType.map((item) => {
            const type = item;
            type.child = [];
            resultsCategories.map((child) => {
              const cat = child;
              if (cat.type === type.id) {
                type.child.push(cat);
              }
              return child;
            });
            return type;
          });
          commit('setCashFlowTable', resultsType);
        }
      }
    },
    async fetchFarmProfileConnectedContracts({
      commit, dispatch,
    }, payload) {
      const contracts_to_update = await dispatch(
        'farmProfile/fetchSalesContractsWithoutExpectedPayoutDateByCashFlow',
        payload,
        { root: true },
      );

      if (contracts_to_update?.contracts.length > 0) {
        commit('setCashFlowDatatoBeUpdated', payload);
        commit('setContractsWithoutExpectedPayout', contracts_to_update.contracts);
        commit('setCashFlowExpectedPaymentDateDialogVisible', true);
      }
    },
    async createCashFlowSetup({
      commit, dispatch, state, rootState, getters,
    }, payload) {
      const start = `${payload.start_month}-01`;
      const dateobjA = new Date(start);
      const startMonth = dateobjA.toISOString();
      const end = `${payload.end_month}-01`;
      const dateobjB = new Date(end);
      const endMonth = dateobjB.toISOString();
      commit('setNewCashFlowChanges', payload);
      try {
        if (payload.is_farm_profile_connected) {
          await dispatch(
            'fetchFarmProfileConnectedContracts',
            { start_month: startMonth, end_month: endMonth },
          );
        }
        const results = await createCashFlow({
          opening_balance: parseFloat(payload.price),
          start_month: startMonth,
          end_month: endMonth,
          is_roi_connected: payload.is_roi_connected,
          is_farm_profile_connected: payload.is_farm_profile_connected,
        });
        if (getters.isCurrentCashFlowOnboardingStep('first_visit') && window.matchMedia('(min-width: 1024px)').matches
        && rootState.launchDarkly.flags['cash-flow-onboarding']) {
          await commit('setCashFlowOnBoardingSuccessDialogVisible', true);
        } else {
          this._vm.$snackbar.success('Cash flow setup created successfully');
        }
        if (rootState.launchDarkly.flags['cash-flow-onboarding']) {
          dispatch('updateOrCreateCashFlowOnboardingProgress', { ...state.cashFlowOnboardingData, first_visit: true });
        }
        dispatch('getUserCashFlows');
        commit('setSelectedCashFlow', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setNewCashFlowChanges', {
          price: '',
          start_month: '',
          end_month: '',
          is_roi_connected: false,
          is_farm_profile_connected: false,
        });
      }
    },
    async copyCashFlow({
      commit, dispatch, state, rootState, getters,
    }, payload) {
      const start = `${payload.start_month}-01`;
      const dateobjA = new Date(start);
      const startMonth = dateobjA.toISOString();
      const end = `${payload.end_month}-01`;
      const dateobjB = new Date(end);
      const endMonth = dateobjB.toISOString();

      commit('setNewCashFlowChanges', payload);
      try {
        if (payload.is_farm_profile_connected) {
          await dispatch(
            'fetchFarmProfileConnectedContracts',
            { start_month: startMonth, end_month: endMonth },
          );
        }

        const results = await copyCashFlow({
          opening_balance: parseFloat(payload.price),
          start_month: startMonth,
          end_month: endMonth,
          is_roi_connected: payload.is_roi_connected,
          is_farm_profile_connected: payload.is_farm_profile_connected,
          from_cash_flow: payload.from_id,
        });

        this._vm.$snackbar.success('Cash flow copied successfully');

        dispatch('getUserCashFlows');
        commit('setSelectedCashFlow', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setNewCashFlowChanges', {
          price: '',
          start_month: '',
          end_month: '',
          is_roi_connected: false,
          is_farm_profile_connected: false,
        });
      }
    },

    async deleteCashFlowAction({ dispatch, commit }, payload) {
      try {
        await deleteCashFlow(payload.id);
        await dispatch('cashflow/getUserCashFlows', null, { root: true });
        const results = await fetchCashFlows();
        if (results.length > 0) {
          const selectedCashFlow = results[0];
          commit('cashflow/setSelectedCashFlow', selectedCashFlow, { root: true });
        }
        const message = `Cash flow from ${payload.id.start_month} - ${payload.id.end_month} successfully deleted.`;
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getCashFlowType({ commit }) {
      try {
        const results = await fetchCashFlowType();
        commit('setCashFlowType', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async getCashFlowCategories({ commit, dispatch }, payload) {
      try {
        const result = await fetchCashFlowCategories(payload);
        commit('cashFlowAllCategories', result);
        dispatch('fetchMultilevelCategories', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    fetchMultilevelCategories({ commit }, payload) {
      const list = payload;
      const map = {};
      let node;
      const roots = [];
      let i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id) {
          // if you have dangling branches check that map[node.parentId] exists
          if (list[map[node.parent_id]]) {
            list[map[node.parent_id]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      commit('cashFlowCategories', roots || []);
      commit('setFilteredCategories', roots || []);
      return roots || [];
    },

    async createCashFlowCategory({ dispatch, state }, payload) {
      try {
        await createCashFlowCategory(payload);
        const cashFlowId = payload.cash_flow;
        dispatch('getCashFlowCategories', { id: cashFlowId });
        if (state.userCashFlows.length > 0) {
          dispatch('getCashFlowExpense', cashFlowId);
        }
        this._vm.$snackbar.success('Cash flow category created successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async updateCashFlowCategory({ dispatch, state }, payload) {
      try {
        await updateCashFlowCategory(payload);
        dispatch('getCashFlowCategories', { id: payload.cash_flow });
        if (state.userCashFlows.length > 0) {
          dispatch('getCashFlowExpense', payload.cash_flow);
        }
        this._vm.$snackbar.success('Cash flow category updated successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deleteCashFlowCategory({ dispatch, state }, payload) {
      try {
        await deleteCashFlowCategory(payload);
        dispatch('getCashFlowCategories', { id: payload.cash_flow });
        if (state.userCashFlows.length > 0) {
          dispatch('getCashFlowExpense', payload.cash_flow);
        }
        this._vm.$snackbar.success('Cash flow category deleted successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deleteCashFlowExpense({ dispatch }, payload) {
      try {
        const res = await deleteCashFlowExpense(payload);
        dispatch('getCashFlowExpense', payload.cash_flow);
        this._vm.$snackbar.success(res.message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async updateCashFlow({ dispatch, commit, state }, payload) {
      try {
        if (payload.is_farm_profile_connected) {
          const contracts_to_update = await dispatch(
            'farmProfile/fetchSalesContractsWithoutExpectedPayoutDateByCashFlow',
            {
              start_month: payload.start_month,
              end_month: payload.end_month,
            },
            { root: true },
          );
          if (contracts_to_update?.contracts.length > 0) {
            commit('setContractsWithoutExpectedPayout', contracts_to_update.contracts);
            commit('setCashFlowExpectedPaymentDateDialogVisible', true);
            return;
          }
        }
        const results = await updateCashFlow(payload);
        if (state.userCashFlows.length > 0) {
          dispatch('getCashFlowExpense', payload.id);
        }
        dispatch('getUserCashFlows');
        commit('setSelectedCashFlow', results);
        this._vm.$snackbar.success('Cash flow updated successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async updateExpensesFromContractChanges({ dispatch, commit, state }, payload) {
      try {
        await updateExpensesFromContractChanges(payload);
        this._vm.$snackbar.success('Updated successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async updateFarmProfileChanges({ commit }, payload) {
      const contractChanges = payload.contract_changes;
      const deletedContracts = payload.deleted_contracts;
      const deletedDeliveries = payload.deleted_deliveries;
      const deliveryChanges = payload.delivery_changes;
      const newContracts = payload.new_contracts;
      const newDeliveries = payload.new_deliveries;
      const updatedContracts = payload.updated_contracts;
      const updatedDeliveries = payload.updated_deliveries;
      const updates = [];
      if (contractChanges) {
        contractChanges.forEach((contractChange) => {
        // get the matching contract from updatedContracts
          const matchingContract = updatedContracts.find(
            (contract) => contract.id === contractChange.original_contract.id,
          );
          if (matchingContract) {
            const updateObject = {
              name: matchingContract.contract_identifier,
              type: 'Contract Changes',
              commodity: matchingContract.quality.grade.name,
              buyer: matchingContract.buyer.name,
              contract_id: matchingContract.id,
              shown: contractChange.shown,
              contract_changes_id: contractChange.id,
              inCashFlow: {
                expected_payment_date: contractChange.expected_payout_date,
                total_price: contractChange.total_price,
              },
              inFarmProfile: {
                expected_payment_date: matchingContract.expected_payout_date,
                total_price: matchingContract.total_price,
              },
            };
            updates.push(updateObject);
          }
        });
      }

      if (deliveryChanges) {
        deliveryChanges.forEach((deliveryChange) => {
        // get the matching contract from updatedContracts
          const matchingDelivery = updatedDeliveries.find(
            (delivery) => delivery.id === deliveryChange.original_delivery.id,
          );
          if (matchingDelivery) {
            const updateObject = {
              name: matchingDelivery.delivery_identifier,
              type: 'Delivery Changes',
              commodity: matchingDelivery.quality,
              buyer: matchingDelivery.buyer_name,
              shown: deliveryChange.shown,
              delivery_changes_id: deliveryChange.id,
              inCashFlow: {
                delivery_date: deliveryChange.delivery_date,
                total_price: deliveryChange.value,
              },
              inFarmProfile: {
                delivery_date: matchingDelivery.delivery_date,
                total_price: matchingDelivery.revenue,
              },
              delivery_id: matchingDelivery.id,
            };
            updates.push(updateObject);
          }
        });
      }

      if (newContracts) {
        newContracts.forEach((newContract) => {
          const matchingContractChange = contractChanges.find(
            (contractChange) => contractChange.original_contract.id === newContract.id,
          );
          if (matchingContractChange) {
            const updateObject = {
              name: newContract.contract_identifier,
              contract_id: newContract.id,
              contract_changes_id: matchingContractChange.id,
              type: 'New Contract',
              shown: matchingContractChange.shown,
              commodity: newContract.quality.grade.name,
              buyer: newContract.buyer.name,
              expected_payment_date: newContract.expected_payout_date,
              total_price: newContract.total_price,
            };
            updates.push(updateObject);
          }
        });
      }

      if (newDeliveries) {
        newDeliveries.forEach((newDelivery) => {
          const matchingDeliveryChange = deliveryChanges.find(
            (deliveryChange) => deliveryChange.original_delivery.id === newDelivery.id,
          );
          if (matchingDeliveryChange) {
            const updateObject = {
              name: newDelivery.delivery_identifier,
              type: 'New Delivery',
              shown: matchingDeliveryChange.shown,
              commodity: newDelivery.grade.name,
              buyer: newDelivery.buyer_name,
              delivery_date: newDelivery.delivery_date,
              total_price: newDelivery.revenue,
              delivery_id: newDelivery.id,
              delivery_changes_id: matchingDeliveryChange.id,
            };
            updates.push(updateObject);
          }
        });
      }

      if (deletedDeliveries) {
        deletedDeliveries.forEach((deletedDelivery) => {
          const updateObject = {
            name: deletedDelivery.delivery_identifier,
            type: 'Delivery Deleted',
            shown: deletedDelivery.shown,
            commodity: deletedDelivery.quality,
            buyer: deletedDelivery.buyer_name,
            delivery_date: deletedDelivery.delivery_date,
            total_price: deletedDelivery.value,
            original_delivery_id: deletedDelivery.original_delivery_id,
            delivery_changes_id: deletedDelivery.id,
            original_contract_id: deletedDelivery.original_contract_id,
          };
          updates.push(updateObject);
        });
      }

      if (deletedContracts) {
        deletedContracts.forEach((deletedContract) => {
          const updateObject = {
            name: deletedContract.contract_identifier,
            type: 'Contract Deleted',
            shown: deletedContract.shown,
            contract_changes_id: deletedContract.id,
            commodity: deletedContract.quality,
            buyer: deletedContract.buyer_name,
            expected_payment_date: deletedContract.expected_payout_date,
            total_price: deletedContract.total_price,
            original_contract_id: deletedContract.original_contract_id,
          };
          updates.push(updateObject);
        });
      }
      // add index to each update
      updates.forEach((update, index) => {
        // eslint-disable-next-line no-param-reassign
        update.index = index;
      });
      commit('setChangedContractsAndDeliveries', updates);
    },
    async syncFarmProfileWithCashFlow({ commit, state, dispatch }, payload) {
      const cash_flow_id = state.selectedCashFlow.id;
      try {
        payload.forEach(async (update) => {
          const { index } = update;
          await dispatch('updateExpensesFromContractChanges', { cash_flow_id, entry: update });
          // remove update from list
          const updatedList = state.changedContractsAndDeliveries;
          updatedList.splice(index, 1);
          commit('setChangedContractsAndDeliveries', updatedList);
        });
        await dispatch('getCashFlowCategories', { id: cash_flow_id });
        await dispatch('getCashFlowExpense', cash_flow_id);
        this._vm.$snackbar.success('Cash flow updated successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async remindMeLater({ commit, state }) {
      const delivery_changes_ids = [];
      const contract_changes_ids = [];

      state.changedContractsAndDeliveries.forEach((update) => {
        if (update.type === 'Delivery Changes' || update.type === 'New Delivery' || update.type === 'Delivery Deleted') {
          delivery_changes_ids.push(update.delivery_changes_id);
        } else if (update.type === 'Contract Changes' || update.type === 'New Contract' || update.type === 'Contract Deleted') {
          contract_changes_ids.push(update.contract_changes_id);
        }
      });
      const payload = {
        delivery_changes_ids,
        contract_changes_ids,
      };

      try {
        const res = await remindMeLater(payload);
        commit('setShowFPUpdateDialog', false);
        commit('setChangesAsShown');
        this._vm.$snackbar.success(res.message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getOrCreateCashFlowOnboardingProgress({ commit }) {
      /**
       * gets or creates a cash flow onboarding progress for the user
       * @param {Object} payload with fields for CashFlowOnboardingProgress()
       * @returns {Object} CashFlowOnboardingProgress()
       * @throws {Error} if the request fails
        */
      try {
        const results = await getOrCreateCashFlowOnboardingProgress();
        commit('setCashFlowOnboardingData', results);
        return results;
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
      return null;
    },

    async updateOrCreateCashFlowOnboardingProgress({ commit }, payload) {
      /**
       * updates or creates a cash flow onboarding progress for the user
       * @param {Object} payload with fields for CashFlowOnboardingProgress()
       */
      try {
        const results = await updateOrCreateCashFlowOnboardingProgress(payload);
        commit('setCashFlowOnboardingData', results);
        return results;
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
      return null;
    },

    async handleCashFlowOnboardingToolTip({
      dispatch, commit, state, rootState,
    }, payload) {
      /**
       * handles the skip button on the cash flow onboarding tooltip
       * @param {Object} payload the fields to update
       */
      commit('setCashFlowOnboardingData', {
        ...state.cashFlowOnboardingData,
        ...payload,
      });
      if (rootState.launchDarkly.flags['cash-flow-onboarding']) {
        await dispatch('updateOrCreateCashFlowOnboardingProgress', state.cashFlowOnboardingData);
      }
    },

    async handleGetFPChanges({
      commit, state, dispatch, getters,
    }, payload) {
      const cashFlowId = payload.selectedCashFlowId;
      if (cashFlowId) {
        if (getters.isFarmProfileConnected) {
          const changes = await farm_profile.actions.fetchChangedContractsAndDeliveriesByCashFlow({ commit }, { id: cashFlowId });
          await dispatch('updateFarmProfileChanges', changes);
          if (getters.hasNewUpdates) {
            commit('setShowFPUpdateDialog', true);
          }
        }
      }
    },
  },
};
