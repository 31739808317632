<template>
  <v-hover v-slot="{ hover }">
    <div>
      <div class="d-flex align-center">
        <div
          class="d-flex align-center flex-grow-1 c-pointer"
          :class="{ 'mt-6': hasPaddingStyle}"
          @click="viewItem(id)"
        >
          <div
            class="thumbnail"
            :style="iconStyle"
          />
          <div class="ml-4">
            <p
              :class="contentLabelStyle"
              class="mb-2 font-weight-bold mb-0 content"
            >
              {{ content.toUpperCase() }}
            </p>
            <p
              class="font-weight-bold primary-grey--text mb-0 mt-0 text-h6"
              :class="{
                'text-subtitle-2 mr-4': $isMobile || $isTablet,
                'subheader-text': $isDesktop
              }"
            >
              {{ subheader.trim() }}
              <span class="inline-nowrap">&nbsp;
                <v-fade-transition>
                  <v-icon
                    v-visible="hover"
                    dense
                  >mdi-chevron-right</v-icon>
                </v-fade-transition>
              </span>
            </p>
            <p class="grey--text text--darken-1 mb-0 mt-2 timestamp">
              <span v-if="showProfilePic">
                <v-avatar
                  size="25"
                >
                  <v-img
                    :src="profilePicture"
                    alt="Writer Img"
                  />
                </v-avatar>
                <v-icon
                  color="grey darken-1"
                >
                  mdi-circle-small
                </v-icon>
              </span>
              {{ dateFormatShortMonthDateYear(date) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex';
import { dateFormatShortMonthDateYear } from '@/helpers/formatting';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    subheader: {
      type: String,
      required: false,
      default: '',
    },

    date: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    provider: {
      type: String,
      default: '',
    },

    content: {
      type: String,
      default: '',
    },

    audioFileFemale: {
      type: String,
      default: '',
    },

    audioFileMale: {
      type: String,
      default: '',
    },

    source: {
      type: String,
      default: '',
    },

    profilePicture: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('launchDarkly', ['flags']),

    showProfilePic() {
      return this.name !== 'Ag News Roundup' && (this.provider === 'FarmLink' || this.provider === 'DePutter');
    },

    iconStyle() {
      return this.icon.length > 0 ? `background-image: url(${this.icon})` : '';
    },

    contentLabelStyle() {
      return this.source === 'deputter' && this.flags['market-intelligence-plan'] ? 'deputter-content' : 'accent--text';
    },

    hasPaddingStyle() {
      return this.index > 0;
    },
    audioData() {
      if (this.audioFileFemale === '/media/'
      || this.audioFileMale === '/media/'
      || !this.audioFileFemale
      || !this.audioFileMale) {
        return null;
      }

      return {
        id: this.id,
        name: this.name,
        content: this.content,
        provider: this.provider,
        audio_file_female: this.audioFileFemale,
        audio_file_male: this.audioFileMale,
      };
    },
    displayTitle() {
      if (this.subheader) {
        return this.subheader;
      }
      if (this.name) {
        return this.name;
      }
      if (this.content) {
        return this.content;
      }
      return '';
    },
  },
  methods: {
    viewItem(id) {
      window.previewFile(id);
    },
    dateFormatShortMonthDateYear,
  },
};
</script>

<style lang="scss">
.thumbnail {
  width: 115px;
  height: 68px;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 2em;
  flex: 0 0 80px;
}
.content {
  font-size: 10px;
}
.timestamp {
  font-size: 12px;
}

.deputter-content {
  color: #769A3D !important;
  caret-color: #769A3D !important;
}

.subheader-text {
  line-height: 24px !important;
}
</style>
