import type { Plugin } from './types';
import type { WebSocketPayload } from '@/helpers/websocket';
import GrainFoxWebSocket from '@/helpers/websocket';

const WebSocketPlugin: Plugin = {
  install: (Vue, { store }): void => {
    if (!store) {
      throw new Error('Please provide vuex store');
    }

    function handleNewChatMessage(message: WebSocketPayload): void {
      store.dispatch('chat/onNewMessage', message);
      store.dispatch('chat/updateInteraction', message.conversation);
    }

    function handleWebsocketOpen(): void {
      store.commit('app/setIsWebsocketOpen', true);
    }

    function handleWebsocketClose(): void {
      store.commit('app/setIsWebsocketOpen', false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsubscribe = store.subscribe((mutation, state: any) => {
      if (mutation.type === 'user/setIsLoggedIn' && mutation.payload) {
        const isLocal = !state.app.isStaging && !state.app.isProduction;
        Vue.prototype.$gfws = new GrainFoxWebSocket(isLocal); // eslint-disable-line new-cap

        try {
          Vue.prototype.$gfws.registerCallback('chat_message', handleNewChatMessage);
          Vue.prototype.$gfws.registerCallback('on_websocket_open', handleWebsocketOpen);
          Vue.prototype.$gfws.registerCallback('on_websocket_close', handleWebsocketClose);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Error establishing websocket connection');
        }

        unsubscribe();
      }
    });
  },
};

export default WebSocketPlugin;
