import { postRequest, postFormRequest } from '@/helpers/request';

export async function getGMAClientReports(payload) {
  const data = await postRequest('/farm_profile/api/reports/get_gma_client_reports/', payload);
  return data;
}

export async function getGMABasisContracts(payload) {
  const data = await postRequest('/farm_profile/api/reports/get_gma_basis_contracts/', payload);
  return data;
}

export async function getGMAMonthlyInventoryLevels(payload) {
  const data = await postRequest('/farm_profile/api/reports/get_gma_monthly_inventory_levels/', payload);
  return data;
}

export async function getGMAActivityData(payload) {
  const data = await postRequest('/farm_advisor_module/get_activity/', payload);
  return data;
}

export async function getGMAValidSubscriptions(payload) {
  const data = await postRequest('/get_gma_valid_subscriptions/', payload);
  return data;
}

export async function getGMACommodityMap(payload) {
  const data = await postRequest('/gma_module/commodity_summary/', payload);
  return data;
}

export async function getGMAContracts(payload) {
  const data = await postRequest('/gma_module/get_contracts_of_interest/', payload);
  return data;
}

export async function getGMADeliveries(payload) {
  const data = await postRequest('/gma_module/get_deliveries_of_interest/', payload);
  return data;
}

export async function downloadGMAReportPDF(payload) {
  const data = await postRequest('/farm_profile/api/download_gma_report_pdf/', payload, {
    responseType: 'blob',
  });
  return data;
}

/**
 * Send a GMA Sales Summary email
 * @param {object} payload The payload to send
 * @param {string} payload.email_body The email text to send
 * @param {string} payload.subject The email subject
 * @param {number[]} payload.users The user IDS to send the email to
 * @param {string} payload.html The HTML report to send
 * @param {number} payload.subscription The subscription ID
 * @returns {Promise<{}>} The response object
 */
export async function sendGMASalesSummaryEmail(payload) {
  const data = await postRequest('/farm_profile/api/send_gma_sales_summary_email/', payload);
  return data;
}

export async function sendGMAChatMessage(payload) {
  const { data } = await postFormRequest('/farm_profile/api/send_gma_message_to_client/', payload);
  return data;
}
