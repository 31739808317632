<template>
  <div>
    <div class="d-flex nowrap-overflow">
      <div class="d-flex flex-column align-center">
        <div
          :class="timeStyle"
        >
          {{ time.days }}
        </div>
        <div
          class="px-1"
          :class="timeUnitStyle"
        >
          days
        </div>
      </div>
      <div class="px-1" />
      <div
        v-for="(unit,index) in timeUnits"
        :key="index"
        class="d-flex flex-row"
      >
        <div class="d-flex flex-column align-center">
          <div :class="timeStyle">
            {{ time[unit] }}
          </div>
          <div
            class="px-1"
            :class="timeUnitStyle"
          >
            {{ unit }}
          </div>
        </div>
        <div
          v-if="unit!='seconds'"
          class="mt-0"
          :class="timeStyle"
        >
          :
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimeDisplay',
  props: {
    time: {
      type: Object,
      required: true,
    },
    timeStyle: {
      type: String,
      default: '',
    },
    timeUnitStyle: {
      type: String,
      default: '',
    },
  },

  computed: {
    timeUnits() {
      return ['hours', 'minutes', 'seconds'];
    },
  },
};

</script>

<style scoped>
.nowrap-overflow {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}
</style>
