/* eslint-disable no-undef */
// import { postRequest, postFormRequest } from '../../../../frontend/helpers/request';
import { getFormData } from '@/helpers/request';

const API = {
  // Accounts API
  async activateUser(activationInfo) {
    const data = await postRequest('/account/', activationInfo);
    return data;
  },

  async updateUserProfileInfo(userInfo) {
    const data = await postRequest('/account/profile/update', userInfo);
    return data;
  },

  async updatePreferencesInfo(userInfo) {
    const data = await postRequest('/account/preferences/update', userInfo);
    return data;
  },

  async updateNotificationsInfo(userInfo) {
    const data = await postRequest('/account/notification_settings/update', userInfo);
    return data;
  },

  async readNotificationSources() {
    const data = await postRequest('/dashboard/api/read_notification_sources/');
    return data;
  },

  async shownSecuritySetPrompt() {
    await postRequest('/account/api/set_shown_security_questions/');
  },

  async shownPriceFinderPrompt() {
    await postRequest('/account/api/set_shown_price_finder_setup/');
  },

  async readSecurityPrompts() {
    const data = await postRequest('/account/request_security_questions/');
    return data;
  },

  async readOwnSecurityQuestions() {
    const data = await postRequest('/account/request_own_security_questions/');
    return data;
  },

  async setOwnSecurityQuestions(payload) {
    const body = { questions: payload };
    const data = await postRequest('/account/set_own_security_questions/', body);
    return data;
  },

  async verifySecurityQuestions(userId, questions, answers) {
    const body = {
      user_id: userId,
      questions,
      answers,
    };
    const data = await postRequest('/account/css_module/verify_users_security_questions/', body);
    return data;
  },

  async cssArtificialActivateUser(activationInfo) {
    const data = await postRequest('/account/css_module/css_artificial_activate/', activationInfo);
    return data;
  },

  // Subscription API

  async createSubscription() {
    const data = await postRequest('/dashboard/api/create_subscription/');
    return data.message;
  },

  async readSubscription() {
    const subscription = await postRequest('/dashboard/api/read_subscription/');
    return {
      id: subscription.id,
      name: subscription.name,
      isFarmer: subscription.is_farmer,
      farmingPeriod: subscription.farming_period,
      isProvider: subscription.is_provider,
      cropInterests: subscription.crop_interests,
      phone: subscription.phone_number,
      province: subscription.province,
      country: subscription.country,
      role: subscription.role,
      is_subscribed_to_deputter: subscription.is_subscribed_to_deputter,
      agribusinessSegment: subscription.agribusiness_segment,
      businessSize: subscription.business_size,
    };
  },

  async updateSubscription(subscription) {
    const body = {
      subscription_name: subscription.name,
      is_farmer: Boolean(subscription.isFarmer),
      farming_period: subscription.farmingPeriod,
      phone_number: subscription.phone,
      crop_interests: subscription.cropInterests.join(),
      province: subscription.province,
      country: subscription.country,
      agribusiness_segment: subscription.agribusinessSegment,
      business_size: subscription.businessSize,
    };
    const data = await postRequest('/dashboard/api/update_subscription/', body);
    return data.message;
  },

  async patchSubscription(body) {
    const data = await postRequest('/dashboard/api/update_subscription/', body);
    return data.message;
  },

  async deleteSubscription(subscriptionId) {
    const body = { subscription_id: subscriptionId };
    const data = await postRequest('/dashboard/api/delete_subscription/', body);
    return data.message;
  },

  async isMemberOfSubscription(subscriptionId, email) {
    const body = { subscription_id: subscriptionId, email };
    const data = await postRequest('/dashboard/api/is_member_of_subscription/', body);
    return data.isMember;
  },

  // Content API

  async createContent(content) {
    const data = await postFormRequest('/dashboard/api/create_content/', content);
    return data.message;
  },

  async updateContent(content) {
    const data = await postFormRequest('/dashboard/api/update_content/', content);
    return data.message;
  },

  async deleteContent(content) {
    const data = await postRequest('/dashboard/api/delete_content/', content);
    return data.message;
  },

  // Library API

  async favouriteLibrary(libraryID) {
    const body = { library_id: libraryID };
    const data = await postRequest('/dashboard/library/favourite/', body);
    return data.message;
  },

  async archiveLibrary(libraryID) {
    const body = { library_id: libraryID };
    const data = await postRequest('/dashboard/library/archive/', body);
    return data.message;
  },

  async shareLibrary(libraryID, email) {
    const body = { library_id: libraryID, email };
    const data = await postRequest('/dashboard/library/share/', body);
    return data.message;
  },

  async deleteLibrary(libraryID) {
    const body = { library_id: libraryID };
    const data = await postRequest('/dashboard/library/delete_file/', body);
    return data.message;
  },

  readRawLibrary(libraryID) {
    const body = { library_id: libraryID, is_raw: true };
    return postRequest('/dashboard/library/preview_file/', body);
  },

  previewLibrary(libraryID, isMobile) {
    const body = { library_id: libraryID, is_mobile: isMobile };
    return postRequest('/dashboard/library/preview_file/', body);
  },

  async queryLibrary(query) {
    const data = await postRequest('/dashboard/library/query/', query);
    return data;
  },

  async getFeaturedLibraryItem() {
    return postRequest('/library/api/get_featured_library_item/');
  },

  // Provider Applications API

  async readProviderApplications() {
    const data = await postRequest('/dashboard/api/read_provider_applications/');
    const providerApplications = data.provider_applications.map((application) => ({
      status: application.status,
      description: application.description,
      dateCreated: application.date_created,
      dateUpdated: application.date_updated,
    }));
    const canDoProviderApplication = data.can_do_provider_application;
    return {
      providerApplications,
      canDoProviderApplication,
    };
  },

  // Provider Application API

  async createProviderApplication(providerApplication) {
    const body = {
      description: providerApplication.description,
      notice_accepted: providerApplication.noticeAccepted,
    };
    const data = await postRequest('/dashboard/api/create_provider_application/', body);
    return data.message;
  },

  // Create Document API

  async createDocument(document) {
    const data = await postRequest('/library/api/create_document/', document);
    return data;
  },

  async updateDocument(document) {
    const data = await postRequest('/library/api/update_document/', document);
    return data;
  },

  async uploadDocument(document) {
    const data = await postFormRequest('/api/upload/', getFormData(document));
    return data;
  },

  async parseSnippets(document) {
    const data = await postRequest('/library/api/parse_snippets/', { html: document });
    return data;
  },

  // API endpoint for communicating with tasks worker

  async addRating(id, rating) {
    const body = { id, rating };
    const data = await postRequest('/library/api/add_rating/', body);
    return data.message;
  },

  // Farm Location

  async createFarmLocation(farmLocation) {
    const data = await postRequest('/dashboard/api/create_farm_location/', farmLocation);
    return data;
  },

  async updateFarmLocation(farmLocation) {
    const data = await postRequest('/dashboard/api/update_farm_location/', farmLocation);
    return data;
  },

  async deleteFarmLocation(id) {
    const body = { id };
    const data = await postRequest('/dashboard/api/delete_farm_location/', body);
    return data;
  },

  // Deleting/deactivating user accounts
  async deleteAccount() {
    const data = await postRequest('/account/delete/');
    return data.message;
  },

  // Updating information about user's active device in a session
  async updateSessionDevice(body) {
    const data = await postRequest('/api/update_session_device/', body);
    return data.message;
  },

  // Mark notification is "interacted"
  async markInteractedNotification(notification) {
    const data = await postRequest('/api/mark_interacted_notification/', { notification }, {
      csrfExempt: true,
    });
    return data.message;
  },

  // Mark ALL notification is "interacted"
  async markInteractedNotificationAll() {
    const data = await postRequest('/api/mark_interacted_notification_all/');
    return data.message;
  },

  // Mark notification as "dismissed"
  async dismissNotification(notification) {
    const data = await postRequest('/api/dismiss_notification/', { notification }, {
      csrfExempt: true,
    });
    return data.message;
  },

  // Search all messages
  async searchConversations(searchText) {
    const data = await postRequest('/messenger/chat_search/', { searchText });
    return data.results;
  },

  // Get all conversations
  async getConversations() {
    const data = await postRequest('/messenger/get_conversations/');
    return data.results;
  },

  // Get a specific conversation
  async getConversation(id) {
    const data = await postRequest('/messenger/get_conversation/', { id });
    return data.conversation;
  },

  // For fetching messages using different query
  async getConversationMessagesAdvance(
    conversation,
    number_offset = 5,
    time_offset = null,
  ) {
    const { results, more_messages_after: moreMessagesAfter, more } = await postRequest('/messenger/get_messages_advance/', {
      conversation,
      number_offset,
      time_offset,
    });
    return { results, moreMessagesAfter, more };
  },

  // Get last 10 messages per conversation
  async getConversationMessages(
    conversation,
    timestamp,
    lte = null,
  ) {
    const data = await postRequest('/messenger/get_messages/', {
      conversation,
      time_offset: timestamp,
      lte,
    });
    return { results: data.results, more: data.more };
  },

  // Send new message to some conversation
  async sendChatMessage(message) {
    const data = await postFormRequest('/messenger/send_new_message/', message);
    return data.message;
  },

  // Get the list of people in contact book
  async getContacts() {
    const data = await postRequest('/messenger/get_contacts/');
    return data.results;
  },

  // Get the list of people in contact book
  async getGMAContacts() {
    const data = await postRequest('/messenger/get_gma_contacts/');
    return data.results;
  },

  // Get the list of people in contact book
  async leaveConversation(conversationID) {
    const data = await postRequest('/messenger/leave_conversation/', {
      conversationID,
    });
    return data;
  },

  // Starts the conversation
  async createConversation(conversation) {
    const data = await postFormRequest('/messenger/create_conversation/', conversation);
    return data.conversation;
  },

  // Blocks a user
  async blockUser(user) {
    const data = await postFormRequest('/social/block_user/', user);
    return data.message;
  },

  // Expire invites of invited users to subscription
  async expireSubscriberInvite(invite) {
    const data = await postRequest('/dashboard/api/expire_subscriber_invite/', { invite });
    return data.message;
  },

  // Expire all pending email change transactions
  async expireEmailChangeTransaction() {
    const data = await postRequest('/account/expire_email_change/');
    return data.message;
  },

  // Get all commodities in the db
  async readCommoditiesAndProvinces() {
    const data = await postRequest('/bids/api/read_commodities_and_provinces/');
    return data;
  },

  async addMembersToConversation(formData) {
    const data = await postFormRequest('/messenger/add_members/', formData);
    return data;
  },

  // Accept chat request
  async acceptChatRequest(conversation) {
    const data = await postRequest('/messenger/accept_request/', { conversation });
    return data.message;
  },

  // Decline chat request
  async declineChatRequest(conversation) {
    const data = await postRequest('/messenger/deny_request/', { conversation });
    return data.message;
  },

  // Update conversation last interaction
  async updateInteraction(conversation) {
    const data = await postRequest('/messenger/update_interaction/', { conversation });
    return data.message;
  },

  // Endpoint for creating ECTs
  async createEmailChangeTransaction(email) {
    const data = await postRequest('/email_change/submit/', email);
    return data;
  },

  // Salesforce API

  async createSalesforceUser(userData) {
    const data = await postRequest('/salesforce/account_salesforce_instance/create/', userData);
    return data.message;
  },

  async updateSalesforceUser(userData) {
    const data = await postRequest('/salesforce/account_salesforce_instance/update/', userData);
    return data.message;
  },

  async getSalesforceUsers(options) {
    const data = await postRequest('/salesforce/get_paginated_salesforce_users/', options);
    return data;
  },

  async getNonSalesforceUsers(options) {
    const data = await postRequest('/salesforce/get_paginated_non_salesforce_users/', options);
    return data;
  },

  // farmProfile/inventory API
  async createStorageGroup({ binGroupName, farmLocationId, storageGroupType }) {
    const res = await postRequest('/farm_profile/api/create_bin_group/', {
      name: String(binGroupName),
      farm_location: Number(farmLocationId),
      bin_group_type: Number(storageGroupType),
    });
    return res;
  },

  async updateStorageGroup({
    id, binGroupName, farmLocationId, storageGroupType,
  }) {
    const res = await postRequest('/farm_profile/api/update_bin_group/', {
      id,
      name: String(binGroupName),
      farm_location: Number(farmLocationId),
      bin_group_type: Number(storageGroupType),
    });
    return res;
  },

  async deleteStorageGroup(options) {
    const res = await postRequest('/farm_profile/api/delete_bin_group/', options);
    return res;
  },

  async createStorage({
    name, capacity, filled, isUnallocated, binUnit,
    storageGroup, binStorageClassificationId, isFertilised,
    moisture, temperature, temperatureUnit, notes,
  }) {
    const res = await postRequest('/farm_profile/api/create_bin/', {
      name: String(name),
      capacity: Number(capacity),
      filled: Number(filled),
      is_unallocated: Boolean(isUnallocated),
      bin_unit: Number(binUnit),
      bin_group: Number(storageGroup),
      bin_storage_classification: Number(binStorageClassificationId),
      is_fertilised: isFertilised !== null ? Boolean(isFertilised) : null,
      moisture,
      temperature,
      temperature_unit: temperatureUnit,
      notes,
    });
    return res;
  },

  async editStorage({
    binId, name, capacity, filled, binUnit,
    gradeId, storageGroup, binStorageClassificationId, isFertilised,
    moisture, temperature, temperatureUnit, notes,
  }) {
    const res = await postRequest('/farm_profile/api/update_bin/', {
      id: Number(binId),
      name: String(name),
      capacity: Number(capacity),
      filled: Number(filled),
      bin_unit: Number(binUnit),
      grade: gradeId == null ? null : Number(gradeId),
      bin_group: Number(storageGroup),
      bin_storage_classification: Number(binStorageClassificationId),
      is_fertilised: isFertilised !== null ? Boolean(isFertilised) : null,
      moisture,
      temperature,
      temperature_unit: temperatureUnit,
      notes,
    });
    return res;
  },

  async fetchAllBinStorageClassifications() {
    const res = await postRequest('/farm_profile/api/fetch_all_bin_storage_classifications/');
    return res;
  },

  async fetchAllGrainBinTypes() {
    const res = await postRequest('/farm_profile/api/fetch_all_grain_bin_types/');
    return res;
  },

  async fetchAllBinGroupTypes() {
    const res = await postRequest('/farm_profile/api/fetch_all_bin_group_types/');
    return res;
  },

  // Farm Profile Sales API

  async getContract({ id, checkFullyDelivered = false }) {
    const data = await postRequest('/farm_profile/api/get_contract/', { id, check_fully_delivered: checkFullyDelivered });
    return data;
  },
  async getContractsByCommodity(commodities) {
    const data = await postRequest('/farm_profile/api/get_contracts_by_commodity/', commodities);
    return data;
  },
  async getGrades() {
    const data = await postRequest('/farm_profile/api/get_all_grades');
    return data;
  },
  async getBuyerRegions() {
    const data = await postRequest('/farm_profile/api/get_buyer_regions/');
    return data;
  },
  async getAllContractBuyers() {
    const data = await postRequest('/farm_profile/api/get_all_contract_buyers/');
    return data;
  },
  async getAllContractCommodities() {
    const data = await postRequest('/farm_profile/api/get_all_contract_commodities/');
    return data;
  },
  async getUnits() {
    const data = await postRequest('/farm_profile/api/get_all_units/');
    return data;
  },
  async getTerms() {
    const data = await postRequest('/farm_profile/api/get_all_terms/');
    return data;
  },
  async getSale(commodityId, cropYearId, unitId) {
    const data = await postRequest('/farm_profile/api/get_sale/', { commodity_id: commodityId, crop_year_id: cropYearId, unit_id: unitId });
    return data;
  },

  async createContract(payload) {
    const data = await postFormRequest('/farm_profile/api/create_contract/', payload);
    return data;
  },
  async updateContract(payload) {
    const data = await postFormRequest('/farm_profile/api/update_contract/', payload);
    return data;
  },
  async deleteContract(id) {
    await postRequest('/farm_profile/api/delete_contract/', id);
  },

  async createBinAdjustment(body) {
    await postRequest('/farm_profile/api/create_bin_adjustment/', body);
  },

  async getUserEngagements(userId) {
    const data = await postRequest('/account/css_module/request_engagements/', { id: userId });
    return data;
  },

  async getHistoricalUserEngagements(userId) {
    const data = await postRequest('/account/css_module/request_user_historical_engagements/', { id: userId });
    return data;
  },

  async getBinAdjustmentsForBin(id) {
    const data = await postRequest('/farm_profile/api/get_bin_adjustments_for_bin/', { id });
    return data;
  },

  getBinAdjustmentsForDelivery(deliveryId) {
    const data = postRequest('/farm_profile/api/get_bin_adjustments_for_delivery/', { id: deliveryId });
    return data;
  },

  async getScannedAttachment(documentId, type) {
    const data = await postRequest('/farm_profile/api/get_scanned_attachment/', { documentId, type });
    return data;
  },
  // css module api

  async readInvitations(marketingCampaigns, searchParam, paginationOptions) {
    const body = {
      marketing_campaigns: marketingCampaigns,
      search_param: searchParam,
      pagination_options: paginationOptions,
    };
    const { results, total } = await postRequest('/account/css_module/get_invitations/', body);
    return { results, total };
  },

  async apiReadMarketingCampaigns() {
    const data = await postRequest('/account/css_module/get_all_marketing_campaigns/');
    return data;
  },

  async createBudgetProduction(payload) {
    const data = await postRequest('/budgeting/api/create_budget_production/', payload);
    return data;
  },

  async updateBudgetProduction(payload) {
    const data = await postRequest('/budgeting/api/update_budget_production/', payload);
    return data;
  },

  async updateBudgetProductions(payload) {
    const data = await postRequest('/budgeting/api/update_budget_productions/', payload);
    return data;
  },

  async createExpenses(payload) {
    const data = await postRequest('/budgeting/api/create_expenses/', payload);
    return data;
  },

  async updateExpense(payload) {
    const data = await postRequest('/budgeting/api/update_expense/', payload);
    return data;
  },

  async deleteExpense(id) {
    await postRequest('/budgeting/api/delete_expense/', { id });
  },

  async getRoiStaticData(payload) {
    const data = await postRequest('/budgeting/api/get_roi_static_data/', payload);
    return data;
  },

  async getBudgetCategoriesPreferences(payload, abortController) {
    const data = await postRequest('/budgeting/api/get_expense_categories_preferences/', payload, {
      abortController,
    });
    return data;
  },

  async getExpenses(payload, abortController) {
    const data = await postRequest('/budgeting/api/get_all_expenses_for_budget_farm_locations/', payload, {
      abortController,
    });
    return data;
  },

  async getBudgetProductions(payload, abortController) {
    const data = await postRequest('/budgeting/api/get_all_budget_productions_for_budget_farm_locations/', payload, {
      abortController,
    });
    return data;
  },

  async getBenchmarkPrices(payload, abortController) {
    const data = await postRequest('/budgeting/api/get_all_benchmark_prices_by_farm_location_and_crop_year/', payload, {
      abortController,
    });
    return data;
  },

  async handleRoiCalculatorImport(payload) {
    const data = await postRequest('/budgeting/api/handle_roi_calculator_import/', payload);
    return data;
  },

  async getSmartPricesByCropYear(payload) {
    const data = await postRequest('/bids/get_smart_prices/', payload);
    return data;
  },

  /**
   * @param {object} payload contains properties of respected ids
   *        commodity: <integer>,
   *        crop_year: <integer>,
   *        farm_location: <integer>,
   *        budget_production: <integer>,
   * @returns data {message, expenses} containing the message and newly created expenses
  */
  async createExpensesUsingBenchmarkPrice(payload) {
    const data = await postRequest('/budgeting/api/create_expenses_using_benchmarks/', payload);
    return data;
  },

  async getPlaceAutocomplete(payload) {
    const data = await postRequest('/api/place_autocomplete/', payload);
    return data;
  },

  async getPlaceDetails(payload) {
    const data = await postRequest('/api/place_details/', payload);
    return data;
  },

  // New Feature
  async getLatestNewFeature() {
    const result = await postRequest('/account/get_latest_feature_popup/');
    return result;
  },
  // eslint-disable-next-line no-unused-vars
  async setNewFeatureViewed(featureId) {
    // This method currently sets a specific user field,
    // but in future should be made generic to handle
    // other features that will be released.
    // await postRequest('/api/set_feature_viewed/', featureId);

    // Temporary hardcoded endpoint
    await postRequest('/account/set_feature_popup_view_status/', { id: featureId });
  },

  async getSalesNumbers(payload) {
    const data = await postRequest('/farm_profile/api/get_sales_numbers/', payload);
    return data;
  },

  async createUpdateSubscription(payload) {
    const data = await postRequest('/dashboard/api/create_update_subscription/', payload);
    return data;
  },

  async fetchContractsIdMapping(id) {
    const contractsIdMapping = await postRequest('/farm_profile/api/get_contract_id_mapping/', id);
    return contractsIdMapping;
  },

  async removeAddon(payload) {
    const data = await postRequest('/commerce/remove_addon/', payload);
    return data;
  },

  async deleteAttachment(id) {
    await postRequest('/farm_profile/api/delete_attachment/', id);
  },

  async hasContract() {
    const data = await postRequest('/farm_profile/api/has_contract/');
    return data;
  },

  async acceptEULA() {
    const data = await postRequest('/account/accept_eula/');
    return data;
  },

  async acceptTermsOfUse() {
    const data = await postRequest('/account/accept_terms_of_use/');
    return data;
  },

  async fetchJohnDeereFieldsAndFarms() {
    const data = await postRequest('/farm_profile/api/integrations/john_deere/fetch_fields_and_farms/');
    return data;
  },

  async fetchJohnDeereFieldOperationsAndMeasurementsForField(field) {
    const data = await postRequest('/farm_profile/api/integrations/john_deere/fetch_field_operations_and_measurements_for_field/', field);

    return data;
  },

  async fetchJohnDeereFarmsFieldsMeasurements() {
    const data = await postRequest('/farm_profile/api/integrations/john_deere/fetch_farms_fields_measurements/');
    return data;
  },

  async fetchProductionMappings(cropYear) {
    const data = await postRequest('/farm_profile/api/get_many_integration_productions_by_crop_year/', {
      crop_year: cropYear,
    });
    return data;
  },

  async hasSeenAdblockRecently() {
    const data = await postRequest('/account/has_seen_adblock_recently/');
    return data;
  },

  async updateAdblockLastSeen() {
    const data = await postRequest('/account/update_adblock_last_seen/');
    return data;
  },
};

Object.freeze(API);
window.API = API;
export default API;
