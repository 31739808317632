<template>
  <v-tooltip
    bottom
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >
        <slot />
      </span>
    </template>

    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
