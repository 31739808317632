/* eslint-disable quote-props */
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
  VApp,
  VAutocomplete,
  VBtn,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VDataTable,
  VDialog,
  VDivider,
  VForm,
  VHover,
  VIcon,
  VList,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VMain,
  VNavigationDrawer,
  VPagination,
  VProgressLinear,
  VRow,
  VSelect,
  VSimpleCheckbox,
  VSpacer,
  VTextField,
  VToolbar,
  VTooltip,
  VOverlay,
} from 'vuetify/lib';
import en from 'vuetify/es5/locale/en';
import {
  ClickOutside,
  Intersect,
  Mutate,
  Ripple,
  Resize,
  Scroll,
  Touch,
} from 'vuetify/lib/directives';
import colors from '@/colors/colors.module.scss';
import emptyStateIcons from '@/icons/empty-state-icons-vuetify';
import customIcons from '@/icons/custom-icons-vuetify';

Vue.use(Vuetify, {
  /**
   * TODO: TR-62
   * Importing these specific components is a temporary solution to the problem
   * of existing pages like Resource Hub not being part of the bundling process.
   * Once those are refactored as component files, unplugin-vue-components
   * can do its job properly and import Vuetify components when necessary.
   */
  components: {
    VApp,
    VAutocomplete,
    VBtn,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCheckbox,
    VCol,
    VContainer,
    VDataTable,
    VDialog,
    VDivider,
    VForm,
    VHover,
    VIcon,
    VList,
    VListItem,
    VListItemTitle,
    VListItemContent,
    VMain,
    VNavigationDrawer,
    VPagination,
    VProgressLinear,
    VRow,
    VSelect,
    VSimpleCheckbox,
    VSpacer,
    VTextField,
    VToolbar,
    VTooltip,
    VOverlay,
  },
  directives: {
    ClickOutside,
    Intersect,
    Mutate,
    Ripple,
    Resize,
    Scroll,
    Touch,
  },
});

const options = {
  lang: {
    locales: { en },
    current: 'en',
  },
  icons: {
    iconfont: 'mdi',
    values: {
      expand: 'mdi-chevron-down',
      menu: 'mdi-chevron-down',
      subgroup: 'mdi-chevron-down',
      dropdown: 'mdi-chevron-down',
      smartAdvisor: 'M12 2a6.752 6.752 0 0 0-4.805 1.99 6.752 6.752 0 0 0-1.99 4.805 6.781 6.781 0 0 0 2.273 5.072 3.066 3.066 0 0 1 1.018 2.293v.457c0 .747.607 1.354 1.354 1.354h4.3c.747 0 1.354-.607 1.354-1.354v-.457c0-.88.37-1.715 1.017-2.293A6.781 6.781 0 0 0 18.26 6.15a6.75 6.75 0 0 0-1.457-2.16 6.809 6.809 0 0 0-2.16-1.457A6.742 6.742 0 0 0 12 2Zm-.266 2.195h.922v1.268c.669.096 1.186.38 1.551.85s.549 1.084.549 1.837H13.36c0-.485-.108-.866-.324-1.144a1.065 1.065 0 0 0-.885-.418c-.368 0-.658.099-.865.299-.207.2-.31.487-.31.86 0 .333.103.602.308.804.205.201.555.392 1.047.574.492.18.877.35 1.158.502.28.154.519.327.71.525.19.198.34.426.443.684.103.258.156.56.156.904 0 .65-.202 1.175-.598 1.58-.398.404-.95.64-1.662.715v1.125h-.918v-1.119c-.76-.085-1.345-.357-1.754-.818-.408-.462-.613-1.071-.613-1.832h1.398c0 .485.122.858.368 1.123.243.265.588.398 1.035.398.437 0 .773-.106 1.002-.318.228-.213.334-.493.334-.842 0-.327-.104-.596-.31-.81-.208-.213-.555-.406-1.038-.577a7.955 7.955 0 0 1-1.13-.476c-.896-.48-1.343-1.19-1.343-2.131 0-.638.196-1.164.584-1.574.389-.41.916-.655 1.58-.73zM11.977 22c.687 0 1.244-.407 1.244-.91h-2.488c0 .501.558.91 1.244.91zm2.09-2.123H9.886c-.2 0-.363.163-.363.363 0 .2.163.363.363.363h4.183a.363.363 0 0 0 0-.726zm.471-1.306H9.415c-.2 0-.364.163-.364.363 0 .2.164.363.364.363h5.123a.363.363 0 0 0 0-.726z',
      ...emptyStateIcons,
      ...customIcons,
    },
  },
  theme: {
    themes: {
      light: {
        primary: colors.$primary,
        'primary-grey': colors.$primaryGrey,
        secondary: colors.$secondary,
        accent: colors.$secondary,
        error: colors.$error,
        success: colors.$success,
        warning: colors.$warning,
        grey: {
          base: colors.$grey,
          lighten5: colors.$greyLighten5,
          lighten4: colors.$greyLighten4,
          lighten3: colors.$greyLighten3,
          lighten2: colors.$greyLighten2,
          lighten1: colors.$greyLighten1,
          darken1: colors.$greyDarken1,
          darken2: colors.$greyDarken2,
          darken3: colors.$greyDarken3,
          darken4: colors.$greyDarken4,
        },
      },
    },
  },
};

export default new Vuetify(options);
