import { getFormData, postFormRequest, postRequest } from '../helpers/request';

export async function apiReadCSSAccounts(options, controller) {
  const { results, total, message } = await postFormRequest('/account/css_module/query/', getFormData(options), { abortController: controller });
  return { results, total, message };
}

export async function apiStartMimic(account) {
  const data = await postFormRequest('/account/css_module/start_mimic/', getFormData(account));
  return data.message;
}

export async function apiStopMimic(force = false) {
  const data = await postRequest('/account/css_module/stop_mimic/', { force });
  return data.message;
}

export async function apiAddNote(notes) {
  const data = await postFormRequest('/account/css_module/add_note/', getFormData(notes));
  return data.message;
}

export async function apiUpdateEmail(payload) {
  const data = await postRequest('/account/css_module/update_email/', payload);
  return data.message;
}

export async function apiResetPassword(account) {
  const data = await postFormRequest('/account/css_module/reset_password/', getFormData(account));
  return data.message;
}

// Send Message API
export async function apiGetUserList() {
  const result = await postRequest('/account/css_module/send_message/get_user_list/');
  return result.user_list;
}

export async function apiGetApproverList() {
  const result = await postRequest('/account/css_module/send_message/get_approver_list/');
  return result.approver_list;
}

export async function apiGetSenderList() {
  const result = await postRequest('/account/css_module/send_message/get_sender_list/');
  return result.sender_list;
}

export async function apiGetMessageList(query) {
  const result = await postRequest('/account/css_module/send_message/get_message_list/', query);
  return result.message_list;
}

export async function apiGetRecipientListFromCsv(payload) {
  const result = await postRequest('/account/css_module/send_message/get_recipient_list_from_csv/', payload);
  return result.add_recipient_list;
}

export async function apiCreateSendMessage(payload) {
  const result = await postRequest('/account/css_module/send_message/create/', payload);
  return result.message;
}

export async function apiEditSendMessage(payload) {
  const result = await postRequest('/account/css_module/send_message/edit/', payload);
  return result.message;
}

export async function apiApproveMessage(payload) {
  const result = await postRequest('/account/css_module/send_message/approve/', payload);
  return result.message;
}

export async function apiCancelSendMessage(payload) {
  const result = await postRequest('/account/css_module/send_message/cancel/', payload);
  return result.message;
}

export async function apiDeleteSendMessage(payload) {
  const result = await postRequest('/account/css_module/send_message/delete/', payload);
  return result.message;
}

export async function apiGetMessageRecipientList(id) {
  const result = await postRequest(`/account/css_module/send_message/get_recipient_list/${id}`);
  return result.recipient_list;
}

export async function apiGetPossibleDeletionReasons() {
  const result = await postRequest('/account/css_module/get_possible_deletion_reasons/');
  return result.reasons;
}

export async function apiGetPossibleDowngradeReasons() {
  const result = await postRequest('/account/css_module/get_possible_downgrade_reasons/');
  return result.reasons;
}

export async function apiResetInvitation(payload) {
  return postRequest('/resend_email/', payload);
}

export async function apiDeleteInvitation(payload) {
  return postRequest('/account/css_module/delete_invitation/', payload);
}

export async function apiChangeSubscriptionLevel(payload) {
  return postRequest('/commerce/change_subscription_level/', payload);
}

export async function apiUpdateTrial(payload) {
  return postRequest('/commerce/update_cb_trial/', payload);
}

export async function apiReadMarketingCampaignsWithInvites() {
  const data = await postRequest('/account/css_module/get_marketing_campaigns_with_invites/');
  return data;
}

export async function apiReadMarketingCampaignsWithAccounts() {
  const data = await postRequest('/account/css_module/get_marketing_campaigns_with_accounts/');
  return data;
}

export async function apiImmediatelyActivateInvitation(invite) {
  const data = await postRequest('/account/css_module/css_immediate_activation/', {
    invite: invite.id,
  });
  return data;
}

export async function apiGetSubscriptionContentById(payload) {
  const data = await postRequest('/account/css_module/get_subscribed_content_by_subscription_id/', payload);
  return data.subscribed_content;
}

export async function apiUpdateSubscriptionContent(payload) {
  const data = await postRequest('/account/css_module/update_subscription_content/', payload);
  return data;
}

export async function apiConvertSource(organization_id, source) {
  const data = await postRequest('/account/css_module/convert_source/', {
    organization_id,
    source,
  });
  return data;
}
