<template>
  <v-card
    v-ripple
    data-qa="video-card"
    elevation="0"
    :class="horizontal ? 'br--0-i' : 'border-grey br--05-i'"
    @click.capture.prevent.stop="openDetailedView"
  >
    <v-card-text
      class="pa-0 d-flex flex-column align-stretch justify-center overflow--hidden elevation-2"
    >
      <v-row
        dense
      >
        <v-col
          :cols="horizontal ? 5 : 12"
          class="pa-4 c-pointer p-rel d-flex flex-wrap items-stretch align-stretch w--100"
        >
          <v-img
            :src="videoDetail.thumbnails.large"
            :srcset="srcSets"
            :alt="videoDetail.title"
            :title="videoDetail.title"
            :aspect-ratio="1.77"
            :class="horizontal ? 'border-grey' : ''"
          >
            <!-- class="videos-border-b" -->
            <template #placeholder>
              <div
                class="pa-4 align-center justify-center"
              >
                <v-img
                  contain
                  src="/static/dashboard/svg/grainfox-icon.svg"
                  alt="Loading ..."
                  :aspect-ratio="1.77"
                />
              </div>
            </template>
          </v-img>
          <span
            v-if="showPlayIcon"
            class="absolute-center p-0"
          >
            <v-icon
              :size="horizontal ? '3rem' : '5rem'"
              color="primary-grey"
              class="text--secondary"
            >
              mdi-play-circle
            </v-icon>
          </span>
          <!-- New chip -->
          <v-chip
            v-if="!videoDetail.is_watched"
            light
            color="primary"
            class="p-abs font-weight-medium video-tags video-new-chip"
          >
            NEW
          </v-chip>
        </v-col>
        <v-col
          :cols="horizontal ? 7 : 12"
          class="d-flex items-stretch lighten-5"
          :class="horizontal ? 'pa-3' : 'pa-4'"
        >
          <v-row
            dense
            align-content="start"
            class="w--100 lh--1"
          >
            <v-col cols="12">
              <a
                class="font-weight-regular grey--text text--darken-3"
                :class="horizontal ? 'subtitle-1 truncated-text'
                  : 'd-block body-1 text-md-title text-truncate'"
                :title="videoDetail.title"
                @click.capture.prevent.stop="openDetailedView"
              >
                {{ videoDetail.title }}
              </a>
            </v-col>
            <!-- Needs this margin in-line styling for when a video has no tag -->
            <v-col
              v-show="videoTags"
              class="d-flex item-center justify-start pb-4"
              :style="videoTags.length === 0 ? 'margin-bottom: 22px' : ''"
            >
              <v-chip
                v-for="(videoTag, index) in videoTagsSubset"
                :key="videoTag"
                :title="videoTagTooltip(index)"
                light
                class="video-tags mr-3"
              >
                {{ capitalizeFirstChar(videoTag) }}
              </v-chip>
            </v-col>
            <!-- Author icons -->
            <v-col
              cols="12"
              class="d-flex"
            >
              <div
                v-for="author in videoDetail.authors"
                :key="author.name"
                class="d-flex align-center"
              >
                <v-avatar
                  size="24"
                >
                  <img
                    :src="author.profile_picture"
                    :alt="author.name"
                  >
                </v-avatar>
                <span class="pl-2 caption grey--text text--darken-1">
                  {{ author.name }}
                </span>
                <v-icon v-if="author !== videoDetail.authors[videoDetail.authors.length - 1]">
                  mdi-circle-small
                </v-icon>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
              :class="horizontal ? 'flex-column' : ''"
            >
              <v-row
                dense
                justify="start"
                :class="horizontal ? 'meta-horizontal' : 'meta'"
              >
                <v-col
                  class="d-flex inline-nowrap flex-grow-initial
                    align-center grey--text text--darken-4 text-caption"
                  :class="horizontal ? 'justify-start justify-sm-center' :
                    'justify-center'"
                >
                  <v-icon
                    class="mr-1 videos-meta-icon"
                  >
                    mdi-clock-outline
                  </v-icon>
                  {{ secondsToMinutes(videoDetail.length) }} min
                  <span
                    class="d-none d-sm-inline-flex ml-1"
                  >watch</span>
                </v-col>
                <v-col
                  class="d-flex inline-nowrap grey--text text--darken-4
                    flex-grow-initial align-center justify-end px-0"
                >
                  <v-icon>
                    mdi-circle-small
                  </v-icon>
                </v-col>
                <v-col
                  class="d-flex inline-nowrap grey--text text--darken-4
                    flex-grow-initial align-center justify-end text-caption"
                >
                  {{ getNumberString(videoDetail.view_count) }} views
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  formatNumberEnforceDecimals,
  dateFormatShortMonthDateYear,
  getNumberString,
  capitalizeFirstChar,
} from '../../helpers/formatting';

export default {
  name: 'VideoCard',

  props: {
    /* Expected videoDetail Object
      id: 173,
      title: 'Test',
      video_id: '12345ABC',
      date_published: '2021-10-13T15:15:30.794Z',
      url: 'www.youtube.com',
      view_count: 0,
      like_count: 0,
      length: 0,
      is_favourited: false,
      thumbnails: {
        medium: 'https://img.youtube.com/vi/12345ABC/sddefault.jpg',
        large: 'https://img.youtube.com/vi/12345ABC/maxresdefault.jpg',
        small: 'https://img.youtube.com/vi/12345ABC/mqdefault.jpg'
      }
     */
    videoDetail: {
      type: Object,
      default: null,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
      required: false,
    },
    showPlayIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      tooltipIndex: -1,
      tooltipValue: undefined,
    };
  },

  computed: {
    ...mapGetters('videos', ['getVideoTags']),
    smAndUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    videoTags() {
      return this.getVideoTags(this.videoDetail);
    },

    videoTagsSubset() {
      return this.computeSubset();
    },

    srcSets() {
      const thumbnails = [
        [this.videoDetail.thumbnails.small, 0.25],
        [this.videoDetail.thumbnails.medium, 0.5],
        [this.videoDetail.thumbnails.large, 1],
      ];
      return thumbnails.map(([image, factor]) => `${image} ${factor}x`).join(', ');
    },
  },

  methods: {
    dateFormatShortMonthDateYear,
    getNumberString,
    capitalizeFirstChar,

    openDetailedView() {
      this.$emit('model-popup', this.videoDetail);
    },

    secondsToMinutes(value) {
      return Math.ceil(formatNumberEnforceDecimals(value / 60));
    },
    // Returns a subsetted list of Video tags to show the user on the video page
    computeSubset() {
      const len = this.videoTags.length;
      // Base case, do nothing when no tags present
      if (len <= 1) {
        this.tooltipIndex = -1;
        this.tooltipValue = undefined;
        return this.videoTags;
      }
      const firstTag = this.videoTags[0];
      const secondTag = this.videoTags[1];
      // If the number of characters in the first two tags reach a certain amount,
      // we return just the first tag  along with the subset, shown as '+(len - 1)'
      if (firstTag.length + secondTag.length >= 32) {
        // tooltipValue will be a combined string of all the remaining tags
        // set on the '+(len - 1)' tag
        this.tooltipIndex = 1;
        this.tooltipValue = this.videoTags.slice(1).map(capitalizeFirstChar).join('\n');
        return [firstTag, `+${len - 1}`];
      }

      // Return all the tags unchanged, since the combined characters of the two
      // do not exceed video card dimensions
      if (len === 2) {
        this.tooltipIndex = -1;
        this.tooltipValue = undefined;
        return this.videoTags;
      }

      // Finally, if none of the other checks pass, we return the first two tags,
      // subset the rest and set the tooltip as the remaining tags in a single string
      this.tooltipIndex = 2;
      this.tooltipValue = this.videoTags.slice(2).map(capitalizeFirstChar).join('\n');
      return [firstTag, secondTag, `+${len - 2}`];
    },

    videoTagTooltip(index) {
      if (index === this.tooltipIndex) {
        return this.tooltipValue;
      }
      return undefined;
    },
  },
};
</script>
<style lang="scss">
@use "@/colors/colors";
  .meta-horizontal {
    font-size: 0.7rem;
  }

  .videos-border-b {
    border-bottom: 1.5px solid #BDBDBD;
  }

  .videos-meta-icon {
    font-size: 1.2rem !important;
  }

  .video-tags {
    padding: 1px 10px;
    height: auto !important;
  }

  .video-new-chip {
    top: 0.6rem;
    left: 0.6rem;
    font-size: 12px !important;
  }

  .border-grey {
    box-shadow: 0px 1px 5px 0px #4A4A4A33;
    box-shadow: 0px 3px 1px 0px #4A4A4A1F;
    box-shadow: 0px 2px 2px 0px #4A4A4A24;
    border: 1px solid colors.$greyLighten5 !important;
  }

  /* This works on all browsers, except FireFox < version 68 and IE */
  /* For the unsupported browsers, max-height is needed.
      This is dependent on line-height, so is unstable.
  */
  .truncated-text {
    display: -webkit-box;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 3.5rem;
  }
</style>
