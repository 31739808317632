export default {
  state: () => ({
    message: '',
    color: '',
    timeout: 10000,
    onUndo: null,
  }),

  mutations: {
    showMessage(state, payload) {
      state.message = payload.message;
      state.color = payload.color;
      state.timeout = payload.timeout;
      state.onUndo = payload.onUndo;
    },
  },

  namespaced: true,
};
