<template>
  <div class="text-center">
    <v-badge
      bottom
      color=""
      offset-x="40"
      offset-y="40"
    >
      <template #badge>
        <v-btn
          fab
          dark
          small
          color="primary"
          @click="toggleOpen"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <v-avatar
        class="elevation-1 mx-auto"
        size="128"
      >
        <v-img
          max-height="128"
          max-width="128"
          :aspect-ratio="1/1"
          :src="pictureFallback"
          data-qa="user-profile-image"
        />
      </v-avatar>
    </v-badge>
    <image-upload-dialog
      :is-open="isOpen"
      title="Update Profile Picture"
      url="/account/upload_profile_pic/"
      @success="handleSuccess"
      @close="handleClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageUploadDialog from '../generic/ImageUploadDialog.vue';

export default {
  components: {
    ImageUploadDialog,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    pictureFallback() {
      return this.picture.length > 0 ? this.picture : '/static/dashboard/templates/profile.png';
    },

    ...mapState('user', ['picture']),
  },

  methods: {
    handleSuccess(pictureURL) {
      this.$store.commit('user/setPicture', pictureURL);
      this.isOpen = false;
    },

    handleClose() {
      this.isOpen = false;
    },

    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
