<template>
  <v-card
    rounded="0"
    class="overflow-hidden d-flex flex-column-reverse h--100"
  >
    <TinyEditor
      v-if="templatesLoaded"
      v-model="state.contents"
      :content-id="state.publicationId"
      :state="state"
      :slide-drawer-overlap="slideDrawerOverlap"
      :templates="templates"
      class="flex-grow-1"
      @update-editor="updateEditor"
    />

    <div
      v-if="shouldShowDownloadButton"
      class="p-abs bottom-0 left-0 pl-6 pb-11"
    >
      <v-btn
        color="primary"
        fab
        @click="handleDownloadClick"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>

    <DownloadForm
      v-model="downloadFormOpen"
      :loading="downloading"
      @save="handleDownload"
    />

    <v-toolbar class="flex-grow-0">
      <v-btn
        icon
        @click="handleCloseClick"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        color="primary-grey"
        class="mr-4 font-weight-regular"
        text
        @click="openNLGSlideout"
      >
        NLG Helper
      </v-btn>

      <v-btn
        color="primary"
        text
        @click="openPropertiesDrawer"
      >
        Properties
      </v-btn>
    </v-toolbar>
    <NLGSlideout
      v-model="nlgSlideout"
      @close="closeNLGSlideout"
      @interface="updateNLGSlideoutInterface"
      @change:overlap="handleNLGChangeOverlap"
    />
    <v-navigation-drawer
      v-model="propertiesDrawer"
      absolute
      right
      temporary
      class="editor-drawers rounded-l-xl elevation-3"
      width="520"
      hide-overlay
    >
      <div class="d-flex justify-space-between ma-4">
        <p class="headline">
          Properties
        </p>

        <v-btn
          icon
          @click="closePropertiesDrawer"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <EditorForm
        class="ma-4"
        :state="state"
        :editor="editor"
        :has-contents="Boolean(state.contents)"
        @submit="handleSubmit"
      />
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import EditorForm from './EditorForm.vue';
import TinyEditor from './TinyEditor.vue';
import NLGSlideout from './NLGSlideout.vue';
import DownloadForm from './DownloadForm.vue';
import { postRequest } from '@/helpers/request';
import { saveAsTXT, saveAsDOCX, saveAsPDF } from '@/helpers/download';

export default {
  components: {
    TinyEditor,
    EditorForm,
    NLGSlideout,
    DownloadForm,
  },

  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      propertiesDrawer: false,
      downloadFormOpen: false,
      downloading: false,
      nlgSlideout: false,
      propertiesSlideDrawerOverlap: 520,
      nlgSlideDrawerOverlap: 0,
      nlgExpand: () => {},
      editor: null,
      templates: [],
      templatesLoaded: false,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    title() {
      const name = this.state.name || 'New Document';
      return `${this.state.isDraft ? '[Draft] ' : ''}${name}`;
    },

    shouldShowDownloadButton() {
      return this.flags['document-download-button'];
    },

    slideDrawerOverlap() {
      if (this.propertiesDrawer) {
        return this.propertiesSlideDrawerOverlap;
      } if (this.nlgSlideout) {
        return this.nlgSlideDrawerOverlap;
      }
      return 0;
    },
  },

  async created() {
    try {
      const templates = await postRequest('/library/api/templates/');
      this.templates = templates;
      this.templatesLoaded = true;
    } catch (e) {
      this.$snackbar.error('Could not load templates.');
    }
  },

  methods: {
    saveAsTXT,
    saveAsDOCX,
    saveAsPDF,

    updateEditor(editor) {
      this.editor = editor;
    },

    handleSubmit() {
      this.$emit('save');
    },

    handleCloseClick() {
      this.$emit('close');
    },

    closePropertiesDrawer() {
      this.propertiesDrawer = false;
    },

    openPropertiesDrawer() {
      this.nlgSlideout = false;
      this.propertiesDrawer = true;
    },

    closeNLGSlideout() {
      if (this.nlgSlideout) {
        this.nlgSlideout = false;
      }
    },

    openNLGSlideout() {
      if (!this.nlgSlideout) {
        this.propertiesDrawer = false;
        this.nlgSlideout = true;
      }
      this.nlgExpand();
    },

    handleNLGChangeOverlap(value) {
      this.nlgSlideDrawerOverlap = value;
    },

    updateNLGSlideoutInterface(emittedInterface) {
      this.nlgExpand = emittedInterface.expand;
    },

    async handleDownload(info) {
      if (!this.editor) {
        return;
      }
      this.downloading = true;
      const documentObject = this.editor.dom.doc;
      const { documentType, fileName } = info;
      try {
        switch (documentType) {
          case 'docx':
            await this.saveAsDOCX(documentObject, `${fileName}.docx`);
            break;
          case 'pdf':
            await this.saveAsPDF(documentObject, `${fileName}.pdf`);
            break;
          case 'txt':
          default:
            await this.saveAsTXT(documentObject, `${fileName}.txt`);
            break;
        }
      } finally {
        this.downloading = false;
        this.downloadFormOpen = false;
      }
    },

    handleDownloadClick() {
      this.downloadFormOpen = true;
    },
  },
};
</script>
<style scoped lang="scss">
  .editor-drawers {
    height: 83% !important;
    top: 145px !important;
  }

</style>
