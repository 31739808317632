<template>
  <v-menu
    v-model="isOpen"
    transition="slide-y-transition"
    :close-on-content-click="false"
    max-height="100%"
    offset-y
    bottom
    left
  >
    <template #activator="{ on, attrs }">
      <v-btn
        data-qa="account-menu-profile-dropdown-button"
        :block="block"
        :class="[widthClass, mimicClass]"
        class="account-menu__button reset--text px-4"
        :height="buttonHeight"
        text
        :tile="tile"
        x-large
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar
          class="elevation-1 mr-4"
          size="32"
        >
          <v-img
            max-height="32"
            max-width="32"
            :aspect-ratio="1/1"
            :src="picture"
          />
        </v-avatar>
        <div
          class="account-menu__text overflow-hidden flex-grow-1 mr-2
          text-left primary-grey--text"
        >
          <p class="text-truncate mb-0">
            {{ name }}
          </p>
          <p class="text-truncate body-2 mb-0">
            {{ subscriptionName }}
          </p>
        </div>

        <v-icon
          right
          size="24"
          :class="{'rotate-180': isOpen}"
        >
          $expand
        </v-icon>
      </v-btn>
    </template>

    <div
      :class="flexDirection"
      class="d-flex white align-start"
    >
      <div
        v-if="!isSimpleMode"
        class="d-flex flex-column overflow-x-hidden"
        :class="accountsOrder"
        :style="menuStyle"
      >
        <v-list>
          <v-subheader class="body-1 text--primary font-weight-bold">
            Account
          </v-subheader>
        </v-list>
        <template v-if="isAdvancedMode">
          <v-text-field
            ref="search"
            v-model="searchTerm"
            label="Search"
            append-icon="mdi-magnify"
            hide-details
            dense
            outlined
            class="mx-4 mt-1 mb-3"
            name="organization_search"
            autocomplete="off"
            @input="showNoDataState = false"
            @click:append="search"
            @keydown.enter="search"
          />
          <v-skeleton-loader
            v-if="isSearchLoading"
            type="list-item-three-line"
            loading
          />
          <template
            v-if="!isSearchLoading
              && (searchResults.length > 0
                || recentSubscription)"
          >
            <v-divider />
            <v-list
              class="overflow-y-auto"
              :class="searchResults.length === 0 ? 'py-0' : ''"
              :max-height="maxListHeight"
            >
              <v-list-item-group
                color="primary"
                :value="subscriptionID"
              >
                <template v-if="searchResults.length === 0">
                  <v-row
                    align="center"
                    justify="space-between"
                    class="ma-0 px-1"
                  >
                    <v-col>
                      <span class="subtitle-2 grey--text">
                        Recent
                      </span>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        text
                        x-small
                        @click="clearRecent"
                      >
                        <v-icon x-small>
                          mdi-close
                        </v-icon>
                        <span class="ml-1">Clear</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-list-item
                    v-for="s in recentSubscription"
                    :key="s.id"
                    :value="s.id"
                    two-line
                    @click="changeSubscription(s.id)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ s.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <div>
                          {{ s.email }}
                        </div>
                        <div class="font-weight-medium mt-1">
                          {{ s.date }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item
                    v-for="s in searchResults"
                    :key="s.id"
                    :value="s.id"
                    two-line
                    @click="changeSubscription(s.id)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ s.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ s.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </template>
          <template
            v-if="showNoDataState && searchTerm"
          >
            <div
              class="mx-4 pa-4 text-center"
            >
              <span class="subtitle-1">No data available</span>
            </div>
          </template>
        </template>
        <template v-else>
          <v-list
            class="overflow-y-auto"
            :max-height="maxListHeight"
          >
            <v-list-item-group
              color="primary"
              :value="subscriptionID"
            >
              <v-list-item
                v-for="s in subscriptionArray"
                :key="s.id"
                :value="s.id"
                @click="changeSubscription(s.id)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ s.subscription_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>
      </div>
      <v-divider
        v-if="!isSimpleMode"
        class="order-1 w--100"
        :vertical="isDividerVertical"
      />
      <v-list
        class="flex-grow-1"
        :class="[actionsOrder, listWidthClass]"
      >
        <v-list-item href="/account/profile/">
          <v-icon left>
            mdi-account
          </v-icon>
          <v-list-item-title>My Profile</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="checkPermission('invite', 'create')"
          @click="openInviteDialog"
        >
          <v-icon left>
            mdi-account-plus
          </v-icon>
          <v-list-item-title>Invite to GrainFox</v-list-item-title>
        </v-list-item>

        <v-list-item @click="openFeedbackDialog">
          <v-icon left>
            mdi-bullhorn
          </v-icon>
          <v-list-item-title>Provide Feedback</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="checkPermission('new_organization','create')"
          @click="openCreateAccountDialog"
        >
          <v-icon left>
            mdi-domain-plus
          </v-icon>
          <v-list-item-title>Create Account</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="!isMimic"
          data-qa="account-menu-signout-item"
          href="/logout/"
        >
          <v-icon left>
            mdi-exit-to-app
          </v-icon>
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-else
          @click="stopMimic"
        >
          <v-icon
            left
            :color="mimicLocked ? 'error' : undefined"
          >
            mdi-location-exit
          </v-icon>
          <v-list-item-title :class="{'error--text': mimicLocked}">
            Stop Mimicking
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>

    <ConfirmPopup
      v-model="confirmUnmimic"
      heading="Stop Mimicking"
      :text="unmimicMessage"
      @confirm="onConfirmStopMimic"
    />
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ConfirmPopup from '../generic/ConfirmPopup.vue';

export default {
  components: {
    ConfirmPopup,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      showNoDataState: false,
      storedRecentSubscription: null,
      confirmUnmimic: false,
    };
  },
  computed: {
    unmimicMessage() {
      if (!this.mimicSession) {
        return '';
      }
      return `You have ${this.mimicSession.lock_count ?? '1 or more'} active operations which will be cancelled if you stop mimicking.
      Are you sure you want to stop mimicking?`;
    },
    name() {
      return this.fullName.trim() || 'Member';
    },
    block() {
      return this.fullWidth;
    },
    tile() {
      return this.fullWidth;
    },
    buttonHeight() {
      return this.fullWidth ? 56 : undefined;
    },
    widthClass() {
      return this.fullWidth ? 'full-width mx-n5' : 'limit-width';
    },
    subscriptionArray() {
      return Array.from(this.subscriptions.values());
    },
    accountsOrder() {
      return this.$vuetify.breakpoint.xs ? 'order-2' : 'order-0';
    },
    actionsOrder() {
      return this.$vuetify.breakpoint.xs ? 'order-0' : 'order-2';
    },
    flexDirection() {
      return this.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row';
    },
    isDividerVertical() {
      return !this.$vuetify.breakpoint.xs;
    },
    maxMenuHeight() {
      return this.$vuetify.breakpoint.xs ? '100%' : '400px';
    },

    menuWidth() {
      return this.$vuetify.breakpoint.xs ? '100%' : '320px';
    },

    listWidthClass() {
      return this.$vuetify.breakpoint.xs ? 'w--100' : '';
    },

    maxListHeight() {
      return this.$vuetify.breakpoint.xs ? '200px' : '100%';
    },

    menuStyle() {
      return `max-height: ${this.maxMenuHeight}; width: ${this.menuWidth};`;
    },

    mimicClass() {
      return this.isMimic ? 'error text--white' : '';
    },

    searchTerm: {
      get() {
        return this.$store.state.subscription.searchTerm;
      },
      set(value) {
        this.$store.commit('subscription/setSearchTerm', value);
      },
    },

    recentSubscription() {
      try {
        // To make this reactive, which helps to clear data
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.storedRecentSubscription = JSON.parse(window.localStorage.getItem('recentSubscription'));
        const reversed = [...this.storedRecentSubscription].reverse();
        return reversed;
      } catch {
        return false;
      }
    },

    ...mapState('app', ['isMimic', 'mimicSession']),
    ...mapState('user', ['picture', 'fullName']),

    ...mapState('subscription', ['searchResults', 'isSearchLoading']),
    ...mapState('subscription', {
      subscriptionID: 'id',
    }),

    ...mapGetters('subscription', ['isSimpleMode', 'isAdvancedMode']),
    ...mapGetters('subscription', {
      subscriptionName: 'getActiveSubscriptionName',
      subscriptions: 'getSubscriptions',
    }),
    ...mapGetters('permissions', ['checkPermission']),
    ...mapGetters('app', ['mimicLocked']),
  },
  methods: {
    openInviteDialog() {
      this.$store.dispatch('app/inviteToSubscription');
      this.isOpen = false;
    },
    openFeedbackDialog() {
      this.$store.commit('app/setFeedbackDialogOpen', true);
      this.isOpen = false;
    },
    openCreateAccountDialog() {
      this.$store.commit('app/setCreateAccountDialogOpen', true);
      this.isOpen = false;
    },
    changeSubscription(id) {
      this.$store.dispatch('subscription/changeSubscription', {
        newValue: id,
        oldValue: this.subscriptionID,
      });
    },

    clearRecent() {
      window.localStorage.removeItem('recentSubscription');
      this.storedRecentSubscription = false;
      this.$snackbar.success('Successfully deleted recent history.');
    },

    async search() {
      this.showNoDataState = false;
      await this.$store.dispatch('subscription/search', this.searchTerm).then(() => {
        if (this.searchResults.length > 0) {
          // Temporary half-measure to fix keyboard nav issues
          this.$refs.search.blur();
        } else {
          this.showNoDataState = true;
        }
      });
    },

    onConfirmStopMimic() {
      this.$store.dispatch('cssModule/stopMimic', true);
    },

    stopMimic() {
      if (!this.mimicLocked) {
        this.$store.dispatch('cssModule/stopMimic');
      } else {
        this.confirmUnmimic = true;
      }
    },
  },
};
</script>

<style lang="scss">
.account-menu {
  &__button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    &.full-width {
      .v-btn__content {
        max-width: 100%;
      }
    }

    &.limit-width {
      .account-menu__text {
        max-width: 200px;
      }
    }
  }
}
</style>
