import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { isNumber } from '@/helpers/numeric';
import { apiSetTutorialViewed } from '@/api/mediaPlayer';

export default {
  state: () => ({
    activeLibrary: null,
    playing: false,
    currentTime: 0,
    duration: 0,
    hasPlayed: false,
    /** Range: [0, 1] */
    volume: 1,

    /** GFA items */
    loadTime: null,
    segmentStart: 0,
    segmentEnd: 0,
    backupStart: 0,
    backupEnd: 0,
  }),

  getters: {
    /**
     * Get the URL of the currently active library's audio file.
     */
    activeTTSFile(state, getters, rootState) {
      if (state.activeLibrary) {
        const url = rootState.preferences.useFemaleVoice
          ? state.activeLibrary.audio_file_female
          : state.activeLibrary.audio_file_male;
        return url || null;
      }
      return null;
    },

    /**
     * Fraction of the audio file that has been played.
     * Range: [0, 1]
     * Null if no library is active.
     */
    progress(state) {
      if (!state.activeLibrary) {
        return null;
      }
      if (state.duration === 0) {
        return 0;
      }
      return state.currentTime / state.duration;
    },

    /**
     * Internal GFA tracking section.
     */

    eventKey(state) {
      if (!state.activeLibrary || state.loadTime === null) {
        return null;
      }
      return `${state.activeLibrary.id}@${state.loadTime}`;
    },

    storageKey(state) {
      if (!state.activeLibrary) {
        return null;
      }
      return `audio-${state.activeLibrary.id}v${state.activeLibrary.version ?? 0}`;
    },

    segment(state) {
      return [state.segmentStart, state.segmentEnd];
    },

    backupSegment(state) {
      return state.backupEnd == null ? null : [state.backupStart, state.backupEnd];
    },
  },

  mutations: {
    /**
     * Set the active library file.
     * @param {Object} library - The library file to set as active.
     * This can be null to indicate no active library file.
     * This function will reset the player state.
     */
    setActiveLibrary(state, library) {
      if (state.activeLibrary && state.activeLibrary.id === library?.id && state.activeLibrary.version === library?.version) {
        // Same library file, can ignore
        return;
      }
      // Either new library file or no library file
      state.activeLibrary = library;
      // Set initial state to empty values
      state.playing = false;
      state.duration = 0;
      state.currentTime = 0;
      state.hasPlayed = false;
      // Reset GFA state
      state.loadTime = null;
      state.segmentStart = 0;
      state.segmentEnd = 0;
      state.backupStart = 0;
      state.backupEnd = 0;
    },

    /**
     * Set the volume of the player.
     * Prefer to use updateVolume action.
     */
    setVolume(state, volume) {
      state.volume = volume;
    },

    /** Player state mutations. Prefer actions where possible. */
    setPlaying(state, playing) {
      state.playing = playing;
      state.hasPlayed ||= playing;
    },

    setDuration(state, duration) {
      state.duration = duration;
    },

    setCurrentTime(state, currentTime) {
      state.currentTime = currentTime;
    },

    /** GFA mutations */
    setLoadTime(state, loadTime) {
      state.loadTime = loadTime;
    },

    setSegmentStart(state, segmentStart) {
      state.segmentStart = segmentStart;
    },

    setSegmentEnd(state, segmentEnd) {
      state.segmentEnd = segmentEnd;
    },

    setBackupStart(state, backupStart) {
      state.backupStart = backupStart;
    },

    setBackupEnd(state, backupEnd) {
      state.backupEnd = backupEnd;
    },
  },

  actions: {
    /**
     *
     */
    async setActiveLibraryViewedTutorial({ state }) {
      await apiSetTutorialViewed(state.activeLibrary.contentId);
      state.activeLibrary.showTTSTutorial = false;
    },

    /**
     * Handle the load completion of a new audio file. Mostly internal for GFA.
     */
    handleLoad({
      commit, state, getters,
    }, totalSeconds) {
      const loadTime = new Date().toISOString();
      commit('setLoadTime', loadTime);
      commit('setDuration', totalSeconds);
      window.gfa.finalizeLibraryListenEvents(loadTime);
      window.gfa.send('library_listen', {
        eventKey: getters.eventKey,
        libraryID: state.activeLibrary.id,
        firstAction: loadTime,
        lastAction: loadTime,
        totalSeconds,
      });
    },

    /**
     * Play the audio file.
     * @param {object} store
     * @param {number} [currentTime] The time at which to start.
     */
    handlePlay({ commit, getters, state }, currentTime) {
      const time = currentTime ?? state.currentTime;
      const lastAction = new Date().toISOString();
      commit('setSegmentStart', time);
      commit('setBackupStart', time);
      commit('setPlaying', true);
      commit('setCurrentTime', time);

      window.gfa.send('library_listen', {
        eventKey: getters.eventKey,
        libraryID: state.activeLibrary.id,
        firstAction: state.loadTime,
        lastAction,
        totalSeconds: state.duration,
      });

      datadogRum.addAction('tts-play', {
        time,
        eventKey: getters.eventKey,
        library: state.activeLibrary,
      });
    },

    /**
     * Pause the audio file.
     * @param {object} store
     * @param {number} [currentTime] The current time in the audio file.
     */
    handlePause({
      commit, dispatch, getters, state,
    }, currentTime) {
      const time = currentTime ?? state.currentTime;
      const lastAction = new Date().toISOString();
      commit('setSegmentEnd', time);
      commit('setPlaying', false);
      commit('setCurrentTime', time);

      window.gfa.send('library_listen', {
        eventKey: getters.eventKey,
        libraryID: state.activeLibrary.id,
        segment: getters.segment,
        firstAction: state.loadTime,
        lastAction,
        totalSeconds: state.duration,
      });

      datadogRum.addAction('tts-pause', {
        time,
        eventKey: getters.eventKey,
        library: state.activeLibrary,
      });

      dispatch('persistCurrentTime', time);
    },

    /**
     * Stop the audio file.
     * @param {object} store
     * @param {number} [currentTime] The current time in the audio file.
     */
    handleStop({
      commit, dispatch, getters, state,
    }, currentTime) {
      const time = currentTime ?? state.currentTime;
      const lastAction = new Date().toISOString();

      commit('setSegmentEnd', currentTime);
      commit('setPlaying', false);
      commit('setCurrentTime', time);

      window.gfa.send('library_listen', {
        eventKey: getters.eventKey,
        libraryID: state.activeLibrary.id,
        segment: getters.segment,
        isOpen: false,
        firstAction: state.loadTime,
        lastAction,
        totalSeconds: state.duration,
      });

      datadogRum.addAction('tts-stop', {
        time,
        eventKey: getters.eventKey,
        library: state.activeLibrary,
      });

      dispatch('persistCurrentTime', time);
    },

    /**
     * End the audio file. This is mostly for the audio component.
     * @param {object} store
     * @param {number} [currentTime] The current time in the audio file.
     */
    handleEnd({
      commit, dispatch, getters, state,
    }, currentTime) {
      const time = currentTime ?? state.currentTime;
      const lastAction = new Date().toISOString();
      commit('setSegmentEnd', time);
      commit('setPlaying', false);
      commit('setCurrentTime', time);

      window.gfa.send('library_listen', {
        eventKey: getters.eventKey,
        libraryID: state.activeLibrary.id,
        segment: getters.segment,
        isOpen: false,
        firstAction: state.loadTime,
        lastAction,
        totalSeconds: state.duration,
      });

      datadogRum.addAction('tts-audio-end', {
        time,
        eventKey: getters.eventKey,
        library: state.activeLibrary,
      });

      dispatch('clearPersistedTime');
    },

    /**
     * Udpate the current position of the player.
     * @param {object} store
     * @param {object} [options] Options for the update.
     * @param {boolean} [options.paused] Whether the player is paused.
     * @param {number} [options.currentTime] The current time in the audio file.
     * @param {boolean} [options.userInteraction] Whether the update was triggered by a user interaction.
     */
    handleUpdate({ commit, getters, state }, { paused, currentTime, userInteraction } = {}) {
      const time = currentTime ?? state.currentTime;
      const isPaused = paused ?? !state.playing;
      const newBackupEnd = isPaused ? null : time;
      commit('setBackupEnd', newBackupEnd);
      commit('setCurrentTime', time);
      window.gfa.setLibraryBackupSegment(getters.eventKey, state.backupSegment, time);
      if (userInteraction) {
        datadogRum.addAction('tts-update', {
          time,
          paused: isPaused,
          eventKey: getters.eventKey,
          library: state.activeLibrary,
        });
      }
    },

    /**
     * Set the volume of the audio player. This persists across different files.
     * @param {object} store
     * @param {number} [volume] The desired volume.
     */
    updateVolume({ commit }, volume, userInteraction = true) {
      commit('setVolume', volume ?? 1);
      window.localStorage.setItem('audio-player-volume', `${volume ?? 1}`);
      if (userInteraction) {
        datadogRum.addAction('tts-change-volume', {
          volume: volume ?? 1,
        });
      }
    },

    /**
     * Load the volume from local storage.
     * @param {object} store
     */
    loadVolume({ commit }) {
      const volume = window.localStorage.getItem('audio-player-volume');
      if (volume) {
        commit('setVolume', parseFloat(volume), false);
      } else {
        commit('setVolume', 1, false);
        window.localStorage.setItem('audio-player-volume', '1');
      }
    },

    persistCurrentTime({ getters }, currentTime) {
      if (getters.storageKey && currentTime !== undefined && currentTime !== null) {
        try {
          window.localStorage.setItem(getters.storageKey, currentTime);
        } catch (e) {
          datadogLogs.logger.error('Media player update time error', {}, e);
        }
      }
    },

    loadPersistedTime({ getters, commit }) {
      if (getters.storageKey) {
        try {
          const time = window.localStorage.getItem(getters.storageKey);
          if (time && isNumber(time)) {
            commit('setCurrentTime', parseFloat(time));
          }
        } catch (e) {
          datadogLogs.logger.error('Media player read time error', {}, e);
        }
      }
    },

    clearPersistedTime({ getters }) {
      if (getters.storageKey) {
        window.localStorage.removeItem(getters.storageKey);
      }
    },
  },

  namespaced: true,
};
