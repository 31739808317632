<template>
  <v-dialog
    :value="shouldShowPasswordPrompt && value"
    max-width="450px"
    width="100%"
    persistent
  >
    <v-card>
      <v-card-title>
        Security
      </v-card-title>
      <v-card-text>
        You have signed in using a one-time password.
        <br>
        <br>
        Please proceed to change your password.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="redirect"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OTPasswordPrompt',
  props: {
    // extra parameters to pass to the dialog to disable prompt showing
    value: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('user', ['shouldShowPasswordPrompt']),
  },
  methods: {
    redirect() {
      window.location.href = '/password_change/';
    },
  },
};
</script>
