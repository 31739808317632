<template>
  <v-card
    :flat="flat"
    :color="color"
    class="d-flex flex-column justify-center pt-6"
    :height="height"
    :class="$isMobile ? 'pb-4 elevation-1' : 'pb-6'"
  >
    <v-card-text
      class="text-center py-0"
      :class="$isMobile ? 'px-4' : 'px-6'"
    >
      <v-icon
        v-if="icon"
        size="80"
        color="accent"
        :class="iconClass"
      >
        {{ icon }}
      </v-icon>
      <h5
        data-qa="take-action-card-title"
        class="mb-2 text-h5 grey--text text--darken-4"
        style="white-space: break-spaces"
      >
        {{ header }}
      </h5>
      <p
        style="white-space: pre-line;"
        class="text-body-1 grey--text text--darken-3"
        :class="showButton && !removeMessageMargin ? 'mb-4' : 'mb-0'"
      >
        {{ message }}
      </p>
    </v-card-text>
    <v-card-actions
      v-if="$slots.default"
      class="justify-center flex-wrap py-0"
    >
      <slot />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    flat: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '',
    },
    imgMaxHeight: {
      type: Number,
      default: 80,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    iconClass: {
      type: String,
      default: 'mb-4',
    },
    removeMessageMargin: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
  },
};
</script>
