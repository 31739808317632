import { postRequest } from '@/helpers/request';

export async function apiGetCommodityPersonalizationDataByCropYearId(cropYearId) {
  const data = await postRequest('/budgeting/ai_advisor/get_commodity_personalization_data/', {
    crop_year: cropYearId,
  });
  return data;
}

export async function apiGetCashFlowPersonalizationDataByCropYearId(cropYearId) {
  const data = await postRequest('/budgeting/ai_advisor/get_cash_flow_personalization_data/', {
    crop_year: cropYearId,
  });
  return data;
}

export async function apiGetInventoryPersonalizationDataByCropYearId(cropYearId) {
  const data = await postRequest('/budgeting/ai_advisor/get_inventory_personalization_data/', {
    crop_year: cropYearId,
  });
  return data;
}

export async function apiCreateSalePercentageGoals(payload) {
  const data = await postRequest('/budgeting/ai_advisor/upsert_sale_percent_goals/', payload);
  return data;
}

export async function apiGetSmartAdvisorProductions(payload) {
  const data = await postRequest('/budgeting/ai_advisor/get_smart_advisor_productions/', payload);
  return data;
}

export async function apiCreateAADProductions(payload) {
  const data = await postRequest('/budgeting/ai_advisor/create_ai_advisor_productions/', payload);
  return data;
}

export async function apiUpdateAADProduction(payload) {
  const data = await postRequest('/budgeting/ai_advisor/update_ai_advisor_production/', payload);
  return data;
}

export async function apiUpdateAADProductions(payload) {
  const data = await postRequest('/budgeting/ai_advisor/update_ai_advisor_productions/', payload);
  return data;
}

export async function apiSyncAADProductions(payload) {
  const data = await postRequest('/budgeting/ai_advisor/sync_all/', payload);
  return data;
}

export async function apiDeleteAADProduction(payload) {
  const data = await postRequest('/budgeting/ai_advisor/delete_ai_advisor_production/', payload);
  return data;
}

export async function apiCreateStorageConstraint(payload) {
  const data = await postRequest('/budgeting/ai_advisor/create_storage_constraint/', payload);
  return data;
}

export async function apiUpdateStorageConstraint(payload) {
  const data = await postRequest('/budgeting/ai_advisor/update_storage_constraint/', payload);
  return data;
}

export async function apiDeleteStorageConstraint(id) {
  const data = await postRequest('/budgeting/ai_advisor/delete_storage_constraint/', { id });
  return data;
}

export async function apiFetchAADCommodities() {
  const data = await postRequest('/budgeting/ai_advisor/get_aad_commodities/');
  return data;
}

export async function apiGetAggregatedFarmProfileData(cropYearId, unitId) {
  const data = await postRequest('/budgeting/ai_advisor/get_aggregated_farm_profile_data/', {
    crop_year: cropYearId,
    unit: unitId,
  });
  return data;
}
