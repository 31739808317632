<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="400px"
      width="100%"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          small
          v-on="on"
        >
          <v-icon>
            mdi-cog
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Weather Settings</v-card-title>
        <v-card-text class="pa-5">
          <v-row justify="center">
            <v-col
              cols="12"
            >
              <v-radio-group
                v-model="selectedLocationType"
                column
                hide-details="auto"
              >
                <v-radio
                  label="Farm Location"
                  color="primary"
                  value="farm"
                />
                <v-radio
                  label="Region"
                  color="primary"
                  value="region"
                />
                <v-radio
                  label="Coordinates"
                  color="primary"
                  value="coordinates"
                />
              </v-radio-group>
            </v-col>
            <v-col
              v-if="selectedLocationType === 'region'"
              cols="12"
            >
              <v-autocomplete
                v-model="selectedRegion"
                label="Select your location"
                outlined
                hide-details
                :items="formattedLocations"
              />
            </v-col>
            <v-col
              v-else-if="selectedLocationType === 'coordinates'"
              cols="12"
            >
              <v-btn @click="requestUserLocation">
                Use my location
              </v-btn>
              <p
                v-if="locationError"
                class="red--text text--darken-3 pt-3"
              >
                {{ locationError }}
              </p>
            </v-col>
            <v-col
              v-else-if="selectedLocationType === 'farm'"
              cols="12"
            >
              <v-select
                v-if="farmLocations"
                v-model="dashboardFarmLocation"
                :items="farmLocations"
                label="Farm Locations"
                outlined
                hide-details
                item-text="name"
                :item-value="farmLocationValue"
                :menu-props="{ bottom: true, offsetY: true }"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            light
            color="primary"
            :disabled="!isFormDirty"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      locationError: '',
      isFormDirty: false,
    };
  },

  computed: {
    selectedRegion: {
      get() {
        return this.selectedLocation;
      },
      set(val) {
        this.setSelectedLocation(val);
        this.activateForm();
      },
    },

    selectedLocationType: {
      get() {
        return this.selectedLocationTypeData;
      },
      set(val) {
        this.setUsingRegionData(val);
        this.activateForm();
      },
    },

    formattedLocations() {
      return this.allLocations.map((val) => ({
        text: val.name,
        value: {
          code: val.code,
          provinceCode: val.provinceCode,
        },
      }));
    },

    dashboardFarmLocation: {
      get() {
        return this.dashboardSelectedFarmLocation;
      },
      set(val) {
        this.setDashboardSelectedFarmLocation(val);
        this.activateForm();
      },
    },

    ...mapState('weather', [
      'allLocations',
      'farmLocations',
      'selectedLocation',
      'selectedLocationTypeData',
      'selectedCoordinates',
      'dashboardSelectedFarmLocation',
    ]),
  },

  created() {
    if (this.farmLocations.length === 0) {
      this.loadFarmLocation();
    }
  },

  methods: {
    activateForm() {
      switch (this.selectedLocationTypeData) {
        case 'farm':
          if (this.dashboardSelectedFarmLocation) {
            this.isFormDirty = true;
          } else {
            this.isFormDirty = false;
          }
          break;
        case 'region':
          if (this.selectedLocation) {
            this.isFormDirty = true;
          } else {
            this.isFormDirty = false;
          }
          break;
        case 'coordinates':
          if (this.selectedCoordinates) {
            this.isFormDirty = true;
          } else {
            this.isFormDirty = false;
          }
          break;
        default:
      }
    },

    farmLocationValue(val) {
      return { latitude: val.latitude, longitude: val.longitude };
    },

    requestUserLocation() {
      const showPosition = (position) => {
        const latitude = position.coords.latitude.toFixed(6);
        const longitude = position.coords.longitude.toFixed(6);

        this.setSelectedCoordinates({ latitude, longitude });
        this.activateForm();
      };

      const showError = (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            this.locationError = 'Location permission needs to be turned on.';
            break;
          case error.POSITION_UNAVAILABLE:
            this.locationError = 'Location information is unavailable.';
            break;
          case error.TIMEOUT:
            this.locationError = 'The request to get your location timed out. Please try again!';
            break;
          case error.UNKNOWN_ERROR:
            this.locationError = 'An unknown error occurred.';
            break;
          default:
        }
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition.bind(this), showError.bind(this));
      } else {
        this.locationError = 'Geolocation is not supported by this browser.';
      }
    },

    save() {
      this.dialog = false;
      this.requestWeatherDetails();
    },

    ...mapMutations('weather', [
      'setSelectedLocation',
      'setUsingRegionData',
      'setSelectedCoordinates',
      'setDashboardSelectedFarmLocation',
      'setDataErrorSnackbar',
    ]),

    ...mapActions('weather', [
      'requestWeatherDetails',
      'loadFarmLocation',
    ]),
  },
};
</script>
