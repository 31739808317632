<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <FingerprintField />
    <v-card
      width="100%"
      max-width="500"
      class="mx-auto"
    >
      <v-card-title>
        <account-picture-activator class="mx-auto" />
      </v-card-title>
      <v-card-text
        class="mt-4 pb-0"
      >
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="firstNameData"
              :rules="nameRules"
              label="First Name"
              name="first_name"
              outlined
              required
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="lastNameData"
              :rules="nameRules"
              label="Last Name"
              name="last_name"
              outlined
              required
            />
          </v-col>
        </v-row>
        <v-row
          dense
          :class="pendingEmailData.length !== 0 ? 'mb-6' : ''"
        >
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="emailData"
              :rules="emailRules"
              label="E-mail"
              name="email"
              outlined
              required
              readonly
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          v-if="pendingEmailData.length === 0"
          dense
          class="mb-2"
        >
          <v-spacer />
          <v-btn
            class="mr-1"
            text
            small
            href="/email_change/"
          >
            Change Email
          </v-btn>
        </v-row>
        <v-row
          v-if="pendingEmailData.length > 0"
          dense
        >
          <v-col cols="12">
            <v-text-field
              v-model="pendingEmailData"
              label="Pending E-mail"
              readonly
              outlined
              append-icon="mdi-close"
              @click:append="emitExpireECT"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
          >
            <phone-number-input
              v-model="phoneData"
              label="Phone Number"
              name="phone_number"
              placeholder="(XXX) XXX-XXXX"
              outlined
              required
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <phone-number-input
              v-model="secondaryPhoneData"
              label="Cell Phone Number"
              name="secondary_phone_number"
              placeholder="(XXX) XXX-XXXX"
              outlined
            />
          </v-col>
        </v-row>
        <div
          v-if="isApp"
          class=" mt-3 mb-10"
        >
          <div class="body-text-1 font-weight-bold primary-grey--text mb-6">
            Settings
          </div>
          <div
            class="d-flex justify-space-between cursor-pointer"
            @click.prevent="handleDeleteClick"
          >
            <a
              data-qa="self-serve-account-delete-button"
              class="mx-2 text-body-2 my-auto primary-grey--text"
            >
              Delete account
            </a>
            <v-icon
              small
              class="mr-2"
            >
              mdi-chevron-right
            </v-icon>
          </div>
          <v-divider class="mt-4 px-4 lighten-4" />
        </div>
      </v-card-text>
      <v-divider v-if="errors" />
      <v-list>
        <v-list-item
          v-for="error in errors"
          :key="error.message"
          class="error-list error--text px-4"
        >
          <v-icon
            color="error"
            class="mr-1"
          >
            mdi-alert-circle
          </v-icon>
          <span class="error-list error--text px-2">
            Field '{{ getFieldName(error) }}': {{ error[0].message }}
          </span>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions
        class="pa-4"
      >
        <!-- <v-btn
          v-if="!isMimic"
          :disabled="contacted"
          color="error"
          outlined
          @click="emitDelete"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          Delete Account
        </v-btn> -->
        <v-spacer />
        <v-btn
          class="mx-auto"
          color="primary"
          :disabled="disabled"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <delete-profile-modal
      v-model="deleteProfileOpen"
      @handle-delete="openAccountHolderDeleteProfileModal"
    />
    <account-holder-delete-profile-modal
      v-model="accountHolderDeleteProfileOpen"
    />
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import AccountPictureActivator from '../account/AccountPictureActivator.vue';
import FingerprintField from '@/components/form/FingerprintField.vue';
import PhoneNumberInput from '@/components/generic/PhoneNumberInput.vue';
import DeleteProfileModal from '@/components/account/DeleteProfileModal.vue';
import AccountHolderDeleteProfileModal from '@/components/account/AccountHolderDeleteProfileModal.vue';
import { validateLatinUnicodeName, containsALetterRule } from '../../helpers/rules';

export default {
  components: {
    AccountPictureActivator,
    FingerprintField,
    DeleteProfileModal,
    AccountHolderDeleteProfileModal,
    PhoneNumberInput,
  },

  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    pendingEmail: {
      type: String,
      required: true,
    },
    contacted: {
      type: Boolean,
      required: true,
    },
    secondaryPhone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      firstNameData: this.firstName,
      lastNameData: this.lastName,
      emailData: this.email,
      phoneData: this.phone !== 'None' ? this.phone : '',
      pendingEmailData: this.pendingEmail,
      secondaryPhoneData: this.secondaryPhone !== 'None' ? this.secondaryPhone : '',
      requestData: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        validateLatinUnicodeName,
        containsALetterRule,
      ],
      phoneRules: [
        (v) => v === '' || (v?.match(/\d+/g) || []).join('').length === 10 || 'Please use a valid phone number',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
      ],
      showToolTip: false,
      errors: null,
      deleteProfileOpen: false,
      accountHolderDeleteProfileOpen: false,
    };
  },

  computed: {
    disabled() { // prevents empty forms from being submitted
      return (!this.valid)
        || !(this.firstNameData !== '')
        || !(this.lastNameData !== '')
        || !(this.emailData !== '');
    },

    ...mapState('app', ['isMimic', 'isApp']),
  },

  methods: {
    validateLatinUnicodeName,
    containsALetterRule,
    emitExpireECT() {
      this.$emit('expire');
      this.pendingEmailData = '';
    },
    emitDelete() {
      this.$emit('delete-account');
    },
    submit(e) {
      if (!this.$refs.form.validate()) {
        e.preventDefault();
        return;
      }
      this.sendUserProfileInfo();
    },
    async sendUserProfileInfo() {
      // build request data
      this.requestData = {
        email: this.emailData,
        first_name: this.firstNameData,
        last_name: this.lastNameData,
        phone_number: this.phoneData,
        secondary_phone_number: this.secondaryPhoneData,
      };

      this.$emit('snack-bar-warning', 'Updating Account Information...');

      // eslint-disable-next-line no-undef
      const data = await API.updateUserProfileInfo(this.requestData);
      if (data.is_valid) {
        this.$emit('snack-bar-success', data.message);
        this.errors = null;
        if (data.pending_email) {
          this.pendingEmailData = data.pending_email;
        }
      } else {
        this.$emit('snack-bar-error', data.message);
        this.errors = JSON.parse(data.form_errors);
      }
      this.requestData = null;
    },
    getFieldName(error) {
      let fieldName = '';
      const header = Object.keys(this.errors).find(
        (key) => this.errors[key] === error,
      );

      if (header === 'first_name') {
        fieldName = 'First Name';
      } else if (header === 'last_name') {
        fieldName = 'Last Name';
      } else if (header === 'email') {
        fieldName = 'E-mail';
      } else if (header === 'phone_number') {
        fieldName = 'Phone Number';
      } else if (header === 'secondary_phone_number') {
        fieldName = 'Cell Phone Number';
      }
      return fieldName;
    },

    handleDeleteClick() {
      this.deleteProfileOpen = true;
    },

    openAccountHolderDeleteProfileModal() {
      this.accountHolderDeleteProfileOpen = true;
    },
  },
};
</script>
<style scoped lang="scss">
@use "@/colors/colors";
.grey-lighten-4 {
  color: colors.$greyLighten4 !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
