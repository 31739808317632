/**
 * Note: Because the /account/ prefix is used for the CSS Module,
 * we can't make child routes here without naming conflicts.
 * Also, we will want to make the title for all these tabs
 * just "Settings" because there are already tabs with
 * the more specific title.
 *
 * I would recommend the following URL patterns:
 *    CSS Module
 *      Concerned with CSS management pages
 *      <root>/css_module/
 *    Account (Organization)
 *      Concerned with organization-wide settings
 *      <root>/account/
 *    User
 *      Concerned with user-level settings
 *      <root>/user/
 */
const routes = [
  {
    path: '/account/profile/',
    meta: {
      title: 'Settings',
    },
  },
  {
    path: '/account/notification_settings/',
    meta: {
      title: 'Settings',
    },
  },
  {
    path: '/account/preferences/',
    meta: {
      title: 'Settings',
    },
  },
  {
    path: '/account/security/',
    meta: {
      title: 'Settings',
    },
  },
  /**
   * This one is a bit different since it's accessed via My Profile,
   * but there's no URL or visual connection, it's a standalone page.
   * The URL and title text are also swapped for some reason.
   */
  {
    path: '/email_change/',
    meta: {
      title: 'Change Email',
    },
  },
];

export default routes;
