import { dateFormatDateString, formatNumberEnforceDecimals } from './formatting';
import { getTodayYearMonthDate } from './datetime';

/**
 * @method convertDateRange() Convert crop year to date range
 * @param selectedYears crop year object
 * @returns {[Date, Date]} Date array
 */
export function convertDateRange(selectedYears) {
  const [firstYear, secondYear] = selectedYears.long_name.split('-');
  return [
    new Date(firstYear, 7, 1),
    new Date(secondYear, 7, 1),
  ].map(dateFormatDateString);
}

export function formatUnit(value, abbreviation) {
  if (typeof value === 'string') {
    return value;
  }
  return `${formatNumberEnforceDecimals(value, 0)} ${abbreviation}`;
}

export function getAdjustmentDate(cropYear) {
  const date = getTodayYearMonthDate();

  if (cropYear.end >= date) {
    return new Date(date);
  }

  const endYear = new Date(cropYear.end).getFullYear();
  return new Date(endYear, 0, 1);
}

export function getFileNameFromPath(path) {
  return path ? path.substring(path.lastIndexOf('/') + 1) : '';
}
