import { postRequest } from '../helpers/request';
import debounce from '../helpers/debounce';
import { datadogLogs } from '@datadog/browser-logs';

function playSound(fileName) {
  const audio = new Audio(fileName);
  audio.play();
}

async function triggerNewMessageSound(fileName) {
  try {
    const canNotify = await postRequest('/account/api/check_allowed_messenger_audio/');
    if (canNotify.allowed) {
      playSound(fileName);
    }
  } catch (e) {
    datadogLogs.logger.error('New chat message audio error', {}, e);
  }
}

export default {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store');
    }

    const debouncedTriggerNewMessageSound = debounce(triggerNewMessageSound, 1000);

    store.subscribe((mutation) => {
      if (mutation.type === 'chat/signalNewMessage') {
        debouncedTriggerNewMessageSound('/static/dashboard/audio/newMessageSound.mp3');
      }
    });
  },
};
