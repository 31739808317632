<template>
  <GDialog
    title="Navigating outside the app"
    disable-fullscreen
    :action-buttons="actionButtons"
    :value="value"
    @cancel="closeDialog"
  >
    <template v-slot:default>
      <p class="font-weight-bold text-center">
        You will be redirected to GrainFox in your mobile browser.
        You will be asked to re-enter your username and password.
      </p>
      <p class="text-center align-center">
        Please tap "Continue" to view your billing information.
      </p>
    </template>
  </GDialog>
</template>

<script>
import GDialog from '@/components/generic/GDialog.vue';

export default {
  name: 'NavigateOutsideAppDialog',
  components: { GDialog },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      actionButtons: [
        {
          name: 'Cancel',
          type: 'primary',
          side: 'right',
          onClick: this.closeDialog,
        },
        {
          name: 'Continue',
          type: 'primary',
          side: 'right',
          onClick: this.goToRoute,
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
    goToRoute() {
      if (this.to) {
        window.location.href = this.to;
      }
    },
  },
};
</script>
