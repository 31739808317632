<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        :sm="isApplyCrop ? '6' : '12'"
      >
        <GAutocomplete
          v-model="state.publicationId"
          label="Publication"
          :hint="publicationHint"
          item-value="id"
          item-text="name"
          :items="state.allPublications"
          :rules="[requiredValueRule('Publication')]"
          :menu-props="{ bottom: true, offsetY: true }"
          persistent-hint
          outlined
        >
          <template #item="{ item }">
            <div class="my-2">
              <p class="ma-0">
                {{ item.name }}
              </p>

              <p class="ma-0 text-caption grey--text text--darken-1">
                {{ item.is_internal | internalOnly }}
              </p>
            </div>
          </template>
        </GAutocomplete>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-if="isApplyCrop"
          v-model="state.crop"
          label="Crop"
          outlined
        />
      </v-col>
    </v-row>
    <v-text-field
      v-model="state.subheader"
      label="Tagline"
      counter
      maxlength="50"
      :rules="[requiredValueRule('Tagline')]"
      outlined
    />

    <GAutocomplete
      v-model="state.topicIds"
      label="Topic"
      item-value="id"
      item-text="topic"
      :items="state.allTopics"
      :menu-props="{ bottom: true, offsetY: true }"
      outlined
      multiple
    />

    <GAutocomplete
      v-model="state.writerIds"
      label="Writers"
      item-value="id"
      item-text="name"
      :items="state.allWriters"
      :rules="[
        requiredValueRule('Writer'),
        requiredArrayRule('Writer'),
      ]"
      :menu-props="{ bottom: true, offsetY: true }"
      outlined
      multiple
    />

    <v-select
      v-if="!state.isPublished"
      v-model="state.isScheduled"
      label="Publication Time"
      :items="publicationTimeCategory"
      :rules="[
        requiredValueRule('Publication Time'),
      ]"
      :menu-props="{ bottom: true, offsetY: true }"
      outlined
    />

    <v-expand-transition>
      <v-row
        v-if="!state.isPublished && state.isScheduled"
        dense
      >
        <v-col cols="12">
          <v-menu
            ref="publishDatePicker"
            v-model="publishDatePicker"
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="state.publishDate"
                label="Publish Date"
                :rules="[requiredValueRule('Publish Date')]"
                :error="Boolean(publishDateTimeError)"
                readonly
                outlined
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="state.publishDate"
              color="primary"
              @change="timeWasChanged"
              @input="publishDatePicker = false"
            />
          </v-menu>
        </v-col>

        <v-col cols="12">
          <GTimePicker
            v-model="state.publishTime"
            :error="Boolean(publishDateTimeError)"
            @change="timeWasChanged"
          />
        </v-col>
        <v-col
          v-if="publishDateTimeError"
          cols="12"
          class="mt-n8 mb-4 text-subtitle-2 error--text"
        >
          {{ publishDateTimeError }}
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-checkbox
      v-model="state.sendNotification"
      label="Notify members"
      class="mt-0 mb-4"
      hide-details
    />

    <div
      v-if="publication"
      class="mb-8"
    >
      <v-checkbox
        v-if="showAllowSharing"
        v-model="state.allowSharing"
        label="Allow members to share"
        class="mt-0 mb-4"
        hide-details
      />
    </div>
  </div>
</template>

<script>
import GAutocomplete from '@/components/generic/GAutocomplete.vue';
import GTimePicker from '@/components/generic/GTimePicker.vue';
import { requiredValueRule, requiredArrayRule } from '../../helpers/rules';
import {
  getLocalDateTime,
  getLocalDateTimeStringsFromISOString,
} from '../../helpers/formatting';

export default {
  components: {
    GAutocomplete,
    GTimePicker,
  },

  filters: {
    internalOnly(value) {
      return value ? 'Internal' : 'External';
    },
  },

  props: {
    state: {
      type: Object,
      default: () => ({
        isDraft: false,
      }),
    },
  },

  data() {
    return {
      isFormValid: false,

      publishDatePicker: null,
      publishTimeSetManually: false,
      publishTimeUpdater: null,

      publicationTimeCategory: [
        {
          value: false,
          text: 'Publish now',
        },
        {
          value: true,
          text: 'Schedule for later',
        },
      ],
    };
  },

  computed: {
    publication() {
      return this.state.allPublications
        .find((x) => x.id === this.state.publicationId);
    },

    isApplyCrop() {
      if (!this.state.publicationId) return false;
      return this.state.allPublications
        .find((x) => x.id === this.state.publicationId)
        .is_title_crop_specific;
    },

    publicationHint() {
      if (this.publication) return this.publication.is_internal ? 'Internal' : 'External';
      return '';
    },

    showAllowSharing() {
      return this.publication && !this.publication.is_internal;
    },

    noticeText() {
      return this.publication.is_internal
        ? 'internally.'
        : `externally with ${this.publication.user_count} members`;
    },

    publishDateTimeError() {
      const date = getLocalDateTime(this.state.publishDate, this.state.publishTime);
      const now = new Date();

      // Adding buffer of 2 minutes
      now.setMinutes(now.getMinutes() - 2, 0, 0);

      return date < now && 'Publish date & time should be in the future';
    },
  },

  watch: {
    'publication.name': {
      handler() {
        this.state.name = this.publication ? this.publication.name : '';
      },
    },

    'state.crop': {
      handler(newVal) {
        if (newVal) {
          const crop = newVal.trim();
          const publication = (this?.publication?.name ?? '').trim();
          this.state.name = [crop, publication].join(' ').trim();
        }
      },
    },
    'state.publicationId': {
      handler() {
        this.state.crop = '';
      },
    },

    'state.isScheduled': {
      handler() {
        if (!this.state.isScheduled) this.setCurrentDateTime();
      },
    },
  },

  mounted() {
    this.autoUpdateTime();

    this.publishTimeUpdater = setInterval(() => {
      if (this.publishTimeSetManually) {
        clearInterval(this.publishTimeUpdater);
        return;
      }
      this.autoUpdateTime();
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.publishTimeUpdater);
  },

  methods: {
    requiredValueRule,
    requiredArrayRule,

    timeWasChanged() {
      this.publishTimeSetManually = true;
    },

    autoUpdateTime() {
      if (!this.state.isScheduled && !this.state.isPublished) this.setCurrentDateTime();
    },

    setCurrentDateTime() {
      const [
        publishDate,
        publishTime,
      ] = getLocalDateTimeStringsFromISOString(new Date().toISOString());
      this.state.publishDate = publishDate;
      this.state.publishTime = publishTime;
    },
  },
};
</script>
