<template
  v-bind="{...$attrs}"
>
  <v-row
    dense
  >
    <v-col
      cols="5"
      sm="3"
    >
      <v-select
        label="Hour"
        :value="hour"
        :items="hours"
        :rules="hourRules"
        :error="$attrs.error"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        @input="handleHours"
      />
    </v-col>
    <v-col
      cols="2"
      sm="1"
      class="text-center pt-4"
    >
      <b>:</b>
    </v-col>
    <v-col
      cols="5"
      sm="3"
    >
      <v-select
        label="Minute"
        :value="minute"
        :items="minutes"
        :rules="minuteRules"
        :error="$attrs.error"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        @input="handleMinutes"
      />
    </v-col>
    <v-col
      cols="12"
      sm="5"
    >
      <v-select
        label="AM/PM"
        :value="postMeridian"
        :items="meridian"
        item-text="text"
        item-value="value"
        :rules="AMPMRules"
        :error="$attrs.error"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        @input="handleMeridian"
      />
    </v-col>
  </v-row>
</template>

<script>
import { requiredValueRule, requiredBoolRule, numericRule } from '../../helpers/rules';

/**
 * @param {Number} n number to count to (exclusive)
 * @returns {Number[]} An array from 0 to n-1
 */
function ascendingArray(n) {
  return [...Array(n).keys()];
}

/**
 * Pads a 1-digit number with a leading 0.
 * Expected that this just be used for numbers 1-59
 * @param {Number|String} n The number to pad
 * @returns {String} The number as string, either unchanged (e.g. '11'), or padded (e.g. '01')
 */
function padZeros(n) {
  // We want minutes to always be 2 digit: 00, 01, 02, ... 10, 11, ... 30, etc.
  return (`${n}`).padStart(2, 0);
}

const PM_HOUR = 12; // any 24-hour time after 11 is considered PM
const HOUR_START = 0;
const HOUR_END = 2;
const MINUTE_START = 3;
const MINUTE_END = 5;

export default {

  props: {
    // value is a time string of form HH:MM, using 24 hour time
    value: {
      type: String,
      required: false,
      default: '12:00',
    },
  },

  data() {
    return {
      hours: ascendingArray(12).map((x) => x + 1).map(padZeros), // [1, 2, ... , 11, 12]
      minutes: ascendingArray(60).map(padZeros), // [0, 1, ... , 58, 59]
      meridian: [
        { text: 'AM', value: false },
        { text: 'PM', value: true },
      ],
      hourRules: [
        requiredValueRule('Hour'),
        numericRule('Hour'),
      ],
      minuteRules: [
        requiredValueRule('Minute'),
        numericRule('Minute'),
      ],
      AMPMRules: [
        requiredBoolRule('AM/PM'),
      ],
    };
  },

  computed: {

    // Functions unpack a 24-hour timestring HH:MM into 12 hour time

    /**
     * @returns {String} the HH part of HH:MM
     */
    rawHour() {
      return this.value.slice(HOUR_START, HOUR_END);
    },

    /**
     * @returns {String} the MM part of HH:MM
     */
    rawMinute() {
      return this.value.slice(MINUTE_START, MINUTE_END);
    },

    /**
     * Extracts the hour from `value` and formats into 12 hour time
     * @returns {String} The hour, examples: 05 -> 05, 13 -> 01, 23 -> 11
     */
    hour() {
      /**
       * 00 -> 12am
       * 01 -> 1am
       * 02 -> 2am
       * ...
       * 11 -> 11am
       * 12 -> 12pm
       * 13 -> 1pm
       * ...
       * 22 -> 10pm
       * 23 -> 11pm
       */

      let currentHour = parseInt(this.rawHour, 10);
      currentHour %= PM_HOUR;
      if (currentHour === 0) {
        currentHour += PM_HOUR;
      }
      return padZeros(currentHour);
    },
    /**
     * @returns {String} The minutes of `value`
     */
    minute() {
      return padZeros(this.rawMinute);
    },
    /**
     * Determine the AM/PM status of the 24 hour time string
     * @returns {Boolean} whether the 24 hour time is AM or PM, AM == `false`, PM == `true`
     */
    postMeridian() {
      const currentHour = parseInt(this.rawHour, 10);
      return currentHour >= PM_HOUR;
    },

  },

  methods: {
    handleHours(hourInput) {
      let hourTo = parseInt(hourInput, 10);
      hourTo %= 12;
      if (this.postMeridian) {
        hourTo += 12;
      }
      const timeString = `${padZeros(hourTo)}:${this.minute}`;
      this.$emit('input', timeString);
      this.$emit('change');
    },

    handleMinutes(minuteInput) {
      const timeString = `${this.rawHour}:${minuteInput}`;
      this.$emit('input', timeString);
      this.$emit('change');
    },

    handleMeridian(meridianInput) {
      let hourTo = parseInt(this.hour, 10);
      hourTo %= 12;
      if (meridianInput) {
        hourTo += 12;
      }
      const timeString = `${padZeros(hourTo)}:${this.minute}`;
      this.$emit('input', timeString);
      this.$emit('change');
    },
  },
};
</script>
