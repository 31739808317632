<template>
  <v-expansion-panels
    v-if="showNewFeaturePopup && $isDesktop"
    v-model="panel"
    :flat="panel !== 0"
    :readonly="panel !== 0 || newFeature.persistent"
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        disable-icon-rotate
        class="primary white--text py-0 pl-10 text-h5 font-weight-medium"
        :class="{'rounded-lg': panel !== 0, 'c-default': panel !== 0 || newFeature.persistent}"
      >
        {{ newFeature.featureTitle || 'New Feature Alert!' }}
        <template v-slot:actions>
          <v-icon
            v-if="panel == 0"
            color="white"
            :class="{'d-none': newFeature.persistent}"
            data-qa="new-feature-alert-close-button"
            @click="setShowExpansionPanel(false)"
          >
            mdi-close
          </v-icon>
          <v-btn
            v-else
            text
            class="white--text pr-2"
            @click.prevent.stop="openFeature"
          >
            {{ btnText }}
          </v-btn>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <new-feature-alert
          :is-loading="isLoading"
          data-qa="new-feature-alert-content"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import NewFeatureAlert from './NewFeatureAlert.vue';

export default {
  components: {
    NewFeatureAlert,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapState('dashboard', ['showExpansionPanel', 'showNewFeaturePopup', 'newFeature']),
    ...mapGetters('user', ['isAuthenticated']),

    panel: {
      get() {
        return this.showExpansionPanel ? 0 : null;
      },
      set(value) {
        this.setShowExpansionPanel(value === 0);
      },
    },

    btnText() {
      if (this.newFeature.featureButtonText) {
        return this.newFeature.featureButtonText;
      }
      return `Explore ${this.newFeature.featureName} now`;
    },
  },

  async created() {
    this.loadLocalStorage();
    await this.getLatestNewFeature();
    this.setShowNewFeaturePopup(this.newFeature.flag);
    this.isLoading = false;
  },

  methods: {
    ...mapMutations('dashboard', ['setShowExpansionPanel', 'setShowNewFeaturePopup']),
    ...mapActions('dashboard', ['loadLocalStorage', 'getLatestNewFeature', 'setNewFeatureViewedStatus']),

    openFeature() {
      this.setNewFeatureViewedStatus(this.newFeature.id);
      if (this.newFeature?.featureLink) {
        if (this.isExternalLink(this.newFeature.featureLink)) {
          window.open(this.newFeature.featureLink, '_blank');
        } else {
          window.location = this.newFeature.featureLink;
        }
      }
    },

    isExternalLink(url) {
      try {
        const urlObject = new URL(url);
        return urlObject.origin !== window.location.origin;
      } catch (e) {
        return false;
      }
    },
  },

};
</script>
<style scoped lang="scss">
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}
</style>
