<template>
  <v-dialog
    v-model="open"
    eager
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      tile
      class="d-flex flex-column preview-card"
      @copy="preventCopy"
    >
      <v-toolbar
        class="flex-grow-0 z--1"
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="flags['recommendation-aside'] && recommendedResourceHubArticles.length && $isDesktop"
          text
          class="primary-grey--text font-weight-medium"
          @click="nextRecommendedArticle"
        >
          NEXT RECOMMENDED ARTICLE
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          icon
          data-qa="close-preview-dialog"
          @click="closePreviewDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        ref="wrapper"
        class="preview-card__wrapper d-flex flex-column"
      >
        <teaser
          :show="showTeaser"
          :title="title.split('|')[0].trim()"
        >
          <WidgetLayout
            v-if="$isDesktop"
            :widgets="widgets"
            shadowless
            no-toolbar
            :desktop-content-col-width="6"
          >
            <template #desktopContent>
              <interval-highlighter
                ref="highlighter"
                :apply="applyHighlights"
                :value="currentTime"
              >
                <div
                  ref="content"
                  class="preview-card__content d-flex flex-column flex-shrink-0"
                  @dragstart="preventDrag"
                  @click="contentClickHandler"
                  @auxclick="contentClickHandler"
                />
              </interval-highlighter>
            </template>
            <template #recommendationAside>
              <div class="pt-16 mt-16" />
              <div class="pt-8" />
              <div class="pt-16" />
              <RecommendationAside
                :recommendations="recommendedArticles"
                class="pa-4 rounded-lg"
                style="border: 1px solid #F5F5F5;"
                @view="previewFile($event)"
              />
            </template>
          </WidgetLayout>
          <interval-highlighter
            v-else
            ref="highlighter"
            :apply="applyHighlights"
            :value="currentTime"
          >
            <div
              ref="content"
              class="preview-card__content d-flex flex-column flex-shrink-0"
              @dragstart="preventDrag"
              @click="contentClickHandler"
              @auxclick="contentClickHandler"
            />
          </interval-highlighter>
        </teaser>
        <div
          ref="comments"
          class="flex-shrink-0"
        >
          <v-divider />
          <v-container class="py-12">
            <template
              v-if="isRatingEnabled === true"
            >
              <div class="d-flex flex-wrap align-center mb-4">
                <h2 class="text-h6">
                  Rating
                </h2>
                <v-rating
                  v-model="rating"
                  color="primary"
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  hover
                  length="5"
                  size="24"
                  value="0"
                />
              </div>
            </template>
          </v-container>
        </div>
      </div>
    </v-card>
    <div class="d-none">
      <AudioInfix ref="audioInfix" />
    </div>
    <div class="d-none">
      <RecommendationCarousel
        ref="recommendationCarousel"
        :recommendations="recommendedArticles"
        @view="previewFile($event)"
      />
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/helpers/formatting';
import Teaser from './Teaser.vue';
import AudioInfix from './AudioInfix.vue';
import IntervalHighlighter from './IntervalHighlighter.vue';
import { datadogLogs } from '@datadog/browser-logs';
import RecommendationCarousel from './RecommendationCarousel.vue';
import WidgetLayout from '@/pages/farm_profile/WidgetLayout.vue';
import RecommendationAside from './RecommendationAside.vue';

async function getHH(m) {
  const e = new TextEncoder();
  const d = e.encode(m);
  const hb = await crypto.subtle.digest('SHA-256', d);
  const ha = Array.from(new Uint8Array(hb));
  const hh = ha.map((b) => (`00${b.toString(16)}`).slice(-2)).join('');
  return hh;
}

export default {
  components: {
    Teaser,
    AudioInfix,
    IntervalHighlighter,
    RecommendationCarousel,
    WidgetLayout,
    RecommendationAside,
  },

  data() {
    return {
      open: false,
      id: 0,
      title: '',
      isLoading: false,
      shared: true,
      rating: 0,
      isRatingEnabled: false,
      audio_dict: null,
      showTeaser: false,
      html: '',
      contentUrl: '',
      debouncedLibraryViewUpdate: null,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapState('mediaPlayer', ['currentTime', 'playing']),
    ...mapGetters('mediaPlayer', ['activeTTSFile']),
    ...mapState('recommendations', ['mlAPIRecommendations', 'recommendedArticles']),

    applyHighlights() {
      return this.flags['text-to-speech-highlighting'] && !!this.activeTTSFile && this.playing;
    },

    showRecommendationCarousel() {
      return this.flags['recommendation-carousel'] && this.recommendedArticles.length && !this.$isDesktop;
    },

    widgets() {
      return this.flags['recommendation-aside'] && this.recommendedArticles.length ? [{ key: 'recommendationAside' }] : [];
    },
    recommendedResourceHubArticles() {
      return this.recommendedArticles.filter((article) => article.source === 'grainfox');
    },
  },

  watch: {
    async rating(newValue) {
      await API.addRating(this.id, newValue);
    },

    async showTeaser(newVal) {
      if (newVal) {
        await this.$nextTick();
        this.applyHTML(this.html);
      }
    },

    open(newVal) {
      if (!newVal) {
        this.showTeaser = false;
        this.$refs.audioInfix.onClose?.();
      }
    },

    recommendedArticles() {
      if (this.showRecommendationCarousel) {
        const parentNode = this.$refs.content;
        this.$refs.recommendationCarousel.applyTo(parentNode);
      }
    },

    async $isDesktop() {
      await this.handleResize(this.$isDesktop);
    },

    async $isTablet() {
      await this.handleResize(this.$isTablet);
    },

    async $isMobile() {
      await this.handleResize(this.$isMobile);
    },
  },

  mounted() {
    this.restrictPrint();
    window.previewFile = this.previewFile;
  },

  methods: {
    ...mapMutations('mediaPlayer', ['setActiveLibrary']),
    ...mapActions('mediaPlayer', ['loadPersistedTime']),
    ...mapActions('recommendations', ['fetchRecommendedArticles']),
    ...mapMutations('recommendations', ['setRecommendedArticles']),

    removeHeaderImage() {
      const header = document.querySelector('.g-header');
      if (header) {
        // remove header img, make .g-header class's min height 0px, removed header divider
        header.style.backgroundImage = 'none';
        header.style.minHeight = '0px';
        header.style.border = 'none';
      }
    },

    applyAudioInfix() {
      const parentNode = this.$refs.content;
      this.$refs.audioInfix.applyTo(parentNode);
      this.$nextTick(() => {
        this.$refs.audioInfix.onOpen?.();
        this.applyHighlighter();
      });
    },

    applyHighlighter() {
      this.$refs.highlighter.initialize();
      this.$refs.highlighter.updateContainer();
    },

    applyHTML(htmlString) {
      this.$refs.content.innerHTML = htmlString;
      if (this.$isMobile) {
        this.removeHeaderImage();
      }
      this.applyAudioInfix();
    },

    async previewFile(id) {
      this.open = true;
      this.id = id;

      // Reset
      this.isLoading = true;
      this.title = 'Loading...';
      this.$refs.content.innerHTML = '';
      this.setRecommendedArticles([]);

      try {
        const data = await API.previewLibrary(id, this.$isMobile);
        const firstAction = new Date().toISOString();
        const {
          name,
          datePublished,
          shared,
          rating,
          ratingAllowed,
          showTeaser,
          contentId,
          showTTSTutorial,
          version,
          contentUrl,
        } = data;
        this.shared = shared;
        this.rating = rating;
        this.isRatingEnabled = ratingAllowed;
        this.showTeaser = showTeaser;
        this.contentUrl = contentUrl;
        // Update audio information
        this.audio_dict = {
          id: this.id,
          name: data.name,
          tagline: data.subheader,
          content: data.content,
          content_image: data.content_image,
          content_image_size: data.content_image_size,
          provider: data.provider,
          showTTSTutorial,
          contentId,
          audio_file_female: data.audio_file_female === 'None' ? null : data.audio_file_female,
          audio_file_male: data.audio_file_male === 'None' ? null : data.audio_file_male,
          showTeaser: this.showTeaser,
          version,
        };
        this.setActiveLibrary(this.audio_dict);
        this.loadPersistedTime();

        // Update the title
        this.title = `${name} | ${formatDate(new Date(datePublished))}`;

        // Prepare analytics
        this.attachScrollListener(id, firstAction);

        // Display content
        switch (data.type) {
          case 'html': {
            const htmlString = data.html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ''); // jQuery regex
            this.html = htmlString;
            this.applyHTML(this.html);
            this.debouncedLibraryViewUpdate();
            break;
          }
          case 'pdf': {
            if (Object.prototype.hasOwnProperty.call(data, 'name')
              && Object.prototype.hasOwnProperty.call(data, 'timestamp')
              && Object.prototype.hasOwnProperty.call(data, 'path')) {
              this.html = data.html;
              this.applyHTML(this.html);

              // Render PDF function will call this.debouncedLibraryViewUpdate();
              const hh = await getHH(data.name + data.timestamp);
              this.renderPDF(data.path, hh, this.id);
            }
            break;
          }
          case 'media': {
            // Parse the html as DOM nodes so we can attach event listeners
            // Once the image/video is fully loaded we can start accurately tracking interactions
            const html = this.generateHTMLFromString(data.html);
            html.addEventListener('load', this.debouncedLibraryViewUpdate);
            this.$refs.content.appendChild(html);
            break;
          }
          default: {
            break;
          }
        }
        // prepare recommendations, silently fail if there is an error
        try {
          if (!this.showTeaser && (this.flags['recommendation-aside'] || this.flags['recommendation-carousel'])) {
            await this.fetchRecommendedArticles(this.contentUrl);
          }
        } catch (e) {
          // Ignore
        }
      } catch (e) {
        this.$snackbar.error(e.toString());
      } finally {
        this.isLoading = false;
      }
    },
    renderPDF(path, vk, id) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';
      const loadingTask = window.pdfjsLib.getDocument({ url: path, password: vk });
      document.getElementById('pdf-preview').innerHTML = '';
      loadingTask.promise.then((pdf) => {
        const pages = [];
        const wrapperWidth = this.$refs.wrapper.clientWidth;
        const wrapperHeight = this.$refs.wrapper.clientHeight - 20;

        for (let i = 1; i <= pdf.numPages; i += 1) {
          pages.push(pdf.getPage(i).then((page) => {
            const pdfPreviewElement = document.getElementById('pdf-preview');
            if (this.id !== id || !pdfPreviewElement) {
              return;
            }
            const canvas = document.createElement('canvas');
            const { width, height } = page.getViewport({ scale: 1 });
            const pageWidthScale = wrapperWidth / width;
            const pageHeightScale = wrapperHeight / height;
            const scale = Math.min(pageWidthScale, pageHeightScale);
            const viewport = page.getViewport({ scale });
            const dpr = window.devicePixelRatio || 1;

            canvas.width = Math.floor(viewport.width * dpr);
            canvas.height = Math.floor(viewport.height * dpr);
            canvas.style.maxWidth = `${Math.floor(viewport.width)}px`;
            canvas.style.maxHeight = `${Math.floor(viewport.height)}px`;
            canvas.classList.add('elevation-2');
            pdfPreviewElement?.appendChild(canvas);

            const context = canvas.getContext('2d');
            context.scale(dpr, dpr);
            const renderContext = {
              canvasContext: context,
              viewport,
            };
            page.render(renderContext);
          }));
        }
        Promise.all(pages).then(() => {
          if (typeof this.debouncedLibraryViewUpdate === 'function') {
            this.debouncedLibraryViewUpdate();
          }
        });
      }).catch((e) => {
        datadogLogs.logger.error('File preview PDF error', {}, e);
      });
    },
    restrictPrint() {
      window.addEventListener('beforeprint', () => {
        this.$refs.wrapper.setAttribute('style', 'display:none!important');
      });

      window.addEventListener('afterprint', () => {
        this.$refs.wrapper.setAttribute('style', '');
      });
    },
    contentClickHandler(event) {
      if (event.target == null) {
        return;
      }

      const link = event.target.closest('a');

      if (link) {
        window.gfa.send('library_click', {
          id: this.id,
          first_action: new Date().toISOString(),
          url: link.href || '',
        });
      }
    },
    attachScrollListener(id, firstAction) {
      this.debouncedLibraryViewUpdate = window.gfa.debounce((event, callback, eventState = true) => {
        if (this.id !== id) {
          return;
        }
        // Get the raw scroll depth value, excluding the comments
        const scrollDepth = this.$refs.wrapper.scrollTop
        / (this.$refs.wrapper.scrollHeight
        - this.$refs.wrapper.clientHeight
        - this.$refs.comments.clientHeight);

        // If the scrollDepth calculation divides by zero, we get a Nan
        // Also, if we divide zero by a negative number, we get a negative zero
        // We assume both of these cases only occur for content that has no scroll height
        // Therefore, we fallback to a value of one to indicate the whole document was seen
        const scrollDepthFormatted = (Number.isNaN(scrollDepth) || Object.is(scrollDepth, -0))
          ? 1 : Number(scrollDepth.toFixed(2));

        // Clamp the scroll depth to prevent float rounding errors
        const scrollDepthClamped = Math.min(Math.max(scrollDepthFormatted, 0), 1);

        window.gfa.send('library_view', {
          id,
          first_action: firstAction,
          last_action: new Date().toISOString(),
          scroll_depth: scrollDepthClamped,
          open: Boolean(eventState),
        });

        if (callback) {
          callback();
        }
      }, 250);
      this.$refs.wrapper.addEventListener('scroll', this.debouncedLibraryViewUpdate);
    },
    generateHTMLFromString(domString) {
      const p = new DOMParser();
      return p.parseFromString(domString, 'text/html').querySelector('body > *:first-of-type');
    },
    closePreviewDialog() {
      // Use this to update the last_action time
      // TODO: GF-1357
      if (this.debouncedLibraryViewUpdate) {
        this.debouncedLibraryViewUpdate(null, () => {
          this.$refs.wrapper.removeEventListener('scroll', this.debouncedLibraryViewUpdate);
          this.debouncedLibraryViewUpdate = null;
          this.$refs.content.innerHTML = '';
        }, false);
      }

      this.open = false;
    },
    preventCopy(event) {
      event.preventDefault();
    },
    preventDrag(event) {
      event.preventDefault();
    },
    nextRecommendedArticle() {
      if (this.recommendedResourceHubArticles.length === 0) {
        return;
      }
      const nextArticle = this.recommendedResourceHubArticles[0];
      this.previewFile(nextArticle.id);
    },

    async handleResize(viewport) {
      if (viewport && this.open) {
        await this.previewFile(this.id);
      }
    },
  },
};
</script>

<style lang="scss">
.preview-card {
  height: 100%;
  overflow: hidden;

  &__wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  &__content {
    all: initial;
    user-select: none;
  }
}
</style>
