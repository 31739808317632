<template>
  <v-dialog
    v-model="isFeedbackDialogOpen"
    max-width="640px"
    width="100%"
  >
    <feedback-form
      :dialog="true"
      :first-name="firstName"
      @close-dialog="handleCloseDialog"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    isFeedbackDialogOpen: {
      get() {
        return this.$store.state.app.isFeedbackDialogOpen;
      },
      set(value) {
        this.$store.commit('app/setFeedbackDialogOpen', value);
      },
    },

    ...mapState('user', ['firstName']),
  },

  methods: {
    handleCloseDialog() {
      this.$store.commit('app/setFeedbackDialogOpen', false);
    },
  },
};
</script>
