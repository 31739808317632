<template>
  <div
    class="d-flex flex-row pb-6 justify-center g-1"
    :class="!$isMobile ? 'px-12' : 'px-8'"
  >
    <div
      v-if="!$isMobile"
      class="d-flex justify-center align-center"
    >
      <v-btn
        icon
        data-qa="sales-rec-carousel-previous-button"
        :disabled="!canScrollLeft"
        @click="scrollLeft"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </div>
    <g-carousel
      ref="carousel"
      show-dots
      :dot-size="8"
      :max-items="1"
      affix
      class="flex"
      @can-scroll-left-changed="canScrollLeft = $event"
      @can-scroll-right-changed="canScrollRight = $event"
    >
      <g-carousel-item
        v-for="(item, index) in salesRecommendations"
        :key="index"
      >
        <sales-recommendation
          :recommendation="item"
          :crop-year="item.crop_year"
          :commodity="item.commodity"
        />
      </g-carousel-item>
    </g-carousel>
    <div
      v-if="!$isMobile"
      class="d-flex justify-center align-center"
    >
      <v-btn
        icon
        data-qa="sales-rec-carousel-next-button"
        :disabled="!canScrollRight"
        @click="scrollRight"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GCarousel from '@/components/generic/GCarousel.vue';
import GCarouselItem from '@/components/generic/GCarouselItem.vue';
import SalesRecommendation from '@/components/dashboard/SalesRecommendation.vue';

export default {
  name: 'SalesRecommendationCarousel',
  components: {
    GCarouselItem, GCarousel, SalesRecommendation,
  },
  data() {
    return {
      canScrollLeft: false,
      canScrollRight: true,
    };
  },
  computed: {
    ...mapState('analysisDashboard', ['advancedAnalysisCommodities']),
    ...mapGetters('analysisDashboard', ['getAllSalesRecommendationTotals']),
    ...mapGetters('shared', ['cropYearById', 'currentCropYear', 'nextCropYear']),

    salesRecommendations() {
      const recommendations = this.getAllSalesRecommendationTotals();
      return recommendations.map((rec) => {
        const commodity = this.advancedAnalysisCommodities[rec.commodity_id];
        const crop_year = this.cropYearById(rec.crop_year_id);
        return {
          ...rec,
          commodity,
          crop_year,
        };
      }).filter((rec) => rec.crop_year.id === this.currentCropYear.id || rec.crop_year.id === this.nextCropYear.id);
    },
  },
  methods: {
    scrollLeft() {
      this.$refs?.carousel?.scrollLeft();
    },
    scrollRight() {
      this.$refs?.carousel?.scrollRight();
    },
  },
};
</script>

<style scoped>

::v-deep .dot {
  margin-top: 16px;
}

</style>
