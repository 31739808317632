<template>
  <g-dialog
    :value="value"
    title="New Release"
    :action-buttons="actionButtons"
    disable-fullscreen
    :width="iframeWidth"
    data-qa="new-feature-alert-video-dialog"
    @cancel="cancel"
  >
    <iframe
      width="100%"
      :height="iframeHeight"
      :src="featureVideo"
      title="Video Tutorial"
      frameborder="0"
    />
  </g-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GDialog from '../generic/GDialog.vue';

export default {
  name: 'NewFeatureTextDialog',

  components: {
    GDialog,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    featureName: {
      type: String,
      required: true,
    },

    featureVideo: {
      type: String,
      required: true,
    },

    featureLink: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('dashboard', ['newFeature']),
    actionButtons() {
      return [
        {
          name: `Explore ${this.featureName}`,
          side: 'right',
          type: 'primary',
          onClick: this.goToFeature,
        },
      ];
    },

    iframeHeight() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 450;
      }
      return 170;
    },

    iframeWidth() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 1000;
      }
      return 600;
    },
  },

  methods: {
    ...mapActions('dashboard', ['setNewFeatureViewedStatus']),
    goToFeature() {
      this.setNewFeatureViewedStatus(this.newFeature.id);
      window.location = this.featureLink;
    },

    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
