<template>
  <component
    :is="asyncComponents[name]"
    class="gf-custom-icon"
  />
</template>

<script>
import { asyncComponents } from '@/icons/custom-icons';

export default {
  name: 'CustomIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    asyncComponents,
  }),
};
</script>

<style>
.gf-custom-icon {
  fill: currentColor;
}
</style>
