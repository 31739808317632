<template>
  <div
    v-if="canShow && show"
    class="d-flex banner-background"
  >
    <v-banner
      single-line
      :height="height"
      elevation="0"
      tile
      class="w--100 d-flex banner-children"
      :class="$isMobile ? 'banner-children--nopad' : ''"
      transition="slide-y-transition"
    >
      <v-avatar
        slot="icon"
        size="20"
      >
        <v-icon class="primary-grey--text">
          mdi-information
        </v-icon>
      </v-avatar>
      <span
        class="grey--text text--darken-4"
        :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'body-1'"
      >
        Your free trial will end in <b>{{ daysLeftInTrial }}</b>
        {{ daysLeftInTrial !== 1 ? 'days' : 'day' }}.
        {{ $vuetify.breakpoint.mdAndDown ? '' :
          'Upgrade your plan today to continue receiving access to GrainFox tools and features.'
        }}
      </span>
      <template #actions>
        <v-btn
          v-if="!$vuetify.breakpoint.mdAndDown && showUpgradeButton"
          text
          href="/commerce/tiers/"
          @click="onBannerClick"
        >
          Upgrade your plan
        </v-btn>
        <v-btn
          v-else
          icon
          small
          @click="onBannerClick"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-banner>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrialBanner',

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    height: {
      type: Number,
      default: 64,
    },
  },

  data() {
    return {

    };
  },

  computed: {
    ...mapGetters('user', ['isAuthenticated', 'showTrialBanner']),
    ...mapGetters('chargebee', ['showUpgradeButton', 'plan']),

    daysLeftInTrial() {
      return this.computeDaysLeft(this.plan?.trial_end ?? 0);
    },

    canShow() {
      return !!(this.isAuthenticated);
    },
  },

  beforeMount() {
    this.setBannerInitialState();
  },

  methods: {
    setBannerInitialState() {
      const dismissed = JSON.parse(sessionStorage.getItem('upgrade_banner_dismissed'));
      const withinWarningPeriod = this.daysLeftInTrial >= 0 && this.daysLeftInTrial <= 5;

      if (dismissed || !withinWarningPeriod || !this.showTrialBanner) {
        this.$emit('hide');
      }
    },

    computeDaysLeft(secondsSinceEpoch) {
      const newDate = new Date(secondsSinceEpoch * 1000);
      const now = new Date();
      const diff = newDate - now;
      if (diff <= 0) {
        return -1;
      }
      const msPerDay = 1000 * 60 * 60 * 24;
      return Math.ceil(diff / msPerDay);
    },

    hideBanner() {
      this.$emit('hide');
      sessionStorage.setItem('upgrade_banner_dismissed', 'true');
    },

    onBannerClick() {
      this.hideBanner();
    },
  },
};
</script>
