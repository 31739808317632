<template>
  <v-text-field
    :value="fingerprint"
    :rules="[requiredValueRule('Fingerprint')]"
    name="web_fingerprint"
    class="d-none"
    hidden
  />
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { requiredValueRule } from '../../helpers/rules';

export default {
  name: 'FingerprintField',
  data() {
    return {
      fingerprint: null,
    };
  },

  created() {
    // eslint-disable-next-line no-undef
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        // eslint-disable-next-line no-param-reassign
        delete result.components.canvas; // Big, useless field
        this.fingerprint = btoa(JSON.stringify(result));
        this.emitFingerPrintChange();
      });
  },

  methods: {
    requiredValueRule,

    emitFingerPrintChange() {
      this.$emit('fingerprint-change', this.fingerprint);
    },
  },
};
</script>
