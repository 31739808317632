<template>
  <v-card
    v-if="showWidget"
    class="mb-6"
    :class="{'pb-16': isLoading}"
  >
    <div
      class="d-flex justify-space-between"
    >
      <div
        class="primary-grey--text text-h6 font-weight-bold ma-6"
      >
        {{ title }}
      </div>
      <div v-if="!isCommercial">
        <v-btn
          v-if="!$isMobile"
          class="primary-grey--text transparent font-weight-bold text-button ma-6"
          :disabled="isLoading"
          @click="openCommoditiesOfInterestDialog"
        >
          <v-icon
            small
            class="ma-1"
          >
            mdi-cog
          </v-icon>
          COMMODITIES OF INTEREST
        </v-btn>
        <v-menu
          v-else
          absolute
          down
          :disabled="isLoading"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-col
              v-bind="attrs"
              class="d-flex justify-end"
              v-on="on"
            >
              <v-icon
                class="ma-1 mt-4"
                medium
              >
                mdi-cog
              </v-icon>
            </v-col>
          </template>
          <v-list>
            <v-list-item @click="openCommoditiesOfInterestDialog">
              <span class="text-button primary-grey--text">
                COMMODITIES OF INTEREST
              </span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <template
      v-if="isLoading === true"
    >
      <div class="grey--text text-center py-16">
        Loading...
      </div>
    </template>
    <sales-recommendations-c-t-a v-else-if="showCTA" />
    <sales-recommendation-carousel v-else-if="salesRecommendations.length > 0" />
    <empty-widget
      v-else
      title="No sales recommendations to show right now"
      subtitle="Enter your commodities and contracts in Farm Profile now to start getting
      customized sales recommendations right here when they become available."
      button-text="Go to Farm Profile"
      button-href="/farm_profile/commodities/"
      icon="$vuetify.icons.emptyStatePrice"
    />
    <crop-interests
      v-if="!isLoading"
      :from-dashboard="true"
      :show-all-crops="true"
      :crop-data="cropData"
    />
  </v-card>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import EmptyWidget from './EmptyWidget.vue';
import SalesRecommendationsCTA from '../farm_profile/SalesRecommendationsCTA.vue';
import CropInterests from '../account/CropInterests.vue';
import SalesRecommendationCarousel from './SalesRecommendationCarousel.vue';

export default {
  components: {
    SalesRecommendationsCTA,
    SalesRecommendationCarousel,
    EmptyWidget,
    CropInterests,
  },
  data() {
    return {
      title: 'Sales recommendations',
      isLoading: true,
      cropData: [],
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapState('analysisDashboard', ['salesRecommendations']),
    ...mapGetters('permissions', ['checkPermission']),
    ...mapGetters('subscription', ['isDeputter', 'isSyngenta', 'isAmerican']),
    ...mapGetters('chargebee', ['isMarketIntelligence', 'isCommercialBasic', 'isCommercial']),

    showCTA() {
      return !this.checkPermission('sales_recommendation', 'read') || this.isCommercialBasic;
    },

    showWidget() {
      return !(this.isAmerican || this.isDeputter || this.isSyngenta);
    },
  },

  async mounted() {
    this.setSalesRecommendationsTab(!this.showWidget);

    await Promise.all([
      this.fetchAllCropYears(),
      this.fetchAllSalesRecommendations(),
      this.fetchAnalysisDashboardCommodities(),
      this.fetchPriceMapLinks(),
      this.fetchLibraryLinks(),
    ]);
    const data = await API.readCommoditiesAndProvinces();
    this.cropData = data.commodities;
    this.isLoading = false;
  },

  methods: {
    ...mapActions('shared', ['fetchAllCropYears']),
    ...mapActions('analysisDashboard', [
      'fetchAllSalesRecommendations',
      'fetchAnalysisDashboardCommodities',
      'fetchPriceMapLinks',
      'fetchLibraryLinks',
    ]),
    ...mapMutations('farmProfile/salesRecommendations', ['setCommoditiesOfInterestDialogOpen']),
    ...mapActions('dashboard', ['setSalesRecommendationsTab']),
    openCommoditiesOfInterestDialog() {
      this.setCommoditiesOfInterestDialogOpen(true);
    },
  },
};
</script>
