import { postRequest } from '@/helpers/request';

// Sample Payload: {crop_year: 2020, name: 'Goal Name', target: 1000.00, date: '2020-01-01'}
export async function apiCreateCashFlowGoal(payload) {
  const data = await postRequest('/budgeting/ai_advisor/create_cash_flow_goal/', payload);
  return data;
}

// Sample Payload: {id: 1, crop_year: 2020, name: 'Goal Name', target: 1000.00, date: '2020-01-01'}
export async function apiUpdateCashFlowGoal(payload) {
  const data = await postRequest('/budgeting/ai_advisor/update_cash_flow_goal/', payload);
  return data;
}

export async function apiDeleteCashFlowGoal(id) {
  const data = await postRequest('/budgeting/ai_advisor/delete_cash_flow_goal/', { id });
  return data;
}

// Sample Payload: {goal: 1, payment_date: '2020-01-01', notes: 'Notes', income_type: 'business', amount: 1000.00 }
export async function apiCreateCashFlowGoalProgress(payload) {
  const data = await postRequest('/budgeting/ai_advisor/create_cash_flow_goal_progress/', payload);
  return data;
}

// Sample Payload: {id: 1, payment_date: '2020-01-01', notes: 'Notes', income_type: 'business', amount: 1000.00 }
export async function apiUpdateCashFlowGoalProgress(payload) {
  const data = await postRequest('/budgeting/ai_advisor/update_cash_flow_goal_progress/', payload);
  return data;
}

export async function apiDeleteCashFlowGoalProgress(id) {
  const data = await postRequest('/budgeting/ai_advisor/delete_cash_flow_goal_progress/', { id });
  return data;
}
