<template>
  <component
    :is="component"
    :value="primaryFarmDialogOpen"
    :title="title"
    :action-buttons="actions"
    :bottom="$isMobile"
    :height="500"
    @input="onCancel"
    @cancel="onCancel"
  >
    <template>
      <div>
        <v-btn
          variant="text"
          class="primary--text"
          :style="{ backgroundColor: 'transparent' }"
          @click="setFarmLocationDialogOpen(true)"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Add New
        </v-btn>
        <farm-location-dialog
          :is-primary-farm-by-default="true"
          @done="handleFarmLocationDialogDone"
        />
      </div>
      <v-divider class="mt-1" />
      <div style="overflow-y: auto;">
        <v-radio-group
          v-model="selectedFarmLocation"
          column
        >
          <div
            v-for="(location, index) in farmLocations"
            :key="location.id"
          >
            <v-divider
              v-if="index > 0"
              class="py-2 mt-3"
            />
            <v-radio
              class="pl-3"
              :label="location.name"
              :value="location.id"
            />
          </div>
        </v-radio-group>
      </div>
      <div
        v-if="$isMobile"
        class="footer-area"
      >
        <v-divider />
        <div class="d-flex justify-center white pa-4">
          <v-btn
            text
            color="primary"
            :disabled="!this.selectedFarmLocation"
            @click="actions[0].onClick"
          >
            {{ actions[0].name }}
          </v-btn>
        </div>
      </div>
    </template>
  </component>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import GDialog from '@/components/generic/GDialog.vue';
import SlideDrawer from '@/components/drawers/SlideDrawer.vue';
import FarmLocationDialog from './FarmLocationDialog.vue';
import { requiredValueRule } from '../../../helpers/rules';

export default {
  components: {
    GDialog,
    SlideDrawer,
    FarmLocationDialog,
  },

  data() {
    return {
      selectedFarmLocation: null,
    };
  },

  computed: {
    ...mapState('farmLocations', ['farmLocations', 'farmLocationDialog', 'farmLocationDialogDefaultName', 'primaryFarmDialogOpen']),
    ...mapGetters('farmLocations', ['getFarmLocationById']),

    title() {
      return 'Set Primary Farm';
    },

    actions() {
      return [{
        name: 'CONFIRM',
        type: 'primary',
        side: 'right',
        mustValidate: true,
        onClick: this.onSave,
        disabled: !this.selectedFarmLocation,
      }];
    },

    component() {
      return this.$isMobile ? 'slide-drawer' : 'g-dialog';
    },
  },

  methods: {
    requiredValueRule,
    ...mapActions('farmLocations', ['updateFarmLocation']),
    ...mapActions('weather', ['loadFarmLocation']),
    ...mapMutations('farmLocations', ['setFarmLocationDialogOpen', 'setPrimaryFarmDialogOpen']),

    async onSave() {
      try {
        const farm = this.getFarmLocationById(this.selectedFarmLocation);
        farm.default = true;

        const result = await this.updateFarmLocation(farm);

        this.$snackbar.success(result.message);

        this.$emit('done', result.data);
        if (this.farmLocationDialog.onDone) {
          this.farmLocationDialog.onDone(result.data);
        }

        await this.loadFarmLocation();

        this.setPrimaryFarmDialogOpen(false);
      } catch (e) {
        this.$snackbar.error(e);
      }
    },

    handleFarmLocationDialogDone(result) {
      this.$emit('done', result);

      if (this.farmLocationDialog.onDone) {
        this.farmLocationDialog.onDone(result);
      }

      this.setPrimaryFarmDialogOpen(false);
    },

    onCancel() {
      this.selectedFarmLocation = null;
      this.setPrimaryFarmDialogOpen(false);
    },
  },
};
</script>
