import {
  apiGetUserList,
  apiGetApproverList,
  apiGetCreatorList,
  apiGetCampaignUrlTypeList,
  apiGetCampaignUrlCreatorList,
  apiValidateCampaignKey,
  apiGetSourceChoices,
  apiGetContentTypesForCampaignURL,
} from '../../../api/marketingModule';

export default {
  namespaced: true,

  state: () => ({
    userList: [],
    approverList: [],
    creatorList: [],
    campaignUrlTypeList: [],
    campaignUrlCreatorList: [],
    fetchedCampaign: null, // Campaign object - CampaignRegistration / Account Activation
    campaignCobranding: null,
    campaignErrorsRaw: null,
    campaignSourceChoices: [],
  }),

  mutations: {
    setUserList(state, value) {
      state.userList = value;
    },

    setApproverList(state, value) {
      state.approverList = value;
    },

    setCreatorList(state, value) {
      state.creatorList = value;
    },

    setCampaignUrlTypeList(state, value) {
      state.campaignUrlTypeList = value;
    },

    setCampaignUrlCreatorList(state, value) {
      state.campaignUrlCreatorList = value;
    },

    setFetchedCampaign(state, value) {
      state.fetchedCampaign = value;
    },

    setCampaignCobranding(state, value) {
      state.campaignCobranding = value;
    },

    setCampaignErrorsRaw(state, value) {
      state.campaignErrorsRaw = value;
    },

    setCampaignSourceChoices(state, value) {
      state.campaignSourceChoices = value;
    },
  },

  actions: {
    async readUserList({ commit }) {
      try {
        const result = await apiGetUserList();
        commit('setUserList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async readApproverList({ commit }) {
      try {
        const result = await apiGetApproverList();
        commit('setApproverList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readCreatorList({ commit }) {
      try {
        const result = await apiGetCreatorList();
        commit('setCreatorList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readCampaignUrlTypeList({ commit }) {
      try {
        const result = await apiGetCampaignUrlTypeList();
        commit('setCampaignUrlTypeList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readCampaignUrlCreatorList({ commit }) {
      try {
        const result = await apiGetCampaignUrlCreatorList();
        commit('setCampaignUrlCreatorList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async validateCampaignUrlKey({ commit }, key) {
      try {
        const { campaign, cobranding } = await apiValidateCampaignKey({
          activation_key: key,
        });
        commit('setFetchedCampaign', campaign);
        commit('setCampaignCobranding', cobranding);
      } catch (e) {
        commit('setCampaignErrorsRaw', JSON.parse(e.message));
      }
    },

    async readCampaignSourceChoices({ commit }) {
      try {
        const result = await apiGetSourceChoices();
        commit('setCampaignSourceChoices', result.sort());
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getContentTypesForCampaignURL(ignored, campaignUrlId) {
      try {
        const result = await apiGetContentTypesForCampaignURL({
          id: campaignUrlId,
        });
        return result;
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
      return [];
    },
  },
};
