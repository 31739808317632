/* eslint-disable camelcase */
import { postRequest, postFormRequest, createJSONFormDataWithAttachments } from '../../../helpers/request';

// TODO: change this filename to deliveries.js

export default {
  state: () => ({
    deliveries: [],
    deliveriesForContract: [],
  }),

  mutations: {
    setDeliveries(state, deliveries) {
      state.deliveries = deliveries;
    },
    setDeliveriesForContract(state, deliveries) {
      state.deliveriesForContract = deliveries;
    },
  },

  actions: {
    async fetchDeliveries({ commit }, {
      deliveryIds, deliveryIdentifiers, contracts, cropYears, commodities,
    }) {
      const deliveries = await postRequest(
        '/farm_profile/api/get_subscriptions_deliveries/',
        {
          filters: {
            ids: deliveryIds,
            delivery_identifiers: deliveryIdentifiers,
            contracts,
            crop_years: cropYears,
            commodities,
          },
        },
      );

      // mutate the state
      commit('setDeliveries', deliveries);
    },

    async fetchDelivery({ commit }, deliveryId) {
      const delivery = await postRequest(
        '/farm_profile/api/get_delivery/',
        {
          id: deliveryId,
        },
      );

      // mutate the state
      commit('setDeliveries', [delivery]);
      return delivery;
    },

    async fetchDeliveriesForContract({ commit }, contractID) {
      // calling the api
      const deliveries = await postRequest(
        '/farm_profile/api/get_contract_deliveries/',
        {
          // request body ( contract id? ), whatever the backend expects
          contract_id: contractID,
        },
      );

      // mutate the state
      commit('setDeliveriesForContract', deliveries);
    },

    async createDelivery({ dispatch }, {
      contract_id,
      delivery_identifier,
      quantity,
      dockage,
      commodity_unit_id,
      quality,
      delivery_date,
      comments,
      price,
      price_unit_id,
      country_id,
      is_spot_delivery,
      files,
    }) {
      const payload = {
        contract: contract_id,
        delivery_identifier,
        quantity,
        dockage,
        commodity_unit: commodity_unit_id,
        quality,
        delivery_date,
        comments,
        price,
        price_unit: price_unit_id,
        country: country_id,
        is_spot_delivery,
      };
      const newDelivery = await postFormRequest(
        '/farm_profile/api/create_delivery/',
        createJSONFormDataWithAttachments(payload, files.map((x) => x.file)),
      );

      // reload deliveries
      await dispatch('fetchDeliveriesForContract', contract_id);
      return newDelivery;
    },
    async updateDelivery({ dispatch }, {
      id,
      contract_id,
      delivery_identifier,
      quantity,
      dockage,
      commodity_unit_id,
      quality,
      delivery_date,
      comments,
      price,
      price_unit_id,
      country_id,
      files,
    }) {
      const payload = {
        delivery_id: id,
        to_update: {
          delivery_identifier,
          quantity,
          dockage,
          commodity_unit_id,
          quality,
          delivery_date,
          comments,
          price,
          price_unit_id,
          country_id,
        },
      };
      await postFormRequest(
        '/farm_profile/api/update_delivery/',
        createJSONFormDataWithAttachments(payload, files.map((x) => x.file)),
      );

      await dispatch('fetchDeliveriesForContract', contract_id);
    },
    async deleteDelivery({ dispatch }, {
      contractId,
      deliveryId,
    }) {
      await postRequest('/farm_profile/api/delete_delivery/', {
        id: deliveryId,
      });

      await dispatch('fetchDeliveriesForContract', contractId);
    },
    async createManyBinAdjustmentForDelivery(context, {
      sources,
      destinationDelivery,
      quantities,
      unit,
      comment,
      adjustmentDate,
    }) {
      await postRequest('/farm_profile/api/create_many_bin_adjustments_for_delivery/', {
        sources,
        destination_delivery: destinationDelivery,
        quantities,
        unit,
        comment,
        adjustment_date: adjustmentDate,
      });
    },
  },

  namespaced: true,
};
