<template>
  <div
    class="d-flex flex-column align-center justify-center text-center"
  >
    <v-icon size="80">
      $vuetify.icons.emptyStateUpgradeAccount
    </v-icon>
    <div class="ma-4">
      <h5 class="text-h5 grey--text text--darken-4 mb-2">
        {{ title }}
      </h5>
      <p class="text-body-1 grey--text text--darken-3 mb-2">
        {{ body }}
      </p>
    </div>
    <v-btn
      v-if="rejectType === 'reject_payment' && isBilling"
      class="mb-6"
      color="primary"
      href="/commerce/tiers/"
      data-qa="sales-recommendation-cta-button"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SalesRecommendationsCTA',
  computed: {
    ...mapState('permissions', ['permissions']),
    ...mapState('group', ['isAdmin']),
    ...mapGetters('permissions', ['isOwner']),
    ...mapGetters('chargebee', ['isCommercial']),
    ...mapGetters('subscription', ['isAmerican']),

    isBilling() {
      return this.permissions.billing.read === 'allow';
    },

    rejectType() {
      return this.permissions.sales_recommendation.read;
    },

    title() {
      if (this.rejectType === 'reject_payment' && this.isBilling) {
        return 'Your plan doesn’t include this feature.';
      }
      return 'You are not allowed to see this feature';
    },
    body() {
      if (this.rejectType === 'reject_admin' && this.isOwner) {
        return 'Please check your permissions configuration';
      }
      if (this.rejectType === 'reject_payment' && this.isBilling) {
        if (this.isAmerican) {
          return 'Please upgrade your plan to unlock more GrainFox tools and features.'
                  + ' Contact us if you have any questions.';
        }
        if (this.isCommercial) {
          return 'Please upgrade to Commercial Plan to unlock all GrainFox tools and features.'
                  + ' Contact us if you have any questions.';
        }
        return 'Upgrade to Smart Plan to get sales recommendations right on your GrainFox Dashboard.';
      }
      return 'Please contact your organization’s administrator';
    },
    buttonText() {
      if (this.isAmerican) {
        return 'UPGRADE PLAN';
      }
      if (this.isCommercial) {
        return 'UPGRADE TO COMMERCIAL PLAN';
      }
      return 'UPGRADE TO SMART PLAN';
    },
  },
};
</script>
