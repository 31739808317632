<template>
  <v-btn
    v-if="show"
    href="/analysis_dashboard/"
  >
    Advanced Analysis Dashboard
  </v-btn>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AnalysisDashboardButton',

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('permissions', ['checkPermission']),

    show() {
      const canShow = this.flags['advanced-analysis-dashboard'] && this.$route.path !== '/analysis_dashboard/';
      if (!canShow) {
        return false;
      }
      return this.checkPermission('advanced_analysis_dashboard', 'read');
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
