<template>
  <v-card
    height="100%"
    :class="$isMobile ? 'elevation-0 transparent' : ''"
  >
    <v-row dense>
      <v-col
        class="pl-2"
      >
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-subtitle>
          Your account is set as a{{ roleIsVowel }} {{ roleDisplay }}
        </v-card-subtitle>
      </v-col>
      <v-col
        v-if="!$isMobile"
        class="d-flex justify-end"
      >
        <template v-if="editMode">
          <v-btn
            text
            class="mt-6 mr-2"
            @click="handleCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            class="mt-6 mr-6"
            :disabled="!isFormValid || disabled || !changed"
            @click="handleSubmit"
          >
            Save
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            text
            color="primary"
            class="mt-6 mr-6"
            :disabled="!canEdit"
            @click="handleEdit"
          >
            <v-icon class="mr-2">
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
        </template>
      </v-col>

      <div
        v-if="$isMobile"
        class="pa-4"
      >
        <v-btn
          fab
          color="primary"
          :disabled="!canEdit"
          @click="handleEdit"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </div>
    </v-row>
    <g-dialog
      v-if="$isMobile"
      v-model="editMode"
      title="Edit Account Profile"
      :subtitle="`Your account is set as a${roleIsVowel} ${roleDisplay}`"
      :action-buttons="editDialogActions"
    >
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-row dense>
          <v-col
            :cols="12"
          >
            <v-text-field
              v-model="updatedSubscription.name"
              label="Business Name"
              outlined
              :disabled="updatedSubscription.isProvider || disabled"
              :hint="accountNameHint"
              persistent-hint
              required
              maxlength="255"
              :rules="nameRules"
            />
          </v-col>
          <v-col
            :cols="12"
          >
            <phone-number-input
              v-model="updatedSubscription.phone"
              label="Business Phone Number"
              name="phone_number"
              placeholder="(XXX) XXX-XXXX"
              outlined
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col
            v-if="showSubregionSelector"
            :cols="12"
          >
            <v-select
              ref="subscriptionProvince"
              v-model="updatedSubscription.province"
              data-qa="account-activation-business-province-select-field"
              :name="subregionTitle"
              :label="subregionTitle"
              item-value="id"
              item-text="name"
              :items="subregions"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="subregionRules"
              outlined
            />
          </v-col>
          <v-col
            v-if="isCommercial"
            :cols="12"
          >
            <v-select
              v-model="updatedSubscription.agribusinessSegment"
              data-qa="account-activation-business-segment-select-field"
              name="agribusinessSegment"
              label="Agribusiness Segment"
              item-value="value"
              item-text="label"
              :items="possibilities.segments"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
          <v-col
            v-if="isCommercial"
            :cols="12"
          >
            <v-select
              v-model="updatedSubscription.businessSize"
              data-qa="account-activation-business-size-select-field"
              name="businessSize"
              label="Business Size"
              item-value="value"
              item-text="label"
              :items="possibilities.sizes"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
          <v-col
            v-if="!isCommercial"
            :cols="12"
          >
            <v-select
              v-model="subscriptionFarmingPeriod"
              :items="farmingPeriods"
              item-text="label"
              item-value="value"
              label="Farming Experience"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
        </v-row>
        <v-row
          v-if="!isCommercial"
          dense
        >
          <v-col>
            <v-select
              v-model="updatedSubscription.cropInterests"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="cropInterestsItems"
              name="crop_interests"
              label="Crop Interests"
              multiple
              outlined
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="mb-5"
        >
          <v-col
            cols="12"
            class="d-flex flex-column"
          >
            <span class="py-4 text-subtitle-1 font-weight-bold primary-grey--text">
              Settings
            </span>
            <div
              class="d-flex justify-space-between py-4 px-2 flex-column g-2"
            >
              <span
                class="primary-grey--text text-body-2"
              >
                Change account type
              </span>
              <div>
                <span class="text-body-2 grey--text text--darken-1">
                  Contact us at
                </span>
                <a
                  :href="changeAccountTypePhone.href"
                  target="_blank"
                  class="text-body-2 grey--text text--darken-4"
                >
                  {{ changeAccountTypePhone.label }}
                </a>
              </div>
            </div>
            <v-divider class="pb-2" />
            <template v-if="isCommercial">
              <div
                class="d-flex justify-space-between py-4 px-2 flex-column g-2"
              >
                <span
                  class="primary-grey--text text-body-2"
                >
                  Delete account
                </span>
                <div>
                  <span class="text-body-2 grey--text text--darken-1">
                    Contact us at
                  </span>
                  <a
                    :href="deleteOrganizationPhone.href"
                    target="_blank"
                    class="text-body-2 grey--text text--darken-4"
                  >
                    {{ deleteOrganizationPhone.label }}
                  </a>
                </div>
              </div>
              <v-divider class="pb-2" />
            </template>
          </v-col>
        </v-row>
      </v-form>
    </g-dialog>
    <v-card-text v-if="editMode && !$isMobile">
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-row dense>
          <v-col
            :cols="!$isMobile ? (isCommercial ? 4 : 6) : 12"
            class="px-2"
          >
            <v-text-field
              v-model="updatedSubscription.name"
              label="Business Name"
              outlined
              :disabled="updatedSubscription.isProvider || disabled"
              :hint="accountNameHint"
              persistent-hint
              required
              maxlength="255"
              :rules="nameRules"
            />
          </v-col>
          <v-col
            :cols="!$isMobile ? (isCommercial ? 4 : 6) : 12"
            class="px-2"
          >
            <phone-number-input
              v-model="updatedSubscription.phone"
              label="Business Phone Number"
              name="phone_number"
              placeholder="(XXX) XXX-XXXX"
              outlined
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col
            v-if="showSubregionSelector"
            :cols="!$isMobile ? (isCommercial ? 4 : 6) : 12"
            class="px-2"
          >
            <v-select
              ref="subscriptionProvince"
              v-model="updatedSubscription.province"
              data-qa="account-activation-business-province-select-field"
              :name="subregionTitle"
              :label="subregionTitle"
              item-value="id"
              item-text="name"
              :items="subregions"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="subregionRules"
              outlined
            />
          </v-col>
          <v-col
            v-if="isCommercial"
            :cols="!$isMobile ? 6 : 12"
            class="px-2"
          >
            <v-select
              v-model="updatedSubscription.agribusinessSegment"
              data-qa="account-activation-business-segment-select-field"
              name="agribusinessSegment"
              label="Agribusiness Segment"
              item-value="value"
              item-text="label"
              :items="possibilities.segments"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
          <v-col
            v-if="isCommercial"
            :cols="!$isMobile ? 6 : 12"
            class="px-2"
          >
            <v-select
              v-model="updatedSubscription.businessSize"
              data-qa="account-activation-business-size-select-field"
              name="businessSize"
              label="Business Size"
              item-value="value"
              item-text="label"
              :items="possibilities.sizes"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
          <v-col
            v-if="!isCommercial"
            :cols="!$isMobile ? 6 : 12"
            class="px-2"
          >
            <v-select
              v-model="subscriptionFarmingPeriod"
              :items="farmingPeriods"
              item-text="label"
              item-value="value"
              label="Farming Experience"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
            />
          </v-col>
        </v-row>
        <v-row
          v-if="!isCommercial"
          dense
        >
          <v-col class="px-2">
            <v-select
              v-model="updatedSubscription.cropInterests"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="cropInterestsItems"
              name="crop_interests"
              label="Crop Interests"
              multiple
              outlined
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="pb-1"
        >
          <v-col
            cols="12"
            class="d-flex flex-column"
          >
            <span class="py-4 text-subtitle-1 font-weight-bold primary-grey--text">
              Settings
            </span>
            <div
              class="d-flex justify-space-between py-4 px-2"
            >
              <span class="primary-grey--text text-body-2">
                Change account type
              </span>
              <div>
                <span class="text-body-2 primary-grey--text">
                  Contact us at
                </span>
                <a
                  :href="changeAccountTypePhone.href"
                  target="_blank"
                  class="text-body-2 grey--text text--darken-4"
                >
                  {{ changeAccountTypePhone.label }}
                </a>
              </div>
            </div>
            <v-divider class="pb-2" />
            <template v-if="isCommercial">
              <div
                class="d-flex justify-space-between py-4 px-2"
              >
                <span class="primary-grey--text text-body-2">
                  Delete organization
                </span>
                <div>
                  <span class="text-body-2 primary-grey--text">
                    Contact us at
                  </span>
                  <a
                    :href="deleteOrganizationPhone.href"
                    target="_blank"
                    class="text-body-2 grey--text text--darken-4"
                  >
                    {{ deleteOrganizationPhone.label }}
                  </a>
                </div>
              </div>
              <v-divider class="pb-2" />
            </template>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text
      v-else
      class="mb-4"
    >
      <v-row class="ma-0 mx-n1 mt-n4">
        <v-col
          :cols="!$isMobile ? (isCommercial ? 4 : 6) : 12"
          class="px-2 d-flex flex-column"
        >
          <span class="text-subtitle-2">
            Business Name
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ updatedSubscription.name }}
          </span>
        </v-col>
        <v-col
          :cols="!$isMobile ? (isCommercial ? 4 : 6) : 12"
          class="px-2 d-flex flex-column"
        >
          <span class="text-subtitle-2">
            Business Phone Number
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ formattedNumber }}
          </span>
        </v-col>
        <v-col
          v-if="showSubregionSelector"
          :cols="!$isMobile ? (isCommercial ? 4 : 6) : 12"
          class="px-2 d-flex flex-column"
        >
          <span class="text-subtitle-2">
            {{ subregionTitle }}
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ subregionName }}
          </span>
        </v-col>
        <v-col
          v-if="isCommercial"
          :cols="!$isMobile ? 4 : 12"
          class="px-2 d-flex flex-column"
        >
          <span class="text-subtitle-2">
            Agribusiness Segment
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ updatedAgribusinessSegment }}
          </span>
        </v-col>
        <v-col
          v-if="isCommercial"
          :cols="!$isMobile ? 4 : 12"
          class="px-2 d-flex flex-column"
        >
          <span class="text-subtitle-2">
            Business Size
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ updatedBusinessSize }}
          </span>
        </v-col>
        <v-col
          v-if="!isCommercial"
          :cols="!$isMobile ? 6 : 12"
          class="px-2 d-flex flex-column"
        >
          <span class="text-subtitle-2">
            Farming Experience
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ updatedExperience }}
          </span>
        </v-col>
      </v-row>
      <v-row
        v-if="!isCommercial"
        class="ma-0 mx-n1"
      >
        <v-col class="px-2 d-flex flex-column">
          <span class="text-subtitle-2">
            Crop Interests
          </span>
          <span class="text-body-1 grey--text text--darken-4">
            {{ (updatedSubscription.cropInterests || []).join(', ') }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <province-change-warning-dialog
      v-model="showSubregionWarning"
      @accept="handleAcceptProvinceWarning"
      @cancel="handleAcceptProvinceWarning"
    />
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import parsePhoneNumberFromString from 'libphonenumber-js';
import {
  containsALetterRule,
  requiredValueRule,
  validateLatinUnicodeNameWithNumbers,
  maxLengthRule,
} from '@/helpers/rules';
import ProvinceChangeWarningDialog from '@/components/account/ProvinceChangeWarningDialog.vue';
import GDialog from '@/components/generic/GDialog.vue';
import PhoneNumberInput from '@/components/generic/PhoneNumberInput.vue';
import { postRequest } from '@/helpers/request';

export default {
  components: { ProvinceChangeWarningDialog, GDialog, PhoneNumberInput },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      changed: false,
      editMode: false,

      possibilities: {
        segments: [],
        sizes: [],
      },

      isFormValid: false,

      updatedSubscription: {
        ...this.subscription,
      },
      showSubregionWarning: false,

      cropInterestsItems: [],
      subregionRules: [requiredValueRule('Province')],
      farmingPeriods: [
        { label: '0 - 5 years', value: 5 },
        { label: '5 - 10 years', value: 10 },
        { label: '10 - 20 years', value: 20 },
        { label: '20 - 30 years', value: 30 },
        { label: '30 - 40 years', value: 40 },
        { label: '40 - 50 years', value: 50 },
        { label: '50+ years', value: 60 },
      ],

      subscriptionFarmingPeriod: 0,
      nameRules: [
        (v) => !!v || 'Name is required',
        maxLengthRule('Name', 255),
        validateLatinUnicodeNameWithNumbers,
        containsALetterRule,
      ],
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('permissions', ['checkPermission']),
    ...mapGetters('region', ['countryProvinces', 'servicedCountries']),

    subregions() {
      return this.countryProvinces[this.updatedSubscription.country];
    },

    subregionTitle() {
      const country = this.servicedCountries.find(
        (countryObject) => countryObject.id === this.updatedSubscription.country,
      );
      switch (country?.country_name) {
        case 'Canada':
          return 'Province';
        case 'United States':
          return 'State';
        default:
          return 'Province or State';
      }
    },

    canEdit() {
      return this.checkPermission('profile', 'update');
    },

    editDialogActions() {
      return [
        {
          name: 'Cancel',
          type: 'default',
          onClick: this.handleCancel,
        },
        {
          name: 'Save',
          type: 'primary',
          onClick: this.handleSubmit,
          disabled: !this.changed,
        },
      ];
    },

    accountNameHint() {
      return this.updatedSubscription.isProvider ? 'Content providers cannot change business name' : '';
    },

    changeAccountTypePhone() {
      return {
        label: '+1 (877) 376-5465',
        href: 'tel:+18773765465',
      };
    },

    deleteOrganizationPhone() {
      return {
        label: '+1 (877) 376-5465',
        href: 'tel:+18773765465',
      };
    },

    role() {
      return this.subscription?.role ?? 'farm';
    },

    roleDisplay() {
      switch (this.role) {
        case 'commercial':
          return 'AgriBusiness';
        case 'farm':
          return 'Farm';
        case 'producer':
          return 'Producer';
        default:
          return this.role;
      }
    },

    roleIsVowel() {
      return ['a', 'e', 'i', 'o', 'u'].includes(this.roleDisplay.toLowerCase()[0]) ? 'n' : '';
    },

    isCommercial() {
      return this.role === 'commercial';
    },

    subregionName() {
      return this.updatedSubregion?.name ?? '';
    },

    updatedSubregion() {
      if (!this.subregions) { return {}; }
      return this.subregions.find((province) => province.id === this.updatedSubscription.province);
    },

    updatedExperience() {
      const period = this.subscriptionFarmingPeriod;
      const experience = this.farmingPeriods.find((p) => p.value === period) ?? this.farmingPeriods[0];
      return experience?.label ?? '';
    },

    updatedAgribusinessSegment() {
      const segment = this.possibilities?.segments.find(
        (s) => s.value === this.updatedSubscription.agribusinessSegment,
      );
      return segment?.label ?? '-';
    },

    updatedBusinessSize() {
      const size = this.possibilities?.sizes.find(
        (s) => s.value === this.updatedSubscription.businessSize,
      );
      return size?.label ?? '-';
    },

    showSubregionSelector() {
      return this.flags['market-intelligence-plan'] && this.subscription.is_subscribed_to_deputter;
    },
    isSubregionChanged() {
      const before = this.subscription.province;
      const after = this.updatedSubscription.province;
      if (before === null && after === null) {
        return false;
      }
      if (before === null && after !== null) {
        return true;
      }

      const currentRegion = this.subregions.find((province) => province.id === before).group;
      const newRegion = this.updatedSubregion.group;
      return currentRegion !== newRegion;
    },

    formattedNumber() {
      if (!this.updatedSubscription.phone) {
        return '';
      }
      const phoneNumberObject = parsePhoneNumberFromString(this.updatedSubscription.phone, 'CA');
      return phoneNumberObject.formatInternational();
    },
  },

  watch: {
    subscription(newVal) {
      this.formatFarmingPeriod();
      this.updatedSubscription = { ...newVal };
      this.$nextTick(() => {
        this.changed = false;
      });
    },

    subscriptionFarmingPeriod(newVal) {
      this.updatedSubscription.farmingPeriod = newVal;
    },

    updatedSubscription: {
      handler() {
        this.changed = this.isChanged();
      },
      deep: true,
    },
  },

  async created() {
    try {
      this.possibilities = await postRequest('/account/get_possible_agribusiness_segments_and_business_sizes');
    } catch (e) {
      this.possibilities = {
        segments: [],
        sizes: [],
      };
    }
  },

  async beforeMount() {
    await Promise.all([
      this.fetchCountries(),
      this.fetchProvinces(),
      this.loadCommodities(),
    ]);
  },

  methods: {
    ...mapActions('region', ['fetchProvinces', 'fetchCountries']),
    isChanged() {
      const updated = Object.keys(this.updatedSubscription);
      const original = Object.keys(this.subscription);
      if (updated.length !== original.length) {
        return true;
      }
      for (let i = 0; i < updated.length; i += 1) {
        const key = updated[i];
        const updatedValue = this.updatedSubscription[key];
        const originalValue = this.subscription[key];
        if (updatedValue !== originalValue) {
          if (Array.isArray(updatedValue)) {
            if (!Array.isArray(originalValue)) {
              return true;
            }
            if (originalValue.length !== updatedValue.length) {
              return true;
            }
            const updatedCopy = [...this.updatedSubscription[key]];
            const originalCopy = [...this.subscription[key]];
            updatedCopy.sort();
            originalCopy.sort();
            for (let j = 0; j < updatedCopy.length; j += 1) {
              const leftValue = updatedCopy[j];
              const rightValue = originalCopy[j];
              if (leftValue !== rightValue) {
                return true;
              }
            }
            // eslint-disable-next-line no-continue
            continue;
          }
          return true;
        }
      }
      return false;
    },

    getSizeMinMax(size) {
      let min = 0;
      let max = Number.MAX_SAFE_INTEGER;
      if (size.endsWith('+')) {
        min = parseInt(size, 10);
      } else {
        const [minStr, maxStr] = size.split(' to ');
        min = parseInt(minStr, 10);
        max = parseInt(maxStr, 10);
      }
      return { min, max };
    },

    useClosestBusinessSize() {
      if ((this.updatedSubscription.businessSize ?? null) === null) {
        return;
      }
      const { min, max } = this.getSizeMinMax(this.updatedSubscription.businessSize);
      const newSize = this.possibilities.sizes.sort((sizeA, sizeB) => {
        const rangeA = this.getSizeMinMax(sizeA.value);
        const rangeB = this.getSizeMinMax(sizeB.value);
        const diffA = Math.abs(rangeA.min - min) + Math.abs(rangeA.max - max);
        const diffB = Math.abs(rangeB.min - min) + Math.abs(rangeB.max - max);
        return diffA - diffB;
      })[0];
      this.updatedSubscription.businessSize = newSize.value;
    },

    handleEdit() {
      this.editMode = true;
    },

    handleSubmit() {
      if (this.changed) {
        if (this.isSubregionChanged) {
          this.showSubregionWarning = true;
        } else {
          if (!this.possibilities.sizes.includes(this.updatedSubscription.businessSize)) {
            this.useClosestBusinessSize();
          }
          this.$emit('save', this.updatedSubscription);
          this.changed = false;
        }
        this.editMode = false;
      }
    },

    async handleCancel() {
      this.updatedSubscription = { ...this.subscription };
      this.formatFarmingPeriod();
      await this.$nextTick(); // wait for changes to propogate
      this.changed = false;
      this.editMode = false;
    },

    formatFarmingPeriod() {
      // Tackle edge cases first before formatting based on subscription farming experience
      if (this.subscription.farmingPeriod <= 5 && this.subscription.farmingPeriod >= 0) {
        this.subscriptionFarmingPeriod = 5;
      } else if (this.subscription.farmingPeriod > 50) {
        this.subscriptionFarmingPeriod = 60;
      } else {
        this.subscriptionFarmingPeriod = Math.ceil(this.subscription.farmingPeriod / 10) * 10;
      }
    },

    async loadCommodities() {
      const data = await postRequest(
        '/bids/api/get_commodity_names/',
      );
      this.cropInterestsItems = data.commodities.sort();
    },
    handleAcceptProvinceWarning() {
      this.showSubregionWarning = false;
      if (this.changed) {
        if (!this.possibilities.sizes.includes(this.updatedSubscription.businessSize)) {
          this.useClosestBusinessSize();
        }
        this.$emit('save', this.updatedSubscription);
        this.changed = false;
      }
    },
  },
};
</script>
