<template>
  <v-hover v-slot="{ hover }">
    <div class="d-flex align-center">
      <div
        class="d-flex align-center flex-grow-1 py-6 c-pointer"
        @click="viewItem(id)"
      >
        <v-img
          :src="contentIcon"
          :alt="contentIcon"
          :aspect-ratio="4 / 3"
          min-width="30%"
        >
          <template #placeholder>
            <div class="pa-4 align-center justify-center">
              <v-img
                contain
                src="/static/dashboard/svg/grainfox-icon.svg"
                alt="/static/dashboard/svg/grainfox-icon.svg"
                :aspect-ratio="4 / 3"
              />
            </div>
          </template>
        </v-img>
        <div class="ml-6 flex-custom">
          <p class="grey--text text--darken-1 mb-2 caption">
            By
            <b class="font-weight-bold inline-nowrap">{{ provider.trim() }}</b>
          </p>
          <template v-if="snippet">
            <p class="primary-grey--text mb-0 text-h6 font-weight-bold">
              <span class="truncated-text truncate-title">{{ tagline }}</span>
            </p>
            <p class="primary-grey--text mb-2">
              <span
                class="truncated-text truncate-subtitle"
              >{{ snippet }}
                <span
                  class="inline-nowrap"
                >&nbsp;
                  <v-fade-transition>
                    <v-icon
                      v-visible="hover"
                      dense
                    >mdi-chevron-right</v-icon>
                  </v-fade-transition>
                </span></span>
            </p>
          </template>
          <template v-else>
            <p class="primary-grey--text mb-0 text-h6 font-weight-bold">
              <span class="truncated-text truncate-title">{{ tagline }}</span>
            </p>
            <p class="primary-grey--text mb-2">
              <span
                class="truncated-text truncate-subtitle"
              >{{ cleanedDescription }}
                <span
                  class="inline-nowrap"
                >&nbsp;
                  <v-fade-transition>
                    <v-icon
                      v-visible="hover"
                      dense
                    >mdi-chevron-right</v-icon>
                  </v-fade-transition>
                </span></span>
            </p>
          </template>
          <p class="caption grey--text text--darken-4">
            {{ dateFormatShortMonthDateYear(date) }}
          </p>
        </div>
      </div>
    </div>
  </v-hover>
</template>

<script>
import { dateFormatShortMonthDateYear } from '../../helpers/formatting';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tagline: {
      type: String,
      required: true,
    },
    snippet: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      required: true,
    },
    contentIcon: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  computed: {
    cleanedDescription() {
      return this.description.trim().replace('\\"', '');
    },
  },
  methods: {
    viewItem(id) {
      window.previewFile(id);
    },
    dateFormatShortMonthDateYear,
  },
};
</script>

<style lang="scss">
.flex-custom {
  flex: 0 0 65%;
}
/* This works on all browsers, except FireFox < version 68 and IE */
.truncated-text {
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* For the unsupported browsers, max-height is needed.
      This is dependent on line-height, so is unstable.
  */
.truncate-title {
  max-height: 4rem;
}
.truncate-subtitle {
  max-height: 3rem;
}
</style>
