const Landing = () => import('@/pages/account/Landing.vue');
const ChangePassword = () => import('@/components/form/ChangePassword.vue');
const ChangePasswordDone = () => import('@/components/form/ChangePasswordDone.vue');

const routes = [
  {
    path: '/login/',
    name: 'login',
    meta: {
      title: 'Login',
    },
    component: Landing,
  },
  {
    path: '/password_reset/',
    name: 'password_reset',
    meta: {
      title: 'Password Reset',
    },
    component: Landing,
  },
  {
    path: '/password_reset/done/',
    name: 'password_reset_done',
    meta: {
      title: 'Password Reset',
    },
    component: Landing,
  },
  {
    path: '/reset/:activationToken/:token/',
    name: 'reset_token',
    meta: {
      title: 'Password Reset',
    },
    component: Landing,
  },
  {
    path: '/password_change/',
    name: 'password_change',
    meta: {
      title: 'Password Change',
    },
    component: ChangePassword,
  },
  {
    path: '/password_change/done/',
    name: 'password_change_done',
    meta: {
      title: 'Password Change',
    },
    component: ChangePasswordDone,
  },
];

export default routes;
