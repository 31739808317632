import { postRequest } from '../helpers/request';

// Managing Coupons API's
export async function apiGetChargebeeCoupons() {
  const result = await postRequest('/commerce/get_all_cb_coupons/');
  return result;
}

export async function apiGetCoupons() {
  const result = await postRequest('/commerce/get_coupons/');
  return result;
}

export async function apiDeleteCoupon(payload) {
  const result = await postRequest('/commerce/delete_coupon', payload);
  return result;
}
export async function apiUpdateCoupon(payload) {
  const result = await postRequest('/commerce/update_coupon/', payload);
  return result;
}

export async function apiGetCouponCadences() {
  const result = await postRequest('/commerce/get_coupon_cadences/');
  return result;
}
