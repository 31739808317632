import {
  apiGetChargebeeCoupons,
  apiGetCoupons,
  apiDeleteCoupon,
  apiUpdateCoupon,
  apiGetCouponCadences,
} from '@/api/couponManagement';
import { dateFormatDateString } from '@/helpers/formatting';
import { postRequest } from '@/helpers/request';

export default {
  namespaced: true,

  state: () => ({
    couponConfigModalOpen: false,
    chargebeeCoupons: [],
    coupons: [],
    filters: {
      creator: null,
      cohort: null,
      configName: null,
      filterVisibilityStartDates: [],
      filterVisibilityEndDates: [],
      status: 'all',
      deleted: [],
      editing: false,
    },
    couponToEdit: {
      id: null,
      chargebeeCoupon: null,
      configName: null,
      interval: null,
      startDate: null,
      duration: null,
      startTime: null,
      cohort: null,
      subCohort: null,
      isActive: false,
    },
    couponCadences: [],
  }),

  mutations: {
    setCouponConfigModalOpen(state, value) {
      state.couponConfigModalOpen = value;
    },
    setChargebeeCoupons(state, value) {
      state.chargebeeCoupons = value;
    },
    setCoupons(state, value) {
      state.coupons = value;
    },
    setFilters(state, value) {
      state.filters = value;
    },
    setCouponToEdit(state, value) {
      state.couponToEdit = value;
    },
    setCouponCadences(state, value) {
      state.couponCadences = value;
    },
  },

  getters: {
    couponTableData: (state) => {
      const tableData = state.coupons.filter((coupon) => (state.filters.creator === null || coupon.creator === state.filters.creator)
      && (state.filters.cohort === null || coupon.cohort === state.filters.cohort)
      && (state.filters.configName === null || coupon.title.toLowerCase().includes(state.filters.configName.toLowerCase()))
      && (state.filters.filterVisibilityStartDates.length === 0 || (dateFormatDateString(new Date(coupon.start_time)) >= state.filters.filterVisibilityStartDates[0]
        && dateFormatDateString(new Date(coupon.start_time)) <= state.filters.filterVisibilityStartDates[1]))
      && (state.filters.filterVisibilityEndDates.length === 0 || (dateFormatDateString(new Date(coupon.end_time)) >= state.filters.filterVisibilityEndDates[0]
        && dateFormatDateString(new Date(coupon.end_time)) <= state.filters.filterVisibilityEndDates[1]))).map((coupon) => {
        const matchingChargebeeCoupon = state.chargebeeCoupons.find((obj) => obj.id === coupon.cb_coupon_id);
        let status = 'Active';
        let onHold = false;
        if (matchingChargebeeCoupon.status !== 'active') {
          status = 'On Hold';
          onHold = true;
        }
        if (!onHold) {
          const today = new Date().toISOString();
          if (coupon.cadence === '1_time') {
            if (coupon.start_time > today) {
              status = 'Scheduled';
            }
            if (coupon.end_time <= today) {
              status = 'Expired';
            }
          } else if (coupon.start_time >= today) {
            status = 'Scheduled';
          }
        }
        const cadence = state.couponCadences.find((c) => c.value === coupon.cadence);
        return { ...coupon, matchingChargebeeCoupon, status, cadence };
      }).filter((coupon) => (state.filters.status === 'all' || coupon.status === state.filters.status));

      return tableData;
    },
    couponCreatorList: (state) => {
      const creatorList = [];

      state.coupons.forEach((coupon) => {
        if (!creatorList.includes(coupon.creator)) {
          creatorList.push(coupon.creator);
        }
      });
      return creatorList;
    },
    couponCohortList: (state) => {
      const cohortList = [];
      state.coupons.forEach((coupon) => {
        if (!cohortList.includes(coupon.cohort)) {
          cohortList.push(coupon.cohort);
        }
      });
      return cohortList;
    },
  },

  actions: {
    async getChargebeeCoupons({ commit }) {
      const { result } = await apiGetChargebeeCoupons();
      commit('setChargebeeCoupons', result);
    },

    async getCoupons({ commit }) {
      const { results } = await apiGetCoupons();
      commit('setCoupons', results);
    },

    async getMarketingCampaignsByChargebeeId({ commit }, payload = {}) {
      const { marketing_campaigns } = await postRequest('/account/get_marketing_campaigns_by_chargebee_id/', payload);
      return marketing_campaigns;
    },

    async createCoupon({ dispatch }, payload) {
      try {
        await postRequest('/commerce/create_coupon/', payload);
        this._vm.$snackbar.success('Coupon Created');
        dispatch('getCoupons');
      } catch (e) {
        this._vm.$snackbar.error('Coupon could not be created');
      }
    },

    async updateCoupon({ dispatch }, payload) {
      try {
        await apiUpdateCoupon(payload);
        this._vm.$snackbar.success('Coupon Updated');
        dispatch('getCoupons');
      } catch (e) {
        this._vm.$snackbar.error('Coupon could not be updated');
      }
    },

    async deleteCoupon({ commit, state }, payload) {
      try {
        const result = await apiDeleteCoupon(payload);
        const filteredCoupons = state.coupons.filter((coupon) => coupon.id !== payload.id);
        commit('setCoupons', filteredCoupons);
        this._vm.$snackbar.success(result.message);
      } catch (e) {
        this.$snackbar.error(e);
      }
    },

    async getCouponCadences({ commit }) {
      const { results } = await apiGetCouponCadences();
      commit('setCouponCadences', results);
    },
  },
};
