<template>
  <div class="p-fixed d-none">
    <audio
      ref="player"
      @ended="handleAudioEnd"
      @timeupdate="handleAudioUpdate"
    />
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { getMediaMimeType } from '@/helpers/media';

/**
 * General note about this component: it takes advantage of the 'mediaSession' API where possible. It always
 *  performs a sanity check to make sure the browser supports the API, as certain older browsers (e.g. Safari 13)
 *  do not, but our platform does support these users.
 */

export default {
  name: 'AudioPlayer',

  computed: {
    ...mapState('mediaPlayer', ['volume', 'playing', 'duration', 'currentTime', 'activeLibrary']),
    ...mapGetters('mediaPlayer', ['activeTTSFile']),
  },

  watch: {
    /**
     * Update audio ref when state changes
     */
    playing() {
      if (this.playing) {
        this.onPlay();
      } else {
        this.onPause();
      }
    },

    volume() {
      this.$refs.player.volume = this.volume;
    },

    activeTTSFile() {
      // Updating src will trigger load event
      this.$refs.player.src = this.activeTTSFile || '';
    },

    /**
     * Update media session when active library changes
     */
    activeLibrary() {
      if ('mediaSession' in navigator && this.activeLibrary) {
        // Updating the metadata is supported on all browsers which support the media session api.

        let artwork = [];
        if (this.activeLibrary.content_image) {
          // Attach an image if there is one
          const mime = getMediaMimeType(this.activeLibrary.content_image);
          artwork = [
            {
              src: this.activeLibrary.content_image,
              sizes: `${this.activeLibrary.content_image_size.width}x${this.activeLibrary.content_image_size.height}`,
              type: mime,
            },
          ];
        }
        // There is an additional item we could use here (perhaps instead of content) which is "name", but name
        //  is not per article - it is per content type and crop (e.g. "barley sales recommendations"), which might be
        //  a better choice for albums but not for titles.
        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.activeLibrary.tagline, // The "subheader" / per article title
          artist: this.activeLibrary.provider, // The subscription which created this article, e.g. farmlink or deputter
          album: this.activeLibrary.content, // The content type, e.g. sales recommendations
          artwork,
        });
      }
    },
  },

  mounted() {
    // Load event fires durationchange once completed, so the file is fully loaded once this event is run
    this.$refs.player.addEventListener('durationchange', () => {
      this.handleLoad(this.$refs.player.duration);
    });
    // Setup media listeners for media keys.
    if ('mediaSession' in navigator) {
      // All of these handlers are supported in every version of every browser which supports the media session at all.
      navigator.mediaSession.setActionHandler('play', this.mediaPlay);
      navigator.mediaSession.setActionHandler('pause', this.mediaPause);
      navigator.mediaSession.setActionHandler('stop', this.mediaStop);
      navigator.mediaSession.setActionHandler('seekbackward', this.mediaSeekBackward);
      navigator.mediaSession.setActionHandler('seekforward', this.mediaSeekForward);
    }
    this.loadVolume();
  },

  methods: {
    ...mapMutations('mediaPlayer', ['setPlaying']),
    ...mapActions('mediaPlayer', ['loadVolume', 'handleLoad', 'handleUpdate', 'handleEnd', 'handleStop', 'handlePlay', 'handlePause']),

    handleAudioUpdate() {
      // Update the position in the track for the Vuex store, for use in the progress bars.
      this.handleUpdate({
        paused: this.$refs.player.paused,
        currentTime: this.$refs.player.currentTime,
      });
    },

    /**
     * Track concluded.
     */
    handleAudioEnd() {
      this.handleEnd(this.currentTime);
    },

    /**
     * Media key handlers
     */
    mediaPlay() {
      this.handlePlay(this.currentTime);
    },

    mediaPause() {
      this.handlePause(this.currentTime);
    },

    mediaStop() {
      this.handleStop(this.currentTime);
    },

    mediaSeekBackward() {
      this.seekMedia(-10);
    },

    mediaSeekForward() {
      this.seekMedia(10);
    },

    /**
     * Helper for seeking.
     */
    seekMedia(deltaT) {
      const currentTime = this.currentTime + deltaT;
      if (this.playing) {
        this.handlePause(this.currentTime);
        this.$nextTick(() => {
          this.handlePlay(currentTime);
        });
      } else {
        this.handleUpdate({ paused: true, currentTime, userInteraction: true });
      }
    },

    /**
     * Update whether the media session is playing or paused.
     */
    setMediaPlayState(playing) {
      if ('mediaSession' in navigator) {
        // Support for playbackState is present in all browsers supporting media session.
        navigator.mediaSession.playbackState = playing ? 'playing' : 'paused';
      }
    },

    /**
     * Play the audio file. This is NOT to be called when trying to turn on the file, but instead a response to an
     *  existing event. To play the file properly, use handlePlay in the Vuex actions.
     */
    async onPlay() {
      try {
        this.$refs.player.currentTime = this.currentTime;
        await this.$refs.player.play();
        this.setMediaPlayState(true);
      } catch (e) {
        this.setPlaying(false);
        this.setMediaPlayState(false);
      }
    },
    /**
     * Pause the audio file. This is NOT to be called when trying to turn off the file, but instead a response to an
     *  existing event. To pause the file properly, use handlePause in the Vuex actions.
     */
    async onPause() {
      this.setMediaPlayState(false);
      try {
        await this.$refs.player.pause();
      } catch (e) {
        // ignore
      }
    },
  },
};
</script>
