<template>
  <span
    v-bind="$attrs"
    :class="{'text-decoration-line-through': strikeThrough}"
  >
    {{ prefix }}{{ formatted }}{{ suffix }}
  </span>
</template>
<script>
import { tween } from 'femtotween';

export default {
  name: 'AnimatedNumber',
  props: {
    number: {
      type: Number,
      required: true,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    strikeThrough: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tweenedNumber: 0,
    };
  },
  computed: {
    formatted() {
      return this.tweenedNumber.toFixed(0);
    },
  },
  watch: {
    number(newValue) {
      tween(this.tweenedNumber, newValue, (num) => { this.tweenedNumber = num; });
    },
  },
  mounted() {
    this.tweenedNumber = this.number;
  },
};
</script>
