export default {
  namespaced: true,

  state: () => ({
    showExpansionPanel: true,
    showNewFeaturePopup: false,
    newFeature: {},
    producerTabs: [{
      id: 'weather',
      title: 'Weather',
      hide: false,
    },
    {
      id: 'growing-trends',
      title: 'Growing Trends',
      hide: false,
    },
    {
      id: 'sales-recommendations',
      title: 'Sales Recommendations',
      hide: false,
    },
    {
      id: 'risk-profile',
      title: 'Risk Profile',
      hide: false,
    },
    {
      id: 'featured-video',
      title: 'Featured Video',
      hide: false,
    },
    {
      id: 'featured-content',
      title: 'Featured Content',
      hide: false,
    },
    {
      id: 'resources',
      title: 'Resources',
      hide: false,
    },
    {
      id: 'barchart-overview',
      title: 'Barchart Overview',
      hide: false,
    },
    {
      id: 'feedback',
      title: 'Feedback',
      hide: false,
    },
    ],
    commercialTabs: [
      {
        id: 'weather',
        title: 'Weather',
        hide: false,
      },
      {
        id: 'growing-trends',
        title: 'Growing Trends',
        hide: false,
      },
      {
        id: 'featured-content',
        title: 'Featured Content',
        hide: false,
      },
      {
        id: 'market-insights',
        title: 'Market Insights',
        hide: false,
      },
      {
        id: 'morning-updates',
        title: 'Morning Updates',
        hide: false,
      },
      {
        id: 'resources',
        title: 'Resources',
        hide: false,
      },
      {
        id: 'barchart-overview',
        title: 'Barchart Overview',
        hide: false,
      },
      {
        id: 'sales-recommendations',
        title: 'Sales Recommendations',
        hide: false,
      },
      {
        id: 'featured-video',
        title: 'Featured Video',
        hide: false,
      },
    ],
  }),

  mutations: {
    setHideStatus(state, payload) {
      // update the hide status of the tab, in both producer and commercial tabs
      const { id, hide } = payload;
      const producerTab = state.producerTabs.find((tab) => tab.id === id);
      const commercialTab = state.commercialTabs.find((tab) => tab.id === id);
      if (producerTab) {
        producerTab.hide = hide;
      }
      if (commercialTab) {
        commercialTab.hide = hide;
      }
    },

    setShowExpansionPanel(state, payload) {
      state.showExpansionPanel = payload;
      localStorage.setItem('showExpansionPanel', JSON.stringify(payload));
    },

    setShowNewFeaturePopup(state, payload) {
      state.showNewFeaturePopup = payload;
    },

    setNewFeature(state, payload) {
      state.newFeature = payload;
    },
  },

  getters: {
    // looks at the chargbee store to see if the user is a commercial user, and then filters out the tabs that are not needed
    activeTabs(state, getters, rootState, rootGetters) {
      if (rootGetters['chargebee/isCommercial']) {
        return state.commercialTabs.filter((tab) => !tab.hide);
      }
      return state.producerTabs.filter((tab) => !tab.hide);
    },
  },

  actions: {
    setWeatherTab({ commit, rootState }, payload) {
      commit('setHideStatus', { id: 'weather', hide: payload });
    },

    setGrowingTrendsTab({ commit }, payload) {
      commit('setHideStatus', { id: 'growing-trends', hide: payload });
    },

    setSalesRecommendationsTab({ commit }, payload) {
      commit('setHideStatus', { id: 'sales-recommendations', hide: payload });
    },

    setRiskProfileTab({ commit }, payload) {
      commit('setHideStatus', { id: 'risk-profile', hide: payload });
    },

    setFeaturedContentTab({ commit }, payload) {
      commit('setHideStatus', { id: 'featured-content', hide: payload });
    },

    setMorningUpdatesTab({ commit }, payload) {
      commit('setHideStatus', { id: 'morning-updates', hide: payload });
    },

    setMarketInsightsTab({ commit }, payload) {
      commit('setHideStatus', { id: 'market-insights', hide: payload });
    },

    setResourcesTab({ commit }, payload) {
      commit('setHideStatus', { id: 'resources', hide: payload });
    },

    setFeaturedVideoTab({ commit, rootState }, payload) {
      commit('setHideStatus', { id: 'featured-video', hide: payload });
    },

    loadLocalStorage({ commit }) {
      const showExpansionPanel = localStorage.getItem('showExpansionPanel');
      if (showExpansionPanel !== null) {
        commit('setShowExpansionPanel', JSON.parse(showExpansionPanel));
      } else {
        commit('setShowExpansionPanel', true);
      }
    },

    async getLatestNewFeature({ commit }) {
      try {
        const result = await API.getLatestNewFeature();
        const {
          feature_name: featureName,
          feature_subtitle: featureSubtitle,
          feature_description: featureDescription,
          feature_image: featureImage,
          feature_video: featureVideo,
          feature_link: featureLink,
          feature_flag: featureFlag,
          feature_title: featureTitle,
          feature_button_text: featureButtonText,
        } = result;
        commit('setNewFeature', {
          ...result,
          featureName,
          featureSubtitle,
          featureDescription,
          featureImage,
          featureVideo,
          featureLink,
          featureFlag,
          featureButtonText,
          featureTitle,
        });
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async setNewFeatureViewedStatus({ commit, state }, featureId) {
      API.setNewFeatureViewed(featureId);
      commit('setShowExpansionPanel', true);
      if (!state.newFeature.persistent) {
        commit('setShowNewFeaturePopup', false);
      }
    },
  },

};
