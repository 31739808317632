import { postRequest } from '../../helpers/request';

const BLACKLIST_BUYER_REGEX = /(farmbucks)|(cxn360)/i;

export default {
  state: () => ({
    buyers: {},
    allContractBuyers: [],
  }),
  getters: {
    isBlacklistedBuyer: (state) => (buyer) => {
      let b = buyer;
      if (typeof b === 'number') {
        b = state.buyers[b]?.name;
      } else if (typeof b === 'object') {
        b = b?.name;
      }

      return !b || BLACKLIST_BUYER_REGEX.test(b);
    },
    buyersFiltered: (state, getters) => Object.fromEntries(
      Object.entries(state.buyers).filter(
        ([, val]) => !getters.isBlacklistedBuyer(val),
      ),
    ),
    buyersAlphabetical: (state, getters) => Object.values(getters.buyersFiltered)
      .sort((a, b) => a.name.localeCompare(b.name)),

    allContractBuyersAlphabetical: (state) => state.allContractBuyers
      .sort((a, b) => a.name.localeCompare(b.name)),
  },
  mutations: {
    setBuyers(state, buyers) {
      state.buyers = buyers;
    },

    setAllContractBuyers(state, buyers) {
      state.allContractBuyers = buyers;
    },
  },
  actions: {
    async fetchBuyers({ commit }) {
      const buyers = await postRequest(
        '/bids/api/get_all_buyers/',
      );
      commit('setBuyers', buyers);
    },

    async fetchAllContractBuyers({ commit }) {
      const { results: buyers } = await API.getAllContractBuyers();
      commit('setAllContractBuyers', buyers);
    },
  },

  namespaced: true,
};
