import { datadogRum } from '@datadog/browser-rum';
// eslint-disable-next-line import/extensions
import { getProperties, combineObjectArrays } from '@/helpers/johnDeereMapping.ts';
import { postRequest } from '@/helpers/request';
import { roundTo2Decimals } from '@/helpers/formatting';

export default {
  state: () => ({
    commodityMappings: [],
    /**
     * A map in the form of:
     * farmLocationId: [
     *   {crop_year: cropYearId1, integration_source: integrationSourceId1},
     *   {crop_year: cropYearId2, integration_source: integrationSourceId2},
     *   ...
     * ]
     */
    farmLocationIntegrations: {},
  }),

  mutations: {
    setCommodityMappings(state, integrationSources) {
      state.commodityMappings = integrationSources;
    },
    setFarmLocationIntegrations(state, integrations) {
      state.farmLocationIntegrations = integrations;
    },
  },

  getters: {
    getIntegrationMapping: (state) => (farmLocationId, cropYearId) => state
      .farmLocationIntegrations[farmLocationId]?.find((x) => x.crop_year === cropYearId),
  },

  actions: {
    async fetchCommodityMappings({ _, commit }) {
      const { results: defaultCommodityMappings } = await postRequest('/farm_profile/api/integrations/john_deere/fetch_commodities/');
      const defaultCommodityMatches = Object.values(defaultCommodityMappings).map((mapping) => ({
        display_name: mapping.display_name,
        source_id: mapping.name,
        grainfox_id: mapping.gf_commodity,
      }));

      const payload = {
        type: 'commodity',
        source: 'john_deere',
        ids: Object.values(defaultCommodityMappings).map((mappingObject) => mappingObject.name),
      };
      const { results: userPreferredCommodityMappings } = await postRequest(
        '/farm_profile/api/integrations/fetch_user_commodity_mapping_preference/',
        payload,
      );

      const userPreferredCommodityMatches = userPreferredCommodityMappings.map((mappingObject) => ({
        display_name: defaultCommodityMatches
          .find((mapping) => mapping.source_id === mappingObject.source_id).display_name,
        source_id: mappingObject.source_id,
        grainfox_id: mappingObject.grainfox_id,
      }));

      const defaultCommodityMatchesFiltered = defaultCommodityMatches
        .filter((defaultMapping) => !userPreferredCommodityMatches
          .some((preferredMapping) => preferredMapping.source_id === defaultMapping.source_id));

      commit('setCommodityMappings', [...userPreferredCommodityMatches, ...defaultCommodityMatchesFiltered]);
    },

    setCommodityMappings({ state, commit }, mappings) {
      const newMappings = mappings.map((mappingObject) => ({
        display_name: state.commodityMappings
          .find((mapping) => mapping.source_id === mappingObject.source_id).display_name,
        source_id: mappingObject.source_id,
        grainfox_id: mappingObject.grainfox_id,
      }));

      const currentCommodityMatchesFiltered = state.commodityMappings
        .filter((defaultMapping) => !newMappings
          .some((preferredMapping) => preferredMapping.source_id === defaultMapping.source_id));

      commit('setCommodityMappings', [...mappings, ...currentCommodityMatchesFiltered]);
    },

    async fetchFarmLocationIntegrations({ commit }) {
      try {
        const result = await postRequest('/farm_profile/api/integrations/john_deere/get_farm_location_integrations/');
        commit('setFarmLocationIntegrations', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    /**
     * Uses a bulk create to create all actual productions for each farm location id : actualProductions pair,
     * and then enables the JD integration for each farm location
     */
    async enableJDIntegration(
      { rootState, rootGetters, commit },
      { mappings, farmLocationsWithAPs },
    ) {
      // get the default units for the farm, and add them to each ap if they don't exist
      const unitsDefault = {
        area_unit: rootGetters['farmProfile/currentUnitPreference']('Area').id,
        production_unit: rootGetters['farmProfile/currentUnitPreference']('Production').id,
      };
      const jdId = rootGetters['farmProfile/integrations/getIntegrationSourceByName']('john_deere').id;
      const actualProductions = [];
      Object.entries(farmLocationsWithAPs).forEach(([farmLocationId, aps]) => {
        // push farmlocation into each ap, convert objects into ids for the backend
        aps.forEach((ap) => {
          actualProductions.push({
            ...unitsDefault,
            ...ap,
            farm_location: farmLocationId,
            crop_year: ap.crop_year,
            commodity: ap.commodity,
          });
        });
      });
      await postRequest('/farm_profile/api/integrations/enable/', { source: jdId, mappings, actual_productions: actualProductions });
    },

    // Converting the response from fetching JD data into productions
    async convertJDToProductions({ dispatch }, { farms, fieldOperations, measurements }) {
      await dispatch('fetchCommodityMappings');
      const res = await Promise.all(farms.map(
        async (farm) => {
          const jdProds = await dispatch('convertJDProductions', {
            measurements: measurements[farm.id],
            fieldOperations: fieldOperations[farm.id],
          });
          return ({ id: farm.id, jdProds });
        },
      ));
      return res.reduce((acc, { id, jdProds }) => ({ ...acc, [id]: jdProds }), {});
    },

    /**
     * Convert FieldOperations to GF ActualProductions
     * Given 2 arrays, of equal length:
     *  - one for FieldOperations ("/fieldOperations/{operationId}")
     *  - one for the corresponding Measurements
     *  ("/fieldOperations/{operationId}/measurementTypes")
     *
     *
     * Steps for conversion:
     * 1. For each measurement, convert to area, and production, with GF units
     * 2. For each operation, convert to CropYear and commodity
     * 3. Combine the 2 arrays into an array of ActualProductions
     * 4. Filter out any ActualProductions that are not from the current crop year
     * 5. Combine ActualProductions with the same CropYear and Commodity
     * 6. Calculate yield and yield unit for each ActualProduction
     *
     * Returns an array of ActualProductions:
     *  production, production_unit,
     *  seeded_area, harvested_area, area_unit,
     *  yield,
     *  crop_year (id), commodity (id),
     *
     * Returns an Array of ActualProductions
     */
    async convertJDProductions({ dispatch }, { fieldOperations, measurements }) {
      const mappedMeasurements = await dispatch('convertMeasurements', { measurements });
      const mappedOperations = await dispatch('convertFieldOperations', { fieldOperations });

      // this combines them into something resembling an actual production
      const combined = combineObjectArrays(mappedOperations, mappedMeasurements);
      const combinedAsAPs = await dispatch('combineActualProductions', { actualProductions: combined });
      const currentYearAps = await dispatch('filterOtherCropYears', { actualProductions: combinedAsAPs });
      const withYield = await dispatch('calculateYield', { actualProductions: currentYearAps });
      return withYield;
    },

    /**
     * Converts JD measurements to GF measurements, including string units to unit ids
     */
    convertMeasurements({ state, rootState, rootGetters }, { measurements }) {
      const mappedMeasurements = measurements.map((measurement) => {
        const mapped = getProperties(measurement);
        if ('production_unit' in mapped) {
          const unit = rootGetters['shared/unitByAbbreviation'](mapped.production_unit);
          if (!unit) {
            const error = new Error(`[jd-missing-unit] Missing production unit: ${mapped.production_unit} with measurement ${measurement}`);
            datadogRum.addError(error);
            throw new Error(`Could not map JD production unit: ${mapped.production_unit}`);
          }
          mapped.production_unit = unit.id;
        }
        if ('area_unit' in mapped) {
          const unit = rootGetters['shared/unitByAbbreviation'](mapped.area_unit);
          if (!unit) {
            const error = new Error(`[jd-missing-unit] Missing area unit: ${mapped.area_unit} with measurement ${measurement}`);
            datadogRum.addError(error);
            throw new Error(`Could not map JD area unit: ${mapped.area_unit}`);
          }
          mapped.area_unit = unit.id;
        }
        return mapped;
      });
      return mappedMeasurements;
    },

    /**
       * Converts a list of field operations (cropName, startDate)
       * to a list of objects with GF crop_years and commoditiy Ids
       */
    convertFieldOperations({ state, rootGetters }, { fieldOperations }) {
      const mappedOperations = fieldOperations.map((operation) => {
        const jdCommodity = operation.cropName;
        const jdOperationStart = operation.startDate;
        // convert cropyear to GF cropyear
        const GFCropyear = rootGetters['shared/cropYearByDate'](jdOperationStart);
        const mappedCommodity = Object.values(state.commodityMappings).find(
          (commodityMapping) => commodityMapping.source_id === jdCommodity,
        );
        if (!mappedCommodity) {
          throw new Error(`Could not map JD commodity: ${jdCommodity}`);
        } else if (!GFCropyear) {
          throw new Error(`Could not map JD date to cropyear: ${jdOperationStart}`);
        }

        return {
          commodity: { id: mappedCommodity.source_id, name: mappedCommodity.display_name },
          crop_year: GFCropyear.id,
        };
      }).filter((operation) => operation !== null);
      return mappedOperations;
    },

    /**
       * Takes a list of ActualProductions with JD style commodities and converts
       * those commodities to their respective GF commodities taking into account
       * user preferences
       *
       * It also combines commodities which share an underlying GF commodity and crop year
       * since aps are unique along these lines and thus the aps would be invalid without
       * combination.
       *
       * Assumes that the given set of aps is for a single farm location.
       */
    convertCommodities({ state, rootGetters }, aps) {
      return aps.reduce((actualProductions, actualProduction) => {
        const mappedCommodity = Object.values(state.commodityMappings).find(
          (commodityMapping) => commodityMapping.source_id === actualProduction?.commodity?.id,
        );

        const updatedProduction = { ...actualProduction, commodity: mappedCommodity.grainfox_id };

        const existingProduction = actualProductions.find(
          (currentProduction) => currentProduction.commodity === updatedProduction.commodity
            && currentProduction.crop_year === updatedProduction.crop_year,
        );

        if (!existingProduction) {
          actualProductions.push(updatedProduction);
          return actualProductions;
        }

        updatedProduction.seeded_area = rootGetters['shared/convert'](
          updatedProduction.seeded_area,
          updatedProduction.area_unit,
          existingProduction.area_unit,
          updatedProduction.gf_commodity,
        );

        updatedProduction.harvested_area = rootGetters['shared/convert'](
          updatedProduction.harvested_area,
          updatedProduction.area_unit,
          existingProduction.area_unit,
          updatedProduction.gf_commodity,
        );

        updatedProduction.production = rootGetters['shared/convert'](
          updatedProduction.production,
          updatedProduction.production_unit,
          existingProduction.production_unit,
          updatedProduction.commodity,
        );

        existingProduction.harvested_area += updatedProduction.harvested_area;
        existingProduction.production += updatedProduction.production;
        existingProduction.seeded_area += updatedProduction.seeded_area;

        return actualProductions;
      }, []);
    },

    /**
     * As of now, previous crop years are not supported. This function filters out
     * any actual productions that are not from the current crop year.
     */
    filterOtherCropYears({ rootGetters }, { actualProductions }) {
      const currentCropYear = rootGetters['shared/currentCropYear'];
      return actualProductions.filter((ap) => ap.crop_year === currentCropYear.id);
    },

    /**
       * Given a list of
       * actual productions (production, seeded_area, harvested_area, crop_year, commodity)
       * Combines them into a list of actual productions with the same crop_year and commodity
       *
       * Note: Each entry will either have area or production, probably not both,
       * therefore null values are expected
       *
       * Note 2: This function converts all production units to bushels. This is because JD
       * can give us units that are hidden from the user in grainfox.
       */
    async combineActualProductions({ state, rootGetters }, { actualProductions }) {
      const combined = [];
      const buAUnit = await rootGetters['shared/unitByAbbreviation']('bu A');
      actualProductions.forEach((actualProduction) => {
        const existing = combined.find((ap) => ap.crop_year === actualProduction.crop_year
          && ap?.commodity?.id === actualProduction?.commodity?.id);

        if (!existing) {
          combined.push({
            seeded_area: 0, harvested_area: 0, production: 0, ...actualProduction, production_unit: buAUnit.id,
          });
        } else {
          // area_unit should always be present (i.e. both seeding and harvesting
          // have area_unit, so existing obj should always have it)
          const isAreaSameUnit = existing.area_unit === actualProduction.area_unit;
          existing.seeded_area += isAreaSameUnit
            ? (actualProduction.seeded_area || 0)
            : (rootGetters['shared/convert'](
              actualProduction.seeded_area,
              actualProduction.area_unit,
              existing.area_unit,
            ) || 0);
          existing.harvested_area += isAreaSameUnit
            ? (actualProduction.harvested_area || 0)
            : (rootGetters['shared/convert'](
              actualProduction.harvested_area,
              actualProduction.area_unit,
              existing.area_unit,
            ) || 0);

          // convert production to bushels
          // ternary in the case its an AP from a seeding operation without production
          existing.production += !actualProduction.production ? 0
            : (rootGetters['shared/convert'](
              actualProduction.production,
              actualProduction.production_unit,
              buAUnit.id,
              actualProduction.commodity,
            ));
        }
      });
      return combined;
    },

    /**
       * Given a list of
       * actual productions (production, seeded_area, harvested_area,
       * crop_year, commodity, area_unit, production_unit)
       * adds a yield and yield unit to each
       *
       * Also Defaults seeded_area to harvested_area if seeded_area is less than harvested_area
       */
    calculateYield({ rootGetters }, { actualProductions }) {
      return actualProductions.map((actualProduction) => {
        // eslint-disable-next-line camelcase
        const { production, harvested_area } = actualProduction;
        // eslint-disable-next-line camelcase
        const yieldValue = roundTo2Decimals(production / harvested_area) || 0;
        const seeded_area = actualProduction.harvested_area > actualProduction.seeded_area
          ? actualProduction.harvested_area : actualProduction.seeded_area;
        return { ...actualProduction, yield: yieldValue, seeded_area };
      });
    },
  },
  namespaced: true,
};
