<template>
  <div>
    <group-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :show-add="canCreateGroups"
      @add="handleAdd"
      @edit="handleEdit"
      @delete="handleDelete"
    />

    <v-dialog
      v-model="groupDialog"
      persistent
      scrollable
      max-width="640px"
      width="100%"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
    >
      <group-info
        v-if="groupDialog"
        :group="group"
        :groups="groups"
        :permissions="permissions"
        :scopes="scopes"
        :title="groupDialogTitle"
        :loading="loadingGrid"
        @cancel="handleCancel"
        @save="handleSave"
      />
    </v-dialog>

    <v-dialog
      v-model="confirmDialog"
      persistent
    >
      <confirm-card
        :text="confirmDialogText"
        :heading="confirmDialogTitle"
        :warnings="confirmDialogWarnings"
        @cancel="resetConfirmDialog"
        @confirm="confirmDialogMethod"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupInfo from './GroupInfo.vue';
import GroupTable from './GroupTable.vue';
import { postRequest } from '../../helpers/request';

const DEFAULT_GROUP = {
  id: null,
  name: '',
  scope_permissions: [],
};

export default {
  components: {
    GroupInfo,
    GroupTable,
  },

  data() {
    return {
      groupDialog: false,
      groupDialogTitle: '',

      group: { ...DEFAULT_GROUP },

      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Permissions', value: 'scope_permissions' },
        { text: 'Members', value: 'user_count' },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],

      groups: [],
      scopes: [],
      permissions: [],
      loading: false,
      loadingGrid: false,

      confirmDialog: false,
      confirmDialogText: '',
      confirmDialogTitle: 'Confirm',
      confirmDialogWarnings: ['All members will be moved to the Member permission level'],
      confirmDialogMethod: () => {},
    };
  },

  computed: {
    items() {
      return this.groups;
    },

    ...mapGetters('permissions', ['checkPermission']),

    canCreateGroups() {
      return this.checkPermission('groups', 'create');
    },
  },

  created() {
    this.readGroups();
  },

  methods: {
    handleAdd() {
      this.groupDialog = true;
      this.groupDialogTitle = 'Add Permission Level';
    },

    handleCancel() {
      this.reset();
      this.groupDialog = false;
    },

    async deleteGroup(item) {
      try {
        await postRequest('/dashboard/api/delete_group/', item);
        await this.readGroups();
      } catch (e) {
        this.$snackbar.error(e);
      }
    },

    resetConfirmDialog() {
      this.confirmDialog = false;
      this.confirmDialogText = '';
      this.confirmDialogMethod = () => {};
    },

    async handleDelete(item) {
      this.confirmDialog = true;
      this.confirmDialogText = `Do you want to delete the ${item.name} permission level?`;
      this.confirmDialogMethod = () => {
        this.deleteGroup(item);
        this.resetConfirmDialog();
      };
    },

    handleEdit(item) {
      this.group = { ...item };
      this.groupDialog = true;
      this.groupDialogTitle = 'Edit Group';
    },

    async handleSave(item) {
      // TODO: https://grainfox.atlassian.net/browse/UT-869 make permission reactive
      try {
        this.loadingGrid = true;
        const { message } = item.id
          ? await postRequest('/dashboard/api/update_group/', item)
          : await postRequest('/dashboard/api/create_group/', item);
        this.$snackbar.success(message);
        this.reset();
        await this.readGroups();
      } catch (e) {
        this.$snackbar.error(e);
      } finally {
        this.loadingGrid = false;
      }
    },

    async readGroups() {
      try {
        this.loading = true;
        const { result } = await postRequest('/dashboard/api/read_groups/');
        this.groups = result.groups;
        this.permissions = result.permissions;
        this.scopes = result.scopes;
      } catch (e) {
        this.$snackbar.error(e);
      } finally {
        this.loading = false;
      }
    },

    reset() {
      this.group = { ...DEFAULT_GROUP };
      this.groupDialog = false;
      this.groupDialogTitle = '';
    },
  },
};
</script>
