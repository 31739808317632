<template>
  <v-app-bar
    v-if="isAuthenticated"
    color="white"
    class="overflow-y-hidden"
    :style="paddingLeftStyle"
    :height="totalHeaderHeight"
    :elevation="0"
    app
  >
    <TrialBanner
      :height="trialBannerHeight"
      :show="showTrialBanner"
      @hide="showTrialBanner = false"
    />

    <div
      class="d-flex flex-nowrap flex-grow-1 align-center overflow-hidden"
      :class="$isMobile ? 'px-2' : 'px-10'"
    >
      <v-btn
        v-if="$isMobile"
        color="primary"
        class="mr-2"
        text
        icon
        large
        @click="toggleNavDrawerState"
      >
        <v-icon large>
          mdi-menu
        </v-icon>
      </v-btn>

      <h1
        data-qa="app-header-title"
        class="grey--text text--darken-3 text-no-wrap"
        :class="$isMobile ? 'text-body-1' : 'text-h5'"
      >
        {{ $metadata.pageTitle }}
      </h1>

      <v-spacer />

      <TrialUpdateButton class="ml-2" />
      <AnalysisDashboardButton class="ml-2" />

      <!-- Notifications and Account Menu -->
      <div v-if="!$isMobile">
        <div class="d-flex flex-nowrap">
          <div class="ml-2">
            <HelpDocument />
          </div>
          <div>
            <NotificationsDropdown />
          </div>
          <div class="ml-2">
            <AccountMenu />
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import AccountMenu from '@/components/account/AccountMenu.vue';
import NotificationsDropdown from './NotificationsDropdown.vue';
import HelpDocument from './HelpDocument.vue';
import TrialBanner from '@/components/app/TrialBanner.vue';
import TrialUpdateButton from '@/components/app/TrialUpdateButton.vue';
import AnalysisDashboardButton from '@/components/analysis_dashboard/AnalysisDashboardButton.vue';

export default {
  name: 'AppHeader',

  components: {
    AccountMenu,
    NotificationsDropdown,
    HelpDocument,
    TrialBanner,
    TrialUpdateButton,
    AnalysisDashboardButton,
  },

  data() {
    return {
      showTrialBanner: true,
    };
  },

  computed: {
    ...mapState('app', ['headerHeightMobile', 'headerHeightDesktop']),
    ...mapState('user', ['partnership', 'cobranding']),
    ...mapGetters('user', ['isAuthenticated']),

    paddingLeftStyle() {
      return `padding-left: ${this.$navigationOffset}px;`;
    },

    baseHeight() {
      return this.$isMobile ? this.headerHeightMobile : this.headerHeightDesktop;
    },

    trialBannerHeight() {
      return this.showTrialBanner ? this.baseHeight : 0;
    },

    totalHeaderHeight() {
      return this.baseHeight + this.trialBannerHeight;
    },
  },

  beforeMount() {
    if (this.partnership) this.setFetchedCampaign(this.partnership);
    if (this.cobranding) this.setCampaignCobranding(this.cobranding);
  },

  methods: {
    ...mapMutations('marketingModule', ['setFetchedCampaign', 'setCampaignCobranding']),
    ...mapMutations('app', ['toggleNavDrawerState']),
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  ::v-deep .v-toolbar__content {
    padding: 0!important;
    display: flex;
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}
</style>
