<template>
  <div
    v-intersect="{
      handler: onIntersect,
      options: {
        root: $parent.$el,
        threshold,
      }
    }"
    :class="[shrinkClass, intersectSideClass]"
    class="g-carousel-item flex-shrink-0"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GCarouselItem',

  props: {
    threshold: {
      type: Number,
      default: 0.25,
    },
  },

  data() {
    return {
      isIntersecting: true,
      intersectSideClass: 'g-carousel-item--right',
    };
  },

  computed: {
    shrinkClass() {
      return this.isIntersecting ? '' : 'g-carousel-item--shrink';
    },
  },

  methods: {
    onIntersect(entries) {
      const [entry] = entries;

      // There's an edge case on resize where this is null.
      // I think it's best to ignore it, otherwise it will
      // seem like the element is not intersecting.
      if (entry.rootBounds === null) return;

      this.isIntersecting = entry.isIntersecting;
      this.intersectSideClass = `g-carousel-item--${entry.boundingClientRect.x > 0 ? 'right' : 'left'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.g-carousel-item {
  > :first-child {
    height: 100%;
    transition: transform 250ms ease-out;
  }

  &--left > :first-child {
    transform-origin: center right;
  }

  &--right > :first-child {
    transform-origin: center left;
  }

  &--shrink > :first-child {
    transform: scale(0.9);
  }
}
</style>
