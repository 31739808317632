<template>
  <div>
    <subscription-tabs tab="/dashboard/organization/farm_locations/" />
    <GParentContainer>
      <FarmLocationTable
        v-if="farmLocationsAlphabetical.length > 0"
        :items="farmLocationsAlphabetical"
        @primary-farm-updated="fetchFarmLocations"
      />
      <div v-else>
        <div class="pa-4">
          <span
            v-if="$isMobile"
            class="text-h6"
          >
            Farm Locations
          </span>
        </div>
        <FarmLocationsEmptyStateCard :loading="isLoading" />
      </div>
    </GParentContainer>
    <FarmLocationDialog />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FarmLocationsEmptyStateCard from '@/components/account/profile/FarmLocationsEmptyStateCard.vue';
import FarmLocationTable from '@/components/account/profile/FarmLocationTable.vue';
import FarmLocationDialog from '@/components/farm_profile/shared/FarmLocationDialog.vue';

export default {

  components: {
    FarmLocationsEmptyStateCard,
    FarmLocationTable,
    FarmLocationDialog,
  },
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('farmLocations', ['farmLocationsAlphabetical']),
  },

  async created() {
    this.isLoading = true;
    await Promise.all([
      this.fetchAllCropYears(),
      this.fetchFarmLocations(),
    ]);
    this.isLoading = false;
  },

  methods: {
    ...mapActions('farmLocations', ['fetchFarmLocations']),
    ...mapActions('shared', ['fetchAllCropYears']),
  },
};
</script>
