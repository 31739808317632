import { postRequest } from '../../helpers/request';

export default {
  state: () => ({
    activeSubscriptionCoupon: null,
    addonDialogOpen: false,
    showTermsAndConditionsDialog: false,
  }),

  mutations: {
    setActiveSubscriptionCoupon(state, value) {
      state.activeSubscriptionCoupon = value;
    },
    setAddonDialogOpen(state, value) {
      state.addonDialogOpen = value;
    },
    setShowTermsAndConditionsDialog(state, value) {
      state.showTermsAndConditionsDialog = value;
    },
  },

  actions: {
    async fetchActiveSubscriptionCoupon({ commit, rootState }) {
      const data = await postRequest('/commerce/get_subscription_active_coupon/');
      commit('setActiveSubscriptionCoupon', data);
    },
    async updateActiveSubscriptionCoupon({ commit, rootState }, coupon) {
      const data = await postRequest('/commerce/update_or_create_subscription_active_coupon/', coupon);
      commit('setActiveSubscriptionCoupon', data);
    },
  },
  namespaced: true,
};
