<template>
  <div
    v-if="show"
    data-skip-highlight="true"
    class="container"
    :class="{'glow': onboardingTooltip,'highlight': hightlightAudioPlayer}"
  >
    <GOnboardingTooltip
      :value="onboardingTooltip"
      title="New text to speech feature"
      body="You can now listen to our articles on the go."
      position="bottom-end"
      @ok="onboardingTooltipClosed"
    >
      <v-btn
        fab
        x-small
        color="primary"
        :disabled="disabled"
        @click="onClick"
      >
        <v-icon>{{ playing ? 'mdi-pause' : 'mdi-play' }}</v-icon>
      </v-btn>
    </GOnboardingTooltip>

    <div class="d-flex align-center g-4 flex-1">
      <span class="text-subtitle-2 grey--text text--darken-1">{{ formattedCurrentTime }}</span>
      <div
        class="flex-1 p-rel"
        @click="onboardingTooltipClosed"
      >
        <v-progress-linear
          ref="progress"
          v-model="progressMut"
          :class="{'c-pointer': !disabled}"
          style="transition: none;"
          :color="disabled ? 'grey' : 'primary'"
          rounded
          background-color="#EEEEEE"
          :indeterminate="disabled"
        />
        <div
          v-if="!disabled"
          class="dot"
          :style="dotStyle"
          @mousedown="dotMouseDown"
        />
      </div>
      <span class="text-subtitle-2 grey--text text--darken-1">{{ formattedDuration }}</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { datadogRum } from '@datadog/browser-rum';
import { formatSeconds } from '@/helpers/formatting';
import GOnboardingTooltip from '@/components/generic/GOnboardingTooltip.vue';
import { bindToRange } from '@/helpers/numeric';

export default {
  name: 'AudioInfix',

  components: {
    GOnboardingTooltip,
  },

  data() {
    return {
      seeking: false,
      onboardingTooltip: false,
      hightlightAudioPlayer: false,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapState('mediaPlayer', ['playing', 'hasPlayed', 'duration', 'currentTime', 'activeLibrary', 'loadTime']),
    ...mapGetters('mediaPlayer', ['progress', 'activeTTSFile']),

    show() {
      return this.flags['show-audio-player']
            && this.activeLibrary
            && !this.activeLibrary.showTeaser
            && !!this.activeTTSFile;
    },

    disabled() {
      return !this.show || !this.loadTime;
    },

    // Seeking helper data
    progressMut: {
      get() {
        if (this.disabled) {
          return 0;
        }
        return this.progress * 100;
      },

      set(val) {
        if (this.disabled) {
          return;
        }
        const frac = bindToRange(val / 100, 0, 1);
        const currentTime = frac * this.duration;
        if (this.playing) {
          // Handle pause + play segments the audio play section in analytics.
          this.handlePause(this.currentTime);
          this.$nextTick(() => {
            this.handlePlay(currentTime);
          });
        } else {
          // If not playing, no need to segment, just update time directly.
          this.handleUpdate({ paused: true, currentTime, userInteraction: true });
        }
      },
    },

    dotStyle() {
      return `left: calc(${this.progressMut}% - 8px);`;
    },

    formattedCurrentTime() {
      return this.formatSeconds(this.currentTime);
    },

    formattedDuration() {
      return this.formatSeconds(this.duration);
    },
  },

  beforeDestroy() {
    window.removeEventListener('mousemove', this.dotDrag);
    window.removeEventListener('mouseup', this.dotMouseUp);
  },

  mounted() {
    // Global listeners for seek controls
    window.addEventListener('mousemove', this.dotDrag);
    window.addEventListener('mouseup', this.dotMouseUp);
    if (this.$route.query.tts === 'true') {
      // user coming from tts enabled email
      datadogRum.addAction('tts-email', {
        library: this.activeLibrary,
      });
      if (this.$route.query.highlight === 'true') {
        // user coming from "listen now" on email
        this.hightlightAudioPlayer = true;
        datadogRum.addAction('tts-email-listen-now', {
          library: this.activeLibrary,
        });
      } else {
        // user coming from "read more" on email
        datadogRum.addAction('tts-email-read-more', {
          library: this.activeLibrary,
        });
      }
    }
  },

  methods: {
    formatSeconds,
    ...mapActions('mediaPlayer', ['handlePlay', 'handlePause', 'handleStop', 'handleUpdate', 'setActiveLibraryViewedTutorial']),

    onOpen() {
      this.updateOnboardingTooltip();
      // Lifecycle event for when dialog is opened
    },

    onClose() {
      // Lifecycle event for when dialog is closed
      // NOTE: this will need to be removed if the global media player is implemented
      if (this.hasPlayed) {
        this.handleStop();
      }
    },

    async updateOnboardingTooltip() {
      this.onboardingTooltip = Boolean(this.activeLibrary && this.activeLibrary.showTTSTutorial && this.activeTTSFile);
      if (this.onboardingTooltip) {
        await this.setActiveLibraryViewedTutorial();
      }
    },

    async onboardingTooltipClosed() {
      this.onboardingTooltip = false;
    },
    /**
     * Bind the infix to a resource hub article.
     */
    applyTo(el) {
      // For certain articles, this subheader is in the wrong place, and we need to use the .g-layout__content class
      const target = el.querySelector('#subheader');
      if (!target) {
        const layoutContent = el.querySelector('.g-layout__content');
        if (layoutContent) {
          layoutContent.parentNode.insertBefore(this.$el, layoutContent);
        }
        return;
      }
      const targetParent = target.parentNode;
      if (targetParent.classList.contains('g-layout__content')) {
        targetParent.insertBefore(this.$el, target.nextSibling);
        return;
      }
      const realTarget = el.querySelector('.g-layout__content');
      // Safety check; this class is not guaranteed to exist,
      // and neither is there a guarantee of a child/sibling.
      try {
        const { firstElementChild } = realTarget;
        const secondChild = firstElementChild.nextSibling;
        realTarget.insertBefore(this.$el, secondChild);
      } catch (e) {
        // Could not determine where to insert the infix
      }
    },

    /**
     * Seek controls
     */
    dotMouseDown() {
      this.seeking = true;
    },

    dotDrag(event) {
      if (this.seeking) {
        const rect = this.$refs.progress.$el.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const frac = x / rect.width;
        this.progressMut = frac * 100;
      }
    },

    dotMouseUp(event) {
      this.dotDrag(event);
      this.seeking = false;
    },

    /**
     * Toggle play state
     */
    onClick() {
      if (this.playing) {
        this.handlePause(this.currentTime);
      } else {
        this.handlePlay(this.currentTime);
      }
      this.onboardingTooltipClosed();
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/colors/colors';

@keyframes focus-animation {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 5px 2px colors.$secondary;
  }
  100% {
    box-shadow: none;
  }
}

.container {
  padding: 1rem;
  display: flex;
  gap: 1.5rem;
  background-color: colors.$greyLighten5;
  border-radius: 1rem;
}

.highlight {
  animation: focus-animation 1s ease-in-out 2;
}
.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: colors.$primary;
  top: -2px;
  cursor: pointer;
}
::v-deep .g-onboarding-tooltip p {
  margin: 0;
}

.glow {
  box-shadow: 0px 2px 14px rgba(181, 153, 133, 0.24);
  border: 1px solid colors.$secondary;
}
</style>
