<template>
  <div
    class="grey lighten-5"
  >
    <h1 class="pb-4 text-h6 pl-2">
      Recommended for you
    </h1>
    <div
      v-for="(recommendation, i) in recommendations"
      :key="i"
    >
      <RecommendationCard
        :recommendation="recommendation"
        @view="$emit('view', $event)"
      />
      <v-divider
        v-if="i != recommendations.length - 1"
        class="my-4"
      />
    </div>
  </div>
</template>
<script>
import RecommendationCard from './RecommendationCard.vue';

export default {
  name: 'RecommendationAside',
  components: {
    RecommendationCard,
  },
  props: {
    recommendations: {
      type: Array,
      required: true,
    },
  },
};
</script>
