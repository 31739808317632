<template>
  <v-data-table
    dense
    :headers="headers"
    :items="scopes"
    :items-per-page="-1"
    :hide-default-footer="true"
    group-by="category"
  >
    <template #[`group.header`]="{ group, toggle, isOpen }">
      <td
        v-if="group !== ''"
        :colspan="headers.length"
      >
        <v-btn
          :ref="group"
          small
          icon
          :data-open="isOpen"
          @click="toggle"
        >
          <v-icon
            :class="{'rotate-180' : isOpen}"
          >
            $expand
          </v-icon>
        </v-btn>
        {{ group }}
      </td>
    </template>

    <template #[`item.name`]="{ item }">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            dark
            left
            small
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>

        <span>{{ item.description }}</span>
      </v-tooltip>

      {{ snakeToPascal(item.name, true) }}
    </template>

    <template #[`item.create`]="{ item }">
      <v-simple-checkbox
        v-if="isPermissionAllowedOnScope(item.id, permissionIds.create)"
        color="primary"
        :value="isPermissionOnScope(item.id, permissionIds.create)"
        @input="handlePermissionChange(item.id, permissionIds.create)"
      />
    </template>

    <template #[`item.read`]="{ item }">
      <v-simple-checkbox
        v-if="isPermissionAllowedOnScope(item.id, permissionIds.read)"
        color="primary"
        :value="isPermissionOnScope(item.id, permissionIds.read)"
        @input="handlePermissionChange(item.id, permissionIds.read)"
      />
    </template>

    <template #[`item.update`]="{ item }">
      <v-simple-checkbox
        v-if="isPermissionAllowedOnScope(item.id, permissionIds.update)"
        color="primary"
        :value="isPermissionOnScope(item.id, permissionIds.update)"
        @input="handlePermissionChange(item.id, permissionIds.update)"
      />
    </template>

    <template #[`item.delete`]="{ item }">
      <v-simple-checkbox
        v-if="isPermissionAllowedOnScope(item.id, permissionIds.delete)"
        color="primary"
        :value="isPermissionOnScope(item.id, permissionIds.delete)"
        @input="handlePermissionChange(item.id, permissionIds.delete)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { snakeToPascal } from '../../helpers/formatting';

const HEADERS = [
  {
    text: 'Scope',
    value: 'name',
    sortable: false,
  },

  {
    text: 'Create',
    value: 'create',
    align: 'center',
    sortable: false,
  },

  {
    text: 'Read',
    value: 'read',
    align: 'center',
    sortable: false,
  },

  {
    text: 'Update',
    value: 'update',
    align: 'center',
    sortable: false,
  },

  {
    text: 'Delete',
    value: 'delete',
    align: 'center',
    sortable: false,
  },
];

export default {
  props: {
    currentGroup: {
      type: Object,
      required: true,
    },

    groups: {
      type: Array,
      required: true,
    },

    permissions: {
      type: Array,
      required: true,
    },

    scopes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return { headers: HEADERS };
  },

  computed: {
    permissionIds() {
      return {
        create: this.getPermissionIdByName('create'),
        read: this.getPermissionIdByName('read'),
        update: this.getPermissionIdByName('update'),
        delete: this.getPermissionIdByName('delete'),
      };
    },
  },

  methods: {
    getPermissionIdByName(name) {
      const permission = this.permissions.find((x) => x.name === name);
      return permission ? permission.id : 0;
    },

    getScopeById(id) {
      return this.scopes.find((x) => x.id === id);
    },

    isPermissionAllowedOnScope(scopeId, permissionId) {
      const scope = this.getScopeById(scopeId);
      return scope.allowed_permissions.includes(permissionId);
    },

    isPermissionOnScope(scopeId, permissionId) {
      return this.currentGroup.scope_permissions
        .some((sp) => sp.scope === scopeId && sp.permission === permissionId);
    },

    handlePermissionChange(scopeId, permissionId) {
      this.currentGroup.scope_permissions = this.isPermissionOnScope(scopeId, permissionId)
        ? this.currentGroup.scope_permissions
          .filter((sp) => !(sp.scope === scopeId && sp.permission === permissionId))
        : [
          ...this.currentGroup.scope_permissions,
          { scope: scopeId, permission: permissionId },
        ];
    },

    snakeToPascal,
  },
};
</script>
