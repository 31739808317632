export default {
  state: () => ({
    name: null,
    isAdmin: false,
    isMember: false,
    isGMA: false,
  }),

  getters: {},

  mutations: {
    setSelectedUserGroupName(state, payload) {
      state.name = payload;
    },
    setIsAdmin(state, payload) {
      state.isAdmin = payload;
    },
    setIsMember(state, payload) {
      state.isMember = payload;
    },
    setIsGMA(state, payload) {
      state.isGMA = payload;
    },
  },

  actions: {
    loadGroupState({ commit }, payload) {
      if (!payload) return;

      const {
        name, isAdmin, isMember, isGMA,
      } = payload;
      commit('setSelectedUserGroupName', name);
      commit('setIsAdmin', isAdmin);
      commit('setIsMember', isMember);
      commit('setIsGMA', isGMA);
    },
  },

  namespaced: true,
};
