<template>
  <v-row dense>
    <v-col
      cols="12"
      md="3"
      class="mb-4 pb-lg-0"
    >
      <GAutocomplete
        v-model="state.filterPublicationSelection"
        data-qa="filter-publication"
        :menu-props="{ bottom: true, offsetY: true }"
        :items="state.filterPublications"
        :search-input.sync="state.filterPublicationInput"
        prepend-inner-icon="mdi-file-document-outline"
        placeholder="Filter"
        label="Publication"
        class="v-select--nowrap"
        item-text="name"
        item-value="id"
        return-object
        hide-details
        clearable
        multiple
        outlined
        @change="state.filterPublicationInput = ''"
      >
        <template #selection="{ index }">
          <span
            v-if="index === 0"
            class="mr-2"
          >{{ pluralize('Filter', state.filterPublicationSelection) }}</span>
        </template>
      </GAutocomplete>
    </v-col>
    <v-col
      cols="12"
      md="3"
      class="mb-4 pb-lg-0"
    >
      <GAutocomplete
        v-model="state.filterTopicSelection"
        :menu-props="{ bottom: true, offsetY: true }"
        :items="state.filterTopics"
        :search-input.sync="state.filterTopicInput"
        prepend-inner-icon="mdi-information-outline"
        placeholder="Filter"
        label="Topic"
        class="v-select--nowrap"
        item-text="topic"
        item-value="id"
        return-object
        hide-details
        clearable
        multiple
        outlined
        @change="state.filterTopicInput = ''"
      >
        <template #selection="{ index }">
          <span
            v-if="index === 0"
            class="mr-2"
          >{{ pluralize('Filter', state.filterTopicSelection) }}</span>
        </template>
      </GAutocomplete>
    </v-col>
    <v-col
      cols="12"
      md="3"
      class="mb-4 pb-lg-0"
    >
      <v-menu
        ref="filterDatePickerMenu"
        v-model="state.filterDatePickerMenu"
        :close-on-content-click="false"
        :return-value.sync="state.filterDates"
        max-width="290px"
        min-width="290px"
        offset-y
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-text-field
            v-model="dateRangeText"
            placeholder="Filter"
            label="Date Range"
            prepend-inner-icon="mdi-calendar"
            readonly
            clearable
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <div class="pa-4 primary white--text">
          <p
            v-if="state.filterDates.length === 0"
            class="mb-0 text-subtitle-1"
          >
            Choose dates
          </p>

          <p
            v-else-if="state.filterDates.length === 1"
            class="mb-0 text-subtitle-1"
          >
            {{ formatDateString(state.filterDates[0]) }} -
            <span class="white--text">Choose date</span>
          </p>

          <p
            v-else
            class="mb-0 text-subtitle-1"
          >
            {{ formatDateString(state.filterDates[0]) }} - {{
              formatDateString(state.filterDates[1]) }}
          </p>
        </div>

        <v-divider />

        <v-date-picker
          v-model="state.filterDates"
          color="primary"
          :min="minDate"
          :max="maxDate"
          range
          no-title
          @change="sortDateRange"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="state.filterDatePickerMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!state.filterDates.length"
            @click="saveDateRange"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      md="3"
      class="mb-4 pb-lg-0"
    >
      <v-text-field
        v-model="state.filterSearch"
        label="Search"
        append-icon="mdi-magnify"
        hide-details
        outlined
        @click:append="search"
        @change="search"
      />
    </v-col>
    <v-col
      cols="12"
      class="pt-0 pb-4 d-none d-md-flex"
    >
      <div
        v-if="state.filterPublicationSelection.length
          || state.filterTopicSelection.length
          || (state.filterDates.length > 0 && state.isFilterDateSaved)"
        class="d-flex flex-wrap align-center"
      >
        <span
          v-if="state.filterPublicationSelection.length > 0"
          class="text-caption mr-2 mb-2"
        >
          Publication Filters:
        </span>
        <v-chip
          v-for="filter of state.filterPublicationSelection"
          :key="createFilterKey('content', filter)"
          class="mr-2 mb-2"
          close
          @click:close="removeChip('content', filter.id)"
        >
          {{ filter.name }}
        </v-chip>
        <span
          v-if="state.filterTopicSelection.length > 0"
          class="text-caption mr-2 mb-2"
        >Topic Filters:
        </span>
        <v-chip
          v-for="filter of state.filterTopicSelection"
          :key="createFilterKey('topic', filter)"
          class="mr-2 mb-2"
          close
          @click:close="removeChip('topic', filter.id)"
        >
          {{ filter.topic }}
        </v-chip>
        <span
          v-if="state.filterDates.length > 0 && state.isFilterDateSaved"
          class="text-caption mr-2 mb-2"
        >Date Filter:
        </span>
        <v-chip
          v-if="state.filterDates.length > 0 && state.isFilterDateSaved"
          class="mr-2 mb-2"
          close
          @click:close="state.filterDates = []"
        >
          {{ dateRangeText }}
        </v-chip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import GAutocomplete from '@/components/generic/GAutocomplete.vue';
import { dateFormatDateString, dateFormatShortMonthDateYear, getLocalDateTime } from '../../helpers/formatting';

export default {
  components: {
    GAutocomplete,
  },

  props: {
    state: {
      type: Object,
      default: () => ({}),
    },

    drawer: {
      type: Boolean,
      default: false,
    },

    minDate: {
      type: String,
      default: '2020-01-01',
    },
  },

  data() {
    return {
      maxDate: dateFormatDateString(new Date()),
    };
  },

  computed: {
    dateRangeText: {
      get() {
        return this.state.filterDates
          .map(this.formatDateString)
          .join(' - ');
      },
      set() {
        this.state.filterDates = [];
      },
    },
  },

  watch: {
    'state.filterPublicationSelection': {
      handler() {
        this.handleUpdate();
      },
      deep: true,
    },

    'state.filterTopicSelection': {
      handler() {
        this.handleUpdate();
      },
      deep: true,
    },

    'state.filterDates': {
      handler(newValue, oldValue) {
        // The datepicker's v-model is reset to null when the component
        // is removed from the page; we need to ignore that and preserve
        // whatever value it previously held
        if (newValue == null) this.state.filterDates = oldValue;
        this.verifyDateRange();
      },
      deep: true,
    },
  },

  beforeDestroy() {
    this.state.filterDatePickerMenu = false;
  },

  methods: {
    sortDateRange() {
      this.state.filterDates.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
    },

    saveDateRange() {
      this.$refs.filterDatePickerMenu.save(this.state.filterDates);
      this.state.isFilterDateSaved = true;
      this.handleUpdate();
    },

    verifyDateRange() {
      if (this.state.filterDates.length === 0 && this.state.isFilterDateSaved) {
        this.state.isFilterDateSaved = false;
        this.handleUpdate();
      }
    },

    removeChip(filter, id) {
      let filterSelectionName;
      switch (filter) {
        case 'content':
          filterSelectionName = 'filterPublicationSelection';
          break;
        case 'topic':
          filterSelectionName = 'filterTopicSelection';
          break;
        default:
          break;
      }
      this.state[filterSelectionName] = this.state[filterSelectionName].filter(
        (x) => x.id !== id,
      );
    },

    search() {
      if ((this.state.filterSearch || null) === this.state.filterLastSearch) return;

      this.state.filterLastSearch = this.state.filterSearch || null;
      this.handleUpdate();
    },

    createFilterKey(term, filter) {
      return `${term}-${filter.id}`;
    },

    handleUpdate() {
      this.$emit('update');
    },

    formatDateString(dateString) {
      const date = getLocalDateTime(dateString);
      return dateFormatShortMonthDateYear(date);
    },

    pluralize(term, collection) {
      return `${collection.length} ${term}${collection.length === 1 ? '' : 's'}`;
    },
  },
};
</script>
