import { postFormRequest, postRequest, createJSONFormDataWithAttachments } from '../helpers/request';

// Marketing Message API
export async function apiGetUserList() {
  const result = await postRequest('/marketing_module/marketing_message/get_user_list/');
  return result.user_list;
}

export async function apiGetApproverList() {
  const result = await postRequest('/marketing_module/marketing_message/get_approver_list/');
  return result.approver_list;
}

export async function apiGetCreatorList() {
  const result = await postRequest('/marketing_module/marketing_message/get_creator_list/');
  return result.creator_list;
}

export async function apiGetMessageList(query) {
  const result = await postRequest('/marketing_module/marketing_message/get_message_list/', query);
  return result.message_list;
}

export async function apiCreateMessage(payload) {
  const result = await postRequest('/marketing_module/marketing_message/create/', payload);
  return result.message;
}

export async function apiEditMessage(payload) {
  const result = await postRequest('/marketing_module/marketing_message/edit/', payload);
  return result.message;
}

export async function apiApproveMessage(payload) {
  const result = await postRequest('/marketing_module/marketing_message/approve/', payload);
  return result.message;
}

export async function apiCancelMessage(payload) {
  const result = await postRequest('/marketing_module/marketing_message/cancel/', payload);
  return result.message;
}

export async function apiDeleteMessage(payload) {
  const result = await postRequest('/marketing_module/marketing_message/delete/', payload);
  return result.message;
}

export async function apiGetMessageRecipientList(id) {
  const result = await postRequest(`/marketing_module/marketing_message/get_recipient_list/${id}`);
  return result.recipient_list;
}

export async function apiGetCampaignUrlTypeList() {
  const result = await postRequest('/marketing_module/campaign_url/get_campaign_url_type_list/');
  return result.type_list;
}

export async function apiGetCampaignUrlCreatorList() {
  const result = await postRequest('/marketing_module/campaign_url/get_campaign_url_creator_list/');
  return result.creator_list;
}

export async function apiGetCampaignUrlList(payload) {
  const result = await postRequest('/marketing_module/campaign_url/get_campaign_url_list/', payload);
  return result.campaign_url_list;
}

export async function apiCreateCampaignUrl(payload) {
  const result = await postRequest('/marketing_module/campaign_url/create/', payload);
  return result;
}

export async function apiEditCampaignUrl(payload) {
  const result = await postRequest('/marketing_module/campaign_url/edit/', payload);
  return result.message;
}

export async function apiDeleteCampaignUrl(payload) {
  const result = await postRequest('/marketing_module/campaign_url/delete/', payload);
  return result.message;
}

// Campaign URL

export async function apiValidateCampaignKey(payload) {
  const result = await postRequest('/api/campaign/validate_campaign_key/', payload);
  return result;
}

export async function apiGetManagingContentFilters() {
  const result = await postRequest('/marketing_module/managing_content/get_filters/');
  return result;
}

export async function apiGetFeaturePopups(payload) {
  const result = await postRequest('/marketing_module/managing_content/get_feature_popups/', payload);
  return result;
}

export async function apiDeleteFeaturePopup(payload) {
  const result = await postRequest('/marketing_module/managing_content/delete_feature_popup/', payload);
  return result.result;
}

export async function apiGetFeaturePopup(payload) {
  const result = await postRequest('/marketing_module/managing_content/get_feature_popup/', payload);
  return result;
}

export async function apiUpdateFeaturePopup(payload, image) {
  const result = await postFormRequest('/marketing_module/managing_content/update_feature_popups/', createJSONFormDataWithAttachments(payload, [image], 'feature_image'));
  return result;
}

export async function apiGetSourceChoices() {
  const result = await postRequest('/account/get_source_choices/');
  return result['sources'];
}

export async function apiGetContentTypesForCampaignURL(payload) {
  const result = await postRequest('/account/get_content_types_for_campaign_url/', payload);
  return result['content_types'];
}

export async function apiGetCampaignContentTypesForUser(payload) {
  const result = await postRequest('/library/api/get_campaign_content_for_user/');
  return result['results'];
}
