const isOnboardingTooltip = (tag) => ['g-onboarding-tooltip', 'GOnboardingTooltip'].includes(tag);

/**
 * Finds the parent tooltip component, if it exists
 */
const getParentTooltipVnode = (vnode) => {
  let parent = vnode.$parent;
  while (parent) {
    if (parent.$vnode?.componentOptions
       && isOnboardingTooltip(parent.$vnode.componentOptions.tag)) {
      return parent;
    }
    parent = parent.$parent;
  }
  return null;
};

/**
 * Find first element in DOM which is a Vue component
 */
const getFirstParentVnode = (el) => {
  // vnode is direct dom element
  let elm = el;
  // eslint-disable-next-line no-underscore-dangle
  while (!elm?.__vue__) {
    if (!elm) {
      return null; // huh?
    }
    elm = elm.parentElement;
  }
  // eslint-disable-next-line no-underscore-dangle
  return elm.__vue__;
};

/**
 * Updates parent tooltip with correct position
 */
const updateParentTooltip = (el, value, vnode, init = false) => {
  let parent;
  if (!vnode.componentInstance) { // not a Vue component, find first parent which is
    const pvnode = getFirstParentVnode(el);
    if (pvnode) {
      // eslint-disable-next-line no-underscore-dangle
      if (isOnboardingTooltip(pvnode.$options._componentTag)) {
        // first vue component is tooltip, don't keep looking
        parent = pvnode;
      } else {
        parent = getParentTooltipVnode(pvnode); // get first parent if not
      }
    } else {
      return;
    }
  } else {
    parent = getParentTooltipVnode(vnode.componentInstance);
  }
  if (parent) {
    if (init || !(parent.anchorOrder || []).includes(el)) {
      parent.anchors = (parent.anchors || 0) + 1;
      parent.anchorOrder = parent.anchorOrder || [];
      parent.anchorOrder.push(el); // Probably stable enough... i hope
    }
    if (value || value === undefined) { // Should be anchored
      const bb = el.getBoundingClientRect();
      const {
        top, left, width, height,
      } = bb;
      parent.arrowAnchorW = width;
      parent.arrowAnchorH = height;
      parent.arrowAnchorX = left;
      parent.arrowAnchorY = top;
    } else if (parent.anchors === 1 || parent.anchorOrder[0] === el) {
      // Should not be anchored, instead anchor to the center of the tooltip
      parent.arrowAnchorW = parent.anchorW;
      parent.arrowAnchorH = parent.anchorH;
      parent.arrowAnchorX = parent.anchorX;
      parent.arrowAnchorY = parent.anchorY;
    }
  }
};

/**
 * Create listener for window (that can be unbound) that updates the parent tooltip
 */
const makeListener = (el, value, vnode) => {
  const listener = () => {
    updateParentTooltip(el, value, vnode);
  };
  return listener;
};

/**
 * `GOnboardingTooltip` arrow anchor directive - using this directive on an element will
 *  cause the tooltip to anchor its arrow to the element instead of the center of the tooltip
 *
 * There should only be one `true` arrow anchor at a time for a given tooltip.
 *
 * Passing in a value of `true` will cause the tooltip arrow to anchor to the element,
 * and passing in a value of `false` will cause the tooltip arrow to anchor to the
 * center of the tooltip anchor.
 */
const ArrowAnchor = {
  bind(el, binding, vnode) {
    // initialize
    updateParentTooltip(el, binding.value, vnode, true);
    window.addEventListener('resize', makeListener(el, binding.value, vnode));
    window.addEventListener('scroll', makeListener(el, binding.value, vnode));
  },
  update(el, binding, vnode, prevVnode) {
    // Update, and if things changed, recreate the listeners
    updateParentTooltip(el, binding.value, vnode);
    window.removeEventListener('resize', makeListener(el, binding.oldValue, prevVnode));
    window.removeEventListener('scroll', makeListener(el, binding.oldValue, prevVnode));
    window.addEventListener('resize', makeListener(el, binding.value, vnode));
    window.addEventListener('scroll', makeListener(el, binding.value, vnode));
  },
  unbind(el, binding, vnode, prevVnode) {
    // Remove any listeners
    window.removeEventListener('resize', makeListener(el, binding.value, vnode));
    window.removeEventListener('scroll', makeListener(el, binding.value, vnode));
    window.removeEventListener('resize', makeListener(el, binding.oldValue, prevVnode));
    window.removeEventListener('scroll', makeListener(el, binding.oldValue, prevVnode));
  },

};

export default ArrowAnchor;
