<template>
  <v-card>
    <v-card-title>
      {{ heading }}
    </v-card-title>

    <v-card-text>
      <div class="body-1">
        <p v-if="hasText">
          {{ text }}
        </p>

        <slot />

        <v-list v-if="hasWarnings">
          <template v-for="(warning, index) in warnings">
            <v-list-item :key="index">
              <v-list-item-content class="pa-4">
                {{ warning }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />

      <v-btn
        text
        @click="handleCancelClick"
      >
        {{ info ? 'Close ' : cancelText }}
      </v-btn>

      <v-btn
        v-if="!info"
        data-qa="confirm-card-confirm-button"
        :disabled="handlingConfirm"
        :loading="handlingConfirm"
        text
        color="primary"
        @click="handleConfirmClick"
      >
        {{ confirmText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: 'Confirm',
    },
    text: {
      type: String,
      default: '',
    },
    warnings: {
      type: Array,
      default: () => [],
    },

    // Text for the confirm button
    confirmText: {
      type: String,
      default: 'Confirm',
    },

    // Text for the cancel button
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    // if info is true:
    // 1. Hide confirm button
    // 2. Change button text from 'Cancel' to 'Close'
    info: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      handlingConfirm: false,
    };
  },

  computed: {
    hasText() {
      return (this.text?.trim()?.length ?? 0) > 0;
    },

    hasWarnings() {
      return (this.warnings ?? []).length;
    },
  },

  methods: {
    handleCancelClick() {
      this.$emit('cancel');
    },

    async handleConfirmClick() {
      this.handlingConfirm = true;
      await this.$listeners.confirm();
      this.handlingConfirm = false;
    },
  },
};
</script>
