const Register = () => import('@/pages/account/Register.vue');
const AccountActivation = () => import('@/pages/account/AccountActivation.vue');
const CampaignRegistration = () => import('@/pages/account/CampaignRegistration.vue');
const CampaignAccountActivation = () => import('@/pages/account/CampaignAccountActivation.vue');
const Landing = () => import('@/pages/account/Landing.vue');

const routes = [
  {
    path: '/register/',
    meta: {
      title: 'Register Account',
    },
    component: Landing,
  },
  {
    path: '/campaign/:activationKey/',
    meta: {
      title: 'Register Account',
    },
    component: CampaignRegistration,
  },
  {
    path: '/api/accept_aak/:inviteKey/I/',
    meta: {
      title: 'Register Account',
    },
    component: Register,
  },
  {
    path: '/activate/:uidb64/:activationToken/',
    meta: {
      title: 'Activate Account',
    },
    components: {
      default: AccountActivation,
      campaign: AccountActivation,
    },
  },
  {
    path: '/account/css_module/view_artificial_activation_user/:userId',
    meta: {
      title: 'Activate Account',
    },
    components: {
      default: AccountActivation,
      campaign: CampaignAccountActivation,
    },
  },
  {
    path: '/account/css_module/view_artificial_activation_invitation/:invitationId',
    meta: {
      title: 'Activate Account',
    },
    component: AccountActivation,
  },
];

export default routes;
