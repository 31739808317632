/**
 * Global Component Imports
 *
 * The component imports in this file are required to
 * support our existing Django template (HTML) files.
 * The end goal is to refactor those templates into
 * single file components and import these other
 * components as needed.
 */

import Vue from 'vue';

// Accounts/Profile
import BarchartOverview from '@/components/generic/BarchartOverview.vue';
import BarchartSettlements from '@/components/generic/BarchartSettlements.vue';
import FuturesDisclaimer from '@/components/generic/FuturesDisclaimer.vue';
import ProviderApplicationInfo from '@/components/account/profile/ProviderApplicationInfo.vue';
import ProviderApplicationTable from '@/components/account/profile/ProviderApplicationTable.vue';
import SubscriptionInfo from '@/components/account/profile/SubscriptionInfo.vue';

// App
import App from '@/components/app/App.vue';

// Content
import ContentInfo from '@/components/content/ContentInfo.vue';
import ContentTable from '@/components/content/ContentTable.vue';

// Dashboard
import LatestNews from '@/components/dashboard/LatestNews.vue';
import FeaturedLibraryCard from '@/components/dashboard/FeaturedLibraryCard.vue';
import FeedbackForm from '@/components/feedback/FeedbackForm.vue';
import DashboardWeather from '@/components/dashboard/DashboardWeather.vue';
import NewsWidgetDtn from '@/components/dashboard/NewsWidgetDtn.vue';
import RecommendNews from '@/components/dashboard/RecommendNews.vue';
import CssActivityLog from '@/components/dashboard/CssActivityLog.vue';
import MobileAppLinkCard from '@/components/dashboard/MobileAppLinkCard.vue';
import LatestVideos from '@/components/dashboard/LatestVideos.vue';
import SalesRecommendationsWidget from '@/components/dashboard/SalesRecommendationsWidget.vue';
import NewSalesRecommendationsWidget from '@/components/dashboard/NewSalesRecommendationsWidget.vue';
import NewFeatureExpansionPanel from '@/components/newFeature/NewFeatureExpansionPanel.vue';

// Generic
import ConfirmCard from '@/components/generic/ConfirmCard.vue';
import TooltipLeft from '@/components/generic/TooltipLeft.vue';
import TooltipBottom from '@/components/generic/TooltipBottom.vue';
import SubscriptionTabs from '@/components/generic/SubscriptionTabs.vue';
import UserTabs from '@/components/generic/UserTabs.vue';
import GParentContainer from '@/components/generic/GParentContainer.vue';
import GTabsItems from '@/components/generic/GTabsItems.vue';
import GenericCTA from '@/components/generic/GenericCTA.vue';

// Form
import UserProfileForm from '@/components/form/UserProfileForm.vue';
import PreferencesForm from '@/components/form/PreferencesForm.vue';
import NotificationsForm from '@/components/form/NotificationsForm.vue';
import Security from '@/components/account/Security.vue';
import FarmLocationsForm from '@/components/form/FarmLocationsForm.vue';

// Resource Hub
import DocumentEditorCard from '@/components/document/EditorCard.vue';
import LibraryFilters from '@/components/library/LibraryFilters.vue';
import LibraryFilterTabs from '@/components/library/LibraryFilterTabs.vue';
import LibraryTitle from '@/components/library/LibraryTitle.vue';
import SalesRecommendationsResourceHubCTA from '@/components/library/SalesRecommendationsResourceHubCTA.vue';
import LibraryItem from '@/components/library/LibraryItem.vue';
import UploadDocument from '@/components/library/UploadDocument.vue';
import SnippetConfirmDialog from '@/components/document/SnippetConfirmDialog.vue';

// Permission
import GroupContainer from '@/components/permission/GroupContainer.vue';
import NewFeature from '@/components/newFeature/NewFeature.vue';

// Accounts/profile
Vue.component('BarchartOverview', BarchartOverview);
Vue.component('BarchartSettlements', BarchartSettlements);
Vue.component('FuturesDisclaimer', FuturesDisclaimer);
Vue.component('ProviderApplicationInfo', ProviderApplicationInfo);
Vue.component('ProviderApplicationTable', ProviderApplicationTable);
Vue.component('SubscriptionInfo', SubscriptionInfo);

// App
Vue.component('App', App);

// Content
Vue.component('ContentInfo', ContentInfo);
Vue.component('ContentTable', ContentTable);

// Dashboard
Vue.component('LatestNews', LatestNews);
Vue.component('FeaturedLibraryCard', FeaturedLibraryCard);
Vue.component('FeedbackForm', FeedbackForm);
Vue.component('DashboardWeather', DashboardWeather);
Vue.component('NewsWidgetDtn', NewsWidgetDtn);
Vue.component('RecommendNews', RecommendNews);
Vue.component('CssActivityLog', CssActivityLog);
Vue.component('MobileAppLinkCard', MobileAppLinkCard);
Vue.component('LatestVideos', LatestVideos);
Vue.component('SalesRecommendationWidget', SalesRecommendationsWidget);
Vue.component('NewSalesRecommendationsWidget', NewSalesRecommendationsWidget);
Vue.component('NewFeatureExpansionPanel', NewFeatureExpansionPanel);
Vue.component('NewFeature', NewFeature);

// Generic
Vue.component('ConfirmCard', ConfirmCard);
Vue.component('TooltipLeft', TooltipLeft);
Vue.component('TooltipBottom', TooltipBottom);
Vue.component('SubscriptionTabs', SubscriptionTabs);
Vue.component('UserTabs', UserTabs);
Vue.component('GParentContainer', GParentContainer);
Vue.component('GTabsItems', GTabsItems);
Vue.component('GenericCTA', GenericCTA);

// Form
Vue.component('UserProfileForm', UserProfileForm);
Vue.component('FarmLocationsForm', FarmLocationsForm);
Vue.component('PreferencesForm', PreferencesForm);
Vue.component('NotificationsForm', NotificationsForm);
Vue.component('Security', Security);

// Resource Hub
Vue.component('DocumentEditorCard', DocumentEditorCard);
Vue.component('LibraryFilters', LibraryFilters);
Vue.component('LibraryFilterTabs', LibraryFilterTabs);
Vue.component('LibraryTitle', LibraryTitle);
Vue.component('SalesRecommendationsResourceHubCTA', SalesRecommendationsResourceHubCTA);
Vue.component('LibraryItem', LibraryItem);
Vue.component('UploadDocument', UploadDocument);
Vue.component('SnippetConfirmDialog', SnippetConfirmDialog);

// Permission
Vue.component('GroupContainer', GroupContainer);

export default {};
