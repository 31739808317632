<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-if="showAdd"
        color="primary"
        icon
        class="mr-4"
        @click="handleAddClick"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      {{ title }}
    </v-card-title>

    <div
      v-if="items.length === 0 && !loading"
      class="pa-4 pt-0"
    >
      <p class="mb-0">
        No content
      </p>
    </div>

    <v-data-table
      v-else
      :items="items"
      :headers="headers"
      :items-per-page="-1"
      :loading="loading"
      :hide-default-footer="true"
    >
      <template #[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template #[`item.actions`]="{ item }">
        <tooltip-left text="Edit Content">
          <v-btn
            v-if="item.state === 'owned' && canUpdateContent"
            icon
            @click="handleEditClick(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </tooltip-left>

        <tooltip-left text="Delete Content">
          <v-btn
            v-if="item.state === 'owned' && canDeleteContent"
            icon
            @click="handleDeleteClick(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </tooltip-left>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showAdd: {
      type: Boolean,
      default: false,
    },

    canUpdateContent: {
      type: Boolean,
      default: false,
    },

    canDeleteContent: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleEditClick(item) {
      this.$emit('edit', item);
    },

    handleDeleteClick(item) {
      this.$emit('delete', item);
    },

    handleAddClick() {
      this.$emit('add');
    },
  },
};
</script>
