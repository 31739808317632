export default {
  state: () => ({
    panels: {},
  }),

  mutations: {
    setPanels(state, { key, value }) {
      state.panels[key] = value;
    },
  },

  getters: {
    getPanelsByKey: (state) => (key) => state.panels[key] || 0,
  },

  actions: {
    setPanels({ commit }, { key, value }) {
      commit('setPanels', { key, value });
    },
  },

  namespaced: true,
};
