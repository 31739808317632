/* eslint-disable import/prefer-default-export */
import { postRequest } from '../helpers/request';

export function fetchCashFlows() {
  return postRequest('/budgeting/api/get_cash_flows/');
}

export function createCashFlow(options) {
  return postRequest('/budgeting/api/create_cash_flow/', options);
}

export function copyCashFlow(options) {
  return postRequest('/budgeting/api/copy_cash_flow/', options);
}

export function deleteCashFlow(options) {
  return postRequest('/budgeting/api/delete_cash_flow/', options);
}

export function updateCashFlow(options) {
  return postRequest('/budgeting/api/update_cash_flow/', options);
}

export function fetchCashFlowType() {
  return postRequest('/budgeting/api/get_cash_flow_category_type/');
}

export function fetchCashFlowCategories(options) {
  return postRequest('/budgeting/api/get_cash_flow_category/', options);
}

export function createCashFlowCategory(options) {
  return postRequest('/budgeting/api/create_cash_flow_category/', options);
}

export function updateCashFlowCategory(options) {
  return postRequest('/budgeting/api/update_cash_flow_category/', options);
}

export function deleteCashFlowCategory(options) {
  return postRequest('/budgeting/api/delete_cash_flow_category/', options);
}

export function fetchCashFlowExpenses(options) {
  return postRequest('/budgeting/api/get_cash_flow_expense/', options);
}

export function createCashFlowExpense(options) {
  return postRequest('/budgeting/api/create_cash_flow_category_expense/', options);
}

export function createBulkCashFlowExpenses(options) {
  return postRequest('/budgeting/api/create_bulk_cash_flow_category_expense/', options);
}

export function deleteCashFlowExpense(options) {
  return postRequest('/budgeting/api/delete_cash_flow_category_expense/', options);
}

export function updateExpensesFromContractChanges(options) {
  return postRequest('/budgeting/api/update_expenses_from_contract_changes/', options);
}

export function remindMeLater(options) {
  return postRequest('/budgeting/api/set_changes_shown/', options);
}

export function apiGetAllCashFlowsClosingBalance(options) {
  return postRequest('/budgeting/api/get_all_cash_flows_closing_balance/', options);
}

export function getOrCreateCashFlowOnboardingProgress() {
  return postRequest('/budgeting/api/get_or_create_cash_flow_onboarding_progress/');
}

export function updateOrCreateCashFlowOnboardingProgress(options) {
  return postRequest('/budgeting/api/update_or_create_cash_flow_onboarding_progress/', options);
}
