<template>
  <div
    :style="styles"
    class="text-center py-10"
  >
    <v-btn
      icon
      @click="$emit('click')"
    >
      <v-icon :class="{'rotate-180': value}">
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SlideDrawerPeek',

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    width: {
      type: Number,
      default: 64,
    },
  },

  computed: {
    styles() {
      return {
        width: `${this.width}px`,
      };
    },
  },
};
</script>
