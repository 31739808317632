import { postRequest } from '../../../helpers/request';

function requestProductionReport(areaUnitId, productionUnit, cropYear) {
  return postRequest(
    '/farm_profile/api/reports/fetch_reporting_production/',
    {
      area_unit: areaUnitId,
      production_unit: productionUnit,
      crop_year: cropYear,
    },
  );
}

function requestReportStats(unit, cropYear, farmLocations, carryover, includeValues) {
  return postRequest(
    '/farm_profile/api/reports/get_reports_statistics/',
    {
      unit,
      crop_year: cropYear,
      farm_locations: farmLocations,
      include_carryover: carryover,
      include_values: includeValues,
    },
  );
}

export default {
  state: () => ({
    context: 'Reporting',
    reportStats: null,
    productionReportData: null,
    includeCarryover: false,
    quantityValueToggle: 'quantity',
    priceTypeToggle: 'smart',
    mobilePriceTypeSlideUpVisible: false,
    showNotEnoughPricesDialog: false,
    loading: false,
    priceCategory: [
      {
        key: 0,
        name: 'Based on smart prices',
        tooltipMsg: 'The value of your unsold portfolio based on the expected average crop prices for the next crop year, predicted using historical and statistical data and our analytical team’s analysis of current market trends.',
      }, {
        key: 1,
        name: 'Based on average local prices',
        tooltipMsg: 'The value of your unsold portfolio based on the current average crop prices near your farm business.',
      },
    ],
    types: [{ value: 'Quantity', text: 'Quantity', icon: 'mdi-scale' }, { value: 'Value', text: 'Value ($)', icon: 'mdi-currency-usd' }],
  }),

  mutations: {
    setReportStats(state, data) {
      state.reportStats = data;
    },

    setProductionReportData(state, data) {
      state.productionReportData = data;
    },

    setIncludeCarryover(state, data) {
      state.includeCarryover = data;
    },

    setQuantityValue(state, value) {
      const textToStateMapping = { Quantity: 'quantity', Value: 'value' };
      const data = textToStateMapping[value];
      state.quantityValueToggle = data;
    },

    setPriceTypeToggle(state, data) {
      state.priceTypeToggle = data;
    },

    setMobilePriceTypeSlideUpVisible(state) {
      state.mobilePriceTypeSlideUpVisible = !state.mobilePriceTypeSlideUpVisible;
    },

    setShowNotEnoughPricesDialog(state, data) {
      state.showNotEnoughPricesDialog = Boolean(data);
    },

    setLoading(state, data) {
      state.loading = Boolean(data);
    },
  },

  getters: {
    selectedProductionUnit(state, getters, rootState, rootGetters) {
      return rootGetters['farmProfile/currentUnitPreference']('Production');
    },

    selectedAreaUnit(state, getters, rootState, rootGetters) {
      return rootGetters['farmProfile/currentUnitPreference']('Area');
    },

    selectedYieldUnit(state, getters, rootState, rootGetters) {
      return rootGetters['farmProfile/currentUnitPreference']('Yield');
    },

    selectedPriceUnit(state, getters, rootState, rootGetters) {
      // Reports has no value unit selector, so we always do $,
      // so this finds the relevant $/selectedProductionUnit (e.g. $/bu)
      return rootGetters['farmProfile/availableUnits'].Price.find(
        (u) => u.denominator_unit.id === getters.selectedProductionUnit.id && u.numerator_unit.abbreviation === '$',
      );
    },

    countPricesWithoutSmartPrices(state) {
      const key = 'smart_price';
      const smartPriceCount = Object.values(state.reportStats)
        .map((x) => x[key]).reduce((count, x) => (x > 0 ? count + 1 : count + 0), 0);
      return Object.values(state.reportStats).length - smartPriceCount;
    },

    countPricesWithoutLocalPrices(state) {
      const key = 'average_price';
      const localPriceCount = Object.values(state.reportStats)
        .map((x) => x[key]).reduce((count, x) => (x > 0 ? count + 1 : count + 0), 0);
      return Object.values(state.reportStats).length - localPriceCount;
    },

    quantityValue(state) {
      const stateToTextMapping = { quantity: 'Quantity', value: 'Value' };
      return stateToTextMapping[state.quantityValueToggle];
    },

    displayedUnit(state, getters) {
      return getters.quantityValue === 'Quantity' ? getters.selectedProductionUnit.abbreviation : '$';
    },

    priceTypeToggleByLocation(state, getters, rootState, rootGetters) {
      const isAmerican = rootGetters['subscription/isAmerican'];
      return isAmerican ? 'local' : state.priceTypeToggle;
    },

  },

  actions: {
    async fetchReportStats({
      commit, state, getters, rootState,
    }, payload) {
      let data = null;
      const includeValues = payload?.includeValues ? payload.includeValues : false;

      // Fetch data
      try {
        data = await requestReportStats(
          payload?.selectedYieldUnitId || getters.selectedYieldUnit.id,
          payload?.selectedYearId || rootState.farmProfile.selectedYear.id,
          payload?.selectedFarmLocations || rootState.farmLocations.selectedFarmLocations,
          state.includeCarryover,
          includeValues,
        );

        // Commit data
        if (data) {
          commit('setReportStats', data);
        }
      } catch (e) {
        this._vm.$snackbar.error('Could not load report data');
      }
    },

    async fetchProductionReport(
      { commit, getters, rootState },
    ) {
      let data = null;
      // Fetch data
      try {
        data = await requestProductionReport(
          getters.selectedAreaUnit.id,
          getters.selectedProductionUnit.id,
          rootState.farmProfile.selectedYear.id,
        );
      } catch (e) {
        this._vm.$snackbar.error(e);
      }

      // Commit data
      if (data) {
        commit('setProductionReportData', data);
      }
    },
  },

  namespaced: true,
};
