<template>
  <v-card-text>
    <v-expand-transition mode="out-in">
      <v-row
        v-if="articles"
        class="px-0 pt-4"
      >
        <v-col
          v-for="article in articles"
          :key="article.id"
          cols="12"
          class="pa-0 d-flex flex-column flex-wrap align-stretch justify-stretch"
        >
          <v-card
            tag="a"
            :href="`${getArticleLink(article.id)}?newTab=true`"
            class="c-pointer overflow--hidden d-flex
            flex-wrap items-stretch justify-center w--100 my-2"
            ripple
            elevation="0"
            @click.stop.prevent="openArticle(article.id, $event)"
          >
            <v-card-text class="pa-0 d-flex flex-column align-stretch justify-center">
              <v-row
                dense
                no-gutters
                class="align-stretch mx-4"
              >
                <v-col
                  :cols="$isDesktop ? 3 : 4"
                  style="maxWidth: 7.188rem"
                  class="d-flex align-stretch my-2"
                >
                  <v-img
                    :src="articleImage(article)"
                    :alt="articleImage(article)"
                    :aspect-ratio="1"
                    max-width="7.188rem"
                    class="grey lighten-4"
                  >
                    <template #placeholder>
                      <div
                        class="align-center justify-center"
                      >
                        <v-img
                          contain
                          src="/static/dashboard/svg/grainfox-icon.svg"
                          alt="/static/dashboard/svg/grainfox-icon.svg"
                          :aspect-ratio="4/3"
                        />
                      </div>
                    </template>
                  </v-img>
                </v-col>
                <v-col
                  :cols="$isDesktop ? 9 : 8"
                  class="d-flex flex-column justify-center my-2 px-4"
                >
                  <span
                    v-if="$isDesktop"
                    class="caption primary-grey--text text--darken-1"
                  >
                    By <b>{{ getSource(article.provider) }}</b>
                  </span>
                  <span
                    class="primary-grey--text mb-2"
                    :class="{
                      'text-h6': $isDesktop,
                      'one-line': $isDesktop,
                      'font-weight-bold': !$isDesktop,
                      'two-lines': !$isDesktop
                    }"
                    :title="article.title"
                  >
                    {{ article.title }}
                  </span>
                  <span
                    class="d-flex flex-wrap align-center justify-start g-2 pb-2 lh--1--i"
                  >
                    <v-chip
                      small
                    >
                      <span>
                        {{ article.categories[0].name }}
                      </span>
                    </v-chip>
                    <v-chip
                      v-if="article.categories.length > 1"
                      small
                      :title="article.categories.slice(1).map((c) => decodeHtml(c.name)).join('\n')"
                    >
                      +{{ article.categories.length - 1 }}
                    </v-chip>
                  </span>
                  <v-spacer />
                  <span class="caption grey--text text--darken-1">
                    {{ dateFormatShortMonthDateYear(article.date) }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-expand-transition mode="in-out">
      <v-row
        v-if="noArticles"
      >
        <v-col
          v-for="(x, i) in 3"
          :key="i"
          cols="12"
        >
          <v-skeleton-loader
            type="avatar, paragraph"
            class="w--100 d-flex flex-row"
          />
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-card-text>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { postRequest } from '@/helpers/request';
import { dateFormatShortMonthDateYear, decodeHtml } from '@/helpers/formatting';

export default {
  name: 'NewsWidgetData',

  props: {
    limit: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      articles: null,
    };
  },

  computed: {
    ...mapGetters('news', ['newsSources']),

    noArticles() {
      if (!this.articles?.length) {
        return true;
      }
      return false;
    },

    mappedArticles() {
      if (this.articles !== null) {
        return this.articles.reduce((acc, article) => {
          acc[article.id] = article;
          return acc;
        }, {});
      }
      return null;
    },
  },

  async mounted() {
    try {
      await this.fetchArticles();
      await this.loadNewsSourcesAndTopics();
    } catch (e) {
      this.$emit('error', e);
      this.$snackbar.error(e);
    }
  },

  methods: {
    ...mapActions('news', ['loadNewsSourcesAndTopics']),
    async fetchArticles() {
      const { page: articles } = await postRequest('/news/articles/', {
        limit: this.limit,
        // removed category filters
        categoryIds: '',
      });
      this.articles = articles;
    },

    getSource(provider) {
      return this.newsSources.find((source) => source.value === provider)?.label ?? provider;
    },

    articleImage(article) {
      return article?.image_url ?? '';
    },

    openArticle(articleId, event) {
      if (event.ctrlKey) {
        window.open(`${this.getArticleLink(articleId)}?newTab=true`, '_blank');
      } else {
        window.location.href = this.getArticleLink(articleId);
      }
    },

    getArticleLink(articleId) {
      const providerString = this.mappedArticles[articleId].provider;
      return `/news/${providerString}/${articleId}`;
    },

    dateFormatShortMonthDateYear,
    decodeHtml,
  },
};
</script>
<style lang="scss" scoped>
.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
}

</style>
