/**
 * Groups an array of objects by the value in a given property
 * @param {Object[]} array The array of objects to be grouped
 * @param {string} property The name of the property to group by
 * @returns {Object} Where keys are the different values of
 * the given property and corresponding values are arrays
 * containing the objects that belong to the group
 */
export function groupBy(array, property) {
  return array.reduce((accumulator, current) => {
    const key = current[property];
    if (!Object.prototype.hasOwnProperty.call(accumulator, key)) {
      accumulator[key] = []; // eslint-disable-line no-param-reassign
    }
    accumulator[key].push(current);
    return accumulator;
  }, {});
}

/**
 * Adds object to array using set addition
 * @param {Object[]} array The array of objects to be added to
 * @param {Object} item The object to be added
 * @param {Function} findFunction Boolean function that checks for item in array
 * @returns {Object[]} The array with the new object added using set addition
 */
export function arraySetAddition(array, item, findFunction) {
  if (!item) return array;

  let newArray = array;
  const itemFound = array.find(findFunction);
  if (!itemFound) {
    newArray = array.concat(item);
  }
  return newArray;
}

export function encodeObject(obj) {
  const stringified = JSON.stringify(obj);
  return btoa(stringified);
}

export function decodeObject(str) {
  const decoded = atob(str);
  return JSON.parse(decoded);
}

export function isEmptyObject(obj) {
  const keysLength = Object.keys(obj).length;
  return keysLength === 0;
}
