import type { FieldOperationMeasurement, MappedMeasurement } from '../components/farm_profile/integrations/types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Given an FieldOperationMeasurement object,
 * return an object with 4 properties: area, areaUnit, production, and productionUnit
 * Each property is an object with 2 properties: value and unit
 *
 * For any null value, default to value: 0 and unit: undefined
 *
 * @param {Object} fieldOperation
 */
export function getProperties(fieldOperation: FieldOperationMeasurement): MappedMeasurement {
  // probably should also return seeded_area and seeded_unit?
  const { area } = fieldOperation;
  const production = fieldOperation.yield;

  // only harvesting operations have production
  if (production) {
    let productionUnit;
    if (production.unitId === 'bu') {
      productionUnit = 'bu A';
    } else {
      productionUnit = production.unitId;
    }
    return {
      harvested_area: area ? area.value : 0,
      area_unit: area ? area.unitId : undefined,
      production: production.value,
      production_unit: productionUnit,
    };
  }
  // must be seeding operation if not harvesting
  return {
    seeded_area: area ? area.value : 0,
    area_unit: area ? area.unitId : undefined,
  };
}

/**
 * Given a string unit
 * treat the 1's as exponents and return the unit in fraction form
 * Example input "unita1unitb-1", output "unita/unitb"
 * In the case of a single unit (e.g. "unita1"), return the unit in the form "unita",
 * or in the case of a single unit with an negative exponent (e.g. "unita-1"),
 * return the unit in the form "1/unita"
 */
export function convertUnitString(parseUnit: string): string {
  const unitArr = parseUnit.split('1').filter((unit) => unit !== '');
  let returnNumerator = '1';
  let returnDenominator = '';
  unitArr.forEach((unit) => {
    if (unit.includes('-')) {
      returnDenominator += unit.replace('-', '');
    } else if (returnNumerator === '1') {
      returnNumerator = unit;
    } else {
      returnNumerator += unit;
    }
  });
  if (returnDenominator === '') {
    return returnNumerator;
  }
  return `${returnNumerator}/${returnDenominator}`;
}

// Given 2 arrays of objects, combine them into a single array of objects
export function combineObjectArrays<
  T extends object,
  U extends object,
>(arr1: Array<T>, arr2: Array<U>): Array<T & U> {
  const combined: Array<any> = [];
  arr1.forEach((obj1: any, i: any) => {
    combined.push({ ...obj1, ...arr2[i] });
  });
  return combined;
}
