<template>
  <g-dialog
    title="Update Province"
    :value="showWarningDialog"
    disable-fullscreen
    @cancel="cancel"
  >
    <div class="row mt-2 ml-4 mr-4">
      GrainFox provides market insights and recommendations specific to your region.
      Updating your province means the publications and market insights you receive will change.
    </div>
    <div class="row justify-center">
      <v-btn
        color="primary"
        class="mt-4 mb-6"
        @click="handleAccept"
      >
        I UNDERSTAND
      </v-btn>
    </div>
  </g-dialog>
</template>

<script>
import GDialog from '@/components/generic/GDialog.vue';

export default {
  name: 'ProvinceChangeWarningDialog',
  components: { GDialog },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showWarningDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    cancel() {
      this.showWarningDialog = false;
      this.$emit('cancel');
    },
    handleAccept() {
      this.showWarningDialog = false;
      this.$emit('accept');
    },
  },
};
</script>
