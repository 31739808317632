<template>
  <div>
    <v-btn
      v-if="featureId != null && ($isMobile || $isTablet) && showFeatureTab"
      class="mx-0 new-feature-button text-h6 font-weight-regular text-left rounded-r-0"
      :class="{'new-feature-button-mobile': $isMobile, 'new-feature-button-tablet': $isTablet}"
      style="z-index: 100;"
      :style="topMargin"
      @click="handleClick"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon> {{ featureSlideoutText }}
    </v-btn>

    <new-feature-text-dialog
      v-model="isNewFeatureTextDialogOpen"
      :feature-title="featureTitle"
      :feature-name="featureName"
      :feature-subtitle="featureSubtitle"
      :feature-description="featureDescription"
      :feature-image="featureImage"
      :feature-link="featureLink"
      :show-video-button="showVideoButton"
      :go-to-feature-button-text="goToFeatureButtonText"
      @cancel="isNewFeatureTextDialogOpen = false"
    />
    <new-feature-video-dialog
      v-if="showVideoButton"
      v-model="isNewFeatureVideoDialogOpen"
      :feature-name="featureName"
      :feature-video="featureVideo"
      :feature-link="featureLink"
      @cancel="isNewFeatureVideoDialogOpen = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NewFeatureTextDialog from './NewFeatureTextDialog.vue';
import NewFeatureVideoDialog from './NewFeatureVideoDialog.vue';
import { BOOK_A_MEETING_FEATURE_FLAG } from '../../helpers/subscription';

export default {
  name: 'NewFeature',

  components: {
    NewFeatureTextDialog,
    NewFeatureVideoDialog,
  },

  data() {
    return {
      hasNewFeatureBeenSeen: false,
      isNewFeatureTextDialogOpen: false,
      isNewFeatureVideoDialogOpen: false,
      showFeatureTab: false,
    };
  },

  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapState('dashboard', ['newFeature']),
    ...mapState('launchDarkly', ['flags']),
    featureId() {
      return this.newFeature.id || null;
    },

    featureTitle() {
      return this.newFeature.featureTitle || 'New Feature Alert!';
    },

    featureName() {
      return this.newFeature.featureName || '';
    },

    featureSlideoutText() {
      return this.newFeature.featureTitle || `${this.featureName} out now`;
    },

    featureSubtitle() {
      return this.newFeature.featureSubtitle || '';
    },

    featureDescription() {
      return this.newFeature.featureDescription || '';
    },

    featureImage() {
      return this.newFeature.featureImage || '';
    },

    featureLink() {
      return this.newFeature.featureLink || '';
    },

    featureVideo() {
      return this.newFeature.featureVideo || '';
    },

    featureFlag() {
      return this.newFeature.flag || '';
    },

    showVideoButton() {
      return !!this.newFeature.featureVideo;
    },

    goToFeatureButtonText() {
      if (this.newFeature.featureButtonText) {
        return this.newFeature.featureButtonText;
      }
      return (this.newFeature.featureFlag === BOOK_A_MEETING_FEATURE_FLAG) ? 'Choose a time!' : 'Explore Tool';
    },

    topMargin() {
      if (this.$isMobile) {
        const offset = this.$headerOffset + document.getElementById('tabs-row').clientHeight;
        return `top: ${offset + 37}px;`;
      }
      return 'top: 133px;';
    },
  },

  async mounted() {
    await this.getLatestNewFeature();
    if (this.newFeature.flag) {
      this.showFeatureTab = true;
    }
  },

  methods: {

    ...mapActions('dashboard', ['getLatestNewFeature', 'setNewFeatureViewedStatus']),

    handleClick() {
      this.showDialog();
      if (!this.newFeature.persistent) {
        this.showFeatureTab = false;
      }
    },

    showDialog() {
      // Prevent the dialog from being opened again
      this.hasNewFeatureBeenSeen = true;

      this.setNewFeatureViewedStatus(this.featureId);

      // Open the dialog only if we're not already
      // on the page for the new feature
      if (this.$route.path !== this.featureLink) {
        this.isNewFeatureTextDialogOpen = true;
      }
    },

    showVideoDialog() {
      if (this.$route.path !== this.featureLink) {
        this.isNewFeatureTextDialogOpen = false;
        this.isNewFeatureVideoDialogOpen = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "@/colors/colors";
.new-feature-button {
  top: 133px;
  right: 0;
  position: fixed;
  background: colors.$greyDarken2 !important;
  color: colors.$white !important;
  text-transform: unset !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  &-mobile {
    height: 45px !important;
    font-size: 14px !important;
  }

  &-tablet {
    height: 64px !important;
    font-size: 20px !important;
  }
}
</style>
