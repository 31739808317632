const OAuthLanding = () => import('@/pages/account/OAuthLogin.vue');

const routes = [
  {
    path: '/oauth/login/',
    name: 'oauth login',
    meta: {
      title: 'Login',
    },
    component: OAuthLanding,
  },
];

export default routes;
