import Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export async function getCSRFToken() {
  let csrfToken = Cookies.get('csrftoken');
  if (csrfToken) return csrfToken;

  try {
    const response = await fetch('/api/csrf/', {
      credentials: 'same-origin',
    });
    if (response.ok) {
      csrfToken = Cookies.get('csrftoken');
      return csrfToken;
    }
    datadogRum.addError(new Error(`Could not get CSRF token. Status: ${response.status}`));
    return csrfToken;
  } catch (e) {
    datadogRum.addError(new Error('Could not get CSRF token. Fetch request failed.'));
    return csrfToken;
  }
}

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
}

/**
 * Extract data from a response, given an expected type.
 *
 * Expected type is the function to call on the request. `bytes` is an alias of `arrayBuffer`.
 *
 * @param {Response} response The response to get data from
 * @param {'json' | 'blob' | 'text' | 'body' | 'bytes' | 'arrayBuffer'} responseType The expected type of the response
 * @returns {Promise<any | Blob | string | ArrayBuffer | ReadableStream<Uint8Array>>}
 *  The extracted data
 * @throws If the response is not ok
 */
async function extractResponseData(response, responseType = 'json') {
  try {
    switch (responseType) {
      case 'blob': {
        if (response.ok) {
          return await response.blob();
        }
        throw Error('Error with server response');
      }
      case 'text': {
        if (response.ok) {
          return await response.text();
        }
        throw Error('Error with server response');
      }
      case 'body': {
        if (response.ok && response.body) {
          return response.body;
        }
        throw Error('Error with server response');
      }
      case 'bytes':
      case 'arrayBuffer': {
        if (response.ok) {
          return await response.arrayBuffer();
        }
        throw Error('Error with server response');
      }
      case 'json':
      default: {
        let data;
        try {
          data = await response.json();
        } catch (e) {
          datadogLogs.logger.error('Error parsing JSON response', {
            body: await response.text(),
          }, e);
          throw e;
        }
        if (response.ok) {
          return data;
        }
        throw data.message ?? data.error;
      }
    }
  } catch (e) {
    switch (response.status) {
      case 400:
        throw e;
      case 401:
        setTimeout(() => {
          // Since the user is logged out, this will bring them to the login screen and use the
          // ?next= argument to let them return to their current page immediately after logging in
          window.location.reload();
        }, 5000);
        throw Error('You are not signed in. Redirecting to the sign in page in 5 seconds...');
      case 403:
        throw Error('Permission denied');
      default: {
        // Handled errors
        if (typeof e === 'string') throw Error(e);
        // Unexpected response errors
        throw Error('Error with server response');
      }
    }
  }
}

/**
 * Send a post request to the server
 * @param {string} url The url to send the request to
 * @param {any} [body] The JSON serializable body to send
 * @param {{
 *  csrfExempt?: boolean,
 *  abortController?: AbortController,
 *  responseType?: 'json' | 'blob' | 'text' | 'body' | 'bytes' | 'arrayBuffer'
 * }} options The options to apply to the request
 * @returns Extracted response data. See `extractResponseData` for more details.
 */
export async function postRequest(url, body, options = {}) {
  const {
    csrfExempt, abortController, responseType,
  } = options;

  const headers = {};
  if (!csrfExempt) {
    const csrfToken = await getCSRFToken();
    headers['X-CSRFToken'] = csrfToken;
  }

  const response = await fetch(url, {
    headers,
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    signal: abortController?.signal,
  }).catch((e) => {
    // Ignore errors about aborted signals
    if (e.name === 'AbortError') {
      throw Error('');
    }
    throw Error('Network connectivity issue');
  });

  return extractResponseData(response, responseType ?? 'json');
}

/**
 * Send a request to the NLG Helper service
 * @param {string} path The path to send the request to
 * @param {{
 *  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
 *  body?: any,
 *  csrfExempt?: boolean,
 *  abortController?: AbortController,
 *  responseType?: 'json' | 'blob' | 'text' | 'body' | 'bytes' | 'arrayBuffer'
 * }} options The options to apply to the request
 * @returns Extracted data, see `extractResponseData` for more details
 */
export async function nlgRequest(path, {
  method, body, csrfExempt, abortController, responseType,
} = {}) {
  let url;
  if (process.env.NODE_ENV === 'development') {
    url = `http://localhost:4000${path.startsWith('/') ? '' : '/'}${path}`;
  } else {
    url = `https://nlg.grainfox.marketing${path.startsWith('/') ? '' : '/'}${path}`;
  }

  const headers = { 'Content-Type': 'application/json' };
  if (!csrfExempt) {
    const csrfToken = await getCSRFToken();
    headers['X-CSRFToken'] = csrfToken;
  }

  const response = await fetch(url, {
    headers,
    method: method ?? 'POST',
    credentials: 'include',
    body: body ? JSON.stringify(body) : undefined,
    signal: abortController?.signal,
  }).catch((e) => {
    // Ignore errors about aborted signals
    if (e.name === 'AbortError') {
      throw Error('');
    }
    throw Error('Network connectivity issue');
  });

  return extractResponseData(response, responseType ?? 'json');
}

export async function simpleGETRequest(url) {
  const response = await fetch(url);
  return extractResponseData(response);
}

/**
 * Send a post request to the server using Form Data
 * @param {string} url The url to send the request to
 * @param {FormData} [body] The FormData to send
 * @param {{
*  abortController?: AbortController,
*  responseType?: 'json' | 'blob' | 'text' | 'body' | 'bytes' | 'arrayBuffer'
* }} options The options to apply to the request
* @returns Extracted response data. See `extractResponseData` for more details.
*/
export async function postFormRequest(url, formData, options = {}) {
  const {
    abortController, responseType,
  } = options;

  const headers = {};
  const csrfToken = await getCSRFToken();
  headers['X-CSRFToken'] = csrfToken;

  const response = await fetch(url, {
    headers,
    method: 'POST',
    credentials: 'same-origin',
    body: formData,
    signal: abortController?.signal,
  });

  return extractResponseData(response, responseType);
}

export function downloadURL(url, filename, target = '_blank') {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  a.target = target;
  a.click();
}

export async function downloadFile(data, filename, target = '_self') {
  // put the file contents in local storage so it can be accessed by flutter
  const bytes = await data.arrayBuffer();
  const uintArr = new Uint8Array(bytes);
  const byteArr = Array.from(uintArr); // use Array#from
  // now stringify
  const blobString = JSON.stringify(byteArr);
  localStorage.setItem('blob-file', blobString);
  localStorage.setItem('blob-file-name', filename);
  const url = URL.createObjectURL(data);
  return downloadURL(url, filename, target);
}

export function createJSONFormDataWithAttachments(payload, attachments, attachmentName = 'attachments') {
  const formData = new FormData();

  formData.set('data', JSON.stringify(payload));
  attachments.forEach((x) => {
    formData.append(attachmentName, x);
  });
  return formData;
}

export function createJSONFormDataWithMultipleAttachments(payload, attachmentsArray, attachmentNameArray = ['attachments']) {
  const formData = new FormData();

  formData.set('data', JSON.stringify(payload));
  attachmentsArray.forEach((attachments, index) => {
    attachments.forEach((x) => {
      formData.append(attachmentNameArray[index], x);
    });
  });
  return formData;
}

export async function MLAPIFetch(url, data) {
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${DJANGO_REQUEST.mlAPIKey}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export async function VDEAPIFetch(url, data, stringify = false) {
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${DJANGO_REQUEST.vdeAPIKey}`,
      Accept: 'application/json',
      // conditionally add content type header
      ...(stringify ? { 'Content-Type': 'application/json' } : {}),
    },
    // conditionally stringify body
    body: stringify ? JSON.stringify(data) : data,
  });
}

export async function VDEAPIGet(url) {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `bearer ${DJANGO_REQUEST.vdeAPIKey}`,
    },
  });
}

export async function MLAPIUpdateToken() {
  const getTokenResponse = await postRequest('/budgeting/ai_advisor/get_token/');
  DJANGO_REQUEST.mlAPIKey = getTokenResponse.auth_token;
}

export async function MLAPIRequest(url, data = {}) {
  let response = await MLAPIFetch(url, data);
  if (response.ok) {
    return response.json();
  } if (response.status === 401) {
    // In case of token expired, update token and try request again.
    await MLAPIUpdateToken();
    response = await MLAPIFetch(url, data);
    if (response.ok) return response.json();
    throw Error('Smart Advisor service cannot be reached');
  } else {
    // If not a 401 error, then use default error handling from extractResponseData
    return extractResponseData(response);
  }
}

export async function VDEAPIUpdateToken() {
  const getTokenResponse = await postRequest('/machine_learning/get_vde_token/');
  DJANGO_REQUEST.vdeAPIKey = getTokenResponse.auth_token;
}

export async function VDEAPIRequest(url, type = 'post', data = {}, retry = true, stringify = false) {
  let response;
  if (type === 'post') {
    response = await VDEAPIFetch(url, data, stringify);
  } else if (type === 'get') {
    response = await VDEAPIGet(url);
  }
  if (response.ok) {
    return response.json();
  }
  if (response.status === 401) {
    if (retry) {
      // In case of token expired, update token and try request again.
      await VDEAPIUpdateToken();
      return VDEAPIRequest(url, type, data, false);
    }
    throw Error('Visual Data Extraction service cannot be reached');
  } else {
    // If not a 401 error, then use default error handling from extractResponseData
    return extractResponseData(response);
  }
}

export async function RECOMMENDATIONAPIFetch(url, data) {
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${DJANGO_REQUEST.recommendationAPIKey}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export async function RECOMMENDATIONSAPIUpdateToken() {
  const getTokenResponse = await postRequest('/machine_learning/get_recommendations_token/');
  DJANGO_REQUEST.recommendationAPIKey = getTokenResponse.auth_token;
}

export async function RecommendationRequest(url, data = {}) {
  let response = await RECOMMENDATIONAPIFetch(url, data);
  if (response.ok) {
    return response.json();
  } if (response.status === 401) {
    // In case of token expired, update token and try request again.
    await RECOMMENDATIONSAPIUpdateToken();
    response = await RECOMMENDATIONAPIFetch(url, data);
    if (response.ok) return response.json();
    throw Error('Recommendation service cannot be reached');
  } else {
    // If not a 401 error, then use default error handling from extractResponseData
    return extractResponseData(response);
  }
}
