import { datadogLogs } from '@datadog/browser-logs';
/* eslint-disable no-param-reassign */

// TODO: UT-870 Refactor

export default {
  install: async (Vue, { disableNotifications }) => {
    /**
     * If notifications are disabled,
     * unregister all service workers.
     * This functionality is currently not used,
     * and is possibly too broad in scope if we
     * ever added more than the firebase worker.
     */
    if (disableNotifications) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach((r) => {
        r.unregister();
      });
      return;
    }

    firebase.initializeApp({
      apiKey: 'AIzaSyDupYee6ohtXDXkYLNQV_Z0476frOHzap4',
      projectId: 'grainfox-notifications',
      appId: '1:626911962804:web:236c74927ab3bd352c99e1',
      messagingSenderId: '626911962804',
    });

    // Stop if messaging is not supported
    if (!firebase.messaging.isSupported()) {
      return;
    }

    const isLoggedIn = DJANGO_REQUEST.user?.isLoggedIn ?? false;
    const messaging = firebase.messaging();
    let localFcm;
    try {
      localFcm = localStorage.getItem('fcm');
    } catch (e) {
      // Handle potential NS_ERROR_FAILURE error in FireFox
      // https://bugzilla.mozilla.org/show_bug.cgi?id=793775
      datadogLogs.logger.error('Error getting fcm from localStorage', {}, e);
    }
    const sessionid = DJANGO_REQUEST.sessionId;
    let seidMismatch;
    try {
      seidMismatch = localStorage.getItem('sessionid') !== sessionid;
    } catch (e) {
      // Handle potential NS_ERROR_FAILURE error in FireFox
      // https://bugzilla.mozilla.org/show_bug.cgi?id=793775
      datadogLogs.logger.error('Error getting sessionid from localStorage', {}, e);
    }
    let registration = null;

    navigator.serviceWorker.register('/static/dashboard/js/notification-worker.js')
      .then((reg) => reg.update())
      .then((reg) => {
        messaging.useServiceWorker(reg);
        registration = reg;
      })
      .then(messaging.requestPermission)
      .then(() => {
        if (isLoggedIn) {
          /**
           * If a user is logged in but fcm was not set yet,
           * or it is not equal to current FCM,
           * or the sessionid changed.
           */
          messaging.getToken()
            .then((fcm) => {
              if (!localFcm || localFcm !== fcm || seidMismatch) {
                // eslint-disable-next-line no-console
                console.log(`Detected new FCM: ${fcm}`);

                API.updateSessionDevice({ fcm });
                try {
                  localStorage.setItem('fcm', fcm);
                  localStorage.setItem('sessionid', sessionid);
                } catch (e) {
                  datadogLogs.logger.error('FCM get token error', {}, e);
                }
              }
            });
        } else if (!isLoggedIn && localFcm) {
          // If a user is logged out, and fcm is still tracked
          messaging.getToken()
            .then((fcm) => {
              messaging.deleteToken(fcm)
                .catch((e) => {
                  datadogLogs.logger.error('FCM token delete error', {}, e);
                });

              localStorage.removeItem('fcm');
            });
        }
      })
      .catch((e) => {
        datadogLogs.logger.error('Unable to get permission to notify', {}, e);
      });

    messaging.onMessage((payload) => {
      const parseTemplate = (template, args) => {
        let result = template;

        for (let i = 1; i <= args.length; i += 1) {
          result = result.replace(`{arg${i}}`, args[i - 1]);
        }

        return result;
      };

      payload.data = JSON.parse(payload.data.data);
      payload.data.data = payload.data;
      payload.data.title = parseTemplate(payload.data.template.title, payload.data.args);
      payload.data.body = parseTemplate(payload.data.template.body, payload.data.args);
      payload.data.icon = payload.data.template.icon;
      payload.data.timestamp = null;

      registration?.showNotification(payload.data.title, payload.data);
    });
  },
};
