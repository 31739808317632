<template>
  <div class="simple-counter primary white--text">
    {{ count }}
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.simple-counter {
  padding: 4px 6px;
  font-size: 12px;
  line-height: 1;
  height: 20px;
  min-width: 20px;
  border-radius: 20px;
  white-space: nowrap;
  text-align: center;
}
</style>
