<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <FingerprintField />
    <v-card
      width="100%"
      max-width="650"
      class="mx-auto"
    >
      <input
        type="hidden"
        name="notifications_string"
        :value="notificationsData"
      >
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="notificationItems"
          :items-per-page="-1"
          :hide-default-footer="true"
          :loading="loading"
          item-key="title"
          group-by="category"
          dense
        >
          <template
            v-for="h in headers"
            #[`header.${h.value}`]
          >
            <v-tooltip
              :key="h.text"
              bottom
            >
              <template #activator="{ on }">
                <span>{{ h.text }}</span>
                <v-icon
                  v-if="h.toolTip"
                  class="ml-2 pb-1"
                  small
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>
                {{ h.toolTip }}
              </span>
            </v-tooltip>
          </template>

          <template #[`group.header`]="{ group, toggle, isOpen }">
            <td
              v-if="group !== ''"
              :colspan="headers.length"
            >
              <v-btn
                :ref="group"
                small
                icon
                :data-open="isOpen"
                @click="toggle"
              >
                <v-icon
                  :class="{'rotate-180' : isOpen}"
                >
                  $expand
                </v-icon>
              </v-btn>
              {{ group }}
            </td>
          </template>

          <template #[`item.source`]="{ item }">
            <span>{{ item.title }}</span>
          </template>
          <template #[`item.web`]="{ item }">
            <v-simple-checkbox
              v-if="item.web.show"
              v-model="item.web.enabled"
              color="primary"
              @click="handleClick(item.web)"
            />
          </template>
          <template #[`item.push`]="{ item }">
            <v-simple-checkbox
              v-if="item.push.show"
              v-model="item.push.enabled"
              color="primary"
              @click="handleClick(item.push)"
            />
          </template>
          <template #[`item.app`]="{ item }">
            <v-simple-checkbox
              v-if="item.app.show"
              v-model="item.app.enabled"
              color="primary"
              @click="handleClick(item.app)"
            />
          </template>
          <template #[`item.email`]="{ item }">
            <v-simple-checkbox
              v-if="item.email.show"
              v-model="item.email.enabled"
              color="primary"
              @click="handleClick(item.email)"
            />
          </template>
          <template #[`item.foxbot`]="{ item }">
            <v-layout>
              <v-simple-checkbox
                v-if="item.foxbot.show"
                v-model="item.foxbot.enabled"
                color="primary"
                disabled
                @click="handleClick(item.foxbot)"
              />
            </v-layout>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider v-if="errors" />
      <v-list>
        <v-list-item
          v-for="error in errors"
          :key="error.message"
          class="error-list error--text px-4"
        >
          <v-icon
            color="error"
            class="mr-1"
          >
            mdi-alert-circle
          </v-icon>
          <span class="error-list error--text px-2">
            {{ error[0].message }}
          </span>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="mx-auto"
          color="primary"
          :disabled="disabled"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import FingerprintField from '@/components/form/FingerprintField.vue';
import { snakeToPascal } from '../../helpers/formatting';

const HEADERS = [
  {
    text: 'Source',
    align: 'start',
    sortable: false,
    value: 'source',
    width: '75px',
    fixed: true,
  },
  {
    text: 'Web',
    align: 'start',
    sortable: false,
    value: 'web',
    toolTip: 'These are the notifications that appear in the notifications drop-down menu',
    width: '75px',
    fixed: true,
  },
  {
    text: 'Push',
    align: 'start',
    sortable: false,
    value: 'push',
    toolTip: 'These are the notifications that get pushed to your desktop',
    width: '75px',
    fixed: true,
  },
  {
    text: 'App',
    align: 'start',
    sortable: false,
    value: 'app',
    width: '75px',
    fixed: true,
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email',
    width: '75px',
    fixed: true,
  },
  {
    text: 'FoxBot',
    align: 'start',
    sortable: false,
    value: 'foxbot',
    width: '75px',
    fixed: true,
  },
];

export default {
  components: {
    FingerprintField,
  },

  data() {
    return {
      valid: false,
      notificationsData: [],
      notificationItems: [],
      errors: null,
      headers: HEADERS,
      loading: true,
    };
  },

  computed: {
    disabled() { // prevents empty forms from being submitted
      return (!this.valid);
    },
    ...mapState('launchDarkly', ['flags']),
  },

  async created() {
    await this.loadNotificationSources();
  },

  methods: {
    submit(e) {
      if (!this.$refs.form.validate()) {
        e.preventDefault();
      } else {
        this.sendNotificationsInfo();
      }
    },

    toPascal: snakeToPascal,

    srcRemove(value) {
      this.notificationsData = this.notificationsData.filter((ele) => ele !== value);
    },

    srcAdd(value) {
      this.notificationsData.push(value);
    },

    handleClick(item) {
      const src = item.id;
      if (!this.notificationsData.includes(src)) {
        this.srcAdd(src);
      } else {
        this.srcRemove(src);
      }
    },

    addDefaults(array) {
      const subSources = ['web', 'app', 'push', 'email', 'foxbot'];
      array.forEach((notificationSource) => {
        subSources.forEach((subSource) => {
          if (notificationSource[subSource].enabled) {
            this.notificationsData.push(notificationSource[subSource].id);
          }
        });
      });
    },

    /**
     * This method should be removed once the
     * temporary Launch Darkly flag is removed
     */
    applyLaunchDarklyFlags() {
      const ldFlagMapping = {
        'Regional Price Updates': this.flags['new-smart-price-notifications'],
        'Daily Digest': this.flags['daily-digest'],
      };

      const blacklist = ['Crop Update', 'Daily Market Wire', 'Market Update'];
      this.notificationItems
        .forEach((n, i) => {
          if (blacklist.includes(n.title)) {
            this.notificationItems[i].email.show = true;
          }
        });
      this.notificationItems = this.notificationItems
        .filter((item) => !(item.title in ldFlagMapping && !ldFlagMapping[item.title]));
    },

    async sendNotificationsInfo() {
      try {
        // build request data
        this.requestData = {
          // if no notifications were enabled upon render, then the first element is blank.
          notifications_string:
            (this.notificationsData[0] === '')
              ? this.notificationsData.slice(1).join()
              : this.notificationsData.join(),
        };

        this.loading = true;
        const data = await API.updateNotificationsInfo(this.requestData);
        if (data.is_valid) {
          this.$emit('snack-bar-success', data.message);
          this.errors = null;
        } else {
          this.$emit('snack-bar-error', data.message);
          this.errors = JSON.parse(data.form_errors);
        }
      } catch (error) {
        this.$snackbar.error(error);
      } finally {
        this.requestData = null;
        this.loading = false;
      }
    },

    async loadNotificationSources() {
      try {
        this.loading = true;
        const data = await API.readNotificationSources();
        this.notificationItems = data.notifications_sources;
        this.addDefaults(this.notificationItems);
        this.applyLaunchDarklyFlags();
      } catch (error) {
        this.$snackbar.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
