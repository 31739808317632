<template>
  <v-card
    v-if="showWidget"
  >
    <v-card-title
      class="pb-0 text-h6 primary-grey--text d-flex justify-space-between"
      :class="$isMobile || $isTablet ? 'px-4' : 'px-6'"
    >
      <template v-if="!loadingVideos">
        <div v-if="!podcastsShown">
          Featured Video
        </div>
        <div
          v-else-if="!showNonPodcast"
        >
          Podcast
        </div>
        <div
          v-else
          class="d-flex p-rel"
        >
          <GButtonToggle
            v-model="typeIndex"
            :selections="types"
          />
          <v-chip
            v-if="latestPodcast && !latestPodcast.is_watched"
            light
            color="primary"
            class="font-weight-medium video-tags new-chip"
          >
            NEW
          </v-chip>
        </div>
        <v-btn
          v-if="!$isMobile && !$isTablet"
          color="primary"
          text
          :loading="loadingVideos"
          href="/videos/"
          data-qa="latest-videos-all-videos-button"
        >
          All Videos
        </v-btn>
      </template>
    </v-card-title>
    <div
      :class="$isMobile || $isTablet ? 'pa-4' : 'pa-6 pr-4'"
    >
      <template
        v-if="loadingVideos"
      >
        <div class="grey--text text-center my-8 py-8">
          Loading...
        </div>
      </template>
      <template v-else>
        <v-row dense>
          <v-col
            class="c-pointer pt-1"
            data-qa="latest-video-widget-desktop-video-thumbnail"
            @click.capture.prevent.stop="showVideoPlayer"
          >
            <video-card
              v-if="latestVideo"
              :video-detail="latestVideo"
              :horizontal="!$vuetify.breakpoint.lgAndDown"
              :show-play-icon="true"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="$isMobile || $isTablet"
          class="justify-center py-4 mt-0"
        >
          <v-btn
            class="mr-2"
            color="primary"
            text
            :loading="loadingVideos"
            href="/videos/"
          >
            All Videos
          </v-btn>
        </v-row>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import {
  formatNumberEnforceDecimals,
  dateFormatShortMonthDateYear,
  getNumberString,
  capitalizeFirstChar,
} from '../../helpers/formatting';
import GButtonToggle from '@/components/generic/GButtonToggle.vue';
import VideoCard from '@/components/videos/VideoCard.vue';

export default {
  name: 'LatestVideos',

  components: {
    GButtonToggle,
    VideoCard,
  },

  data() {
    return {
      isLoaded: false,
      types: [{ value: 0, text: 'PODCAST' }, { value: 1, text: 'FEATURED VIDEO' }],
      typeIndex: 0,
    };
  },

  computed: {
    ...mapState('videos', [
      'videos',
      'loadingVideos',
    ]),
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('subscription', ['isAmerican']),
    ...mapGetters('chargebee', ['isMarketIntelligence']),
    ...mapGetters('videos', ['getVideoTags']),

    showWidget() {
      return this.showNonPodcast || this.showPodcast || this.loadingVideos;
    },

    videoTags() {
      if (!this.latestVideo) { return []; }
      return this.getVideoTags(this.latestVideo);
    },

    latestVideo() {
      if (this.podcastsShown && this.typeIndex === 0) {
        return this.latestPodcast;
      }
      return this.latestNonPodcast;
    },

    latestPodcast() {
      return this.videos.find((video) => video.categories.includes('Podcast'));
    },

    latestNonPodcast() {
      return this.videos.find((video) => !video.categories.includes('Podcast'));
    },

    showPodcast() {
      return (this.latestPodcast?.length ?? 0) > 0;
    },

    showNonPodcast() {
      return (this.latestNonPodcast?.length ?? 0) > 0;
    },

    latestVideoLength() {
      if (this.latestVideo?.length) {
        return this.latestVideo.length;
      }
      return 0;
    },

    videoDateFormatted() {
      if (this.latestVideo?.date_published) {
        return dateFormatShortMonthDateYear(new Date(this.latestVideo.date_published));
      }
      return '';
    },

    srcSets() {
      if (!this.latestVideo) { return []; }
      const thumbnails = [
        [this.latestVideo.thumbnails.small, 0.25],
        [this.latestVideo.thumbnails.medium, 0.5],
        [this.latestVideo.thumbnails.large, 1],
      ];
      return thumbnails.map(([image, factor]) => `${image} ${factor}x`).join(', ');
    },

    podcastsShown() {
      if (this.isAmerican && !this.isMarketIntelligence) return false;
      return this.flags['podcasts'] && this.showPodcast;
    },
  },

  watch: {
    videos() {
      this.setFeaturedVideoTab(!this.showWidget);
    },
  },

  async created() {
    await this.loadAllVideoCategories();
    await this.loadVideos();
  },

  mounted() {
    this.setFeaturedVideoTab(!this.showWidget);
  },

  methods: {
    dateFormatShortMonthDateYear,
    getNumberString,
    capitalizeFirstChar,
    ...mapActions('videos', [
      'loadAllVideos',
      'loadAllVideoCategories',
    ]),
    ...mapMutations('videos', [
      'setLoadingVideos',
    ]),
    ...mapActions('dashboard', ['setFeaturedVideoTab']),

    secondsToMinutes(value) {
      return Math.ceil(formatNumberEnforceDecimals(value / 60));
    },
    async loadVideos() {
      try {
        this.setLoadingVideos(true);
        await this.loadAllVideos();
      } catch (e) {
        this.$snackbar.error(e);
      } finally {
        this.setLoadingVideos(false);
        this.isLoaded = true;
      }
    },
    showVideoPlayer() {
      window.location.href = `/video/${this.latestVideo.video_id}/`;
    },
  },
};
</script>
<style lang="scss">
@use "@/colors/colors";

  .videos-border-b {
    border-bottom: 1.5px solid colors.$greyLighten1;
  }

  .video-tags {
    padding: 1px 10px;
    height: auto !important;
    font-size: 10px !important;
  }

  /* This works on all browsers, except FireFox < version 68 and IE */
  /* For the unsupported browsers, max-height is needed.
      This is dependent on line-height, so is unstable.
  */
  .truncated-text {
    display: -webkit-box;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 3.5rem;
  }

  .latest-videos-widget-title {
    line-height: 28px !important;
  }

  .new-chip {
    position: absolute;
    right: 150px;
    top: -6px;
  }
</style>
