<template>
  <v-dialog
    v-model="isOpen"
    max-width="400px"
    width="100%"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row
          dense
        >
          <v-col cols="12">
            <div class="d-flex my-8">
              <v-avatar
                class="mx-auto"
                size="128"
              >
                <v-img
                  max-height="128"
                  max-width="128"
                  :aspect-ratio="1/1"
                  :src="previewPicture"
                />
              </v-avatar>
            </div>
            <p v-if="message">
              {{ message }}
            </p>
            <v-file-input
              ref="input"
              solo
              :rules="[fileIsImageRule(), fileSizeRule(2)]"
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon=""
              prepend-inner-icon="mdi-camera"
              hide-details="auto"
              label="Select a file"
              @change="updatePreview"
              @click:prepend-inner="handleFileInputClick"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="handleCloseDialog"
        >
          Back
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="disabled"
          @click="uploadImage"
        >
          {{ primaryActionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fileIsImageRule, fileSizeRule } from '../../helpers/rules';
import { postFormRequest } from '../../helpers/request';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    // Dialog's title
    title: {
      type: String,
      required: true,
    },

    // This prop allows you to set a custom placeholder
    // or otherwise pre-populate the preview circle
    picture: {
      type: String,
      required: false,
      default: '',
    },

    // This prop allows you to pass extra
    // data when sending the POST request
    extraData: {
      type: Array,
      required: false,
      default: () => [],
    },

    // This prop allows you to display
    // some extra text in the dialog
    message: {
      type: String,
      required: false,
      default: null,
    },

    // API endpoint to use
    url: {
      type: String,
      required: true,
    },

    // Whether an image selection is required
    // When not required, dialog will allow for a "reset" action
    required: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      file: null,
      inputPicture: '',
    };
  },

  computed: {
    previewPicture() {
      if (this.inputPicture.length > 0) return this.inputPicture;
      if (this.picture.length > 0) return this.picture;
      return '/static/dashboard/templates/blank_image.png';
    },

    resettable() {
      return !this.required && !this.file;
    },

    disabled() {
      return !this.resettable ? this.inputPicture.length === 0 : false;
    },

    primaryActionText() {
      return !this.resettable ? 'Upload' : 'Reset';
    },
  },

  methods: {
    fileIsImageRule,
    fileSizeRule,

    handleFileInputClick() {
      this.$refs.input.$refs.input.click();
    },

    updatePreview(file) {
      if (file) {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          this.file = file;
          this.inputPicture = reader.result;
        }, false);

        reader.readAsDataURL(file);
      } else {
        this.file = null;
        this.inputPicture = '';
      }
    },

    handleCloseDialog() {
      this.inputPicture = '';
      this.file = null;
      this.$refs.input.clearableCallback();
      this.$emit('close');
    },

    async uploadImage() {
      const formData = new FormData();

      // Empty file triggers a picture reset
      let fileValue;
      if (this.resettable) {
        fileValue = new File([], '.jpg', { type: 'image/jpeg' });
      } else {
        fileValue = this.file;
      }
      formData.append('picture', fileValue);

      // Append additional data to request
      this.extraData.forEach((data) => {
        formData.append(data[0], data[1]);
      });

      try {
        const data = await postFormRequest(this.url, formData);
        this.$snackbar.success(data.message);
        this.$refs.input.clearableCallback();

        // Emit event on successful upload
        this.$emit('success', data.url);
      } catch (e) {
        this.$snackbar.error(e);
      }
    },
  },
};
</script>
