<template>
  <component
    :is="component"
    v-model="farmLocationDialog.open"
    :title="title"
    :action-buttons="actions"
    :bottom="$isMobile"
    :height="500"
    @input="onCancel"
    @cancel="onCancel"
  >
    <template v-if="!isDelete">
      <div style="overflow-x: hidden;">
        <v-row dense>
          <v-text-field
            v-model="name"
            data-qa="farm-location-dialog-farm-name-text-field"
            label="Farm Name"
            class="pl-1 mt-2 shrink"
            style="width:99%"
            outlined
            required
            maxlength="255"
            :rules="[requiredValueRule('Name')]"
            autofocus
          />
        </v-row>
        <v-row
          dense
          class="align-baseline"
        >
          <v-col
            cols="12"
            sm="8"
          >
            <v-text-field
              :value="combinedLatLong"
              :rules="[requiredValueRule('Location')]"
              label="Farm Location"
              outlined
              required
              disabled
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <div :class="{'text-center': !$isMobile, 'text-right': $isMobile }">
              <LocationPicker
                data-qa="farm-location-dialog-location-picker"
                :farm-location="farm"
                :locations="farmLocationsAlphabetical"
                @on-change="setCoordinates"
              />
            </div>
          </v-col>
        </v-row>
        <v-row
          :class="{'mt-6' : $isMobile }"
          dense
        >
          <v-textarea
            v-model="description"
            data-qa="farm-location-dialog-description-text-field"
            label="Description"
            class="pl-1 mt-2 shrink"
            style="width:99%"
            outlined
            maxlength="500"
          />
        </v-row>
        <v-row
          :class="{'mt-1 mb-2 pl-1' : $isMobile, 'mb-0 pl-1': !$isMobile }"
          dense
        >
          <v-checkbox
            v-model="isPrimary"
            :disabled="isPrimaryFarmByDefault"
          >
            <template #label>
              <div
                class="text-body-1 grey--text text--darken-4"
              >
                Set as my primary farm
              </div>
            </template>
          </v-checkbox>
        </v-row>
        <div
          v-if="$isMobile"
          class="footer-area"
        >
          <v-divider />
          <div class="d-flex justify-center white pa-4">
            <v-btn
              text
              @click="actions[0].onClick"
            >
              {{ actions[0].name }}
            </v-btn>
          </div>
          <v-divider />
          <div class="d-flex justify-center white pa-4">
            <v-btn
              color="primary"
              text
              :disabled="!isSaveButtonActive"
              @click="actions[1].onClick"
            >
              {{ actions[1].name }}
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      Are you sure you want to delete this farm location? This action is not reversible.
    </template>
  </component>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import GDialog from '../../generic/GDialog.vue';
import SlideDrawer from '@/components/drawers/SlideDrawer.vue';
import LocationPicker from '@/components/generic/LocationPicker.vue';
import { requiredValueRule } from '../../../helpers/rules';

export default {
  components: {
    GDialog,
    SlideDrawer,
    LocationPicker,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isPrimaryFarmByDefault: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: '',
      description: '',
      longitude: '',
      latitude: '',
      stateFarm: null,
      isPrimary: this.isPrimaryFarmByDefault,
    };
  },

  computed: {
    ...mapState('farmLocations', ['farmLocationDialog', 'farmLocationDialogDefaultName']),
    ...mapGetters('farmLocations', ['farmLocationsAlphabetical', 'getFarmLocationById']),

    isUpdate() {
      return Boolean(this.stateFarm);
    },

    isDelete() {
      return this.farmLocationDialog?.delete;
    },

    isSaveButtonActive() {
      return this.name && this.longitude && this.latitude;
    },

    farm() {
      return {
        id: this.stateFarm?.id,
        name: this.name,
        description: this.description,
        longitude: this.longitude,
        latitude: this.latitude,
        default: this.isPrimary,
      };
    },

    title() {
      if (this.isDelete) {
        return 'Delete Farm Location';
      }

      if (this.isUpdate) {
        return `Update Farm Location: ${this.stateFarm.name}`;
      }

      return 'Add Farm Location';
    },

    combinedLatLong() {
      if (this.latitude !== '' && this.longitude !== '') {
        return `${this.latitude}, ${this.longitude}`;
      }
      return '';
    },

    actions() {
      return [{
        name: 'CANCEL',
        type: 'default',
        side: 'right',
        onClick: this.onCancel,
      }, {
        name: this.isDelete ? 'DELETE' : 'SAVE',
        type: this.isDelete ? 'delete' : 'primary',
        side: 'right',
        mustValidate: true,
        onClick: this.isDelete ? this.onDelete : this.onSave,
      }];
    },

    component() {
      return this.$isMobile ? 'slide-drawer' : 'g-dialog';
    },

    deletePrompt() {
      return `Are you sure you want to delete ${this.stateFarm?.name || 'this farm'}?`;
    },
  },

  watch: {
    'farmLocationDialog.open': function handle(val) {
      if (val) {
        this.onOpen();
      }
    },
  },

  methods: {
    requiredValueRule,
    ...mapActions('farmLocations', [
      'closeFarmLocationDialog',
      'createFarmLocation',
      'updateFarmLocation',
      'deleteFarmLocation',
      'fetchFarmLocations',
    ]),
    ...mapActions('weather', ['loadFarmLocation']),
    ...mapMutations('farmLocations', ['setPrimaryFarmDialogOpen']),

    async onSave() {
      try {
        let result;

        if (this.isUpdate) {
          result = await this.updateFarmLocation(this.farm);
        } else {
          result = await this.createFarmLocation(this.farm);
        }

        this.$snackbar.success(result.message);

        this.$emit('done', result.data);
        if (this.farmLocationDialog.onDone) {
          this.farmLocationDialog.onDone(result.data);
        }

        this.fetchFarmLocations();
        this.closeFarmLocationDialog();
        this.setPrimaryFarmDialogOpen(false);
      } catch (e) {
        this.$snackbar.error(e);
      }
    },

    async onDelete() {
      try {
        const { message } = await this.deleteFarmLocation(this.farm);
        this.$snackbar.success(message);
        this.$emit('delete', this.farm);
        this.closeFarmLocationDialog();
      } catch (e) {
        this.$snackbar.error(e);
      }
    },

    setCoordinates(val) {
      this.longitude = val[0].longitude;
      this.latitude = val[0].latitude;
    },
    onOpen() {
      this.stateFarm = this.getFarmLocationById(this.farmLocationDialog.id) || null;

      this.name = this.farmLocationDialogDefaultName || '';
      this.description = '';
      this.longitude = '';
      this.latitude = '';
      this.isPrimary = this.isPrimaryFarmByDefault;

      this.$nextTick(() => {
        if (this.farmLocationDialog.id) {
          this.name = this.stateFarm.name;
          this.description = this.stateFarm.description;
          this.longitude = this.stateFarm.longitude;
          this.latitude = this.stateFarm.latitude;
          this.isPrimary = this.stateFarm.default;
        }
      });
    },
    onCancel() {
      this.$emit('cancel');
      this.closeFarmLocationDialog();
    },
  },
};
</script>
