import Vue from 'vue';

export default {
  state: () => ({
    loading: false,
    showMore: false,
    moreInfo: [],
    resultTable: {},
    pageData: undefined,
    totalItems: 0,
    dupeCount: 0,
    itemsPerPage: 15,
    scrollTop: 0,
  }),
  mutations: {
    setScrollTop(state, payload) {
      state.scrollTop = payload;
    },
    setLoading(state, payload) {
      state.loading = Boolean(payload);
    },
    setShowMore(state, payload) {
      state.showMore = Boolean(payload);
    },
    setMoreInfo(state, payload) {
      state.moreInfo = payload;
    },
    setResult(state, payload) {
      Vue.set(state.resultTable, payload.key, payload.value);
    },
    setDupeCount(state, payload) {
      state.dupeCount = payload;
    },
    pushInfo(state, payload) {
      state.moreInfo.push(payload);
    },
    setResultTable(state, payload) {
      state.resultTable = payload;
    },
    setPageData(state, payload) {
      state.pageData = payload;
    },
    setTotalItems(state, payload) {
      state.totalItems = payload;
    },
    setItemsPerPage(state, payload) {
      state.itemsPerPage = payload;
    },
  },
  namespaced: true,
};
