export function getTrees(nodes, key = 'id', parentKey = 'parent_id', options = {}) {
  // Guard against being passed null/undefined nodes
  if (!nodes) return [];

  // Convert nodes to an array before reducing
  const nodeList = Array.isArray(nodes) ? nodes : Object.values(nodes);

  const nodesHash = nodeList.reduce(
    (acc, curr) => ({ ...acc, [curr[key]]: { ...curr, children: [], ...options } }),
    {},
  );
  const treeStructure = Object.values(nodesHash).reduce(
    (acc, curr) => {
      const id = curr[parentKey];
      if (id && nodesHash[id]) nodesHash[id].children.push(curr);
      else acc.push(curr);
      return acc;
    },
    [],
  );
  return treeStructure;
}

export function getActualProductionSummaryTrees(nodes, showChildCommodities, key = 'id', parentKey = 'parent_id') {
  const nodesHash = Object.values(nodes).reduce(
    (acc, curr) => ({
      ...acc,
      [curr[key]]: { ...curr, children: [], showChildren: showChildCommodities[curr[key]] },
    }),
    {},
  );
  const treeStructure = Object.values(nodesHash).reduce(
    (acc, curr) => {
      if (curr[parentKey] && nodesHash[curr[parentKey]]) {
        nodesHash[curr[parentKey]].children.push(curr);
      } else {
        acc.push(curr);
      }
      return acc;
    },
    [],
  );
  return treeStructure;
}

export function getParent(flattenedTree, key, itemKey) {
  const identifier = itemKey || 'id';

  return flattenedTree.find((item) => {
    if (!item.children) return false;
    const arr = item.children.map((child) => child[identifier] === key);
    return arr.includes(true);
  });
}

export function flatten(items) {
  // This function was introduced because it is a bit less opinionated
  // about the properties required of each node
  return items.reduce((accumulated, item) => {
    const { children } = item;
    if (!children?.length) return [{ ...item }, ...accumulated];
    return [...accumulated, { ...item }, ...flatten(children)];
  }, []);
}

export function flattenTree(tree, level, options = {}) {
  let flattenChildren = [];
  if (tree.children.length !== 0 && tree.showChildren) {
    flattenChildren = tree.children.map((child) => flattenTree(child, level + 1, options)).flat();
  }
  const node = { ...tree, ...options, level };
  return [node, ...flattenChildren];
}

export function findNode(tree, searchKey, searchValue) {
  if (tree[searchKey] === searchValue) {
    return tree;
  }
  if (tree.children.length === 0) {
    // not found
    return null;
  }
  let result = null;
  for (let i = 0; result == null && i < tree.children.length; i += 1) {
    result = findNode(tree.children[i], searchKey, searchValue);
  }
  return result;
}

export function sortTrees(treesArray, property = 'name', childrenProperty = 'children') {
  // base case
  if (treesArray.length === 0) return treesArray;
  // recursively sort the children of all tree elements
  treesArray.forEach((i) => sortTrees(i?.[childrenProperty]));
  // sort the root array alphabetically by name of commodity
  treesArray.sort((a, b) => a?.[property]?.localeCompare(b?.[property]));
  return treesArray;
}

export function disableTopmostParents(treesArray, exceptions) {
  const newTreeArray = treesArray.map(
    (x) => (x.has_children ? { ...x, isNavNode: true } : x),
  );
  exceptions.forEach((exception) => {
    const exceptionCrop = newTreeArray.find((x) => x.name === exception);
    if (exceptionCrop) {
      const exceptionChild = {
        ...exceptionCrop,
        isNavNode: false,
        has_children: false,
        children: [],
        parent_id: exceptionCrop.id,
      };
      exceptionCrop.children.push(exceptionChild);
      exceptionCrop.id = `exception${exceptionCrop.id}`; // Ensures nav only parent has a unique id
    }
  });
  return newTreeArray;
}
