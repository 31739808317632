<template>
  <div>
    <Chargebee />
    <AddonDialog
      :default-publication="defaultPublication"
      :enforce-default="enforcePublication"
      :show="addonDialogOpen"
      :show-coupon-disclaimer="true"
      :only-addons="false"
      :addon-target="addonTarget"
      @cancel="onCancel"
      @continue="onContinue"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Chargebee from '@/components/payments/Chargebee.vue';
import { SMART_PLAN, FARMLINK_ADDON, DEPUTTER_ADDON } from '../payments/data/constants';
import AddonDialog from '../payments/AddonDialog.vue';
import purgeResource from '@/api/payments';

export default {
  name: 'CouponDialog',

  components: {
    Chargebee,
    AddonDialog,
  },

  data() {
    return {
      addonTarget: SMART_PLAN,
      selectedProvince: null,
      success: false,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('chargebee', ['isInTrial']),
    ...mapGetters('subscription', ['isDeputter', 'isSyngenta']),
    ...mapState('discountCoupon', ['activeSubscriptionCoupon', 'addonDialogOpen']),

    isFarmlink() {
      return !this.province || this.province.group === 'western';
    },

    defaultPublication() {
      if (!this.enforcePublication) {
        return null;
      }
      return this.isFarmlink ? FARMLINK_ADDON : DEPUTTER_ADDON;
    },

    enforcePublication() {
      return this.flags['restricted-addons'];
    },
  },

  methods: {
    ...mapActions('subscription', ['convertSubscriptionToFarmLink', 'patchSubscription']),
    ...mapActions('app', ['acquireMimicLock', 'releaseMimicLock']),
    ...mapMutations('discountCoupon', ['setAddonDialogOpen']),

    async onClose() {
      if (this.success) {
        await purgeResource(['subscription', 'estimate']);
        if (this.isDeputter || this.isSyngenta) {
          await this.convertSubscriptionToFarmLink();
          await this.releaseMimicLock();
        }
        window.location.reload();
      } else {
        await this.releaseMimicLock();
      }
    },

    async onSuccess() {
      if (this.selectedProvince && this.selectedProvince.id) {
        await this.patchSubscription({ province: this.selectedProvince.id, country: this.selectedProvince.country });
      }
      this.success = true;
    },

    onCancel() {
      this.setAddonDialogOpen(false);
    },

    async onContinue(addons, defaultPublication, _, selectedProvince) {
      this.selectedProvince = selectedProvince;
      await this.acquireMimicLock();
      this.$chargebee.openCheckout({
        plan: SMART_PLAN,
        isMonthly: false,
        defaultPublication,
        addons,
        endTrial: this.isInTrial,
        coupon: this.activeSubscriptionCoupon.coupon_id,
        callbacks: {
          close: this.onClose,
          success: this.onSuccess,
        },
      });
      this.setAddonDialogOpen(false);
    },
  },
};
</script>
