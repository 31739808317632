const NewsDtn = () => import('@/pages/news/NewsDtn.vue');
const NewsDtnArticle = () => import('@/pages/news/NewsDtnArticle.vue');

const routes = [
  {
    path: '/news/',
    meta: {
      title: 'News',
    },
    component: NewsDtn,
  },
  {
    path: '/news/:provider/:articleId',
    meta: {
      title: 'News',
    },
    component: NewsDtnArticle,
  },
];

export default routes;
