<template>
  <v-card>
    <v-card-title>
      Provider Application
    </v-card-title>

    <v-btn
      v-if="showAdd"
      color="primary"
      class="ma-4 mt-0"
      @click="handleAddClick"
    >
      Apply
    </v-btn>

    <v-data-table
      v-if="items.length"
      :items="items"
      :headers="headers"
      :items-per-page="-1"
      :loading="loading"
      :hide-default-footer="true"
    >
      <template #[`item.dateCreated`]="{ item }">
        {{ item.dateCreated | date }}
      </template>

      <template #[`item.dateUpdated`]="{ item }">
        {{ item.dateUpdated | date }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString('en-CA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    showAdd: {
      type: Boolean,
    },
  },

  data() {
    return {
      headers: [
        { text: 'Status', value: 'status' },
        { text: 'Date Created', value: 'dateCreated' },
        { text: 'Date Updated', value: 'dateUpdated' },
      ],
    };
  },

  methods: {
    handleAddClick() {
      this.$emit('add');
    },
  },
};
</script>
