import { postRequest } from '../../helpers/request';

export async function getDtnWeatherKeys() {
  const data = await postRequest('/read_dtn_keys/');
  return data;
}

async function getFarmLocations() {
  const data = await postRequest('/dashboard/api/read_farm_locations/');
  return data;
}

async function getAllLocations() {
  const data = await postRequest('/read_weather_stations/');
  return data;
}

async function getForeCastCodes() {
  const data = await fetch('/static/dashboard/data/weather_forecast_codes.json')
    .then((res) => res.json());
  return data;
}

async function getWeatherDetails(body) {
  const data = await postRequest('/read_weather_api/', body);
  return data;
}

export default {
  state: () => ({
    apiKeysDtn: {
      localWeatherWeb: null,
    },
    farmLocations: [],
    winnipegCoordinates: {
      latitude: 49.887060,
      longitude: -97.131287,
    },
    dtnCustomCoordinateVal: null,
    usingFarmLocationVal: false,
    postalCodeVal: '',
    currentWidgetVal: '',
    locationTypeVal: '',
    allLocations: [],
    selectedLocation: null,
    selectedLocationTypeData: 'farm',
    selectedCoordinates: null,
    weatherData: null,
    dashboardSelectedFarmLocation: null,
    forecastImgMapping: null,
    dataErrorSnackbar: {
      value: false,
      text: '',
    },
    dashboardWeatherWidgetStatus: 'loading', // loading / dataAvailable / dataNotAvailable
  }),

  mutations: {
    setDtnKey(state, payload) {
      state.apiKeysDtn.localWeatherWeb = payload;
    },
    setFarmLocations(state, payload) {
      state.farmLocations = payload;
    },
    setPostalCode(state, payload) {
      state.postalCodeVal = payload;
    },
    setCurrentWidgetVal(state, payload) {
      state.currentWidgetVal = payload;
    },
    setLocationTypeVal(state, payload) {
      state.locationTypeVal = payload;
    },
    setAllLocations(state, payload) {
      state.allLocations = payload;
    },
    setSelectedLocation(state, payload) {
      state.selectedLocation = payload;
    },
    setUsingRegionData(state, payload) {
      state.selectedLocationTypeData = payload;
    },
    setSelectedCoordinates(state, payload) {
      state.selectedCoordinates = payload;
    },
    setWeatherData(state, payload) {
      state.weatherData = payload;
    },
    setDashboardSelectedFarmLocation(state, payload) {
      state.dashboardSelectedFarmLocation = payload;
    },
    setForecastImgMapping(state, payload) {
      state.forecastImgMapping = payload;
    },
    setDataErrorSnackbar(state, payload) {
      state.dataErrorSnackbar = payload;
    },
    setDashboardWeatherWidgetStatus(state, payload) {
      state.dashboardWeatherWidgetStatus = payload;
    },
    setDtnCustomCoordinateVal(state, payload) {
      state.dtnCustomCoordinateVal = payload;
    },
    setUsingFarmLocationVal(state, payload) {
      state.usingFarmLocationVal = payload;
    },
  },

  getters: {
    stationsByCode: (state) => {
      const stations = {};
      state.allLocations.forEach((station) => {
        stations[station.code] = station;
      });
      return stations;
    },
  },

  actions: {
    async loadFarmLocation({ commit }) {
      const farms = await getFarmLocations();
      commit('setFarmLocations', farms);
    },
    async loadAllLocations({ commit }) {
      const { results } = await getAllLocations();
      const forecastImgMapping = await getForeCastCodes();
      commit('setAllLocations', results);
      commit('setForecastImgMapping', forecastImgMapping);
    },
    loadCoordinates({ commit }, payload) {
      const value = Array.from(payload);
      commit('setDtnCustomCoordinateVal', value);
      window.localStorage.setItem('dtnCoordinates', btoa(JSON.stringify(value)));
    },
    onDashboardSelectedFarmLocationChange({ commit }, payload) {
      commit('setDashboardSelectedFarmLocation', payload);
      window.localStorage.setItem('dashboardSelectedFarmLocation', btoa(JSON.stringify(payload)));
    },
    onPostalCodeChange({ commit }, payload) {
      let val = payload.toUpperCase();
      if (!val) { val = ''; }
      commit('setPostalCode', val);
      window.localStorage.setItem('userPostalCode', btoa(JSON.stringify(val)));
    },
    onCurrentWidgetChange({ commit }, payload) {
      let val = payload;
      if (!val) { val = ''; }
      commit('setCurrentWidgetVal', val);
      window.localStorage.setItem('dtnCurrentWidget', btoa(JSON.stringify(val)));
    },
    onLocationTypeChange({ commit }, payload) {
      let val = String(payload);
      if (!val) { val = '0'; }
      commit('setLocationTypeVal', val);
      window.localStorage.setItem('dtnLocationType', btoa(JSON.stringify(val)));
    },
    onUsingFarmLocationValChange({ commit }, payload) {
      commit('setUsingFarmLocationVal', payload);
      window.localStorage.setItem('usingFarmLocationVal', btoa(JSON.stringify(payload)));
    },
    async requestWeatherDetails({ commit, state }) {
      let body = null;

      if (state.selectedLocationTypeData === 'farm' && state.dashboardSelectedFarmLocation) {
        body = state.dashboardSelectedFarmLocation;
      } else if (state.selectedLocationTypeData === 'region' && state.selectedLocation) {
        body = state.selectedLocation;
      } else if (state.selectedLocationTypeData === 'coordinates' && state.selectedCoordinates) {
        body = state.selectedCoordinates;
      }

      window.localStorage.setItem('dashboardWeatherReqBody', btoa(JSON.stringify(body)));
      window.localStorage.setItem('dashboardSelectedLocationTypeData', state.selectedLocationTypeData);
      if (body === null) {
        // Don't make a request if there is no body to request, data is technically still not available
        // Also prevents flashing the error widget upon mounting
        return;
      }
      try {
        const { results } = await getWeatherDetails(body);
        commit('setWeatherData', results);
        commit('setDashboardWeatherWidgetStatus', 'dataAvailable');
      } catch (error) {
        this._vm.$snackbar.error(error);
        commit('setDashboardWeatherWidgetStatus', 'dataError');
      }
    },
    async loadInitialDashboardWeather({ dispatch, commit, state }, payload) {
      let body = null;
      let farmRequests = payload || 0;
      const requestLimit = 3;

      const dashboardWeatherReqBody = (() => {
        if (window.localStorage.getItem('dashboardWeatherReqBody')) return JSON.parse(atob(window.localStorage.getItem('dashboardWeatherReqBody')));
        return {};
      })();
      const dashboardSelectedLocationTypeData = window.localStorage.getItem('dashboardSelectedLocationTypeData') ?? '';

      if (dashboardWeatherReqBody && dashboardSelectedLocationTypeData) {
        body = dashboardWeatherReqBody;
        commit('setUsingRegionData', dashboardSelectedLocationTypeData);
      } else if (state.farmLocations.length > 0 && farmRequests <= requestLimit) {
        // find index of default farm location
        const defaultFarmIndex = (state.farmLocations.length === 1)
          ? 0
          : state.farmLocations.findIndex((farm) => farm.default);
        if (defaultFarmIndex === -1) {
          // default farm location not set, show empty state and allow user to set default farm location
          commit('setDashboardWeatherWidgetStatus', 'dataNotAvailable');
          return;
        }
        commit('setDashboardSelectedFarmLocation', state.farmLocations[defaultFarmIndex]);
        body = state.dashboardSelectedFarmLocation;
      } else if (state.farmLocations.length === 0 && farmRequests <= requestLimit) {
        // recalls the function after 500ms up to 4x
        setTimeout(() => {
          farmRequests += 1;
          dispatch('loadInitialDashboardWeather', farmRequests);
        }, 500);
      }

      if (body && farmRequests <= requestLimit) {
        window.localStorage.setItem('dashboardWeatherReqBody', btoa(JSON.stringify(body)));
        window.localStorage.setItem('dashboardSelectedLocationTypeData', state.selectedLocationTypeData);
        try {
          const { results } = await getWeatherDetails(body);
          commit('setWeatherData', results);
          commit('setDashboardWeatherWidgetStatus', 'dataAvailable');
        } catch (error) {
          this._vm.$snackbar.error(error);
          commit('setDashboardWeatherWidgetStatus', 'dataError');
        }
      } else if (farmRequests > requestLimit) {
        commit('setDashboardWeatherWidgetStatus', 'dataNotAvailable');
      }
    },
    loadDtnPageValues({ state }) {
      const storage = window.localStorage;
      const data = {};
      data.dtnCustomCoordinateVal = storage.getItem('dtnCoordinates');
      data.postalCodeVal = storage.getItem('userPostalCode');
      data.currentWidgetVal = storage.getItem('dtnCurrentWidget');
      data.locationTypeVal = storage.getItem('dtnLocationType');
      data.usingFarmLocationVal = storage.getItem('usingFarmLocationVal');
      data.dashboardSelectedFarmLocation = storage.getItem('dashboardSelectedFarmLocation');

      Object.entries(data).forEach(([property, value]) => {
        // Try catch is for handling null values and to keep
        // executing this loop if one proprty throws exception
        try {
          if (value) {
            state[property] = JSON.parse(atob(value));
          }
          // eslint-disable-next-line no-empty
        } catch (e) { }
      });
    },
  },

  namespaced: true,
};
