<template>
  <div class="g-onboarding-tooltip">
    <div
      ref="anchor"
      class="g-onboarding-tooltip__anchor"
    >
      <slot />
    </div>
    <div
      v-if="showTooltip"
      class="g-onboarding-tooltip__arrow"
      :style="arrowPositionStyle"
      @click.prevent.stop=""
    />
    <div
      v-if="showTooltip"
      ref="portal"
      data-qa="g-onboarding-tooltip-portal"
      class="g-onboarding-tooltip__portal"
      :style="portalPositionStyle"
      @click.prevent.stop=""
    >
      <div
        class="g-onboarding-tooltip__content elevation-4"
        :style="`width: ${portalWidth}`"
      >
        <slot name="step">
          <p
            :class="body ? 'mb-1' : 'mb-2'"
            class="text-caption grey--text text--darken-4"
          >
            {{ step }}
          </p>
        </slot>
        <div class="g-onboarding-tooltip__text">
          <div class="g-onboarding-tooltip__title">
            <slot name="title">
              <p
                :class="body ? 'mb-1' : 'mb-2'"
                class="text-body-1 font-weight-medium grey--text text--darken-4"
              >
                {{ title }}
              </p>
            </slot>
          </div>
          <div class="g-onboarding-tooltip__body">
            <slot name="body">
              <p
                v-if="body"
                class="text-body-1 mb-2 grey--text text--darken-4"
              >
                {{ body }}
              </p>
            </slot>
          </div>
        </div>
        <div class="w--100 d-flex justify-end">
          <slot name="skip">
            <template v-if="$slots.skip">
              <v-btn
                text
              >
                SKIP
              </v-btn>
            </template>
          </slot>
          <slot name="button">
            <v-btn
              data-qa="g-onboarding-tooltip-ok"
              text
              @click="onOK"
            >
              OK
            </v-btn>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber } from '@/helpers/numeric';

/**
 * Tooltip component for use in onboarding/tutorial flows.
 *
 * This component has several slots:
 * - The default slot is used to render the anchor element. This is the element
 *    that this component will "attach" to.
 * - The "title" slot is used to render the title of the tooltip.
 * - The "body" slot is used to render the body of the tooltip.
 * - The "button" slot is used to render the button of the tooltip.
 *
 * This component also uses specific CSS classnames to accomplish styles.
 * The base classname is "g-onboarding-tooltip". The following classes are used:
 * - "g-onboarding-tooltip__anchor": The anchor element. This class is empty and is just
 *    used to select the anchor element if style overrides are needed.
 * - "g-onboarding-tooltip__portal": The portal which contains the actual tooltip.
 * - "g-onboarding-tooltip__arrow": The arrow element.
 * - "g-onboarding-tooltip__content": The content div which contains the area and button.
 * - "g-onboarding-tooltip__title": The title container.
 * - "g-onboarding-tooltip__body": The body container.
 * - "g-onboarding-tooltip__text": The div which contains body and title.
 *
 * Finally, this component can be used in tandem with the `v-arrow-anchor` directive
 *  to position the arrow correctly. The default behavior of the arrow is to point to the
 *  center of the highest child element, however sometimes this is not desirable. In these
 *  cases, the directive can be used to target a specific child element.
 *
 */
export default {
  name: 'GOnboardingTooltip',
  props: {
    /**
     * Title of the tooltip
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Body of the tooltip
     */
    body: {
      type: String,
      default: '',
    },
    /**
     * Width of the tooltip
     *
     * Accepts a number of pixels or a CSS string
     */
    width: {
      type: [Number, String],
      default: 273,
    },
    /**
     * Position of the tooltip
     *
     * Values are a tuple of (position, alignment).
     * Example: "top-start" will position the tooltip above the anchor and
     *  align the end of the tooltip and anchor (such that the tooltip "starts" before the anchor).
     *
     * Possible positions:
     * - top
     * - bottom
     * - left
     * - right
     *
     * Possible alignments:
     * - start
     * - center
     * - end
     *
     * Alignment also accepts [left, right, top, bottom] for ease of use,
     *  but preference is for 'start' and 'end'. Left and right alignments are present
     *  for top and bottom positions, while top and bottom alignments are present
     *  for left and right positions.
     *
     * Leaving out the alignment will default to 'center'.
     */
    position: {
      validator(value) {
        if (typeof value !== 'string') {
          return false;
        }
        const fixedValue = value.toLowerCase().trim();
        return [
          'top',
          'top-center',
          'top-left',
          'top-start',
          'top-right',
          'top-end',
          'bottom',
          'bottom-center',
          'bottom-left',
          'bottom-start',
          'bottom-right',
          'bottom-end',
          'left',
          'left-center',
          'left-top',
          'left-start',
          'left-bottom',
          'left-end',
          'right',
          'right-center',
          'right-top',
          'right-start',
          'right-bottom',
          'right-end',
        ].includes(fixedValue);
      },
      default: 'bottom',
    },
    /**
     * Whether to show the tooltip.
     */
    value: {
      type: Boolean,
      default: true,
    },
    manual: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // Anchor position
      anchorX: 0,
      anchorY: 0,
      anchorW: 0,
      anchorH: 0,
      // Arrow anchor position
      arrowAnchorX: 0,
      arrowAnchorY: 0,
      arrowAnchorW: 0,
      arrowAnchorH: 0,
      // Tooltip content position
      contentW: 0,
      contentH: 0,
      contentX: 0,
      contentY: 0,
    };
  },
  computed: {
    showTooltip: { // v-model support in case it is needed in the future
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    tuple() {
      if (this.position.includes('-')) {
        return this.position.split('-');
      }
      return [this.position, 'center'];
    },

    portalWidth() {
      // Convert number to style string
      if (isNumber(this.width)) {
        return `${this.width}px`;
      }
      return this.width;
    },
    portalPositionStyle() {
      // The monster
      switch (this.position) {
        case 'top':
        case 'top-center':
          return {
            transform: `translate(${(this.anchorW / 2) - (this.contentW / 2)}px, ${-this.contentH}px)`,
          };
        case 'top-right':
        case 'top-end':
          return {
            transform: `translate(${-12}px, ${-this.contentH}px)`,
          };
        case 'top-start':
        case 'top-left':
          return {
            transform: `translate(${this.anchorW - this.contentW + 12}px, ${-this.contentH}px)`,
          };
        case 'left':
        case 'left-center':
          return {
            transform: `translate(${-this.contentW}px, ${(this.anchorH / 2) - (this.contentH / 2)}px)`,
          };
        case 'left-bottom':
        case 'left-end':
          return {
            transform: `translate(${-this.contentW}px, ${-12}px)`,
          };
        case 'left-top':
        case 'left-start':
          return {
            transform: `translate(${-this.contentW}px, ${this.anchorH - this.contentH + 12}px)`,
          };
        case 'bottom':
        case 'bottom-center':
          return {
            transform: `translate(${+(this.anchorW / 2) - (this.contentW / 2)}px, ${this.anchorH}px)`,
          };
        case 'bottom-right':
        case 'bottom-end':
          return {
            transform: `translate(${-12}px, ${this.anchorH}px)`,
          };
        case 'bottom-start':
        case 'bottom-left':
          return {
            transform: `translate(${+this.anchorW - this.contentW + 12}px, ${+this.anchorH}px)`,
          };
        case 'right':
        case 'right-center':
          return {
            transform: `translate(${+this.anchorW}px, ${+(this.anchorH / 2) - (this.contentH / 2)}px)`,
          };
        case 'right-bottom':
        case 'right-end':
          return {
            transform: `translate(${+this.anchorW}px, ${-this.anchorH + 24}px)`,
          };
        case 'right-top':
        case 'right-start':
          return {
            transform: `translate(${+this.anchorW}px, ${+this.anchorH - this.contentH + 12}px)`,
          };

        default: return {};
      }
    },
    arrowPositionStyle() {
      // Offsets for if there is an arrow anchor, these will be 0 if not
      const anchorDeltaH = this.anchorH - this.arrowAnchorH;
      const anchorDeltaX = this.anchorX - this.arrowAnchorX;
      const anchorDeltaY = this.anchorY - this.arrowAnchorY;
      const arrowSide = 17 / 2;
      switch (this.tuple[0]) {
        case 'top':
          return {
            transform: `translate(${-anchorDeltaX + this.arrowAnchorW / 2 - arrowSide}px, ${-17}px) rotate(45deg)`,
          };
        case 'left':
          return {
            transform: `translate(${-17}px, ${this.arrowAnchorH / 2 - arrowSide - anchorDeltaY}px) rotate(45deg)`,
          };
        case 'bottom':
          return {
            transform: `translate(${-anchorDeltaX + this.arrowAnchorW / 2 - arrowSide}px, ${+this.arrowAnchorH + 6 + anchorDeltaH}px) rotate(45deg)`,
          };
        case 'right':
          return {
            transform: `translate(${+this.anchorW + 5}px, ${this.arrowAnchorH / 2 - arrowSide - anchorDeltaY}px) rotate(45deg)`,
          };
        default: return {};
      }
    },
  },
  watch: {
    // If any of these change, the height could as well, so watch to ensure correct positioning
    body() {
      this.$nextTick(this.recomputeAnchorPosition);
      this.$nextTick(this.recomputeContentSize);
    },
    title() {
      this.$nextTick(this.recomputeAnchorPosition);
      this.$nextTick(this.recomputeContentSize);
    },
    width() {
      this.$nextTick(this.recomputeAnchorPosition);
      this.$nextTick(this.recomputeContentSize);
    },
    position() {
      this.$nextTick(this.recomputeAnchorPosition);
      this.$nextTick(this.recomputeContentSize);
    },
    value() {
      this.$nextTick(this.recomputeAnchorPosition);
      this.$nextTick(this.recomputeContentSize);
    },

  },
  mounted() {
    // Add event listeners and compute initial size
    this.recomputeAnchorPosition();
    this.recomputeContentSize();
    window.addEventListener('resize', this.recomputeAnchorPosition);
    window.addEventListener('resize', this.recomputeContentSize);
    window.addEventListener('scroll', this.recomputeAnchorPosition);
  },
  beforeDestroy() {
    // Cleanup listeners
    window.removeEventListener('resize', this.recomputeAnchorPosition);
    window.removeEventListener('resize', this.recomputeContentSize);
    window.removeEventListener('scroll', this.recomputeAnchorPosition);
  },
  methods: {
    onOK(clickEvent) {
      this.$emit('ok', clickEvent);
    },
    zeroAnchors() {
      // Clear anchor position data
      this.anchorX = 0;
      this.anchorY = 0;
      this.anchorW = 0;
      this.anchorH = 0;
      this.arrowAnchorX = 0;
      this.arrowAnchorY = 0;
      this.arrowAnchorW = 0;
      this.arrowAnchorH = 0;
    },
    recomputeContentSize() { // Content size is computed from the DOM
      if (!this.$refs.portal) {
        this.contentW = 0;
        this.contentH = 0;
        this.contentX = 0;
        this.contentY = 0;
        return;
      }
      const {
        width, height, top, left,
      } = this.$refs.portal.getBoundingClientRect();
      this.contentW = width;
      this.contentH = height;
      this.contentX = left;
      this.contentY = top;
    },
    recomputeAnchorPosition() { // Anchor position is computed from the DOM
      if (this.manual) {
        return;
      }
      const { anchor } = this.$refs;
      if (!anchor) {
        this.zeroAnchors(); // No anchor (shouldn't happen but just in case)
        return;
      }
      const anchorRect = anchor.getBoundingClientRect();
      this.anchorX = anchorRect.left;
      this.anchorY = anchorRect.top;
      this.anchorW = anchorRect.width;
      this.anchorH = anchorRect.height;
      if (!this.anchors) {
        this.arrowAnchorX = anchorRect.left;
        this.arrowAnchorY = anchorRect.top;
        this.arrowAnchorW = anchorRect.width;
        this.arrowAnchorH = anchorRect.height;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/colors/colors';

@mixin reset-functions {
  cursor: default;
  text-transform: none;
  white-space: normal;
  text-align: left;
}

// See component docstring for details on what these are used for
.g-onboarding-tooltip {
    position: relative;

    &__portal {
        @include reset-functions;
        position: absolute;
        padding: 12px;
        z-index: 3;
        text-transform: initial;
        top: 0;
        left: 0;
    }

    &__text {
        display: flex;
        flex-direction: column;
    }

    &__content {
        background-color: colors.$secondary;
        border-radius: 4px;
        padding: 16px;
    }

    &__arrow {
        @include reset-functions;
        position: absolute;
        transform: rotate(45deg);
        top: 0;
        left: 0;
        background-color: colors.$secondary;
        // These come from the mockup :shrug:
        width: 12.02px;
        height: 12.02px;
        z-index: 4;
    }
}

</style>
