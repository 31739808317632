<template>
  <v-form
    ref="securityForm"
    v-model="valid"
    :disabled="!editing"
  >
    <v-card
      shaped
      max-width="750px"
      class="mx-auto"
    >
      <v-card-subtitle>
        <h6 class="text-h6">
          Security Questions
        </h6>
      </v-card-subtitle>
      <v-card-text>
        <security-question-row
          v-for="index in 5"
          :key="index"
          v-model="answers[index - 1]"
          :editing="editing"
          :selected-question="questions[index - 1]"
          :question-list="questionList"
          :rules="answerRules"
          :counter="answerLength"
          @unselected-question="unselectedQuestions"
          @selected-question="selectedQuestion(index - 1, ...arguments)"
          @set-questions-list="setQuestionsList"
        />
      </v-card-text>
      <v-card-actions class="pa-4">
        <template v-if="editing">
          <v-spacer />
          <v-btn
            :disabled="firstTime"
            text
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="saveSecurityQuestions"
          >
            Save
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            color="primary"
            @click="enableEdit"
          >
            Reset Security Questions
          </v-btn>
        </template>
      </v-card-actions>
      <v-card-subtitle>
        <h6 class="text-h6">
          Password
        </h6>
      </v-card-subtitle>
      <v-card-actions class="pa-4">
        <v-btn
          color="primary"
          href="/password_change/"
        >
          Change Password
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="verificationOpen"
      max-width="700px"
      width="100%"
    >
      <v-card v-if="verificationOpen">
        <v-card-title>Security Questions</v-card-title>
        <v-card-text class="text--primary body-1">
          <p class="mb-4">
            Your security questions are now set. Please remember your answers
            for future use.
          </p>
          <v-row
            v-for="index in 5"
            :key="index"
            dense
          >
            <v-col
              cols="12"
              sm="8"
            >
              <b>Question</b>: {{ questions[index - 1].question_string }}
            </v-col>
            <v-col><b>Answer</b>: {{ answers[index - 1] }}</v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="resetForm"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import SecurityQuestionRow from './SecurityQuestionRow.vue';
import {
  maxLengthRule,
  minimumLengthRule,
  requiredValueRule,
} from '../../helpers/rules';

export default {
  name: 'Security',
  components: {
    SecurityQuestionRow,
  },
  data() {
    return {
      verificationOpen: false,
      editing: false,
      loading: true,
      questions: [null, null, null, null, null],
      answers: ['', '', '', '', ''],
      valid: false,
      answerLength: 50,
      answerRules: [
        requiredValueRule('Answer'),
        minimumLengthRule('Answer', 3),
        maxLengthRule('Answer', 50),
      ],
      questionList: [
        { id: 0, question_string: 'q1' },
        { id: 1, question_string: 'q2' },
        { id: 2, question_string: 'q3' },
        { id: 3, question_string: 'q4' },
        { id: 4, question_string: 'q5' },
        { id: 5, question_string: 'q6' },
      ],
      firstTime: false, // indicates if this is the first time setting security questions
    };
  },
  async mounted() {
    await this.loadSecurityQuestions();
    await this.loadOwnSecurityQuestions();
    this.loading = false;
    if (this.questions.length === 0) this.enableEdit();
  },
  methods: {
    selectedQuestion(index, question) {
      this.questions[index] = question;
    },
    setQuestionsList(questions) {
      this.questionList = questions;
    },
    async loadOwnSecurityQuestions() {
      const data = await API.readOwnSecurityQuestions();
      this.firstTime = true;
      this.questions = data;
    },
    async loadSecurityQuestions() {
      const data = await API.readSecurityPrompts();
      this.questionList = data;
    },
    async saveSecurityQuestions() {
      const payload = [];
      try {
        this.questions.forEach((q, index) => payload.unshift({
          question_id: q.id,
          answer: this.answers[index],
        }));
        const response = await API.setOwnSecurityQuestions(payload);
        this.$snackbar.success(response.message);
        this.verificationOpen = true;
      } catch (e) {
        this.$snackbar.error(e);
      }
    },
    async cancel() {
      this.editing = false;
      await this.loadOwnSecurityQuestions();
    },
    resetForm() {
      this.verificationOpen = false;
      this.answers = ['', '', '', '', ''];
      this.editing = false;
    },
    unselectedQuestions(questions) {
      this.questionList.push(...questions);
    },

    enableEdit() {
      this.editing = true;
      this.resetValidation();
    },

    resetValidation() {
      this.$refs.securityForm.resetValidation();
    },
  },
};
</script>
