<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    @submit.prevent="handleSubmit"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text class="pt-3">
        <v-text-field
          v-model="content.name"
          :value="content.name"
          label="Name"
          outlined
          required
          maxlength="255"
          hide-details="auto"
          :rules="[requiredValueRule('Name')]"
          autofocus
        />

        <v-row
          class="mt-6"
          dense
        >
          <v-col
            v-if="!isInternal"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.number="content.share_monthly_limit"
              :value="content.share_monthly_limit"
              label="Monthly Sharing Limit"
              outlined
              required
              hide-details="auto"
              type="number"
              :rules="[integerRule('Share limit'), rangeRule('Share limit', 0)]"
            />
          </v-col>

          <v-col cols="12">
            <v-row dense>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="hasAudio"
                  label="Enable Text-to-Speech"
                />
                <v-checkbox
                  v-if="!isProduction"
                  v-model="isRatingEnabled"
                  class="mt-0"
                  label="Enable Rating"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-img
                  v-if="iconUrlValue"
                  alt="Product Image"
                  :src="iconUrlValue"
                  class="my-2 rounded"
                  transition="scale-transition"
                  height="11rem"
                />
                <v-file-input
                  :value="imageUrl"
                  label="Upload Image"
                  show-size
                  accept="image/*"
                  outlined
                  class="mt-4"
                  :clearable="false"
                  hide-details="auto"
                  prepend-icon="mdi-camera"
                  :rules="imageUploadRules"
                  @change="onImageChange"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="handleCancelClick"
        >
          Cancel
        </v-btn>

        <v-btn
          text
          type="submit"
          color="primary"
          :disabled="!isFormValid || sendingRequest"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import {
  requiredValueRule,
  numericRule,
  integerRule,
  rangeRule,
  maxLengthRule,
  fileIsImageRule,
  fileSizeRule,
} from '../../helpers/rules';

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isFormValid: false,
      isFileInputDirty: false,
      iconUrlValue: '',
      imageUploadRules: [
        fileIsImageRule(),
        fileSizeRule(1),
      ],
      imageUrl: new File([], 'load.svg'),
      sendingRequest: false,
    };
  },

  computed: {
    isInternal() {
      return this.content.is_internal;
    },

    isRatingEnabled: {
      get() {
        return this.content.rating_enabled;
      },
      set(value) {
        this.content.rating_enabled = value;
      },
    },

    hasAudio: {
      get() {
        return this.content.has_audio;
      },
      set(value) {
        this.content.has_audio = value;
      },
    },
    ...mapState('app', ['isProduction']),
  },

  mounted() {
    this.$refs.form.resetValidation();
    this.loadFileObject();
  },

  methods: {
    requiredValueRule,
    numericRule,
    integerRule,
    rangeRule,
    maxLengthRule,

    loadFileObject() {
      const data = fetch(this.content.image)
        .then((res) => {
          if (!res.ok) {
            this.iconUrlValue = '/static/dashboard/templates/content-placeholder.svg';
          } else {
            this.iconUrlValue = this.content.image;
          }
          return res.blob();
        });
      const metadata = {
        type: 'image/svg',
      };
      this.imageUrl = new File([data], 'default.svg', metadata);
    },

    onImageChange(e) {
      if (e) {
        this.imageUrl = e;
        let iconUrl;
        if (!this.imageUrl) iconUrl = null;
        if (this.imageUrl.type.includes('image')) iconUrl = URL.createObjectURL(this.imageUrl);
        this.iconUrlValue = iconUrl;
        this.isFileInputDirty = true;
      }
    },

    handleSubmit() {
      this.sendingRequest = true;
      const content = { ...this.content };
      content.name = this.content.name;

      if (this.isFileInputDirty) {
        content.image = this.imageUrl;
      } else {
        delete content.image;
      }

      this.$emit('save', content);
    },

    handleCancelClick() {
      this.$emit('cancel', this.content);
    },
  },
};
</script>
