const routes = [
  {
    path: '/salesforce/admin/',
    meta: {
      title: 'Salesforce Admin',
    },
  },
  {
    path: '/salesforce/credentials/',
    meta: {
      title: 'Salesforce Credentials',
    },
  },
];

export default routes;
