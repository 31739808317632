export function registerSpeakIcon(editor) {
  editor.ui.registry.addIcon('speak-icon', '<svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 13c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H3zM15.08 7.05c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27l-1.68 1.69zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"/></svg>');
}

export function isSelectedNodeImage(editor) {
  return editor.selection.getNode().nodeName === 'IMG';
}

export function generateSpeakDialog(editor) {
  return {
    title: 'Customize Speech',

    buttons: [
      { type: 'cancel', text: 'Cancel' },
      { type: 'submit', text: 'Save' },
    ],

    initialData: {
      display: isSelectedNodeImage(editor) ? 'Image' : editor.selection.getContent(),
      speech: isSelectedNodeImage(editor) ? editor.selection.getNode().getAttribute('alt') : editor.selection.getContent(),
    },

    onSubmit(dialog) {
      const { display, speech } = dialog.getData();
      if (isSelectedNodeImage(editor)) editor.selection.getNode().setAttribute('alt', speech);
      else editor.execCommand('mceInsertContent', false, `[(${display})(${speech})]`);
      dialog.close();
    },

    body: {
      type: 'panel',

      items: [
        // eslint-disable-next-line object-curly-newline
        { type: 'input', name: 'display', label: 'Display', disabled: isSelectedNodeImage(editor) },
        { type: 'input', name: 'speech', label: 'Speech' },
      ],
    },
  };
}

export function registerSpeakButton(editor) {
  editor.ui.registry.addButton('speak', {
    icon: 'speak-icon',
    tooltip: 'Customize speech',

    onAction() {
      editor.windowManager.open(generateSpeakDialog(editor));
    },
  });
}
