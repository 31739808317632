/* eslint-disable camelcase */
import Vue from 'vue';
import { sendGMAChatMessage } from '@/api/gmaModule';

function attachDefaultMessages(conversations) {
  // Simplifies Vue reactivity if the property exists
  Object.values(conversations).forEach((c) => {
    c.messages = []; // eslint-disable-line no-param-reassign
  });
}

function countUnreadMessages(conversations) {
  return Object.values(conversations).reduce((acc, current) => acc + current.unread_count, 0);
}

export default {
  state: () => ({
    contacts: [],
    conversations: {},
    searchMembers: [],
    searchEmails: [],
    unreadMessagesCount: 0,
    activeConversationID: -1,
    isChatWindowLoading: false,
    isChatWindowLoadingMore: false,
    isConversationListLoading: false,
    isContactListLoading: false,
    isSendingMessage: false,
    isInfoDrawerOpen: true,
    isAddMemberDrawerOpen: false,
    showSearchBar: false,
    isMessengerSearchLoading: false,
    chatSearchText: null,
    searchResults: {},
    searchActiveMessage: null,
    activeConversationLastMessageId: null,
    chatAreaScrollTop: 0,
  }),

  mutations: {
    setSearchActiveMessage(state, messageId) {
      state.searchActiveMessage = messageId;
    },

    setChatAreaScrollTop(state, value) {
      state.chatAreaScrollTop = value;
    },

    setActiveConversationLastMessageId(state, value) {
      state.activeConversationLastMessageId = value;
    },

    setSearchResults(state, value) {
      state.searchResults = value;
    },

    setChatSearchText(state, value) {
      state.chatSearchText = value;
    },

    setShowSearchBar(state, value) {
      state.showSearchBar = value;
    },

    setIsMessengerSearchLoading(state, value) {
      state.isMessengerSearchLoading = value;
    },

    setContacts(state, contacts) {
      state.contacts = contacts;
    },

    addContact(state, contact) {
      state.contacts.push(contact);
    },

    deleteContact(state, id) {
      const contactIndex = state.contacts.findIndex((contact) => contact.id === id);
      if (contactIndex >= 0) state.contacts.splice(contactIndex, 1);

      // Remove any deleted contacts from the member search list
      const contactMemberListIndex = state.searchMembers.findIndex((member) => member === id);
      if (contactMemberListIndex >= 0) state.searchMembers.splice(contactMemberListIndex, 1);
    },

    addSearchEmail(state, email) {
      state.searchEmails.push(email);
    },

    deleteSearchEmail(state, email) {
      const emailIndex = state.searchEmails.indexOf(email);
      if (emailIndex >= 0) state.searchEmails.splice(emailIndex, 1);
    },

    addSearchMember(state, member) {
      state.searchMembers.push(member);
    },

    deleteSearchMember(state, index) {
      state.searchMembers.splice(index, 1);
    },

    deleteAllTemporaryContacts(state) {
      state.contacts = state.contacts.filter((contact) => contact.id >= 0);
    },

    setConversations(state, conversations) {
      // eslint-disable-next-line no-param-reassign
      if (state.conversations[-2]) conversations[-2] = state.conversations[-2];
      state.conversations = conversations;
    },

    addConversation(state, conversation) {
      // Don't add conversations that are already loaded
      if (conversation.id in state.conversations) return;

      attachDefaultMessages([conversation]);
      Vue.set(state.conversations, conversation.id, conversation);
    },

    deleteConversation(state, id) {
      Vue.delete(state.conversations, id);
    },

    setConversationName(state, { id, name }) {
      Vue.set(state.conversations[id], 'name', name ?? '');
    },

    setConversationPicture(state, { id, url }) {
      Vue.set(state.conversations[id], 'picture', url ?? '');
    },

    setSearchMembers(state, members) {
      state.searchMembers = members;
    },

    setSearchEmails(state, emails) {
      state.searchEmails = emails;
    },

    setUnreadMessagesCount(state, count) {
      state.unreadMessagesCount = count;
    },

    addUnreadMessagesCount(state, count) {
      state.unreadMessagesCount += count;
    },

    signalNewMessage() {
    },

    setActiveConversationID(state, id) {
      state.activeConversationID = id;
      state.activeConversationLastMessageId = null;
    },

    setConversationMessages(state, {
      id, messages, more,
    }) {
      if (messages.length > 0) {
        state.conversations[id].messages = messages;
      }
      Vue.set(state.conversations[id], 'more', more);
    },

    setConversationMessagesAdvance(state, {
      id, messages, moreMessagesAfter, more = undefined,
    }) {
      if (messages.length > 0) {
        state.conversations[id].messages = messages;
      }
      Vue.set(state.conversations[id], 'moreMessagesAfter', moreMessagesAfter);
      if (typeof more !== 'undefined') {
        Vue.set(state.conversations[id], 'more', more);
      }
    },

    addConversationMessagesAdvance(state, {
      id, messages, moreMessagesAfter, more = undefined,
    }) {
      if (messages.length > 0) {
        state.conversations[id].messages.push(...messages);
      }
      Vue.set(state.conversations[id], 'moreMessagesAfter', moreMessagesAfter);
      if (typeof more !== 'undefined') {
        Vue.set(state.conversations[id], 'more', more);
      }
    },

    addConversationSingleMessage(state, { id, message }) {
      state.conversations[id].messages.push(message);
    },

    setConversationLastMessage(state, { id, message }) {
      state.conversations[id].last_message = message;
    },

    updateConversationMember(state, member) {
      const memberPreUpdate = state.conversations[member.conversation].members.find((m) => m.user === member.user);
      Vue.set(member, Object.assign(memberPreUpdate, member));
    },

    setConversationMemberHasLeft(state, { conversationID, userID, hasLeft }) {
      const member = state.conversations[conversationID].members.find((m) => m.user === userID);
      Vue.set(member, 'has_left', hasLeft);
    },

    addConversationMembers(state, { conversation, members }) {
      Vue.set(state.conversations[conversation], 'members', state.conversations[conversation].members.concat(members));
    },

    deleteConversationMember(state, { conversation, member }) {
      Vue.set(state.conversations[conversation], 'members', state.conversations[conversation].members.filter((m) => m.user !== member));
    },

    setConversationUnreadCount(state, { id, count }) {
      state.conversations[id].unread_count = count;
    },

    addConversationUnreadCount(state, { id, count }) {
      state.conversations[id].unread_count += count;
    },

    setIsChatWindowLoading(state, value) {
      state.isChatWindowLoading = value;
    },

    setIsChatWindowLoadingMore(state, value) {
      state.isChatWindowLoadingMore = value;
    },

    setIsConversationListLoading(state, value) {
      state.isConversationListLoading = value;
    },

    setIsContactListLoading(state, value) {
      state.isContactListLoading = value;
    },

    setIsSendingMessage(state, value) {
      state.isSendingMessage = value;
    },

    setIsInfoDrawerOpen(state, value) {
      state.isInfoDrawerOpen = value;
    },

    toggleInfoDrawerOpen(state) {
      state.isInfoDrawerOpen = !state.isInfoDrawerOpen;
    },

    setIsAddMemberDrawerOpen(state, value) {
      state.isAddMemberDrawerOpen = value;
    },

    signalResetMessageState() { },
  },

  getters: {
    userID: (state, getters, rootState) => rootState.user.id,

    isConversationPending: (state, getters) => (id) => state.conversations[id].members
      .find((member) => member.user === getters.userID).has_accepted === false,

    hasConversationPendingMembers: (state) => (id) => state.conversations[id].members
      .some((m) => m.has_accepted === false),

    sortedConversations: (state, getters) => Object.values(state.conversations).sort((a, b) => {
      // New conversation
      if (a.id === -2) return -1;
      if (b.id === -2) return 1;

      // Regular sort
      const nameA = getters.getConversationName(a.id);
      const nameB = getters.getConversationName(b.id);
      const timeA = new Date(getters.getConversationTimestamp(a.id) ?? 0);
      const timeB = new Date(getters.getConversationTimestamp(b.id) ?? 0);
      return timeB - timeA || nameA.localeCompare(nameB);
    }),

    directConversations: (state, getters) => getters.sortedConversations
      .filter((c) => c.members.length === 2 || c.id === -2),

    groupConversations: (state, getters) => getters.sortedConversations
      .filter((c) => c.members.length > 2 || c.id === -2),

    pendingConversations: (state, getters) => getters.sortedConversations
      .filter((c) => c.members.some((m) => m.has_accepted === false)),

    activeConversation: (state) => {
      if (state.activeConversationID === -1) {
        return null;
      }
      const conv = state.conversations[state.activeConversationID];
      if (conv) {
        return {
          ...conv,
          isDirect: conv.members.length === 2,
        };
      }
      return conv;
    },

    getConversationOtherMembers: (state, getters) => (id) => {
      const conversation = state.conversations[id];
      return conversation.members.filter((member) => member.user !== getters.userID);
    },

    getConversationName: (state, getters) => (id) => {
      if (id === -2) return 'New Conversation';

      const conversation = state.conversations[id];
      const otherMembers = getters.getConversationOtherMembers(id)
        .filter((member) => member.has_left === false);
      const validMembers = otherMembers.filter((m) => otherMembers.length === 1 || m.has_accepted === true);

      if (conversation.name.length > 0) {
        return conversation.name;
      }

      if (validMembers.length === 1) {
        return validMembers[0].name;
      }

      if (validMembers.length === 2) {
        return validMembers
          .map((member) => member.first_name ?? member.name)
          .join(' and ');
      }

      if (validMembers.length > 2) {
        let memberNames = validMembers
          .slice(0, 2)
          .map((member) => member.first_name ?? member.name)
          .join(', ')
          .concat(`, and ${validMembers[2].first_name ?? validMembers[2].name}`);

        if (validMembers.length > 3) {
          memberNames = memberNames.concat(` +${conversation.members.length - 3}`);
        }

        return memberNames;
      }

      return 'Conversation';
    },

    /**
     * Checks if the conversation is a one-on-one conversation with a support group member.
     */
    isSupportConversation: (state, getters) => (id) => {
      if (id === -2) return false;
      const conversation = state.conversations[id];
      if (conversation.name && conversation.name.length > 0) {
        return false;
      }
      const otherMembers = getters.getConversationOtherMembers(id)
        .filter((member) => member.has_left === false);
      const validMembers = otherMembers.filter((m) => otherMembers.length === 1 || m.has_accepted === true);
      return validMembers.length === 1 && 'support' in validMembers[0];
    },

    getConversationMemberAvatars: (state, getters) => (id) => getters
      .getConversationOtherMembers(id).map((member) => member.picture),

    getConversationTimestamp: (state) => (id) => {
      const conversation = state.conversations[id];
      return conversation.last_message.timestamp ?? conversation.timestamp ?? null;
    },

    getConversationLastMessage: (state, getters) => (id) => {
      if (getters.isConversationPending(id)) {
        return 'Pending chat request';
      }

      const conversation = state.conversations[id];

      if (conversation.last_message) {
        let sender;
        let text;
        let attachment;

        if (conversation.last_message?.sender?.user === getters.userID) {
          sender = 'You';
        } else if (conversation.members.length > 2 && conversation.last_message.sender) {
          sender = `${conversation.last_message.sender.first_name ?? conversation.last_message.sender.name}`;
        } else {
          sender = '';
        }

        if (conversation.last_message?.text?.length > 0) {
          text = conversation.last_message.text;
          // Special case for action messages
          if (conversation.last_message?.is_action) {
            return text;
          }
        } else {
          text = '';
        }

        if (conversation.last_message?.attachments?.length > 0 && text.length === 0) {
          attachment = 'sent an attachment';
        } else {
          attachment = '';
        }

        // Joshua sent an attachment | You sent an attachment
        if (sender.length > 0 && attachment.length > 0) {
          return `${sender} ${attachment}`;
        }

        // Sent an attachment
        if (attachment.length > 0) {
          return attachment.charAt(0).toUpperCase() + attachment.slice(1);
        }

        // Joshua: Hello there | You: Hello there
        if (sender.length > 0 && text.length > 0) {
          return `${sender}: ${text}`;
        }

        // Hello there
        return text;
      }
      return '';
    },

    sortedContacts: (state) => state.contacts
      .filter((c) => c.contact_type !== 'email')
      .sort((a, b) => (a.name).localeCompare((b.name))),

    accountContacts: (state, getters) => getters.sortedContacts
      .filter((c) => c.contact_type === 'organization'),

    personalContacts: (state, getters) => getters.sortedContacts
      .filter((c) => c.contact_type === 'personal'),

    canMemberChat: (state, getters) => {
      if (getters?.activeConversation?.members && getters?.activeConversation?.members.length > 0) {
        const [activeConversationMember] = getters.activeConversation.members
          .filter((member) => member.user === getters.userID);

        return !activeConversationMember.has_left || getters.activeConversation.id < 0;
      }
      return false;
    },

    // controls whether the user can send an email in chat to invite a user to grainfox, rather than messaging existing users.
    canSendEmail: (state, getters, rootState, rootGetters) => !rootState.user.isGMA,
  },

  actions: {
    async leaveConversation({ commit, getters }, { conversationID }) {
      try {
        const { hasLeft } = await API.leaveConversation(conversationID);
        const { userID } = getters;
        commit('setConversationMemberHasLeft', { conversationID, userID, hasLeft });
      } catch (e) {
        this._vm.$snackbar.error(e.message);
      }
    },

    async loadContacts({ state, commit, rootState }) {
      if (state.isContactListLoading) return;

      commit('setIsContactListLoading', true);
      try {
        let contacts = [];
        if (rootState.user.isGMA) {
          contacts = await API.getGMAContacts();
        } else {
          contacts = await API.getContacts();
        }

        commit('setContacts', contacts);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setIsContactListLoading', false);
      }
    },

    async loadSingleConversation({ commit }, conversationID) {
      try {
        const conversation = await API.getConversation(conversationID);
        commit('addConversation', conversation);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async loadConversations({ commit }) {
      commit('setIsConversationListLoading', true);
      try {
        const conversations = await API.getConversations();
        attachDefaultMessages(conversations);
        commit('setUnreadMessagesCount', countUnreadMessages(conversations));
        commit('setConversations', conversations);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setIsConversationListLoading', false);
      }
    },

    async loadConversationAdvance({ commit, state, getters }, {
      id, number_offset, time_offset,
    }) {
      // Exit early for invalid conversation IDs
      if (!(id in state.conversations) && (id !== -1)) {
        this._vm.$snackbar.error('Conversation not found');
        return;
      }

      // Clean up previous active conversation
      if (state.activeConversationID !== -1) {
        commit('setConversationMessagesAdvance', {
          id: state.activeConversationID,
          messages: [],
          moreMessagesAfter: false,
          more: false,
        });
        commit('setChatAreaScrollTop', 0);
      }

      // Remove draft conversation
      if (state.activeConversationID === -2) {
        commit('deleteConversation', -2);
      }

      // Set the new active conversation
      const isSameConversation = id === state.activeConversationID;
      commit('setActiveConversationID', id);

      // Stop if conversation was unloaded
      if (id === -1) {
        return;
      }

      // Set loading state
      commit('setIsChatWindowLoading', true);

      // Clear unread message count
      commit('addUnreadMessagesCount', -state.conversations[id].unread_count);
      commit('setConversationUnreadCount', { id, count: 0 });

      if (getters.isConversationPending(id) === false) {
        try {
          const {
            results: messages,
            more_messages_after: moreMessagesAfter,
            more,
          } = await API.getConversationMessagesAdvance(id, number_offset, time_offset);
          commit('setConversationMessagesAdvance', {
            id,
            messages,
            moreMessagesAfter,
            more,
          });
        } catch (e) {
          this._vm.$snackbar.error(e);
        }
      }
      commit('setIsChatWindowLoading', false);

      // If the conversation being loaded differs from
      // the one that was already active, we should
      // reset the message input state
      if (!isSameConversation) {
        commit('signalResetMessageState');
      }
    },

    async loadConversation({ commit, state, getters }, {
      id, timestamp, lte,
    }) {
      // Exit early for invalid conversation IDs
      if (!(id in state.conversations) && (id !== -1)) {
        this._vm.$snackbar.error('Conversation not found');
        return;
      }

      // Clean up previous active conversation
      if (state.activeConversationID !== -1 && !lte) {
        commit('setConversationMessages', {
          id: state.activeConversationID,
          messages: [],
          more: false,
        });
      }

      // Remove draft conversation
      if (state.activeConversationID === -2) {
        commit('deleteConversation', -2);
      }

      // Set the new active conversation
      const isSameConversation = id === state.activeConversationID;
      commit('setActiveConversationID', id);

      // Stop if conversation was unloaded
      if (id === -1) {
        return;
      }

      // Set loading state
      if (!lte) {
        commit('setIsChatWindowLoading', true);
      }
      // Clear unread message count
      commit('addUnreadMessagesCount', -state.conversations[id].unread_count);
      commit('setConversationUnreadCount', { id, count: 0 });

      if (getters.isConversationPending(id) === false) {
        try {
          const { results: messages, more } = await API.getConversationMessages(id, timestamp, lte);
          const lastMessage = messages[messages.length - 1] ?? {};
          if (lte) {
            commit('addConversationMessagesAdvance', {
              id,
              messages,
              moreMessagesAfter: more,
            });
          } else {
            commit('setConversationMessages', {
              id,
              messages,
              more,
            });
          }
          commit('setConversationLastMessage', { id, message: lastMessage });
        } catch (e) {
          this._vm.$snackbar.error(e);
        }
      }
      if (!lte) {
        commit('setIsChatWindowLoading', false);
      }
      // If the conversation being loaded differs from
      // the one that was already active, we should
      // reset the message input state
      if (!isSameConversation) {
        commit('signalResetMessageState');
      }
    },

    loadConversationByContact({ commit, dispatch, getters }, id) {
      const existingConversation = getters.directConversations.find((c) => c.id === c.members.find((m) => m.user === id)?.conversation);
      if (existingConversation) {
        dispatch('loadConversation', {
          id: existingConversation.id,
          timestamp: null,
        });
      } else {
        dispatch('createNewConversationDraft');
        commit('setSearchMembers', [id]);
      }
    },

    deleteConversation({ state, commit, dispatch }, id) {
      if (state.activeConversationID === id) {
        dispatch('loadConversation', {
          id: -1,
          timestamp: null,
        });
      }

      commit('deleteConversation', id);
    },

    async loadMoreMessages({ commit, state }, { id, timestamp }) {
      commit('setIsChatWindowLoadingMore', true);
      try {
        const { results: messages, more } = await API.getConversationMessages(id, timestamp);
        commit('setConversationMessages', { id, messages: [...messages, ...state.conversations[id].messages], more });
        commit('setIsChatWindowLoadingMore', false);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setIsChatWindowLoadingMore', false);
      }
    },

    async sendNewMessage({ state, rootState, commit }, {
      id, text, attachments, message_type,
    }) {
      commit('setIsSendingMessage', true);

      const formData = new FormData();
      if (id) {
        formData.append('conversation', id);
      } else {
        formData.append('members', JSON.stringify(state.searchMembers.filter((memberId) => memberId > 0)));
      }

      formData.append('text', text);
      if (message_type) {
        formData.append('message_type', message_type);
      }

      // Note: newMessageAttachments is a single file for now, hence the []
      // but it will be an array when we fully support multiple attachments
      attachments.forEach((file) => {
        formData.append('attachments', file);
      });

      try {
        if (rootState.user.isGMA) {
          const { conversation } = await sendGMAChatMessage(formData);
          return conversation;
        }
        return await API.sendChatMessage(formData);
      } catch (e) {
        this._vm.$snackbar.error(e);
        throw e;
      } finally {
        commit('setIsSendingMessage', false);
      }
    },

    onNewMessage({
      commit, state, getters, dispatch,
    }, message) {
      const { conversation: id } = message;
      // Signal new message for triggering events for users that received the message
      if (message.sender.user !== getters.userID) {
        dispatch('newMessageAlert', state.activeConversationID === id);
      }
      // Add message to active conversation
      if (state.activeConversationID === id) {
        commit('addConversationSingleMessage', {
          id,
          message,
        });
      }

      // Update conversation last message if
      // the conversation is present in store
      if (state.conversations[id]) {
        commit('setConversationLastMessage', {
          id,
          message,
        });
      }

      // Increment unread count for inactive conversation
      if (state.activeConversationID !== id && message.sender.user !== getters.userID) {
        commit('addUnreadMessagesCount', 1);

        // Increment conversation unread count if
        // the conversation is present in store
        if (state.conversations[id]) {
          commit('addConversationUnreadCount', {
            id,
            count: 1,
          });
        }
      }
    },

    updateInteraction({ state }, id) {
      if (state.activeConversationID === id) {
        API.updateInteraction(id);
      }
    },

    createNewConversationDraft({ commit, getters, dispatch }) {
      dispatch('resetAllSearchMembers');

      const conversation = {
        id: -2,
        name: '',
        last_message: {
          timestamp: null,
        },
        members: [
          {
            name: '',
            has_accepted: true,
            user: getters.userID,
          },
        ],
        messages: [],
        unread_count: 0,
      };
      commit('addConversation', conversation);
      commit('setActiveConversationID', conversation.id);
      commit('setIsChatWindowLoading', false);
    },

    async createNewConversation({
      state, commit, dispatch, getters,
    }, {
      text,
      attachments,
      message_type,
    }) {
      // get non-placeholder members
      const validMembers = state.searchMembers.filter((memberId) => memberId > 0);

      const formData = new FormData();
      validMembers.forEach((member) => formData.append('members', member));
      state.searchEmails.forEach((email) => formData.append('emails', email));

      formData.append('text', text);
      [attachments].forEach((file) => {
        formData.append('attachments', file);
      });
      if (message_type) {
        formData.append('message_type', message_type);
      }

      // Load normal conversation (Member exist in organization)
      if (validMembers.length === 1 && state.searchEmails.length === 0) {
        const id = validMembers[0];
        const existingConversation = getters.directConversations
          .find((c) => c.id === c.members.find((m) => m.user === id)?.conversation);
        if (existingConversation) {
          await dispatch('loadConversation', {
            id: existingConversation.id,
            timestamp: null,
          });
          dispatch('sendNewMessage', {
            id: existingConversation.id, text, attachments, message_type,
          });
          return;
        }
      }

      // Load email conversation (Member may not exist in organization)
      if (state.searchEmails.length === 1 && validMembers.length === 0) {
        const email = state.searchEmails[0];
        const existingConversation = getters.directConversations
          .find((c) => c.id === c.members.find((m) => m.name === email)?.conversation);
        if (existingConversation) {
          await dispatch('loadConversation', {
            id: existingConversation.id,
            timestamp: null,
          });
          dispatch('sendNewMessage', {
            id: existingConversation.id, text, attachments, message_type,
          });
          return;
        }
      }

      try {
        const conversation = await API.createConversation(formData);

        dispatch('resetAllSearchMembers');
        commit('addConversation', conversation);
        dispatch('loadConversation', {
          id: conversation.id,
          timestamp: null,
        });
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async addMembersToConversation({ state, commit, dispatch }) {
      const formData = new FormData();
      state.searchMembers.forEach((member) => formData.append('members', member));
      state.searchEmails.forEach((email) => formData.append('emails', email));
      formData.append('conversation', state.activeConversationID);

      try {
        await API.addMembersToConversation(formData);
        dispatch('resetAllSearchMembers');
        commit('setIsAddMemberDrawerOpen', false);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async onAddMembersToConversation({ state, commit, dispatch }, { conversation, members }) {
      // If the conversation is already in your list,
      // all we need to do is add the new members
      if (conversation in state.conversations) {
        commit('addConversationMembers', { conversation, members });
        return;
      }
      // Otherwise, we need to load the new conversation
      await dispatch('loadSingleConversation', conversation);
    },

    async acceptChatRequest({
      commit,
      getters,
      dispatch,
      rootState,
    }, { id, timestamp }) {
      try {
        await API.acceptChatRequest(id);

        commit('updateConversationMember', {
          conversation: id,
          user: getters.userID,
          first_name: rootState.user.firstName,
          name: rootState.user.fullName,
          picture: rootState.user.picture,
          has_accepted: true,
        });
        dispatch('loadConversation', {
          id,
          timestamp,
        });
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async declineChatRequest({ dispatch }, { id }) {
      try {
        await API.declineChatRequest(id);
        dispatch('deleteConversation', id);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async blockUser({ commit }, user) {
      try {
        const formData = new FormData();
        formData.append('user', user);
        const message = await API.blockUser(formData);

        commit('deleteContact', user);

        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    resetAllSearchMembers({ commit, dispatch }) {
      commit('setSearchMembers', []);
      dispatch('resetTemporarySearchMembers');
    },

    resetTemporarySearchMembers({ commit }) {
      commit('setSearchEmails', []);
      commit('deleteAllTemporaryContacts');
    },

    removeSearchMember({ commit, state }, member) {
      const memberIndex = state.searchMembers.indexOf(member.id);
      if (memberIndex >= 0) {
        if (member.contact_type === 'email') {
          // Remove from list of emails
          commit('deleteSearchEmail', member.name);

          // Remove temporary email from items
          commit('deleteContact', member.id);
        } else {
          commit('deleteSearchMember', memberIndex);
        }
      }
    },

    tryAddEmail({ commit, state }, email) {
      commit('addSearchEmail', email);
      const id = Math.min(...(state.searchMembers ?? [0]), 0) - 1;

      commit('addContact', {
        id,
        name: email,
        contact_type: 'email',
        picture: null,
      });

      commit('addSearchMember', id);
    },

    newMessageAlert({ commit }, active) {
      // Signal if conversation not active, or, if active, signal if tab is currently hidden.
      if (!active || (document.hidden || document.msHidden || document.webkitHidden || document.mozHidden)) {
        commit('signalNewMessage');
      }
    },

    openAddMemberDialog({ commit }) {
      commit('setIsAddMemberDrawerOpen', true);
    },

    async reconnect({ state, dispatch }) {
      if (state.activeConversationID >= 0) {
        dispatch('loadConversation', {
          id: state.activeConversationID,
          timestamp: null,
        });
      }
    },
  },

  namespaced: true,
};
