import Vue from 'vue';
import VueRouter from 'vue-router';
import { datadogRum } from '@datadog/browser-rum';
import { decodeObject, isEmptyObject } from '@/helpers/transformations';

// Routes
import account from './account';
import activation from './activation';
import barchart from './barchart';
import budgeting from './budgeting';
import css from './css';
import farmProfile from './farm-profile';
import gma from './gma';
import login from './login';
import legal from './legal';
import marketing from './marketing';
import news from './news';
import oauth from './oauth';
import payments from './payments';
import resourceHub from './resource-hub';
import salesforce from './salesforce';
import user from './user';
import video from './video';
import insights from './powerbi-insights';
import analysisDashboard from './analysis-dashboard';

const Chat = () => import('@/components/chat/Chat.vue');
const Notifications = () => import('@/pages/notifications/Notifications.vue');
const Unsubscribe = () => import('@/pages/notifications/Unsubscribe.vue');
const WeatherDtn = () => import('@/pages/weather/WeatherDtn.vue');
const PriceMapper = () => import('@/pages/bids/PriceMapper.vue');
const BasePrice = () => import('@/components/bids/BasePrice.vue');
const Dashboard = () => import('@/pages/dashboard/Dashboard.vue');
const Sandbox = () => import('@/pages/sandbox/Sandbox.vue');
const OAuthHandler = () => import('@/pages/oauth/OAuthHandler.vue');
const IntegrationConnections = () => import('@/pages/oauth/IntegrationConnections.vue');

Vue.use(VueRouter);

// Ensure $metadata exists
Vue.prototype.$metadata = Vue.observable({
  pageTitle: '',
});

const routes = [
  ...account,
  ...activation,
  ...barchart,
  ...budgeting,
  ...css,
  ...farmProfile,
  ...legal,
  ...login,
  ...marketing,
  ...gma,
  ...news,
  ...oauth,
  ...payments,
  ...resourceHub,
  ...salesforce,
  ...user,
  ...video,
  ...insights,
  ...analysisDashboard,
  {
    path: '/dashboard/',
    meta: {
      title: 'Dashboard',
    },
    component: Dashboard,
  },
  {
    path: '/notifications/',
    meta: {
      title: 'Notifications',
    },
    component: Notifications,
  },
  {
    path: '/unsubscribe/',
    meta: {
      title: 'Unsubscribed',
    },
    component: Unsubscribe,
  },
  {
    path: '/weather/',
    meta: {
      title: 'Weather',
    },
    component: WeatherDtn,
  },
  {
    path: '/bids/map/',
    meta: {
      title: 'Price Map',
    },
    component: PriceMapper,
  },
  {
    path: '/dashboard/bids/base_price/',
    meta: {
      title: 'Base Price',
    },
    component: BasePrice,
  },
  {
    path: '/messenger/:convo?/',
    meta: {
      title: 'Messenger',
    },
    component: Chat,
    props: (route) => ({ routeConversationID: parseInt(route.params.convo, 10) || -1 }),
  },
  {
    path: '/sandbox/',
    meta: {
      title: 'Sandbox',
    },
    component: Sandbox,
  },
  {
    path: '/oauth_redirect/',
    component: OAuthHandler,
  },
  {
    path: '/oauth_redirect/ag_expert/',
    component: OAuthHandler,
  },
  {
    path: '/integration/connections/:clientId/',
    component: IntegrationConnections,
    props: true,
  },
];

/**
 * Add important properties and metadata to a route and its children
 * @param {object} route The route to be enhanced
 */
function enhanceRoutes(route) {
  /**
   * Enable Strict Matching
   * This configures the regex used for matching routes to be strict.
   * That means if characters like trailing slashes are present in the route path,
   * then the router must include them - this is used to match our Django URL convention.
   */
  route.pathToRegexpOptions = { strict: true }; // eslint-disable-line no-param-reassign

  /**
   * Add Scope Metadata
   * This adds a meta property called "scope" to a route.
   * In general, this will be the same as the route name, except when
   * using a default child route, the parent route cannot have a name.
   * This takes the default child route's name and assigns it to the parent's
   * scope property, which we can then use to identify the nesting hierarchy.
   * e.g. FarmProfile > FarmProfileFarms > FarmProfileFarmsArea
   */
  route.meta = { // eslint-disable-line no-param-reassign
    ...route.meta,
    scope: route.name ?? route?.children?.[0]?.name ?? '',
  };

  // Recursively enhance child routes
  if ('children' in route && Array.isArray([route.children])) {
    route.children.forEach((childRoute) => enhanceRoutes(childRoute));
  }
}
routes.forEach((route) => {
  enhanceRoutes(route);
});

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

function handleLinkTracking() {
  const tracking = router.currentRoute.query.ddt;
  if (tracking) {
    const decodedString = decodeObject(router.currentRoute.query.ddt); // ddt = datadog tracking
    if (!isEmptyObject(decodedString)) {
      const from = decodedString.from || 'unknown';
      const id = decodedString.id || 'unknown';
      const to = decodedString.to || 'unknown';
      datadogRum.addAction(`${from}`);
      datadogRum.addAction(`${id}-${to}`);
    }
  }
}

/**
 * Update Flutter Router after any navigation
 * event, including client-side navigation.
 */
function flutterUpdateURL() {
  if (window.flutterUpdateUrl) {
    const url = window.location.toString();
    window.flutterUpdateUrl.postMessage(JSON.stringify({ url }));
  }
}

/**
 * Updates the page title using route metadata.
 * @param {Object} to Destination route
 */
function updatePageTitle(to) {
  const titleValue = to?.matched?.[0]?.meta?.title ?? '';
  const titleParts = [titleValue, 'GrainFox'].filter((t) => t);
  const title = titleParts.join(' - ');

  Vue.prototype.$metadata.pageTitle = titleValue;
  document.title = title;
}

/**
 * Updates Appcues after a client-side navigation.
 */
function updateAppCues() {
  try {
    window.Appcues.page();
  } catch (e) {
    datadogRum.addError(new Error('Could not reach Appcues.'));
  }
}

/**
 * Updates the lockdown plugin after a client-side navigation.
 * @param {Object} to Destination route
 */
function updateLockdown(to) {
  router.app.$lockdown.process(to.path);
}

/**
 * Resets nav drawer to the closed state
 */
function resetNavDrawer() {
  router.app.$store?.commit('app/setNavDrawerState', false);
}

router.beforeEach((to, from, next) => {
  updateLockdown(to);
  resetNavDrawer();
  next();
});

router.onReady(() => {
  handleLinkTracking();
});

router.afterEach((to) => {
  updateAppCues();
  Vue.nextTick(() => {
    flutterUpdateURL();
    updatePageTitle(to);
  });
});

export default router;
