const PaymentTiers = () => import('@/pages/payments/PaymentTiers.vue');
const Billing = () => import('@/pages/payments/Billing.vue');
const Subscriptions = () => import('@/pages/payments/Subscriptions.vue');

const routes = [
  {
    path: '/commerce/tiers/',
    meta: {
      title: 'Subscription Plans',
    },
    component: PaymentTiers,
  },
  {
    /**
     * TODO UT-965
     * This should likely become a child route of the Account grouping,
     * if you notice the URL and where this page actually gets used.
     * Once that happens, the meta.title property here can be removed,
     * since we just need to define it on the parent grouping.
     */
    path: '/dashboard/organization/billing/',
    meta: {
      title: 'Account',
    },
    component: Billing,
  },
  /**
   * TODO UT-965
   * The same applies to this route; it should be a child route of Account.
   */
  {
    path: '/dashboard/organization/subscriptions/',
    meta: {
      title: 'Account',
    },
    component: Subscriptions,
  },
];

export default routes;
