<template>
  <component
    :is="componentType"
    v-model="isDialogOpen"
    :title="header"
    :disable-fullscreen="false"
    mobile-breakpoint="smAndDown"
    :no-toolbar="noToolbar"
  >
    <!-- desktop header -->
    <template #breadcrumbs>
      <FarmProfileBreadcrumbs v-if="!loading" />
    </template>
    <template #actions>
      <template v-for="(button, index) in actionButtons">
        <v-btn
          :key="`button-${index}`"
          :index="index"
          :class="button.class"
          :text="button.isText"
          :color="button.color"
          :loading="button.loading"
          :disabled="button.disabled"
          :data-qa="`button-${button.text}`"
          @click="$emit('process-click', button.type)"
        >
          <v-icon
            v-if="button.icon"
            class="mr-2"
            small
          >
            {{ button.icon }}
          </v-icon>
          {{ button.text }}
          <v-icon
            v-if="$isTablet"
            class="ml-1"
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </template>

    <!-- mobile widgets (tabs) -->
    <v-row
      v-if="$isMobile && hasWidgets"
    >
      <v-col class="pa-0">
        <v-tabs
          v-model="selectedWidget"
          align-tabs="center"
          class="fixed-tabs-bar"
          grow
        >
          <v-tab
            v-for="(widget, index) in widgets"
            :key="index"
            :value="index"
          >
            {{ widget.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="selectedWidget"
        >
          <v-tab-item
            v-for="(widget, index) in widgets"
            :key="index"
            :value="index"
            class="grey lighten-5"
          >
            <div
              class="mt-4"
            >
              <slot :name="widget.key" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!--  content -->
    <v-row justify="center">
      <v-col
        cols="12"
        :lg="hasWidgets ? desktopContentColWidth : 12"
        :md="hasWidgets ? 7 : 12"
        :class="$isTablet ? 'pt-3' : ''"
      >
        <v-card
          :class="$isMobile ? 'v-card--mobile-fill': 'my-4'"
          :flat="$isMobile"
          :tile="$isMobile"
          :loading="loading"
        >
          <!-- dynamic content -->
          <div v-if="!($isMobile && hasWidgets)">
            <slot :name="contentSlot" />
          </div>
        </v-card>
      </v-col>
      <!-- widgets -->
      <v-col
        v-if="hasWidgets && !$isMobile"
        cols="12"
        lg="4"
        md="5"
      >
        <v-card
          v-for="widget in widgets"
          :key="widget.key"
          class=" my-1 scroll mb-6"
          :class="{'shadowless': shadowless}"
        >
          <v-card-title class="text-h6 font-weight-regular">
            <slot :name="`${widget.key}-title`" />
          </v-card-title>
          <v-card-text>
            <slot
              :name="widget.key"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- popups -->
    <slot name="popups" />
  </component>
</template>

<script>
import GDialog from '../../components/generic/GDialog.vue';
import LayoutNested from './LayoutNested.vue';
import FarmProfileBreadcrumbs from '../../components/farm_profile/FarmProfileBreadcrumbs.vue';

export default {
  name: 'WidgetLayout',

  components: {
    GDialog,
    LayoutNested,
    FarmProfileBreadcrumbs,
  },

  props: {
    widgets: {
      type: Array,
      required: false,
      default: () => [],
    },
    allowPageScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    header: {
      type: String,
      required: false,
      default: '',
    },
    actionButtons: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    shadowless: {
      type: Boolean,
      default: false,
    },
    noToolbar: {
      type: Boolean,
      default: false,
    },
    desktopContentColWidth: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      selectedWidget: 0,
      isDialogOpen: true,
    };
  },
  computed: {
    componentType() {
      if (this.$isMobile) {
        return 'g-dialog';
      }
      return 'LayoutNested';
    },
    hasWidgets() {
      return this.widgets.length > 0;
    },

    contentSlot() {
      // dictates which slot [mobileContent, tabletContent, desktopContent] to use
      // based on the current screen size, plugged in named slots, and mobileFirst prop
      const slots = ['mobileContent', 'tabletContent', 'desktopContent'];
      if (this.mobileFirst) {
        slots.reverse();
      }

      // initialize slot name
      let slot = 'desktopContent';
      if (this.$isTablet) {
        slot = 'tabletContent';
      } else if (this.$isMobile) {
        slot = 'mobileContent';
      }

      // find the index of slot
      const index = slots.indexOf(slot);

      // if the slot is empty, find the next non-empty slot
      if (!this.$slots[slot]) {
        // iterate from the slot index to the beginning of the array
        for (let i = index + 1; i < slots.length; i += 1) {
          if (this.$slots[slots[i]]) {
            slot = slots[i];
            break;
          }
        }
      }

      return slot;
    },
  },

  watch: {
    isDialogOpen(val) {
      if (!val) {
        this.$emit('close');
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow-y: auto;
}
.v-tab--active::before {
  background-color: #FFFFFF !important;
  text-decoration-color: #FFFFFF !important;
}
.v-tab--active {
  color: #212121!important;
}
.fixed-tabs-bar {
  position: sticky;
  top: -20px;
  z-index: 2;
}
.shadowless {
  box-shadow: none !important;
}
</style>
