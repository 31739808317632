// UT-857 TODO: Consider renaming these properties:
//  farmsSum -> productionFarmData
//  farms -> productionFarms

export default {
  getters: {
    farmsSum(state, getters, rootState, rootGetters) {
      const currentYear = rootGetters['shared/currentCropYear'];
      const preferredProductionUnit = rootGetters['farmProfile/currentUnitPreference']('Production');
      const farmLocations = rootGetters['farmLocations/farmLocationsAlphabetical'];
      const convertProduction = rootGetters['shared/convert'];
      const { actualProductions } = rootState.farmProfile;

      // Initialize starting values
      const farmsSum = farmLocations.reduce((acc, current) => {
        acc[current.id] = {
          area: 0,
          prodYield: 0,
          production: 0,
          commodities: {},
        };
        return acc;
      }, {});

      Object.values(actualProductions).forEach((ap) => {
        // NOTE: It would be nice if this was already in camelCase
        const {
          farm_location_id: farmLocationId,
          crop_year_id: cropYearId,
          parent_id: parentId,
          harvested_area: harvestedArea,
          commodity_id: commodityId,
          area_unit_id: areaUnitId,
          yield_unit_id: yieldUnitId,
          production_unit_id: productionUnitId,
          production,
        } = ap;

        const farm = farmsSum[farmLocationId];

        if (farm) {
          // Create the top-level production data summary
          if (!parentId) {
            // UT-2126 It was agreed to limit the sum to the current
            // crop year and to continue using harvested acres
            const isCurrentYear = cropYearId === currentYear.id;
            const filteredHarvestedArea = isCurrentYear ? harvestedArea : 0;
            const filteredProduction = isCurrentYear ? production : 0;

            farm.area += filteredHarvestedArea;
            farm.production += convertProduction(
              filteredProduction,
              productionUnitId,
              preferredProductionUnit,
              commodityId,
            );
            farm.prodYield = farm.production / farm.area;
          }

          // Add commodity data for current year
          // TODO: UT-827 This data is only used by FarmProfileTable
          // and may not even be correct - what happens when you have multiple
          // actual productions for the same commodity/year/location? Overwrite?
          // Consider a getter for commodity production data grouped by location, filtered by year.
          if (cropYearId === currentYear.id) {
            farm.commodities[commodityId] = {
              area: harvestedArea,
              production,
              prodYield: production / harvestedArea,
              areaUnit: areaUnitId,
              productionUnit: productionUnitId,
              yieldUnit: yieldUnitId,
            };
          }
        }
      });
      return farmsSum;
    },

    farms(state, getters, rootState, rootGetters) {
      const farmLocations = rootGetters['farmLocations/farmLocationsAlphabetical'];

      // Combine farm location and production data
      return farmLocations.map((f) => ({
        ...f,
        ...getters.farmsSum[f.id],
      }));
    },

    selectedProductionFarm(state, getters, rootState) {
      // Because selectedFarmLocations is an array of farm location IDs, we will
      // consider the first item in that array to be the selectedProductionFarm.
      const selectedFarmLocationId = rootState.farmLocations.selectedFarmLocations?.[0] ?? null;
      return getters.farms.find((f) => f.id === selectedFarmLocationId) ?? null;
    },
  },

  namespaced: true,
};
