<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    @submit.prevent="handleSubmit"
  >
    <v-card :loading="loading">
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text
        class="py-4"
        style="height:500px"
      >
        <v-text-field
          v-model="group.name"
          :value="group.name"
          label="Permission Level Name"
          outlined
          required
          maxlength="255"
          :rules="[requiredValueRule('Group name')]"
          :disabled="group.is_admin || group.is_gma || group.is_member"
          autofocus
        />

        <GAutocomplete
          v-model="group.subordinate_groups"
          label="Subordinate Levels"
          item-value="id"
          item-text="name"
          :items="groups"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          multiple
        />

        <permission-grid
          v-if="showGrid"
          :current-group="group"
          :groups="groups"
          :permissions="permissions"
          :scopes="scopes"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          :disabled="loading"
          @click="handleCancelClick"
        >
          Cancel
        </v-btn>

        <v-btn
          text
          type="submit"
          color="primary"
          :disabled="!isFormValid || loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { requiredValueRule } from '../../helpers/rules';
import PermissionGrid from './PermissionGrid.vue';
import GAutocomplete from '@/components/generic/GAutocomplete.vue';

export default {
  components: {
    PermissionGrid,
    GAutocomplete,
  },

  props: {
    group: {
      type: Object,
      required: true,
    },

    groups: {
      type: Array,
      required: true,
    },

    permissions: {
      type: Array,
      required: true,
    },

    scopes: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),

    showGrid() {
      if (this.group.is_admin) {
        return this.flags['admin-permission-edits'] ?? false;
      }
      return true;
    },
  },

  data() {
    return {
      isFormValid: false,
    };
  },

  mounted() {
    this.$refs.form.resetValidation();
  },

  methods: {
    requiredValueRule,

    handleSubmit() {
      this.$emit('save', this.group);
    },

    handleCancelClick() {
      this.$emit('cancel', this.group);
    },
  },
};
</script>
