<template>
  <span class="font-weight-bold primary-grey--text lh--1-5">
    {{ displayName }}<span class="inline-nowrap">&nbsp;
      <v-fade-transition>
        <v-icon
          v-visible="hover"
          dense
        >mdi-chevron-right</v-icon>
      </v-fade-transition>
    </span>
  </span>
</template>

<script>
// Note: This is a temporary component that will be necessary until
// we have the Library page split up into various other components.
// (we need the type of whitespace trimming provided by vue-loader)
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    draft: {
      type: Boolean,
      required: true,
    },
    hover: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    displayName() {
      return `${this.draft ? '[Draft]' : ''} ${this.name}`.trim();
    },
  },
};
</script>
