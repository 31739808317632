<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$isMobile"
      :retain-focus="false"
      max-width="816"
      width="100%"
      scrollable
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-text
          class="no-radius"
          :class="$isMobile ? 'px-3' : ''"
        >
          <v-row v-if="$isMobile">
            <v-col class="primary text-right mb-n1 pt-4">
              <v-btn
                icon
                @click="closeDialog"
              >
                <v-icon
                  color="white"
                  size="28"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="h-100">
            <v-col
              cols="12"
              md="5"
              class="primary d-flex align-center justify-center"
              :class="!$isMobile ? 'mb-n2 ml-n3' : ''"
            >
              <img
                :src="timerGIF"
                style="height: 18vh"
                alt="coupon"
                class="ml-8"
              >
            </v-col>
            <v-col
              cols="12"
              md="7"
              class="mb-n2 mr-n3"
            >
              <div
                v-if="!$isMobile"
                class="text-right pt-1 mr-n8"
              >
                <v-btn
                  icon
                  @click="closeDialog"
                >
                  <v-icon size="28">
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
              <div
                class="text-center pt-12"
                :class="$isMobile ? '' : 'px-10'"
              >
                <p class="primary-grey--text font-weight-bold text-h6 mb-2 mt-n2">
                  Hurry! Time's Running Out!
                </p>
                <p class="primary--text font-weight-bold text-h3">
                  {{ activeSubscriptionCoupon ? activeSubscriptionCoupon.title : '' }}
                </p>
                <p class="primary-grey--text font-weight-regular text-h6 pt-4 mb-1">
                  offer ends in
                </p>
                <div class="primary-grey--text d-flex justify-center">
                  <CountdownTimeDisplay
                    :time="remainingTime"
                    time-style="text-h3"
                    time-unit-style="text-h7"
                  />
                </div>

                <v-btn
                  class="primary mt-10 mb-6"
                  @click="handleUpgradeNowButton"
                >
                  Upgrade Now
                </v-btn>
                <div class="mt-n4">
                  <a
                    class="text-underline text-caption"
                    @click="setShowTermsAndConditionsDialog(true)"
                  >
                    Terms and Conditions
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <UpgradeFlow />
    <TermsAndConditionsDialog />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { datadogRum } from '@datadog/browser-rum';
import timerGIF from '@/assets/gifs/timer.gif?url';
import { countDown } from './helpers';
import UpgradeFlow from './UpgradeFlow.vue';
import CountdownTimeDisplay from './CountdownTimeDisplay.vue';
import TermsAndConditionsDialog from './TermsAndConditionsDialog.vue';

export default {
  name: 'CouponDialog',

  components: {
    UpgradeFlow,
    CountdownTimeDisplay,
    TermsAndConditionsDialog,
  },

  data() {
    return {
      timerGIF,
      remainingTime: {},
      dialogOpen: false,
    };
  },

  async created() {
    if (this.isBasic && this.isOwnerOrBilling) {
      await this.fetchActiveSubscriptionCoupon();
      this.coupon = this.activeSubscriptionCoupon;
      this.calcCountDown();
      this.dialogOpen = true;
    }
  },

  watch: {
    remainingTime() {
      setTimeout(() => {
        this.calcCountDown();
      }, 1000);
    },
  },

  computed: {
    ...mapGetters('chargebee', ['isBasic']),
    ...mapState('discountCoupon', ['activeSubscriptionCoupon']),
    ...mapGetters('permissions', ['isOwnerOrBilling']),

    hasActiveNonMinimizedActiveSubscriptionCoupon() {
      return this.activeSubscriptionCoupon?.end_date && !this.activeSubscriptionCoupon?.minimized;
    },

    dialog: {
      get() {
        return this.hasActiveNonMinimizedActiveSubscriptionCoupon
        && this.isBasic && this.isOwnerOrBilling && this.dialogOpen;
      },
      set(value) {
        this.dialogOpen = value;
      },
    },
  },

  methods: {
    ...mapActions('discountCoupon', ['fetchActiveSubscriptionCoupon', 'updateActiveSubscriptionCoupon']),
    ...mapMutations('discountCoupon', ['setAddonDialogOpen', 'setShowTermsAndConditionsDialog']),

    calcCountDown() {
      const couponDate = new Date(this.activeSubscriptionCoupon?.end_date);
      this.remainingTime = countDown(couponDate);
    },

    async closeDialog() {
      await this.updateActiveSubscriptionCoupon(
        { minimized: true, coupon_id: this.activeSubscriptionCoupon.id },
      );
      this.dialogOpen = false;
    },

    async handleUpgradeNowButton() {
      this.setAddonDialogOpen(true);
      datadogRum.addAction(`timed-coupon-upgrade-dialog${this.$isMobile ? '-mobile' : ''}`, {
        is_mobile: this.$isMobile,
        from: 'dialog',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-radius {
  border-radius: 0 !important;
}
</style>
