<template>
  <v-container
    class="ma-0"
    :class="[spacingClass, {'h--100': fillHeight}, {'pt-6': topPadding}]"
    fluid
  >
    <slot />
  </v-container>
</template>

<script>
export default {
  props: {
    fillHeight: {
      type: Boolean,
      default: false,
    },
    disableTopPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    spacingClass() {
      return this.$isMobile ? 'px-4' : 'px-10';
    },
    topPadding() {
      return !this.$isMobile && !this.disableTopPadding;
    },
  },
};
</script>
