/**
 * Returns a given date in specific format
 * @returns 'year-month-date' e.g. 2021-08-09 in local time
 */
export function getYearMonthDate(date) {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Gets today's date
 * @returns 'year-month-date' e.g. 2021-08-09
 */
export function getTodayYearMonthDate() {
  return getYearMonthDate(new Date());
}

export function cleanDateForFilename(date) {
  return date
    .toLocaleString()
    .replace(/[A-Z,]/g, '')
    .replace(/[/:]/g, '-')
    .slice(0, -1);
}

/**
 * Formats the date to a string
 * @param {Date} date
 * @returns 'Month D, Yr' e.g. February 17, 2020
 */
export function formatDateTimeMDY(date) {
  return `${date.getDate()} ${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
}

/**
 * Offsets the date by the timezone to ensure date works for all timezones
 * @param {Date | string | number} date - Date to offset
 * @returns {Date} the offset date
 */
export function negateTimezoneOffset(date) {
  if (!date) {
    return date;
  }
  if (!(date instanceof Date)) {
    return negateTimezoneOffset(new Date(date));
  }
  return new Date(date.getTime()
    + date.getTimezoneOffset() * 60000);
}

/**
 * Gets the unix timestamp of the given date.
 * @param {Date} date The date to get the timestamp for.
 * @returns The unix timestamp of the given date in seconds.
 */
export function getDateTimestamp(date) {
  return date.getTime() / 1000;
}

/**
 * Parses a date stamp into an object with year, day, and 0-indexed month.
 * @param {string} dateStamp An ISO timestamp
 * @returns {Object} An object with the year, day, and 0-indexed month.
 */
export function parseYMD(dateStamp) {
  const match = dateStamp.match(/(\d{4})-(\d{2})(?:-(\d{2}))?/);

  return {
    year: +match[1],
    month: +match[2] - 1, // return 0-indexed
    day: +(match[3] ?? 1),
  };
}

/**
 * Gets a Date representing 11:59:59pm in the current time zone, one
 * day before dateStamp.
 * @param {string} dateStamp A yyyy-mm-dd date stamp
 * @returns {Date} The last second of the day before `dateStamp` in
 * the current timezone.
 */
export function getLocalEndOfPreviousDay(dateStamp) {
  const { year, month, day } = parseYMD(dateStamp);
  const date = new Date(year, month, day);

  date.setSeconds(-1);

  return date;
}

/**
 * Returns the date before a given date in specific format
 * @returns 'year-month-date' e.g. 2021-08-09
 */
export function getPreviousYearMonthDate(date) {
  return getYearMonthDate(getLocalEndOfPreviousDay(date));
}

/**
 * Given a target date returns the date range which that target date falls within
 * if no matches exist then it will return null
 *
 * @param {Array[Array[Date, Date]]} dateRanges Array of date tuples to query against
 * @param {Date} targetDate The given target date.
 * @returns The date range which gets matched
 */
export function findDateRangeForGivenDate(dateRanges, targetDate) {
  const timestamp = targetDate.getTime();
  return dateRanges.find((dateRange) => dateRange[0].getTime() <= timestamp && timestamp < dateRange[1].getTime());
}

export function dateStampFromMonthAndYear({ month, year }, when = 'start') {
  const months = [null, 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let monthNum = months.indexOf(month);
  let yearStr = `${year}`;

  if (when === 'end') {
    monthNum = ((monthNum + 1) % months.length) || 1;
    if (monthNum === 1) yearStr = `${parseInt(year, 10) + 1}`;
  }

  const monthStr = `${monthNum}`.padStart(2, '0');

  return `${yearStr}-${monthStr}-01`;
}

/**
 * Gets the beginning of the day of a given timestamp string
 */
export function getDay(timestamp) {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date;
}

/**
 *
  Checks if the given year is a leap year or not
 */
export function isLeapYear(year) {
  return new Date(year, 1, 29).getDate() === 29;
}
