<script>
import { VTabsItems } from 'vuetify/lib/components/VTabs';

export default VTabsItems.extend({
  name: 'g-tabs-items',
  methods: {
    genDefaultSlot() {
      if (!this.$slots.default || this.$slots.default.length === 0) {
        return this.$slots.default;
      }
      return this.$slots.default.filter(
        (vnode) => vnode.componentOptions?.propsData?.value === this.internalValue,
      );
    },
  },
});
</script>
