import Vue from 'vue';

export default {
  state: () => ({
    permissions: {},
  }),

  getters: {
    checkPermission(state) {
      return (scope, permission) => state.permissions[scope]?.[permission] === 'allow';
    },
    checkPermissionAllowBadPayment(state) {
      // Returns true if permission is granted, or rejected by payment status. For letting user see options they dont have
      return (scope, permission) => state.permissions[scope]?.[permission] === 'allow' || state.permissions[scope]?.[permission] === 'reject_payment';
    },
    isOwnerOrBilling(state, getters, rootState) {
      return (permission) => {
        const userId = rootState?.user?.id;
        const ownerId = rootState?.subscription?.ownerId;

        return userId && (userId === ownerId || getters.checkPermission('billing', permission));
      };
    },

    isOwner(state, getters, rootState) {
      const userId = rootState?.user?.id;
      const ownerId = rootState?.subscription?.ownerId;
      return userId && (userId === ownerId);
    },
  },

  mutations: {
    setPermissions(state, permissions) {
      Vue.set(state, 'permissions', permissions);
    },
  },

  actions: {
    loadPermissionsState({ commit }, payload) {
      commit('setPermissions', payload);
    },
  },

  namespaced: true,
};
