import type OriginalVue from 'vue';
import type { PluginOptions } from './types';

export type Snackbar = {
  info(message?: string, timeout?: number): void
  success(message?: string, timeout?: number): void
  error(message?: string | Error, timeout?: number): void
  custom(color?: string, message?: string, timeout?: number): void
  undo(message?: string, onUndo?: () => void, timeout?: number): void;
};

declare module 'vue' {
  interface ComponentCustomProperties {
    $snackbar: Snackbar;
  }
}

export default {
  install: (Vue: typeof OriginalVue, { store }: PluginOptions): void => {
    if (!store) {
      throw new Error('Please provide vuex store');
    }

    const snackbar: Snackbar = {
      info(message = '', timeout = 10000) {
        store.commit('snackbar/showMessage', { message, timeout }, { root: true });
      },

      success(message = '', timeout = 10000) {
        const color = 'success';
        store.commit('snackbar/showMessage', { message, color, timeout }, { root: true });
      },

      error(message = '', timeout = 10000) {
        const color = 'error';
        const errorMessage = typeof message === 'string' ? message : message.message;
        store.commit('snackbar/showMessage', { message: errorMessage, color, timeout }, { root: true });
      },

      undo(message = '', onUndo = ():void => {}, timeout = 15000) {
        const color = 'success';
        store.commit('snackbar/showMessage', { message, color, timeout, onUndo }, { root: true });
      },

      custom(color = '', message = '', timeout = 10000) {
        store.commit('snackbar/showMessage', { message, color, timeout }, { root: true });
      },
    };

    Vue.prototype.$snackbar = snackbar;
  },
};
