<template>
  <div class="g-carousel">
    <div class="d-flex justify-space-between">
      <div>
        <slot name="header" />
      </div>
      <!-- Scroll Arrows -->
      <div
        v-if="overflowWidth > 0 && showArrows"
        class="d-flex justify-end"
      >
        <v-btn
          icon
          :disabled="!canScrollLeft"
          @click="scrollLeft"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="!canScrollRight"
          @click="scrollRight"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- Carousel Items -->
    <v-item-group
      :value="value"
      @change="updateValue"
    >
      <div
        ref="scroll"
        v-resize="onResize"
        class="g-carousel__items py-2"
        :class="[draggingClass, scrollClass]"
        :style="gridStyle"
        @mousedown.capture.prevent="onMouseDown"
        @mousemove.capture.prevent="onMouseMove"
        @click.capture="onClick"
        @scroll="onScroll"
      >
        <slot />
      </div>
    </v-item-group>

    <!-- Dots -->
    <div
      class="d-flex justify-center"
    >
      <DotComponent
        v-if="showDots"
        :affix="affix"
        :size="dotSize"
        :count="itemCount"
        :max-items="maxItems"
        :active-index="activeIndexLow"
      />
    </div>
  </div>
</template>

<script>
import DotComponent from './DotComponent.vue';

export default {
  name: 'GCarousel',

  components: {
    DotComponent,
  },

  provide() {
    return {
      $inputEventType: () => this.inputEventType,
      $containerWidth: () => this.containerWidth,
    };
  },

  props: {
    maxItems: {
      type: Number,
      default: 10,
    },

    dotSize: {
      type: Number,
      default: 8,
    },

    affix: {
      type: Boolean,
      default: false,
    },

    showArrows: {
      type: Boolean,
      default: false,
    },

    showDots: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Number,
      default: null,
    },

    resetScroll: {
      type: Boolean,
      default: false,
    },

    scrollClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isMounted: false,
      isDragging: false,
      dragStartPosition: {
        left: 0,
        mouseX: 0,
      },
      viewportWidth: 0,
      scrollLeftValue: 0,
      inputEventType: null,
      itemCount: 0,
      observer: null,
      activeIndexLow: 0,
      activeIndexHigh: 0,
      containerWidth: 0,
    };
  },

  computed: {

    /**
     * Number of pixels by which the scroll area overflows its container
     */
    overflowWidth() {
      const watch = this.viewportWidth; // eslint-disable-line no-unused-vars
      const innerWidth = this.$refs?.scroll?.scrollWidth ?? 0;
      const outerWidth = this.$refs?.scroll?.clientWidth ?? 0;
      return innerWidth - outerWidth - 1;
    },

    /**
     * Dynamic gap width that changes based on screen size
     */
    gapWidth() {
      return this.$isMobile ? 12 : 24;
    },

    /**
     * Dynamic style that enforces child element width
     */
    gridStyle() {
      const columns = 'grid-template-columns: repeat(auto-fill, fit-content);';
      const gap = `grid-gap: ${this.gapWidth}px;gap: ${this.gapWidth}px;`;
      return `${columns}${gap}`;
    },

    /**
     * Whether the carousel be scrolled further left
     */
    canScrollLeft() {
      return this.scrollLeftValue > 0;
    },

    /**
     * Whether the carousel be scrolled further right
     */
    canScrollRight() {
      if (!this.isMounted) return false;
      return this.scrollLeftValue < this.overflowWidth;
    },

    /**
     * Class for dragging state
     */
    draggingClass() {
      return this.isDragging ? 'g-carousel__items--dragging' : '';
    },
  },

  watch: {
    scrollLeftValue() {
      this.updateActiveIndex();
    },

    canScrollLeft(newValue) {
      this.$emit('can-scroll-left-changed', newValue);
    },

    canScrollRight(newValue) {
      this.$emit('can-scroll-right-changed', newValue);
    },

    gapWidth() {
      this.updateActiveIndex();
    },
  },

  mounted() {
    // Signal that DOM is ready
    this.isMounted = true;
    const observationTarget = this.$refs.scroll;
    if (this.resetScroll) {
      this.$refs.scroll.scrollLeft = 0;
    }
    const config = { childList: true };
    this.updateItemLength();
    this.observer = new MutationObserver(this.onMutation);
    this.observer.observe(observationTarget, config);
    this.onMutation();
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {
    onMutation() {
      this.$nextTick(async () => {
        this.updateItemLength();
        await this.updateScrollContainer();
        this.updateActiveIndex();
      });
    },

    updateItemLength() {
      this.itemCount = (this.$slots?.default ?? []).length;
    },

    updateActiveIndex() {
      const items = this.$slots?.default || [];
      const widths = items.map((item) => item.elm?.getBoundingClientRect().width || 0);
      const totalWidth = widths.reduce((acc, width) => acc + width, 0);
      if (totalWidth === 0) {
        // invis components
        this.activeIndexLow = 0;
        this.activeIndexHigh = 0;
        this.$emit('active-index', [0, 0]);
        return;
      }
      this.activeIndexLow = 0;
      this.activeIndexHigh = 0;
      let inWindow = false;
      for (let i = 0; i < items.length; i += 1) {
        const isVisible = this.isElementPartiallyVisible(i);
        if (isVisible) {
          this.activeIndexHigh = i;
          if (!inWindow) {
            this.activeIndexLow = i;
            inWindow = true;
          }
        } else if (inWindow) {
          break;
        }
      }

      this.$emit('active-index', [this.activeIndexLow, this.activeIndexHigh]);
    },

    async updateScrollContainer() {
      this.viewportWidth += 1;
      await this.$nextTick();
      this.viewportWidth -= 1;
      this.containerWidth = this.$refs.scroll.getBoundingClientRect().width;
    },

    onMouseDown(e) {
      this.isDragging = true;

      // Record the starting position of the drag
      this.dragStartPosition = {
        left: this.$refs.scroll.scrollLeft,
        mouseX: e.clientX,
      };

      // Listen for a mouseup event anywhere
      document.addEventListener('mouseup', this.onMouseUp);
    },

    onMouseMove(e) {
      if (!this.isDragging) {
        return;
      }

      // Calculate movement delta and update scroll position
      const deltaX = e.clientX - this.dragStartPosition.mouseX;
      this.$refs.scroll.scrollLeft = this.dragStartPosition.left - deltaX;
    },

    onMouseUp(e) {
      // Click vs Drag Threshold Detection
      const deltaX = Math.abs(e.clientX - this.dragStartPosition.mouseX);
      this.inputEventType = deltaX > 20 ? 'drag' : 'click';

      // Remove the temporary global mouseup event listener
      document.removeEventListener('mouseup', this.onMouseUp);
      this.$emit('drag-finish', this.inputEventType);
      this.isDragging = false;
    },

    async onScroll() {
      await this.$nextTick();
      this.scrollLeftValue = this.$refs.scroll.scrollLeft;
    },

    async onResize() {
      await this.$nextTick();
      this.viewportWidth = window.innerWidth;
      this.containerWidth = this.$refs.scroll.getBoundingClientRect().width;
      this.onMutation();
    },

    isElementPartiallyVisible(index) {
      const elem = this.$slots?.default?.[index]?.elm;
      if (!elem) {
        return false;
      }

      const rect = elem.getBoundingClientRect();
      const box = this.$refs.scroll.getBoundingClientRect();
      const isVisible = (
        rect.left <= box.right
        && rect.right >= box.left
      );
      return isVisible;
    },

    isElementEntirelyVisible(index) {
      const elem = this.$slots?.default?.[index]?.elm;
      if (!elem) {
        return false;
      }

      const marginOfError = 25;

      const rect = elem.getBoundingClientRect();
      const box = this.$refs.scroll.getBoundingClientRect();
      const isVisible = (
        rect.left >= box.left - marginOfError
        && rect.right <= box.right + marginOfError
      );
      return isVisible;
    },

    scrollLeft() {
      const slots = this.$slots?.default || [];
      const target = this.isElementEntirelyVisible(this.activeIndexLow)
        ? this.activeIndexLow - 1 : this.activeIndexLow;
      const elem = slots[target]?.elm;

      if (!elem || target === 0) {
        this.$refs.scroll.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        return;
      }

      const offset = this.$refs.scroll.getBoundingClientRect().left + 1;
      const posn = elem.getBoundingClientRect().left;
      const newPosn = posn + this.scrollLeftValue - offset;

      this.$refs.scroll.scrollTo({
        left: newPosn,
        behavior: 'smooth',
      });
    },

    scrollRight() {
      const slots = this.$slots?.default || [];
      const target = this.isElementEntirelyVisible(this.activeIndexHigh)
        ? this.activeIndexHigh + 1 : this.activeIndexHigh;
      const elem = slots[target]?.elm;

      if (!elem || target === slots.length - 1) {
        this.$refs.scroll.scrollTo({
          left: this.overflowWidth + 1,
          behavior: 'smooth',
        });
        return;
      }

      const offset = this.$refs.scroll.getBoundingClientRect().right - 1;
      const posn = elem.getBoundingClientRect().right;
      const newPosn = posn + this.scrollLeftValue - offset;

      this.$refs.scroll.scrollTo({
        left: newPosn,
        behavior: 'smooth',
      });
    },

    updateValue(value) {
      this.$emit('input', value);
    },

    onClick(event) {
      if (this.inputEventType === 'drag') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .g-carousel {
    &__items {
      display: grid;
      grid-auto-flow: column;
      overflow-x: scroll;
      padding: 0 1px;

      /* Hide scrollbar */
      &::-webkit-scrollbar {
        display: none; /* Chrome */
      }
      scrollbar-width: none;  /* Firefox */
      -ms-overflow-style: none;  /* IE and Edge */

      &--dragging {
        user-select: none;
      }
    }
  }
</style>
