import Vue from 'vue';
import { postRequest } from '../../helpers/request';
import navigationLinks from '../../config/navigationLinks';

export default {
  state: () => ({
    navDrawerWidth: 80,
    headerHeightMobile: 48,
    headerHeightDesktop: 64,
    isFeedbackDialogOpen: false,
    isInviteDialogOpen: false,
    isInviteToSubscription: false,
    isCreateAccountDialogOpen: false,
    isNavDrawerOpen: false,
    navDrawerItems: [],
    launchDarklyKey: '',
    isWebsocketOpen: true,
    isMobileDevice: false,
    isProduction: false,
    isStaging: false,
    isDebug: false,
    isMimic: false,
    mimicSession: null,
    isChangingMimic: false,
    isApp: false,
    isIOS: false,
    isPrintingEnabled: false,
    scrollOptions: {
      easing: 'easeOutQuad',
      duration: 300,
    },
    notifications: {},
    isAllNotificationsRead: false,
    unreadNotifications: 0,
    recentNotifications: {},
    // window property
    windowSize: {
      width: 0,
      height: 0,
    },

    // invite organization confirmation properties
    organizationInviteData: {
      heading: '',
      text: '',
      inviteKey: '',
      isMember: false,
      dialogOpen: false,
    },
    showAdBlock: false,
  }),

  getters: {
    mimicLocked: (state) => {
      if (!state.mimicSession) {
        return false;
      }
      return state.mimicSession.locked;
    },

    isDebug: (state) => !state.isProduction && !state.isStaging,

    getNotifications: (state) => Object.values(state.notifications)
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),

    getRecentNotifications: (state) => Object.values(state.recentNotifications)
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),

    getRecentNotificationCount: (state, getters) => getters.getRecentNotifications
      .length,

    areAllNotificationsRead: (state) => state.unreadNotifications === 0,
  },

  mutations: {
    setNavDrawerItems(state, items) {
      state.navDrawerItems = items;
    },

    setNavDrawerState(state, payload) {
      state.isNavDrawerOpen = payload;
    },

    toggleNavDrawerState(state) {
      state.isNavDrawerOpen = !state.isNavDrawerOpen;
    },

    setFeedbackDialogOpen(state, payload) {
      state.isFeedbackDialogOpen = payload;
    },

    setInviteDialogOpen(state, payload) {
      state.isInviteDialogOpen = payload;
    },

    setCreateAccountDialogOpen(state, payload) {
      state.isCreateAccountDialogOpen = payload;
    },

    setIsInviteToSubscription(state, payload) {
      state.isInviteToSubscription = payload;
    },

    setLaunchDarklyKey(state, value) {
      state.launchDarklyKey = value;
    },

    setIsWebsocketOpen(state, value) {
      state.isWebsocketOpen = value;
    },

    setIsMobileDevice(state, payload) {
      state.isMobileDevice = payload;
    },

    setIsProduction(state, payload) {
      state.isProduction = payload;
    },

    setIsStaging(state, payload) {
      state.isStaging = payload;
    },

    setIsDebug(state, payload) {
      state.isDebug = payload;
    },

    setIsMimic(state, payload) {
      state.isMimic = payload;
    },

    setIsChangingMimic(state, payload) {
      state.isChangingMimic = payload;
    },

    setMimicSession(state, payload) {
      state.mimicSession = payload;
    },

    setIsApp(state, payload) {
      state.isApp = payload;
    },

    setIsIOS(state, payload) {
      state.isIOS = payload;
    },

    setIsPrintingEnabled(state, payload) {
      state.isPrintingEnabled = payload;
    },

    setNotifications(state, payload) {
      state.notifications = payload;
    },

    setRecentNotifications(state, payload) {
      state.recentNotifications = payload;
    },

    setNotificationRead(state, { id }) {
      const isUnread = (notification) => notification !== undefined && !notification?.read;
      if (isUnread(state.notifications[id]) || isUnread(state.recentNotifications[id])) {
        state.unreadNotifications = Math.max(state.unreadNotifications - 1, 0);
      }

      // Both regular and recent notifications can be marked as read
      if (state.notifications[id]) {
        state.notifications[id].read = true;
      }
      if (state.recentNotifications[id]) {
        state.recentNotifications[id].read = true;
      }
    },

    setUnreadNotificationCount(state, value) {
      state.unreadNotifications = value;
    },

    setAllNotificationsRead(state, { value }) {
      state.unreadNotifications = 0;
      state.isAllNotificationsRead = true;
      // Both regular and recent notifications can be marked as read
      // eslint-disable-next-line no-param-reassign
      Object.values(state.notifications).forEach((n) => { n.read = value; });
      // eslint-disable-next-line no-param-reassign
      Object.values(state.recentNotifications).forEach((n) => { n.read = value; });
    },

    setRecentNotificationDismissed(state, id) {
      // if it was already read, then it shouldn't count to the total anyways
      if (!state.recentNotifications[id].read) {
        state.unreadNotifications -= 1;
      }
      // Only recent notifications can be dismissed
      Vue.delete(state.recentNotifications, id);
    },

    setWindowSize(state, { width, height }) {
      state.windowSize = {
        width,
        height,
      };
    },

    openOrganizationInviteDialog(state, { isMember, subscription, inviteKey }) {
      state.organizationInviteData = {
        heading: isMember ? 'Information' : 'Confirm',
        text: isMember ? `You're already a member of ${subscription}` : `Please confirm if you'd like to join ${subscription}`,
        isMember,
        inviteKey,
        dialogOpen: true,
      };
    },
    cancelOrganizationInviteDialog(state) {
      state.organizationInviteData.dialogOpen = false;
    },

    confirmOrganizationInviteDialog(state) {
      state.organizationInviteData.dialogOpen = false;
      const { inviteKey } = state.organizationInviteData;
      window.location.href = `/api/accept_aak/${inviteKey}/I/`;
    },

    setShowAdBlockDialog(state, value) {
      state.showAdBlock = value;
    },
  },

  actions: {
    loadNavDrawerItems({ commit }) {
      commit('setNavDrawerItems', navigationLinks);
    },

    loadAppState({ commit }, payload) {
      const {
        launchDarklyKey,
        isMobileDevice,
        isProduction,
        isStaging,
        isDebug,
        isMimic,
        mimicSession,
        isApp,
        isIOS,
      } = payload;
      commit('setLaunchDarklyKey', launchDarklyKey);
      commit('setIsMobileDevice', isMobileDevice);
      commit('setIsProduction', isProduction);
      commit('setIsStaging', isStaging);
      commit('setIsDebug', isDebug);
      commit('setIsMimic', isMimic);
      commit('setMimicSession', mimicSession);
      commit('setIsApp', isApp);
      commit('setIsIOS', isIOS);
    },

    async acquireMimicLock({ commit, state }) {
      await postRequest('/account/css_module/acquire_lock/').catch(() => {
        // can only error if not mimicking, which is a no-op.
      });
      commit('setMimicSession', {
        ...state.mimicSession,
        locked: true,
      });
    },

    async releaseMimicLock({ commit, state }) {
      const response = await postRequest('/account/css_module/release_lock/').catch(() => ({
        locked: false, // can only error if not mimicking, which is a no-op.
      }));
      commit('setMimicSession', {
        ...state.mimicSession,
        locked: response.locked,
      });
    },

    handleMimicChanged({ commit, state }, payload) {
      commit('setMimicSession', payload.session);
      const isMimic = !!payload.session;
      if (isMimic !== state.isMimic && !state.isChangingMimic) {
        window.location.reload();
      }
    },

    loadNotifications({ commit }, page) {
      return new Promise((resolve, reject) => {
        postRequest('/dashboard/api/read_notifications/', { page }).then((response) => {
          commit('setNotifications', response.results);
          resolve(response);
        }, (error) => {
          reject(error);
        });
      });
    },

    loadRecentNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        postRequest('/dashboard/api/read_recent_notifications/').then((response) => {
          commit('setRecentNotifications', response.results);
          resolve();
        }, (error) => {
          reject(error);
        });
      });
    },

    readNotification({ commit }, payload) {
      try {
        commit('setNotificationRead', payload);

        // Not awaiting this API call, since
        // a responsive UI is more important
        // than ensuring the server is updated
        API.markInteractedNotification(payload.id);
      } catch (e) {
        this._vm.$snackbar.error('Could not read notification');
      }
    },

    readAllNotifications({ commit }, payload) {
      try {
        commit('setAllNotificationsRead', payload);

        // Not awaiting this API call, since
        // a responsive UI is more important
        // than ensuring the server is updated
        API.markInteractedNotificationAll();
      } catch (e) {
        this._vm.$snackbar.error('Could not read notification');
      }
    },

    dismissNotification({ commit }, id) {
      try {
        commit('setRecentNotificationDismissed', id);

        // Not awaiting this API call, since
        // a responsive UI is more important
        // than ensuring the server is updated
        API.dismissNotification(id);
      } catch (e) {
        this._vm.$snackbar.error('Could not dismiss notification');
      }
    },

    inviteToGrainFox({ commit }) {
      commit('setIsInviteToSubscription', false);
      commit('setInviteDialogOpen', true);
    },

    inviteToSubscription({ commit }) {
      commit('setIsInviteToSubscription', true);
      commit('setInviteDialogOpen', true);
    },

    async detectAdBlock({ commit, rootState }) {
      // Checks for adblock by making a request to a known ad URL, and seeing if it fails
      // If adblock is detected and the user hasn't seen the dialog in 30 days, show the dialog
      // this is a URL that should be blocked by AdBlock
      const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      if (rootState.user.isLoggedIn) {
        try {
          await fetch(googleAdUrl);
        } catch (error) {
          const { has_seen_recently } = await API.hasSeenAdblockRecently();
          if (!has_seen_recently) {
            commit('setShowAdBlockDialog', true);
          }
        }
      }
    },

    async markAdBlockSeen({ commit }) {
      await API.updateAdblockLastSeen();
      commit('setShowAdBlockDialog', false);
    },
  },

  namespaced: true,
};
