<template>
  <v-tabs
    :value="tab"
    show-arrows
    centered
    center-active
    class="mb-3"
  >
    <v-tab
      v-for="t in tabs"
      :key="t.title"
      :href="t.href"
    >
      {{ t.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
const TABS = [
  {
    title: 'My Profile',
    href: '/account/profile/',
  },
  {
    title: 'Notifications',
    href: '/account/notification_settings/',
  },
  {
    title: 'Preferences',
    href: '/account/preferences/',
  },
  {
    title: 'Security',
    href: '/account/security/',
  },
];

export default {
  props: {
    tab: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tabs: TABS,
    };
  },

};
</script>
