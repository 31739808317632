<template>
  <div
    v-if="hasActionButtons"
    class="mt-auto g-dialog__actions"
    :class="actionButtonsClass"
  >
    <v-divider
      v-if="showActionButtonDivider"
      :class="outerDividerClass"
    />
    <div
      class="v-card__actions"
      :class="actionMarginPaddingClasses"
    >
      <template v-for="(button, index) in filteredActionButtons">
        <g-dialog-button
          :key="`button-${index}`"
          :index="index"
          :is-mobile="isMobile"
          :is-form-valid="isFormValid"
          :is-processing-request="isProcessingRequest"
          v-bind="button"
          @process-click="processClick"
        />
      </template>
    </div>
  </div>
</template>

<script>
import GDialogButton from './GDialogButton.vue';

export default {
  name: 'GDialogActions',

  components: {
    GDialogButton,
  },

  props: {
    actionButtons: {
      type: Array,
      default: () => [],
    },

    isMobile: {
      type: Boolean,
      required: true,
    },

    isMobileFullscreen: {
      type: Boolean,
      required: true,
    },

    isFormValid: {
      type: Boolean,
      required: true,
    },

    isProcessingRequest: {
      type: Boolean,
      required: true,
    },

    side: {
      type: String,
      default: '',
    },
  },

  computed: {
    filteredActionButtons() {
      return this.actionButtons.filter((b) => (this.isMobile ? !b.hideOnMobile : true));
    },

    hasActionButtons() {
      return this.filteredActionButtons.length > 0;
    },

    hasCTAButton() {
      return this.filteredActionButtons.length === 1 && this.filteredActionButtons[0].type === 'cta';
    },

    showActionButtonDivider() {
      return !this.hasCTAButton && this.hasActionButtons;
    },

    actionButtonsClass() {
      return this.isMobile ? 'g-dialog__actions--mobile' : 'g-dialog__actions--desktop';
    },

    actionMarginPaddingClasses() {
      return this.isMobileFullscreen ? 'mx-n1 mb-n2 pb-0 px-0' : '';
    },

    outerDividerClass() {
      return this.isMobileFullscreen ? 'mx-n4' : '';
    },
  },

  methods: {
    processClick({ onClick, mustValidate }) {
      this.$emit('process-click', {
        onClick,
        mustValidate,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.g-dialog__actions {
  &--mobile {
    .v-card__actions {
      display: block !important;
    }
  }
  .v-divider {
    max-width: none;
  }
}
</style>
