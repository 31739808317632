<template>
  <div>
    <div
      class="text-h6 grey--text text--darken-4 font-weight-regular"
    >
      Recommended for you
    </div>
    <g-carousel
      affix
      reset-scroll
    >
      <g-carousel-item
        v-for="(recommendation, i) in recommendations"
        :key="i"
      >
        <div
          :style="`width: ${width}px`"
        >
          <RecommendationCard
            :recommendation="recommendation"
            class="px-2 py-4"
            @view="$emit('view', $event)"
          />
        </div>
      </g-carousel-item>
    </g-carousel>
  </div>
</template>
<script>
import RecommendationCard from './RecommendationCard.vue';
import GCarousel from '../generic/GCarousel.vue';
import GCarouselItem from '../generic/GCarouselItem.vue';

export default {
  name: 'RecommendationCarousel',
  components: {
    RecommendationCard,
    GCarousel,
    GCarouselItem,
  },
  props: {
    recommendations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    width() {
      const factor = this.$vuetify.breakpoint.xs ? 0.8 : 0.4;
      return this.$vuetify.breakpoint.width * factor;
    },
  },
  methods: {
    applyTo(el) {
      const target = el.querySelector('#subheader');
      if (!target) {
        return;
      }
      const targetParent = target.parentNode;
      if (targetParent.classList.contains('g-layout__content')) {
        const firstParagraph = targetParent.querySelector('p');
        if (firstParagraph) {
          targetParent.insertBefore(this.$el, firstParagraph.nextSibling);
        } else {
          targetParent.appendChild(this.$el);
        }
        return;
      }
      const realTarget = el.querySelector('.g-layout__content');
      try {
        const { firstElementChild } = realTarget;
        const secondChild = firstElementChild.nextSibling;
        if (secondChild) {
          realTarget.insertBefore(this.$el, secondChild);
        } else {
          realTarget.appendChild(this.$el);
        }
      } catch (e) {
        // Could not determine where to insert the carousel
      }
    },
  },
};
</script>
