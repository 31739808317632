import smartAdvisorImplementation from './smart-advisor-context';
import SmartAdvisorContexts from '@/components/ai-advisor/constants';
import colors from '@/colors/colors.module.scss';

const contexts = [SmartAdvisorContexts.current, SmartAdvisorContexts.next];

const sellColors = {
  current: colors.$positive,
  next: colors.$ss5,
};
const holdColors = {
  current: colors.$neutral,
  next: colors.$sp4,
};
const rangeLightColors = {
  current: colors.$st4,
  next: colors.$ss4,
};
const rangeDarkColors = {
  current: colors.$st9,
  next: colors.$ss9,
};

/**
 * Smart Advisor Facade
 * A new child module will be registered for each context
 * and state/mutations/getters/actions will be proxied.
 */

/**
 * Create the context modules using identical stores.
 */
const modules = contexts.reduce((acc, context) => {
  acc[context] = smartAdvisorImplementation;
  return acc;
}, {});

/**
 * Context module state will be proxied as getters.
 * Be careful to avoid naming collisions.
 */
const stateProperties = Object.keys(smartAdvisorImplementation.state());
const proxiedState = stateProperties.reduce((acc, getterKey) => {
  acc[getterKey] = (state) => state[state.activeContextKey][getterKey];
  return acc;
}, {});

/**
 * Context module mutations will be proxied as actions,
 * because there's no simple way to access the child module
 * mutations directly - and mutations are deprecated in Pinia.
 */
const mutationProperties = Object.keys(smartAdvisorImplementation.mutations);
const proxiedMutations = mutationProperties.reduce((acc, mutationKey) => {
  acc[mutationKey] = ({ state, commit }, payload) => {
    commit(`${state.activeContextKey}/${mutationKey}`, payload);
  };
  return acc;
}, {});

/**
 * Context module getters will be proxied as getters.
 */
const getterProperties = Object.keys(smartAdvisorImplementation.getters);
const proxiedGetters = getterProperties.reduce((acc, getterKey) => {
  acc[getterKey] = (state, getters) => getters[`${state.activeContextKey}/${getterKey}`];
  return acc;
}, {});

/**
 * Context module actions will be proxied as actions.
 */
const actionProperties = Object.keys(smartAdvisorImplementation.actions);
const proxiedActions = actionProperties.reduce((acc, actionKey) => {
  acc[actionKey] = async ({ state, dispatch }, payload) => await dispatch(`${state.activeContextKey}/${actionKey}`, payload);
  return acc;
}, {});

const module = {
  namespaced: true,
  state: () => ({
    activeContextKey: SmartAdvisorContexts.current,
  }),
  mutations: {
    setActiveContextKey(state, key) {
      state.activeContextKey = key;
    },
  },
  getters: {
    ...proxiedState,
    ...proxiedGetters,
    activeCropYear: (state, getters, rootState, rootGetters) => rootGetters[`shared/${state.activeContextKey}CropYear`],
    productionCropYear: (state, getters, rootState, rootGetters) => rootGetters[`shared/${state.activeContextKey}CropYear`],
    isCurrentContext: (state) => state.activeContextKey === SmartAdvisorContexts.current,
    sellColor: (state) => sellColors[state.activeContextKey],
    holdColor: (state) => holdColors[state.activeContextKey],
    rangeColors: (state) => [rangeLightColors[state.activeContextKey], rangeDarkColors[state.activeContextKey]],
  },
  actions: {
    ...proxiedMutations,
    ...proxiedActions,
  },
  modules,
};

export default module;
