<template>
  <g-dialog
    v-model="dialogOpen"
    :title="title"
    disable-fullscreen
    :width="hasSnippets ? 1400 : 500"
    :height="800"
    :action-buttons="buttons"
    @cancel="closeDialog"
  >
    <div
      v-if="!hasSnippets"
      ref="noSnippets"
      class="font-weight-bold h1"
      style="text-align: center;"
    >
      No snippets found
    </div>
    <div
      v-else
      class="h--100 pb-8"
    >
      <v-tabs
        v-model="currentTab"
        class="mt-0 pt-0"
        grow
      >
        <v-tab
          :disabled="news.length === 0"
        >
          NEWS
        </v-tab>
        <v-tab
          :disabled="quickBites.length === 0"
        >
          QUICK BITES
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="currentTab"
        class="h--100 overflow-auto"
      >
        <v-tab-item
          class="pa-4 tab-item"
          :value="0"
        >
          <v-card
            v-for="(snippet, index) in news"
            :key="`news-${index}`"
            class="pa-6 elevation-4"
          >
            <VTextField
              :ref="`header-news-${index}`"
              v-model="snippet.header"
              label="Header"
              :rules="headerRules"
              outlined
            />
            <VTextarea
              :ref="`body-news-${index}`"
              v-model="snippet.body"
              label="Body"
              :rules="bodyRules"
              outlined
            />
            <VTextField
              :ref="`url-news-${index}`"
              v-model="snippet.article_link"
              label="URL"
              :rules="urlRules"
              outlined
            />
          </v-card>
        </v-tab-item>
        <v-tab-item
          key="quick-bites"
          :disabled="quickBites.length === 0"
          class="pa-4 tab-item"
          :value="1"
        >
          <v-card
            v-for="(snippet, index) in quickBites"
            :key="`quick-bites-${index}`"
            class="pa-4 elevation-4"
          >
            <VTextField
              :ref="`header-quick-bites-${index}`"
              v-model="snippet.header"
              label="Header"
              :rules="headerRules"
              outlined
            />
            <VTextarea
              :ref="`body-quick-bites-${index}`"
              v-model="snippet.body"
              label="Body"
              :rules="bodyRules"
              outlined
            />
            <VTextField
              :ref="`url-quick-bites-${index}`"
              v-model="snippet.article_link"
              label="URL"
              :rules="urlRules"
              outlined
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </g-dialog>
</template>

<script>
import GDialog from '@/components/generic/GDialog.vue';
import { hasScheme, maxLengthRule, requiredValueRule } from '@/helpers/rules';

export default {
  name: 'SnippetConfirmDialog',
  components: {
    GDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    snippets: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      buttons: [
        {
          name: 'Cancel',
          side: 'right',
          type: 'default',
          onClick: this.closeDialog,
        },
        {
          name: 'Confirm',
          side: 'right',
          type: 'primary',
          onClick: this.confirm,
          mustValidate: true,
        },
      ],
      currentTab: null,
      headerRules: [
        requiredValueRule('Header'),
        maxLengthRule('Header', 255),
      ],
      bodyRules: [
        requiredValueRule('Body'),
      ],
      urlRules: [
        hasScheme('URL'),
        maxLengthRule('URL', 255),
      ],
    };
  },

  computed: {
    title() {
      return 'Parsed Snippets';
    },
    dialogOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    hasSnippets() {
      return this.snippets.news.length > 0
        || this.snippets.quick_bite.length > 0;
    },
    news() {
      return this.snippets.news;
    },
    quickBites() {
      return this.snippets.quick_bite;
    },
  },
  methods: {
    closeDialog() {
      this.dialogOpen = false;
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
