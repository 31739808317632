<template>
  <v-system-bar
    v-if="isDebug || isStaging"
    class="white--text caption justify-center align-center"
    :color="statusColor"
    app
  >
    {{ text }}
  </v-system-bar>
</template>

<script>
import { mapState } from 'vuex';
import { DEV } from '@/helpers/environment_variables';

export default {
  computed: {
    ...mapState('app', ['isDebug', 'isStaging']),

    statusColor() {
      if (this.isDebug) {
        return 'info';
      }
      if (this.isStaging) {
        return 'primary';
      }
      return '';
    },

    text() {
      const mode = DEV ? 'DEV SERVER' : 'PROD BUILD';

      if (this.isDebug) {
        return `LOCALHOST - ${mode}`;
      }
      if (this.isStaging) {
        return 'STAGING';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.v-system-bar {
  z-index: 4;
}
</style>
