export default {
  state: () => ({
    allowedFields: [
      {
        name: 'Commodity', unitContext: null, tooltip: 'The crop that is being or has been grown', required: true,
      },
      {
        name: 'Seeded Area', unitContext: 'Area', tooltip: 'Seeded area for the farm', required: false,
      },
      {
        name: 'Harvested Area', unitContext: 'Area', tooltip: 'Harvested area for the farm', required: false,
      },
      {
        name: 'Production', unitContext: 'Production', tooltip: 'Amount of actually produced crop', required: false,
      },
    ],
    importedFileData: null,
    importedFileName: '',
    fileHeaderCols: [{}],
    fileRows: [{}],
    cropYear: null,
    farmLocation: null,
    uploadFileDialogOpen: false,
  }),

  getters: {
    //
  },

  mutations: {

    setCropYear(state, year) {
      state.cropYear = year;
    },

    setFarmLocation(state, location) {
      state.farmLocation = location;
    },

    setImportedFileData(state, fileData) {
      state.importedFileData = fileData;
    },

    setImportedFileName(state, name) {
      state.importedFileName = name;
    },

    setFileHeaderCols(state, colHeaders) {
      state.fileHeaderCols = colHeaders;
    },

    setFileRows(state, fileRows) {
      state.fileRows = fileRows;
    },

    setUploadFileDialogOpen(state, value) {
      state.uploadFileDialogOpen = value;
    },
  },

  actions: {
    setCropYear({ commit }, year) {
      commit('setCropYear', year);
    },

    setFarmLocation({ commit }, year) {
      commit('setFarmLocation', year);
    },
  },

  namespaced: true,
};
