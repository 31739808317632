const EULAPage = () => import('@/pages/legal/EULA.vue');
const TermsOfUsePage = () => import('@/pages/legal/TermsOfUse.vue');
const PrivacyPolicyPage = () => import('@/pages/legal/PrivacyPolicy.vue');

const routes = [
  {
    path: '/privacy_policy/',
    meta: {
      title: 'Privacy Policy',
    },
    component: PrivacyPolicyPage,
  },
  {
    path: '/eula/',
    meta: {
      title: 'End User License Agreement',
    },
    component: EULAPage,
  },
  {
    path: '/terms_of_use/',
    meta: {
      title: 'Terms of Use',
    },
    component: TermsOfUsePage,
  },
];

export default routes;
