<template>
  <v-card
    v-if="results.length > 0"
  >
    <v-card-title class="pb-0">
      CSS Activity Log
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            dark
            right
            small
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>

        <span>Shows recent actions performed by Customer Success Specialists</span>
      </v-tooltip>
    </v-card-title>
    <div
      class="pa-4 pt-0"
    >
      <div
        v-for="(item, index) in results"
        :key="item.id"
      >
        <div>
          <v-divider v-if="index > 0" />
          <div class="d-flex align-center">
            <div
              class="d-flex align-center flex-grow-1 py-6"
            >
              <div class="ml-4">
                <p class="grey--text text--darken-1 mb-0">
                  {{ item.mimic }}, {{ formatDateTime(item.timestamp) }}
                </p>
                <p class="font-weight-bold primary-grey--text mb-0">
                  <span>{{ item.action }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { postFormRequest } from '../../helpers/request';
import { formatDateTime } from '../../helpers/formatting';

export default {
  data() {
    return {
      isLoading: true,
      results: [],
    };
  },
  async mounted() {
    const data = await postFormRequest('/account/css_module/activity_log/');
    this.results = data.results;
    this.isLoading = false;
  },
  methods: {
    formatDateTime,
  },
};
</script>
