<template>
  <v-card>
    <v-btn
      v-if="showAdd"
      color="primary"
      class="ma-4 mb-0"
      @click="handleAddClick"
    >
      <v-icon left>
        mdi-plus
      </v-icon> Add Permission Level
    </v-btn>

    <div
      v-if="items.length === 0 && !loading"
      class="pa-4"
    >
      <p class="mb-0">
        No groups
      </p>
    </div>

    <v-data-table
      v-else
      :items="items"
      :headers="headers"
      :items-per-page="-1"
      :loading="loading"
      :hide-default-footer="true"
      sort-by="name"
    >
      <template #[`item.scope_permissions`]="{ item }">
        {{ item.scope_permissions.length }}
      </template>

      <template #[`item.actions`]="{ item }">
        <tooltip-left text="Edit">
          <v-btn
            v-if="item.can_edit"
            icon
            @click="handleEditClick(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </tooltip-left>

        <tooltip-left text="Delete">
          <v-btn
            v-if="item.can_delete"
            icon
            @click="handleDeleteClick(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </tooltip-left>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showAdd: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleAddClick() {
      this.$emit('add');
    },

    handleDeleteClick(item) {
      this.$emit('delete', item);
    },

    handleEditClick(item) {
      this.$emit('edit', item);
    },
  },
};
</script>
