const CSSModule = () => import('@/pages/css_module/CSSModule.vue');
const SendMessage = () => import('@/pages/css_module/subpages/CSSSendMessage.vue');
const CSSAccounts = () => import('@/pages/css_module/subpages/CSSAccounts.vue');
const CSSInvitations = () => import('@/pages/css_module/subpages/CSSInvitations.vue');

const routes = [
  {
    path: '/account/css_module/',
    name: 'CSSModule',
    meta: {
      title: 'CSS Module',
    },
    component: CSSModule,
    children: [
      {
        path: 'accounts/:data?/',
        component: CSSAccounts,
      },
      {
        path: 'invitations/:data?/',
        name: 'Invitations',
        component: CSSInvitations,
      },
      {
        path: 'send_message/',
        component: SendMessage,
      },
    ],
  },
];

export default routes;
