<template>
  <v-row
    class="ma-0 white px-3"
    :class="$isMobile ? 'px-0' : 'pl-10 pr-0'"
  >
    <v-tabs
      data-qa="subscription-tabs-tab-container"
      :value="tab"
    >
      <v-tab
        v-for="t in visibleTabs"
        :key="t.title"
        :data-qa="`subscription-tabs-${t.title.toLowerCase().replace(' ', '-')}`"
        :href="getHref(t)"
        @click="onTabClick(t)"
      >
        {{ t.title }}
      </v-tab>
    </v-tabs>
    <NavigateOutsideAppDialog
      v-model="showDialog"
      to="/dashboard/organization/billing"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NavigateOutsideAppDialog from '@/components/app/NavigateOutsideAppDialog.vue';

const TABS = [
  {
    title: 'Profile',
    permission: {
      scope: 'profile',
      permission: 'read',
    },
    href: '/dashboard/organization/profile/',
  },
  {
    title: 'Farm Locations',
    permission: [{
      scope: 'profile',
      permission: 'read',
    }, {
      scope: 'farm_locations',
      permission: 'read',
    }],
    href: '/dashboard/organization/farm_locations/',
  },
  {
    title: 'Subscriptions',
    href: '/dashboard/organization/subscriptions/',
    showOnlyBilling: true,
    hideFromDeputter: true,
    hideFromSyngenta: true,
  },
  {
    title: 'Members',
    permission: {
      scope: 'users',
      permission: 'read',
    },
    href: '/dashboard/organization/members/',
  },
  {
    title: 'Permissions',
    permission: {
      scope: 'groups',
      permission: 'read',
    },
    href: '/dashboard/organization/permissions/',
  },
  {
    title: 'Billing',
    href: '/dashboard/organization/billing/',
    showOnlyBilling: true,
    hideFromDeputter: true,
    hideFromCommercial: true,
    showNavigationGuard: true,
  },
];

export default {
  components: { NavigateOutsideAppDialog },
  props: {
    tab: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tabs: TABS,
      showDialog: false,
    };
  },

  computed: {
    visibleTabs() {
      return this.tabs
        .filter((x) => this.showOnlyPermitted(x.permission))
        .filter((x) => this.showOnlyPC(x.showOnlyPC))
        .filter((x) => this.showLaunchDarkly(x))
        .filter((x) => this.showOnlyDebug(x.showOnlyDebug))
        .filter((x) => this.showOnlyBilling(x.showOnlyBilling))
        .filter((x) => this.showOnlyDeputter(x.hideFromDeputter))
        .filter((x) => this.showOnlySyngenta(x.hideFromSyngenta))
        .filter((x) => this.showOnlyCommercial(x.hideFromCommercial));
    },

    ...mapState('app', ['isMobileDevice', 'isDebug', 'isApp']),
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('permissions', ['checkPermission', 'isOwnerOrBilling']),
    ...mapGetters('subscription', ['isDeputter', 'isSyngenta', 'isCommercial']),
  },

  methods: {
    showLaunchDarkly(tab) {
      if (!tab.flag) {
        return true;
      }
      return Boolean(this.flags[tab.flag]);
    },

    showOnlyPermitted(permission) {
      if (!permission) {
        return true;
      }
      if (Array.isArray(permission)) {
        return permission.every((p) => this.checkPermission(p.scope, p.permission));
      }
      return this.checkPermission(permission.scope, permission.permission);
    },

    showOnlyPC(show) {
      return !show || (show && !this.isMobileDevice);
    },
    showOnlyDebug(show) {
      return !show || (show && this.isDebug);
    },
    showOnlyBilling(show) {
      return !show || (show && this.isOwnerOrBilling('read'));
    },

    showOnlyDeputter(hideFromDeputter) {
      return !hideFromDeputter || !this.isDeputter;
    },

    showOnlyCommercial(hideFromCommercial) {
      return !hideFromCommercial || !this.isCommercial;
    },

    showOnlySyngenta(hideFromSyngenta) {
      return !hideFromSyngenta || !this.isSyngenta;
    },
    getHref(tab) {
      if (tab.showNavigationGuard && this.isApp) {
        return '#';
      }
      return tab.href;
    },
    onTabClick(tab) {
      if (tab.showNavigationGuard && this.isApp) {
        this.showDialog = true;
      }
    },
  },
};
</script>
