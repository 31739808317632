const ChangeEmail = () => import('@/pages/account/ChangeEmail.vue');

const routes = [
  {
    path: '/dashboard/organization/',
    meta: {
      title: 'Account',
    },
    children: [
      {
        path: 'profile/',
      },
      {
        path: 'farm_locations/',
      },
      // TODO UT-965
      // {
      //   path: 'subscriptions/',
      // },
      {
        path: 'members/',
      },
      {
        path: 'permissions/',
      },
      // TODO UT-965
      // {
      //   path: 'billing/',
      // },
    ],
  },
  {
    path: '/email_change/',
    meta: {
      title: 'Change Email',
    },
    component: ChangeEmail,
  },
];

export default routes;
