<template>
  <div :class="{'pa-6 pb-0': !$isMobile}">
    <p>
      <strong>
        PLEASE READ THE FOLLOWING AGREEMENT CAREFULLY! IT CONTAINS VERY IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND
        OBLIGATIONS, AS WELL AS LIMITATIONS AND EXCLUSIONS THAT MAY APPLY TO YOU. THIS DOCUMENT CONTAINS A DISPUTE
        RESOLUTION CLAUSE. BY CLICKING ON THE “I ACCEPT” BUTTON OR LINK, YOU ARE CONSENTING TO BE BOUND BY AND ARE
        BECOMING A PARTY TO THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT,
        CLICK THE “I DO NOT ACCEPT” BUTTON OR LINK OR EXIT THE SOFTWARE.
      </strong>
    </p>
    <p>
      <strong>
        BY CLICKING “I ACCEPT,” YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS
        AGREEMENT, AND ANY ADDITIONAL TERMS AND CONDITIONS OR FUTURE MODIFICATIONS.
      </strong>
    </p>
    <p>
      NOW THEREFORE in consideration of the foregoing and the mutual promises, covenants and agreements herein
      contained, and for other good and valuable consideration, the receipt and sufficiency of which the parties
      hereby acknowledge, the parties agree as follows:
    </p>
    <ol class="numbered">
      <li>
        <p>
          DEFINITIONS
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Definitions</strong>. The following terms, wherever used in this Agreement, shall have the
              respective meanings set forth below:
            </p>
            <p>
              <strong>“Agreement”</strong> means this End User Licence Agreement and all of the schedules and
              appendices attached hereto, and any additional terms, conditions or future modifications as described
              herein.
            </p>
            <p>
              <strong>“Canada's Anti-Spam Legislation”</strong> means an Act to promote the efficiency and
              adaptability of the Canadian economy by regulating certain activities that discourage reliance on
              electronic means of carrying out commercial activities, and to amend the Canadian Radio-television and
              Telecommunications Commission Act, the Competition Act, the Personal Information Protection and
              Electronic Documents Act and the Telecommunications Act, as amended.
            </p>
            <p>
              <strong>“Company”</strong> means GrainFox Corp., a corporation incorporated under
              the laws of Manitoba, and includes all of the Company’s affiliates, subsidiaries, shareholders,
              officers, directors, agents, representatives, contractors, suppliers, customers, licensors, licensees,
              partners, employees, successors and assigns, except for the Licensee.
            </p>
            <p>
              <strong>“Confidential Information”</strong> means any and all information, whether disclosed in
              writing, electronically, orally, in machine readable form or otherwise, of any nature and in any form,
              and whether or not specifically marked as confidential, including but not limited to, the terms and
              agreements contained in this Agreement, the information gathered by inspection or heard by the Licensee
              from an inspection of any property, activities or facilities of the Company, the Software, the Results,
              business plans, business strategies, research and development plans, marketing plans, pricing
              information and any other technical, engineering, manufacturing, product, servicing, personnel,
              business or financial information, which is provided, developed, made available or disclosed by the
              Company to the Licensee, or that the Licensee prepares that contain or otherwise reflect a review of
              any of the information referred to in this Section.
            </p>
            <p>
              <strong>“Content Licence”</strong> has the meaning assigned to it in Section 4.2(b).
            </p>
            <p>
              <strong>“Defaulting Party”</strong> has the meaning assigned to it in Section 10.2(c).
            </p>
            <p>
              <strong>“Disagreement”</strong> has the meaning assigned to it in Section 11.2.
            </p>
            <p>
              <strong>“Intellectual Property”</strong> means any and all rights in and in relation to any
              intellectual and industrial property of every nature, under the laws of any country, whether
              registered or unregistered, including without limitation, improvements, modifications,
              developments, trade secrets, proprietary information, know-how, derivative works, copyrights,
              moral rights, databases, data structures, database designs, screenshots, database indices,
              modules, objects, classes, packages, in-line comments, user interfaces, design documents, test
              plans and scripts, computer programs, applications and software (whether in source, object code
              or executable formats) and related documentation and manuals, literary and/or artistic works,
              compositions, compilations, diagrams, designs, domain names, patents (including without limitation,
              divisions, reissues, substitutions, prolongations, continuations, re-examinations, continuations
              in part, renewals, modifications and extensions thereof), trademarks, trade dress, rights under
              registered user agreements, trade names, corporate names, business names, social media handles,
              hashtags, keywords and other trademark and service mark rights and goodwill, industrial designs,
              models and utility models, prototypes, inventions, ideas, data, suggestions,  conceptions, formulations,
              compounds, methods, discoveries, processes, compositions, research data and results, project plans,
              notes, testing materials, logs,  drawings, information, findings, results, technologies, materials,
              formulae, specifications and architecture, data, techniques, instructions, manuals, records, look and
              feel, integrated circuit topography, studies, blueprints, packaging, reports, files, samples,
              photographs, graphs, graphics, text files, websites (including all of the related web pages, content,
              software, information, photographs, images, illustrations, audio clips, video clips, data, code,
              graphics, text files, icons, titles, objects, concepts, artwork, animations, text, sounds, audio-visual
              effects, methods of operation and the look and feel of the content and information), drawings,
              interfaces, screen display, audio visual display or presentation, algorithms, documentation and media,
              and procedures, in whatever form or medium, including:
            </p>
            <ol class="alpha-bracket">
              <li>
                <p>
                  the benefit of all registrations and applications to register as well as all rights to apply for
                  registration of any of the foregoing items and all rights in the nature of any of the foregoing
                  items, each for their full term (including any extensions or renewals thereof);
                </p>
              </li>
              <li>
                <p>
                  any other statutory protection of whatever kind;
                </p>
              </li>

              <li>
                <p>
                  all such other rights which may be recognized under law, equity, contract or otherwise, to protect
                  technical or other creative contributions or expressions;
                </p>
              </li>
              <li>
                <p>
                  confidential information, know-how and trade secrets;
                </p>
              </li>
              <li>
                <p>
                  all priority rights derived from any of the foregoing items and all rights in the nature of any of
                  the foregoing items for any and all countries in the world; and
                </p>
              </li>
              <li>
                <p>
                  all rights to sue for infringement, misappropriation and/or violation of any of the foregoing items
                  and all rights in the nature of any of the foregoing items, whether arising prior to or subsequent
                  to the date of this Agreement.
                </p>
              </li>
            </ol>
            <p>
              <strong>“Licence”</strong> has the meaning assigned to it in Section 4.2(a).
            </p>
            <p>
              <strong>“Licensee”</strong> means a person who uses and accesses the Software and/or the Results
              in any manner.  Such a person is also referred to in this Agreement as “you”.
            </p>
            <p>
              <strong>“Licensee Content”</strong> means any data and information distributed or submitted
              electronically or otherwise by you via the Software, including but not limited to, data,
              content user accounts and images, farm data, land data (including precise location),
              agronomic data (such as commodity pricing, information relating to acres, yields, production
              values), farm management data (such as information about sales, cashflows, inventory),
              and other business information.
            </p>
            <p>
              <strong>“Non-Defaulting Party”</strong> has the meaning assigned to it in Section 10.2(c).
            </p>
            <p>
              <strong>“Requirements”</strong> has the meaning assigned to it in Section 7.3.
            </p>
            <p>
              <strong>“Results”</strong> means any and all results, reports and recommendations based on the
              Licensee Content, user data, analytics and/or user data results that the Company provides to the
              Licensee. The term “Results” shall also include any modification or enhancement to the Licensee Content
              and any Intellectual Property relating thereto by the Company, and any Intellectual Property made,
              conceived, acquired, suggested, discovered or developed by the Company, or under the Company’s
              direction, either solely or jointly with others, which relates to, relies on or uses the Licensee
              Content and all Intellectual Property relating thereto.
            </p>
            <p>
              <strong>“Service Providers”</strong> has the meaning assigned to it in Section 11.17.
            </p>
            <p>
              <strong>“Software”</strong> means the GrainFox software and application, together with (a) all
              computer programs, software, website, database and/or multi-media content in object code form that
              are embedded in the said software and application or delivered to the Licensee separately by the
              Company and are designed to be used in conjunction with the said software and application; and (b)
              all documentation relating to the said software and application, including without limitation,
              manuals, handbooks and any user guides and/or other instructions concerning the access, use and/or
              operation of the Software.
            </p>
            <p>
              <strong>“Subscribing Organization”</strong> has the meaning assigned to it in Section 2.1.
            </p>
            <p>
              <strong>“Subscription Fee”</strong> has the meaning assigned to it in Section 3.1.
            </p>
            <p>
              <strong>“Term”</strong> has the meaning assigned to it in Section 10.1.
            </p>
            <p>
              <strong>“Territory”</strong> means worldwide.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          SUBSCRIBING ORGANIZATION
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Subscribing Organization</strong>. If you are registering or using the Software or the
              Results on behalf of, or in connection with, your employment or engagement with a company, entity
              or organization (collectively, the “Subscribing Organization”), you represent and warrant that you
              are an authorized representative of that Subscribing Organization with the authority to bind such
              Subscribing Organization to the terms and conditions of this Agreement, and you hereby do agree to be
              bound by the terms and conditions of this Agreement on behalf of such Subscribing Organization. In
              such a case, references to “you” and/or the “Licensee” in this Agreement shall be construed to mean
              you, the Subscribing Organization, and any other individual that uses the Software or the Results on
              the Subscribing Organization’s behalf or in connection with their employment with or engagement by such
              Subscribing Organization. To the extent your Subscribing Organization has a separate transaction
              agreement with the Company, that agreement will define the order of precedence between the terms of this
              Agreement and that separate transaction agreement with respect to such Subscribing Organization,
              provided that you remain individually bound by the terms and conditions of this Agreement with respect
              to your use of the Software and the Results.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          FEES
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Fees & Payment</strong>. The Company shall charge the Licensee or the Subscribing Organization
              and the Licensee or the Subscribing Organization shall pay to the Company a subscription fee
              described on
              <a
                href="https://www.grainfox.ca/"
                target="_blank"
              >
                https://www.grainfox.ca/
              </a>
              or in any transaction agreement between you or the Subscribing Organization and the Company
              (the “Subscription Fee”), as applicable. The Subscription Fee shall be paid in accordance with this
              Agreement or any transaction agreement between you or the Subscribing Organization and the Company,
              plus any applicable taxes (including any goods and services taxes), during the Term. The Company
              reserves the right to change at any time the Subscription Fee without any notice or liability to you
              or any other person. Any amounts remaining unpaid after the due date shall have interest charged
              thereon at a rate of 1.5% per month. If and when applicable, the Licensee or the Subscribing
              Organization, as applicable, shall pay all taxes and any related interest or penalty howsoever
              designated and imposed as a result of the existence or operation of this Agreement, the Results or
              the Software, except for taxes on the income or profits of the Company.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          LICENSES
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Delivery</strong>. Subject to the terms and conditions of this Agreement:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    the Company or a third party will host the Software on its servers for access and reasonable
                    use by the Licensee; and
                  </p>
                </li>
                <li>
                  <p>
                    the Company shall, from time to time and at its sole discretion, provide the Results to the
                    Licensee.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Licenses</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    Subject to the terms and conditions of this Agreement, the Company hereby grants to the
                    Licensee a personal, revocable, non-exclusive, non-assignable, non-transferable,
                    non-sublicenceable right and licence to access and use the Software and the Results in
                    the Territory for the Term in accordance with this Agreement (collectively, the “Licence”).
                    For clarity, the Licence is personal to the Licensee, and the Licence may not be assigned,
                    transferred or sublicensed to any other person or accessed and used for purposes other than
                    as described in this Agreement. Using the Software and the Results for any other purpose
                    or in any other manner is strictly prohibited. The Company retains all rights not expressly
                    granted hereunder. Any access and use of the Software and the Results, other than as
                    specifically authorized under this Agreement, including sublicensing to any person, without
                    the prior written consent of the Company, is strictly prohibited and may, at the discretion
                    of the Company, terminate the Licence.
                  </p>
                </li>
                <li>
                  <p>
                    Subject to the terms and conditions of this Agreement, the Licensee hereby grants to the Company a
                    royalty-free, fully-paid, perpetual, irrevocable, transferable, sublicenceable, worldwide and
                    non-exclusive right and licence to access, reproduce, copy, distribute, create derivative works
                    of, adapt, translate, transmit, arrange, modify, host, bundle, use and fully exploit the Licensee
                    Content and any Intellectual Property relating thereto (the “Content Licence”). The Company can
                    grant sublicences with respect to the Content Licence.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Restrictions</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Licensee agrees and covenants (i) to keep all disclaimers and copyright, trademark and other
                    proprietary notices intact on the Software and the Results; (ii) that access and use of the
                    Software and/or the Results does not transfer to the Licensee any ownership or other rights in the
                    Software or the Results; and (iii) to access and use the Software and the Results only in the
                    manner described expressly in this Agreement and subject to all applicable laws.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee may not use, modify, enhance, translate, transfer, transmit, disclose, copy, release,
                    communicate, reproduce, provide or make available to any third party, in any way, the
                    Confidential Information, the Results and/or the Software, and all Intellectual Property relating
                    thereto, in whole or in part, except as expressly provided for in this Agreement.  The Licensee
                    shall not copy, use, analyze, translate, convert, reverse engineer, decompile, disassemble or
                    otherwise reduce the Software, the Results and/or the Confidential Information, and all
                    Intellectual Property relating thereto, to a human readable form, nor shall the Licensee permit
                    any operator, employee, agent, outside consultant or other third party to do any of the
                    foregoing. Any modification or enhancement to the Software, the Results and/or the Confidential
                    Information, and any Intellectual Property relating thereto, other than as specifically authorized
                    under this Agreement, without the prior written consent of the Company, is strictly prohibited and
                    may, at the discretion of the Company, terminate the Licence and any Intellectual Property made,
                    conceived, acquired, suggested, discovered or developed by the Licensee, or under the Licensee’s
                    direction, either solely or jointly with others, which relates to, relies on or uses the Software,
                    the Results and/or the Confidential Information, and all Intellectual Property relating thereto,
                    shall be treated as Confidential Information of the Company and shall be the sole exclusive
                    property of the Company.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee agrees and covenants to disclose promptly in writing to the Company any such
                    Intellectual Property.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall not sell, market, rent, lease, transfer, distribute, sublicence or create
                    derivative works of the Software, the Results and/or the Confidential Information, and all
                    Intellectual Property relating thereto, in whole or in part, to any third party.
                  </p>
                </li>
                <li>
                  <p>
                    This Agreement shall not be construed as granting or conferring any ownership, security right,
                    title, rights or licences to use or modify in any manner the Software, the Results and/or the
                    Confidential Information, and all Intellectual Property relating thereto, except for the Licence.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee agrees and covenants not to assign or grant a sublicence for this Agreement or any
                    rights or licences obtained pursuant to it.  The Licensee has no right to grant sublicences.
                  </p>
                </li>
              </ol>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          ACKNOWLEDGEMENTS & COVENANTS
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Acknowledgements</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Licensee agrees and acknowledges the validity of the Software and the Results. The Licensee
                    shall not use or modify the Software or the Results in any manner likely to negate, impair or
                    dilute any of the rights of the Company. The Licensee shall not, either during the Term or at any
                    time thereafter, contest or dispute or assist another in contesting or disputing, directly or
                    indirectly, the validity, ownership, control or enforceability of any of the Company’s right,
                    title and interest in and to the Software, the Results and/or the Confidential Information, and
                    all Intellectual Property relating thereto.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee agrees that it shall not at any time file any Intellectual Property, secure and/or
                    register any Intellectual Property or maintain any Intellectual Property relating to or arising
                    out of the Software, the Results and/or the Confidential Information, or any Intellectual Property
                    relating thereto.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall not, to the extent legally enforceable, file any action to challenge or raise
                    any question or objection, or cause to be filed any such action or cause to be raised any such
                    question or objection, to the validity, enforceability, registration or patentability of the
                    Software, the Results and/or the Confidential Information, or any Intellectual Property relating
                    thereto, on any grounds whatsoever.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall provide to the Company all such access, assistance and co-operation as is
                    reasonably requested by the Company in order to facilitate the performance by the Company of its
                    obligations hereunder.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall access and use the Software and the Results in a careful and prudent manner in
                    accordance with this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall (a) comply with all laws and directions of the Company regarding the access and
                    use of the Software and the Results; (b) not, nor shall the Licensee permit any person, other
                    than the Company’s authorized representatives, to modify, disassemble, perform maintenance on,
                    service or attempt any repair or adjustment to the Software.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee agrees to assume the full risk for loss or damage to the Software and/or the Results,
                    however caused, except if caused by the negligent act or omission of the Company.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall keep the Software, the Results and the Confidential Information free and clear
                    of any liens or other encumbrances.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Service Level</strong>. You shall ensure that your systems include reasonable attacker defences
              and security measures. Your systems shall be configured with reasonable security measures related to
              data theft (through SSL encryption) and unauthorized network access from other unlicensed computers
              within your end users.
            </p>
          </li>
          <li>
            <p>
              <strong>Licensee Costs</strong>. The Licensee shall be solely responsible for:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    the cost of all necessary servicing, repair or correction of problems caused by viruses or other
                    harmful components, unless such viruses or other harmful components are the direct result of the
                    Company’s negligence or wilful conduct; and
                  </p>
                </li>
                <li>
                  <p>
                    the cost of acquiring, installing, operating, servicing, maintaining and updating all equipment,
                    computers, carrier data plans, Internet fees, software and communication services not owned or
                    operated by or on behalf of the Company that allows the Licensee to access and use the Software
                    and the Results, specifically the Licensee must provide certain devices, software, and data
                    connections, which the Company does not otherwise supply, to use the Software and the Licensee
                    hereby consents to manually or automatically download and install updates to the Software.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Availability</strong>. The Licensee acknowledges and agrees that the operation and availability
              of the systems used for accessing, using and interacting with the Software, the Results and/or the
              Licensee Content, including the public telephone, computer networks and the Internet, or to transmit
              information whether or not supplied by you or the Company, can be unpredictable and may, from time to
              time, interfere with or prevent the access, the use and/or the operation of the Software, the Results
              and/or the Licensee Content. The Licensee acknowledges and agrees that the bandwidth and servers
              provided by the Company are not guaranteed and the Licensee agrees not to use an unreasonable amount of
              bandwidth or unreasonably burden the Company’s servers. The Company is not responsible for any outages
              at your premises, including internal network, local infrastructure or facilities, unless directly
              attributable to the Company’s negligence or wilful conduct. In the event viruses are detected in your
              local client environment managed by the Company, the Company may be required to secure the systems by
              denying access to infected users. If the virus infection is traced back to you, you will be invoiced for
              remedying the virus. The Licensee acknowledges and agrees that the Results may not be available at all
              times and may contain inaccuracies. The Software and the Results may be interrupted, including for
              maintenance, repairs, upgrades, or network or equipment failures. The Company reserves the right to
              discontinue some or all of the Software and the Results in its sole discretion, including certain
              features and the support for certain devices and platforms. Events beyond the control of the Company
              may affect the Software and the Results, such as events in nature and other force majeure events.
            </p>
          </li>
          <li>
            <p>
              <strong>Licensee Content</strong>. The Licensee acknowledges and agrees that the Company has the
              perpetual and irrevocable right to delete any or all of the Licensee Content and the Results, including
              any Intellectual Property relating thereto, from the Company’s servers and from the Software, whether
              intentionally or unintentionally, and for any reason or no reason, without any liability of any kind to
              the Licensee or any other party. THE LICENSEE UNDERSTANDS AND AGREES THAT THE COMPANY HAS THE RIGHT, BUT
              NOT THE OBLIGATION, TO REMOVE, IN WHOLE OR IN PART, ANY LICENSEE CONTENT, THE RESULTS OR OTHER CONTENT
              RELATED TO THE SOFTWARE, INCLUDING WITHOUT LIMITATION, DATA, ACCOUNT HISTORY AND ACCOUNT CONTENT
              RESIDING ON THE COMPANY’S SERVERS OR THE SOFTWARE, AT ANY TIME FOR ANY REASON OR NO REASON, WITH OR
              WITHOUT NOTICE AND WITH NO LIABILITY OF ANY KIND. Upon request, the Company will provide the Licensee
              with access to or a copy of the Licensee Content within a reasonable timeframe, provided that the
              Company still retains any Licensee Content and subject to restrictions imposed by the Company’s Privacy
              Policy, which is available upon written request or on the Software, and/or applicable laws.
            </p>
          </li>
          <li>
            <p>
              <strong>Representations & Warranties</strong>. When the Licensee distributes or submits the Licensee
              Content on or through the Software, the Licensee represents and warrants: (1) that the Licensee owns or
              otherwise controls all of the rights, including moral rights and Intellectual Property rights, to the
              Licensee Content; (2) that the Licensee can grant or has obtained all rights and consents that are
              necessary for the Licensee to grant any rights and licences described in this Agreement and relating to
              the Licensee Content to the Company, including the Content Licence; (3) that the Licensee Content is
              accurate and is original to the Licensee and that no other party has any rights thereto; (4) that any
              and all access, use, submission, modification, transmission, creation of derivative works, adaptation,
              translation, hosting, bundling, arrangement, distribution and full exploitation of the Licensee
              Content will not infringe the rights of any third party and will not otherwise cause injury to any
              person or entity; and (5) that the Licensee will immediately notify the Company of any Licensee
              Content that does not comply with this Agreement or may infringe the rights of a third party or
              third parties.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          INTELLECTUAL PROPERTY
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Ownership</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Company is the sole and exclusive owner of and shall own all right, title and interest in the
                    Software, the Results and the Confidential Information, and all Intellectual Property relating
                    thereto. The Licensee shall maintain the Software, the Results and the Confidential Information,
                    and all Intellectual Property relating thereto, in confidence, except as otherwise stated in this
                    Agreement. The Software and the Results, and all Intellectual Property relating thereto, shall be
                    treated as the Confidential Information of the Company.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee is the sole and exclusive owner of and shall own all right, title and interest in the
                    Licensee Content.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Assignment of IP</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Licensee assigns and transfers and/or shall cause the assignment and transfer over to the
                    Company of any right, title and interest, worldwide, it may have or may in the future acquire in
                    and to the Software, the Results and the Confidential Information, and all Intellectual Property
                    relating thereto, without any remuneration. The Licensee agrees, at the request of the Company,
                    to do or to cause all lawful acts to secure and protect the Company’s rights and interests in
                    the Software, the Results and the Confidential Information, and all Intellectual Property
                    relating thereto, without any compensation, and the Licensee agrees, when requested by the
                    Company, to execute, acknowledge and deliver to the Company, without compensation, any and all
                    instruments, assignments, waivers and documents relating thereto.
                  </p>
                </li>
                <li>
                  <p>
                    The Company assigns and transfers and/or shall cause the assignment and transfer over to the
                    Licensee of any right, title and interest, worldwide, it may have or may in the future acquire in
                    and to the Licensee Content, without any remuneration.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Waiver</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Licensee waives any rights which the Licensee may have in the Software, the Results and the
                    Confidential Information, and all Intellectual Property relating thereto, including moral rights
                    in the Software, the Results and the Confidential Information, and all Intellectual Property
                    relating thereto.
                  </p>
                </li>
                <li>
                  <p>
                    The Company waives any rights which the Company may have in the Licensee Content, including moral
                    rights in the Licensee Content.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>IP Notices</strong>. The Licensee shall mark in the appropriate place on or within each of the
              items which bear the Software and/or the Results, an appropriate notice of copyright, trademark or
              otherwise as stipulated by the Company from time to time, and a notice that the Licensee is using the
              same as a “Licensee” or other such designation, as required from time to time by the Company. The
              Licensee shall ensure that all proprietary notices, trademark notices, copyright notices and disclaimers
              of the Company, its suppliers or its licensors, as the case may be, on the Software and the Results,
              as provided to the Licensee by the Company, are in place and left intact at all times, and are placed
              in such location or locations as the Company may reasonably advise.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          CONFIDENTIALITY
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Confidentiality</strong>. The parties acknowledge that it shall be necessary for the Company to
              disclose or make available to the Licensee the Confidential Information. The Confidential Information
              shall remain the sole exclusive property of the Company. Both during and after the termination or
              expiration of this Agreement, the Licensee:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    covenants to keep the Confidential Information strictly confidential;
                  </p>
                </li>
                <li>
                  <p>
                    shall make no further use of the Confidential Information upon the return or the destruction of
                    the Confidential Information;
                  </p>
                </li>
                <li>
                  <p>
                    shall not nor will it assist any other person, directly or indirectly, at any time, to (1) use for
                    itself or others, or divulge to others, the Confidential Information; nor (2) use, publish or
                    sell for its own purposes or for any purpose, other than to carry out its obligations under this
                    Agreement, the Confidential Information;
                  </p>
                </li>
                <li>
                  <p>
                    shall take all steps and do all things necessary to preserve the value, confidential nature and
                    proprietary nature of the Confidential Information;
                  </p>
                </li>
                <li>
                  <p>
                    shall immediately notify the Company of any use, disclosure, transfer or transmission of the
                    Confidential Information or any part thereof which is not in accordance with the terms of this
                    Agreement;
                  </p>
                </li>
                <li>
                  <p>
                    may make the Confidential Information available to its agents, contractors or employees having a
                    need to know such information solely for the purposes described in this Agreement and only to the
                    extent necessary for such agents, contractors or employees. Prior to making any such disclosure,
                    the Licensee shall provide to the Company a listing of all persons receiving the Confidential
                    Information and shall cause all such agents, contractors or employees to execute non-disclosure
                    agreements or other agreements containing substantially similar terms and conditions to those
                    contained in this Agreement;
                  </p>
                </li>
                <li>
                  <p>
                    acknowledges and agrees that, save and except for the purpose of discharging its obligations
                    pursuant to this Agreement and save and except as provided for in this Agreement, it has no right
                    whatsoever to any of the Confidential Information; and
                  </p>
                </li>
                <li>
                  <p>
                    shall take all reasonable steps to prevent the accidental or intentional disclosure of the
                    Confidential Information to third parties and shall comply with any reasonable request of the
                    Company with regard to the safeguarding of the Confidential Information.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Exclusions</strong>. Information shall not be considered to be Confidential Information if it:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    becomes publicly available through no fault of the recipient;
                  </p>
                </li>
                <li>
                  <p>
                    at the time communicated by the disclosing party as Confidential Information, was already in the
                    possession of the recipient;
                  </p>
                </li>
                <li>
                  <p>
                    is lawfully received from a third party having the right to disclose the Confidential Information
                    without restriction; or
                  </p>
                </li>
                <li>
                  <p>
                    has been independently developed without access to or use of any Confidential Information.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Requirements</strong>. If the Licensee becomes compelled to disclose any Confidential
              Information pursuant to law, regulation or a lawful order or process (collectively, the “Requirements”),
              the Licensee shall provide the Company with prompt notice of any such Requirement and shall cooperate
              with the Company in seeking to obtain any protective order or other arrangement pursuant to which the
              Confidential Information is preserved. If such an order or arrangement is not obtained, the Licensee
              shall disclose only that portion of the Confidential Information as is required pursuant to such
              Requirement. Any such required disclosure shall not, in and of itself, change the status of the
              disclosed information as Confidential Information under the terms of this Agreement.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          INFRINGEMENT
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Infringement</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Licensee and the Company shall promptly notify each other in writing of any conflicting use,
                    act of infringement or appropriation that comes to their attention regarding the Software, the
                    Results, the Confidential Information and/or the Licensee Content, and all Intellectual Property
                    relating thereto, and shall provide any evidence relating to same which is reasonably available.
                  </p>
                </li>
                <li>
                  <p>
                    In such cases where it is alleged that a third party is infringing the Intellectual Property
                    rights of the Company, the Company shall have the sole right, but not the obligation, at the
                    Company’s sole cost and expense, (i) to bring an action for infringement against the alleged
                    infringer or to take steps as it may deem necessary in order to terminate such conflict,
                    infringement or appropriation; or (ii) to investigate, defend, litigate and settle any such
                    complaint. The Company may in its sole discretion settle any action or complaint as it sees fit.
                    Any damages or sums recovered by the Company in such action or complaint or any settlement
                    thereof shall be retained by the Company. The Licensee shall not at any time settle any such
                    action or complaint without first obtaining the written consent of the Company.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall cooperate fully with and assist the Company to the fullest extent possible on
                    any such action or step and in the event of any such complaint and execute such documents and do
                    such other acts and things as in the opinion of the Company may be necessary, including to testify
                    when requested by the Company, and to make available any records, papers, information, specimens,
                    and the like.
                  </p>
                </li>
              </ol>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          LIMITATIONS AND DISCLAIMERS
        </p>
        <ol class="numbered font-weight-bold">
          <li>
            <p>
              Disclaimers & Limitation of Liability. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR CONDITIONS OR
                    THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO THE LICENSEE, SOME OR ALL
                    OF THE BELOW DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MIGHT NOT APPLY TO THE LICENSEE, AND THE
                    LICENSEE MIGHT HAVE ADDITIONAL RIGHTS.
                  </p>
                </li>
                <li>
                  <p>
                    THE LICENSEE AGREES THAT THE SOFTWARE, THE RESULTS AND THE CONFIDENTIAL INFORMATION, AND ALL
                    INTELLECTUAL PROPERTY RELATING THERETO, ARE PROVIDED BY THE COMPANY ON AN "AS IS" AND "AS
                    AVAILABLE" BASIS. THE LICENSEE FURTHER AGREES THAT THE LICENSEE’S ACCESS AND USE OF THE SOFTWARE,
                    THE RESULTS AND THE CONFIDENTIAL INFORMATION, AND ALL INTELLECTUAL PROPERTY RELATING THERETO,
                    SHALL BE AT THE LICENSEE’S SOLE RISK.
                  </p>
                </li>
                <li>
                  <p>
                    TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, CONDITIONS,
                    GUARANTEES AND/OR REPRESENTATIONS, EXPRESS, STATUTORY IMPLIED OR ARISING BY CUSTOM, COURSE OF
                    DEALING OR TRADE USAGE, IN CONNECTION WITH THE SOFTWARE, THE RESULTS, THE LICENSEE CONTENT AND
                    THE CONFIDENTIAL INFORMATION, AND ALL INTELLECTUAL PROPERTY RELATING THERETO, AND THE LICENSEE’S
                    ACCESS TO AND USE THEREOF, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY, CONDITION,
                    GUARANTEE AND/OR REPRESENTATION OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                    PERFORMANCE, QUALITY, NON-INFRINGEMENT, SECURITY, ACCURACY, COMPLETENESS, SUITABILITY OR ANY
                    IMPLIED WARRANTY, CONDITION, GUARANTEE OR REPRESENTATION ARISING FROM COURSE OF DEALING OR USAGE
                    OF TRADE.
                  </p>
                </li>
                <li>
                  <p>
                    THE COMPANY MAKES NO WARRANTIES, CONDITIONS, GUARANTEES OR REPRESENTATIONS ABOUT THE TRUTHFULNESS,
                    USEFULNESS, ACCURACY OR COMPLETENESS OF THE SOFTWARE, THE RESULTS,  THE LICENSEE CONTENT AND
                    CONFIDENTIAL INFORMATION, AND INCLUDING ALL INTELLECTUAL PROPERTY RELATING THERETO, OR THE
                    AVAILABILITY, QUALITY, CHARACTERISTICS, LEGITIMACY, FUNCTIONALITY, SECURITY OR SAFETY OF ANY OF
                    THE SOFTWARE, THE RESULTS, THE LICENSEE CONTENT AND CONFIDENTIAL INFORMATION, AND INCLUDING ALL
                    INTELLECTUAL PROPERTY RELATING THERETO, AND THE COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY
                    THEREOF.
                  </p>
                </li>
                <li>
                  <p>
                    THE COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A) PERSONAL INJURY OR PROPERTY DAMAGE,
                    OF ANY NATURE WHATSOEVER, RESULTING FROM THE LICENSEE’S ACCESS TO AND USE OF THE SOFTWARE, THE
                    RESULTS AND/OR CONFIDENTIAL INFORMATION, AND INCLUDING ALL INTELLECTUAL PROPERTY RELATING
                    THERETO; (B) ANY UNAUTHORIZED ACCESS TO AND USE OF THE COMPANY’S SECURE SERVERS AND/OR ANY AND
                    ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (C) ANY INTERRUPTION OR
                    CESSATION OF TRANSMISSION TO OR FROM THE SOFTWARE OR THE RESULTS; (D) ANY BUGS, VIRUSES, TROJAN
                    HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE SOFTWARE AND/OR THE RESULTS BY
                    ANY THIRD PARTY; AND/OR (E) ANY ERRORS OR OMISSIONS CONTAINED IN THE SOFTWARE, THE RESULTS, THE
                    LICENSEE CONTENT AND/OR CONFIDENTIAL INFORMATION, AND INCLUDING ALL INTELLECTUAL PROPERTY RELATING
                    THERETO, AND/OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE ACCESS TO OR USE OF
                    THE SOFTWARE, THE RESULTS, THE LICENSEE CONTENT AND/OR CONFIDENTIAL INFORMATION, AND ALL
                    INTELLECTUAL PROPERTY RELATING THERETO.
                  </p>
                </li>
                <li>
                  <p>
                    IN NO EVENT SHALL THE COMPANY BE LIABLE TO THE LICENSEE OR ANY OTHER PERSON FOR ANY INDIRECT,
                    INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY LOSS OR DAMAGE ARISING FROM, CONNECTED
                    WITH, OR RELATING TO (I) THE SOFTWARE, THE RESULTS, THE LICENSEE CONTENT AND/OR CONFIDENTIAL
                    INFORMATION, AND INCLUDING ALL INTELLECTUAL PROPERTY RELATING THERETO, AND (2) THIS AGREEMENT,
                    INCLUDING BUT NOT LIMITED TO, LOSS OF DATA, BUSINESS, BUSINESS INTERRUPTION, MARKETS, SAVINGS,
                    INCOME, PROFITS, USE, PRODUCTION, REPUTATION AND/OR GOODWILL, ANTICIPATED OR OTHERWISE, AND/OR
                    ECONOMIC LOSS, UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR ANY
                    OTHER THEORY OR LAW OR EQUITY), REGARDLESS OF ANY NEGLIGENCE OR OTHER FAULT OR WRONGDOING
                    (INCLUDING WITHOUT LIMITATION GROSS NEGLIGENCE AND FUNDAMENTAL BREACH) BY THE COMPANY OR ANY
                    PERSON FOR WHOM THE COMPANY IS RESPONSIBLE, AND EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH LOSS OR DAMAGE BEING INCURRED. IN THOSE JURISDICTIONS THAT DO NOT ALLOW THE
                    EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
                    JURISDICTIONS, THE LIABILITY OF THE COMPANY SHALL BE LIMITED TO THE FULL EXTENT PERMITTED BY LAW.
                  </p>
                </li>
                <li>
                  <p>
                    THE LICENSEE HAS SOLE RESPONSIBILITY FOR ANY DECISIONS THE LICENSEE MAKES BASED ON INFORMATION
                    CONTAINED IN THE SOFTWARE, THE RESULTS, THE LICENSEE CONTENT AND/OR THE CONFIDENTIAL INFORMATION,
                    AND INCLUDING ALL INTELLECTUAL PROPERTY RELATING THERETO.
                  </p>
                </li>
                <li>
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSEE ACKNOWLEDGES AND AGREES THAT ITS
                    SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH THE COMPANY IS TO STOP USING THE SOFTWARE AND THE
                    RESULTS.
                  </p>
                </li>
                <li>
                  <p>
                    THE COMPANY WILL NOT BE RESPONSIBLE FOR ANY DAMAGES THE LICENSEE OR ANY THIRD PARTY MAY SUFFER AS
                    A RESULT OF THE TRANSMISSION, STORAGE OR RECEIPT OF THE RESULTS AND CONFIDENTIAL OR PROPRIETARY
                    INFORMATION THAT THE LICENSEE MAKES OR THAT THE LICENSEE EXPRESSLY OR IMPLICITLY AUTHORIZES THE
                    COMPANY TO MAKE, OR FOR ANY ERRORS OR ANY CHANGES MADE TO ANY TRANSMITTED, STORED OR RECEIVED
                    INFORMATION OR RESULTS.
                  </p>
                </li>
                <li>
                  <p>
                    THE COMPANY’S TOTAL AGGREGATE LIABILITY TO THE LICENSEE OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE
                    IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES THE LICENSEE PAID TO THE COMPANY; AND (B)
                    $100. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO THE LICENSEE.
                  </p>
                </li>
                <li>
                  <p>
                    IN ADDITION, NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY THE LICENSEE FROM THE COMPANY
                    SHALL CREATE ANY WARRANTY.
                  </p>
                </li>
                <li>
                  <p>
                    THE LICENSEE AND THE COMPANY AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE
                    SOFTWARE, THE RESULTS, THE LICENSEE CONTENT AND/OR CONFIDENTIAL INFORMATION, AND INCLUDING ALL
                    INTELLECTUAL PROPERTY RELATING THERETO, OR THIS AGREEMENT, MUST COMMENCE AND BE FILED WITHIN ONE
                    (1) YEAR AFTER THE CAUSE OF ACTION AROSE; OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                  </p>
                </li>
                <li>
                  <p>
                    USE OF THE SERVICES, PLATFORM, WEBSITE IS NOT AUTHORIZED IN ANY JURISDICTION THAT DOES NOT GIVE
                    EFFECT TO ALL PROVISIONS OF THE TERMS OF USE AND EULA.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              Release. The Licensee releases and forever discharges the Company from and against any and all demands,
              causes of action, liability and claims, at law or in equity, of any nature or kind, including, without
              limitation, that the Licensee, or its successors or assigns, can, has ever had, now or may hereafter
              have arising out of or connected in any way with the Software, the Results, the Licensee Content and/or
              the Confidential Information, and all Intellectual Property relating thereto.
            </p>
          </li>
          <li>
            <p>
              Indemnity. Except if caused by the negligent act or omission of the Company, the Licensee shall
              indemnify and hold harmless the Company from and against any and all claims, damages, losses, expenses
              or liability of any kind whatsoever from third parties relating to:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    the Licensee’s breach of any provision, representation, warranty or covenant of this Agreement; or
                  </p>
                </li>
                <li>
                  <p>
                    any loss, injury, death, damage, expense, charge or cost that the Company may suffer or incur,
                    whether in respect of injury to persons or damage to its property, end-users, or others in any
                    manner that arises out of, or is attributable to the use or access by the Licensee of the
                    Software, the Results and/or the Confidential Information, and all Intellectual Property relating
                    thereto, or any other item provided by the Company hereunder; or
                  </p>
                </li>
                <li>
                  <p>
                    the Licensee Content and the Results, including any claim in respect of infringement of a third
                    party’s Intellectual Property rights. The Company takes no responsibility and assumes no liability
                    for any Licensee Content distributed or submitted by the Licensee or any third party.
                  </p>
                </li>
              </ol>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          TERMINATION
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Term</strong>. The term of this Agreement will commence on the date of your acceptance of this
              Agreement and shall remain in effect until terminated as provided in this Agreement or in any
              transaction agreement between you and the Company (the “Term”).
            </p>
          </li>
          <li>
            <p>
              <strong>Termination</strong>. The parties agree that:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Company shall have the right to terminate this Agreement, at any time and without cause, upon
                    the provision of one (1) month’s written notice to the Licensee.
                  </p>
                </li>
                <li>
                  <p>
                    In the event the Licensee fails to pay the Subscription Fee or other amounts due to the Company
                    pursuant to this Agreement or any transaction agreement between the Company and you, the Company
                    can immediately terminate this Agreement and the rights and licences granted hereunder.
                  </p>
                </li>
                <li>
                  <p>
                    Subject to Section 10.2(a) and Section 10.2(b), in the event that a party (the “Defaulting Party”)
                    shall breach any material provision of this Agreement or fail to observe or perform any covenant
                    or obligation applicable to it under this Agreement, the other party (the “Non-Defaulting Party”)
                    has the right to serve written notice on the Defaulting Party of the Non-Defaulting Party’s intent
                    to terminate this Agreement. The notice of intent to terminate shall specify the alleged breach or
                    failure and if within thirty (30) business days of the date of delivery of such notice to the
                    Defaulting Party, the Defaulting Party has not cured all of the defaults, the Non-Defaulting Party
                    may, at its sole discretion, terminate this Agreement. Such termination shall be without prejudice
                    to any other rights or remedies the Non-Defaulting Party may have in respect of such default.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee agrees to pay any outstanding amounts owing to the Company to the effective date of
                    termination of this Agreement.  Such termination shall not relieve any of the parties from
                    obligations incurred prior to the date of such termination and shall not relieve the Licensee
                    from any of the obligations which survive any termination for any reason of this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    If any party becomes insolvent or bankrupt, dissolves or winds up, this Agreement terminates
                    immediately.
                  </p>
                </li>
                <li>
                  <p>
                    Where either party is given a right to terminate hereunder and does not exercise the same, such
                    forbearance shall not be deemed to be a waiver of such party's right to terminate upon any
                    subsequent or future event by which such party has, or is provided with, the right to terminate
                    this Agreement.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Return</strong>. Upon the expiration or termination of this Agreement, for any reason
              whatsoever:
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    The Licensee shall forthwith deliver to the Company, without charge, the Confidential Information,
                    the Software and the Results, and all Intellectual Property relating thereto, including all
                    copies, and the Licensee shall certify to the Company that no copies of such material have been
                    retained and have been destroyed.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee shall immediately cease any and all access and use of the Software, the Results, the
                    Confidential Information and any other item provided by the Company hereunder, and any
                    Intellectual Property relating thereto, disclosed or provided by the Company to the Licensee in
                    any manner whatsoever.
                  </p>
                </li>
                <li>
                  <p>
                    The Licence will cease and terminate. The Licensee’s access and use to the Software and the
                    Results shall be disabled. The Content Licence shall continue.
                  </p>
                </li>
                <li>
                  <p>
                    The Licensee agrees that the Company has the right to notify any persons it deems necessary or
                    appropriate as to the termination or expiry of the rights granted hereunder.
                  </p>
                </li>
              </ol>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          GENERAL
        </p>
        <ol class="numbered">
          <li>
            <p>
              <strong>Governing Law</strong>. This Agreement will be construed and the legal relationships between
              the parties determined in accordance with the laws of the Province of Manitoba and the laws of Canada,
              without regard to conflicts of laws principles, and the parties expressly attorn to the exclusive
              jurisdiction of the courts of Manitoba for enforcement thereof. Notwithstanding the foregoing, either
              party may apply to the court to obtain injunctive relief and any other available equitable or legal
              remedy regarding any matter relating to their confidentiality or proprietary rights. You and the
              Company expressly exclude the UN Convention on Contracts for the International Sale of Goods and The
              International Sale of Goods Act (Manitoba), as amended, replaced or re-enacted from time to time.
              You agree to waive any right that you may have to: (i) a trial by jury; and (ii) commence or
              participate in any class action against the Company related to the Software, the Results, the
              Licensee Content and this Agreement. You also agree to opt out of any class proceedings against the
              Company.
            </p>
          </li>
          <li>
            <p>
              <strong>Arbitration</strong>. In the event of any dispute arising between the parties concerning the
              subject matter of this Agreement, its enforceability or the interpretation thereof (the “Disagreement”):
              <ol class="alpha-lower-bracket">
                <li>
                  <p>
                    the parties shall attempt to amicably resolve the Disagreement;
                  </p>
                </li>
                <li>
                  <p>
                    if the Disagreement is not resolved pursuant to Section 11.2(a) within thirty (30) days (or such
                    longer period as may be agreed upon between the parties), the parties shall refer the Disagreement
                    to their respective Chief Executive Officers for resolution, or their nominees, if applicable;
                  </p>
                </li>
                <li>
                  <p>
                    if the Disagreement is not resolved pursuant to Section 11.2(b) within thirty (30) days (or such
                    longer period as may be agreed upon between the parties), a mediator shall be appointed by the
                    parties who shall assist the parties in resolving the Disagreement;
                  </p>
                </li>
                <li>
                  <p>
                    if the Disagreement is not resolved under Section 11.2(c) within thirty (30) days (or such longer
                    period as may be agreed upon between the parties), any party may refer the Disagreement to be
                    resolved by arbitration conducted as follows:
                  </p>
                  <ol class="alpha-bracket">
                    <li>
                      <p>
                        any party may require arbitration by giving written notice to arbitrate to the other parties,
                        which written notice shall identify the nature of the Disagreement;
                      </p>
                    </li>
                    <li>
                      <p>
                        if the parties are able to agree upon a single arbitrator, the arbitration shall be conducted
                        before the single arbitrator;
                      </p>
                    </li>
                    <li>
                      <p>
                        if the parties have been unable to agree upon the selection of a single arbitrator within two
                        (2) weeks after receipt of the notice requiring arbitration, such arbitrator shall be
                        appointed by a Judge of the Court of King’s Bench of the Province of Manitoba upon the
                        application of any of the parties, and a Judge of the Court of King’s Bench of the Province
                        of Manitoba shall be entitled to act as such arbitrator, if he or she so desires;
                      </p>
                    </li>
                    <li>
                      <p>
                        the arbitrator shall, as soon as reasonably possible, proceed to hear and determine the
                        Disagreement. The parties agree that it is important that all Disagreements be resolved
                        promptly and the parties, therefore, agree that the arbitration shall be required to be
                        conducted expeditiously and that the final disposition shall be accomplished within three (3)
                        weeks or as soon thereafter as reasonably possible. The parties shall ensure that the
                        arbitrator upon accepting the nomination shall agree that the arbitrator has time available
                        for the timely handling of the arbitration in order to reasonably expect to achieve final
                        disposition within three (3) weeks;
                      </p>
                    </li>
                    <li>
                      <p>
                        the decision of the arbitrator shall be rendered in writing, with reasons, and shall be
                        promptly served upon all parties. The decision of the arbitrator shall be binding upon the
                        parties;
                      </p>
                    </li>
                    <li>
                      <p>
                        in the event of the death, resignation, incapacity, neglect or refusal to act of an
                        arbitrator, and if the neglect or refusal continues for a period of five (5) days after notice
                        in writing of such has been given by any party, another arbitrator shall be nominated or
                        appointed, as described above, to replace the arbitrator;
                      </p>
                    </li>
                    <li>
                      <p>
                        the cost of the arbitration shall be in the discretion of the arbitrator, and shall be borne
                        by each party in accordance with the decision of the arbitrator; and
                      </p>
                    </li>
                    <li>
                      <p>
                        except as to matters otherwise provided herein, the arbitration shall be conducted in
                        accordance with The Arbitration Act (Manitoba) or any successor legislation then in force.
                        The place of arbitration shall be Winnipeg, Manitoba. The language to be used in the
                        arbitration proceedings shall be English.
                      </p>
                    </li>
                  </ol>
                  <p>
                    This Section shall not prevent a party hereto from applying to a court of competent jurisdiction
                    for interim protection such as, by way of example, an interim injunction.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <strong>Currency</strong>. All references to dollar amounts in this Agreement shall be lawful money of
              Canada, unless indicated otherwise.
            </p>
          </li>
          <li>
            <p>
              <strong>General</strong>. All rights and remedies of each party under this Agreement are cumulative and
              may be exercised at any time and from time to time, independently or in combination. If any provision
              of this Agreement is determined by a court of competent jurisdiction to be invalid, illegal or
              unenforceable in any respect, such determination shall not impair or affect the validity, legality or
              enforceability of any other provision of this Agreement. No party shall be bound by any waiver of any
              provision of this Agreement unless such waiver is consented to in writing by that party.  No waiver of
              any provision in this Agreement shall constitute a waiver of any other provision, nor shall any waiver
              constitute a continuing waiver unless otherwise provided. Time shall be of the essence herein.  The
              Licensee and the Company are independent contractors.  No agency relationship or partnership exists
              between them, and neither of them has the right to enter into a contract on behalf of or as an agent
              or representative of the other.  The parties shall execute and deliver, or cause to be executed and
              delivered, upon written request, any and all further documents and do all acts and things or cause such
              acts or things to be performed which may be necessary or desirable to give effect to the terms of this
              Agreement. The parties acknowledge that this Agreement and any existing transaction agreements
              between you and the Company, together with any schedules and appendices attached thereto, constitutes
              the entire agreement between the parties and supersedes all previous representations or agreements,
              written or oral, between the parties hereto. You consent to the exchange of information and
              documents between us electronically over the internet or by email.  You agree that this electronic
              agreement shall be equivalent of a written paper agreement between us.
            </p>
          </li>
          <li>
            <p>
              <strong>Notice</strong>.  Any notice required or authorized under this Agreement to be given by any
              party to the other parties shall be in writing and may be delivered in person or by courier or via
              email, or sent by prepaid registered mail, and addressed to the addresses described on any transaction
              agreement between you and the Company or such other parties or such other addresses as a party shall
              notify the other parties in writing.  Any notice given shall be deemed to be received on the date of
              delivery by person or by courier, as the case may be, or on the fifth business day following the date
              of mailing.  Any notice sent via email shall be deemed to be received on the date of transmission if
              confirmation of delivery is obtained and if such notice is also given by courier.
            </p>
          </li>
          <li>
            <p>
              <strong>Language of Agreement</strong>. The parties hereto confirm that it is their wish that this
              Agreement, as well as other documents related hereto, including notices, have been and shall be drawn
              up in the English language only.  Les parties aux présentes confirment leur volonté à cette convention
              de même que tous les documents, y compris tout avis qui s’y rattache, sont rédigés en anglais seulement.
            </p>
          </li>
          <li>
            <p>
              <strong>Other Remedies</strong>. Except as expressly provided herein to the contrary, the exercise of a
              right of termination or any other right or remedy by either party shall be without prejudice to such
              party's right, subject to the limitations set forth in this Agreement, to pursue any other right or
              remedy available hereunder or under applicable law.
            </p>
          </li>
          <li>
            <p>
              <strong>Survival</strong>. Any indemnity or any obligation of confidence under this Agreement is
              independent and survives termination or expiration of this Agreement.  All obligations under this
              Agreement that necessarily extend beyond termination or expiration of this Agreement in order to fully
              achieve their intended purpose shall survive termination or expiration of this Agreement, including
              without limiting the generality of the foregoing, all indemnification provisions, intellectual property
              provisions, confidentiality provisions, licence provisions, representations, warranties, covenants,
              ownership provisions, disclaimers and limitation of liability provisions.
            </p>
          </li>
          <li>
            <p>
              <strong>Assignment & Enurement</strong>. The Licensee may not assign, transfer or encumber this
              Agreement, or any of its rights or obligations hereunder, without the prior written consent of the
              Company. Any attempted assignment, transfer or encumbrance without the required consent shall be void.
              The consent of the Company to any assignment of this Agreement shall not constitute the Company’s
              consent to a further assignment. Notwithstanding this Section, this Agreement shall be binding upon and
              enure to the benefit of the parties hereto and their respective heirs, executors, personal
              representatives, successors and permitted assigns.
            </p>
          </li>
          <li>
            <p>
              <strong>Force Majeure</strong>. Neither party shall be liable for any failure or delay in performing its
              obligations under this Agreement, or for any loss or damage resulting therefrom, due to acts of God,
              the public enemy, terrorist activities, riots, fires, pandemics, epidemics, and similar causes beyond
              such party’s control.  In the event of such failure or delay, the date of delivery or performance shall
              be extended for a period not to exceed the time lost by reason of the failure or delay; provided that
              the party affected by such delay is using commercially reasonable efforts to mitigate or eliminate the
              cause of such delay or its effects.
            </p>
          </li>
          <li>
            <p>
              <strong>Non-Solicitation</strong>.  Both during this Agreement and after the termination or expiration
              of this Agreement for a period of one (1) year, the Licensee shall not nor attempt to, directly or
              indirectly, whether for the Licensee’s own benefit or for the benefit of any other entity or individual,
              solicit, encourage, induce or in any way influence any person employed by, or engaged to render
              services on behalf of the Company, to leave the Company or to engage in any activity contrary to or
              conflicting with the interests of the Company.
            </p>
          </li>
          <li>
            <p>
              <strong>Injunctive Relief</strong>. Notwithstanding this Agreement, any breach of the Articles titled
              “CONFIDENTIALITY”, “INTELLECTUAL PROPERTY”, “LICENCES”, “ACKNOWLEDGEMENTS & COVENANTS”, or
              “INFRINGEMENT” is a breach of this Agreement that may cause serious and irreparable harm to the Company.
              Any such breach will entitle the Company to injunctive relief, in addition to all other legal or
              equitable remedies that may be available.
            </p>
          </li>
          <li>
            <p>
              <strong>Anti-Spam Consent</strong>. In the event that Canada's Anti-Spam Legislation applies to the
              installation of the Software and related upgrades, the Company hereby seeks and the Licensee hereby
              provides consent to the Company for such installation and potential future upgrades installation so
              that the Licensee may use the Software, provided that the Licensee may revoke such consent at any time.
              The function and purpose of the Software that is to be installed or may have upgrades is described in
              this Agreement. The mailing address and contact information for the Company is noted in this Agreement.
            </p>
          </li>
          <li>
            <p>
              <strong>Changes to EULA</strong>. The Company reserves the right, at its sole discretion, to change,
              amend, modify, add or remove terms and conditions contained in this Agreement, at any time, without
              further notice by posting any changes on the Software. Any new terms or conditions will be effective
              as soon as they are posted. It is your responsibility to check this Agreement and the Software
              periodically and you are deemed to be aware of such changes. Your continued use of the Software and/or
              the Results following the posting of such changes will mean that you accept and agree to the changes.
              If you do not agree to the changes, please stop using the Software and the Results.
            </p>
          </li>
          <li>
            <p>
              <strong>Unsolicited Commercial Electronic Messages</strong>. The inclusion of any email addresses on the
              Software, the Results or within this Agreement does not constitute consent to receiving unsolicited
              commercial electronic messages or SPAM.
            </p>
          </li>
          <li>
            <p>
              <strong>Third Parties</strong>. The Software may allow the Licensee to access, use, or interact with
              websites, applications, content, and other products and services that are not provided by the Company.
              For example, the Licensee may choose to use third-party data backup services (such as iCloud or Google
              Drive) that may be used with some of the Software. Please note that when the Licensee uses these other
              websites, applications, content, and other products and services, the third party terms of use and
              privacy policies will govern the Licensee’s use of those websites, applications, content, and other
              products and services. The Company is not responsible or liable for your use of those websites,
              applications, content, and other products and services, the third party’s terms of use or policies, or
              any actions you take under the third party’s terms of use and policies.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Providers</strong>.  The Company may engage third parties to provide a business,
              professional or technical support function (“Service Providers”). Where such a Service Provider
              may have access to your Licensee Content, the Company uses contractual and other means to ensure
              the License Content is afforded protections which are substantially similar to those provided by
              the Company.
            </p>
          </li>
          <li>
            <p>
              <strong>Contact Us</strong>. If you have any questions, concerns or comments, please contact us at:
            </p>
            <p
              v-if="!$isMobile"
              class="pb-2"
            >
              <strong>GrainFox Corp.</strong>
              <br>
              <strong>Mailing Address:</strong> Box 68085 RPO Osborne Village, Winnipeg, MB R3L 2V9
              <br>
              <strong>Telephone No.:</strong>  1-877-376-5465
              <br>
              <strong>E-mail:</strong> <a href="mailto:info@farmlinksolutions.ca">info@farmlinksolutions.ca</a>
              <br>
            </p>
            <v-btn
              v-if="!$isMobile"
              href="/static/dashboard/documents/eula.docx"
              download="eula.docx"
            >
              <v-icon
                small
                class="pr-1"
              >
                mdi-download
              </v-icon>
              Save EULA
            </v-btn>
          </li>
        </ol>
      </li>
    </ol>

    <p
      v-if="$isMobile"
      class="pb-2"
    >
      <strong>GrainFox Corp.</strong>
      <br>
      <strong>Mailing Address:</strong> Box 68085 RPO Osborne Village, Winnipeg, MB R3L 2V9
      <br>
      <strong>Telephone No.:</strong>  1-877-376-5465
      <br>
      <strong>E-mail:</strong> <a href="mailto:info@farmlinksolutions.ca">info@farmlinksolutions.ca</a>
      <br>
    </p>
    <div
      v-if="$isMobile"
      class="w--100 d-flex justify-center"
    >
      <v-btn
        color="primary"
        href="/static/dashboard/documents/eula.docx"
        download="eula.docx"
        class=""
      >
        <v-icon
          small
          class="pr-1"
        >
          mdi-download
        </v-icon>
        Save EULA
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EULADocument',
};
</script>
<style lang="scss" scoped>
.alpha {
  list-style-type: upper-alpha;
  &-lower {
    list-style-type: lower-alpha;
    &-bracket {
      counter-reset: alphaitem;
      & > li {
        counter-increment: alphaitem;
        &::marker {
          content: "(" counter(alphaitem, lower-alpha) ") ";
        }
      }
    }
  }
  &-bracket {
    counter-reset: alphaitem;
    & > li {
      counter-increment: alphaitem;
      &::marker {
        content: "(" counter(alphaitem, upper-alpha) ") ";
      }
    }
  }
}
ol.numbered {
  counter-reset: item;
  & > li {
    counter-increment: item;
    &::marker {
      content: counters(item, ".") ". ";
    }
  }
}
</style>
