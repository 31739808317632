<template>
  <v-dialog
    :value="value"
    eager
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      tile
      class="d-flex flex-column preview-card"
      @copy="preventCopy"
    >
      <v-toolbar
        class="flex-grow-0 z--1"
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <div>
          <v-btn
            v-if="checkPermission('css_module', 'update')"
            text
            plain
            @click="$emit('edit')"
          >
            EDIT
          </v-btn>
          <v-btn
            v-if="checkPermission('css_module', 'delete')"
            text
            plain
            color="error"
            :disabled="!html"
            @click="$emit('delete')"
          >
            DELETE
          </v-btn>
        </div>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        ref="wrapper"
        class="preview-card__wrapper d-flex flex-column"
      >
        <!--
          We disable eslint no-v-html because we know the HTML being injected
          is safe and sanitized on the backend before saving. This poses no
          more risk than the current implementation found in other file viewers
          which directly inject the HTML rather than using the v-html property
        -->
        <!-- eslint-disable vue/no-v-html -->
        <div
          ref="content"
          class="preview-card__content d-flex flex-column flex-shrink-0 pa-4"
          @dragstart="preventDrag"
          v-html="html"
        />
        <!-- eslint-enable -->
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      id: 0,
      title: '',
      isLoading: false,
      audio_dict: null,
    };
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    html: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('permissions', ['checkPermission']),
  },

  methods: {
    preventCopy(event) {
      event.preventDefault();
    },
    preventDrag(event) {
      event.preventDefault();
    },
    closeFileViewer() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-card {
  height: 100%;
  overflow: hidden;

  &__wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  &__content {
    all: initial;
    user-select: none;
  }
}
</style>
