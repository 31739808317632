import API from '@/api';
import {
  VDEAPIRequest, VDEAPIUpdateToken,
} from '../../helpers/request';

const MLAPI_CREATE_TASK = 'api/data_extraction/sales_contract';
const MLAPI_TASK_STATUS = 'api/data_extraction/task/';
const VDE_ROOT = 'https://vde-ui.farmlinksolutions.ca';
const MLAPI_CORRECTIONS = 'api/data_extraction/store_corrections';

export function createVDEurl(APIRoot, APIEndpoint, APIVersion = 'v1') {
  return new URL(`${APIEndpoint}`, APIRoot);
}

export default {
  state: () => ({
    isSalesContractDialogOpen: false,
    salesContractProgress: 0,
    salesContractFileName: '',
    salesContractFileSize: 0,
    salesContractFileType: '',
    isFetchingMLData: false,
    mlSalesContractData: {},
    scannerEditDialog: false,
    scanContractDialog: false, // Desktop only dialogue
    contractImageData: null,
    scannedAttachment: null,
    filteredContractData: {},
    isContractTableWrapperOpen: false, // To prevent the ContractDeliveryScanner component from rendering twice on mobile,
  }),

  mutations: {
    setIsSalesContractDialogOpen(state, value) {
      state.isSalesContractDialogOpen = value;
    },
    setSalesContractProgress(state, value) {
      state.salesContractProgress = value;
    },
    setSalesContractFileName(state, value) {
      state.salesContractFileName = value;
    },
    setSalesContractFileSize(state, value) {
      state.salesContractFileSize = value;
    },
    setSalesContractFileType(state, value) {
      state.salesContractFileType = value;
    },
    setIsFetchingMLData(state, value) {
      state.isFetchingMLData = value;
    },
    setMlSalesContractData(state, value) {
      state.mlSalesContractData = value;
    },
    setScannerEditDialog(state, value) {
      state.scannerEditDialog = value;
    },
    setScanContractDialog(state, value) {
      state.scanContractDialog = value;
    },
    setContractImageData(state, value) {
      state.contractImageData = value;
    },
    setScannedAttachment(state, value) {
      state.scannedAttachment = value;
    },
    setFilteredContractData(state, value) {
      state.filteredContractData = value;
    },
    setIsContractTableWrapperOpen(state, value) {
      state.isContractTableWrapperOpen = value;
    },
  },

  actions: {
    async updateToken() {
      await VDEAPIUpdateToken();
    },

    async createVDETask({ commit }, payload) {
      const url = createVDEurl(VDE_ROOT, MLAPI_CREATE_TASK);
      try {
        const data = await VDEAPIRequest(url.href, 'post', payload);
        return data;
      } catch (e) {
        this._vm.$snackbar.error('Unable to process file');
        return null;
      }
    },

    async getContractDataFromVDEAPI({ commit }, payload) {
      const task_id = `${MLAPI_TASK_STATUS}${payload}`;
      const url = createVDEurl(VDE_ROOT, task_id);
      try {
        const data = await VDEAPIRequest(url.href, 'get');

        return data;
      } catch (e) {
        this._vm.$snackbar.error('Unable to process file');
        return null;
      }
    },

    async sendCorrectionsDataToVDEAPI({ commit, state, rootGetters }, payload) {
      const original = state.mlSalesContractData?.task_result;
      const contract = await API.getContract({ id: payload });
      const updated = {
        commodity: contract?.commodity_full_name || null,
        contract_id: contract?.contract_identifier || null,
        contract_date: contract?.contract_written.substr(0, 10) || null,
        crop_year: rootGetters['shared/cropYearById'](contract?.crop_year).long_name || null,
        contract_type: contract?.contract_type?.contract_type || null,
        price_type: contract?.price_type?.pricing_type || null,
        region: contract?.region?.name || null,
        buyer: contract?.buyer?.name || null,
        grade: contract?.quality?.grade?.name || null,
        factors: contract?.quality?.factors.map((factor) => factor.name),
        quantity: contract?.quantity || null,
        quantity_unit: contract?.commodity_unit?.abbreviation || null,
        price: Number(contract?.lockin_price) || null,
        price_unit: contract?.price_unit?.abbreviation || null,
        currency: contract?.country?.country_code3 || null,
        delivery_start_date: contract?.delivery_start.substr(0, 10) || null,
        delivery_end_date: contract?.delivery_end.substr(0, 10) || null,
        expected_payout_date: contract?.expected_payout_date?.substr(0, 10) || null,
        terms: contract?.terms.map((term) => term.details),
        comments: contract?.comments || null,
      };

      const url = createVDEurl(VDE_ROOT, MLAPI_CORRECTIONS);
      const res = await VDEAPIRequest(url.href, 'post', { original, updated }, true, true);
    },
  },
  namespaced: true,
};
