<!-- Renderless Component -->

<script>
import { mapActions } from 'vuex';
import Vue from 'vue';
import { postRequest } from '../../helpers/request';
import {
  SMART_PLAN,
  CAD,
  USD,
  SECTIONS_STANDALONE,
  SECTIONS_FORWARDING,
} from './data/constants';
import {
  getChargebeeId,
} from './data/helpers';
import { datadogLogs } from '@datadog/browser-logs';

const CHARGEBEE_SCRIPT_URL = 'https://js.chargebee.com/v2/chargebee.js';
const CHARGEBEE_SITE = window.DJANGO_REQUEST.app.isProduction ? 'farmlinksolutions' : 'farmlinksolutions-test';

export default {
  name: 'Chargebee',

  data() {
    return {
      instance: null,
      portalExpiryDate: null,
      portalSectionsStandalone: SECTIONS_STANDALONE,
      portalSectionsForwarding: SECTIONS_FORWARDING,
    };
  },

  beforeCreate() {
    Vue.prototype.$chargebee = {
      isReady: false,
      openPortal: () => {},
      openCheckout: () => {},
      addAddon: () => {},
      closeAll: () => {},
    };
  },

  async created() {
    await Promise.all([this.initializeChargebee(), this.getAllItemPrices()]);
  },

  beforeDestroy() {
    this.$unloadScript(CHARGEBEE_SCRIPT_URL);
    delete Vue.prototype.$chargebee;
  },

  methods: {
    ...mapActions('chargebee', ['getAllItemPrices']),

    async initializeChargebee() {
      try {
        await this.$loadScript(CHARGEBEE_SCRIPT_URL);
        this.instance = Chargebee.init({
          site: CHARGEBEE_SITE,
          iframeOnly: true,
        });
        Vue.prototype.$chargebee = {
          isReady: true,
          openPortal: this.openPortal,
          openCheckout: this.openCheckout,
          addAddon: this.addAddon,
          closeAll: this.closeAll,
        };
      } catch (e) {
        datadogLogs.logger.error('Chargebee init error', {}, e);
      }
    },

    /**
     * Checks if the portal session
     * is within the expiry period.
     */
    isPortalSessionActive() {
      if (!this.portalExpiryDate) {
        return false;
      }
      return new Date() < this.portalExpiryDate;
    },

    /**
     * Creates a portal session if necessary
     */
    async createPortalSession() {
      try {
        if (!this.isPortalSessionActive()) {
          const session = await postRequest('/commerce/portal/');
          this.portalExpiryDate = new Date(session.expires_at * 1000);
          this.instance.setPortalSession(session);
        }
      } catch (e) {
        datadogLogs.logger.error('Chargebee portal session error', {}, e);
      }
    },

    /**
     * Opens the customer self-serve portal.
     * A new portal session will only be created
     * if one has not been set, or a previous
     * session has passed the expiry time.
     * @param {Object} options Config data for the portal
     * @param {string} options.section Name of the section to open or forward to
     * @param {Object} options.params Parameters to identify the subscription or payment source
     * @param {string} options.params.subscriptionId Subscription ID
     * @param {string} options.params.paymentSourceId Payment source ID
     * @param {Object} options.callbacks Callback functions such as loaded, close, etc.
     */
    async openPortal(options = {}) {
      const {
        section = '',
        params = {},
        callbacks = {},
      } = options;
      const sectionOptions = {
        sectionType: section,
        params,
      };

      try {
        await this.createPortalSession();
        const portal = this.instance.createChargebeePortal();

        if (this.portalSectionsStandalone.includes(section)) {
          // Open a standalone section
          portal.openSection(sectionOptions, callbacks);
        } else if (this.portalSectionsForwarding.includes(section)) {
          // Open the portal then forward to the section
          portal.open(callbacks, sectionOptions);
        } else {
          // Open the basic portal
          portal.open(callbacks);
        }
      } catch (e) {
        datadogLogs.logger.error('Chargebee open portal error', {}, e);
      }
    },

    /**
     * Opens a checkout modal.
     * @param {Object} options Config data for the portal
     * @param {string} options.plan Name of the requested plan
     * @param {string} options.currency Name of the currency unit
     * @param {boolean} options.isMonthly Should billing frequency by monthly? If false, then annual
     * @param {boolean} options.isAuthenticated Whether to use logged in or activation session
     * @param {Array} options.addons List of addon names
     * @param {Object} options.callbacks Callback functions such as loaded, close, etc.
     * @param {boolean} options.endTrial Should the trial be ended immediately?
     */
    openCheckout(options = {}) {
      const {
        plan = SMART_PLAN,
        currency = CAD,
        isMonthly = false,
        isAuthenticated = true,
        defaultPublication = null,
        endTrial = false,
        addons = [],
        coupon = null,
        callbacks = {},
      } = options;

      const id = getChargebeeId(plan, currency, isMonthly);
      const addonIds = addons.map((addon) => {
        if (typeof addon === 'string') {
          return getChargebeeId(addon, currency, isMonthly);
        }
        return addon;
      });
      const url = isAuthenticated ? '/commerce/checkout/' : '/commerce/unauthenticated_checkout/';
      try {
        this.instance.openCheckout({
          hostedPage: () => postRequest(url, {
            plan: id,
            defaultPublication,
            allowAddonPriceOverride: currency === CAD,
            addons: addonIds,
            end_trial: endTrial,
            coupon,
          }),
          ...callbacks,
        });
      } catch (e) {
        datadogLogs.logger.error('Chargebee open checkout error', {}, e);
      }
    },

    /**
     *
     */
    addAddon(options = {}) {
      const {
        currency = CAD,
        isMonthly = true,
        addons = [],
        callbacks = {},
      } = options;

      const addonIds = addons.map((addon) => getChargebeeId(addon, currency, isMonthly));
      try {
        this.instance.openCheckout({
          hostedPage: () => postRequest('/commerce/add_addons/', {
            addons: addonIds,
          }),
          ...callbacks,
        });
      } catch (e) {
        datadogLogs.logger.error('Chargebee addon error', {}, e);
      }
    },

    /**
     * Closes any open modals
     */
    closeAll() {
      this.instance.closeAll();
    },
  },

  render() {
    return null;
  },
};
</script>
