import { postRequest } from '../helpers/request';

// Marketing Message API
async function purgeCache(resourceNames) {
  // Clears the resource from the backend cache for a faster update
  // Without this, it takes a couple of seconds to see some variables update
  await postRequest('/commerce/purge_cache/', { resource_names: resourceNames });
}

export default purgeCache;
