import { get } from 'lodash';
import { postRequest } from '@/helpers/request';

export default {
  state: () => ({
    commoditiesOfInterestDialogOpen: false,
    farmProfileCommoditiesList: [],
    cropInterests: [],
  }),
  namespaced: true,
  mutations: {
    setCommoditiesOfInterestDialogOpen(state, payload) {
      state.commoditiesOfInterestDialogOpen = payload;
    },

    setFarmProfileCommoditiesList(state, payload) {
      state.farmProfileCommoditiesList = payload;
    },

    setCropInterests(state, payload) {
      state.cropInterests = payload;
    },
  },
  actions: {
    async getFarmprofileCommodities({ commit }) {
      const results = await postRequest('/farm_profile/api/get_commodities_in_farm_profile/');
      commit('setFarmProfileCommoditiesList', results['commodities']);
    },

    async getCropInterests({ commit }) {
      const data = await postRequest('/account/get_crop_interests/');
      commit('setCropInterests', data['crop_interests']);
    },

    async updateCropInterests({ commit }, payload) {
      await postRequest('/account/update_crop_interests/', payload);
    },
  },
};
