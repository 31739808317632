<template>
  <component :is="asyncComponents[name]" />
</template>

<script>
import { asyncComponents } from '@/icons/empty-state-icons';

export default {
  name: 'EmptyStateIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    asyncComponents,
  }),

  mounted() {
    this.$parent.$el.classList.add('v-icon--width-auto');
  },
};
</script>

<style lang="scss">
// Should not use scoped attribute.
// Preserves the icon's intrinsic aspect ratio,
// while making the VIcon size prop control the height.
.v-icon--width-auto {
  &, .v-icon__component {
    width: auto !important;
  }
}
</style>
