import { datadogRum } from '@datadog/browser-rum';
import { GF_VERSION } from '@/helpers/environment_variables';
import store from '@/store';

const TEST_DOMAIN_ENVIRONMENTS = {
  'devel.farmlinksolutions.ca': 'devel',
  'quiqfarm.farmlinksolutions.ca': 'staging',
};

export function updateUser(email) {
  datadogRum.setUser({
    email: email ?? store.state.user?.email,
  });
}

function init(env) {
  datadogRum.init({
    applicationId: '38443230-823a-4db3-b86a-4ba7ffd231d0',
    clientToken: 'pube3e7a8d18f80d6aadcf3ba8e26980cf3',
    site: 'datadoghq.com',
    service: 'grainfox',
    env,
    version: GF_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
    /* eslint-disable no-param-reassign */
    beforeSend: (event, context) => {
      if (event.type === 'view') {
        const campaign = store.state.marketingModule?.fetchedCampaign;
        if (campaign?.campaign_name) {
          const loc = context.location;
          const oldQueryString = loc.search;
          const queryParams = new URLSearchParams(oldQueryString);
          queryParams.set('utm_campaign', campaign.campaign_name);
          queryParams.set('utm_source', 'grainfox');
          queryParams.set('utm_medium', campaign.type);
          queryParams.set('utm_id', campaign.key);
          const newUrl = new URL(event.view.url);
          newUrl.search = queryParams.toString();
          event.view.url = newUrl.toString();
        }
      }
      const u = new URL(event.view.url);
      if (u.pathname.startsWith('/activate')) {
        // Sometimes the first part of the group gets saved like a real URL
        // Need to put a number into it to make it a valid URL group
        const re = /\/activate\/([a-zA-Z_-]+)\/(.+)/;
        const match = u.pathname.match(re);
        if (match) {
          u.pathname = `/activate/${match[1]}_1/${match[2]}`;
          event.view.url = u.toString();
        }
      }
      return true;
    },
    /* eslint-enable no-param-reassign */
  });
  datadogRum.setUser({
    email: store.state.user?.email,
  });
  datadogRum.startSessionReplayRecording();
}

export default (() => {
  // Default Case
  // Always initialize for production
  if (store.state.app.isProduction) {
    init('production');
    return;
  }

  // Test Environments
  // If we're not in production, generally we don't want to initialize DataDog,
  // except when the environment matches a LaunchDarkly feature flag.
  // This is meant to provide a mechanism for *temporarily* turning on RUM in other environments.
  // Note that the frontend *currently* does not know how to distinguish devel and staging, which
  // is why I chose to look at the domain name; this is fragile and should be fixed in UT-1844.
  if (window.location.hostname in TEST_DOMAIN_ENVIRONMENTS) {
    const unsubscribe = store.subscribe((mutation) => {
      if (mutation.type === 'launchDarkly/setFlags') {
        // Actual environment must match the LD flag value, which can be set on a per-environment basis
        // Note that LD uses a single "Staging" environment for both staging and devel, but you can set
        // the flag's value to either "Staging" or "Devel" in that environment, depending on which you want.
        // If the flag value doesn't match the current actual environment/hostname then nothing will happen.
        // (The LD flag default is "Production" which is not present in TEST_DOMAIN_ENVIRONMENTS)
        if (TEST_DOMAIN_ENVIRONMENTS[window.location.hostname] === mutation.payload['datadog-rum-env']) {
          init(mutation.payload['datadog-rum-env']);
        }
        unsubscribe();
      }
    });
  }
});
