import { datadogLogs } from '@datadog/browser-logs';
import { GF_VERSION } from '@/helpers/environment_variables';

export default (() => {
  if (DJANGO_REQUEST.app.isProduction) {
    datadogLogs.init({
      clientToken: 'pube3e7a8d18f80d6aadcf3ba8e26980cf3',
      site: 'datadoghq.com',
      version: GF_VERSION,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'],
      sessionSampleRate: 100,
      service: 'grainfox',
      env: 'production',
    });
  }
});
