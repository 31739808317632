import { postRequest } from '../../helpers/request';

export default {
  state: () => ({
    buyerRegions: {},
  }),
  mutations: {
    setBuyerRegions(state, buyerRegions) {
      state.buyerRegions = buyerRegions;
    },
  },
  actions: {
    async fetchBuyerRegions({ commit }) {
      const buyerRegions = await postRequest(
        '/bids/api/get_all_buyer_region_relations/',
      );
      commit('setBuyerRegions', buyerRegions);
    },
  },

  namespaced: true,
};
