<template>
  <v-container
    :class="{'fill-height pb-0 align-start p-rel pt-0': $isMobile, 'pt-16 px-10': !$isMobile}"
    fluid
  >
    <template
      v-if="!$isMobile"
    >
      <v-toolbar
        v-if="!noToolbar"
        class="fixed-header no-shadow grey lighten-5 ml-n3"
        :style="`top: ${$headerOffset}px; left: ${$navigationOffset}px; right: 0;`"
        :elevation="0"
      >
        <v-row class="px-10">
          <v-col
            cols="auto"
            class="px-0"
          >
            <slot name="breadcrumbs" />
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
            class="px-0"
          >
            <slot name="actions" />
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'LayoutNested',

  provide() {
    return {
      $backButtonText: () => 'Back',
    };
  },

  props: {
    noToolbar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasHistory() {
      return window.history.length > 1;
    },
  },

  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
  .fixed-header {
      position: fixed;
      background: #fff;
      z-index:1;

      ::v-deep.v-toolbar__content {
        padding-left: 12px;
        padding-right: 12px;
      }
  }
</style>
