import { datadogRum } from '@datadog/browser-rum';

/**
 * Wraps the flag object with a proxy that will call the onEvaluate callback
 * @param {Record<string, any>} flags The flags object to wrap
 * @param {(key: string, value: any) => void} onEvaluate The callback to run
 * @returns The wrapped object
 */
function wrapWithGetters(flags, onEvaluate) {
  const keys = Object.keys(flags);
  return new Proxy(flags, {
    get(target, key) {
      if (!keys.includes(key)) {
        return target[key];
      }
      onEvaluate(key, target[key]);
      return target[key];
    },
  });
}

export default {
  state: () => ({
    flags: {},
    // For internal use only, do not use in components
    $unobserved: {},
  }),

  mutations: {
    setFlags(state, flags) {
      state.$unobserved = flags;
      state.flags = wrapWithGetters(flags, datadogRum.addFeatureFlagEvaluation);
    },
  },

  namespaced: true,
};
