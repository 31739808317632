<template>
  <component
    :is="component"
    :prepend-inner-icon="prependInnerIcon"
    v-bind="{...$attrs}"
    :items="$attrs.items"
    v-on="$listeners"
    @contextmenu.prevent
  >
    <template
      v-for="slot in Object.keys($scopedSlots)"
      #[slot]="{ item, index }"
    >
      <slot
        :name="slot"
        :item="item"
        :index="index"
      />
    </template>
  </component>
</template>

<script>
import { VAutocomplete, VSelect } from 'vuetify/lib';

export default {
  name: 'GAutoComplete',

  components: {
    VAutocomplete,
    VSelect,
  },

  inheritAttrs: false,

  props: {
    prependInnerIcon: {
      type: String,
      default: '',
    },
    useCustomComponent: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    component() {
      return this.$isMobile && this.useCustomComponent ? 'v-select' : 'v-autocomplete';
    },
  },
};
</script>
