<template>
  <div>
    <v-card
      :class="!$isMobile ? 'mt-4' : 'ma-4'"
    >
      <div
        v-if="loading"
        class="d-flex justify-center py-7"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <div v-else>
        <v-row class="d-flex justify-center py-4">
          <v-icon size="80">
            $vuetify.icons.emptyStateFarm
          </v-icon>
        </v-row>
        <v-card-title class="justify-center text-h5 grey--text text--darken-4">
          <p
            class="text-center title-text"
            :class="!$isMobile ? 'mb-2' : 'mb-0'"
          >
            {{ title }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center">
            <v-col
              class="py-0"
            >
              <p
                class="text-center mx-auto text-body-1 primary-grey--text my-0"
              >
                {{ cardText }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="primary"
              class="mx-auto my-6"
              :disabled="addLoading"
              :loading="addLoading"
              @click="handleAddClick"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Farm Location
            </v-btn>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { VProgressCircular } from 'vuetify/lib';

export default {
  name: 'FarmLocationsEmptyStateCard',

  components: {
    VProgressCircular,
  },

  props: {
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      dialogOpen: true,
      title: 'No farms',
      addLoading: false,
      cardText: 'Add your farm location to get started!',
    };
  },

  computed: {
    ...mapGetters('permissions', ['checkPermission']),

    canCreateFarms() {
      return this.checkPermission('farm_locations', 'create');
    },
  },

  methods: {
    ...mapActions('farmLocations', ['openFarmLocationDialog']),
    handleAddClick() {
      this.addLoading = true;
      this.openFarmLocationDialog();
      this.addLoading = false;
    },
  },
};
</script>
<style lang="scss">
.title-text {
  font-size: 23px;
}
</style>
