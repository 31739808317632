<template>
  <v-dialog
    :value="shouldShowSecurityPrompt && value && shownSecurityPrompt && !isChangingPassword"
    max-width="450px"
    width="100%"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        Security
      </v-card-title>
      <v-card-text class="body-1 text--primary">
        <p>
          It appears as if you haven't set your security questions yet.
        </p>
        <p>
          Would you like to do so?
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="close"
        >
          Not right now
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="redirect"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SetSecurityQuestionsPrompt',
  props: {
    // extra parameters to pass to the dialog to disable prompt showing
    value: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('user', ['shownSecurityPrompt', 'isSecurityQuestionsSet', 'shouldShowSecurityPrompt', 'isChangingPassword']),
  },
  methods: {
    ...mapActions('user', ['toggleSecurityPrompt']),

    async redirect() {
      await this.toggleSecurityPrompt();
      window.location.href = '/account/security';
    },

    async close() {
      await this.toggleSecurityPrompt();
    },
  },
};
</script>
