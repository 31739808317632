<template>
  <div
    ref="container"
  >
    <slot />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IntervalTree from 'node-interval-tree';

export default {
  name: 'IntervalHighlighter',

  props: {
    value: {
      type: Number,
      required: true,
    },
    apply: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tree: null,
      activeSentences: null,
      activeWords: null,
      activeInterval: null,
      baseSentenceNode: null,
      baseWordNode: null,
    };
  },
  computed: {
    ...mapGetters('mediaPlayer', ['activeTTSFile']),
  },
  watch: {
    activeTTSFile() {
      this.initialize();
    },
    value() {
      this.updateContainer();
    },
    apply() {
      this.updateContainer();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    updateContainer() {
      if (!this.tree) {
        return;
      }
      const intervals = this.tree.search(this.value, this.value);
      this.activeInterval = intervals;
      if (this.activeWords) {
        for (let k = 0; k < this.activeWords.length; k += 1) {
          const word = this.activeWords[k];
          word.classList.remove('interval-highlighter-word--highlight');
        }
      }
      if (this.activeSentences) {
        for (let k = 0; k < this.activeSentences.length; k += 1) {
          const sentence = this.activeSentences[k];
          sentence.classList.remove('interval-highlighter-phrase--highlight');
        }
      }
      if (intervals.length === 0 || !this.apply) {
        return;
      }
      this.activeSentences = [];
      this.activeWords = [];
      for (let i = 0; i < intervals.length; i += 1) {
        const node = intervals[i];
        const type = node.getAttribute('data-highlight-type');
        if (type === 'sentence') {
          node.classList.add('interval-highlighter-phrase--highlight');
          this.activeSentences.push(node);
        } else if (type === 'word') {
          node.classList.add('interval-highlighter-word--highlight');
          this.activeWords.push(node);
        }
      }
    },

    destroyContainer() {
      if (this.activeSentences) {
        this.activeSentences = null;
      }
      if (this.activeWord) {
        this.activeWords = null;
      }
    },

    initialize() {
      if (!this.activeTTSFile) {
        return;
      }
      this.tree = new IntervalTree();
      this.destroyContainer();
      const allSentences = this.$refs.container.querySelectorAll(
        '[data-highlight-type="sentence"]',
      );
      const allWords = this.$refs.container.querySelectorAll(
        '[data-highlight-type="word"]',
      );
      for (let i = 0; i < allSentences.length; i += 1) {
        const sentence = allSentences[i];
        sentence.classList.add('interval-highlighter-phrase');
        const timeStart = +sentence.getAttribute('data-highlight-time-start');
        const timeEnd = +sentence.getAttribute('data-highlight-time-end');
        this.tree.insert(timeStart, timeEnd, sentence);
      }
      for (let i = 0; i < allWords.length; i += 1) {
        const word = allWords[i];
        word.classList.add('interval-highlighter-word');
        const timeStart = +word.getAttribute('data-highlight-time-start');
        const timeEnd = +word.getAttribute('data-highlight-time-end');
        this.tree.insert(timeStart, timeEnd, word);
      }
    },
  },
};
</script>
<style lang="scss">
// NOTE: These styles are NOT scoped because they need to apply to elements that are children.
//  Using v-deep doesn't really work because they are dynamic.
//  Additionally, these styles apply GLOBALLY.
//  Only make changes if you are certain of the ramifications.
@use "@/colors/colors";

.interval-highlighter {
  &-phrase {
    transition: background-color 0.3s ease-in-out;

    &--highlight {
      transition: none;
      background-color: rgba(colors.$primary, 0.1);
    }
  }

  &-word {
    transition: background-color 0.3s ease-in-out;
    border-radius: 4px;

    @media (prefers-reduced-motion) {
      transition: none;
    }

    &--highlight {
      transition: none;
      background-color: rgba(colors.$primary, 0.2);

      @media (prefers-reduced-motion) {
        background-color: transparent;
      }
    }
  }
}
</style>
