import { RecommendationRequest, RECOMMENDATIONSAPIUpdateToken, postRequest } from '@/helpers/request';
import { MLAPI_ROOT } from '@/helpers/environment_variables';
import { createMLUrl } from '@/store/modules/smart-advisor/smart-advisor-context';

const MLAPI_FETCH_RECOMMENDATIONS = 'article_recommendations/fetch';

export default {
  state: () => ({
    mlAPIRecommendations: [],
    recommendedArticles: [],
  }),

  mutations: {
    setMLAPIRecommendations(state, payload) {
      state.mlAPIRecommendations = payload;
    },
    setRecommendedArticles(state, payload) {
      state.recommendedArticles = payload;
    },
  },

  actions: {
    async updateToken() {
      await RECOMMENDATIONSAPIUpdateToken();
    },

    async getMLAPIRecommendations({ commit, rootState, state }, contentURL) {
      const userId = rootState?.user?.id;
      const url = createMLUrl(MLAPI_ROOT, MLAPI_FETCH_RECOMMENDATIONS);
      const params = {
        current_article_url: contentURL,
        num_suggestions: 5,
        user_id: userId,
      };
      const data = await RecommendationRequest(url.href, params);
      commit('setMLAPIRecommendations', data?.recommendations);
    },

    async getRecommendedArticles({ commit, state }, payload) {
      const articleInfo = [];
      state.mlAPIRecommendations.forEach((item) => {
        articleInfo.push({
          article_id: item['source'] === 'grainfox' ? item['library_id'] : item['id'],
          source: item['source'],
          score: item['score'],
        });
      });
      const body = {
        articles: articleInfo,
        original_article_link: payload['originalArticleLink'],
        check_cache: payload['checkCache'],
      };
      const data = await postRequest('/library/api/get_recommended_articles', body);
      commit('setRecommendedArticles', data?.results);
    },

    async fetchRecommendedArticles({ dispatch, state }, contentURL) {
      await dispatch('getRecommendedArticles', { originalArticleLink: contentURL, checkCache: true });
      if (state.recommendedArticles.length === 0) {
        await dispatch('updateToken');
        await dispatch('getMLAPIRecommendations', contentURL);
        await dispatch('getRecommendedArticles', { originalArticleLink: contentURL, checkCache: false });
      }
    },
  },

  namespaced: true,
};
