<template>
  <div>
    <v-card
      v-if="showWidget"
      elevation="2"
    >
      <v-row
        v-if="$isMobile"
        align="center"
        justify="center"
        no-gutters
        dense
      >
        <v-col cols="auto">
          <v-img
            class="ml-8"
            src="https://static-assets.dtn.com/branding/dtn/v1/dtn-logo-fullcolor-dark.png"
            width="66px"
          />
        </v-col>
        <v-col cols="auto">
          <h6
            class="v-card__title primary-grey--text text-h6 pb-3"
          >
            Weather Forecast
          </h6>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row
        dense
        class="overflow--hidden"
        :class="{'pl-4': $isMobile}"
      >
        <v-col
          v-if="!$isMobile"
          style="minWidth: 20rem; maxWidth: 20rem;"
          class="py-4 pr-4 pl-7 grey lighten-4"
        >
          <v-row dense>
            <!-- Location Name and Settings :: Start -->
            <v-col
              cols="12"
              class="d-flex justify-space-between"
            >
              <v-skeleton-loader
                v-if="isDataLoading"
                type="heading"
                width="100%"
              />
              <h3
                v-if="isDataNotAvailable || isDataAvailable"
                class="text-truncate max-w--75"
              >
                {{ isDataAvailable ? currentDetails.location : 'Data not available' }}
              </h3>
              <v-layout
                align-center
                justify-end
              >
                <WeatherWidgetSettings />
                <v-btn
                  tag="a"
                  href="/weather/"
                  class="ml-1"
                  icon
                  small
                  title="Weather Page"
                >
                  <v-icon>mdi-open-in-app</v-icon>
                </v-btn>
              </v-layout>
            </v-col>
            <!-- Location Name and Settings :: End -->
            <!-- Current Skeleton :: Start -->
            <v-col
              v-if="isDataLoading"
              cols="12"
              class="align-center"
            >
              <v-layout
                v-for="(x, index) in currentWeather"
                :key="index"
                align-center
                justify-end
                class="mt-2"
              >
                <v-skeleton-loader
                  v-bind="currentDetailsSkeletonAttrs"
                />
              </v-layout>
            </v-col>
            <!-- Current Skeleton :: End -->
            <!-- Current Data :: Start -->
            <v-col
              v-for="(x, index) in currentWeather"
              v-else
              :key="index"
              cols="12"
              class="d-flex w--100 justify-start align-center mt-2"
            >
              <v-img
                :lazy-src="x.icon"
                :src="x.icon"
                class="d-inline-block current-weather-icons"
                aspect-ratio="1"
                height="24"
                max-width="30"
                width="24"
                contain
              />
              <div class="pl-2">
                <span class="subtitle-2 grey--text text--darken-3">
                  {{ x.title }} :
                </span>
                {{ x.value() }}
              </div>
            </v-col>
            <!-- Current Data :: End -->
          </v-row>
          <v-img
            class="mt-4"
            src="https://static-assets.dtn.com/branding/dtn/v1/dtn-logo-fullcolor-dark.png"
            width="66px"
          />
        </v-col>
        <v-col
          class="scroll-d-none d-block p-rel rounded-lg pr-3 pl-0
            user-select--none overflow--hidden"
        >
          <!-- Forecast Slider :: Start -->
          <v-sheet
            v-if="!isDataNotAvailable"
            class="mx-1 pa-0"
            :class="{'pb-4': $isMobile}"
          >
            <v-slide-group
              v-model="weatherModelIsOpen"
              :show-arrows="$vuetify.breakpoint.smAndUp"
            >
              <v-slide-item
                v-for="(x, index) in futureForecast"
                :key="index"
                v-slot="{ active, toggle }"
              >
                <v-card
                  class="ma-2 justify-center align-center"
                  height="235"
                  width="120"
                  @[clickWeatherSetting(isDataLoading)]="toggle"
                >
                  <v-fade-transition
                    group
                    hide-on-leave
                  >
                    <!-- Forecast Extra Details :: Start -->
                    <v-row
                      v-if="active"
                      :key="`${index}-extra`"
                      dense
                      class="fill-height text-center"
                      align="center"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        class="caption font-weight-medium"
                      >
                        <div class="font-weight-bold">
                          Low:
                        </div>
                        <div>
                          {{ x.lowTemp }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="caption font-weight-medium"
                      >
                        <div class="font-weight-bold">
                          High:
                        </div>
                        <div>
                          {{ x.highTemp }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="caption font-weight-medium"
                      >
                        <div class="font-weight-bold">
                          Humidity:
                        </div>
                        <div>
                          {{ x.humidity }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="caption font-weight-medium"
                      >
                        <div class="font-weight-bold">
                          Wind:
                        </div>
                        <div>
                          <span>{{ x.wind.value }}</span>
                          <span class="grey--text text--darken-1 font-weight-bold">
                            {{ x.wind.direction }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Forecast Extra Details :: End -->
                    <!-- Forecast Skeleton :: Start -->
                    <v-row
                      v-if="isDataLoading"
                      :key="`${index}-skeleton`"
                      dense
                      class="fill-height"
                      align-content="center"
                      align="center"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        class="text-center d-flex"
                      >
                        <v-skeleton-loader
                          v-bind="skeletonAttrs"
                        />
                      </v-col>
                    </v-row>
                    <!-- Forecast Skeleton :: End -->
                    <!-- Forecast card details :: Start -->
                    <v-row
                      v-if="isDataAvailable"
                      :key="`${index}-details`"
                      dense
                      class="fill-height"
                      align-content="center"
                      align="center"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        class="text-center caption text-no-wrap lh-1"
                      >
                        {{ x.textForecastName }}
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex text-center"
                      >
                        <v-img
                          :lazy-src="x.iconCode"
                          :src="x.iconCode"
                          class="d-block my-2"
                          aspect-ratio="1"
                          height="35"
                          width="35"
                          contain
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        :class="`text-center h4 font-weight-bold ${x.tempClass} text--darken-4`"
                      >
                        {{ x.temperature }}
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center d-flex
                        justify-center align-center mt-2 px-2"
                        title="Precipitation"
                      >
                        <v-icon dense>
                          mdi-opacity
                        </v-icon>
                        <div class="ml-2 subtitle">
                          {{ x.precipitation }}
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Forecast card details :: End -->
                  </v-fade-transition>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
          <!-- Forecast Slider :: End -->
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else-if="isDataNotAvailable"
    >
      <h6
        v-if="!isDataAvailable"
        class="v-card__title primary-grey--text text-h6"
      >
        Weather Report
      </h6>
      <EmptyWidget
        title="No Weather Report"
        subtitle="Looks like you have multiple farms. Please set one of them as a primary farm to get weather forecasts"
        button-text="Farm Location"
        icon="mdi-weather-partly-cloudy"
      >
        <v-btn
          slot="button"
          color="primary"
          @click="setPrimaryFarmDialogOpen(true)"
        >
          Set Primary Farm
        </v-btn>
      </EmptyWidget>
    </v-card>
    <v-card
      v-else-if="$isDesktop && isDataError"
    >
      <h6
        v-if="!$isMobile && !isDataAvailable"
        class="v-card__title primary-grey--text text-h6"
      >
        Weather Report
      </h6>
      <EmptyWidget
        title="Weather Report Is Not Available"
        subtitle="Try to return later, we are fixing the problem."
        icon="$vuetify.icons.emptyStateError"
      />
    </v-card>
    <primary-farm-dialog
      @done="handleDone"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import WeatherWidgetSettings from './WeatherWidgetSettings.vue';
import EmptyWidget from './EmptyWidget.vue';
import { parseCamelCase, degreeToCompass } from '../../helpers/formatting';
import PrimaryFarmDialog from '@/components/farm_profile/shared/PrimaryFarmDialog.vue';

const mockArray = (length) => {
  const lengthToUse = length ?? 10;
  return new Array(lengthToUse).fill(null).map(() => ({}));
};

export default {
  components: {
    WeatherWidgetSettings,
    EmptyWidget,
    PrimaryFarmDialog,
  },
  data() {
    return {
      weatherModelIsOpen: null,
      skeletonAttrs: {
        class: 'my-6 mx-auto d-flex flex-column justify-space-around align-center',
        type: 'heading, button, button, button',
        width: '120',
        height: '200',
      },
      currentDetailsSkeletonAttrs: {
        class: 'mt-1 mb-3 pa-0 currentSkeletonContianer',
        type: 'list-item',
        width: '100%',
      },
    };
  },
  computed: {
    showWidget() {
      if (this.isCommercial && !this.isAmerican) {
        return false;
      }
      return this.isDataAvailable;
    },

    futureForecast() {
      if (this.weatherData.length) {
        return this.weatherData.map((x) => ({
          tempClass: 'yellow--text',
          temperature: `${x.avgTemperature}° ${this.isAmerican ? 'F' : 'C'}` ?? 'N/A',
          lowTemp: `${x.minTemperature}° ${this.isAmerican ? 'F' : 'C'}` ?? 'N/A',
          highTemp: `${x.maxTemperature}° ${this.isAmerican ? 'F' : 'C'}` ?? 'N/A',
          humidity: x.avgRelativeHumidity ? `${x.avgRelativeHumidity}%` : 'N/A',
          precipitation: x.precipitation?.find((y) => y.probability) ? `${Math.round(this.averagePrecipitation(x.precipitation))} %` : '0 %',
          textForecastName: new Date(x.date).toLocaleString('en-ca', { weekday: 'long', timeZone: 'utc' }),
          iconCode: this.getMappedIcon(x.weatherCode),
          wind: {
            value: x.avgWindSpeed ? `${x.avgWindSpeed} ${this.isAmerican ? 'mph' : 'km/h'}` : `0 ${this.isAmerican ? 'mph' : 'km/h'}`,
            direction: degreeToCompass(x.windDirection),
          },
        }));
      }
      return mockArray();
    },

    currentWeather() {
      if (this.isDataLoading) {
        return mockArray(4);
      }
      const outputArray = [];
      const currentD = { ...this.currentDetails };
      delete currentD.location;
      Object.entries(currentD).forEach(([property, value]) => {
        outputArray.push({
          title: parseCamelCase(property),
          icon: `/static/dashboard/svg/${property}.svg`,
          value: () => {
            if (typeof value === 'function') {
              return value();
            }
            return value;
          },
        });
      });
      return outputArray;
    },

    currentDetails() {
      const currentConditions = this.weatherData[0] ?? {};
      return {
        location: this.stationsByCode[currentConditions.stationId]?.name
          ?? currentConditions?.stationId,
        temperature: typeof currentConditions?.avgTemperature !== 'undefined'
          ? `${currentConditions.avgTemperature}° ${this.isAmerican ? 'F' : 'C'}`
          : `0° ${this.isAmerican ? 'F' : 'C'}`,
        dewpoint: typeof currentConditions?.avgDewpoint !== 'undefined'
          ? `${currentConditions.avgDewpoint}° ${this.isAmerican ? 'F' : 'C'}`
          : `0° ${this.isAmerican ? 'F' : 'C'}`,
        relativeHumidity: typeof currentConditions?.avgRelativeHumidity !== 'undefined'
          ? `${currentConditions.avgRelativeHumidity} %`
          : '0 %',
        windSpeed: currentConditions.avgWindSpeed ? `${currentConditions.avgWindSpeed} ${this.isAmerican ? 'mph' : 'km/h'} ${degreeToCompass(currentConditions.windDirection)}` : `0 ${this.isAmerican ? 'mph' : 'km/h'} --`,
      };
    },

    isDataLoading() {
      return this.dashboardWeatherWidgetStatus === 'loading';
    },

    isDataNotAvailable() {
      return this.dashboardWeatherWidgetStatus === 'dataNotAvailable';
    },

    isDataAvailable() {
      return this.dashboardWeatherWidgetStatus === 'dataAvailable';
    },

    isDataError() {
      return this.dashboardWeatherWidgetStatus === 'dataError';
    },

    ...mapState('weather', [
      'weatherData',
      'forecastImgMapping',
      'dashboardWeatherWidgetStatus',
    ]),
    ...mapGetters('weather', ['stationsByCode']),
    ...mapState('farmLocations', [
      'farmLocations',
    ]),
    ...mapGetters('subscription', ['isAmerican', 'isCommercial']),
  },

  watch: {
    async farmLocations() {
      await this.getWeatherReport();
    },
    showWidget: {
      handler(showWidget) {
        this.setWeatherTab(!showWidget);
      },
      immediate: true,
    },
  },

  async mounted() {
    await this.loadFarmLocation();
    await this.fetchFarmLocations();
  },

  methods: {
    ...mapActions('farmLocations', ['fetchFarmLocations']),

    // click method
    clickWeatherSetting(isDataLoading) {
      return !isDataLoading ? 'click' : null;
    },

    getMappedIcon(iconCode) {
      if (this.forecastImgMapping && iconCode) {
        const obj = this.forecastImgMapping.find((x) => Number(x.iconCode) === Number(iconCode));
        return `/static/dashboard/svg/${obj.code}.svg`;
      }
      return '';
    },

    scrollToEnd() {
      const container = document.getElementsByClassName('weather-forcast--container')[0];
      container.scrollLeft = container.scrollWidth;
    },

    handleDone() {
      this.loadFarmLocation();
    },

    async getWeatherReport() {
      if (this.farmLocations.length === 0) {
        await this.loadFarmLocation();
      }

      await this.loadAllLocations();
      await this.requestWeatherDetails();
      await this.loadInitialDashboardWeather();
    },

    averagePrecipitation(precipitations) {
      return precipitations.filter((precipitation) => precipitation.probability).reduce(
        (totalPercentage, precipitation) => totalPercentage + precipitation.probability,
        0,
      ) / precipitations.length;
    },

    ...mapMutations('farmLocations', [
      'setPrimaryFarmDialogOpen',
    ]),
    ...mapActions('weather', ['loadFarmLocation', 'loadAllLocations', 'loadInitialDashboardWeather', 'requestWeatherDetails',
    ]),
    ...mapActions('dashboard', ['setWeatherTab']),
  },
};
</script>
<style lang="scss">
.humidity-img {
  & > .v-image__image {
    padding-right: 0px;
  }
}

.currentSkeletonContianer {
  .v-skeleton-loader__list-item {
    height: auto;
  }
}
</style>
