import {
  apiCreateSearch,
  apiReadBidsMapSearches,
  apiDeleteBidsMapSearch,
  apiPerformSearch,
  apiGetLastSearch,
  apiGetDefaultFarmLocation,
  apiGetSeasonalityData,
  apiCreatePriceAlert,
  apiGetPriceAlerts,
  apiDeletePriceAlert,
  apiUpdatePriceAlert,
  apiUpdateOrCreatePriceMapperProgress,
} from '@/api/priceMapper';
import { encodeObject, isEmptyObject } from '@/helpers/transformations';
import { datadogLogs } from '@datadog/browser-logs';

export default {
  state: () => ({
    savedSearches: [],
    lastSearch: {},
    currentlyActiveSearch: {},
    currentFilters: {},
    defaultFarmLocation: {},
    savedSearchesPopupState: false,
    showBackdrop: false,
    showPriceAlertDrawer: false,
    seasonalityData: [],
    priceAlerts: [],
    onboardingData: {},
    onboardingOrder: [
      'commodity_selected',
      'applied_filters',
      'viewed_price_bubble',
      'viewed_more_prices',
      'viewed_nearby_prices',
      'saved_search',
      'viewed_saved_searches',
      'minimized_filters',
    ],
    onboardingOrderMobile: [
      'commodity_selected',
      'applied_filters',
      'viewed_price_bubble',
      'viewed_more_prices',
      'saved_search',
      'viewed_nearby_prices',
      'viewed_saved_searches',
      'minimized_filters',
    ],
    currentOnboardingStepIndex: 0,
    currentOnboardingStepIndexMobile: 0,
    searchCount: 0,
  }),

  getters: {
    isCurrentOnboardingStep: (state) => (step) => state.onboardingOrder[state.currentOnboardingStepIndex] === step,
    isCurrentOnboardingStepMobile: (state) => (step) => state.onboardingOrderMobile[state.currentOnboardingStepIndexMobile] === step,
    isCurrentOnboardingStepLast: (state) => state.currentOnboardingStepIndex === state.onboardingOrder.length - 1,
    isCurrentOnboardingStepLastMobile: (state) => state.currentOnboardingStepIndexMobile === state.onboardingOrderMobile.length - 1,
    isCurrentOnboardingStepFirst: (state) => state.currentOnboardingStepIndex === 0,
    isCurrentOnboardingStepFirstMobile: (state) => state.currentOnboardingStepIndexMobile === 0,
    isOnboardingComplete: (state) => state.currentOnboardingStepIndex >= state.onboardingOrder.length,
    isOnboardingCompleteMobile: (state) => state.currentOnboardingStepIndexMobile >= state.onboardingOrderMobile.length,
    currentOnboardingStep: (state) => state.onboardingOrder[state.currentOnboardingStepIndex],
    currentOnboardingStepMobile: (state) => state.onboardingOrderMobile[state.currentOnboardingStepIndexMobile],
    currentOnboardingStepByIndex: (state) => (index) => state.onboardingOrder[index],
  },

  mutations: {
    setSavedSearches(state, payload) {
      state.savedSearches = payload;
    },
    setShowBackdrop(state, payload) {
      state.showBackdrop = Boolean(payload);
    },

    setShowPriceAlertDrawer(state, payload) {
      state.showPriceAlertDrawer = Boolean(payload);
    },
    addSavedSearches(state, payload) {
      state.savedSearches.unshift(payload);
    },
    setSavedSearchesPopupState(state, payload) {
      state.savedSearchesPopupState = payload;
    },
    setCurrentFilters(state, payload) {
      state.currentFilters = payload;
    },
    setCurrentlyActiveSearch(state, payload) {
      state.currentlyActiveSearch = payload;
    },
    updateSavedSearches(state, payload) {
      const index = state.savedSearches.findIndex((object) => object.id === payload);
      state.savedSearches.unshift(state.savedSearches.splice(index, 1)[0]);
    },
    setLastSearch(state, payload) {
      state.lastSearch = payload;
    },
    setDefaultFarmLocation(state, payload) {
      state.defaultFarmLocation = payload;
    },
    setSeasonalityData(state, payload) {
      state.seasonalityData = payload;
    },
    addPriceAlert(state, payload) {
      state.priceAlerts.push(payload);
    },
    setPriceAlerts(state, payload) {
      state.priceAlerts = payload;
    },
    updatePriceAlert(state, payload) {
      const index = state.priceAlerts.findIndex((object) => object.id === payload.id);
      state.priceAlerts.splice(index, 1, payload);
    },
    incrementOnboardingStep(state) {
      const currentStep = state.onboardingOrder[state.currentOnboardingStepIndex];
      state.onboardingData[currentStep] = true;
      let step = state.onboardingOrder[state.currentOnboardingStepIndex];
      while (step && state.onboardingData[step]) {
        state.currentOnboardingStepIndex += 1;
        step = state.onboardingOrder[state.currentOnboardingStepIndex];
      }
    },
    incrementOnboardingStepMobile(state) {
      const currentStepMobile = state.onboardingOrderMobile[state.currentOnboardingStepIndexMobile];
      state.onboardingData[currentStepMobile] = true;
      let step = state.onboardingOrderMobile[state.currentOnboardingStepIndexMobile];
      while (step && state.onboardingData[step]) {
        state.currentOnboardingStepIndexMobile += 1;
        step = state.onboardingOrderMobile[state.currentOnboardingStepIndexMobile];
      }
    },
    setOnboardingData(state, payload) {
      state.onboardingData = payload;
      state.currentOnboardingStepIndex = state.onboardingOrder.findIndex((step) => !payload[step]);
      state.currentOnboardingStepIndexMobile = state.onboardingOrderMobile.findIndex((step) => !payload[step]);
    },
    incrementSearchCount(state) {
      state.searchCount += 1;
    },
  },

  actions: {
    async fetchSavedSearches({ commit }) {
      try {
        const results = await apiReadBidsMapSearches();
        commit('setSavedSearches', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deleteSavedSearches({ commit, state }, savedSearchDetails) {
      try {
        await apiDeleteBidsMapSearch({ search_id: savedSearchDetails.id });
        commit('setSavedSearches', state.savedSearches.filter((x) => x.id !== savedSearchDetails.id));
        this._vm.$snackbar.success(`${savedSearchDetails.name.substring(0, 20)} : deleted successfully`);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async triggerSavedSearch({ commit }, {
      savedSearchDetails, previousRouteQuery, name, id,
    }) {
      try {
        // Deep copy search details to prevent the user from accidentally modifying the saved search when changing the current filters
        commit('setCurrentFilters', JSON.parse(JSON.stringify(savedSearchDetails)));
        // Update url with updated filters
        const filters = isEmptyObject(savedSearchDetails) ? undefined : encodeObject(savedSearchDetails);
        if (previousRouteQuery?.filters !== filters) {
          this._vm.$router.push({
            path: '/bids/map/',
            query: { ...previousRouteQuery, filters },
          }).catch((e) => {
            datadogLogs.logger.error('Trigger saved search error', { }, e);
          });
        }
        await apiPerformSearch({ search_id: id });
        commit('updateSavedSearches', id);
        this._vm.$snackbar.success(`${name.substring(0, 20)} : applied successfully`);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async createSearch({ state, commit }, searchName) {
      const search = {
        name: searchName,
        query: state.currentlyActiveSearch,
      };
      const savedSearch = await apiCreateSearch(search);
      commit('addSavedSearches', savedSearch);
    },

    async createUnsavedSearch({ state }) {
      const search = {
        query: state.currentlyActiveSearch,
      };
      await apiCreateSearch(search);
    },

    async getLastSearch({ commit }) {
      const lastSearch = await apiGetLastSearch();
      if (lastSearch.id !== undefined) {
        await apiPerformSearch({ search_id: lastSearch.id });
        commit('setLastSearch', lastSearch);
      }
    },

    async getDefaultFarmLocation({ commit }) {
      const defaultFarmLocation = await apiGetDefaultFarmLocation();
      commit('setDefaultFarmLocation', defaultFarmLocation.data);
    },

    async getSeasonalityData({ commit, state }) {
      const seasonalityData = await apiGetSeasonalityData({ filters: state.currentlyActiveSearch });
      commit('setSeasonalityData', seasonalityData);
    },

    async createPriceAlert({ commit }, payload) {
      try {
        const alert = await apiCreatePriceAlert({
          commodity: payload.commodity.id,
          price: payload.price,
          buyers: payload.buyers,
          region: payload.location && !payload.location.isFarm ? payload.location.id : null,
          farm_location: payload.location && payload.location.isFarm ? payload.location.id : null,
          delivery_start: payload.deliveryPeriod ? payload.deliveryPeriod[0] : null,
          delivery_end: payload.deliveryPeriod ? payload.deliveryPeriod[1] : null,
          expiry_date: payload.expiryDate,
          radius: payload.range,
        });
        commit('addPriceAlert', alert);
        this._vm.$snackbar.success('Price alert created successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async fetchPriceAlerts({ commit }) {
      try {
        const priceAlerts = await apiGetPriceAlerts();
        commit('setPriceAlerts', priceAlerts);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deletePriceAlert({ state, commit }, payload) {
      try {
        await apiDeletePriceAlert({ price_alert_id: payload.id });
        commit('setPriceAlerts', state.priceAlerts.filter((i) => i !== payload));
        this._vm.$snackbar.success('Price Alert: deleted successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async updatePriceAlert({ commit }, payload) {
      const toUpdate = {
        web_notifications: payload.web_notifications,
        push_notifications: payload.push_notifications,
        app_notifications: payload.app_notifications,
        email_notifications: payload.email_notifications,
      };
      try {
        const updatedAlert = await apiUpdatePriceAlert({
          id: payload.id,
          to_update: toUpdate,
        });
        commit('updatePriceAlert', updatedAlert);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async updateOnboarding({ commit, state }) {
      try {
        commit('incrementOnboardingStep');
        await apiUpdateOrCreatePriceMapperProgress(state.onboardingData);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async updateOnboardingMobile({ commit, state }) {
      try {
        commit('incrementOnboardingStepMobile');
        await apiUpdateOrCreatePriceMapperProgress(state.onboardingData);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    loadOnboarding({ commit, getters }, payload) {
      commit('setOnboardingData', payload);
      if (getters.isCurrentOnboardingStep('saved_search')) {
        commit('incrementSearchCount');
      }
    },
  },

  namespaced: true,
};
