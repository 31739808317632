import { postRequest, postFormRequest } from '../helpers/request';

/**
 * This plugin is a temporary solution to the problem
 * of some parts of the app needing to call a function
 * from outside the compiled app environment.
 *
 * This could be something like Django html/vue hybrid pages
 * that can't import postRequest themselves, or any of the
 * API functions which currently expect a global postRequest.
 *
 * This may also be a valid solution for exposing functions
 * for outside systems like Flutter notifications to call
 * app functions to preview files, open dialogs, etc.
 */

export default {
  install: () => {
    window.postRequest = postRequest;
    window.postFormRequest = postFormRequest;
  },
};
