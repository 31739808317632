/**
 * @param {int} timeUnit hrs, min, or sec in integer
 * Enforces double digit display of time unit
 * @returns formatted string of time unit in 00 format
 */
export function formatTimeUnit(timeUnit) {
  return timeUnit < 10 ? `0${timeUnit}` : timeUnit.toString();
}

/**
 * @param {*} time Date object
 * @returns time remaining between now until 'time' in the format of 00:00:00 （hr：min：sec）
 */
export function countDown(time) {
  // TODO: AV-551 Will this be time aware?
  let countdownInUnix = time - new Date();
  if (countdownInUnix < 0) {
    return {
      days: '0', hours: '00', minutes: '00', seconds: '00',
    };
  }
  const timeToUnix = {
    days: 86400000,
    hours: 3600000,
    minutes: 60000,
    seconds: 1000,
  };
  const remaining = {
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const key in timeToUnix) {
    if (countdownInUnix > timeToUnix[key]) {
      if (key === 'days') {
        remaining[key] = Math.floor(countdownInUnix / timeToUnix[key]).toString();
        countdownInUnix -= remaining[key] * timeToUnix[key];
      } else {
        remaining[key] = formatTimeUnit(Math.floor(countdownInUnix / timeToUnix[key]));
        countdownInUnix -= remaining[key] * timeToUnix[key];
      }
    } else if (key === 'days') {
      remaining[key] = '0';
    } else {
      remaining[key] = '00';
    }
  }
  return remaining;
}
