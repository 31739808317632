const AnalysisDashboardPage = () => import('@/pages/analysis_dashboard/AnalysisDashboardPage.vue');

const routes = [
  {
    path: '/analysis_dashboard/',
    meta: {
      title: 'Advanced Analysis',
    },
    component: AnalysisDashboardPage,
  },
];

export default routes;
