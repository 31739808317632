<template>
  <div
    id="teaser-body"
  >
    <template
      v-if="show"
    >
      <v-overlay
        absolute
        :light="true"
        opacity="0"
        z-index="0"
      >
        <slot />
      </v-overlay>
    </template>
    <template
      v-else
    >
      <slot />
    </template>
    <v-bottom-sheet
      v-if="show"
      ref="bottomSheet"
      :value="true"
      persistent
      no-click-animation
      hide-overlay
      attach="#teaser-body"
    >
      <div
        class="mt-n5 white blur-background"
      />
      <v-card
        :class="[
          'd-flex',
          'flex-column',
          'align-center',
          'justify-start',
          $isMobile ? 'py-6 px-4' : 'pa-10',
          flags['paywall-color-scheme'] ? 'primary-grey': 'grey lighten-3'
        ]"
        style="border-radius: 0 !important"
      >
        <div
          :class="[
            'text-center',
            $isMobile?'text-h6':'text-h5',
            flags['paywall-color-scheme'] ? 'white--text': 'grey--text text--darken-4'
          ]"
          data-qa="teaser-body"
        >
          Uh oh! looks like you don’t have access to this publication with your current plan!
        </div>
        <div
          :class="[
            'pt-6',
            flags['paywall-color-scheme'] ? 'white--text': 'primary-grey--text'
          ]"
        >
          Want access to {{ title }}? Upgrade {{ upgradedPlan }} to unlock this and so much more!
        </div>
        <v-btn
          v-if="$isMobile && !flags['paywall-color-scheme']"
          ref="upgradeButton"
          class="my-6"
          color="primary"
          @click="toPayment"
          @focus="onBtnFocus"
        >
          {{ buttonText }}
        </v-btn>
        <v-list :class="flags['paywall-color-scheme'] ? 'primary-grey' :'grey lighten-3'">
          <v-list-item
            v-for="(item, index) in listItems"
            :key="index"
            :class="[
              'text-subtitle-1',
              flags['paywall-color-scheme'] ? 'white--text': 'grey--text text--darken-4'
            ]"
          >
            <v-icon
              color="success"
              class="pr-2"
            >
              mdi-check
            </v-icon>
            {{ item }}
          </v-list-item>
        </v-list>
        <v-btn
          v-if="!$isMobile || flags['paywall-color-scheme']"
          ref="upgradeButton"
          class="my-2"
          color="primary"
          @click="toPayment"
          @focus="onBtnFocus"
        >
          {{ buttonText }}
        </v-btn>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { datadogRum } from '@datadog/browser-rum';

export default {
  name: 'Teaser',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('chargebee', ['isCommercial']),
    ...mapGetters('subscription', ['isAmerican']),
    buttonText() {
      if (this.isAmerican) {
        return 'UPGRADE PLAN';
      }
      if (this.isCommercial) {
        return 'UPGRADE TO COMMERCIAL PLAN';
      }
      return 'UPGRADE TO SMART PLAN';
    },
    upgradedPlan() {
      if (this.isAmerican) {
        return 'your plan';
      }
      if (this.isCommercial) {
        return 'to a paid subscription';
      }
      return 'to Smart Plan';
    },
    listItems() {
      if (this.isAmerican) {
        return [
          'Get access to Grainfox market insights',
          'Make informed cashflow decisions',
          'Track your commodities, inventory, contract, reports & more',
        ];
      }
      if (this.isCommercial) {
        return [
          'Get access to Grainfox market insights',
          'Get crop updates and personalized sales recommendation',
          'Access USDA, WADSE, Growing Trends reports and more',
          'Access BarChart overview and details',
        ];
      }
      return [
        'Get access to Grainfox market insights',
        'Get crop updates and personalized sales recommendation',
        'Make informed cashflow decisions',
        'Track your commodities, inventory, contract, reports & more',
      ];
    },
  },
  watch: {
    async show(newVal) {
      // Sets the Teaser to show everytime the dialog is opened
      // This is required because Vuetify sets isActive to false
      // when closing but never sets it to true when opening
      const root = document.querySelector(':root');
      if (this.$refs.bottomSheet) {
        if (newVal) {
          this.$refs.bottomSheet.isActive = true;
        }
      }
      if (newVal) {
        root.style.setProperty('--teaser-show-blur', 'blur(5px)');
        root.style.setProperty('--teaser-user-select', 'none');
        root.style.setProperty('--teaser-pointer-events', 'none');
      } else {
        root.style.setProperty('--teaser-show-blur', 'unset');
        root.style.setProperty('--teaser-user-select', 'unset');
        root.style.setProperty('--teaser-pointer-events', 'unset');
      }
    },
  },
  methods: {
    toPayment() {
      window.location.href = '/commerce/tiers/';
      datadogRum.addAction('paywall-color', {
        dark_mode: this.flags['paywall-color-scheme'],
      });
    },
    onBtnFocus(event) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
        this.$refs.upgradeButton.$el.blur();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@use '@/colors/colors';
:root {
  --teaser-show-blur: unset;
  --teaser-user-select: unset;
  --teaser-pointer-events: unset;
}

.v-bottom-sheet {
  width: 100%;
}
.slot {
  overflow: hidden;
}
.blur-background {
    height: 40px;
    opacity: 0.97;
    filter: blur(20px);
}
.blend-blur {
  height: 8px;
  background: linear-gradient(180deg, rgba(250,250,250,0.6) 0%, rgba(238,238,238,1) 100%);
  filter: blur(20px);
}

::v-deep .v-overlay {
  display: inherit !important;
}
::v-deep .v-dialog__content {
   z-index: 1 !important;
}

::v-deep .v-bottom-sheet {
  box-shadow: none!important;
}

::v-deep .g-header--salesRecommendation + .g-layout__content {
  filter: var(--teaser-show-blur);
  user-select: var(--teaser-user-select);
  pointer-events: var(--teaser-pointer-events);
}

@media (min-width: 1261px) {
  ::v-deep .g-layout__container {
    background-color: #fff !important;
  }
  ::v-deep .v-sheet.v-card {
    box-shadow: none !important;
  }
}
</style>
