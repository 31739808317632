export function convertFromSameClass(value, fromUnit, toUnit, conversionTable, exponent = 1) {
  if (fromUnit.unit_classification.classification !== toUnit.unit_classification.classification) {
    throw Error(`${fromUnit.name} and ${toUnit.name} do not have the same classification`);
  }

  if (fromUnit.unit_classification.is_complex) {
    throw Error(`Unit Classification must not be complex: recieved ${fromUnit.unit_classification}`);
  }

  const fromUnitConversion = conversionTable.find((c) => c.from.id === fromUnit.id);
  const toUnitConversion = conversionTable.find((c) => c.from.id === toUnit.id);

  if ((typeof fromUnitConversion === 'undefined') || (typeof toUnitConversion === 'undefined')) {
    throw Error(`Could not find conversions for ${fromUnit.name} and ${toUnit.name}`);
  }
  return (value * (fromUnitConversion.conversion / toUnitConversion.conversion) ** exponent);
}

export function convertProduction(value, fromUnit, toUnit, density, conversionTable, exponent = 1) {
  // direct to convert from same class helper
  // mass -> mass or volume -> volume, area -> area:
  if (fromUnit.unit_classification.classification === toUnit.unit_classification.classification) {
    return convertFromSameClass(value, fromUnit, toUnit, conversionTable, exponent);
  }

  // units class validation when unit class isn't the same
  if (!['Mass', 'Volume'].includes(fromUnit.unit_classification.classification) || !['Mass', 'Volume'].includes(toUnit.unit_classification.classification)) {
    throw Error(`Units classification must be either Mass or Volume: recieved ${fromUnit.name} and ${toUnit.name}`);
  }

  // If there is no density, default to 0
  if (!density.unit || !density.value) {
    return 0;
  }

  /*
  convert from mass to volume
  mass -> volume (volume = mass/density):
  1. conversion density value = convert density numerator to current mass unit
  2. calculate volume = take current mass value divide by new density value
  3. convert volume from density denomrate unit to new volume unit
  */
  const densityMass = density.unit.numerator_unit;
  const densityVolume = density.unit.denominator_unit;
  if (fromUnit.unit_classification.classification === 'Mass') {
    const densityConvertedValue = convertFromSameClass(
      density.value,
      densityMass,
      fromUnit,
      conversionTable,
      1,
    );
    const volumeValue = 1 / densityConvertedValue;
    const convertedVolumeValue = convertFromSameClass(
      volumeValue,
      densityVolume,
      toUnit,
      conversionTable,
    );
    return value * convertedVolumeValue ** exponent;
  }
  /*
  convert from volume to mass
  volume -> mass (mass = density * volume):
  1. conversion density value = convert density denomenator to current volume unit
  2. calculate mass = take new density value multiple by current volume value
  3. convert mass from density numerator unit to new mass unit
  */
  if (fromUnit.unit_classification.classification === 'Volume') {
    const densityConvertedValue = convertFromSameClass(
      density.value,
      densityVolume,
      fromUnit,
      conversionTable,
      -1,
    );
    const massValue = densityConvertedValue;
    const convertedMassValue = convertFromSameClass(
      massValue,
      densityMass,
      toUnit,
      conversionTable,
    );
    return value * convertedMassValue ** exponent;
  }
  // no unit conversion exists, return 0
  throw Error(`Error in Production unit conversion from ${fromUnit.name} to ${toUnit.name}`);
}

export function convertYield(value, fromUnit, toUnit, density, conversionTable) {
  if (fromUnit.unit_classification.classification !== 'Yield' || toUnit.unit_classification.classification !== 'Yield') {
    throw Error(`Units classification must be Yield: recieved ${fromUnit.name} and ${toUnit.name}`);
  }

  let convertedValue = value;
  convertedValue = convertProduction(
    convertedValue,
    fromUnit.numerator_unit,
    toUnit.numerator_unit,
    density,
    conversionTable,
    1,
  );
  convertedValue = convertProduction(
    convertedValue,
    fromUnit.denominator_unit,
    toUnit.denominator_unit,
    density,
    conversionTable,
    -1,
  );
  return convertedValue;
}

export function findYieldUnit(units, prodUnitId, areaUnitId) {
  const yieldUnit = Object.values(units).find((unit) => {
    const numerator = unit.numerator_unit;
    const denominator = unit.denominator_unit;
    const foundTop = numerator !== undefined && numerator.id === prodUnitId;
    const foundBottom = denominator !== undefined && denominator.id === areaUnitId;
    return foundTop && foundBottom;
  });
  if (typeof yieldUnit === 'undefined') {
    throw new Error(
      `Yield unit not found:\nGiven ${units[prodUnitId].name} and ${units[areaUnitId].name}`,
    );
  }
  return yieldUnit;
}

/**
 * Converts a pricing unit to another pricing unit
 * @param value the value to convert
 * @param fromUnit the initial pricing unit
 * @param toUnit the final pricing unit
 * @param density the density of the commodity being converted (for density calculations)
 * @param conversionTable the conversion table to use for this conversion
 */
export function convertPrice(value, fromUnit, toUnit, density, conversionTable) {
  if (fromUnit.unit_classification.classification !== 'Price' || toUnit.unit_classification.classification !== 'Price') {
    throw Error(`Units classification must be Price: recieved ${fromUnit.name} and ${toUnit.name}`);
  }
  // eslint-disable-next-line max-len
  let newVal = convertFromSameClass(value, fromUnit.numerator_unit, toUnit.numerator_unit, conversionTable);
  newVal = convertProduction(
    newVal,
    fromUnit.denominator_unit,
    toUnit.denominator_unit,
    density,
    conversionTable,
    -1,
  );
  return newVal.toFixed(2);
}
