<template>
  <g-dialog
    v-model="dialogValue"
    :title="title"
    disable-fullscreen
    :width="680"
    :action-buttons="buttons"
    @cancel="closeDialog"
  >
    <v-row>
      <v-col
        cols="12"
        class="pt-6 pb-0"
      >
        <div class="subtitle-text-2 grey-darken-4">
          Select the reason for deletion from the list below:
        </div>
        <v-radio-group
          v-model="selectedReason"
          class="py-0 my-0"
          hide-details
        >
          <v-radio
            v-for="reason in deletionReasons"
            :key="reason.value"
            class="mt-0 pl-1 py-3 my-0"
            :value="reason.value"
            hide-details
          >
            <template slot="label">
              <span
                :data-qa="`account-holder-delete-option-${reason.value}`"
                class="text-body-1 grey--text text--darken-4"
              >
                {{ convertLabel(reason) }}
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <div data-qa="account-holder-other-textarea-row">
      <v-textarea
        v-model="otherNotes"
        data-qa="account-holder-other-textarea"
        class="mt-5 mb-7"
        :disabled="selectedReason != 'explicit_request'"
        :placeholder="otherNotesPlaceholder"
        outlined
        maxlength="1000"
        hide-details
        no-resize
      />
    </div>
  </g-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GDialog from '../generic/GDialog.vue';
import { postRequest } from '../../helpers/request';
import { dateFormatShortMonthDateYear } from '@/helpers/formatting';

export default {
  components: {
    GDialog,
  },

  props: {
    /**
     * Whether the modal is open (or not)
     */
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      title: 'Delete Account',
      selectedReason: '',
      otherNotes: '',
      otherNotesPlaceholder: 'Describe "other"',
    };
  },

  computed: {
    ...mapState('cssModule', ['deletionReasons']),
    buttons() {
      return [
        {
          name: 'CANCEL',
          side: 'right',
          type: 'default',
          onClick: this.closeDialog,
          mustValidate: true,
        },
        {
          name: 'DELETE',
          side: 'right',
          type: 'delete',
          onClick: this.handleDeletion,
          mustValidate: true,
          disabled: !this.isValid,
        },
      ];
    },

    isValid() {
      if (this.selectedReason === 'explicit_request') {
        return this.otherNotes.length > 0;
      }
      return !!this.selectedReason;
    },

    dialogValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

  },

  watch: {
    selectedReason() {
      this.otherNotes = '';
    },
  },

  created() {
    this.readPossibleDeletionReasons();
  },

  methods: {
    ...mapActions('cssModule', ['readPossibleDeletionReasons']),
    dateFormatShortMonthDateYear,
    closeDialog() {
      this.$emit('input', false);
    },

    convertLabel(reason) {
      if (reason.value === 'explicit_request') {
        return 'Other';
      }
      return reason.label;
    },

    async handleDeletion() {
      try {
        const result = await postRequest('/account/schedule_for_deletion/', {
          deletion_reason: this.selectedReason,
          deletion_notes: this.otherNotes,
        });
        const expirationDate = dateFormatShortMonthDateYear(new Date(result.expiration_date));
        const successMessage = `Your account will be deleted on ${expirationDate}.`;
        this.$snackbar.success(successMessage);
      } catch (e) {
        // snackbar
        if (e && Array.isArray(e) && e.length > 0) {
          this.$snackbar.error(e[0]);
        } else {
          this.$snackbar.error(e);
        }
      }
      this.closeDialog();
      this.$emit('deleted');
    },
  },
};
</script>
