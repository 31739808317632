const MarketingModule = () => import('@/pages/marketing/MarketingModule.vue');
const MarketingInvitations = () => import('@/pages/marketing/subpages/MarketingInvitations.vue');
const MarketingMessage = () => import('@/pages/marketing/subpages/MarketingMessage.vue');
const MarketingManagingContent = () => import('@/pages/marketing/subpages/MarketingManagingContent.vue');
const ManagingContent = () => import('@/pages/marketing/subpages/ManagingContent.vue');
const MarketingManagingContentAddEdit = () => import('@/pages/marketing/subpages/MarketingManagingContentAddEdit.vue');
const CampaignUrl = () => import('@/pages/marketing/subpages/CampaignUrl.vue');
const CouponsConfig = () => import ('@/pages/marketing/subpages/CouponsConfig.vue');
const CampaignUrlCreateEdit = () => import('@/pages/marketing/subpages/CampaignUrlCreateEdit.vue');

const routes = [
  {
    path: '/marketing_module/',
    name: 'MarketingModule',
    meta: {
      title: 'Marketing Module',
    },
    component: MarketingModule,
    children: [
      {
        path: 'invitations/',
        component: MarketingInvitations,
      },
      {
        path: 'marketing_message/',
        component: MarketingMessage,
      },
      {
        path: 'managing_content/',
        meta: {
          title: 'Managing Content',
        },
        component: ManagingContent,
        children: [
          {
            path: '',
            component: MarketingManagingContent,
            name: 'MarketingManagingContent',
          },
          {
            path: 'new_release_banner/new/',
            name: 'CreateNewFeatureBanner',
            component: MarketingManagingContentAddEdit,
          },
          {
            path: 'new_release_banner/:bannerId/:mode/',
            name: 'EditViewNewFeatureBanner',
            component: MarketingManagingContentAddEdit,
            props: (route) => ({
              mode: route.params.mode,
              bannerId: parseInt(route.params.bannerId, 10) || null,
            }),
          },
        ],
      },
      {
        path: 'campaign_url/',
        component: CampaignUrl,
      },
      {
        path: 'campaign_url/view/new/',
        component: CampaignUrlCreateEdit,
      },
      {
        path: 'campaign_url/view/:campaignKey/',
        component: CampaignUrlCreateEdit,
      },
      {
        path: 'coupons_config/',
        component: CouponsConfig,
      },
    ],
  },
];

export default routes;
