<template>
  <v-card
    v-if="showWidget"
    class="mb-6"
    :class="{'pb-16': isLoading}"
  >
    <v-row
      align="center"
    >
      <v-col
        class="py-0"
      >
        <div
          class="primary-grey--text text-h6 font-weight-bold"
          :class="$isMobile ? 'ml-4 mt-4' : 'ma-6'"
        >
          {{ title }}
        </div>
      </v-col>
      <v-col
        md="5"
      >
        <div class="d-flex">
          <v-select
            v-model="recommendationPreference"
            :items="recommendationPreferences"
            class="text-body-2 font-weight-medium primary-color flex-grow-0"
            :class="{'ml-2' : $isMobile}"
            solo
            flat
            dense
            single-line
            hide-details
            @change="onPreferenceSelect"
          />
          <v-btn
            v-if="!isCommercial"
            class="primary-grey--text transparent font-weight-bold text-button"
            text
            :disabled="isLoading"
            @click="openCommoditiesOfInterestDialog"
          >
            <v-icon
              medium
              class="ma-1"
            >
              mdi-cog
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :cols="$isMobile ? '8' : '3'"
        class="py-0"
      >
        <v-select
          v-model="selectedYear"
          :items="formattedYears"
          class="font-weight-bold"
          :class="$isMobile ? 'ml-2' : 'ml-4'"
          flat
          solo
          item-text="long_name"
          item-value="id"
          return-object
        />
      </v-col>
    </v-row>
    <v-row
      :class="$isMobile ? 'ml-1' : 'mx-4'"
      class="my-0 py-0"
    >
      <v-col>
        <v-chip-group
          v-model="selectedCommodity"
          mandatory
          column
          active-class="primary"
        >
          <v-chip
            v-for="item,index in commoditiesWithRecommendations"
            :key="index"
            :value="item"
          >
            {{ item.display_name }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <template
      v-if="isLoading === true"
    >
      <div class="grey--text text-center py-16">
        Loading...
      </div>
    </template>
    <sales-recommendations-c-t-a v-else-if="showCTA" />
    <v-row
      v-else
    >
      <v-col
        class="pa-0 ma-0"
      >
        <NewSalesRecommendations
          :selected-year="selectedYear"
          :selected-commodity="selectedCommodity"
          :is-smart-advisor-selected="isSmartAdvisorSelected"
        />
      </v-col>
    </v-row>
    <crop-interests
      v-if="!isLoading"
      :from-dashboard="true"
      :show-all-crops="true"
      :crop-data="cropData"
    />
  </v-card>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import SalesRecommendationsCTA from '../farm_profile/SalesRecommendationsCTA.vue';
import CropInterests from '../account/CropInterests.vue';
import NewSalesRecommendations from './NewSalesRecommendations.vue';

export default {
  name: 'NewSalesRecommendationsWidget',

  components: {
    SalesRecommendationsCTA,
    CropInterests,
    NewSalesRecommendations,
  },

  data() {
    return {
      title: 'Sales recommendations',
      isLoading: true,
      cropData: [],
      recommendationPreference: 'analyst',
      recommendationPreferences: [{
        text: 'BY ANALYST',
        value: 'analyst',
      },
      {
        text: 'BY SMART ADVISOR',
        value: 'smart-advisor',
      }],
      selectedCommodity: {},
      selectedCropYearInternal: null,
    };
  },

  computed: {
    ...mapState('analysisDashboard', ['advancedAnalysisCommodities', 'salesRecommendations']),
    ...mapGetters('permissions', ['checkPermission']),
    ...mapGetters('subscription', ['isDeputter', 'isSyngenta', 'isAmerican']),
    ...mapGetters('chargebee', ['isCommercialBasic', 'isCommercial']),
    ...mapGetters('smartAdvisor', ['commoditiesAvailableForOnboarding', 'productionsSorted']),
    ...mapState('farmProfile/salesRecommendations', ['farmProfileCommoditiesList']),
    ...mapState('shared', ['cropYears']),
    ...mapGetters('shared', ['currentCropYear']),

    formattedYears() {
      return this.cropYears.slice(0, 2).map((year, index) => ({
        ...year,
        disabled: index === 0,
      }));
    },

    selectedYear: {
      get() {
        if (this.selectedCropYearInternal) return this.selectedCropYearInternal;
        return this.currentCropYear;
      },
      set(value) {
        this.selectedCropYearInternal = value;
      },
    },

    showCTA() {
      return !this.checkPermission('sales_recommendation', 'read') || this.isCommercialBasic;
    },

    showWidget() {
      return !(this.isAmerican || this.isDeputter || this.isSyngenta);
    },

    isSmartAdvisorSelected() {
      return this.recommendationPreference === 'smart-advisor';
    },

    commoditiesWithRecommendations() {
      if (this.isSmartAdvisorSelected) {
        return this.smartAdvisorCommodities;
      }
      return this.analystCommodities;
    },

    smartAdvisorCommodities() {
      return Object.values(this.productionsSorted)
        .filter((production) => this.commoditiesAvailableForOnboarding[production.commodity])
        .map((production) => this.commoditiesAvailableForOnboarding[production.commodity]);
    },

    analystCommodities() {
      const uniqueCommodities = new Set();
      this.farmProfileCommoditiesList.forEach((commodity) => {
        const matchedCommodity = this.flattenAdvancedAnalysisCommodities.find(
          (availableItem) => availableItem.id === commodity.id,
        );
        if (matchedCommodity && this.hasAnalystRecommendation(matchedCommodity)) {
          uniqueCommodities.add(matchedCommodity.aadCommodity);
        }
      });
      return Array.from(uniqueCommodities);
    },

    flattenAdvancedAnalysisCommodities() {
      return Object.values(this.advancedAnalysisCommodities)
        .flatMap((commodityAvailable) => commodityAvailable.commodities.map((item) => ({
          ...item,
          aadCommodity: commodityAvailable,
        })));
    },

    combinedCommoditiesString() {
      return Object.values(this.smartAdvisorCommodities).map((x) => x.name.replace(/ /g, '_')).join(' ');
    },
  },

  async mounted() {
    this.recommendationPreference = localStorage.getItem('dashboardRecommendationPreference') || 'analyst';
    this.setSalesRecommendationsTab(!this.showWidget);
    // This should be set to 'next' when the next crop year is selected and
    // next crop year's recommendations are available
    this.setActiveContextKey('current');
    await this.getStaticData();

    await this.getSupportedParameters();
    await this.getSmartAdvisorProductions();
    await this.getPersonalizationDataByCropYearId(this.selectedYear?.id);
    await this.getBestSaleData({ commodities: this.combinedCommoditiesString });

    const data = await API.readCommoditiesAndProvinces();
    this.cropData = data.commodities;
    this.isLoading = false;
  },

  methods: {
    ...mapActions('shared', ['fetchAllCropYears']),
    ...mapActions('analysisDashboard', [
      'fetchAllSalesRecommendations',
      'fetchAnalysisDashboardCommodities',
      'fetchPriceMapLinks',
    ]),
    ...mapMutations('farmProfile/salesRecommendations', ['setCommoditiesOfInterestDialogOpen']),
    ...mapActions('dashboard', ['setSalesRecommendationsTab']),
    ...mapActions('smartAdvisor', [
      'fetchAADCommodities',
      'getBestSaleData',
      'getSupportedParameters',
      'getSmartAdvisorProductions',
      'getPersonalizationDataByCropYearId']),
    ...mapActions('farmProfile/salesRecommendations', ['getFarmprofileCommodities']),
    ...mapMutations('smartAdvisor', ['setActiveContextKey']),

    async getStaticData() {
      await Promise.all([
        this.fetchAllCropYears(),
        this.fetchAnalysisDashboardCommodities(),
        this.fetchAllSalesRecommendations(),
        this.fetchPriceMapLinks(),
        this.fetchAADCommodities(),
        this.getFarmprofileCommodities(),
      ]);
    },

    openCommoditiesOfInterestDialog() {
      this.setCommoditiesOfInterestDialogOpen(true);
    },

    onPreferenceSelect(value) {
      localStorage.setItem('dashboardRecommendationPreference', value);
    },

    hasAnalystRecommendation(commodity) {
      return this.salesRecommendations.find(
        (rec) => rec.crop_year_id === this.selectedYear.id
        && rec.commodity_id === commodity.aadCommodity.id && rec.sold_percent > 0,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/colors/colors";

::v-deep .v-text-field__details .v-select__selections input {
  display: none !important;
}

.primary-color::v-deep .v-select__selections {
  color: colors.$primary !important;
}
</style>
