<template>
  <div>
    <span
      v-if="!isCollapsed"
      class="primary-grey--text text-overline font-weight-regular text-no-wrap"
    >
      BROUGHT TO YOU BY
    </span>
    <v-card
      v-if="isCollapsed"
      width="48px"
      height="48px"
      class="my-7"
    >
      <img
        class="pa-1"
        :src="logoCollapsed"
        :alt="alt"
        width="48px"
        height="48px"
        style="object-fit: contain;"
      >
    </v-card>
    <div
      v-else
      class="pa-2"
      style="height: 48px;"
    >
      <img
        :src="logoExpanded"
        :alt="alt"
        height="32px"
        style="object-fit: contain;"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnershipLogo',

  props: {
    logoCollapsed: {
      type: String,
      required: true,
    },
    logoExpanded: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
