<template>
  <g-dialog
    v-model="dialogOpen"
    :title="title"
    disable-fullscreen
    :width="1400"
    :height="400"
    @cancel="closeDialog"
  >
    <v-data-table
      :headers="nlgHeaders"
      :items="nlgRows"
      class="nlg-table"
      height="100%"
      item-key="name"
      sort-by="frequencyValue"
      hide-default-footer
      disable-pagination
      fixed-header
      scroll-x-sticky
    >
      <template #item="{ item }">
        <tr
          :class="selectedCadenceValue === item.frequencyValue ? 'selected-row' : ''"
        >
          <td
            v-for="(val, key) in item"
            :key="key"
          >
            <div v-if="val!==''">
              {{ val }}
            </div>
            <div v-else>
              -
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </g-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import GDialog from '@/components/generic/GDialog.vue';
import { capitalizeFirstChar, removeLy } from '@/helpers/formatting';

export default {
  name: 'NLGDataTable',
  components: {
    GDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      nlgData: [],
    };
  },

  computed: {
    ...mapState('nlg', [
      'selectedDataSet',
      'selectedCrop',
    ]),
    ...mapGetters('nlg', [
      'selectedCadenceValue',
    ]),
    title() {
      return `${this.selectedDataSet} ${this.selectedCrop}`;
    },
    nlgRows() {
      return this.nlgData.map((row) => {
        const { data, frequencyValue } = row;
        return {
          frequencyValue,
          ...data,
        };
      });
    },
    nlgHeaders() {
      if (!this.nlgRows.length) {
        return [];
      }
      const frequencyUnit = this.nlgData[0]?.frequencyUnit;
      return Object.keys(this.nlgRows[0]).map((key) => {
        if (key === 'frequencyValue') {
          return {
            text: frequencyUnit && capitalizeFirstChar(removeLy(frequencyUnit)),
            value: key,
          };
        }
        return {
          text: key && capitalizeFirstChar(key),
          value: key,
        };
      });
    },
    dialogOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  watch: {
    async dialogOpen() {
      if (this.dialogOpen) {
        this.nlgData = await this.getAllData();
      }
    },
  },
  methods: {
    ...mapActions('nlg', ['getAllData']),

    closeDialog() {
      this.dialogOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/colors/colors";

.nlg-table {
  height: 100%;
  & > div {
    height: 100%;
  }

  ::v-deep .selected-row {
    background-color: rgba(colors.$secondary, .2) !important;
  }
}
</style>
