<template>
  <v-card v-if="displayWidget">
    <v-card-title class="primary-grey--text pt-6 text-h6 d-flex justify-space-between">
      <div
        data-qa="latest-news-header"
        class="pl-2"
      >
        Latest Articles
      </div>
      <div>
        <div class="end">
          <v-btn
            text
            color="primary"
            class="mx-auto"
            href="/dashboard/resource_hub/"
            data-qa="latest-news-widget-resource-hub-button"
          >
            Resource Hub
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <div
      class="pa-4 px-6 pb-6 pt-2"
    >
      <template
        v-if="results.length === 0 && isLoading === true"
      >
        <div class="grey--text text-center my-8 py-8">
          Loading...
        </div>
      </template>
      <template
        v-else-if="results.length === 0 && isLoading === false"
      >
        <div class="grey--text text-center my-8 py-8">
          No Results
        </div>
      </template>
      <template
        v-else
      >
        <template
          v-for="(resource, index) in results"
        >
          <LatestNewsItem
            :id="resource.id"
            :key="resource.id"
            :class="{'blur': blurArticle(resource.content)}"
            :name="resource.name"
            :date="resource.date"
            :icon="resource.icon"
            :provider="resource.provider"
            :content="resource.content"
            :subheader="resource.subheader"
            :source="resource.source"
            :audio-file-female="resource.audio_file_female"
            :audio-file-male="resource.audio_file_male"
            :index="index"
            :profile-picture="resource.profile_picture"
            data-qa="latest-news-news-item-container"
            :dava-value="resource.id"
          />
        </template>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { postRequest } from '@/helpers/request';
import LatestNewsItem from '@/components/dashboard/LatestNewsItem.vue';

export default {
  components: {
    LatestNewsItem,
  },
  data() {
    return {
      isLoading: true,
      results: [],
    };
  },
  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('permissions', ['checkPermission']),
    displayWidget() {
      return (this.$isDesktop && (this.isLoading || this.results.length > 0));
    },
  },
  async mounted() {
    try {
      const data = await postRequest('/dashboard/library/latest/');
      this.results = data.results;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  },
  methods: {
    blurArticle(content) {
      if (content === 'Sales Recommendations') {
        return !this.checkPermission('sales_recommendation', 'read');
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.blur {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

</style>
