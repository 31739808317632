// Returns true if value can be used as a number type
export function isNumber(value) {
  if (value === null || value === undefined) {
    return false;
  }
  if (Number.isNaN(+value)) {
    return false;
  }
  return true;
}

// If number is inside [minimum, maximum], returns number
// If number < minimum, returns minimum
// If number > maximum, returns maximum
export function bindToRange(number, minimum, maximum) {
  if (maximum < minimum) {
    // Parameters reversed, we can just invert the call
    return bindToRange(number, maximum, minimum);
  }
  return Math.min(maximum, Math.max(minimum, number));
}

// Scales a number from within [minimum, maximum] to be in
//  the same relative location in range [0, 1]
// If number is not in the range, or is not a number, it returns the original value
export function scale0to1(number, minimum, maximum) {
  // This would produce NaN for number, so we can just return it
  if (minimum === maximum) {
    return number;
  }
  // Just return the value if we cannot run the computation
  if (!isNumber(number) || !isNumber(minimum) || !isNumber(maximum)) {
    return number;
  }
  if (maximum < minimum) {
    // Parameters reversed, we can just invert the call
    return scale0to1(number, maximum, minimum);
  }
  // If the number is outside the range, just return it
  if (number > maximum || number < minimum) {
    return number;
  }
  const slope = 1 / (maximum - minimum);
  return (number - minimum) * slope;
}

/**
 * Calculate the sum of a certain key in a nested object
 * It is expected that every nested object will have a value for key
 * @param objectArray an array of objects of form
 *  [irrelevantKey1: {key: value1, ...}, irrelevantKey2 {key: value2, ...}, ...]
 * @param key which object property to sum over in the array
 * @returns the sum value1 + value2 + ... + valueN
 */
export function sumObjectKey(objectArray, key) {
  return Object.values(objectArray).map((x) => x[key]).reduce((partialSum, x) => partialSum + x, 0);
}
