/* eslint-disable import/named */
import {
  apiReadAllYoutubeVideos,
  apiReadAllVideoCategories,
  apiMarkVideoAsWatched,
  apiToggleFavourite,
  apiReadAllFavourites,
  apiReadSingularVideo,
  apiHandleVideoMetric,
} from '@/api/videos';

export default {
  state: () => ({
    videos: [],
    loadingVideos: false,
    tags: [
      'categories',
      'most recent',
      'older',
      'most views',
      'favourites',
    ],
    filteredCategories: [],
    sortingData: {
      sortBy: {
        categories: ['date_published'],
        'most recent': ['date_published'],
        older: ['date_published'],
        'most views': ['view_count'],
      },
      sortDesc: {
        categories: [true],
        'most recent': [true],
        older: [false],
        'most views': [true],
      },
    },
    selectedTag: 'categories',
    loadingTags: false,
    currentVideo: null,
    currentPage: 1,
    totalVideos: null,
    itemsPerPage: 12,
    videoPageScrollTop: null,
    showPlayer: false,
    favouritedVideos: [],
  }),

  getters: {
    getVideoTags(state) {
      return (video) => video.categories
        .filter((x) => state.filteredCategories
          .some((y) => y.toLowerCase() === x.toLowerCase()));
    },

    sortBy(state) {
      return state.sortingData.sortBy[state.selectedTag];
    },

    sortDesc(state) {
      return state.sortingData.sortDesc[state.selectedTag];
    },

    categories(state) {
      return state.selectedTag === 'categories' ? state.filteredCategories : [];
    },
  },

  mutations: {
    setLoadingVideos(state, payload) {
      state.loadingVideos = payload;
    },

    setVideos(state, payload) {
      state.videos = payload;
    },

    addVideos(state, payload) {
      state.videos = state.videos.concat(payload);
    },

    setTags(state, payload) {
      state.tags = payload;
    },

    setSelectedTag(state, payload) {
      state.selectedTag = payload;
    },

    setLoadingTags(state, payload) {
      state.loadingTags = payload;
    },

    setCurrentVideo(state, payload) {
      state.currentVideo = payload;
    },

    setCurrentPage(state, payload) {
      state.currentPage = payload;
    },

    setTotal(state, payload) {
      state.totalVideos = payload;
    },

    setVideoPageScrollTop(state, payload) {
      state.videoPageScrollTop = payload;
    },

    setShowPlayer(state, payload) {
      state.showPlayer = payload;
    },

    setVideoAsWatched(state, payload) {
      const video = state.videos.find((x) => x.id === payload);
      video.is_watched = true;
    },

    toggleFavouriteVideo(state, payload) {
      const video = state.videos.find((x) => x.id === payload);
      video.is_favourited = !video.is_favourited;
    },

    setFavouritedVideos(state, payload) {
      state.favouritedVideos = payload;
    },

    setVideoCategories(state, payload) {
      state.filteredCategories = payload;
    },
  },

  actions: {
    async loadAllVideos({ commit, state }) {
      const { currentPage, results, total } = await apiReadAllYoutubeVideos({
        itemsPerPage: state.itemsPerPage,
        page: state.currentPage,
      });
      commit('setCurrentPage', currentPage);
      commit('setTotal', total);
      if (results && state.currentPage === 1) commit('setVideos', results);
      if (results && state.currentPage !== 1) commit('addVideos', results);
    },

    async markVideoAsWatched({ commit, state }, currentVideoId) {
      await apiMarkVideoAsWatched({ id: currentVideoId });
      commit('setCurrentVideo', { ...state.currentVideo, is_watched: true });
      commit('setVideoAsWatched', currentVideoId);
    },

    async toggleFavouriteVideos({ commit, state }, currentVideoId) {
      await apiToggleFavourite({ id: currentVideoId });
      commit('setCurrentVideo', { ...state.currentVideo, is_favourited: !state.currentVideo.is_favourited });
      commit('toggleFavouriteVideo', currentVideoId);
    },

    // eslint-disable-next-line no-unused-vars
    async handleVideoMetric(_, options) {
      await apiHandleVideoMetric(options);
    },

    async loadAllFavouriteVideos({ commit }) {
      const result = await apiReadAllFavourites();
      if (result) commit('setFavouritedVideos', result);
    },

    async loadSingularVideoAsCurrent({ commit }, payload) {
      const result = await apiReadSingularVideo(payload);
      if (result) commit('setCurrentVideo', result);
    },

    async loadAllVideoCategories({ commit }, payload = false) {
      try {
        const { results } = await apiReadAllVideoCategories({ include_untracked: payload });
        commit('setVideoCategories', results);
      } catch (e) {
        // ignore the resultant error
      }
    },
  },

  namespaced: true,
};
