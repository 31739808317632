/**
 * Guess the mime type of a resource
 * @param {string} url The resource to guess
 *
 * @returns {string} The guessed mime type
 */
// eslint-disable-next-line import/prefer-default-export
export function getMediaMimeType(url) {
  if (!url) return null;
  const ext = url.split('.').pop();
  switch (ext) {
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'svg':
      return 'image/svg+xml';
    case 'webp':
      return 'image/webp';
    case 'webm':
      return 'video/webm';
    case 'mp3':
      return 'audio/mpeg';
    case 'ogg':
      return 'audio/ogg';
    case 'wav':
      return 'audio/wav';
    case 'mp4':
      return 'video/mp4';
    default:
      return null;
  }
}
