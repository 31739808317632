import { postRequest } from '../../helpers/request';

const BLACKLIST_REGION_REGEX = /(farmbucks)|(cxn360)/i;

export default {
  state: () => ({
    regions: {},
    countries: {},
    provinces: {},
  }),
  getters: {
    servicedCountries: (state) => Object.values(state.countries).filter((country) => country.serviced),
    isBlacklistedRegion: (state) => (region) => {
      let r = region;
      if (typeof r === 'number') {
        r = state.regions[r]?.name;
      } else if (typeof r === 'object') {
        r = r?.name;
      }

      return !r || BLACKLIST_REGION_REGEX.test(r);
    },
    regionsFiltered: (state, getters) => Object.fromEntries(
      Object.entries(state.regions).filter(
        ([, val]) => !getters.isBlacklistedRegion(val),
      ),
    ),
    regionsAlphabetical: (state, getters) => Object.values(getters.regionsFiltered)
      .sort((a, b) => a.name.localeCompare(b.name)),
    countryRegions: (state, getters) => getters.servicedCountries.reduce((countryRegionMapping, country) => ({
      ...countryRegionMapping,
      [country.region]: Object.values(state.regions).filter((region) => region.parent_region_id === country.region),
    }), {}),
    countryProvinces: (state, getters) => getters.servicedCountries.reduce((countryProvinceMapping, country) => ({
      ...countryProvinceMapping,
      [country.id]: Object.values(state.provinces).filter((province) => province.country_id === country.id),
    }), {}),

    getParentCountry: (state, _) => (region) => {
      let currentRegion = region;
      const isCountry = (givenRegion) => Object.values(state.countries).some((country) => country.region === givenRegion?.id);
      while (currentRegion?.parent_region_id && !isCountry(currentRegion)) {
        currentRegion = state.regions[currentRegion.parent_region_id];
      }
      const regionCountry = Object.values(state.countries).find((country) => country.region === currentRegion?.id);
      return regionCountry;
    },

    getRegionByName: (_, getters) => (regionName) => {
      let found = false;
      let start = 0;
      let end = getters.regionsAlphabetical.length;

      let foundRegion = null;
      while (start < end && !found) {
        const mid = Math.floor((start + end) / 2);
        foundRegion = getters.regionsAlphabetical[mid];

        if (regionName > foundRegion.name) {
          start = mid + 1;
        } else if (regionName < foundRegion.name) {
          end = mid;
        } else {
          found = true;
        }
      }

      return found ? foundRegion : null;
    },
  },
  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setProvinces(state, provinces) {
      state.provinces = provinces;
    },
  },
  actions: {
    async fetchRegions({ commit }) {
      const regions = await postRequest(
        '/bids/api/get_all_geopolitical_regions/',
      );
      commit('setRegions', regions);
    },
    async fetchCountries({ commit }) {
      const countries = await postRequest(
        '/bids/countries/',
      );
      commit('setCountries', countries);
    },
    async fetchProvinces({ commit }) {
      const provinces = await postRequest(
        '/bids/api/get_provinces/',
      );
      commit('setProvinces', provinces);
    },
  },

  namespaced: true,
};
