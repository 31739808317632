import Vue from 'vue';
import { postRequest } from '../../../helpers/request';
import { roundTo2Decimals } from '../../../helpers/formatting';

export default {
  state: () => ({
    pricings: [],
    sales: {},
    contractsIdMapping: {},
    salesPageModes: [{ id: 0, text: 'all' }, { id: 1, text: 'contracts' }, { id: 2, text: 'deliveries' }],
    salesPageSelectionIndex: 0,
    savedFilters: {},
    selectedColumns: {},
    allContractCommodities: [],
  }),

  getters: {
    /**
     * Calculates the total quantity of a contract that has been locked in.
     * @param futureContract the contract object to check.
     */
    // eslint-disable-next-line max-len
    contractLockedInQuantity: () => (futureContract) => futureContract.contractpricinglockin_set.reduce((total, curr) => total + curr.quantity, 0),

    /**
     * Checks whether a futures contract is completely priced out.
     * @param contract the contract object to check
     */
    // eslint-disable-next-line max-len
    isPricedOut: (state, getters) => (contract) => getters.isFutures(contract) && getters.contractLockedInQuantity(contract) === contract.quantity,

    getLockinPrice: (state, getters) => (contract) => (getters.isFutures(contract)
      ? roundTo2Decimals(getters.averageCashPrice(contract.contractpricinglockin_set))
      : contract.given_price),

    isFutures: () => (contract) => contract?.price_type?.pricing_type === 'basis' || contract?.price_type?.pricing_type === 'futures',

    isSales: () => (contract) => contract?.contract_type?.contract_type === 'sales',

    isProduction: () => (contract) => contract?.contract_type?.contract_type === 'production',

    averageCashPrice: () => (pricings) => {
      const [priceSum, quantitySum] = pricings.reduce(
        // eslint-disable-next-line max-len
        ([sumPrice, sumQuantity], pricing) => ([sumPrice + pricing.cash_price * pricing.quantity, sumQuantity + pricing.quantity]),
        [0, 0],
      );
      return quantitySum > 0 ? priceSum / quantitySum : 0;
    },

    // Determines unit for factor
    getFactorUnit: (state, getters, rootState) => (factorName) => {
      let unit = null;
      switch (factorName) {
        case 'Test Weight': unit = Object.values(rootState.shared.units).find((u) => u.name === 'pound/bushel A'); break;
        case 'Falling Number': unit = Object.values(rootState.shared.units).find((u) => u.name === 'second'); break;
        default: unit = Object.values(rootState.shared.units).find((u) => u.name === 'per cent'); break;
      }
      if (typeof unit === 'undefined') {
        throw Error('No factor unit found');
      }

      return unit;
    },

    convertedSales(state, getters, rootState, rootGetters) {
      const sales = {};
      const toConvert = ['delivered', 'inventory', 'sold', 'unsold', 'committed'];
      const toUnit = rootGetters['farmProfile/farms/units'].production;

      Object.values(state.sales).forEach((sale) => {
        const comm = sale.commodity;
        if (!comm) return;
        sales[comm] = {
          unit_id: toUnit,
        };

        Object.keys(sale).forEach((key) => {
          if (toConvert.indexOf(key) !== -1) {
            sales[comm][key] = rootGetters['shared/convert'](
              sale[key],
              sale.unit_id,
              toUnit,
              comm,
            );
          }
        });
      });

      return sales;
    },

    salesPageSelectionName: (state) => {
      const fallback = state.salesPageModes[0].text;
      return state.salesPageModes[state.salesPageSelectionIndex]?.text || fallback;
    },
  },

  mutations: {
    setPricings(state, payload) {
      state.pricings = payload;
    },

    setSale(state, { sale, commodityId }) {
      state.sales = {
        ...state.sales,
        [sale.crop_year_id]: {
          ...state.sales[sale.crop_year_id],
          [commodityId]: sale,
        },
      };
    },

    setSales(state, sales) {
      state.sales = sales;
    },

    setContractsIdMapping(state, { contractsIdMapping, updatedContract }) {
      state.contractsIdMapping = contractsIdMapping;
      if (updatedContract) {
        const existingContract = state.contractsIdMapping[updatedContract.id];
        if (existingContract) {
          existingContract.contract_identifier = updatedContract.contract_identifier;
        }
      }
    },

    convertSaleUnit(state, {
      cropYearId, commodityId, fromUnit, toUnit, convert,
    }) {
      const sale = state.sales[cropYearId][commodityId];

      Object.entries(sale).forEach(([key, value]) => {
        if (key !== 'Unit id') {
          sale[key] = convert(value, fromUnit, toUnit, commodityId);
        }
      });
      sale.unit_id = toUnit.id;

      state.sales[cropYearId][commodityId] = sale;
    },

    setSalesPageSelectionIndex(state, selection) {
      state.salesPageSelectionIndex = selection;
    },

    setSavedFilters(state, filters) {
      state.savedFilters = filters;
    },

    setSelectedColumns(state, { type, columns }) {
      if (type === 'all') {
        const initialSelectedState = ['contracts', 'deliveries'];
        const map = new Map();
        initialSelectedState.forEach((key, index) => {
          map.set(key, columns[index]);
        });
        state.selectedColumns = Object.fromEntries(map);
      } else {
        Vue.set(state.selectedColumns, type, columns);
      }
    },

    setAllContractCommodities(state, commodities) {
      state.allContractCommodities = commodities;
    },
  },

  actions: {
    async fetchPricings({ commit }) {
      const data = await postRequest('/farm_profile/api/get_all_pricings/');
      commit('setPricings', data.sort((a, b) => a.id - b.id));
    },

    async addPricing(context, {
      contract, price, basis, priceUnit, quantity, quantityUnit, lockinDate,
    }) {
      try {
        await postRequest(
          '/farm_profile/api/add_pricing/',
          {
            contract,
            price,
            basis,
            price_unit: priceUnit,
            quantity,
            quantity_unit: quantityUnit,
            lockin_date: lockinDate,
          },
        );
      } catch (e) {
        this._vm.$snackbar.error('Server Error');
      }
    },
    async updatePricing(context, {
      id, price, basis, quantity, lockinDate,
    }) {
      try {
        await postRequest(
          '/farm_profile/api/update_pricing/',
          {
            id,
            to_update: {
              price,
              basis,
              quantity,
              lockin_date: lockinDate,
            },

          },
        );
      } catch (e) {
        this._vm.$snackbar.error('Server Error');
      }
    },

    async deletePricing(context, id) {
      try {
        await postRequest(
          '/farm_profile/api/delete_pricing/',
          { id },
        );
      } catch (e) {
        this._vm.$snackbar.error('Server Error');
      }
    },

    async fetchSale({ commit, rootState }, payload) {
      const commodityId = payload.commodity_id;
      const cropYearId = rootState.farmProfile.selectedYear.id;
      const unitId = payload.unit_id;

      const sale = await API.getSale(commodityId, cropYearId, unitId);

      commit('setSale', { sale, commodityId });
    },

    async updatePreference({
      commit, state, rootGetters, dispatch,
    }, {
      cropYearId, commodityId, unitSelected,
    }) {
      commit(
        'convertSaleUnit',
        {
          cropYearId,
          commodityId,
          fromUnit: state.sales[cropYearId][commodityId].unit_id,
          toUnit: unitSelected.id,
          convert: rootGetters['shared/convert'],
        },
      );
      // We piggyback on the farm profile's main update preference action for most of the
      // functionality (since sales use the same units as production)
      dispatch('farmProfile/updatePreference', { commodityId, unitSelected, context: 'Production' }, { root: true });
    },

    async fetchSales({ commit, rootGetters, rootState }) {
      const cropYear = rootState.farmProfile.selectedYear?.id;
      const unit = rootGetters['farmProfile/farms/units']?.production?.id;

      if (!cropYear || !unit) {
        return;
      }

      const sales = await API.getSalesNumbers({ crop_year: cropYear, unit });
      commit('setSales', sales);
    },

    async fetchContractsIdMapping({ state, commit }, contractId) {
      if (!state.contractsIdMapping[contractId.id]) {
        const contractsIdMapping = await API.fetchContractsIdMapping(contractId);
        commit('setContractsIdMapping', { contractsIdMapping, updatedContract: null });
      }
    },

    async createContract({ commit }, payload) {
      let data;
      try {
        data = await API.createContract(payload);
        this._vm.$snackbar.success('Contract successfully created');
      } catch (e) {
        this._vm.$snackbar.error('Failed to create contract');
      }
      return data;
    },

    async updateContract({ state, commit }, payload) {
      let contract;
      try {
        contract = await API.updateContract(payload);
        this._vm.$snackbar.success('Contract successfully updated');
        commit('setContractsIdMapping', { contractsIdMapping: state.contractsIdMapping, updatedContract: contract.updatedContract });
      } catch (e) {
        this._vm.$snackbar.error('Failed to update contract');
      }
      return contract;
    },

    async deleteContract(context, id) {
      try {
        await API.deleteContract(id);
        this._vm.$snackbar.success('Contract successfully deleted');
      } catch (e) {
        this._vm.$snackbar.error('Failed to delete contract');
      }
    },

    async fetchAllContractCommodities({ commit }) {
      const { results: commodities } = await API.getAllContractCommodities();
      commit('setAllContractCommodities', commodities);
    },
  },

  namespaced: true,
};
