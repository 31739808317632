// TODO: Rename date/time formatters as either
// timestampFormatXYZ or dateFormatXYZ
// depending on parameter type
import { isNumber } from './numeric';

import { getYearMonthDate, parseYMD } from './datetime';

export function capitalizeFirstChar(val) {
  return val.charAt(0).toUpperCase() + val.slice(1);
}

export function parseCamelCase(val) {
  return val.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
}

// Output format: October 16, 2018
export function formatDate(timestamp) {
  const date = new Date(timestamp);
  return new Date(date).toLocaleDateString('en-CA', { year: 'numeric', month: 'long', day: 'numeric' });
}

// Output format: October 16, 2018 @ 10:00 AM
export function formatDateTime(timestamp) {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString('en-CA', { year: 'numeric', month: 'long', day: 'numeric' })} @ ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
}

// Output format: Tuesday, October 16, 2018
export function formatDateLong(date) {
  return date.toLocaleDateString('en-CA', {
    year: 'numeric', day: 'numeric', month: 'long', weekday: 'long',
  });
}

// Output format: 16/10/2018
export function formatDateWithSlashes(timestamp) {
  const date = new Date(timestamp);
  return new Date(date).toLocaleDateString('en-GB');
}

// Output format: 2018-10
export function trimDateFromISODate(date) {
  return date.toISOString().substr(0, 7);
}

/**
 * Gets the month's short name from a Date object
 * Output format: Oct
 * @param Date object
 */
export function dateFormatShortMonth(date) {
  return date.toLocaleDateString('en-US', { month: 'short' });
}

// Output format: Jan 22
export function dateFormatShortMonthShortYear(date) {
  return date.toLocaleDateString('en-US', { year: '2-digit', month: 'short' });
}

/**
 * Accepts date and time strings to create
 * a date that is reliable across browsers.
 * This method always assumes a local time,
 * DO NOT pass it the time portion of
 * an ISO string like T12:34:00.000Z.
 * @param {string} date The date in 'YYYY-MM-DD' format
 * @param {string} time The time in 'HH:MM:SS' format
 * @returns {date} The date object in local time
 */
export function getLocalDateTime(date, time = '') {
  // d will be an array of numbers [year, monthIndex, day]
  // We subtract 1 from the month value since month must
  // be 0-indexed when provided to the Date constructor.
  const d = date.split(/[-/]+/).map((x, i) => (i % 2 === 1 ? parseInt(x, 10) - 1 : parseInt(x, 10)));

  // t will be an array of numbers [hours, minutes, seconds, milliseconds]
  const t = time.replace(/[TZ]+/g, '').split(/[:.]+/).filter((s) => s.length !== 0);

  // We require the year, monthIndex, and date
  if (d.length !== 3) throw Error('Invalid Date');

  // d and t will be spread as individual parameters
  return new Date(...d, ...t);
}

/**
 * Converts an ISO datetime string to local date and time
 * strings for use by date/time picker components.
 * @param {string} ISOString The datetime in standard ISO format
 * @returns {string[]} Date and time strings
 */
export function getLocalDateTimeStringsFromISOString(ISOString) {
  const date = new Date(ISOString);
  return [
    getYearMonthDate(date),
    date.toLocaleTimeString('en-CA', {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    }),
  ];
}

/**
 * Converts a pair of date strings into ISO format start and end times,
 * which are defined as the min/max possible times for that date.
 * @param {string[]} dateStringPair Two dates in the format "2020-01-01"
 * @return {string[]} Start and end datetimes in ISO format
 */
export function getStartEndISOStringsFromDateStringPair(dateStringPair) {
  const [dateStart, dateEnd] = dateStringPair;
  return [
    getLocalDateTime(dateStart, '00:00:00.000').toISOString(),
    getLocalDateTime(dateEnd, '23:59:59.999').toISOString(),
  ];
}

// We use en-US here because 'en-US' returns Dec while 'en-CA' returns Dec.
// Output format: Oct 16
export function dateFormatShortMonthDate(date) {
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}

/**
 * Given a date like 2023-06-11, return a date like Jun 11
 *
 * @param {string} A string beginning with a yyyy-mm-dd date
 * @returns {string} A human-readable date <month-abbrev> <date>
 */
export function formatDateStampShort(dateStamp) {
  const { year, month, day } = parseYMD(dateStamp);
  return dateFormatShortMonthDate(new Date(year, month, day));
}

// Output format: Oct 2018
export function dateFormatShortMonthYear(date) {
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
}

/**
 * Given a date like 2023-06-11, return a date like Jun 2023
 *
 * @param {string} A string beginning with a yyyy-mm-dd date
 * @returns {string} A human-readable date <month-abbrev> <year>
 */
export function formatDateStampShortMonthYear(dateStamp) {
  const { year, month, day } = parseYMD(dateStamp);
  return dateFormatShortMonthYear(new Date(year, month, day));
}

// Output format: October
export function dateFormatLongMonth(date) {
  return date.toLocaleDateString('en-US', { month: 'long' });
}

// Output format: October 2018
export function dateFormatLongMonthYear(date) {
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
}

// Output format: Oct 16, 2018
export function dateFormatShortMonthDateYear(date) {
  if (date instanceof Date) {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
}

// Output format: October 16, 2018
export function dateFormatLongMonthDateYear(date) {
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

// Output format: 2018-10-16
export function dateFormatDateString(date) {
  return getYearMonthDate(date);
}

// output format: 21-12-2020
export function formatDateDMY(isoDateString) {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

// Output format: 10:00 AM
export function dateFormat12HourTime(date) {
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

// Output format: Oct 16, 10:00 AM
export function dateFormatShortMonthDate12HourTime(date) {
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

// Output format: Oct 16, 2018, 10:00 AM
export function dateFormatShortMonthDateYear12HourTime(date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

/**
 * Convert date format from ISOString to mon date, year, 24hour
 * @param {Object} Date => e.g.) new Date("2021-07-12T02:52:01.045Z")
 * @returns {string} => e.g.) Jul 11, 2021, 22:52
 */
export function dateFormatShortMonthDateYear24HourTime(date) {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
}

/**
 * Convert date format from ISOString to year-month-date, 24hour
 * @param {Object} Date => e.g.) new Date("2021-07-12T02:52:01.045Z")
 * @returns {string} => e.g.) 2021-07-11, 22:52
 */
export function dateFormatYearMonthDate24HourTime(date) {
  const dateString = getYearMonthDate(date);
  const timeString = date.toLocaleString('en-CA', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
  return `${dateString}, ${timeString}`;
}

/**
 * Convert date format from ISOString to year-month-date
 * @param {Object} Date => e.g.) new Date("2021-07-12T02:52:01.045Z")
 * @returns {string} => e.g.) 2021-07-11
 */
export function dateFormatYearMonthDate(date) {
  return getYearMonthDate(date);
}

// Output formats:
// If date is same as reference, returns 10:00 AM
// If date is different from reference, returns Oct 16
// If year is different from reference, returns Oct 16, 2018
export function dateFormatRecentVariant(date, referenceDate = new Date()) {
  const year = date.getYear();
  const day = date.getDate();
  const referenceYear = referenceDate.getYear();
  const referenceDay = referenceDate.getDate();

  if (Number.isNaN(year)) {
    return '';
  }

  if (day === referenceDay) {
    return dateFormat12HourTime(date);
  }

  if (year === referenceYear) {
    return dateFormatShortMonthDate(date);
  }

  return dateFormatShortMonthDateYear(date);
}

// Output formats:
// If year is same as reference, returns Oct 16, 10:00 AM
// If year is different, returns Oct 16, 2022, 10:00 AM
export function dateFormatRecentVariantLong(date, referenceDate = new Date()) {
  const year = date.getYear();
  const referenceYear = referenceDate.getYear();

  if (Number.isNaN(year)) {
    return '';
  }

  if (year === referenceYear) {
    return dateFormatShortMonthDate12HourTime(date);
  }

  return dateFormatShortMonthDateYear12HourTime(date);
}

// Returns mm:ss
export function formatDuration(seconds) {
  const intSeconds = parseInt(seconds, 10);
  const partMinutes = (Math.floor(intSeconds / 60) % 60).toString().padStart(2, '0');
  const partSeconds = (intSeconds % 60).toString().padStart(2, '0');
  return `${partMinutes}:${partSeconds}`;
}

/**
 * Formats a floating point number as a percentage
 * @param {*} decimal The number to format
 * @returns Formatted percentage, or the original value if not a number
 */
export function formatPercent(value) {
  const number = parseFloat(value);
  if (Number.isNaN(number)) return value;
  return number.toLocaleString('en-CA', { style: 'percent' });
}
/**
 *
 * @method formatNumberEnforceDecimals returns a string of number with specified decimal places
 * @param {Number} number number to be formatted
 * @param {Number} numDecimals number of decimal places to maintain
 * @param {Boolean} removeCommas whether to strip commas from string
 * @returns {String} string of formatted number
 */
export function formatNumberEnforceDecimals(number, numDecimals = 2, removeCommas = false) {
  const num = number ?? 0;
  let formattedNumber = num.toLocaleString('en-CA', { minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals });
  if (removeCommas) {
    formattedNumber = formattedNumber.replace(/,/g, '');
  }
  return formattedNumber;
}

// Returns largest time delta ago, e.g. 1 second, 2 minutes, 3 hours, 4 days, 5 months, 6 years
export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let returnString = `${Math.floor(Math.abs(seconds))} second`;
  if (Math.floor(Math.abs(seconds)) !== 1) returnString += 's';
  let interval = seconds / 60;
  let plural = false;

  if (Math.abs(interval) > 1) {
    returnString = `${Math.floor(Math.abs(interval))} minute`;
    plural = Math.floor(Math.abs(interval)) !== 1;
  }

  interval = seconds / 3600; // 3600 seconds in an hour
  if (Math.abs(interval) > 1) {
    returnString = `${Math.floor(Math.abs(interval))} hour`;
    plural = Math.floor(Math.abs(interval)) !== 1;
  }

  interval = seconds / 86400; // 86400 seconds in a day
  if (Math.abs(interval) > 1) {
    returnString = `${Math.floor(Math.abs(interval))} day`;
    plural = Math.floor(Math.abs(interval)) !== 1;
  }

  interval = seconds / 2592000; // 2592000 seconds in a month
  if (Math.abs(interval) > 1) {
    returnString = `${Math.floor(Math.abs(interval))} month`;
    plural = Math.floor(Math.abs(interval)) !== 1;
  }

  interval = seconds / 31536000; // 31536000 seconds in a year
  if (Math.abs(interval) > 1) {
    returnString = `${Math.floor(Math.abs(interval))} year`;
    plural = Math.floor(Math.abs(interval)) !== 1;
  }

  if (plural) returnString += 's';

  if (seconds > 0) returnString += ' ago';
  else if (seconds < 0) returnString += ' from now';
  else returnString = 'Now';

  return returnString;
}

export const snakeToCamel = (str, spaces) => str.replace(/([-_]\w)/g, (g) => {
  let string = g[1].toUpperCase();
  if (spaces) {
    string = ` ${string}`;
  }
  return string;
});

export const snakeToPascal = (str, spaces) => {
  const camelCase = snakeToCamel(str, spaces);
  const pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);
  return pascalCase;
};

// Returns ISO8601 date string
export function getIsoDateFromHours(date) {
  return date.toISOString().split('T')[0];
}

export function getIsoDateFromDateString(dateString) {
  const string = dateString ?? new Date().toISOString();
  return string.split('T')[0];
}

export function getIsoMonthFromDateString(dateString) {
  return dateString.replace(/(\d{4}-\d{2}).*/, '$1');
}
/**
 * Please use roundUpTo instead of roundTo5Decimals function
 */
export function roundTo5Decimals(num) {
  return Math.round((num + Number.EPSILON) * 100000) / 100000;
}

/**
 * Please use roundUpTo instead of roundTo2Decimals function
 */
export function roundTo2Decimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function placeHyphenAtStart(str) {
  if (str.includes('-')) {
    return `-${str.replace('-', '')}`;
  }
  return str;
}

export function pluralize(term, count) {
  return `${count} ${term}${count === 1 ? '' : 's'}`;
}

/**
 * Formats the given value into the given unit's template
 * @param unit the unit to format with
 * @param value the value to format
 * @param fractionDigits the number of digits to found to (default 2)
 * @param locale the locale for number formatting
 * @returns The formatted value, if the value != 0, or '-' if the value is 0
 */
export function formatWithUnitTemplate(unit, value, fractionDigits = 2, locale = 'en-CA') {
  const number = Number(parseFloat(value));
  const isNegative = number < 0;
  const output = {
    sign: isNegative ? '-' : '',
    number: '',
  };

  // Format the number
  if (Number.isNaN(number)) {
    output.number = '-';
  } else if (isNegative) {
    output.number = Math.abs(number).toLocaleString(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  } else {
    output.number = number.toLocaleString(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }

  // Template substitution
  output.number = unit?.template?.replace('{{ value }}', output.number) ?? output.number;

  // Build and return the string
  return Object.values(output).join('');
}

/**
 * If falsey (0, null, undefined), return '-'
 * Else, return formatWithUnitTemplate(myNumber)
 * @param unit the unit to format with
 * @param value the value to format
 * @param fractionDigits the number of digits to round to (default 2)
 * @returns The formatted value, if the value != 0, or '-' if the value is 0
 */

export function formatUnitTemplateWithDashes(unit, value, fractionDigits = 2) {
  if (!value) return '-';
  return formatWithUnitTemplate(unit, value, fractionDigits);
}

/**
 * Adds delimiters to the given value after it has been formatted with the unit template
 */
export function formatUnitTemplateWithDelimiters(unit, value, fractionDigits = 2) {
  if (value < 0) {
    return `-${unit.template.replace('{{ value }}', formatNumberEnforceDecimals(Math.abs(Number(value)), fractionDigits))}`;
  }
  return unit.template.replace('{{ value }}', formatNumberEnforceDecimals(Number(value), fractionDigits));
}

/**
 * Formats the given number. If 0 < x < 1, will return <1 (e.g. 0.5 -> <1)
 * Else it just returns the number. If symbol is true, will prepend a $ sign.
 * @param {boolean} symbol whether to prepend a $ sign to the number
 * @returns The formatted number, or the original value if formatting not required.
 */
export function formatNumberWithLessThanOne(value, symbol = false) {
  if (value < 1 && value > 0) {
    return symbol ? '<$1' : '<1';
  }
  return value;
}

/**
 * Formats the given value as Canadian currency
 * If symbol is false this will return a string that can be
 * easily parsed as a number (no currency sign or commas).
 * @param {number} value The number to be formatted
 * @param {number} minDigits The minimum digits to display
 * @param {number} maxDigits The maximum digits to display
 * @param {boolean} symbol Display dollar sign and thousands separator
 * @returns The formatted number e.g. $1,234.50 if symbol=true, or 1234.50 if symbol=false
 */
export function formatCurrency(value, minDigits = 2, maxDigits = 2, symbol = true) {
  const formatted = value.toLocaleString('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return symbol ? formatted : formatted.replace(/[$,]+/g, '');
}

/**
 * @method roundUpTo() Round math values
 * @param value The value which you want to be rounded
 * @param decimalPlaces Number of decimal places you want (Default: 2)
 * @returns {Number} Rounded number
 */
export function roundUpTo(value, decimalPlaces = 2) {
  const expo = 10 ** decimalPlaces;
  return Math.round((value + Number.EPSILON) * expo) / expo;
}

export function ceilingUpTo(value, decimalPlaces = 2) {
  const expo = 10 ** decimalPlaces;
  return Math.ceil((value) * expo) / expo;
}

// Returns 24 hour time
export function getTimeStringFromDate(date) {
  // AKA dateFormat12HourTime in formatting.js
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
}

// Returns ISO8601 date string
export function getDateStringFromDate(date) {
  return getYearMonthDate(date);
}

export function getNumberString(number) {
  const numberString = String(number);
  if (number < 1000) {
    return numberString;
  } if (number < 10000) {
    return `${numberString.charAt(0)},${numberString.substring(1)}`;
  }
  return `${(number / 1000).toFixed(0)}k`;
}

export function getDigitsFromString(input) {
  return input.replace(/\D/g, '');
}

export function formatPhoneNumber(phoneNum) {
  const numbers = String(phoneNum).replace(/[^0-9]/g, '');
  const parts = numbers.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (parts) {
    return `${parts[1]}.${parts[2]}.${parts[3]}`;
  }
  return null;
}

export function formatListOfStrings(strings) {
  let result = '';

  strings.forEach((s, i) => {
    result += s;

    if (i + 1 === strings.length - 1) {
      result += ' and ';
    } else if (i + 1 !== strings.length) {
      result += ', ';
    }
  });

  return result;
}

export function truncate(string, length) {
  if (string?.length > length) {
    return `${string.substring(0, length)}`;
  }
  return string;
}

/**
 * Takes either a number or string and truncates decimals past a certain point.
 * String input values are preferred, since large enough numbers will lose precision.
 * @param {number|string} value The value to be truncated
 * @param {number} maxFractionDigits The max number of decimals to keep
 * @returns {string}
 */
export function truncateDecimals(value, maxFractionDigits = 2) {
  const [integer, fraction] = value.toLocaleString('en-US', { useGrouping: false, maximumFractionDigits: maxFractionDigits + 1 }).split('.');
  const parts = [integer];

  if (fraction) {
    const index = (maxFractionDigits - fraction.length) || fraction.length;
    const truncatedFraction = fraction.slice(0, index);
    parts.push(truncatedFraction);
  }

  return parts.join('.');
}

export function getProductionUnitName(value) {
  switch (value) {
    case 'bushel A':
    case 'bushel I':
    case 'bushel W':
      return 'bushels';
    case 'kilogram':
      return 'kilograms';
    case 'pound':
      return 'pounds';
    case 'tonne':
      return 'tonnes';
    default:
      return value;
  }
}

// Special case for when numbers are not tens + ones
function getTeens(num) {
  switch (num % 10) {
    case 0: return 'ten';
    case 1: return 'eleven';
    case 2: return 'twelve';
    case 3: return 'thirteen';
    case 4: return 'fourteen';
    case 5: return 'fifteen';
    case 6: return 'sixteen';
    case 7: return 'seventeen';
    case 8: return 'eighteen';
    case 9: return 'nineteen';
    default: break;
  }
  return '';
}

// Get ones digit
function getOnes(num) {
  switch (num % 10) {
    case 1: return 'one';
    case 2: return 'two';
    case 3: return 'three';
    case 4: return 'four';
    case 5: return 'five';
    case 6: return 'six';
    case 7: return 'seven';
    case 8: return 'eight';
    case 9: return 'nine';
    default: break;
  }
  return '';
}

// Get the tens digit
function getTens(num) {
  switch (num) {
    case 1: return 'ten';
    case 2: return 'twenty';
    case 3: return 'thirty';
    case 4: return 'forty';
    case 5: return 'fifty';
    case 6: return 'sixty';
    case 7: return 'seventy';
    case 8: return 'eighty';
    case 9: return 'ninety';
    default: break;
  }
  return '';
}

// Helper for to english word to get 3 digit number in english
function get3(input, addAnd = false) {
  let num = input;
  let output = '';
  let hundred = false;
  let ten = false;
  if (num >= 100) {
    output += `${getOnes(Math.floor(num / 100))} hundred`;
    hundred = true;
  }
  num %= 100;
  if (num >= 10) {
    if (hundred) {
      output += ' ';
      hundred = false;
    }
    if (addAnd) {
      output += 'and ';
    }
    if (num < 20) {
      output += getTeens(num);
      return output; // short circuit because we have ones in the teens
    }
    output += getTens(Math.floor(num / 10));

    ten = true;
  }
  num %= 10;
  if (num === 0) {
    return output;
  }
  if (hundred || ten) {
    output += ' ';
  }
  if (addAnd && !ten) {
    output += 'and ';
  }
  output += getOnes(num);
  return output;
}

/**
 * Converts the integer part of the number into words
 * @param {*} value Number to make into words
 * @param {*} max The highest number before ignoring and using numbers again, default 10.
 *                If null, no max is applied.
 * @returns The english word for the number
 */
export function numberToWords(value, max = 10) {
  if (!isNumber(value)) {
    return value;
  }
  let num = +value;
  if (isNumber(max) && max < num) {
    return value;
  }
  if (!Number.isInteger(num)) {
    num = Math.floor(num);
  }
  // quadrillion too much - anything over 2^53 (~9 quadrillion) is an unsafe integer, so we discard
  if (Math.abs(num) >= 1_000_000_000_000_000) {
    return value;
  }
  if (num === 0) {
    return 'zero';
  }
  if (num < 0) {
    return `negative ${numberToWords(-value, max < 0 ? -max : max)}`;
  }
  const parts = [];
  const bigiter = ['', 'thousand', 'million', 'billion', 'trillion'];
  let addAnd = num > 100;
  while (num > 0) {
    let suffix = bigiter.shift();
    const part = get3(num % 1000, addAnd);
    if (suffix.length > 0) {
      suffix = ` ${suffix}`;
    }
    if (part.length > 0) {
      parts.push(part + suffix);
    }
    num = Math.floor(num / 1000);
    addAnd = false;
  }
  let total = '';
  let sep = '';
  while (parts.length) {
    total += sep + parts.pop();
    sep = ' ';
  }
  return total.trim();
}

// Output format: Oct
function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', {
    month: 'short',
  });
}

export function dateRange(startDate, endDate) {
  const start = startDate.split('-');
  const end = endDate.split('-');
  const startYear = Number(start[0]);
  const endYear = Number(end[0]);
  const dates = [];
  for (let i = startYear; i <= endYear; i += 1) {
    const endMonth = i !== endYear ? 11 : Number(end[1]) - 1;
    const startMon = i === startYear ? Number(start[1]) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? `0${month}` : month;
      dates.push(toMonthName(displayMonth));
    }
  }
  return dates;
}

export function getMonthDifference(dateFrom, dateTo) {
  const startDate = dateFrom instanceof Date ? dateFrom : new Date(dateFrom);
  const endDate = dateTo instanceof Date ? dateTo : new Date(dateTo);
  return endDate.getMonth() - startDate.getMonth()
    + (12 * (endDate.getFullYear() - startDate.getFullYear())) + 1;
}

export function formatNumberRange(from, to, suffix = '') {
  let lowerBound = from;
  let upperBound = to;

  if (from === -Infinity) {
    lowerBound = 'under';
  }

  if (to === Infinity) {
    upperBound = 'over';
    lowerBound += suffix;
  } else {
    upperBound += suffix;
  }
  return `${lowerBound} - ${upperBound}`;
}

/**
 * Strip commas and dollar signs from string
 * @param {string} value
 * @returns string
 */
export function stripCommasAndDollarSigns(value) {
  return (value ?? '').toString().replace(/[$,]+/g, '');
}

export function convertDate(year, month) {
  const monthResult = `${year}-${month}-01`;
  const monthYear = new Date(monthResult.trim()).toISOString();
  return monthYear;
}

export function removeLy(value) {
  if (!value) {
    return value;
  }
  if (value === 'daily') {
    return 'day';
  }
  return value.replace(/ly$/, '');
}

/**
 * Convert seconds into either mm:ss or hh:mm:ss format, whichever is smaller.
 * @param {number} time The number of seconds to convert
 * @returns {string} The formatted time
 */
export function formatSeconds(time) {
  if (time >= 3600) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const COMPASS_DIRECTIONS = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];
export const COMPASS_DIRECTION_SEPARATION = 360 / (COMPASS_DIRECTIONS.length - 1);

/**
 * Converts the given degrees to compass directions where 0 degrees is North.
 * @param {number} The degrees to convert
 * @returns {string} A compass direction (N, NNE, NE, E, etc.)
 */
export function degreeToCompass(deg) {
  const index = Math.round((deg % 360) / COMPASS_DIRECTION_SEPARATION);
  return COMPASS_DIRECTIONS[index];
}

/**
 * Remove HTML tags from a string
 * @param {string} html The string to strip HTML tags from
 * @returns {string} The string without HTML tags
 */
export function decodeHtml(html) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}
