<template>
  <g-dialog
    v-model="dialogOpen"
    title="Download Document"
    disable-fullscreen
    :width="680"
    :action-buttons="buttons"
    @cancel="closeDialog"
  >
    <v-form
      v-model="isNameValid"
    >
      <v-text-field
        v-model.trim="fileName"
        :rules="[requiredValueRule('File Name'),
                 maxLengthRule('File Name', maxLength),
                 customRegexRule]"
        label="Save As"
        :maxlength="maxLength"
        outlined
        required
      >
        <template #append>
          <div class="text-select-doctype">
            <v-select
              v-model="documentType"
              class="h--100"
              :items="documentTypes"
              :disabled="!allowChoices"
              :append-icon="allowChoices ? 'mdi-chevron-down' : null"
              filled
              outlined
              hide-details
              required
            >
              <template #selection="{ item }">
                <span class="text-select-doctype__selection">
                  .{{ item }}
                </span>
              </template>
            </v-select>
          </div>
        </template>
      </v-text-field>
    </v-form>
  </g-dialog>
</template>
<script>
import GDialog from '@/components/generic/GDialog.vue';
import { requiredValueRule, maxLengthRule, requiredValueMatchesRegex } from '@/helpers/rules';

export default {
  name: 'DownloadForm',

  components: {
    GDialog,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxLength: 64,
      fileName: '',
      isNameValid: false,
      allowChoices: false,
      documentType: 'docx',
      documentTypes: ['docx', 'pdf', 'txt'],
    };
  },

  computed: {
    customRegexRule() {
      return requiredValueMatchesRegex(
        'File Name',
        /^[^"*/:<>?\\]*$/i,
        'Must use valid file name characters',
      );
    },

    buttons() {
      return [
        {
          name: 'CANCEL',
          side: 'right',
          type: 'default',
          onClick: this.closeDialog,
        },
        {
          name: 'SAVE',
          side: 'right',
          type: 'primary',
          onClick: this.saveFile,
          loading: this.loading,
          disabled: !this.isNameValid,
          hasIcon: true,
          icon: 'mdi-content-save',
        },
      ];
    },

    dialogOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },

  methods: {
    requiredValueRule,
    /*
      We use the maxLengthRule since the maximum bytes in a path is 255 on most platforms.
        The choice of 64 is fairly arbitrary, since it allows a relatively long name while
        still leaving room for the extension and the path.
    */
    maxLengthRule,
    requiredValueMatchesRegex,

    closeDialog() {
      this.dialogOpen = false;
    },

    saveFile() {
      this.$emit('save', {
        fileName: this.fileName,
        documentType: this.documentType,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.text-select-doctype {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100px;
  &__selection {
    min-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
