export const OPERATING_RATIO = Object.freeze({
  header: 'Operating Expense Ratio',
  description: 'How much of every $1.00 generated goes to operating costs',
  ranges: {
    good: {
      min: 0,
      max: 60,
    },
    moderate: {
      min: 60,
      max: 80,
    },
    bad: {
      min: 80,
      max: 100,
    },
  },
});

export const DEPRECIATION_RATIO = Object.freeze({
  header: 'Depreciation Expense Ratio',
  description: 'How much of every $1.00 generated goes to depreciation costs',
  ranges: {
    good: {
      min: 0,
      max: 5,
    },
    moderate: {
      min: 5,
      max: 10,
    },
    bad: {
      min: 10,
      max: Infinity,
    },
  },
});

export const INTEREST_RATIO = Object.freeze({
  header: 'Interest Expense Ratio',
  description: 'How much of every $1.00 generated goes to interest costs',
  ranges: {
    good: {
      min: 0,
      max: 5,
    },
    moderate: {
      min: 5,
      max: 10,
    },
    bad: {
      min: 10,
      max: Infinity,
    },
  },
});

export const NET_RATIO = Object.freeze({
  header: 'NET Farm Income Ratio',
  description: 'How much of every $1.00 generated is farm income',
  ranges: {
    good: {
      min: 20,
      max: Infinity,
    },
    moderate: {
      min: 10,
      max: 20,
    },
    bad: {
      min: 0,
      max: 10,
    },
  },
});

export const DEMO_BUDGET_PRODUCTIONS = Object.freeze({
  1: {
    id: 1,
    farm_location: 1,
    name: 'Corn',
    commodity: 41,
    acres: 333,
    yield: 174,
    price: 6.75,
    use_province_benchmark_prices: false,
    use_smart_price: false,
    demoTag: true,
  },
  2: {
    id: 2,
    farm_location: 1,
    name: 'Soybeans',
    commodity: 43,
    acres: 245,
    yield: 43,
    price: 9.47,
    use_province_benchmark_prices: false,
    use_smart_price: false,
    demoTag: true,
  },
  3: {
    id: 3,
    farm_location: 1,
    name: 'Wheat',
    commodity: 44,
    acres: 98,
    yield: 85.68,
    price: 4.95,
    use_province_benchmark_prices: false,
    use_smart_price: false,
    demoTag: true,
  },
});

export const DEMO_CATEGORIES = Object.freeze({
  1: {
    id: 1,
    name: 'Seed & Treatment',
    type: 4,
    group: 11,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 1,
  },
  2: {
    id: 2,
    name: 'Fertilizer',
    type: 4,
    group: 11,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 1,
  },
  3: {
    id: 3,
    name: 'Pesticide',
    type: 4,
    group: 11,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 1,
  },
  4: {
    id: 4,
    name: 'Fuel',
    type: 4,
    group: 13,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 1,
  },
  5: {
    id: 5,
    name: 'Machinery Operating & Lease',
    type: 5,
    group: 13,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 1,
  },
  6: {
    id: 6,
    name: 'Operating Interest',
    type: 5,
    group: 13,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 2,
  },
  7: {
    id: 7,
    name: 'Building Depreciation',
    type: 5,
    group: 13,
    province: null,
    isBenchmark: false,
    isCustom: false,
    classification: 3,
  },
});

export const DEMO_PREFERENCES = Object.freeze({
  1: {
    id: 1,
    category: 1,
    preferred: true,
  },
  2: {
    id: 2,
    category: 2,
    preferred: true,
  },

  3: {
    id: 3,
    category: 3,
    preferred: true,
  },
  4: {
    id: 4,
    category: 4,
    preferred: true,
  },
  5: {
    id: 5,
    category: 5,
    preferred: true,
  },
  6: {
    id: 6,
    category: 6,
    preferred: true,
  },
  7: {
    id: 7,
    category: 7,
    preferred: true,
  },
});

export const DEMO_EXPENSES = Object.freeze({
  1: {
    id: 1,
    amount: 24245,
    budgetProduction: 1,
    category: 5,
    type: 5,
    memo: '',
    allocationPercentage: null,
    isBenchmarkDefault: false,
    benchmark: null,
    price_unit: 'dollar',
  },
  2: {
    id: 2,
    amount: 12345,
    budgetProduction: 1,
    category: 6,
    type: 5,
    memo: '',
    allocationPercentage: null,
    isBenchmarkDefault: false,
    benchmark: null,
    price_unit: 'dollar',
  },
  3: {
    id: 3,
    amount: 88005,
    budgetProduction: 1,
    category: 7,
    type: 5,
    memo: '',
    allocationPercentage: null,
    isBenchmarkDefault: false,
    benchmark: null,
    price_unit: 'dollar',
  },
});
