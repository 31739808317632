import { MLAPI_ROOT } from '@/helpers/environment_variables';
import { SCOPE_SMART_ADVISOR } from '@/router/scopes';

const DOMAIN_GRAINFOX = window.location.origin;
const DOMAIN_ML_API = MLAPI_ROOT;

const criticalEndpoints = {
  [SCOPE_SMART_ADVISOR]: {
    [DOMAIN_GRAINFOX]: ['/budgeting/ai_advisor/get_token/', '/budgeting/ai_advisor/get_smart_advisor_productions/', '/budgeting/ai_advisor/get_aggregated_farm_profile_data/'],
    [DOMAIN_ML_API]: ['/v1/supported-parameters', '/v1/action-plan'],
  },
};

export default criticalEndpoints;
