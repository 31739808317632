<template>
  <div>
    <v-btn-toggle
      v-if="btnToggleMode"
      v-model="selected"
      mandatory
      group
      dense
      @change="handleSelect"
    >
      <v-btn
        v-for="selection in selections"
        :key="selection.key"
        :value="selection.value"
        :data-qa="`g-button-toggle-${selection.value}`"
        :disabled="disabled"
      >
        <v-icon v-if="buttonType === 'Icon'">
          {{ selection.icon }}
        </v-icon>
        <span v-if="buttonType === 'Text'"> {{ selection.text }}</span>
      </v-btn>
    </v-btn-toggle>
    <v-select
      v-else
      v-model="selected"
      color="grey darken-4"
      background-color="grey lighten-4"
      class="font-weight-bold text-body-2"
      :menu-props="{ bottom: true, offsetY: true }"
      :items="selections"
      close-on-click
      item-text="text"
      item-value="value"
      hide-details
      flat
      dense
      solo
      @change="handleSelect"
    />
  </div>
</template>

<script>
export default {
  props: {
    // array of type [{value: myValue, text: textToDisplay}]
    selections: {
      type: Array,
      required: true,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    displayMobile: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'Text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    btnToggleMode() {
      return !(this.$isMobile && this.displayMobile);
    },
  },
  watch: {
    value(newV) {
      this.selected = newV;
    },
  },
  methods: {
    handleSelect() {
      this.$emit('input', this.selected);
    },
  },

};
</script>

<style lang="scss" scoped>
@use "@/colors/colors";

.v-select {
  border-radius: 9999px;
}

::v-deep {
  &.v-list {
    .v-list-item--active {
      color: colors.$white !important;
      background-color: colors.$primary;
    }
  }
}

::v-deep {
  &.v-btn-toggle > .v-btn--active.v-btn--disabled::before {
      background-color: colors.$greyLighten3 !important;
    }
  }
</style>
