import { postRequest } from '@/helpers/request';

async function getNewsFilters() {
  return postRequest('/news/filters/');
}

export default {
  state: () => ({
    $newsTopics: [],
    $newsSources: [],
    filterTopics: [],
    filterSources: [],
    filterDates: [],
    filterState: {
      filterTopicSelection: [],
      filterTopicInput: '',
      filterDates: [],
      filterDatePickerMenu: false,
      filterSourceSelection: [],
      filterSourceInput: '',
      filterSearchResults: [],
      filterSearchLoading: false,
      filterSearchError: false,
      isFilterDateSaved: false,
    }, // Mobile filter state
  }),

  getters: {
    newsTopics: (state, _, rootState) => {
      const flagMap = {
        inoreader: 'inoreader',
      };
      return state.$newsTopics.filter((topic) => {
        const flagName = flagMap[topic.provider];
        if (flagName) {
          return rootState.launchDarkly.flags[flagName];
        }
        return true;
      });
    },
    newsSources: (state, _, rootState) => {
      const flagMap = {
        inoreader: 'inoreader',
      };
      return state.$newsSources.filter((source) => {
        const flagName = flagMap[source.value];
        if (flagName) {
          return rootState.launchDarkly.flags[flagName];
        }
        return true;
      });
    },
  },

  mutations: {
    setNewsTopics(state, payload) {
      state.$newsTopics = payload;
    },

    setNewsSources(state, payload) {
      state.$newsSources = payload;
    },

    setFilterTopics(state, payload) {
      state.filterTopics = payload;
    },

    setFilterSources(state, payload) {
      state.filterSources = payload;
    },

    setFilterDates(state, payload) {
      state.filterDates = payload;
    },
    clearFilterState(state) {
      state.filterState = {
        filterTopicSelection: [],
        filterTopicInput: '',
        filterDates: [],
        filterDatePickerMenu: false,
        filterSourceSelection: [],
        filterSourceInput: '',
        filterSearchResults: [],
        filterSearchLoading: false,
        filterSearchError: false,
        isFilterDateSaved: false,
      };
    },
  },

  actions: {
    async loadNewsSourcesAndTopics({ commit }) {
      const newsFilters = await getNewsFilters();
      commit('setNewsSources', newsFilters.sources);
      commit('setNewsTopics', newsFilters.topics);
    },
    clearFilters({ commit }) {
      commit('clearFilterState');
    },
    loadFilterState({ state }) {
      state.filterState.filterTopicSelection = state.filterTopics;
      state.filterState.filterSourceSelection = state.filterSources;
      state.filterState.filterDates = state.filterDates;
    },
  },

  namespaced: true,
};
