<template>
  <v-app :class="computedStyles">
    <status-banner class="disable-print" />
    <AppHeader class="disable-print" />
    <AppNavigation class="disable-print" />
    <v-main
      class="grey lighten-5"
      :style="vMainStyles"
    >
      <slot />
    </v-main>
    <InviteDialog />
    <FeedbackDialog />
    <create-account />
    <FilePreview />
    <snackbar />
    <OrganizationInviteConfirmDialog v-if="isAuthenticated" />
    <SetSecurityQuestionsPrompt :value="!isMimic" />
    <OTPasswordPrompt :value="!isMimic" />
    <TermsOfUseDialog v-if="shouldShowTermsOfUsePrompt" />
    <EULADialog v-if="shouldShowEULAPrompt && !shouldShowTermsOfUsePrompt" />
    <AudioPlayer />
    <OutageOverlay />
    <AdblockDialog />
    <CouponDialog v-if="showTimedSalesDialog" />
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import StatusBanner from './StatusBanner.vue';
import AppHeader from './AppHeader.vue';
import AppNavigation from './AppNavigation.vue';
import InviteDialog from './InviteDialog.vue';
import FeedbackDialog from './FeedbackDialog.vue';
import FilePreview from '@/components/library/FilePreview.vue';
import Snackbar from './Snackbar.vue';
import CreateAccount from './CreateAccount.vue';
import TermsOfUseDialog from '../legal/TermsOfUseDialog.vue';
import EULADialog from '../legal/EULADialog.vue';
import OrganizationInviteConfirmDialog from './OrganizationInviteConfirmDialog.vue';
import SetSecurityQuestionsPrompt from '@/components/dashboard/SetSecurityQuestionsPrompt.vue';
import OTPasswordPrompt from '@/components/dashboard/OTPasswordPrompt.vue';
import AudioPlayer from '@/components/library/AudioPlayer.vue';
import OutageOverlay from '@/components/overlays/OutageOverlay.vue';
import AdblockDialog from '@/components/overlays/AdblockDialog.vue';
import CouponDialog from '../coupons/CouponDialog.vue';

export default {
  name: 'GrainFox',

  components: {
    AudioPlayer,
    TermsOfUseDialog,
    EULADialog,
    StatusBanner,
    AppHeader,
    AppNavigation,
    InviteDialog,
    FeedbackDialog,
    FilePreview,
    Snackbar,
    CreateAccount,
    OrganizationInviteConfirmDialog,
    SetSecurityQuestionsPrompt,
    OTPasswordPrompt,
    OutageOverlay,
    AdblockDialog,
    CouponDialog,
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapState('app', ['isPrintingEnabled', 'isMimic', 'navDrawerWidth']),
    ...mapState('user', ['shouldShowTermsOfUsePrompt', 'shouldShowEULAPrompt']),
    ...mapGetters('user', ['isAuthenticated']),

    computedStyles() {
      return {
        'mimic-mode': this.isMimic,
        'disable-print': !this.isPrintingEnabled,
      };
    },

    vMainStyles() {
      return {
        'padding-left': `${this.navigationOffset}px`,
      };
    },

    // Global breakpoints
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },

    isTablet() {
      return this.$vuetify.breakpoint.md;
    },

    isDesktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },

    // App UI offsets
    headerOffset() {
      return this.$vuetify.application.top + this.$vuetify.application.bar;
    },

    navigationOffset() {
      return this.isAuthenticated && !this.$isMobile ? this.navDrawerWidth : 0;
    },

    showTimedSalesDialog() {
      return this.flags['timed-coupon'] && DJANGO_REQUEST.user?.isLoggedIn;
    },
  },

  created() {
    // Make global breakpoints available on Vue prototype
    Object.defineProperty(Vue.prototype, '$isMobile', {
      get: () => this.isMobile,
    });

    Object.defineProperty(Vue.prototype, '$isTablet', {
      get: () => this.isTablet,
    });

    Object.defineProperty(Vue.prototype, '$isDesktop', {
      get: () => this.isDesktop,
    });

    // Make app UI offsets available on Vue prototype
    Object.defineProperty(Vue.prototype, '$headerOffset', {
      get: () => this.headerOffset,
    });

    Object.defineProperty(Vue.prototype, '$navigationOffset', {
      get: () => this.navigationOffset,
    });

    // App Setup
    this.initializeVuex();
  },

  async mounted() {
    window.addEventListener('resize', () => {
      this.setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
    this.setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    await this.detectAdBlock();
  },

  methods: {
    ...mapMutations('app', ['setWindowSize', 'setUnreadNotificationCount']),
    ...mapMutations('chat', ['setUnreadMessagesCount']),

    ...mapActions('app', ['loadAppState', 'loadNavDrawerItems', 'detectAdBlock', 'handleMimicChanged']),
    ...mapActions('user', ['loadUserState']),
    ...mapActions('group', ['loadGroupState']),
    ...mapActions('chargebee', ['loadChargebeeState', 'handleChargebeeWebhook']),
    ...mapActions('preferences', ['loadPreferencesState']),
    ...mapActions('subscription', ['loadSubscriptionState']),
    ...mapActions('permissions', ['loadPermissionsState']),

    /**
     * Initialize the store data with data from the Django request/context
     */
    async initializeVuex() {
      // 1. Load app state
      this.loadAppState(DJANGO_REQUEST.app);

      // 2. Load user state
      this.loadUserState(DJANGO_REQUEST.user);

      // 3. Load group state
      this.loadGroupState(DJANGO_REQUEST.group);

      // 4. Load misc other state
      this.loadChargebeeState(DJANGO_REQUEST.chargebee);
      this.loadPreferencesState(DJANGO_REQUEST.preferences);
      this.loadSubscriptionState(DJANGO_REQUEST.subscription);
      this.loadPermissionsState(DJANGO_REQUEST.permissions);
      this.setUnreadMessagesCount(DJANGO_REQUEST.unreadChatMessagesCount);
      this.setUnreadNotificationCount(DJANGO_REQUEST.unreadTotalNotificationsCount);

      if (DJANGO_REQUEST.user?.isLoggedIn) {
        this.loadNavDrawerItems();
        this.$gfws.registerCallback('customer_data_updated', this.handleChargebeeWebhook);
        this.$gfws.registerCallback('mimic_state_updated', this.handleMimicChanged);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Remove padding when printing
@media print {
  .v-main {
    padding: 0px !important;
  }
}
</style>
