<template>
  <g-dialog
    v-model="dialogOpen"
    title="Terms and Conditions"
  >
    <div>
      <ul>
        <li>Offer available for producers who are GrainFox Basic users and trial users</li>
        <li>Must subscribe to an annual Smart Plan</li>
        <li>Full payment made up front</li>
        <li>Payment must be made by credit card, checks will not be accepted</li>
        <li>Coupon/promo code cannot be used with other offers or discounts</li>
        <li v-if="activeSubscriptionCoupon">
          Offer ends on {{ formatDateLong(new Date(activeSubscriptionCoupon.end_date)) }}
        </li>
        <li>GrainFox reserves the right to revise, end, or extend the offer at any time</li>
      </ul>
    </div>
  </g-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import GDialog from '../generic/GDialog.vue';
import { formatDateLong } from '../../helpers/formatting';

export default {
  components: {
    GDialog,
  },

  computed: {
    ...mapState('discountCoupon', ['activeSubscriptionCoupon', 'showTermsAndConditionsDialog']),

    dialogOpen: {
      get() {
        return this.showTermsAndConditionsDialog;
      },
      set(value) {
        this.setShowTermsAndConditionsDialog(value);
      },
    },
  },

  methods: {
    ...mapMutations('discountCoupon', ['setShowTermsAndConditionsDialog']),

    formatDateLong,
  },

};
</script>
