const encodePayload = (payload: WebSocketPayload): string => btoa(JSON.stringify(payload));
const decodePayload = (payload: string): WebSocketPayload => JSON.parse(atob(payload));

export type WebSocketPayload = {
  type: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type WebSocketCallback = (payload?: WebSocketPayload) => void;

export default class GrainFoxWebSocket {
  static instance: GrainFoxWebSocket;

  ws: WebSocket | undefined;

  scheme!: string;

  url!: string;

  callbacks!: Record<string, (WebSocketCallback)[]>;

  constructor(isLocal: boolean) {
    if (GrainFoxWebSocket.instance) {
      // eslint-disable-next-line no-constructor-return
      return GrainFoxWebSocket.instance;
    }
    GrainFoxWebSocket.instance = this;

    this.scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';

    if (isLocal) {
      this.url = `ws://${window.location.hostname}:8001`;
    } else {
      this.url = `wss://${window.location.host}/ws`;
    }

    this.callbacks = {};
    this.connect();
  }

  registerCallback(messageType: string, fn: WebSocketCallback): void {
    if (typeof fn !== 'function') return;

    if (!Object.prototype.hasOwnProperty.call(this.callbacks, messageType)) {
      this.callbacks[messageType] = [];
    }

    this.callbacks[messageType].push(fn);
  }

  unregisterCallback(messageType: string, fn: WebSocketCallback): void {
    if (typeof fn !== 'function') return;

    if (!Object.prototype.hasOwnProperty.call(this.callbacks, messageType)) return;
    this.callbacks[messageType] = this.callbacks[messageType].filter((callback) => callback !== fn);
  }

  processCallbacks(messageType: string, message: WebSocketPayload | undefined): void {
    this.callbacks[messageType].forEach((fn) => {
      if (fn.length === 1 && message === undefined) {
        throw Error('Function takes payload but none was supplied');
      } else {
        fn(message);
      }
    });
  }

  onMessage(payload: { data: string }): void {
    try {
      const message = decodePayload(payload.data);
      const messageType = message.type;

      if (Object.prototype.hasOwnProperty.call(this.callbacks, messageType)) {
        this.processCallbacks(messageType, message);
      }
    } catch (e) { console.log(e); } // eslint-disable-line no-console
  }

  connect(reconnect = false): void {
    this.ws = new WebSocket(this.url);
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onopen = this.onOpen.bind(this, reconnect);
    this.ws.onclose = this.onClose.bind(this);
  }

  onOpen(reconnect = false): void {
    if (Object.prototype.hasOwnProperty.call(this.callbacks, 'on_websocket_open')) {
      this.processCallbacks('on_websocket_open', { type: 'websocket_open', reconnect });
    }
  }

  onClose(event: CloseEvent): void {
    // Firefox tries to run onClose even on clean disconnects (refresh/navigation)
    // Other browsers will skip the callbacks, so this line standardizes that behaviour
    // Code 1001 is used when "going away" - the one case where we don't want to try and reconnect
    if (event.code === 1001) return;

    if (Object.prototype.hasOwnProperty.call(this.callbacks, 'on_websocket_close')) {
      this.processCallbacks('on_websocket_close', undefined);
    }
    setTimeout(this.connect.bind(this, true), 5000);
  }

  sendMessage(payload: WebSocketPayload): void {
    // eslint-disable-next-line no-console
    console.warn('You should not be sending messages to websocket from client side unless you know what you are doing');

    // TODO: Maybe at some point we could actually ditch the encode/decode process of payload
    this.ws?.send(encodePayload(payload));
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $gfws: GrainFoxWebSocket;
  }
}
