/* eslint-disable camelcase */
import { postRequest } from '../../helpers/request';

async function apiReadContent() {
  const { result } = await postRequest('/dashboard/api/read_content/');
  return result;
}

async function apiReadAllContent({ chargebeeItemId = null, planType = null }) {
  const data = { chargebeeItemId, planType };
  const { result } = await postRequest('/dashboard/api/read_all_content/', data);
  return result;
}

async function apiGetSubscribedContent() {
  const { results } = await postRequest('/commerce/get_all_subscribed_content/');
  return results;
}

async function apiGetPublicationAccessSettings() {
  const { results } = await postRequest('/dashboard/api_get_publication_settings/');
  return results;
}

const isInternal = (content) => content.is_internal;
const isExternal = (content) => !content.is_internal;

export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      content: [],
      trialContentItems: [],
      downgradeContentItems: [],
      publicationAccessSettings: {},
    };
  },

  getters: {
    ownedContent(state, _, rootState) {
      return state.content
        .filter((content) => content.owner_id === rootState.subscription.id);
    },

    ownedInternalContent(_, getters) {
      return getters.ownedContent
        .filter(isInternal);
    },

    ownedExternalContent(_, getters) {
      return getters.ownedContent
        .filter(isExternal);
    },
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setContent(state, value) {
      state.content = value;
    },

    setPublicationAccessSettings(state, value) {
      state.publicationAccessSettings = value;
    },

    setTrialContentItems(state, content) {
      const subscribableContent = content.map((item) => ({
        ...item,
        full: item.full ?? false,
        teaser: item.teaser ?? false,
        periodically: item.periodically ?? false,
      })).filter((item) => item.subscribable === true);

      state.trialContentItems = subscribableContent;
    },

    setDowngradeContentItems(state, content) {
      const subscribableContent = content.map((item) => ({
        ...item,
        full: item.full ?? false,
        teaser: item.teaser ?? false,
        periodically: item.periodically ?? false,
      })).filter((item) => item.subscribable === true);

      state.downgradeContentItems = subscribableContent;
    },
  },

  actions: {
    async readSubscribedContent({ commit }) {
      try {
        const content = await apiGetSubscribedContent();
        commit('setContent', content);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async readPublicationAccessSettings({ commit }) {
      try {
        const publicationAccessSettings = await apiGetPublicationAccessSettings();
        commit('setPublicationAccessSettings', publicationAccessSettings);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async readContent({ commit }) {
      try {
        commit('setLoading', true);
        const content = await apiReadContent();
        commit('setContent', content);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setLoading', false);
      }
    },
    async readAllContent({ commit }, { chargebeeItemId = null, planType = null } = {}) {
      try {
        commit('setLoading', true);
        const content = await apiReadAllContent({ chargebeeItemId, planType });
        // sort by content name
        const sortedContent = content.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          return nameA.localeCompare(nameB);
        });
        if (planType === 'trial') {
          commit('setTrialContentItems', sortedContent);
        } else if (planType === 'downgrade') {
          commit('setDowngradeContentItems', sortedContent);
        } else {
          // If no type is specified, assume it's regular content
          commit('setContent', sortedContent);
        }
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
