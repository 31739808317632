import { postRequest, postFormRequest } from '../../helpers/request';

export default {
  state: () => ({
    id: null,
    ownerId: null,
    domainRestrictions: [],
    subscriptions: [],
    searchResults: [],
    searchTerm: '',
    source: '',
    role: '',
    province: {},
    country: {},
    isSearchLoading: false,
    gmaIntegration: null,
  }),

  getters: {
    getDomainRestrictions(state, getters, rootState) {
      if (rootState.launchDarkly.flags['restrict-domains']) {
        return state.domainRestrictions;
      }
      return [];
    },
    ownsAnySubscription: (state, getters, rootState) => {
      const userId = rootState?.user?.id;
      return state.subscriptions.some((subscription) => subscription.owner_id === userId);
    },
    getCurrentSubscription: (state, getters) => (
      getters.getSubscriptions.get(state.id) ?? null
    ),
    getSubscriptions: (state) => {
      const data = state.subscriptions;
      const sorted = data.sort((a, b) => a.subscription_name.localeCompare(b.subscription_name));
      return sorted.reduce((acc, curr) => {
        acc.set(curr.id, curr);
        return acc;
      }, new Map());
    },

    activeSubscription: (state, getters) => getters.getSubscriptions.get(state.id),

    getActiveSubscriptionName: (state, getters) => getters.activeSubscription?.subscription_name ?? '',

    isAmerican: (state) => state.country?.country_name === 'United States',

    getIsFarmer: (state, getters) => {
      const subscription = getters.getSubscriptions.get(state.id);
      return subscription?.is_farmer ?? false;
    },

    isSimpleMode: (state) => state.subscriptions.length <= 1,

    isAdvancedMode: (state) => state.subscriptions.length >= 6,

    getRecentSubscription() {
      try {
        const recentSubscription = window.localStorage.getItem('recentSubscription');
        return JSON.parse(recentSubscription);
      } catch {
        return false;
      }
    },

    isDeputter(state) {
      return state.source === 'deputter';
    },

    isSyngenta(state) {
      return state.source === 'syngenta';
    },

    isCommercial(state) {
      return state.role === 'commercial';
    },
  },

  mutations: {
    setID(state, payload) {
      state.id = payload;
    },

    setOwnerId(state, payload) {
      state.ownerId = payload;
    },

    setSubscriptions(state, payload) {
      state.subscriptions = payload;
    },

    setSearchResults(state, payload) {
      state.searchResults = payload;
    },

    setSearchTerm(state, payload) {
      state.searchTerm = payload;
    },

    setIsSearchLoading(state, payload) {
      state.isSearchLoading = payload;
    },

    setSource(state, payload) {
      state.source = payload;
    },

    setProvince(state, payload) {
      state.province = payload;
    },

    setCountry(state, payload) {
      state.country = payload;
    },

    setRole(state, payload) {
      state.role = payload;
    },

    setDomainRestrictions(state, payload) {
      state.domainRestrictions = payload;
    },

    setGmaIntegration(state, payload) {
      state.gmaIntegration = payload;
    },
  },

  actions: {
    loadSubscriptionState({ commit }, payload) {
      if (payload === null) {
        return;
      }

      const {
        id,
        ownerId,
        subscriptions,
        source,
        role,
        domainRestrictions,
        province,
        country,
        gmaIntegration,
      } = payload;
      commit('setID', id);
      commit('setOwnerId', ownerId);
      commit('setSubscriptions', subscriptions);
      commit('setSource', source);
      commit('setRole', role);
      commit('setProvince', province);
      commit('setCountry', country);
      commit('setDomainRestrictions', domainRestrictions);
      commit('setGmaIntegration', gmaIntegration ?? null);
    },

    async changeSubscription({ commit, state }, payload) {
      try {
        /* Local Storage Section -- Start
         * Add subscription object to local storage
         */
        const storage = window.localStorage;
        const currentSubscription = state.searchResults.filter((x) => x.id === payload.newValue)[0];
        if (currentSubscription) {
          const storedRecentSubscription = JSON.parse(window.localStorage.getItem('recentSubscription')) || [];
          if (storedRecentSubscription.length >= 5) {
            storedRecentSubscription.splice(0, 1);
          }
          storedRecentSubscription.push({
            ...(currentSubscription),
            date: new Date().toLocaleString(),
          });
          storage.setItem('recentSubscription', JSON.stringify(storedRecentSubscription));
        }
        /* Local Storage Section -- End */

        commit('setID', payload.newValue);
        await postRequest('/account/set_subscription/', { subscription: payload.newValue });
        window.location.reload();
      } catch (e) {
        commit('setID', payload.oldValue);
        this._vm.$snackbar.error(e);
      }
    },

    async convertSubscriptionToFarmLink({ commit, state }) {
      try {
        // Sets the new source for the subscription here if user continues to navigate site after converting subscription to Farmlink
        commit('setSource', 'organic');
        await postRequest('/account/convert_subscription_to_farmlink/');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async search({ commit }, payload) {
      try {
        commit('setIsSearchLoading', true);
        const formData = new FormData();
        formData.append('search_id', 5);
        formData.append('search_in', 'subscription_name,userorganizationgroup__user__email,userorganizationgroup__group__is_admin');
        formData.append('search_for', payload);
        const { results } = await postFormRequest('/api/search_subscriptions/', formData);
        commit('setSearchResults', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
      commit('setIsSearchLoading', false);
      return Promise.resolve();
    },

    async patchSubscription({ commit, state }, payload) {
      try {
        const response = await API.patchSubscription(payload);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },

  namespaced: true,
};
