<template>
  <g-dialog
    :value="value"
    :title="featureTitle"
    :action-buttons="actionButtons"
    :width="680"
    transition="slide-x-reverse-transition"
    :is-new-feature-popup="true"
    @cancel="cancel"
  >
    <v-row>
      <v-col
        md="6"
        class="order-sm-0 order-1"
        :class="{$isMobile: 'order-1'}"
      >
        <h5
          class="text-h5 mb-2 primary-grey--text"
        >
          {{ featureName }}
        </h5>
        <h6 class="text-subtitle-1 primary-grey--text mb-2">
          {{ featureSubtitle }}
        </h6>
        <p class="text-pre-line">
          {{ featureDescription }}
        </p>
      </v-col>
      <v-col
        md="6"
        class="d-flex order-sm-1 order-0"
      >
        <v-img
          v-if="featureImage"
          :src="featureImage"
          :alt="featureName"
          width="33vh"
          height="100%"
          contain
          class="elevation-3 rounded-lg"
        />
      </v-col>
    </v-row>
  </g-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GDialog from '../generic/GDialog.vue';

export default {
  name: 'NewFeatureTextDialog',

  components: {
    GDialog,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    featureTitle: {
      type: String,
      default: 'New Feature Alert!',
    },

    featureName: {
      type: String,
      required: true,
    },

    featureSubtitle: {
      type: String,
      required: true,
    },

    featureDescription: {
      type: String,
      required: true,
    },

    featureImage: {
      type: String,
      required: true,
    },

    featureLink: {
      type: String,
      required: true,
    },

    showVideoButton: {
      type: Boolean,
      required: true,
    },

    goToFeatureButtonText: {
      type: String,
      default: 'Explore Tool',
    },
  },

  computed: {
    ...mapState('user', ['picture', 'fullName']),
    ...mapState('launchDarkly', ['flags']),
    ...mapState('dashboard', ['newFeature']),

    actionButtons() {
      const buttons = [];
      let videoButton;

      if (this.showVideoButton && this.flags['new-release-popup-see-video']) {
        videoButton = {
          name: 'See Tutorial Video',
          side: 'right',
          type: 'default',
          onClick: this.openVideoDialog,
        };
        buttons.push(videoButton);
      }

      buttons.push({
        name: this.goToFeatureButtonText,
        side: 'right',
        type: 'primary',
        onClick: this.goToFeature,
      });
      return buttons;
    },
  },

  methods: {
    ...mapActions('dashboard', ['setNewFeatureViewedStatus']),
    goToFeature() {
      this.setNewFeatureViewedStatus(this.newFeature.id);
      if (this.isExternalLink(this.featureLink)) {
        window.open(this.featureLink, '_blank');
        this.$emit('cancel'); // should close the dilaog, as the user is redirected to a new open tab
      } else {
        window.location = this.featureLink;
      }
    },

    isExternalLink(url) {
      try {
        const urlObject = new URL(url);
        return urlObject.origin !== window.location.origin;
      } catch (e) {
        return false;
      }
    },

    openVideoDialog() {
      this.$parent.showVideoDialog();
    },

    cancel() {
      this.$emit('cancel');
      this.$store.dispatch('user/loadUserState', {
        picture: this.picture,
        fullName: this.fullName,
      });
    },
  },
};
</script>
