<template>
  <v-row dense>
    <v-col
      cols="7"
      class="mr-4"
    >
      <v-select
        :value="selectedQuestion"
        :items="questionListPlusExtras"
        item-text="question_string"
        label="Select a Question"
        return-object
        required
        :rules="selectRules"
        @input="selectQuestion"
      />
    </v-col>
    <v-col>
      <v-text-field
        :value="displayValue"
        label="Answer"
        :disabled="!editing"
        :rules="rules"
        :counter="counter"
        :type="type"
        @input="$emit('input', arguments[0])"
      />
    </v-col>
  </v-row>
</template>
<script>
import { requiredValueRule } from '../../helpers/rules';

export default {

  name: 'SecurityQuestionRow',
  props: {
    questionList: {
      type: Array,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    counter: {
      type: Number,
      required: false,
      default: 5,
    },
    value: {
      type: String,
      required: false,
      default: ',',
    },
  },
  data() {
    return {
      selectRules: [requiredValueRule('Question')],
      questionsInCategory: [],
    };
  },
  computed: {
    questionListPlusExtras() {
      if (this.selectedQuestion) {
        return this.questionsInCategory.concat(this.questionList);
      }
      return this.questionList;
    },

    type() {
      return (!this.editing) ? 'password' : '';
    },

    displayValue() {
      return (this.editing) ? this.value : 'xxxxxxxx';
    },

    enableAnswer() {
      return this.editing && this.selectedQuestion;
    },

    applicableRules() {
      return this.enableAnswer ? this.rules : [];
    },
  },
  methods: {
    selectQuestion(question) {
      if (this.selectedQuestion) this.$emit('unselected-question', this.questionsInCategory);
      this.$emit('selected-question', question);
      this.questionsInCategory = this.questionList
        .filter((q) => q.category === question.category && q.id !== question.id);
      this.questionsInCategory.unshift(question);
      this.$emit('set-questions-list', this.questionList.filter((q) => q.category !== question.category));
    },
  },
};
</script>
