<template>
  <div>
    <g-dialog
      v-model="state.uploadDialog"
      title="Upload PDF"
      :action-buttons="formActionButtons"
      height="600"
    >
      <v-file-input
        v-model="state.file"
        :rules="fileRules"
        label="Select File"
        prepend-icon=""
        prepend-inner-icon="mdi-paperclip"
        :show-size="1000"
        :clearable="true"
        :multiple="false"
        accept=".pdf"
        outlined
      />
      <properties-fields
        :state="state"
      />
    </g-dialog>
    <g-dialog
      v-model="confirmDialog"
      title="Confirm"
      :action-buttons="confirmActionButtons"
    >
      <v-card-text>
        <div class="body-1">
          <p>{{ confirmDialogText }}</p>
          <p>
            <span>By publishing, you accept that this document will be shared</span>
            <span
              :class="[
                'font-weight-bold',
                {'error--text': !internalPublication},
              ]"
            >
              {{ noticeText }}
            </span>
          </p>
        </div>
      </v-card-text>
    </g-dialog>
  </div>
</template>
<script>
import GDialog from '@/components/generic/GDialog.vue';
import PropertiesFields from '@/components/document/PropertiesFields.vue';
import { requiredValueRule } from '@/helpers/rules';
import { getLocalDateTime, dateFormatShortMonthDate12HourTime } from '@/helpers/formatting';

export default {
  name: 'UploadDocument',
  components: {
    PropertiesFields,
    GDialog,
  },
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formActionButtons: [
        {
          name: 'Cancel',
          type: 'default',
          side: 'right',
          onClick: () => {
            this.state.uploadDialog = false;
          },
        },
        {
          name: 'Upload',
          color: 'primary',
          side: 'right',
          onClick: () => {
            this.confirmDialog = true;
          },
          mustValidate: true,
        },
      ],
      confirmActionButtons: [
        {
          name: 'Cancel',
          type: 'default',
          side: 'right',
          onClick: () => {
            this.confirmDialog = false;
          },
        },
        {
          name: 'Publish',
          color: 'primary',
          side: 'right',
          onClick: this.handlePublish,
        },
      ],
      fileRules: [
        requiredValueRule('Document File'),
      ],
      confirmDialog: false,
    };
  },
  computed: {
    confirmButtonText() {
      if (this.state.willSaveDraft || this.state.isPublished) return 'Save';
      return this.state.isScheduled ? 'Schedule' : 'Publish';
    },
    confirmDialogText() {
      let confirmationText;
      if (this.state.willSaveDraft) {
        confirmationText = 'Do you want to save the document as a draft?';
      } else if (this.state.isPublished) {
        confirmationText = 'Do you want to save your changes?';
      } else if (this.state.isScheduled) {
        const date = getLocalDateTime(this.state.publishDate, this.state.publishTime);
        confirmationText = `Do you want to schedule publication for ${dateFormatShortMonthDate12HourTime(date)}?`;
      } else {
        confirmationText = 'Do you want to publish the document now?';
      }

      let notificationText;
      if (this.state.sendNotification && !this.state.willSaveDraft) {
        const verb = this.state.isPublished ? 'saved' : 'published';
        notificationText = `Once ${verb}, notifications will be sent.`;
      }

      return [confirmationText, notificationText].join(' ');
    },
    publication() {
      return this.state.allPublications
        .find((x) => x.id === this.state.publicationId);
    },
    internalPublication() {
      return this.publication?.is_internal;
    },
    noticeText() {
      return this.internalPublication
        ? 'internally.'
        : `externally with ${this.publication?.user_count} members.`;
    },
  },
  methods: {
    async handlePublish() {
      const state = {
        data: JSON.stringify({
          name: this.state.name,
          crop: this.state.crop,
          subheader: this.state.subheader,
          contents: this.state.contents,
          topics: this.state.topicIds,
          writers: this.state.writerIds,
          content: this.state.publicationId,
          is_scheduled: this.state.isScheduled,
          date_published: getLocalDateTime(this.state.publishDate, this.state.publishTime)
            .toISOString(),
          allow_sharing: this.state.allowSharing,
          send_notification: this.state.sendNotification,
          notice_accepted: true,
          is_draft: false,
        }),
        file: this.state.file,
      };
      try {
        await API.uploadDocument(state);
        this.confirmDialog = false;
        this.state.uploadDialog = false;
        this.$emit('save');
        this.$snackbar.success('PDF successfully uploaded');
      } catch (e) {
        this.$snackbar.error(e.message);
        this.confirmDialog = false;
      }
    },
  },
};
</script>
