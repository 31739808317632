import Vue from 'vue';
import { featureFlaggedMaska } from './config/maska';
import LoadScript from 'vue-plugin-load-script';
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Zoom from 'fusioncharts/fusioncharts.zoomline';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import firebasePlugin from '@/plugins/firebase';
import launchDarklyPlugin from '@/plugins/launchDarkly';
import websocketPlugin from '@/plugins/websocket';
import lockdownPlugin from '@/plugins/lockdown';
import snackbarPlugin from '@/plugins/snackbar';
import audioPlugin from '@/plugins/audio';
import appcuesPlugin from '@/plugins/appcues';
import globalPlugin from '@/plugins/global';
import Visible from '@/directives/visible';
import ArrowAnchor from '@/directives/arrow-anchor';
import startDatadog from '@/config/datadog';
import startDatadogLogs from '@/config/datadog-logs';
import '@/scss/styles.scss';

// Helpers
import '@/helpers/analytics';
import '@/helpers/transformations';
import '@/helpers/websocket';
import { getLocalDateTime, getStartEndISOStringsFromDateStringPair } from './helpers/formatting';

// Register global components
// TODO: TR-62 Import components only where needed
import '@/components';

// Register global API methods
// TODO: TR-44 Refactor organization of API methods
import '@/api';

// DataDog Frontend Logs
startDatadogLogs();

// TODO: TR-62 Remove once library.html is a component
window.getLocalDateTime = getLocalDateTime;
window.getStartEndISOStringsFromDateStringPair = getStartEndISOStringsFromDateStringPair;

// Turn off dev mode notice
Vue.config.productionTip = false;

// Directives
Vue.directive('visible', Visible);
Vue.directive('arrow-anchor', ArrowAnchor);
// Replace with regular Maska once feature flag removed
Vue.directive('maska', featureFlaggedMaska);

// Plugins
Vue.use(firebasePlugin, { disableNotifications: false });
Vue.use(launchDarklyPlugin, { store });
Vue.use(websocketPlugin, { store });
Vue.use(lockdownPlugin, { store });
Vue.use(snackbarPlugin, { store });
Vue.use(audioPlugin, { store });
Vue.use(appcuesPlugin, { store });
Vue.use(globalPlugin);

// Load Script
Vue.use(LoadScript);

// Fusion Charts
FusionCharts.options.license({
  key: 'xjB3fB-8qH2B7B2A5D4F4B3D3B3D3C11A5C7vaiD4F1nhfxiD5D3A-8teB6A6E3E5B4E4G3F2H3A10A4A4A3D1D3G4poiB9D2C6iG4F4D3G-7B-31jA5C5E3tpgA1C3A4tllG2F4H1C2C6D5C5A3E6D4G4ckeC6D4PF4kmI-8F2C2D5uf1C2KC1C1G-7B-21oC1E5C1B3H3E2A1C7D4B1C4C2E1C3w==',
  creditLabel: false,
});
Vue.use(VueFusionCharts, FusionCharts, Charts, Zoom, TimeSeries, FusionTheme);

// Vue Instance
// eslint-disable-next-line no-new
new Vue({
  vuetify,
  router,
  store,
  el: '#app',
  delimiters: ['[[', ']]'], // LEGACY
  mixins: [window.GRAINFOX_PAGE_MIXIN], // LEGACY
});

// Start DataDog browser monitoring
startDatadog();
