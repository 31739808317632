import { vMaska } from 'maska';

// Tokens
export const multipleDigitsToken = '0:\\d:multiple';
export const optionalDigitsToken = '9:\\d:optional';
export const negativeSignToken = '-:\\-:optional';
export const numericTokens = [multipleDigitsToken, optionalDigitsToken].join('|');
export const negativeNumberTokens = [negativeSignToken, multipleDigitsToken, optionalDigitsToken].join('|');

// Masks
export const twoDecimalMask = '0.99';
export const threeDecimalMask = '0.999';
export const twoDecimalMaskNegative = '-0.99'; // Allows both positive and negative values
export const threeDecimalMaskNegative = '-0.999';

// Feature Flagged Directive (temporary)
export function featureFlaggedMaska(el, binding, vnode) {
  /**
   * Binding arg should be the name of the feature flag being used for the specific input.
   * For example, v-maska:number-rounding or v-maska:inventory-number-rounding
   * This complicates using the v-maska:[options] syntax, but we aren't currently
   * using that anywhere. Just remove the binding argument once the flags are removed.
   * e.g. v-maska:number-rounding -> v-maska
   */
  if (vnode.componentInstance.$store.state.launchDarkly.flags[binding.arg]) {
    vMaska(el, binding);
  }
}
