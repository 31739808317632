const routes = [
  {
    path: '/dashboard/barchart/overview/',
    meta: {
      title: 'Barchart Overview',
    },
  },
  {
    path: '/dashboard/barchart/detail/',
    meta: {
      title: 'Barchart Details',
    },
  },
];

export default routes;
