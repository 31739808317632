<template>
  <div
    class="slide-drawer-tab pt-1 text-center c-pointer"
    tabindex="0"
    @click="$emit('click')"
    @keydown.enter="$emit('click')"
  >
    <component
      :is="iconComponent"
      class="slide-drawer-tab__icon"
    />
  </div>
</template>

<script>
import PullTabDefault from '@/assets/icons/pull-tab-default.svg';
import PullTabChevron from '@/assets/icons/pull-tab-chevron.svg';

export default {
  name: 'SlideDrawerTab',

  components: {
    PullTabDefault,
    PullTabChevron,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    iconComponent() {
      return this.value ? 'pull-tab-default' : 'pull-tab-chevron';
    },
  },
};
</script>

<style lang="scss">
@use "@/colors/colors";

.slide-drawer-tab {
  &:focus {
    outline: none;
  }

  &:focus &__icon, &:hover &__icon {
    color: colors.$greyLighten2;
  }

  &__icon {
    width: 44px;
    height: 12px;
    color: colors.$greyLighten3;
  }
}
</style>
