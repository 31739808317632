<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :style="logoWidthStyle"
    v-html="GrainFoxLogoSVG"
  />
</template>

<script>
import GrainFoxLogo from '@/assets/images/grainfox-logo-black.svg?raw';

/**
 * This is a special use-case logo component.
 * We want to skip the standard SVGO optimizations to make it non-resizable.
 * This lets us achieve an effect where changing the SVG width clips it instead of resizing.
 */
export default {
  props: {
    width: {
      type: Number,
      required: true,
    },
  },

  computed: {
    GrainFoxLogoSVG() {
      const xmlRegex = /<\?xml version="1.0" encoding="UTF-8"\?>\n/;
      const viewboxRegex = /viewBox="(.+)"/;

      // Strip the XML comment first
      const svg = GrainFoxLogo.replace(xmlRegex, '');

      // Check for a viewBox attribute
      const viewbox = svg.match(viewboxRegex)?.[1];
      if (!viewbox) return svg;

      // Replace the viewBox with size attributes
      const [x, y, width, height] = viewbox.split(' ');
      const attributes = `x="${x}" y="${y}" width="${width}" height="${height}" `;
      return svg.replace(viewboxRegex, attributes);
    },

    logoWidthStyle() {
      return `width:${this.width}px;`;
    },
  },
};
</script>
