import { postRequest } from '@/helpers/request';
import { datadogRum } from '@datadog/browser-rum';

export default {
  state: () => ({
    integrationsMenuOpen: false,
    integrationSources: {},
    integrationsStatus: {},
    integratedActualProductions: {},
  }),

  getters: {
    getIntegrationSourceById: (state) => (id) => state.integrationSources[id],

    getIntegrationSourceByName: (state) => (name) => Object.values(state.integrationSources).find(
      (source) => source.name === name,
    ),

    integrationsStatusBySourceId: (state) => Object.values(state.integrationsStatus)
      .reduce((acc, status) => ({
        ...acc,
        [status.source]: status,
      }), {}),

    integratedActualProductionsWithSourceData: (state) => Object.entries(state.integratedActualProductions)
      .reduce((acc, [k, v]) => {
        const integrationSource = state.integrationSources[v.integration_source];
        acc[k] = {
          source: {
            id: v.integration_source,
            displayName: integrationSource.display_name,
            brandColor: integrationSource.brand_color,
          },
          production: {
            id: v.id,
            seededArea: v.seeded_area,
            harvestedArea: v.harvested_area,
            yield: v.prod_yield,
            production: v.production,
            areaUnitId: v.area_unit,
            productionUnitId: v.production_unit,
            modifiedSinceImport: v.modified_since_import,
          },
        };
        return acc;
      }, {}),

    activeIntegration: (state) => {
      const firstActiveStatus = Object.values(state.integrationsStatus).find((source) => source.status);
      if (!firstActiveStatus) return null;
      return state.integrationSources[firstActiveStatus.source];
    },
  },

  mutations: {
    setIntegrationsMenuOpen(state, value) {
      state.integrationsMenuOpen = value;
    },

    setIntegrationSources(state, integrationSources) {
      // Attach brand color to the integration source on the frontend
      state.integrationSources = integrationSources;
    },

    setIntegrationsStatus(state, integrationsStatus) {
      state.integrationsStatus = integrationsStatus;
    },

    updateIntegratedActualProductions(state, productions) {
      // We might fetch integration mappings for various productions
      // from various parts of Farm Profile, so we should treat this
      // as incremental data and do an update or create, rather
      // than completely replacing state each time.
      state.integratedActualProductions = {
        ...state.integratedActualProductions,
        ...productions,
      };
    },
  },

  actions: {
    /**
     * Log info to DataDog
     * @param {*} vuex Vuex information
     * @param {object} payload Info to log
     * @param {string} payload.action Action to log
     * @param {string} payload.sourceId Source to log
     * @param {string} [payload.data] Data to log
     */
    logDataDogEvent({ state }, payload) {
      const source = state.integrationSources[payload.sourceId];
      const status = state.integrationsStatus[payload.sourceId];
      datadogRum.addAction(`integration.${payload.action}`, {
        source,
        status,
        data: payload.data,
      });
    },

    async fetchIntegrationSources({ commit }) {
      try {
        const { results: sources } = await postRequest('/farm_profile/api/integrations/fetch_integration_sources/');
        commit('setIntegrationSources', sources);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async fetchIntegrationsStatus({ commit }) {
      try {
        const { results: status } = await postRequest('/farm_profile/api/integrations/get_status/');
        commit('setIntegrationsStatus', status);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async syncUpdateProduction({ commit }, payload) {
      try {
        const { message } = await postRequest('/farm_profile/api/integrations/sync_productions/', payload);
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async fetchIntegrationProductionsForCropYear({ commit }, cropYearId) {
      try {
        const { results } = await API.fetchProductionMappings(cropYearId);
        // The frontend generally works with ActualProductions and needs to do a
        // lookup based on this ID, not the IntegrationProduction ID, so we will
        // shuffle the keys around - maybe replace with a getter or lookup fn.
        // To be clear, the production here is an IP, grouped by AP ID.
        const normalized = Object.values(results).reduce((acc, current) => {
          acc[current.actual_production] = current;
          return acc;
        }, {});
        commit('updateIntegratedActualProductions', normalized);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },

  namespaced: true,
};
