<template>
  <v-list-item
    two-line
    link
    @click.prevent="handleSelect"
  >
    <v-list-item-content>
      <div class="d-flex">
        <div
          v-visible="!isRead"
          @click.stop="handleRead"
        >
          <tooltip-bottom
            text="Mark as Read"
          >
            <v-badge
              color="primary"
              class="pr-2"
              inline
              dot
            />
          </tooltip-bottom>
        </div>
        <div>
          <v-list-item-title class="text-wrap">
            {{ notification.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ date }}
          </v-list-item-subtitle>
        </div>
        <div
          v-if="dismissable"
          class="ml-auto pl-2"
        >
          <v-btn
            icon
            small
            class="mt-n1"
            @click.stop="handleDismiss"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { dateFormatRecentVariantLong } from '../../helpers/formatting';
import TooltipBottom from '../generic/TooltipBottom.vue';

export default {
  components: { TooltipBottom },

  props: {
    notification: {
      type: Object,
      required: true,
    },

    dismissable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    isRead() {
      return this.notification.read;
    },

    date() {
      return dateFormatRecentVariantLong(new Date(this.notification.timestamp));
    },

    type() {
      return this.notification.template_name;
    },
  },

  methods: {
    dateFormatRecentVariantLong,

    async handleRead() {
      await window.markNotificationRead(this.notification.id);
    },

    async handleSelect() {
      // Note that there's an intentional lack of awaiting for the API return
      // farther along in the callstack, causing a subtle race condition.
      // This is intentional (for responsiveness), but note that this await doesn't
      // guarantee that the backend is notified.
      // i.e. you could change window.location.href before the request fires off
      await this.handleRead();

      if (this.type === 'commented_on') {
        window.previewFile(parseInt(this.notification.args.arg3, 10));
      } else if (this.type.includes('chat_notification')) {
        const conversationID = this.notification.args.arg3;
        const isValidID = (conversationID ?? -1) > -1;
        window.location.href = isValidID ? `/messenger/${conversationID}/` : '/messenger/';
      } else if (this.type === 'new_file_upload') {
        window.previewFile(parseInt(this.notification.args.arg1, 10));
      } else if (this.type === 'invite_organization') {
        const subscriptionId = this.notification.args.arg4;
        const email = this.notification.args.arg7;
        const isMember = await API.isMemberOfSubscription(subscriptionId, email);
        const inviteKey = this.notification.args.arg8;
        const subscription = this.notification.args.arg5;
        window.openOrganizationInviteDialog({ isMember, inviteKey, subscription });
      }

      if (this.type === 'send_message_approval' || this.type === 'send_message_approved') {
        window.location.href = '/account/css_module/send_message';
      }

      if (this.type === 'marketing_message_approval' || this.type === 'marketing_message_approved') {
        window.location.href = '/marketing_module/marketing_message';
      }
    },

    handleDismiss() {
      this.$store.dispatch('app/dismissNotification', this.notification.id);
      this.$emit('notification-dismissed');
    },
  },
};
</script>
