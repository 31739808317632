<template>
  <div
    ref="scroll"
    class="d-flex overflow-hidden no-scrollbar"
    :style="`max-width: ${maxWidth};`"
  >
    <template v-for="index in count">
      <div
        ref="dot"
        :key="index"
        :style="dotStyle(index - 1)"
        :class="{ dot: true, [dotClass(index - 1)]: true, 'dot-active': isActive(index - 1) }"
      />
    </template>
  </div>
</template>
<script>
import { bindToRange } from '../../helpers/numeric';

export default {
  props: {
    count: {
      type: Number,
      required: true,
    },

    size: {
      type: Number,
      default: 8,
    },

    activeIndex: {
      type: Number,
      default: 0,
    },

    affix: {
      type: Boolean,
      default: false,
    },

    maxItems: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      indexInSubset: 0,
      prefixStart: -2,
      suffixEnd: this.maxItems + 1,
    };
  },
  computed: {
    maxWidth() {
      if (this.affix && this.count > this.maxItems) {
        return `${(this.size + 8) * (this.maxItems + 4)}px`;
      }
      return 'none';
    },
  },
  watch: {
    activeIndex(newValue, oldValue) {
      const delta = newValue - oldValue;
      const id = this.indexInSubset + delta;
      if (id < 0 && this.prefixStart === -2) {
        // attempting to move too far left, probably on mount
        return;
      }
      const upper = this.maxItems - 1;
      this.indexInSubset = bindToRange(id, 0, upper);
      if (id < 0) {
        this.prefixStart += id;
        this.suffixEnd += id;
      } else if (id > upper) {
        this.prefixStart += (id - upper);
        this.suffixEnd += (id - upper);
      }
      this.$refs.dot[newValue].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    },
  },
  mounted() {
    this.$refs.scroll.scrollLeft = 0;
  },
  methods: {

    dotStyle(index) {
      const clas = this.dotClass(index);
      const size = clas === 'dot--none' ? 0 : this.size;
      return `
        width: ${size}px;
        max-width: ${size}px;
        min-width: ${size}px;
        height: ${size}px
      `;
    },

    dotClass(index) {
      if (!this.affix || this.count <= this.maxItems) {
        return 'dot--large';
      }
      if (this.activeIndex === index) {
        return 'dot--large';
      }
      if (index < this.prefixStart || index > this.suffixEnd) {
        return 'dot--none';
      }
      if (index === this.prefixStart || index === this.suffixEnd) {
        return 'dot--small';
      }
      if (index === this.prefixStart + 1 || index === this.suffixEnd - 1) {
        return 'dot--medium';
      }
      return 'dot--large';
    },

    isActive(index) {
      return this.activeIndex === index;
    },
  },
};
</script>
<style lang="scss" scoped>
@use "@/colors/colors";
.dot {
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: colors.$greyLighten4;
  opacity: 1;
  transition: all 300ms ease;
  &--small {
    transform: scale(0.5);
  }
  &--medium {
    transform: scale(0.75);
  }
  &--large {
    transform: scale(1);
  }
  &--none {
    transform: scale(0.5);
    opacity: 0;
    margin: 0px;
  }
}
.dot-active {
  background-color: colors.$primary;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
</style>
