import { postRequest } from '../helpers/request';

export async function apiGetRiskProfileResults(options) {
  return postRequest('/risk_profile/api/get_risk_profile_results_for_subscription/', options);
}

export async function apiGetQuestionnaire(options) {
  return postRequest('/risk_profile/get_questionnaire/', options);
}

export async function apiSubmitRiskProfileResults(options) {
  return postRequest('/risk_profile/submit_questionnaire/', options);
}

export async function apiGetRiskProfileProgress(options) {
  return postRequest('/risk_profile/api/get_risk_profile_progress/', options);
}

export async function apiUpsertRiskProfileQuestionProgress(options) {
  return postRequest('/risk_profile/api/upsert_risk_profile_question_progress/', options);
}
