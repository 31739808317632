export const iconList = [
  {
    name: 'Account',
    import: () => import('@/assets/icons/empty-state-account.svg'),
  },
  {
    name: 'AddCrop',
    import: () => import('@/assets/icons/empty-state-add-crop.svg'),
  },
  {
    name: 'Chat',
    import: () => import('@/assets/icons/empty-state-chat.svg'),
  },
  {
    name: 'Error',
    import: () => import('@/assets/icons/empty-state-error.svg'),
  },
  {
    name: 'Farm',
    import: () => import('@/assets/icons/empty-state-farm.svg'),
  },
  {
    name: 'Filters',
    import: () => import('@/assets/icons/empty-state-filters.svg'),
  },
  {
    name: 'NoCharts',
    import: () => import('@/assets/icons/empty-state-no-charts.svg'),
  },
  {
    name: 'NoInformation',
    import: () => import('@/assets/icons/empty-state-no-information.svg'),
  },
  {
    name: 'NoReports',
    import: () => import('@/assets/icons/empty-state-no-reports.svg'),
  },
  {
    name: 'NoResults',
    import: () => import('@/assets/icons/empty-state-no-results.svg'),
  },
  {
    name: 'Payment',
    import: () => import('@/assets/icons/empty-state-payment.svg'),
  },
  {
    name: 'Permissions',
    import: () => import('@/assets/icons/empty-state-permissions.svg'),
  },
  {
    name: 'Price',
    import: () => import('@/assets/icons/empty-state-price.svg'),
  },
  {
    name: 'Storage',
    import: () => import('@/assets/icons/empty-state-storage.svg'),
  },
  {
    name: 'UpgradeAccount',
    import: () => import('@/assets/icons/empty-state-upgrade-account.svg'),
  },
  {
    name: 'Coupon',
    import: () => import('@/assets/icons/empty-state-coupon.svg'),
  },
];

export const asyncComponents = iconList.reduce((acc, current) => {
  acc[current.name] = current.import;
  return acc;
}, {});
