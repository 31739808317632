<template>
  <g-dialog
    v-model="dialogValue"
    :title="title"
    disable-fullscreen
    :width="680"
    :action-buttons="buttons"
    @cancel="closeDialog"
  >
    <v-row>
      <v-col
        cols="12"
        class="py-5"
        data-qa="user-profile-delete-profile-text"
      >
        <div class="subtitle-text-2 grey-darken-4">
          Are you sure you want to delete your profile? This action is not reversible.
        </div>
        <div
          v-if="ownsAnySubscription"
          class="subtitle-text-2 grey-darken-4"
        >
          Since you're the main account holder of at least one organization,
          the organization will be deleted along with all its data.
        </div>
      </v-col>
    </v-row>
  </g-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import GDialog from '../generic/GDialog.vue';
import { postRequest } from '../../helpers/request';
import { dateFormatShortMonthDateYear } from '@/helpers/formatting';

export default {
  components: {
    GDialog,
  },

  props: {
    /**
     * Whether the modal is open (or not)
     */
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      title: 'Delete Profile',
      accountHolderText: 'Since you\'re the main account holder of at least one organization, the organization will be deleted along with all its data.',
      bodyText: 'Are you sure you want to delete your profile? This action is not reversible.',
    };
  },

  computed: {
    ...mapGetters('subscription', ['ownsAnySubscription']),
    buttons() {
      return [
        {
          name: 'CANCEL',
          side: 'right',
          type: 'default',
          onClick: this.closeDialog,
          mustValidate: true,
        },
        {
          name: 'DELETE',
          side: 'right',
          type: 'delete',
          onClick: this.handleDeletion,
          mustValidate: true,
        },
      ];
    },

    dialogValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

  },

  methods: {
    dateFormatShortMonthDateYear,
    closeDialog() {
      this.$emit('input', false);
    },

    async handleDeletion() {
      if (this.ownsAnySubscription) {
        this.closeDialog();
        this.$emit('handle-delete');
      } else {
        try {
          const result = await postRequest('/account/schedule_for_deletion/', {});
          const expirationDate = dateFormatShortMonthDateYear(new Date(result.expiration_date));
          const successMessage = `Your account will be deleted on ${expirationDate}.`;
          this.$snackbar.success(successMessage);
        } catch (e) {
          // snackbar
          if (e && Array.isArray(e) && e.length > 0) {
            this.$snackbar.error(e[0]);
          } else {
            this.$snackbar.error(e);
          }
        }
        this.closeDialog();
        this.$emit('deleted');
      }
    },
  },
};
</script>
