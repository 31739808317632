<template>
  <v-btn
    v-if="showUpgradeButton"
    color="primary"
    href="/commerce/tiers/"
  >
    {{ text }}
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';

const BASIC_TEXT = 'Upgrade';
const PLAN_TEXT_MOBILE = 'View plans';
const PLAN_TEXT_DESKTOP = 'View subscription plans';

export default {
  name: 'TrialUpdateButton',

  computed: {
    ...mapGetters('chargebee', ['showUpgradeButton', 'isBasic']),
    ...mapGetters('subscription', ['isDeputter', 'isSyngenta']),

    text() {
      if (!this.showUpgradeButton && !this.isDeputter && !this.isSyngenta) return '';

      if (this.isBasic) return BASIC_TEXT;

      if (this.$vuetify.breakpoint.mdAndDown) return PLAN_TEXT_MOBILE;

      return PLAN_TEXT_DESKTOP;
    },
  },
};
</script>
