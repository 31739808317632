<template>
  <div
    :class="{'d-flex' : !vertical}"
    class="align-center justify-space-between flex-wrap-reverse ml-auto"
  >
    <v-switch
      v-model="state.filterFavouritesToggle"
      color="primary"
      label="Favourites"
      :prepend-icon="state.filterFavouritesToggle ? 'mdi-star' : 'mdi-star-outline'"
      class="mt-0 mb-4 mb-sm2 mr-0 mr-sm-4 pt-0 px-4"
      hide-details
    />
    <template v-if="vertical">
      <v-list>
        <v-list-item-group v-model="state.filterViewMode">
          <v-list-item
            value="normal"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-file-document-multiple
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Resource Hub
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-show="canReadDrafts"
            value="drafts"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-file-document-edit
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Drafts
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-show="canReadScheduled"
            value="scheduled"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-calendar-clock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Scheduled
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!isProduction"
            value="shared"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-folder-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Shared
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            value="archived"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-package-down
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Archive
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-else>
      <v-btn-toggle
        v-model="state.filterViewMode"
        :class="{ 'flex-w--100' : !$vuetify.breakpoint.smAndUp }"
        class="mb-4 mb-sm2 flex-column flex-sm-row"
        mandatory
        dense
        group
      >
        <v-btn value="normal">
          <v-icon left>
            mdi-file-document-multiple
          </v-icon> Resource Hub
        </v-btn>
        <v-btn
          v-show="canReadDrafts"
          value="drafts"
        >
          <v-icon left>
            mdi-file-document-edit
          </v-icon> Drafts
        </v-btn>
        <v-btn
          v-show="canReadScheduled"
          value="scheduled"
        >
          <v-icon left>
            mdi-calendar-clock
          </v-icon> Scheduled
        </v-btn>
        <v-btn
          v-if="!isProduction"
          value="shared"
        >
          <v-icon left>
            mdi-folder-account
          </v-icon> Shared
        </v-btn>
        <v-btn value="archived">
          <v-icon left>
            mdi-package-down
          </v-icon> Archive
        </v-btn>
      </v-btn-toggle>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },

    canReadDrafts: {
      type: Boolean,
    },

    canReadScheduled: {
      type: Boolean,
    },

    vertical: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState('app', ['isProduction']),
  },

  watch: {
    'state.filterViewMode': {
      handler() {
        this.handleUpdate();
      },
    },

    'state.filterFavouritesToggle': {
      handler() {
        this.handleUpdate();
      },
    },
  },

  methods: {
    handleUpdate() {
      this.$emit('update');
    },
  },
};
</script>
