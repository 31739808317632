import {
  getGMAClientReports, getGMABasisContracts, getGMAValidSubscriptions, getGMAActivityData, getGMAMonthlyInventoryLevels,
} from '@/api/gmaModule';

export default {
  namespaced: true,

  state: () => ({
    reportData: null,
    basisContracts: null,
    validSubscriptions: [],
    activities: [],
    hasMoreActivities: true,
    monthlyInventoryLevels: null,
  }),

  mutations: {
    setActivityData(state, data) {
      state.activities = data;
    },
    setReportData(state, data) {
      state.reportData = data;
    },
    setBasisContracts(state, data) {
      state.basisContracts = data;
    },
    setValidSubscriptions(state, data) {
      state.validSubscriptions = data;
    },
    setHasMoreActivities(state, data) {
      state.hasMoreActivities = data;
    },
    setMonthlyInventoryLevels(state, data) {
      state.monthlyInventoryLevels = data;
    },
  },

  actions: {
    async getReportData({ commit }, payload) {
      try {
        const data = await getGMAClientReports(payload);

        commit('setReportData', data.reports);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getBasisContracts({ commit }, payload) {
      try {
        const data = await getGMABasisContracts(payload);

        commit('setBasisContracts', data.contracts);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getMonthlyInventoryLevels({ commit }, payload) {
      try {
        const data = await getGMAMonthlyInventoryLevels(payload);

        commit('setMonthlyInventoryLevels', data.inventory_levels);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async fetchValidSubscriptions({ commit }) {
      try {
        const data = await getGMAValidSubscriptions();
        const subs = [...data.subscriptions];
        subs.sort((a, b) => a.subscription_name.localeCompare(b.subscription_name));
        commit('setValidSubscriptions', subs);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
    async fetchActivityData({ state, commit }, payload) {
      try {
        const data = await getGMAActivityData(payload);
        commit('setActivityData', state.activities.concat(data.data));
        commit('setHasMoreActivities', !data.last_page);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },
};
