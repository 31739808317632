<template>
  <v-card
    :loading="loading"
  >
    <template slot="progress">
      <v-progress-linear
        color="primary"
        background-color="grey lighten-2"
        :value="value"
      />
    </template>
    <div :class="$isTablet || $isMobile ? 'px-4' : 'px-6'">
      <v-form
        ref="feedbackForm"
        v-model="isFeedbackFormValid"
        @submit.prevent="submitFeedback"
      >
        <div
          :class="{
            'd-flex justify-space-between align-center':
              showDesktop
          }"
        >
          <div>
            <p
              class="text-h6 font-weight-medium primary-grey--text pl-0 mb-0"
              :class="$isTablet || $isMobile ? 'pt-4' : 'pt-6'"
            >
              We’d love to hear from you!
            </p>
            <v-card-subtitle
              class="grey--text text--darken-1 pl-0 pt-1"
              :class="$isTablet || $isMobile ? 'pb-4' : 'pb-6'"
            >
              What do you think of GrainFox?
              <br>
              <span v-if="show">
                Your feedback will help us improve the GrainFox experience for every producer
                on the platform, so let us know in the box below!
              </span>
            </v-card-subtitle>
          </div>
          <div :class="{'pb-4 text-right': !showDesktop}">
            <v-btn
              v-if="!show"
              color="primary"
              data-qa="feedback-widget-give-feedback-button"
              @click="show=true"
            >
              Give Feedback
            </v-btn>
          </div>
        </div>
        <v-expand-transition>
          <div v-show="show">
            <v-card-text class="pa-0">
              <v-row dense>
                <v-col cols="12">
                  <v-textarea
                    v-model="comments"
                    label="Comments"
                    :rules="[requiredValueRule('Comments')]"
                    outlined
                    required
                    maxlength="1000"
                    data-qa="feedback-widget-form-input"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="px-0 pt-0 pb-6">
              <v-spacer />
              <v-btn
                type="submit"
                color="primary"
                :disabled="!isFeedbackFormValid || loading"
                data-qa="feedback-widget-form-submit-button"
              >
                <v-icon
                  v-if="!loading"
                  class="pr-2"
                  small
                >
                  mdi-send
                </v-icon>
                {{ loading ? 'Feedback Sent!' : 'Send Feedback' }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-expand-transition>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { requiredValueRule } from '../../helpers/rules';
import { postFormRequest } from '../../helpers/request';

export default {

  props: {
    showDesktopView: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      isFeedbackFormValid: false,
      comments: '',
      show: false,
      loading: false,
      value: 0,
    };
  },
  computed: {
    showDesktop() {
      if (this.showDesktopView !== null) {
        return this.showDesktopView;
      }
      return !this.$isMobile;
    },
  },

  methods: {
    requiredValueRule,

    async submitFeedback() {
      const data = new FormData();
      data.append('comments', this.comments);
      try {
        this.loading = true;
        this.interval = setInterval(() => {
          if (this.value > 100) {
            this.resetFeedbackForm();
            return;
          }
          this.value += 4;
        }, 100);
        await postFormRequest('/api/submit_feedback/', data);
      } catch (e) {
        this.$snackbar.error(e.toString());
      }
    },

    resetFeedbackValidation() {
      this.$refs.feedbackForm.resetValidation();
    },

    resetFeedbackForm() {
      this.loading = false;
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = null;
      this.value = 0;
      this.comments = '';
      this.show = false;
      this.resetFeedbackValidation();
    },
  },
};
</script>
