<template>
  <v-tooltip
    :disabled="!showTooltip"
    bottom
  >
    <template #activator="{ on, attrs }">
      <div
        :class="[marginClass, flexClass]"
        :data-side="side"
        :data-cta="isCTA"
        v-on="on"
      >
        <v-btn
          :text="text"
          :block="block"
          :color="color"
          :type="buttonType"
          :disabled="isDisabled"
          :loading="loading"
          :data-qa="`g-dialog-button-${type}`"
          v-bind="attrs"
          @click="handleClick"
        >
          <v-icon
            v-if="hasIcon"
            class="pr-2"
            small
          >
            {{ icon }}
          </v-icon>
          {{ name }}
        </v-btn>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'GDialogButton',

  props: {
    index: {
      type: Number,
      required: true,
    },

    isMobile: {
      type: Boolean,
      required: true,
    },

    isFormValid: {
      type: Boolean,
      required: true,
    },

    isProcessingRequest: {
      type: Boolean,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    side: {
      type: String,
      default: '', // ['left', 'right']
    },

    type: {
      type: String,
      default: 'primary', // ['primary', 'default', 'delete', 'cta]
    },

    onClick: {
      type: Function,
      default: () => {},
    },

    submit: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: '',
    },

    mustValidate: {
      type: Boolean,
      default: false,
    },

    hideOnMobile: {
      type: Boolean,
      default: false,
    },

    hasIcon: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    full: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    isCTA() {
      return this.type === 'cta';
    },

    marginClass() {
      if (this.isCTA) return 'my-4';
      if (this.index !== 0 && this.isMobile) return 'mt-4';
      return '';
    },

    flexClass() {
      return this.isMobile && !this.isCTA ? 'flex-0 flex-grow-1' : '';
    },

    text() {
      if (this.full !== null) {
        return !this.full;
      }
      return !this.isCTA;
    },

    block() {
      return this.isMobile && !this.isCTA;
    },

    color() {
      switch (this.type) {
        case 'primary':
          return 'primary';
        case 'default':
          return '';
        case 'delete':
          return 'error';
        case 'cta':
          return 'primary';
        default:
          return '';
      }
    },

    buttonType() {
      return this.submit ? 'submit' : 'button';
    },

    isInvalid() {
      return !this.isFormValid && this.mustValidate;
    },

    isDisabled() {
      return this.isProcessingRequest || this.isInvalid || this.disabled;
    },

    showTooltip() {
      return !!this.tooltip && (this.isInvalid || this.disabled);
    },
  },

  methods: {
    handleClick() {
      this.$emit('process-click', {
        onClick: this.onClick,
        mustValidate: this.mustValidate,
      });
    },
  },
};
</script>

<style lang="scss">
.g-dialog__actions {
  &--desktop {
    [data-side=right] {
      margin-left: auto !important;
    }
    [data-side=right] ~ [data-side=right] {
      margin-left: initial !important;
    }
    [data-side] {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  [data-side] {
    max-width: 100%;
  }

  [data-cta] {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .v-btn {
    height: auto !important;
    min-height: 36px;
    max-width: 100%;
    padding: 8px 16px !important;
    overflow: hidden;
    white-space: normal !important;

    &__content {
      width: 100%;
    }
  }
}
</style>
