<template>
  <div>
    <v-fade-transition v-if="isOpen && !minimize">
      <div
        class="slide-drawer__overlay"
        @click="handleClickOutside"
      />
    </v-fade-transition>
    <aside
      class="slide-drawer slide-drawer--bottom
      d-flex flex-column rounded-xl
      rounded-b-0 mx-0 mx-md-16"
      :class="drawerClasses"
      :style="drawerStyles"
    >
      <!-- Minimize -->
      <SlideDrawerTab
        v-show="showToggle"
        v-touch="touchHandlers"
        :value="minimize"
        @click="handleClickToggle"
      />

      <div class="d-flex flex-column flex-1 py-4 px-4 px-md-10 overflow-hidden">
        <!-- Header -->
        <SlideDrawerHeader
          v-touch="touchHandlers"
          :title="title"
          :subtitle="subtitle"
          :info="info"
          :permanent="permanent"
          :class="{'pl-5 pt-3':!showToggle}"
          @close="handleClickClose"
        >
          <template
            v-if="this.$slots.title"
            #title
          >
            <slot name="title" />
          </template>
          <template
            v-if="this.$slots.subtitle"
            #subtitle
          >
            <slot name="subtitle" />
          </template>
          <template
            v-if="this.$slots.info"
            #info
          >
            <slot name="info" />
          </template>
        </SlideDrawerHeader>

        <!-- Content -->
        <div class="flex-grow-1 overflow-y-auto">
          <slot />
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import SlideDrawerTab from './SlideDrawerTab.vue';
import SlideDrawerHeader from './SlideDrawerHeader.vue';

export default {
  name: 'SlideDrawerBottom',

  components: {
    SlideDrawerTab,
    SlideDrawerHeader,
  },

  props: {
    value: {
      default: undefined,
      validator(value) {
        return typeof value === 'boolean' || typeof value === 'undefined';
      },
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    info: {
      type: String,
      default: '',
    },

    permanent: {
      type: Boolean,
      default: false,
    },

    minimize: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
      default: 520,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    nudgeTop: {
      type: Number,
      default: 0,
    },

    nudgeBottom: {
      type: Number,
      default: 0,
    },

    drawerClasses: {
      type: Object,
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },

    showToggle: {
      type: Boolean,
      default: true,
    },

    peekWidth: {
      type: Number,
      default: 64,
    },

    fitContent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      touchHandlers: {
        up: () => this.swipe('up'),
        down: () => this.swipe('down'),
      },
    };
  },

  computed: {
    drawerStyles() {
      const padding = 16;
      const defaultTop = this.$headerOffset + padding;
      const styles = {
        left: `${this.$navigationOffset}px`,
        'z-index': `${this.minimize ? 6 : 10}`,
      };
      if (!this.fitContent) {
        styles.top = `${defaultTop + this.nudgeTop}px`;
      }
      return styles;
    },
  },

  methods: {
    handleClickOutside() {
      if (this.isOpen && !this.minimize) this.handleClickToggle();
    },

    handleClickToggle() {
      this.$emit('toggle-minimize');
    },

    handleClickClose() {
      this.$emit('close');
    },

    swipe(direction) {
      const slideUp = direction === 'up' && this.minimize;
      const slideDown = direction === 'down' && !this.minimize;
      if (slideUp || slideDown) {
        this.handleClickToggle();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-drawer--bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px -8px 16px rgba(181, 153, 133, 0.12); // TODO: UT-928 Elevation 3 Inverted

  &.slide-drawer--minimize {
    transform: translateY(calc(100% - 126px));
  }

  &.slide-drawer--close {
    transform: translateY(calc(100% + 32px));
  }
}
.slide-drawer__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(33, 33, 33, 0.46);
  z-index: 9;
}
</style>
