<template>
  <div
    class="d-flex flex-column align-center justify-center text-center pt-1 px-4"
    :class="{'pb-4': $isMobile, 'pb-6': !$isMobile}"
  >
    <slot name="icon">
      <template v-if="icon !== undefined">
        <v-icon
          size="80"
          class="mb-4"
          color="accent"
        >
          {{ icon }}
        </v-icon>
      </template>
    </slot>
    <slot name="title">
      <h5
        v-if="title !== undefined"
        class="text-h5 grey--text text--darken-4 pt-1"
      >
        {{ title }}
      </h5>
    </slot>
    <slot name="subtitle">
      <span
        v-if="subtitle !== undefined"
        class="text-body-1 primary-grey--text pt-2"
        :class="{'pb-6': buttonText !== undefined}"
      >
        {{ subtitle }}
      </span>
    </slot>
    <slot name="button">
      <template v-if="buttonText !== undefined && buttonHref">
        <v-btn
          color="primary"
          :href="buttonHref"
        >
          {{ buttonText }}
        </v-btn>
      </template>
      <template v-else-if="buttonText">
        <v-btn
          color="primary"
          @click="onClick"
        >
          {{ buttonText }}
        </v-btn>
      </template>
    </slot>
  </div>
</template>
<script>

export default {
  name: 'EmptyWidget',

  props: {
    icon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    buttonText: {
      type: String,
      default: undefined,
    },
    // If buttonHref is not defined, button will emit 'click' event
    buttonHref: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
