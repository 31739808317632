import { SCOPE_FARM_PROFILE } from './scopes';

const FarmProfile = () => import('@/pages/farm_profile/FarmProfile.vue');

// Farms
const FarmsPage = () => import('@/pages/farm_profile/farms/FarmsPage.vue');
const FarmsTab = () => import('@/components/farm_profile/farms/FarmsTab.vue');

// Commodities
const CommoditiesPage = () => import('@/pages/farm_profile/commodities/CommoditiesPage.vue');

// Inventory
const InventoryPage = () => import('@/pages/farm_profile/inventory/InventoryPage.vue');
const InventoryDetailPage = () => import('@/pages/farm_profile/inventory/InventoryDetailPage.vue');

// Sales
const NewSalesPage = () => import('@/pages/farm_profile/sales/NewSalesPage.vue');
const SalesContractsTab = () => import('@/pages/farm_profile/sales/SalesContractsTab.vue');
const SalesDeliveriesTab = () => import('@/pages/farm_profile/sales/SalesDeliveriesTab.vue');
const SalesCommoditiesTab = () => import('@/pages/farm_profile/sales/SalesCommoditiesTab.vue');
const ContractViewPage = () => import('@/pages/farm_profile/sales/ContractViewPage.vue');
const ContractAddEditPage = () => import('@/pages/farm_profile/sales/ContractAddEditPage.vue');
const DeliveryViewPage = () => import('@/pages/farm_profile/sales/DeliveryViewPage.vue');
const DeliveryAddEditPage = () => import('@/pages/farm_profile/sales/DeliveryAddEditPage.vue');

// Reports
const ReportsPage = () => import('@/pages/farm_profile/reports/ReportsPage.vue');
const ReportsOverviewTab = () => import('@/pages/farm_profile/reports/ReportsOverviewTab.vue');
const ReportsProductionTab = () => import('@/pages/farm_profile/reports/ReportsProductionTab.vue');
const ReportsSalesTab = () => import('@/pages/farm_profile/reports/ReportsSalesTab.vue');

const routes = [
  {
    path: '/farm_profile',
    name: SCOPE_FARM_PROFILE,
    meta: {
      title: 'Farm Profile',
    },
    component: FarmProfile,
    children: [
      {
        path: 'farms/',
        component: FarmsPage,
        meta: {
          title: 'Farms',
          icon: {
            type: 'mdi',
            value: 'mdi-barn',
          },
          permission: {
            scope: 'farm_profile',
            permission: 'read',
          },
        },
        children: [
          {
            path: '',
            name: 'FarmProfileFarms',
            component: FarmsTab,
            meta: {
              title: 'Area',
            },
            props: { tab: 'Area' },
          },
          {
            path: 'area/',
            name: 'FarmProfileFarmsArea',
            component: FarmsTab,
            meta: {
              title: 'Area',
            },
            props: { tab: 'Area' },
          },
          {
            path: 'yield/',
            name: 'FarmProfileFarmsYield',
            component: FarmsTab,
            meta: {
              title: 'Yield',
            },
            props: { tab: 'Yield' },
          },
          {
            path: 'production/',
            name: 'FarmProfileFarmsProduction',
            component: FarmsTab,
            meta: {
              title: 'Production',
            },
            props: { tab: 'Production' },
          },
        ],
      },
      {
        path: 'commodities/',
        name: 'FarmProfileCommodities',
        component: CommoditiesPage,
        props: true,
        meta: {
          title: 'Commodities',
          icon: {
            type: 'mdi',
            value: 'mdi-grain',
          },
          permission: {
            scope: 'farm_profile',
            permission: 'read',
          },
        },
        children: [
          {
            path: 'contract/new/',
            name: 'FarmProfileCommoditiesContractNew',
            component: ContractAddEditPage,
            props: (route) => ({
              mainContractId: parseInt(route.params.mainContractId, 10) || null,
              cropYear: route.params.cropYear,
              buyer: route.params.buyer,
              region: route.params.region,
              commodity: route.params.commodity,
              derivedCropYear: route.params.derivedCropYear,
            }),
            meta: {
              title: 'New contract',
            },
          },

          {
            path: 'contract/:contractId/',
            name: 'FarmProfileCommoditiesContract',
            component: ContractViewPage,
            props: (route) => ({
              contractId: parseInt(route.params.contractId, 10) || -1,
              // If we aren't on the contract route, we must be on a child route
              showContract: route.name === 'FarmProfileCommoditiesContract',
            }),
            meta: {
              title: 'Contract',
            },
            children: [
              {
                path: 'edit/',
                name: 'FarmProfileCommoditiesContractEdit',
                component: ContractAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                }),
                meta: {
                  title: 'Edit contract',
                },
              },
              {
                path: 'delivery/:deliveryId/edit/',
                name: 'FarmProfileCommoditiesContractDeliveryEdit',
                component: DeliveryAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                  deliveryId: parseInt(route.params.deliveryId, 10) || -1,
                }),
                meta: {
                  title: 'Edit delivery',
                },
              },
              {
                path: 'delivery/new/',
                name: 'FarmProfileCommoditiesContractDeliveryNew',
                component: DeliveryAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                  defaultCompletionDelivery: Boolean(route.params.defaultCompletionDelivery),
                }),
                meta: {
                  title: 'New delivery',
                },
              },
              {
                path: 'delivery/:deliveryId/',
                name: 'FarmProfileCommoditiesContractDelivery',
                component: DeliveryViewPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                  deliveryId: parseInt(route.params.deliveryId, 10) || -1,
                }),
                meta: {
                  title: 'Delivery',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'inventory/',
        name: 'FarmProfileInventory',
        component: InventoryPage,
        meta: {
          title: 'Inventory',
          permission: {
            scope: 'farm_profile',
            permission: 'read',
          },
        },
        children: [
          {
            path: 'storage/:storageId/',
            name: 'FarmProfileInventoryStorage',
            component: InventoryDetailPage,
            meta: {
              title: 'Storage',
            },
            props: (route) => ({
              storageId: parseInt(route.params.storageId, 10) || -1,
            }),
          },
        ],
      },
      {
        path: 'sales/',
        component: NewSalesPage,
        meta: {
          title: 'Sales',
          icon: {
            type: 'mdi',
            value: 'mdi-currency-usd',
          },
          permission: {
            scope: 'farm_profile',
            permission: 'read',
          },
        },
        children: [
          {
            path: '',
            name: 'FarmProfileSales',
            component: SalesContractsTab,
            meta: {
              title: 'Contracts',
            },
          },
          {
            path: 'contracts/',
            name: 'FarmProfileSalesContracts',
            component: SalesContractsTab,
            meta: {
              title: 'Contracts',
            },
          },
          {
            path: 'deliveries/',
            name: 'FarmProfileSalesDeliveries',
            component: SalesDeliveriesTab,
            meta: {
              title: 'Deliveries',
            },
          },
          {
            path: 'commodities/',
            name: 'FarmProfileSalesCommodities',
            component: SalesCommoditiesTab,
            meta: {
              title: 'Commodities',
            },
          },
          {
            path: 'contract/new/',
            name: 'FarmProfileSalesContractNew',
            component: ContractAddEditPage,
            props: (route) => ({
              mainContractId: parseInt(route.params.mainContractId, 10) || null,
              cropYear: route.params.cropYear,
              buyer: route.params.buyer,
              region: route.params.region,
              commodity: route.params.commodity,
              isContractScanned: route.params.isContractScanned,
              derivedCropYear: route.params.derivedCropYear,
            }),
            meta: {
              title: 'New contract',
            },
          },
          // TODO: Will be done in the Contract Fields Epic
          // {
          //   path: 'contract/fields/',
          //   name: 'FarmProfileSalesContractFields',
          //   component: SalesPage, // TODO: Contract Fields Component
          //   meta: {
          //     title: 'Contract fields',
          //   },
          // },
          {
            path: 'contract/:contractId/',
            name: 'FarmProfileSalesContract',
            component: ContractViewPage,
            props: (route) => ({
              contractId: parseInt(route.params.contractId, 10) || -1,
              // If we aren't on the contract route, we must be on a child route
              showContract: route.name === 'FarmProfileSalesContract',
            }),
            meta: {
              title: 'Contract',
            },
            children: [
              {
                path: 'edit/',
                name: 'FarmProfileSalesContractEdit',
                component: ContractAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                }),
                meta: {
                  title: 'Edit contract',
                },
              },
              {
                path: 'convert_to_contract/',
                name: 'FarmProfileSalesConvertToContract',
                component: ContractAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                }),
                meta: {
                  title: 'Convert to contract',
                },
              },
              {
                path: 'delivery/new/',
                name: 'FarmProfileSalesContractDeliveryNew',
                component: DeliveryAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                  defaultCompletionDelivery: Boolean(route.params.defaultCompletionDelivery),
                }),
                meta: {
                  title: 'New delivery',
                },
              },
              {
                path: 'delivery/:deliveryId/',
                name: 'FarmProfileSalesContractDelivery',
                component: DeliveryViewPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                  deliveryId: parseInt(route.params.deliveryId, 10) || -1,
                }),
                meta: {
                  title: 'Delivery',
                },
              },
              {
                path: 'delivery/:deliveryId/edit/',
                name: 'FarmProfileSalesContractDeliveryEdit',
                component: DeliveryAddEditPage,
                props: (route) => ({
                  contractId: parseInt(route.params.contractId, 10) || -1,
                  deliveryId: parseInt(route.params.deliveryId, 10) || -1,
                }),
                meta: {
                  title: 'Edit delivery',
                },
              },
            ],
          },
          {
            path: 'delivery/new/',
            name: 'FarmProfileSalesDeliveryNew',
            component: DeliveryAddEditPage,
            meta: {
              title: 'New delivery',
            },
          },
        ],
      },
      {
        path: 'reports',
        component: ReportsPage,
        meta: {
          title: 'Reports',
          icon: {
            type: 'mdi',
            value: 'mdi-chart-box',
          },
          permission: {
            scope: 'farm_profile',
            permission: 'read',
          },
        },
        children: [
          {
            path: '',
            name: 'FarmProfileReports',
            component: ReportsOverviewTab,
            meta: {
              title: 'Overview',
            },
          },
          {
            path: 'overview/',
            name: 'FarmProfileReportsOverview',
            component: ReportsOverviewTab,
            meta: {
              title: 'Overview',
            },
          },
          {
            path: 'production/',
            name: 'FarmProfileReportsProduction',
            props: true,
            component: ReportsProductionTab,
            meta: {
              title: 'Production',
            },
          },
          {
            path: 'sales/',
            name: 'FarmProfileReportsSales',
            component: ReportsSalesTab,
            props: true,
            meta: {
              title: 'Sales',
            },
          },
        ],
      },
    ],
  },
];

export default routes;
