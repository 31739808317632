import EmptyStateIcon from '@/components/icons/EmptyStateIcon.vue';
import { iconList } from '@/icons/empty-state-icons';

const emptyStateIcons = iconList.reduce((acc, current) => {
  acc[`emptyState${current.name}`] = {
    component: EmptyStateIcon,
    props: {
      name: current.name,
    },
  };
  return acc;
}, {});

export default emptyStateIcons;
