<template>
  <component
    :is="menu ? 'v-menu' : 'div'"
    :nudge-bottom="top ? -6 : 6"
    offset-y
    :bottom="!top"
    :top="top"
    :content-class="contentClass"
    left
  >
    <!-- Menu Activator -->
    <template
      v-if="menu"
      #activator="{ on, attrs }"
    >
      <v-btn
        data-qa="button-collapse-menu-parent-button"
        data-dd-action-name="Button Collapse Menu"
        icon
        v-bind="attrs"
        v-on="on"
        @click="handleMenuOpen"
      >
        <!-- Disable transition if transform is false -->
        <v-icon :class="{notransition: !transform}">
          {{ dotsHorizontal ? 'mdi-dots-horizontal' : 'mdi-dots-vertical' }}
        </v-icon>
      </v-btn>
    </template>

    <!-- Menu -->
    <template v-if="menu">
      <slot name="menu" />
      <v-list class="py-0 rounded-lg">
        <v-tooltip
          v-for="(action, index) in shownActions"
          :key="index"
          :disabled="!action.tooltip"
          bottom
        >
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item
                :data-qa="`button-collapse-menu-${action.name.toLowerCase()
                  .replace(' ', '-')}-button`"
                :data-dd-action-name="action.name"
                :disabled="action.disabled"
                @click="action.method"
              >
                <v-list-item-content>
                  <v-list-item-title class="align-center d-flex w--100">
                    <v-icon
                      v-if="showIcons && !!action.icon"
                      :color="action.color ? action.color : ''"
                      :disabled="action.disabled"
                      small
                      class="mr-2"
                    >
                      {{ action.icon }}
                    </v-icon>
                    <span
                      style="user-select: none"
                      class="text-body-1 grey--text text--darken-4"
                      :class="{
                        [`${action.color}--text`] : action.color,
                        'text--disabled': action.disabled,
                      }"
                    >
                      {{ action.name }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
          <span>{{ action.tooltip }}</span>
        </v-tooltip>
      </v-list>
    </template>

    <!-- Buttons -->
    <template v-else>
      <v-btn
        v-for="(action, index) in shownActions"
        :key="index"
        :color="action.color ? action.color : ''"
        :class="index !== 0 ? 'ml-2' : ''"
        :disabled="action.disabled"
        :data-dd-action-name="action.name"
        @click="action.method"
      >
        <v-icon
          v-if="showIcons && !!action.icon"
          left
        >
          {{ action.icon }}
        </v-icon>
        {{ action.name }}
      </v-btn>
    </template>
  </component>
</template>

<script>
import { VMenu } from 'vuetify/lib';

export default {
  name: 'ButtonCollapseMenu',

  components: {
    VMenu,
  },

  props: {
    menu: {
      type: Boolean,
      required: true,
    },

    showIcons: {
      type: Boolean,
      default: false,
    },

    actions: {
      type: Array,
      required: true,
    },

    transform: {
      type: Boolean,
      default: false,
    },

    contentClass: {
      type: String,
      default: '',
    },

    dotsHorizontal: {
      type: Boolean,
      default: false,
    },

    top: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    shownActions() {
      return this.actions.filter((action) => !action.hide);
    },
  },

  methods: {
    handleMenuOpen() {
      this.$emit('menu-open');
    },
  },
};
</script>

<style lang="scss" scoped>
.notransition {
  -webkit-transform: none !important;
  transform: none !important;
}
</style>
