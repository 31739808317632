import { postRequest } from '../helpers/request';

export async function apiReadAllYoutubeVideos(options) {
  return postRequest('/videos/api/get_all_videos/', options);
}

export async function apiReadAllVideoCategories(options) {
  return postRequest('/videos/api/get_all_video_categories/', options);
}

export async function apiToggleFavourite(options) {
  return postRequest('/videos/api/toggle_favourite/', options);
}

export async function apiMarkVideoAsWatched(options) {
  return postRequest('/videos/api/toggle_watched/', options);
}

export async function apiReadAllFavourites(options) {
  return postRequest('/videos/api/get_favourited_videos_by_user/', options);
}

export async function apiReadSingularVideo(options) {
  return postRequest('/videos/api/get_video_from_video_id/', options);
}

export async function apiHandleVideoMetric(options) {
  return postRequest('/videos/api/handle_video_metric/', options);
}
