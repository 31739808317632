import {
  apiGetRiskProfileResults,
  apiGetQuestionnaire,
  apiSubmitRiskProfileResults,
  apiGetRiskProfileProgress,
  apiUpsertRiskProfileQuestionProgress,
} from '../../api/riskProfile';

export default {
  state: () => ({
    questionnaire: {},
    riskProfileProgress: null,
    riskProfileResults: null,
    selectedAnswers: {},
    updatePromise: null,
    questionnaireLoaded: false,
  }),

  getters: {
    questionCount(state) {
      return state.questionnaire?.questions?.length || 0;
    },

    orderedQuestions(state) {
      // Return the questionnaire questions ordered by 'displayOrder', also orders each questions options/answers by 'displayOrder'
      if (state.questionnaire?.questions) {
        const sorted = state.questionnaire.questions
          .map((question) => ({
            ...question,
            options: question.options.sort(
              (a, b) => a.displayOrder - b.displayOrder,
            ),
          }))
          .sort((a, b) => a.displayOrder - b.displayOrder);
        return sorted;
      }
      return [];
    },

    enableSaveProgress(state, getters, rootState) {
      return !rootState.user.isSubscriptionGMA;
    },
  },

  mutations: {
    setQuestionnaireLoaded(state, payload) {
      state.questionnaireLoaded = payload;
    },

    setSelectedAnswers(state, payload) {
      if (!payload) {
        state.selectedAnswers = {};
        return;
      }

      const { question, selected } = payload;
      state.selectedAnswers = {
        ...state.selectedAnswers,
        [question]: selected,
      };
    },

    setQuestionnaire(state, payload) {
      if (payload) {
        state.questionnaireLoaded = true;
      }
      state.questionnaire = payload;
    },

    setRiskProfileProgress(state, payload) {
      if (!payload) {
        state.riskProfileProgress = null;
        return;
      }
      const { questionnaire_id: questionnaireId, questions: answers } = payload;
      if (state.questionnaire?.questions) {
        (answers || []).forEach((answer) => {
          if (!answer) {
            return;
          }
          const question = state.questionnaire.questions.find((q) => q.id === answer.id);
          if (!question) {
            return;
          }
          if (question.multipleSelect) {
            state.selectedAnswers[question.displayOrder] = answer.answers;
          } else {
            const [ans] = answer.answers;
            state.selectedAnswers[question.displayOrder] = ans;
          }
        });
      }
      state.riskProfileProgress = {
        questionnaireId,
        answers,
      };
    },

    setRiskProfileResults(state, payload) {
      state.riskProfileResults = payload;
    },

    addRiskProfileProgress(state, payload) {
      if (!state.riskProfileProgress) {
        state.riskProfileProgress = {
          questionnaireId: state.questionnaire.id,
          answers: [payload],
        };
        return;
      }
      let found = false;
      const answers = state.riskProfileProgress.answers.map((answer) => {
        if (answer.id === payload.id) {
          found = true;
          return payload;
        }
        return answer;
      });
      if (!found) {
        answers.push(payload);
      }

      state.riskProfileProgress = {
        ...state.riskProfileProgress,
        answers,
      };
    },

    setUpdatePromise(state, payload) {
      state.updatePromise = payload;
    },
  },

  actions: {
    async loadQuestionnaire({ commit }, payload) {
      // Payload should be a questionnaire id as a string. Calling this without a payload
      // will instead fetch the default questionnaire defined in the backend
      try {
        const { results } = await apiGetQuestionnaire({ questionnaireId: payload });
        if (results) commit('setQuestionnaire', results);
      } catch (e) {
        commit('setQuestionnaire', {});
      }
    },

    async loadRiskProfileProgress({ commit }) {
      try {
        const results = await apiGetRiskProfileProgress();
        if (results) commit('setRiskProfileProgress', results);
      } catch (e) {
        commit('setRiskProfileProgress', null);
      }
    },

    async loadRiskProfileResults({ commit }) {
      try {
        const results = await apiGetRiskProfileResults();
        if (results) commit('setRiskProfileResults', results);
      } catch (e) {
        commit('setRiskProfileResults', null);
      }
    },

    async submitRiskProfileResults({ commit, state, rootGetters }) {
      const enableSaveProgress = rootGetters['riskProfile/enableSaveProgress'];
      if (state.updatePromise) {
        try {
          await state.updatePromise; // wait for existing promises to complete
        } catch (e) {
          // ignore errors
        }
        commit('setUpdatePromise', null);
      }
      try {
        if (enableSaveProgress) {
          const { results } = await apiSubmitRiskProfileResults();
          if (results) commit('setRiskProfileResults', results);
        } else {
          const { questionnaireId, answers } = state.riskProfileProgress;
          const { results } = await apiSubmitRiskProfileResults({ questionnaireId, answers });
          if (results) commit('setRiskProfileResults', results);
        }
      } catch (e) {
        // ignore if error happens
      }
    },

    async updateRiskProfileProgress({ commit, rootGetters }, payload) {
      const enableSaveProgress = rootGetters['riskProfile/enableSaveProgress'];
      // Progress should be saved to the DB only if enableSaveProgress is allowed, or else commit to local state only

      commit('addRiskProfileProgress', payload);
      if (enableSaveProgress) {
        // Payload should be a question id and a set of response id's to correspond to the backend
        const promise = apiUpsertRiskProfileQuestionProgress(payload);
        commit('setUpdatePromise', promise);
        try {
          await promise;
        } catch (e) {
          // ignore result if an error happens
        }
        commit('setUpdatePromise', null);
      }
    },
  },

  namespaced: true,
};
