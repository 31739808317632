<template>
  <v-dialog
    :value="organizationInviteData.dialogOpen"
    @click:outside="cancelOrganizationInviteDialog"
  >
    <confirm-card
      :heading="organizationInviteData.heading"
      :text="organizationInviteData.text"
      :info="organizationInviteData.isMember"
      @cancel="cancelOrganizationInviteDialog"
      @confirm="confirmOrganizationInviteDialog"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('app', ['organizationInviteData']),
  },

  mounted() {
    // registering property on window object to enable callling it via Flutter
    //  this component needs to be mounted before any NotificationItem component
    window.openOrganizationInviteDialog = this.openOrganizationInviteDialog;
    window.markNotificationRead = async (notifID) => {
      await this.$store.dispatch('app/readNotification', {
        id: notifID,
      });
    };
  },

  methods: {
    ...mapMutations('app', [
      'cancelOrganizationInviteDialog',
      'confirmOrganizationInviteDialog',
      'openOrganizationInviteDialog',
    ]),
  },
};
</script>
