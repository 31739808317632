import Vue from 'vue';
import { nlgRequest } from '@/helpers/request';

export default {
  state: () => ({
    selectedReportName: '',
    selectedDataSet: '',
    selectedCrop: '',
    selectedYear: '',
    // The cadence value (1, 2,..), formatted based on the cadence unit ('daily', 'weekly', 'monthly', 'quarterly')
    // ex. '2022-01-01', 'Week 1', 'January', 'Q1',
    selectedCadence: '',
    generatedSuggestionsCache: {},
    allDataCache: {},
    loading: false,
    failed: false,
    optionsTree: null,
  }),
  getters: {
    key(state) {
      if (state.selectedReportName && state.selectedDataSet && state.selectedCrop && state.selectedYear && state.selectedCadence) {
        const key = `${state.selectedReportName}:${state.selectedDataSet}:${state.selectedCrop}:${state.selectedYear}:${state.selectedCadence}`;
        return key;
      }
      return null;
    },
    suggestion(state, getters) {
      if (getters.key) {
        return state.generatedSuggestionsCache[getters.key];
      }
      return null;
    },
    reportNames(state) {
      if (state.optionsTree) {
        return Object.keys(state.optionsTree);
      }
      return [];
    },
    dataSets(state) {
      if (state.selectedReportName) {
        return Object.keys(state.optionsTree[state.selectedReportName]);
      }
      return [];
    },
    crops(state) {
      if (state.selectedReportName && state.selectedDataSet) {
        return Object.keys(state.optionsTree[state.selectedReportName][state.selectedDataSet]);
      }
      return [];
    },
    years(state) {
      if (state.selectedReportName && state.selectedDataSet && state.selectedCrop) {
        return Object.keys(state.optionsTree[state.selectedReportName][state.selectedDataSet][state.selectedCrop]);
      }
      return [];
    },
    cadence(state) {
      if (state.selectedReportName && state.selectedDataSet && state.selectedCrop && state.selectedYear) {
        const cadenceUnit = Object.keys(state.optionsTree[state.selectedReportName][state.selectedDataSet][state.selectedCrop][state.selectedYear])[0];
        const cadenceValues = state.optionsTree[state.selectedReportName][state.selectedDataSet][state.selectedCrop][state.selectedYear][cadenceUnit];
        return {
          cadenceUnit: cadenceUnit.toLowerCase(),
          cadenceValues,
        };
      }
      return {
        cadenceUnit: '',
        cadenceValues: [],
      };
    },
    /*
    Returns the selectedCadence as a Number, as expected by the frequency_value field in the nlg db
    ex:
      'Q3' -> 3
      'May' -> 5
      'Week 8' -> 8
      '2022-04-18' -> 49
     */
    selectedCadenceValue(state, getters) {
      if (!state.selectedCadence) return null;
      const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      if (getters.cadence.cadenceUnit === 'monthly') {
        const monthNumber = months.indexOf(state.selectedCadence.toLowerCase()) + 1;
        return monthNumber;
      }
      if (getters.cadence.cadenceUnit === 'weekly') {
        const week = state.selectedCadence.split(' ')[1];
        return Number(week);
      }
      if (getters.cadence.cadenceUnit === 'quarterly') {
        const quarter = state.selectedCadence[1];
        return Number(quarter);
      }
      if (getters.cadence.cadenceUnit === 'daily') {
        const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        const date = new Date(`${state.selectedCadence} ${timezone}`);
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
      }
      if (getters.cadence.cadenceUnit === 'yearly') {
        const year = state.selectedYear;
        return Number(year);
      }
      return null;
    },
  },
  mutations: {
    updateLoading(state, payload) {
      state.loading = payload;
    },
    updateFailed(state, payload) {
      state.failed = payload;
    },
    updateSelectedReportName(state, payload) {
      state.selectedReportName = payload;
    },
    updateSelectedDataSet(state, payload) {
      state.selectedDataSet = payload;
    },
    updateSelectedCrop(state, payload) {
      state.selectedCrop = payload;
    },
    updateSelectedYear(state, payload) {
      state.selectedYear = payload;
      state.selectedCadence = '';
    },
    updateSelectedCadence(state, payload) {
      state.selectedCadence = payload;
    },
    updateGeneratedSuggestionsCache(state, payload) {
      const { key, generatedSuggestion } = payload;
      Vue.set(state.generatedSuggestionsCache, key, generatedSuggestion);
    },
    updateAllDataCache(state, payload) {
      const { key, allData } = payload;
      Vue.set(state.allDataCache, key, allData);
    },
    updateOptionsTree(state, payload) {
      state.optionsTree = payload;
    },
    clear(state) {
      state.selectedReportName = '';
      state.selectedDataSet = '';
      state.selectedCrop = '';
      state.selectedYear = '';
      state.selectedCadence = '';
    },
  },
  actions: {
    async generateSuggestion({ commit, state, getters }) {
      try {
        commit('updateFailed', false);
        const { key } = getters;
        if (key in state.generatedSuggestionsCache) {
          return state.generatedSuggestionsCache[key];
        }
        commit('updateLoading', true);
        const { summary: generatedSuggestion } = await nlgRequest('/api/summary', {
          body: {
            reportName: state.selectedReportName,
            dataset: state.selectedDataSet,
            year: state.selectedYear,
            crop: state.selectedCrop,
            frequency: {
              value: getters.selectedCadenceValue,
              unit: getters.cadence.cadenceUnit,
            },
          },
        });
        commit('updateGeneratedSuggestionsCache', { key, generatedSuggestion });
        return generatedSuggestion;
      } catch (e) {
        commit('updateFailed', true);
        this._vm.$snackbar.error(e);
        return null;
      } finally {
        commit('updateLoading', false);
      }
    },
    async getAllData({ commit, state, getters }) {
      try {
        const key = `${state.selectedReportName}:${state.selectedDataSet}:${state.selectedCrop}:${state.selectedYear}`;
        if (key in state.allDataCache) {
          return state.allDataCache[key];
        }
        const params = new URLSearchParams({
          reportName: state.selectedReportName,
          dataset: state.selectedDataSet,
          year: state.selectedYear,
          crop: state.selectedCrop,
        });
        const url = `/api/data?${params.toString()}`;
        const allData = await nlgRequest(url, {
          method: 'GET',
        });
        commit('updateAllDataCache', { key, allData });
        return allData;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getOptionsTree({ commit, state }) {
      try {
        if (state.optionsTree) {
          return state.optionsTree;
        }
        const optionsTree = await nlgRequest('/api/options', {
          method: 'GET',
        });
        commit('updateOptionsTree', optionsTree);
        return optionsTree;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },
  },

  namespaced: true,
};
