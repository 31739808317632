/* eslint-disable import/prefer-default-export */
import { postRequest } from '../helpers/request';

export async function apiReadBidsMapSearches() {
  const { results } = await postRequest('/bids/map/searches/');
  return results;
}

export async function apiDeleteBidsMapSearch(options) {
  await postRequest('/bids/map/delete_search/', options);
}

export async function apiCreateSearch(options) {
  return postRequest('/bids/map/create_search/', options);
}

export async function apiPerformSearch(options) {
  return postRequest('/bids/map/perform_search/', options);
}

export async function apiGetLastSearch() {
  return postRequest('/bids/map/get_last_search/');
}

export async function apiGetDefaultFarmLocation() {
  return postRequest('/farm_profile/api/get_default_location/');
}

export async function apiGetSeasonalityData(options) {
  return postRequest('/bids/map/get_seasonality_data/', options);
}

export async function apiGetPriceAlerts() {
  const { results } = await postRequest('/bids/map/get_price_alerts/');
  return results;
}

export async function apiCreatePriceAlert(options) {
  return postRequest('/bids/map/create_price_alert/', options);
}

export async function apiDeletePriceAlert(options) {
  return postRequest('/bids/map/delete_price_alert/', options);
}

export async function apiUpdatePriceAlert(options) {
  return postRequest('/bids/map/update_price_alert/', options);
}

export async function apiGetMultiplePriceData(options) {
  return postRequest('/bids/map/get_commodity_prices_at_buyer_region/', options);
}

export async function apiUpdateOrCreatePriceMapperProgress(options) {
  return postRequest('/bids/map/update_or_create_progress/', options);
}
