<template>
  <v-menu
    transition="slide-y-transition"
    :close-on-content-click="false"
    :min-width="minWidth"
    max-width="400px"
    nudge-top="-10"
    offset-y
    bottom
    left
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :block="block"
        :color="color"
        :height="buttonHeight"
        :text="text"
        :tile="tile"
        :class="widthClass"
        x-large
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          :value="unreadNotifications > 0"
          :offset-x="badgeOffsetX"
          :offset-y="badgeOffsetY"
          :content="formattedNotificationCount"
          color="primary"
          overlap
        >
          <v-icon
            :class="[iconClass]"
          >
            mdi-bell
          </v-icon>
        </v-badge>
        <span
          v-if="fullWidth"
          class="reset--text"
        >
          Notifications
        </span>
      </v-btn>
    </template>

    <v-sheet
      class="d-flex flex-column"
    >
      <v-list>
        <v-subheader class="body-1 primary-grey--text font-weight-bold">
          <span>Notifications</span>
          <v-spacer />
          <v-btn
            icon
            href="/account/notification_settings/"
          >
            <v-icon>
              mdi-cog
            </v-icon>
          </v-btn>
        </v-subheader>
      </v-list>
      <v-divider />
      <v-list
        max-height="320"
        class="py-0 overflow-y--scroll"
      >
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item-two-line"
          loading
        />
        <v-list-item
          v-if="noResults"
          class="justify-center"
        >
          No Notifications
        </v-list-item>
        <notification-item
          v-for="(item) in notifications"
          :key="item.id"
          :notification="item"
          @notification-dismissed="getMoreNotifications"
        />
      </v-list>
      <v-divider />
      <div
        class="pa-4 text-center"
      >
        <a href="/notifications/">
          View all notifications
        </a>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NotificationItem from './NotificationItem.vue';

export default {
  components: {
    NotificationItem,
  },

  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState('app', ['unreadNotifications']),

    noResults() {
      return this.isLoading === false && this.count === 0;
    },

    minWidth() {
      return this.$vuetify.breakpoint.xs ? '0' : '400px';
    },

    block() {
      return this.fullWidth;
    },

    buttonHeight() {
      return this.fullWidth ? 56 : undefined;
    },

    color() {
      return undefined;
    },

    elevation() {
      return this.fullWidth ? 0 : 2;
    },

    fab() {
      return !this.fullWidth;
    },

    text() {
      return this.fullWidth;
    },

    tile() {
      return this.fullWidth;
    },

    xSmall() {
      return !this.fullWidth;
    },

    xLarge() {
      return this.fullWidth;
    },

    widthClass() {
      if (this.fullWidth) {
        return 'full-width px-3';
      }
      return 'px-2';
    },

    iconClass() {
      return this.fullWidth ? 'v-list-item__icon ml-0' : '';
    },

    formattedNotificationCount() {
      return this.unreadNotifications <= 99 ? String(this.unreadNotifications) : '99+';
    },

    badgeOffsetX() {
      if (!this.fullWidth) return 16;
      return 32;
    },

    badgeOffsetY() {
      if (!this.fullWidth) return 22;
      return 34;
    },

    ...mapGetters('app', {
      notifications: 'getRecentNotifications',
      count: 'getRecentNotificationCount',
    }),
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('app/loadRecentNotifications')
      .catch(() => {
        this.$snackbar.error('Error loading notifications');
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    getMoreNotifications() {
      if (this.count <= 1) {
        this.$store.dispatch('app/loadRecentNotifications')
          .catch(() => {
            this.$snackbar.error('Error loading notifications');
          });
      }
    },
  },
};
</script>
