<template>
  <v-card
    v-if="!isApp"
    id="mobile-app-link-card"
    class="mb-6"
  >
    <v-card-title>Get the app</v-card-title>
    <MobileAppLink class="pl-3" />
  </v-card>
</template>

<script>
import MobileAppLink from '@/components/dashboard/MobileAppLink.vue';

export default {
  components: {
    MobileAppLink,
  },

  computed: {
    isApp() {
      return this.$store.state.app.isApp;
    },
  },
};
</script>
