import {
  convertFromSameClass,
  convertPrice,
  convertProduction,
  convertYield,
} from '../../helpers/units';
import { postRequest } from '../../helpers/request';

export default {
  state: () => ({
    commodities: {},
    units: {},
    unitConversions: [],
    cropYears: [],
    cropYearsNamed: {},
    buyerRegions: [],
    terms: [],
    kgUnit: null,
    provinces: [],
  }),
  getters: {
    /**
     * This getter returns a function that converts and returns the value to a specific unit.
     *
     * @param {object} state - The state of the store.
     * @param {object} getters - The getters of the store.
     * @returns a function that converts and returns the value to a specific unit.
     */
    convert(state, getters) {
      /**
       * This function returns the converted value based on the fromUnit, toUnit, and optional commodity.
       *
       * @param {number|string} value the original value to convert from
       * @param {number} fromUnit the unit to convert from
       * @param {number} toUnit the unit to convert to
       * @param {number} commodity only required for Price, Yield, Mass, or Volume conversions since the density of the commodity is needed.
       * @returns the converted value
       */
      const convertFunction = (value, fromUnit, toUnit, commodity = null) => {
        // get the is associated objects when ids are provided
        const from = (typeof fromUnit === 'number') ? state.units[fromUnit] : fromUnit;
        const to = (typeof toUnit === 'number') ? state.units[toUnit] : toUnit;
        const commod = (typeof commodity === 'number') ? state.commodities[commodity] : commodity;

        if (from.id === to.id) return Number(value);

        const fromClassification = from.unit_classification.classification;
        const toClassification = to.unit_classification.classification;

        if (fromClassification === toClassification) {
          if (fromClassification === 'Price') {
            if (!commod) throw Error('Commodity must be provided to convert a Price unit');
            const density = getters.commodityDensity(commod.id);
            return convertPrice(value, from, to, density, state.unitConversions);
          } if (fromClassification === 'Yield') {
            if (!commod) throw Error('Commodity must be provided to convert Yield unit');
            const density = getters.commodityDensity(commod.id);
            return convertYield(value, from, to, density, state.unitConversions);
          }
          return convertFromSameClass(value, from, to, state.unitConversions);
        }

        // Production units conversion  (Mass => Volume or Volume => Mass)
        if ((fromClassification === 'Mass' && toClassification === 'Volume') || (fromClassification === 'Volume' && toClassification === 'Mass')) {
          if (!commod) throw Error('Commodity must be provided to convert a Volume unit to Mass or Mass to Volume');
          const density = getters.commodityDensity(commod.id);
          return convertProduction(value, from, to, density, state.unitConversions);
        }
        throw Error(`Convert function error from unit ${from.name} to unit ${to.name}`);
      };
      return convertFunction;
    },
    /**
     * This getter returns a function that returns the density value and density unit of a given commodity by id.
     *
     * @param commodityID the id corresponding to the commodity
     * @returns density object {value, unit}
     */
    commodityDensity(state) {
      return (commodityID) => {
        const commodity = state.commodities[commodityID];
        const densityValue = commodity.density;
        const densityUnit = state.units[commodity.density_unit_id];
        return {
          value: densityValue,
          unit: densityUnit,
        };
      };
    },
    unitAbbreviation: (state) => (unitId) => state.units[unitId]?.abbreviation,
    yieldUnits: (state) => {
      const units = Object.values(state.units).filter((unit) => unit.unit_classification?.classification === 'Yield');
      units.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
      return units;
    },
    unitByAbbreviation: (state) => (abbreviation) => Object.values(state.units).find((u) => u.abbreviation === abbreviation),
    defaultProductionUnit: (state, getters) => getters.unitByAbbreviation('bu A'),
    /**
     * @param {string} givenDate the date whose corresponding crop year you want to get
     * @returns {Object} a CropYear object
     */
    cropYearByDate(state) {
      return (date) => state.cropYears.find((c) => c.start <= date && date <= c.end);
    },
    /**
     * @returns {Object} the current CropYear
     */
    currentCropYear(state, getters) {
      return getters.cropYearByDate(new Date().toISOString());
    },
    /**
     * @returns {Object} the next CropYear
     */
    nextCropYear(state, getters) {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return getters.cropYearByDate(date.toISOString());
    },
    findCropYearByLongName(state) {
      return (longName) => Object.values(state.cropYears).find((year) => year.long_name === longName);
    },
    /**
    * Given a crop year gives the crop year that precedes it.
    * Allowing the user to traverse backwards through cropYears
    *
    * @param {cropYear} CropYear object
    * @returns the previous CropYear object
    */
    getPreviousCropYear: (state, getters) => (cropYear) => {
      // Long name only contains one '-' thus splitting the string into two elements
      const years = cropYear.long_name.split('-').map((year) => parseInt(year, 10) - 1);
      const longName = `${years[0]}-${years[1]}`;

      return getters.findCropYearByLongName(longName);
    },

    /**
    * Given a crop year gives the crop year that succeeds it.
    * Allowing the user to traverse forwards through cropYears
    *
    * @param {cropYear} CropYear object
    * @returns the next CropYear object
    */
    getNextCropYear: (state, getters) => (cropYear) => {
      // Long name only contains one '-' thus splitting the string into two elements
      const years = cropYear.long_name.split('-').map((year) => parseInt(year, 10) + 1);
      const longName = `${years[0]}-${years[1]}`;

      return getters.findCropYearByLongName(longName);
    },

    cropYearById: (state) => (id) => state.cropYears.find((year) => year.id === id),

    parentCommodityList: (state) => (commodityId) => {
      const parents = [];
      let curr = state.commodities[commodityId];
      while (curr != null && curr.parent_id != null) {
        parents.push(curr.parent_id);
        curr = state.commodities[curr.parent_id];
      }
      return parents;
    },

    /**
     * Get a list of all the descendant commodities of the given
     * commodity (with id commodityId)
     * @param commodityId the commodity to find descendants of
     * @param objects whether or not to return the list of
     *      descendants as objects or integer ids.
     */
    childCommodityList: (state, getters) => (commodityId, objects = false) => {
      const children = [];
      Object.values(state.commodities)
        .filter((commodity) => commodity.parent_id === commodityId)
        .forEach((commodity) => { children.push((objects ? commodity : commodity.id), ...getters.childCommodityList(commodity.id, objects)); });
      return children;
    },
    commodityByLongName: (state) => (commodityName) => Object.values(state.commodities)
      .find((comm) => comm.name.toLowerCase() === commodityName),
  },
  mutations: {
    setCommodities(state, commodities) {
      state.commodities = commodities;
    },
    setUnits(state, units) {
      state.units = units;
      Object.values(units).some((unit) => {
        if (unit.abbreviation === 'kg') {
          state.kgUnit = unit;
          return true;
        }
        return false;
      });
    },
    setCropYears(state, cropYears) {
      state.cropYears = cropYears;
    },
    setCropYearsNamed(state, { last, current, next }) {
      state.cropYearsNamed.last = last;
      state.cropYearsNamed.current = current;
      state.cropYearsNamed.next = next;
    },
    setUnitConversions(state, conversionTable) {
      state.unitConversions = conversionTable;
    },
    setBuyerRegions(state, buyerRegions) {
      state.buyerRegions = buyerRegions;
    },
    setTerms(state, terms) {
      state.terms = terms;
    },
    setProvinces(state, provinces) {
      state.provinces = provinces;
    },
  },
  actions: {
    async fetchAllCommodities({ commit }) {
      const commodities = await postRequest(
        '/farm_profile/api/get_all_commodities/',
      );
      commit('setCommodities', commodities);
    },
    async fetchAllUnits({ commit }) {
      const units = await postRequest(
        '/farm_profile/api/get_all_units/',
      );
      commit('setUnits', units);
    },
    async fetchAllCropYears({ commit, dispatch }) {
      const cropYears = await postRequest(
        '/farm_profile/api/get_crop_years/',
      );
      commit('setCropYears', cropYears.map((cropYear) => ({
        ...cropYear,
        start: cropYear.start.slice(0, 10),
        end: cropYear.end.slice(0, 10),
      })));
      dispatch('initOrUpdateCropYearsNamed');
    },
    async fetchUnitConversions({ commit }) {
      const unitConversions = await postRequest(
        '/farm_profile/api/get_unit_conversions/',
      );
      commit('setUnitConversions', unitConversions);
    },
    async fetchBuyerRegions({ commit }) {
      const result = await API.getBuyerRegions();
      commit('setBuyerRegions', result.buyer_regions);
    },
    async fetchTerms({ commit }) {
      const result = await API.getTerms();
      const terms = result.terms.map((term) => ({
        id: term.id,
        details: term.details,
        term_type: term.term_type.term_type,
        contract_types: term.term_type.contract_types.map((x) => x.contract_type),
      }));
      commit('setTerms', terms);
    },
    async fetchCommoditiesAndProvinces({ commit }) {
      const result = await API.readCommoditiesAndProvinces();
      commit('setCommodities', result.commodities);
      commit('setProvinces', result.provinces);
    },
    initOrUpdateCropYearsNamed({ getters, commit }) {
      const currentYear = getters.currentCropYear;
      const lastYear = currentYear ? getters.getPreviousCropYear(currentYear) : null;
      const nextYear = currentYear ? getters.getNextCropYear(currentYear) : null;
      commit('setCropYearsNamed', {
        last: lastYear ? { name: 'Last Year', id: lastYear.id } : null,
        current: currentYear ? { name: 'Current Year', id: currentYear.id } : null,
        next: nextYear ? { name: 'Next Year', id: nextYear.id } : null,
      });
    },
    openFarmLocationDialog({ commit }, val) {
      commit('setFarmLocationDialogFarm', 0);
      commit('setFarmLocationDialogDelete', false);
      commit('setFarmLocationDialogOnDone', null);
      commit('setFarmLocationDialogOpen', true);

      if (typeof (val) === 'object') {
        commit('setFarmLocationDialogFarm', val.id);
        commit('setFarmLocationDialogDelete', val.delete);
        commit('setFarmLocationDialogOnDone', val.onDone);
      } else {
        commit('setFarmLocationDialogFarm', val);
      }
    },
  },
  namespaced: true,
};
