<template>
  <component
    :is="wrapperComponent"
    :class="$isMobile ? 'pa-2 px-4' : 'pa-2'"
  >
    <a
      :href="destinationUrl"
      target="_blank"
    >
      <div class="text-body-2 secondary--text font-weight-bold pb-2">
        {{ title }}
      </div>

      <div
        class="text-caption"
        :class="{'d-flex justify-space-between' : $isDesktop}"
      >
        <p class="primary-grey--text my-1">
          <span v-if="showProfilePic">
            <v-avatar
              size="25"
            >
              <v-img
                :src="profilePicture"
                alt="Writer Img"
              />
            </v-avatar>
            <v-icon
              color="grey darken-1"
            >
              mdi-circle-small
            </v-icon>
          </span>
          <template v-for="(writer, idx) in writers">
            <span
              :key="writer"
              class="font-weight-bold"
            >By {{ writer }}</span>
            <span
              v-if="idx != writers.length - 1"
              :key="idx"
              class="font-weight-bold"
            >, </span>
          </template>
        </p>
        <p class="grey--text text--darken-1 my-1">
          {{ dateFormatShortMonthDateYear(date) }}
        </p>
      </div>

      <p class="text-body-1 font-weight-medium mt-1 mb-6 primary-grey--text">
        {{ description }}
      </p>
    </a>
    <div class="d-flex justify-space-between">
      <div>
        <v-chip
          v-for="(chip, index) in chips.slice(0,numChipsShown)"
          :key="index"
          class="mr-2"
          color="grey lighten-2"
          text-color="grey darken-4"
        >
          {{ chip }}
        </v-chip>
        <v-tooltip
          v-if="$isDesktop"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-chip
                v-if="chips.length > numChipsShown"
                color="grey lighten-2"
                text-color="grey darken-4"
              >
                +{{ chips.length - numChipsShown }}
              </v-chip>
            </span>
          </template>
          {{ chips.slice(numChipsShown).join(', ') }}
        </v-tooltip>

        <v-menu
          v-if="!$isDesktop"
          v-model="chipMenu"
          offset-y
        >
          <template
            v-slot:activator="{ on, attrs }"
          >
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-chip
                v-if="chips.length > numChipsShown"
                color="grey lighten-2"
                text-color="grey darken-4"
              >
                +{{ chips.length - numChipsShown }}
              </v-chip>
            </span>
          </template>
          <v-list class="pt-2">
            <div
              v-for="chip in chips.slice(numChipsShown)"
              :key="chip"
              class="px-2 pb-2 text-left"
            >
              <v-chip
                color="grey lighten-2"
                text-color="grey darken-4"
              >
                {{ chip }}
              </v-chip>
            </div>
          </v-list>
        </v-menu>
        <!-- Empty spacer -->
        <v-spacer
          v-if="!chips.length"
          class="py-4"
        />
      </div>
    </div>
  </component>
</template>

<script>
import { dateFormatShortMonthDateYear } from '@/helpers/formatting';

export default {
  name: 'RecommendationCard',
  props: {
    recommendation: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    await this.buildDataStructure();
  },

  data() {
    return {
      chipMenu: false,
      title: '',
      writers: [],
      date: '',
      description: '',
      chips: [],
      provider: '',
      profilePicture: '',
    };
  },

  computed: {
    showProfilePic() {
      return this.title !== 'Ag News Roundup' && (this.provider === 'FarmLink' || this.provider === 'DePutter');
    },

    wrapperComponent() {
      return this.$isDesktop ? 'div' : 'v-card';
    },

    numChipsShown() {
      return 3 - +this.$isMobile;
    },

    source() {
      return this.recommendation.source;
    },

    destinationUrl() {
      const isGrainFoxSource = this.source === 'grainfox';
      if (isGrainFoxSource) {
        return `/dashboard/resource_hub/${this.recommendation.slug}`;
      }
      return this.getArticleLink();
    },
  },

  methods: {
    handleScroll() {
      this.chipMenu = false;
    },
    buildDataStructure() {
      switch (this.source) {
        case 'grainfox':
          this.title = this.recommendation.name;
          this.writers = this.recommendation.writers;
          this.date = this.recommendation.date_published;
          this.description = this.recommendation.tagline;
          this.chips = this.recommendation.topics;
          this.provider = this.recommendation.provider;
          this.profilePicture = this.recommendation['profile_picture'];
          break;
        case 'realag':
          this.title = `${`${this.recommendation.title.slice(0, 60)}...`}`;
          this.writers = [this.recommendation.providerVerbose];
          this.date = this.recommendation.date;
          this.description = `${this.recommendation.subTitle.slice(0, 60)}...`;
          this.chips = [this.recommendation.categories];
          this.provider = this.recommendation.provider;
          this.profilePicture = this.recommendation['profile_picture'];
          break;
        default:
          break;
      }
    },
    getArticleLink() {
      const providerString = this.recommendation.provider;
      return `/news/${providerString}/${this.recommendation.article_id}?newTab=true`;
    },
    dateFormatShortMonthDateYear,
  },
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
