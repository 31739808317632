const InsightsPage = () => import('@/pages/powerbi_insights/InsightsPage.vue');
const InsightsOverview = () => import('@/pages/powerbi_insights/InsightsOverview.vue');

const routes = [
  {
    path: '/insights/report/:slug/',
    meta: {
      title: 'Market Insights',
      permission: {
        scope: 'powerbi_insights',
        permission: 'read',
      },
    },
    component: InsightsPage,
  },
  {
    path: '/insights/overview/',
    meta: {
      title: 'Market Insights',
      permission: {
        scope: 'powerbi_insights',
        permission: 'read',
      },
    },
    component: InsightsOverview,
  },
];

export default routes;
