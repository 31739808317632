<template>
  <div>
    <div
      v-if="isLoading"
      class="grey--text text-center py-16"
    >
      Loading...
    </div>
    <new-feature-alert-text
      :feature-name="featureName"
      :feature-subtitle="featureSubtitle"
      :feature-description="featureDescription"
      :feature-image="featureImage"
      :feature-link="featureLink"
      :show-video-button="showVideoButton"
      :go-to-feature-button-text="goToFeatureButtonText"
      @feature="showNewFeature"
      @video="showVideoDialog"
    />
    <new-feature-video-dialog
      v-if="showVideoButton"
      v-model="isNewFeatureVideoDialogOpen"
      :feature-name="featureName"
      :feature-video="featureVideo"
      :feature-link="featureLink"
      @cancel="isNewFeatureVideoDialogOpen = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NewFeatureAlertText from './NewFeatureAlertText.vue';
import NewFeatureVideoDialog from './NewFeatureVideoDialog.vue';
import { BOOK_A_MEETING_FEATURE_FLAG } from '../../helpers/subscription';

export default {
  name: 'NewFeatureAlert',

  components: {
    NewFeatureAlertText,
    NewFeatureVideoDialog,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isNewFeatureVideoDialogOpen: false,
    };
  },

  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapState('dashboard', ['newFeature']),

    goToFeatureButtonText() {
      if (this.newFeature.featureButtonText) {
        return this.newFeature.featureButtonText;
      }
      return (this.newFeature.featureFlag === BOOK_A_MEETING_FEATURE_FLAG) ? 'Choose a time!' : 'Explore Tool';
    },

    featureName() {
      return this.newFeature.featureName || '';
    },

    featureSubtitle() {
      return this.newFeature.featureSubtitle || '';
    },

    featureDescription() {
      return this.newFeature.featureDescription || '';
    },

    featureImage() {
      return this.newFeature.featureImage || '';
    },

    featureLink() {
      return this.newFeature.featureLink || '';
    },

    featureVideo() {
      return this.newFeature.featureVideo || '';
    },

    showVideoButton() {
      return !!this.newFeature.featureVideo;
    },
  },

  methods: {
    ...mapActions('dashboard', ['setNewFeatureViewedStatus']),

    showNewFeature() {
      // Marks the feature as viewed when 'explored tools' button is clicked
      this.setNewFeatureViewedStatus(this.newFeature.id);
    },

    showVideoDialog() {
      this.isNewFeatureVideoDialogOpen = true;
    },
  },
};
</script>
