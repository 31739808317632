import { convert } from 'html-to-text';
import html2pdf from 'html2pdf.js';
import { postRequest } from './request';

/**
 * Download a URL as a file
 * @param {string} downloadUrl The url to download
 * @param {string} filename The filename to save the file as
 */
function downloadFile(downloadUrl, filename) {
  const a = document.createElement('a');
  a.setAttribute('href', downloadUrl);
  a.setAttribute('download', filename);
  a.style.visibility = 'hidden';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Save a HTML element as a PDF
 * @param {Document} dom The HTML element to save
 * @param {string} filename The filename to save the file as
 */
export async function saveAsPDF(dom, filename) {
  await html2pdf().set({
    filename,
  }).from(dom.documentElement).save(filename);
}

/**
 * Save a HTML element as a TXT file
 * @param {Document} dom The HTML element to save
 * @param {string} filename The filename to save the file as
 */
export async function saveAsTXT(dom, filename) {
  const text = convert(dom.documentElement.outerHTML, {
    wordwrap: 120,
  });
  const downloadUrl = URL.createObjectURL(new Blob([text], { type: 'text/plain' }));
  downloadFile(downloadUrl, filename);
}

function getMime(url) {
  // Remove query params from url
  const fixedUrl = url.split('?')[0];
  const ext = fixedUrl.split('.').pop();
  switch (ext) {
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'svg':
      return 'image/svg+xml';
    default:
      return 'image/png';
  }
}

/**
 * Convert all URL-based images to data URLs for use in DOCX conversion
 * @param {Document} dom The HTML to convert
 * @returns {function} A function to undo the conversion
 */
function convertImagesToDataUrls(dom) {
  const images = dom.querySelectorAll('img');
  const restore = [];
  images.forEach((element) => {
    const oldUrl = element.getAttribute('src');

    if (oldUrl.startsWith('data:')) {
      // Already a data URL, no need to convert
      return;
    }
    const mime = getMime(oldUrl);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // preparing canvas for drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = element.naturalWidth;
    canvas.height = element.naturalHeight;

    ctx.drawImage(element, 0, 0);

    // by default toDataURL() produces png image, but you can also export to jpeg
    // checkout function's documentation for more details

    try {
      const dataUrl = canvas.toDataURL(mime);
      element.setAttribute('src', dataUrl);
      restore.push(() => {
        // Function to bring DOM back to original state
        element.setAttribute('src', oldUrl);
      });
    } catch (e) {
      // Ignore errors, embed image URL instead.
    }
    canvas.remove();
  });

  return () => {
    // Undo the conversion
    restore.forEach((fn) => fn());
  };
}

/**
 * Save a dom element as a DOCX file
 * @param {Document} dom The HTML element to save
 * @param {string} filename The filename to save the file as
 */
export async function saveAsDOCX(dom, filename) {
  const undo = convertImagesToDataUrls(dom);
  try {
    // This is mostly cloned from postRequest, except it doesn't convert JSON and instead reads a blob
    const blob = await postRequest('/library/api/convert_html_to_docx/', {
      html: dom.documentElement.outerHTML,
    }, {
      responseType: 'blob',
    });
    const downloadUrl = URL.createObjectURL(blob);
    downloadFile(downloadUrl, filename);
  } finally {
    undo();
  }
}
