import {
  apiGetFeaturePopup,
  apiGetFeaturePopups,
  apiGetManagingContentFilters,
  apiDeleteFeaturePopup,
  apiUpdateFeaturePopup,
} from '@/api/marketingModule';
import { negateTimezoneOffset } from '@/helpers/datetime';

export default {
  namespaced: true,

  state: () => ({
    filters: {
      creator: [],
      contentType: [],
      approvalStatus: [],
      contentStatus: [],
    },
    selectedFilters: {
      creator: [],
      contentType: [],
      approvalStatus: [],
      contentStatus: [],
    },
    newReleaseBannerFields: {
      creator: null,
      reviewer: null,
      scheduledDate: null,
      archivedDate: null,
      title: 'News & Events',
      subtitle: null,
      body: null,
      imagePath: null,
      image: null,
      featureName: null,
      featureButtonText: null,
      exploreToolPath: null,
      videoTutorialPath: null,
      contentStatus: null,
      isProducer: false,
      isCommercial: false,
      persistent: false,
    },
    selectedPreviewPaneType: 'desktop', // 'mobile' | 'desktop' | 'tablet'
    selectedPopup: null, // id to indicate which existing popup we are editing/viewing
    slideOutOpen: false,
    previewType: null, // 'edit' | 'view' | null for table view
    featurePopups: [],
    totalItems: 0,
    tableLoading: false,
  }),

  mutations: {
    setFilters: (state, payload) => {
      state.filters.creator = payload.creators;
      state.filters.contentType = payload.content_types;
      state.filters.approvalStatus = payload.approval_statuses;
      state.filters.contentStatus = payload.content_statuses;
    },

    setSelectedFilters(state, payload) {
      state.selectedFilters.creator = payload.creators;
      state.selectedFilters.contentType = payload.content_types;
      state.selectedFilters.approvalStatus = payload.approval_statuses;
      state.selectedFilters.contentStatus = payload.content_statuses;
    },

    setNewReleaseBannerFields(state, payload) {
      state.newReleaseBannerFields.creator = payload.creator?.id;
      state.newReleaseBannerFields.reviewer = payload.reviewer?.id;
      state.newReleaseBannerFields.scheduledDate = payload.date_scheduled;
      state.newReleaseBannerFields.archivedDate = payload.date_archived;
      state.newReleaseBannerFields.title = payload.feature_title;
      state.newReleaseBannerFields.subtitle = payload.feature_subtitle;
      state.newReleaseBannerFields.body = payload.feature_description;
      state.newReleaseBannerFields.imagePath = payload.feature_image;
      state.newReleaseBannerFields.featureName = payload.feature_name;
      state.newReleaseBannerFields.featureButtonText = payload.feature_button_text;
      state.newReleaseBannerFields.exploreToolPath = payload.feature_link;
      state.newReleaseBannerFields.videoTutorialPath = payload.feature_video;
      state.newReleaseBannerFields.contentStatus = payload.content_status;
      state.newReleaseBannerFields.image = payload.image;
      state.newReleaseBannerFields.isProducer = payload.is_producer;
      state.newReleaseBannerFields.isCommercial = payload.is_commercial;
      state.newReleaseBannerFields.persistent = payload.persistent;
    },

    setSelectedPreviewPaneType(state, payload) {
      state.selectedPreviewPaneType = payload;
    },

    setSelectedPopup(state, payload) {
      state.selectedPopup = payload;
    },

    setSlideOutOpen(state, payload) {
      state.slideOutOpen = payload;
    },

    setPreviewType(state, payload) {
      state.previewType = payload;
    },
    setDirty(state, payload) {
      state.dirty = payload;
    },
    setFeaturePopups(state, payload) {
      state.featurePopups = payload;
    },
    setTotalItems(state, payload) {
      state.totalItems = payload;
    },
    setTableLoading(state, payload) {
      state.tableLoading = payload;
    },
    deleteFeaturePopup(state, payload) {
      state.featurePopups = state.featurePopups.filter((popup) => popup.id !== payload);
    },
    addFeaturePopup(state, payload) {
      if (!state.featurePopups || state.featurePopups.length === 0) {
        state.featurePopups = [payload];
      } else {
        const has = state.featurePopups.some((popup) => popup.id === payload.id);
        if (!has) {
          state.featurePopups.push(payload);
        }
      }
    },
  },
  getters: {
    formattedFilters: (state) => ({
      creator: state.selectedFilters.creator,
      content_type: state.selectedFilters.contentType,
      approval_status: state.selectedFilters.approvalStatus,
      content_status: state.selectedFilters.contentStatus,
    }),
  },
  actions: {
    async getFilters({ commit }) {
      const result = await apiGetManagingContentFilters();
      commit('setFilters', result);
    },

    async getFeaturePopups({ commit }, payload) {
      commit('setTableLoading', true);

      const data = {
        ...payload,
        sort_by: payload.sortBy,
        sort_desc: payload.sortDesc,
      };

      const { results, total } = await apiGetFeaturePopups(data);
      commit('setFeaturePopups', results);
      commit('setTotalItems', total);
      commit('setTableLoading', false);
    },

    async deleteFeaturePopup({ commit }, payload) {
      try {
        await apiDeleteFeaturePopup({ id: payload.id });
        commit('deleteFeaturePopup', payload.id);
        this._vm.$snackbar.success(`${payload.contentType} created on ${payload.dateCreated} was deleted with success.`);
      } catch (e) {
        this._vm.$snackbar.error('Could not delete content.');
      }
    },

    async updateFeaturePopup({ state }, payload) {
      const data = {
        id: state.selectedPopup,
        creator: payload.creator,
        reviewer: payload.reviewer,
        date_scheduled: negateTimezoneOffset(payload.scheduledDate),
        date_archived: negateTimezoneOffset(payload.archivedDate),
        feature_title: payload.title,
        feature_subtitle: payload.subtitle,
        feature_description: payload.body,
        feature_name: payload.featureName,
        feature_link: payload.exploreToolPath,
        feature_video: payload.videoTutorialPath,
        feature_button_text: payload.featureButtonText,
        content_status: payload.contentStatus,
        is_producer: payload.isProducer,
        is_commercial: payload.isCommercial,
        persistent: payload.persistent,
      };

      try {
        await apiUpdateFeaturePopup(data, payload.image);
        this._vm.$snackbar.success('Content updated');
      } catch (e) {
        this._vm.$snackbar.error('Content could not be updated');
      }
    },

    async getBanner({ commit, state }, bannerId) {
      const cachedPopup = state.featurePopups.find((popup) => popup.id === bannerId);
      if (cachedPopup) {
        commit('setNewReleaseBannerFields', cachedPopup);
        commit('setSelectedPopup', bannerId);
      } else {
        const result = await apiGetFeaturePopup({ id: bannerId });
        if (Object.keys(result).length > 0) {
          commit('setNewReleaseBannerFields', result);
          commit('setSelectedPopup', bannerId);
          commit('addFeaturePopup', result);
        }
      }
    },
    clearSelectedBanner({ commit }) {
      commit('setNewReleaseBannerFields', {
        creator: null,
        reviewer: null,
        scheduledDate: null,
        archivedDate: null,
        title: null,
        subtitle: null,
        body: null,
        feature_image: null,
        feature_name: null,
        feature_link: null,
        feature_video: null,
        feature_button_text: null,
        content_status: null,
        image: null,
        is_producer: false,
        is_commercial: false,
        persistent: false,
      });
      commit('setSelectedPopup', null);
      commit('setPreviewType', null);
      commit('setSlideOutOpen', false);
      commit('setSelectedPreviewPaneType', 'desktop');
    },
  },
};
