import { stripCommasAndDollarSigns } from '@/helpers/formatting';

export function requiredValueRule(name) {
  return (value) => {
    const input = (value ?? '').toString();
    return input.trim().length > 0 ? true : `${name} is required`;
  };
}

/**
 * Rule which matches a value against a provided regex.
 *  Note: if the regex is stateful (i.e. /g), it will NOT be reset before use.
 * @param {string} name The name of the field being validated
 * @param {RegExp} regex The regex to match against
 * @param {string | undefined} errorMessage Custom error message to display if the value does not match the regex
 * @returns A validation rule function
 */
export function requiredValueMatchesRegex(name, regex, errorMessage) {
  return (value) => {
    const input = (value ?? '').toString();
    return regex.test(input) ? true : (errorMessage ?? `${name} is does not match expected format.`);
  };
}

export function requiredBoolRule(name) {
  return (value) => (value === true || value === false ? true : `${name} is required`);
}

export function requiredArrayRule(name) {
  return (value) => (value.length ? true : `${name} is required`);
}

export function fixLengthRule(name, length) {
  return (value) => (value?.length === length ? true : `${name} must contain ${length} characters`);
}

export function minimumArrayLengthRule(name, length) {
  return (value) => (value?.length >= length ? true : `Must contain at least ${length} ${name}`);
}

export function minimumLengthRule(name, length) {
  return (value) => (value?.length >= length ? true : `${name} must contain at least ${length} characters`);
}

export function maxLengthRule(name, length) {
  const maxLength = Math.max(length, 0);
  return (value) => (value === null || value?.length <= maxLength ? true : `${name} must not contain more than ${maxLength} characters`);
}

export function lengthRangeRuleAllowEmpty(name, minLength, maxLength) {
  return (value) => {
    if (minLength && value && value?.length < minLength) return `${name} is too short.`;
    if (minLength && value && value?.length > maxLength) return `${name} is too long.`;
    return true;
  };
}

export function integerRule(name) {
  return (value) => (/^-?[0-9]+$/.test(value) || `${name} should be a whole number`);
}

export function integerRuleAllowEmpty(name) {
  return (value) => (!value || /^-?[0-9]+$/.test(value) || `${name} should be a number`);
}

export function numericRule(name) {
  return (value) => {
    const strValue = `${value}`;
    const expNotation = strValue.includes('e');
    const float = parseFloat(strValue);
    const isNaN = Number.isNaN(float);
    if (expNotation || isNaN) return `${name} should be a number`;
    return true;
  };
}

export function decimalPlaces(name, places) {
  return (value) => {
    const regex = new RegExp(`^[-]?[0-9]*(\\.[0-9]{0,${places}})?$`);
    return regex.test(value) || `${name} should have no more than ${places} decimal place${places > 1 ? 's' : ''}`;
  };
}

export function notAllNumericRule(name) {
  return (value) => (!(/^[0-9]+$/.test(value)) || `${name} can't be entirely numeric`);
}

export function validatePhone(name) {
  const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  return (value) => regex.test(value) || `${name} should be a valid phone number`;
}

export function validatePhoneOrNull(name) {
  return (value) => {
    if (value === '' || value === null || value === undefined) return true;
    return validatePhone(name)(value);
  };
}

export function validateEmail(email) {
  // Adapted from https://emailregex.com/
  const regex = /^([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"(!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])$/gi;
  return regex.test(String(email));
}

export function validateEmailPrefix(emailPrefix) {
  /**
   * Same logic as validateEmail, but without the @domain.com part
   */
  const regex = /^([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"(!#-[^-~ \t]|(\\[\t -~]))+")$/gi;
  return regex.test(String(emailPrefix));
}

/**
 * Ensure a value is within a range.
 * @param {string} name The name of the field being validated
 * @param {object} options Validation options.
 * @param {number} [options.min] The minimum value allowed.
 * @param {number} [options.max] The maximum value allowed.
 * @param {boolean} [options.minInclusive] Whether the minimum value is inclusive.
 * @param {boolean} [options.maxInclusive] Whether the maximum value is inclusive.
 * @returns A validation rule function
 */
export function boundsCheckRule(name, options) {
  let {
    min, max,
  } = options;
  const {
    minInclusive, maxInclusive,
  } = options;
  min = min ?? Number.NEGATIVE_INFINITY;
  max = max ?? Number.POSITIVE_INFINITY;
  return (value) => {
    const convertedValue = +value;
    if (minInclusive) {
      if (convertedValue < min) {
        return `${name} should be at least ${min}`;
      }
    } else if (convertedValue <= min) {
      return `${name} should be more than ${min}`;
    }
    if (maxInclusive) {
      if (convertedValue > max) {
        return `${name} should be at most ${max}`;
      }
    } else if (convertedValue >= max) {
      return `${name} should be less than ${max}`;
    }
    return true;
  };
}

export function rangeRule(name, min, max) {
  return (value) => {
    if (typeof min !== 'undefined' && value < min) return `${name} should be more than or equal to ${min}`;
    if (typeof max !== 'undefined' && value > max) return `${name} should be less than or equal to ${max}`;
    return true;
  };
}

export function maxRuleLessThanValue(name, max) {
  return (value) => {
    if (typeof max !== 'undefined' && value > max) return `${name} should be less than or equal to ${max}`;
    return true;
  };
}

export function maxRuleGreaterThanZero(name, max) {
  return (value) => {
    if (value <= 0) return `${name} should be more than 0`;
    if (typeof max !== 'undefined' && value > max) return `${name} should be less than or equal to ${max}`;
    return true;
  };
}

export function validEmailRule(name) {
  return (value) => (/.+@.+\..+/.test(value) || `${name} must be valid`);
}

export function validEmailRuleByValidateEmail(name) {
  return (value) => {
    if (typeof value !== 'string') { return `${name} must be valid`; }
    return validateEmail(value.trim()) || `${name} must be valid`;
  };
}

export function validEmailRuleByValidateEmailVersion2(name) {
  return (value) => (validateEmail(value.trim()) || `This ${name} looks wrong. Please enter a valid ${name} address`);
}

export function validEmailPrefixRule(name) {
  return (value) => (validateEmailPrefix(value.trim()) || `${name} must be valid`);
}

export function validFileExtensionRule(extensions) {
  return (value) => {
    if (value) {
      const name = value.name.toLowerCase();
      const extension = name.split('.').pop();

      return extensions.includes(extension) || 'Invalid file type';
    }
    return true;
  };
}

export function fileIsImageRule() {
  return (value) => {
    if (value) {
      return /^image\/.*/.test(value.type) || 'File must be an image';
    }
    return true;
  };
}

export function fileSizeRule(megabytes) {
  return (value) => {
    if (value) {
      return value.size < (megabytes * 1000 * 1000) || `File should be less than ${megabytes} MB`;
    }
    return true;
  };
}

export function isPostalCodeCA(name) {
  return (value) => (/^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/.test(value) || `${name} should be a valid postal code.`);
}

export function isZipCodeUSA(name) {
  return (value) => (/^\d{5}$/.test(value) || `${name} should be a valid zip code.`);
}

export function isPostalOrZipCode(name) {
  return (value) => {
    const options = [
      isPostalCodeCA(name)(value),
      isZipCodeUSA(name)(value),
    ];
    return options.some((val) => val === true) || `${name} should be a valid postal/zip code.`;
  };
}

export function hasScheme(link) {
  return (value) => {
    if (value) {
      return /^(https?:\/\/)/.test(value) || `${link} should start with http:// or https://`;
    }
    return true;
  };
}

export function greaterThanZero() {
  return (value) => (value > 0 || 'Must be greater than 0.');
}

export function greaterEqualToZero() {
  return (value) => (value >= 0 || 'Must be greater than or equal to 0.');
}

export function lessThanZero() {
  return (value) => (value < 0 || 'Must be less than 0.');
}

export function isValidCommodity(name, commodities) {
  return (value) => (Object.values(commodities).find((commodity) => commodity.name === value) !== undefined || `${name} is not a valid commodity.`);
}

export function noMultipleZero() {
  return (value) => {
    if (Number.isInteger(Number(value))
      && Number(value) === 0
      && String(value).length > 1) {
      return false;
    }
    return true;
  };
}

export function validateLatinUnicodeName(name) {
  const lettersAndAccentsRegex = /^[a-zA-ZÀ-ÿ-Œœ.' &]*$/;
  return lettersAndAccentsRegex.test(name) || 'Name cannot contain symbols or numbers.';
}

export function validateLatinUnicodeNameWithNumbers(name) {
  const lettersAndAccentsRegex = /^[0-9a-zA-ZÀ-ÿ-Œœ.' &]*$/;
  return lettersAndAccentsRegex.test(name) || 'Name cannot contain symbols.';
}

export function containsALetterRule(name) {
  const containsALetterRegex = /[a-zA-ZÀ-ÿŒœ]+/;
  return containsALetterRegex.test(name) || 'Name must contain at least 1 letter.';
}

export function disallowSpecialCharactersRule(name, id) {
  const regex = /^[0-9a-zA-Z-_ ]+$/;
  return regex.test(id) || `${name} cannot contain special characters.`;
}

export function disallowOnlyWhitespaceRule(name, id) {
  return id.trim().length !== 0 || `${name} cannot be only spaces.`;
}

export function startsAndEndsWithAlphanumericRule(name, id) {
  const regex = (id.length === 1) ? /[0-9a-zA-Z]+/ : /^[0-9a-zA-Z ]+.*[0-9a-zA-Z ]+$/;
  return regex.test(id) || `${name} must start and end with a character or a number.`;
}

export function searchNameDuplicate(searches) {
  return (value) => {
    if (value) {
      return !searches.find((search) => search.name.toLowerCase() === value.toLowerCase().trim()) || `The search name ${value} already exists.`;
    }
    return true;
  };
}

export function duplicateEmail(users) {
  return (value) => {
    if (value) {
      return !users.find((user) => user.email.toLowerCase() === value.toLowerCase().trim()) || 'Email already exists in the invite list.';
    }
    return true;
  };
}

export function rangeRuleAllowEmpty(name, min, max) {
  return (value) => {
    if (value === null) return true;
    return rangeRule(name, min, max)(value);
  };
}

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber && phoneNumber.length > 0) {
    const regex = /(\d{0,3})(\d{0,3})(\d{0,4})/;
    const matches = phoneNumber.replace(/\D/g, '').match(regex);
    const [
      phoneValue, areaCode, prefix, lineNumber,
    ] = matches;
    const phoneNumberFormatted = !areaCode ? prefix
      : `(${areaCode}) ${prefix}${lineNumber ? `-${lineNumber}` : ''}`;
    return { phoneValue, phoneNumberFormatted };
  }
  return { phoneValue: '', phoneNumber };
}

export function checkAllNumericByValue(value) {
  return (/^-?[0-9]*[.]?[0-9]+$/.test(value));
}

/**
 * Calls an existing validation function with any
 * commas or dollar signs stripped from the input
 * @param {Function} rule Validation function
 * @param  {...any} args Original arguments
 * @returns true or error message
 */
export function ignoreCurrency(rule, ...args) {
  return (value) => rule(...args)(stripCommasAndDollarSigns(value));
}
