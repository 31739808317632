<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :style="{ maxWidth: `588px` }"
    :disabled="!editor"
    @submit.prevent=""
  >
    <properties-fields
      class="mt-2"
      :state="state"
    />

    <v-btn
      v-if="!state.isPublished"
      class="mt-2 mr-2"
      large
      :disabled="state.isUploading || !isFormValid || !hasContents"
      @click="submitDraft"
    >
      Save as Draft
    </v-btn>

    <v-btn
      type="submit"
      color="primary"
      class="mt-2"
      large
      :disabled="state.isUploading || !isFormValid || !hasContents"
      @click="submitForPublication"
    >
      {{ buttonText }}
    </v-btn>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="400px"
      width="100%"
    >
      <confirm-card
        heading="Confirm"
        :text="confirmDialogText"
        :confirm-text="confirmButtonText"
        @cancel="confirmDialog = false"
        @confirm="handlePublish"
      >
        <template v-if="!willSaveDraft">
          <p>
            <span>By publishing, you accept that this document will be shared</span>
            <span
              :class="[
                'font-weight-bold',
                {'error--text': !internalPublication},
              ]"
            >
              {{ noticeText }}
            </span>
          </p>
        </template>
      </confirm-card>
    </v-dialog>
    <SnippetConfirmDialog
      v-if="snippetDialog"
      v-model="snippetDialog"
      :snippets="state.snippets"
      @confirm="handleSnippetDialogSave"
    />
  </v-form>
</template>

<script>
import ConfirmCard from '../generic/ConfirmCard.vue';
import PropertiesFields from './PropertiesFields.vue';
import SnippetConfirmDialog from '@/components/document/SnippetConfirmDialog.vue';
import { getLocalDateTime, dateFormatShortMonthDate12HourTime } from '../../helpers/formatting';

export default {
  components: {
    PropertiesFields,
    ConfirmCard,
    SnippetConfirmDialog,
  },

  props: {
    state: {
      type: Object,
      default: () => {},
    },

    editor: {
      type: Object,
      default: null,
    },

    hasContents: Boolean,
  },
  data() {
    return {
      isFormValid: false,
      confirmDialog: false,
      willSaveDraft: false,
      snippetDialog: false,
    };
  },
  computed: {
    publication() {
      return this.state.allPublications
        .find((x) => x.id === this.state.publicationId);
    },

    confirmButtonText() {
      if (this.willSaveDraft || this.state.isPublished) return 'Save';
      return this.state.isScheduled ? 'Schedule' : 'Publish';
    },

    internalPublication() {
      return this.publication?.is_internal;
    },

    noticeText() {
      return this.internalPublication
        ? 'internally.'
        : `externally with ${this.publication?.user_count} members.`;
    },

    confirmDialogText() {
      let confirmationText;
      if (this.willSaveDraft) {
        confirmationText = 'Do you want to save the document as a draft?';
      } else if (this.state.isPublished) {
        confirmationText = 'Do you want to save your changes?';
      } else if (this.state.isScheduled) {
        const date = getLocalDateTime(this.state.publishDate, this.state.publishTime);
        confirmationText = `Do you want to schedule publication for ${dateFormatShortMonthDate12HourTime(date)}?`;
      } else {
        confirmationText = 'Do you want to publish the document now?';
      }

      let notificationText;
      if (this.state.sendNotification && !this.willSaveDraft) {
        const verb = this.state.isPublished ? 'saved' : 'published';
        notificationText = `Once ${verb}, notifications will be sent.`;
      }

      return [confirmationText, notificationText].join(' ');
    },

    buttonText() {
      if (this.state.isPublished) return 'Save';
      return this.state.isScheduled ? 'Schedule' : 'Publish';
    },
  },
  methods: {
    async handleSubmit() {
      if (this.publication.parse_snippets) {
        this.state.isUploading = true;
        this.state.snippets = await API.parseSnippets(this.state.contents);
        this.state.isUploading = false;
        this.snippetDialog = true;
      } else {
        this.confirmDialog = true;
      }
    },

    submitForPublication() {
      this.willSaveDraft = false;
      this.handleSubmit();
    },

    submitDraft() {
      this.willSaveDraft = true;
      this.handleSubmit();
    },

    handlePublish() {
      this.state.isDraft = this.willSaveDraft;
      this.$refs.form.resetValidation();
      this.state.noticeAccepted = true;
      this.confirmDialog = false;
      this.$emit('submit');
    },
    handleSnippetDialogSave() {
      this.snippetDialog = false;
      this.confirmDialog = true;
    },
  },
};
</script>
