import { datadogRum } from '@datadog/browser-rum';

export default {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store');
    }

    const { getters } = store;

    /**
     * Append user information
     */
    const userSetup = store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'user/loadUserState') {
          if (state.user.isLoggedIn) {
            try {
              window.Appcues.identify(
                state.user.id,
                {
                  email: state.user.email,
                  firstName: state.user.firstName,
                  fullName: state.user.fullName,
                  lastName: state.user.lastName,
                },
              );
            } catch (e) {
              datadogRum.addError(new Error('Could not reach Appcues.'));
            }
          }
          userSetup();
        }
      },
    });

    /**
     * Append Chargebee information
     */
    const chargebeeSetup = store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'chargebee/loadChargebeeState') {
          if (state.user.isLoggedIn) {
            try {
              window.Appcues.identify(
                state.user.id,
                {
                  trialStart: state.chargebee.subscription.trial_start,
                  trialEnd: state.chargebee.subscription.trial_end,
                  trialLength: getters['chargebee/trialLengthInDays'],
                  chargebeeID: state.chargebee.subscription.id,
                  paymentFrequency: getters['chargebee/planFrequency'],
                  plan: getters['chargebee/plan'].planId,
                  inTrial: getters['chargebee/isInTrial'],
                  isBasic: getters['chargebee/isBasic'],
                  isCommercial: getters['chargebee/isCommercial'],
                  isCommercialBasic: getters['chargebee/isCommercialBasic'],
                  isMarketIntelligence: getters['chargebee/isMarketIntelligence'],
                },
              );
            } catch (e) {
              datadogRum.addError(new Error('Could not reach Appcues.'));
            }
          }
          chargebeeSetup();
        }
      },
    });

    /**
     * Append GrainFox subscription information
     */
    const subscriptionSetup = store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'subscription/loadSubscriptionState') {
          if (state.user.isLoggedIn) {
            try {
              window.Appcues.identify(
                state.user.id,
                {
                  ownerId: state.subscription.ownerId,
                  registrationSource: state.subscription.source,
                  activeSubscriptionName: getters['subscription/getActiveSubscriptionName'],
                  isFarmer: getters['subscription/getIsFarmer'],
                  isDeputter: getters['subscription/isDeputter'],
                  ownsAnyAccounts: getters['subscription/ownsAnySubscription'],
                },
              );
            } catch (e) {
              datadogRum.addError(new Error('Could not reach Appcues.'));
            }
          }
          subscriptionSetup();
        }
      },
    });
  },
};
