<template>
  <v-card>
    <v-card-title class="pb-0">
      Recommendations For You
    </v-card-title>
    <div
      class="pa-4 pt-0"
    >
      <template
        v-if="results.length === 0 && isLoading === true"
      >
        <div class="grey--text text-center my-8 py-8">
          Loading...
        </div>
      </template>
      <template
        v-else-if="results.length === 0 && isLoading === false"
      >
        <div class="grey--text text-center my-8 py-8">
          No Results
        </div>
      </template>
      <template
        v-else
      >
        <template
          v-for="(resource, index) in results"
        >
          <LatestNewsItem
            :id="resource.id"
            :key="resource.id"
            :name="resource.name"
            :date="resource.date"
            :icon="resource.icon"
            :provider="resource.provider"
            :source="resource.source"
            :content="resource.content"
            :audio-file-female="resource.audio_file_female"
            :audio-file-male="resource.audio_file_male"
            :index="index"
          />
        </template>
      </template>
    </div>
  </v-card>
</template>

<script>
import { postRequest } from '@/helpers/request';
import LatestNewsItem from '@/components/dashboard/LatestNewsItem.vue';

export default {
  components: {
    LatestNewsItem,
  },
  data() {
    return {
      isLoading: true,
      results: [],
    };
  },
  async mounted() {
    const data = await postRequest('/machine_learning/recommend_news/');
    this.results = data.results;
    this.isLoading = false;
  },
};
</script>
