<template>
  <v-snackbar
    v-model="show"
    data-qa="snackbar"
    :color="color"
    :light="!color"
    :timeout="timeout"
    app
  >
    {{ message }}

    <template
      v-if="onUndo"
      #action="{ attrs }"
    >
      <!-- Show undo button if onUndo callback is provided -->
      <v-btn
        text
        v-bind="attrs"
        @click="undoAction"
      >
        Undo
      </v-btn>
    </template>

    <template
      v-else
      #action="{ attrs }"
    >
      <v-btn
        text
        v-bind="attrs"
        @click="show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      color: '',
      timeout: 10000,
      show: false,
      onUndo: null,
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        if (state.snackbar.message
          && typeof state.snackbar.message === 'string'
          && state.snackbar.message.trim().length > 0) {
          this.message = state.snackbar.message;
          this.color = state.snackbar.color;
          this.timeout = state.snackbar.timeout;
          this.show = true;
          this.onUndo = state.snackbar.onUndo;
        }
      }
    });
  },

  methods: {
    undoAction() {
      this.onUndo();
      this.show = false;
    },
  },
};
</script>
