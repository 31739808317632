<template>
  <GDialog
    v-model="isInviteDialogOpen"
    :title="title"
    width="600"
    :action-buttons="buttons"
    :subtitle="subtitle"
    @cancel="closeInviteDialog"
  >
    <v-form
      ref="inviteForm"
      v-model="isInviteFormValid"
      @submit.prevent="addInvitee"
    >
      <v-card-text class="px-0 pb-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            :class="$isMobile ? 'py-0' : 'py-0 pr-1'"
          >
            <v-text-field
              v-model.trim="inviteeFirstName"
              label="First Name"
              :rules="[
                requiredValueRule('First Name'),
                maxLengthRule('First Name', 30),
                minimumLengthRule('First Name', 2),
                validateLatinUnicodeName,
                containsALetterRule,
              ]"
              outlined
              required
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            :class="$isMobile ? 'py-0' : 'py-0 pl-1'"
          >
            <v-text-field
              v-model.trim="inviteeLastName"
              label="Last Name"
              :rules="[
                requiredValueRule('Last Name'),
                maxLengthRule('Last Name', 30),
                minimumLengthRule('Last Name', 2),
                validateLatinUnicodeName,
                containsALetterRule,
              ]"
              outlined
              required
            />
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <template v-if="showDomainRestrictions">
              <v-text-field
                v-model.trim="inviteeEmail"
                :rules="[
                  requiredValueRule('Email'),
                  validEmailPrefixRule('Email'),
                  duplicateEmailRule,
                  notUserEmailRule,
                ]"
                label="Email"
                outlined
                required
              >
                <template #append>
                  <div class="text-select-domain">
                    <v-select
                      v-model="inviteeEmailDomain"
                      class="h--100"
                      :items="domainRestrictions"
                      :rules="[
                        requiredValueRule('Email Domain'),
                      ]"
                      filled
                      outlined
                      :disabled="domainRestrictions.length === 1"
                      hide-details
                      required
                    >
                      <template #selection="{ item }">
                        <span class="text-select-domain__selection">
                          @{{ item }}
                        </span>
                      </template>
                      <template
                        v-if="domainRestrictions.length === 1"
                        #append
                      >
                        <div />
                      </template>
                    </v-select>
                  </div>
                </template>
              </v-text-field>
            </template>
            <template v-else>
              <v-text-field
                v-model.trim="inviteeEmail"
                :rules="[
                  requiredValueRule('Email'),
                  validEmailRuleByValidateEmail('Email'),
                  duplicateEmailRule,
                  notUserEmailRule,
                ]"
                label="Email"
                outlined
                required
              />
            </template>
          </v-col>
        </v-row>
        <v-col class="text-right pb-0">
          <v-btn
            color="primary"
            type="submit"
            text
            :disabled="!isInviteFormValid"
          >
            <v-icon left>
              mdi-plus
            </v-icon> ADD MEMBER
          </v-btn>
        </v-col>
        <v-col v-show="usersToInvite.length > 0">
          <p class="primary-grey--text text-body-1">
            Invites
          </p>
          <v-data-table
            :headers="headers"
            :items="usersToInvite"
            :items-per-page="-1"
            disable-sort
            disable-pagination
            hide-default-header
            hide-default-footer
            mobile-breakpoint="300"
          >
            <template
              #[`item.user`]="{ item }"
            >
              <v-col
                :class="`truncate-${truncatedClass} d-inline-block text-truncate px-0`"
              >
                <span class="grey--text text--darken-4">
                  {{ inviteeFullName(item) }}
                </span>
                <br v-if="$isMobile">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <span
                      class="grey--text text--darken-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      ({{ item.email }})
                    </span>
                  </template>
                  <span> {{ item.email }} </span>
                </v-tooltip>
              </v-col>
            </template>

            <template
              #[`item.actions`]="{ item }"
            >
              <v-btn
                icon
                @click="removeInvitee(item)"
              >
                <v-icon>
                  mdi-trash-can
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-card-text>
    </v-form>
  </GDialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { postRequest } from '@/helpers/request';
import {
  requiredValueRule,
  validEmailRuleByValidateEmail,
  validEmailPrefixRule,
  maxLengthRule,
  minimumLengthRule,
  validateLatinUnicodeName,
  containsALetterRule,
  duplicateEmail,
} from '@/helpers/rules';
import GDialog from '@/components/generic/GDialog.vue';

export default {
  components: {
    GDialog,
  },
  data() {
    return {
      inviteeFirstName: '',
      inviteeLastName: '',
      inviteeEmail: '',
      inviteeEmailDomainValue: null,
      isInviteFormValid: false,
      usersToInvite: [],
      buttons: [
        {
          name: 'CANCEL',
          side: 'right',
          type: 'default',
          onClick: this.closeInviteDialog,
        },
        {
          name: 'SEND INVITATION',
          side: 'right',
          type: 'primary',
          onClick: this.sendInvite,
          disabled: true,
          hasIcon: true,
          icon: 'mdi-send',
        },
      ],
      headers: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Actions', value: 'actions', align: 'end', sortable: false,
        },
      ],
    };
  },

  computed: {
    title() {
      return this.isInviteToSubscription ? 'Invite members to your account' : 'Invite members to GrainFox';
    },

    subtitle() {
      return this.isInviteToSubscription ? 'Invitations to your account will be sent via email.' : 'Invitations will be sent via email.';
    },

    isInviteDialogOpen: {
      get() {
        return this.$store.state.app.isInviteDialogOpen;
      },
      set(value) {
        this.$store.commit('app/setInviteDialogOpen', value);
      },
    },

    duplicateEmailRule() {
      return duplicateEmail(this.usersToInvite);
    },

    notUserEmailRule() {
      return (value) => (value?.trim().toLowerCase() !== this.email?.toLowerCase() || 'Unable to invite your own email');
    },

    truncatedClass() {
      return this.$isMobile ? 'mobile' : 'desktop';
    },

    inviteeEmailDomain: {
      get() {
        if (this.inviteeEmailDomainValue) {
          return this.inviteeEmailDomainValue;
        }
        return this.domainRestrictions[0];
      },
      set(newValue) {
        this.inviteeEmailDomainValue = newValue;
      },
    },

    showDomainRestrictions() {
      return this.domainRestrictions.length > 0;
    },

    domainRestrictions() {
      return this.getDomainRestrictions;
    },

    ...mapState('app', ['isInviteToSubscription']),
    ...mapState('user', ['email']),
    ...mapGetters('subscription', ['getDomainRestrictions']),
  },

  watch: {
    usersToInvite() {
      this.buttons[1].disabled = this.usersToInvite.length <= 0;
    },

  },

  methods: {
    requiredValueRule,
    validEmailRuleByValidateEmail,
    validEmailPrefixRule,
    validateLatinUnicodeName,
    maxLengthRule,
    minimumLengthRule,
    containsALetterRule,

    resetInviteValidation() {
      this.inviteeEmail = '';
      this.inviteeFirstName = '';
      this.inviteeLastName = '';
      this.$refs.inviteForm.resetValidation();
    },

    closeInviteDialog() {
      this.resetInviteValidation();
      this.usersToInvite = [];
      this.$store.commit('app/setInviteDialogOpen', false);
    },

    addInvitee() {
      let email = this.inviteeEmail;
      if (this.showDomainRestrictions) {
        email += `@${this.inviteeEmailDomain}`;
      }
      const invitee = {
        invitee_first_name: this.inviteeFirstName,
        invitee_last_name: this.inviteeLastName,
        email,
      };
      this.usersToInvite.push(invitee);
      this.resetInviteValidation();
    },

    removeInvitee(user) {
      const index = this.usersToInvite.indexOf(user);
      this.usersToInvite.splice(index, 1);
    },

    inviteeFullName(user) {
      return `${user.invitee_first_name} ${user.invitee_last_name}`;
    },

    async sendInvite() {
      if (this.usersToInvite.length > 0) {
        this.$snackbar.info('Processing the invite...');
        const data = {
          to_subscription: this.isInviteToSubscription,
          users: this.usersToInvite,
        };

        try {
          this.closeInviteDialog();
          const response = await postRequest('/api/invite/', data);
          this.$snackbar.success(response.message);
        } catch (e) {
          this.closeInviteDialog();
          this.$snackbar.error(e);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate-desktop {
  max-width: 400px;
}
.truncate-mobile {
  max-width: 180px;
}
.text-select-domain {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40%;
  min-width: 150px;
  &__selection {
    min-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
