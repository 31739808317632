<template>
  <v-dialog
    :value="value"
    persistent
    :max-width="maxWidth"
    width="100%"
  >
    <confirm-card
      :heading="heading"
      :text="text"
      :warnings="warnings"
      :info="info"
      :confirm-text="confirmText"
      :cancel-text="cancelText"
      @cancel="close"
      @confirm="confirm"
    />
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmPopup',
  props: {
    // Standard v-model compatible property for showing/hiding the modal
    value: {
      type: Boolean,
      default: false,
    },

    // max-width of the popup
    maxWidth: {
      type: Number,
      default: 600,
    },

    /**
     * Props passed directly into ConfirmCard.vue
     * See the component for details on props.
     */
    heading: {
      type: String,
      default: 'Confirm',
    },
    text: {
      type: String,
      required: true,
    },
    warnings: {
      type: Array,
      default: () => [],
    },
    // if info is true:
    // 1. Hide confirm button
    // 2. Change button text from 'Cancel' to 'Close'
    info: {
      type: Boolean,
      default: false,
    },

    // Text for the confirm button
    confirmText: {
      type: String,
      default: 'Confirm',
    },

    // Text for the cancel button
    cancelText: {
      type: String,
      default: 'Cancel',
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },

    confirm() {
      this.close();
      this.$emit('confirm');
    },
  },
};
</script>
