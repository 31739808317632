import CustomIcon from '@/components/icons/CustomIcon.vue';
import { iconList } from '@/icons/custom-icons';

const customIcons = {};
iconList.forEach((icon) => {
  customIcons[`custom${icon.name}`] = {
    component: CustomIcon,
    props: {
      name: icon.name,
    },
  };
});

export default customIcons;
