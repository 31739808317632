const VideoPage = () => import('@/pages/videos/VideoPage.vue');
const VideoPlayerPage = () => import('@/pages/videos/VideoPlayerPage.vue');

const routes = [
  {
    path: '/videos/',
    meta: {
      title: 'Videos',
    },
    component: VideoPage,
  },
  {
    path: '/video/:videoId/',
    meta: {
      title: 'Videos',
    },
    component: VideoPlayerPage,
  },
];

export default routes;
