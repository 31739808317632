const colourPalette = [
  '#fcf7cd',
  '#ffd271',
  '#ffa843',
  '#f47721',
  '#e33f3e',
  '#801e36',
  '#2e1438',
  '#13418f',
  '#427af1',
  '#9575f5',
  '#5dbe9c',
  '#72a75a',
  '#e1cb31',
  '#4b5f6a',
  '#4a4a4a',
  '#848c8c',
];

export default colourPalette;
