<template>
  <TakeActionCard
    :header="title"
    :message="body"
    class="z--1 white pb-8"
    :icon="$isDesktop ? '$vuetify.icons.emptyStateUpgradeAccount' : ''"
    :show-button="showUpgradeLink"
    :remove-message-margin="removeMessageMargin"
  >
    <v-btn
      v-if="showUpgradeLink"
      color="primary"
      href="/commerce/tiers/"
    >
      {{ buttonText }}
    </v-btn>
  </TakeActionCard>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import TakeActionCard from '@/components/farm_profile/shared/TakeActionCard.vue';

export default {
  name: 'GenericCTA',

  components: {
    TakeActionCard,
  },

  computed: {
    ...mapState('app', ['windowSize']),
    ...mapGetters('subscription', ['isAmerican']),
    ...mapGetters('permissions', ['isOwnerOrBilling']),
    ...mapGetters('chargebee', ['isCommercial']),
    buttonText() {
      if (this.isAmerican) {
        return 'UPGRADE PLAN';
      }
      if (this.isCommercial) {
        return 'UPGRADE TO COMMERCIAL PLAN';
      }
      return 'UPGRADE TO SMART PLAN';
    },
    showUpgradeLink() {
      return this.isOwnerOrBilling('update');
    },

    title() {
      if (!this.$isMobile) {
        return `${this.titleOne} \n ${this.titleTwo}`;
      }
      return this.titleTwo;
    },

    titleOne() {
      if (this.showUpgradeLink) {
        return 'Oops!';
      }
      return 'Whoops!';
    },

    titleTwo() {
      if (this.showUpgradeLink) {
        return "Your current plan doesn't include this feature.";
      }
      return "This feature isn't included in your current plan.";
    },
    body() {
      if (this.showUpgradeLink) {
        if (this.isAmerican) {
          return 'Upgrade your plan to unlock more GrainFox tools and features.';
        }
        if (this.isCommercial) {
          return 'Please upgrade to Commercial Plan to unlock all GrainFox tools and features.';
        }
        return 'Upgrade to Smart Plan to unlock all GrainFox tools and features.';
      }

      return 'Talk to your account administrator about getting access.';
    },
    removeMessageMargin() {
      return this.windowSize.width < 400;
    },
  },
};
</script>
