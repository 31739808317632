<template>
  <div
    class="library-table__row v-card v-sheet mb-4 mb-md-8 mx-4 mx-sm-0 pa-4 pa-sm-0 white p-rel"
  >
    <div
      v-if="hideSalesRecs"
      class="blur-overlay rounded-lg z--2"
    >
      <div
        class="w-100 pa-4 d-flex"
        :style="buttonContainerStyle"
      >
        <v-btn
          color="primary"
          :class="$isMobile ? 'mb-3' : ''"
          href="/commerce/tiers/"
        >
          Upgrade to view
        </v-btn>
      </div>
    </div>
    <v-hover v-slot:default="{ hover }">
      <div
        class="library-table__grid c-pointer"
        :class="{'blur-child': hideSalesRecs}"
      >
        <div
          class="ga-icon library-table__cell library-table__icon
           d-flex align-center py-0 py-sm-6 py-md-0"
          @click="previewFile(file.id)"
        >
          <div
            class="library-table__icon--img p-rel"
            :style="libraryIconStyle"
          >
            <div
              v-if="fileIsNew"
              class="library-table__icon--new text-uppercase text-caption
               font-weight-bold white--text error"
            >
              New
            </div>
          </div>
        </div>
        <div
          class="ga-content library-table__cell
           px-0 px-sm-4 py-4 py-sm-6 pt-0 pt-sm-6 pl-0 pl-sm-6 pl-md-8"
          @click="previewFile(file.id)"
        >
          <p class="my-0 text-md-truncate d-flex align-center text-h6">
            <library-title
              :name="file.tagline"
              :draft="file.is_draft"
              :hover="hover"
            />
          </p>
          <p
            data-qa="library-item-name"
            class="my-0 mb-sm-2 text-truncate grey--text text--darken-1"
          >
            {{ file.name }}
          </p>
          <p class="d-sm-flex my-0 my-2">
            <v-tooltip
              v-if="file.writers.length > 3"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    class="text--primary mr-2"
                    dense
                  >mdi-account</v-icon>
                </span>
              </template>
              <span>{{ file.writers.length > 0 ? file.writers.join(', ') : 'No Writers' }}</span>
            </v-tooltip>
            <span
              v-else
            >
              <v-avatar
                size="25"
              >
                <v-img
                  :src="file['profile_picture']"
                  alt="Writer Img"
                />
              </v-avatar>
              <v-icon
                color="grey darken-1"
              >
                mdi-circle-small
              </v-icon>
            </span>
            <span class="grey--text text--darken-1 text-truncate">
              By {{ file.writers.length > 0 ? file.writers.slice(0,3).join(', ') : 'No Writers' }}
              {{ file.writers.length > 3 ? `, and ${file.writers.length - 3} more` : '' }}
            </span>
          </p>
          <p class="d-none d-sm-flex my-0">
            <v-tooltip
              v-if="file.topics.length > 5"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    class="text--primary mr-2"
                    dense
                  >mdi-information</v-icon>
                </span>
              </template>
              <span>{{ file.topics.length > 0 ? file.topics.join(', ') : 'No Topics' }}</span>
            </v-tooltip>
            <v-icon
              v-else
              class="text--primary mr-2"
              dense
            >
              mdi-information
            </v-icon>
            <span class="grey--text text--darken-1 text-truncate">
              {{ file.topics.length > 0 ? file.topics.slice(0,5).join(', ') : 'No Topics' }}
              {{ file.topics.length > 5 ? `, and ${file.topics.length - 5} more` : '' }}
            </span>
          </p>
        </div>
        <div
          class="ga-date library-table__cell d-flex px-0 px-sm-4 pt-0 pt-sm-6 pb-4 pb-sm-6 pb-md-6"
          @click="previewFile(file.id)"
        >
          <div
            v-if="!file.is_draft"
            class="grey--text text--darken-1"
          >
            <p class="font-weight-bold primary-grey--text my-0">
              Publish Date
            </p>
            <p
              v-if="new Date(file.date_published) > new Date()"
              class="mb-0"
            >
              [ <i>Scheduled</i> ]
            </p>
            <p class="mb-0">
              {{ fileDateFormatted }}
            </p>
          </div>
        </div>
        <div
          class="ga-action library-table__cell
           d-flex align-start px-0 px-sm-4 pb-0 pb-sm-6 pt-0 pt-md-6"
        >
          <div class="d-flex align-center justify-end flex-wrap w--100 my-auto my-sm-0">
            <v-btn
              v-if="fileCanEdit"
              icon
              @click="editFile(file)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="file.is_published"
              icon
              :color="file.isFavourited ? 'primary' : ''"
              @click="favouriteLibrary(file.id)"
            >
              <v-icon>{{ file.isFavourited ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
            </v-btn>
            <v-btn
              v-if="file.can_share"
              icon
              @click="shareDialogOpen(file)"
            >
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
            <v-btn
              v-if="filterState.filterViewMode !== 'scheduled' && file.is_published"
              icon
              :color="file.isArchived ? 'primary' : ''"
              @click="archiveFile(file.id)"
            >
              <v-icon>mdi-package-down</v-icon>
            </v-btn>
            <v-btn
              v-if="file.can_delete"
              icon
              @click="deleteFile(file.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-hover>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'LibraryItem',
  props: {
    file: {
      type: Object,
      required: true,
    },
    filterState: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('subscription', ['role']),
    libraryIconStyle() {
      const image = { 'background-image': `url(${this.file.icon})` };
      return this.file.icon.length > 0 ? image : {};
    },
    fileIsNew() {
      return typeof (this.file.isRead) !== 'undefined' && !this.file.isRead;
    },
    fileDateFormatted() {
      return new Date(this.file.date_published).toLocaleDateString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
      });
    },
    fileCanEdit() {
      return this.filterState.filterViewMode !== 'shared' && this.file.can_edit && ['sheet', 'html'].includes(this.file.type);
    },
    hideSalesRecs() {
      if (this.file.content !== 'Sales Recommendations') {
        // if file is not sales rec, no CTA
        return false;
      }
      if (this.filterState.filterPublicationSelection?.some((filter) => filter.name === 'Sales Recommendations')) {
        if (this.filterState.filterPublicationSelection?.length === 1) {
          return false;
        }
      }

      // If User is Commercial Customers, should see sales rec, but teasered if Basic
      if (this.role === 'commercial') {
        return false;
      }
      // if user has sales rec permission, don't show CTA
      return !this.checkPermission('sales_recommendation', 'read');
    },
    buttonContainerStyle() {
      return {
        height: 'inherit',
        justifyContent: this.$isMobile ? 'center' : 'end',
        alignItems: 'end',
      };
    },
    ...mapGetters('permissions', ['checkPermission']),
    ...mapState('launchDarkly', ['flags']),
  },
  methods: {
    previewFile(fileId) {
      this.$emit('preview-file', fileId);
    },
    editFile(file) {
      this.$emit('edit-file', file);
    },
    favouriteLibrary(fileId) {
      this.$emit('favourite-library', fileId);
    },
    shareDialogOpen(file) {
      this.$emit('share-dialog-open', file);
    },
    archiveFile(fileId) {
      this.$emit('archive-file', fileId);
    },
    deleteFile(fileId) {
      this.$emit('delete-file', fileId);
    },
  },
};
</script>
<style lang="scss" scoped>
.blur {
    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF77;
    }
    &-child {
        filter: blur(4px);
        pointer-events: none;
    }
}
</style>
