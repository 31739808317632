<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <FingerprintField />
    <v-card
      width="100%"
      max-width="500"
      class="mx-auto"
    >
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            sm="12"
          >
            <v-select
              v-if="!hideTTSVoiceOptions"
              v-model="voice"
              :items="voices"
              :rules="voiceRules"
              label="Text-to-Speech Voice"
              name="use_female_ai_voice"
              item-text="text"
              item-value="value"
              required
              outlined
            />
            <v-switch
              v-model="toggleWebMessengerAudio"
              label="Web Messenger Audio"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="errors" />
      <v-list>
        <v-list-item
          v-for="error in errors"
          :key="error.message"
          class="px-4 error-list error--text"
        >
          <v-icon
            color="error"
            class="mr-1"
          >
            mdi-alert-circle
          </v-icon>
          <span class="px-2 error-list error--text">
            Field '{{ getFieldName(error) }}': {{ error[0].message }}
          </span>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="mx-auto"
          color="primary"
          :disabled="disabled"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import FingerprintField from '@/components/form/FingerprintField.vue';

export default {
  components: {
    FingerprintField,
  },

  props: {
    useFemaleVoice: {
      type: Boolean,
      required: true,
    },
    useWebMessengerAudio: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      toggleWebMessengerAudio: this.useWebMessengerAudio,
      voice: this.useFemaleVoice,
      voiceRules: [
        (v) => (v !== undefined) || 'Text-to-Speech voice is required',
      ],
      voices: [
        { text: 'Female', value: true },
        { text: 'Male', value: false },
      ],
      errors: null,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    disabled() { // prevents empty forms from being submitted
      return !this.valid || !(this.voice !== '');
    },
    hideTTSVoiceOptions() {
      return this.flags['remove-tts-voice-preference'];
    },
  },

  methods: {
    submit(e) {
      if (!this.$refs.form.validate()) {
        e.preventDefault();
      } else {
        this.sendPreferencesInfo();
      }
    },

    async sendPreferencesInfo() {
      // build request data
      this.requestData = {
        use_female_ai_voice: this.voice,
        web_messenger_audio: this.toggleWebMessengerAudio,
      };

      this.$emit('snack-bar-warning', 'Updating Preferences Information...');

      // eslint-disable-next-line no-undef
      const data = await API.updatePreferencesInfo(this.requestData);

      if (data.is_valid) {
        this.$emit('snack-bar-success', data.message);
        this.errors = null;
      } else {
        this.$emit('snack-bar-error', data.message);
        this.errors = JSON.parse(data.form_errors);
      }

      this.requestData = null;
    },
    getFieldName(error) {
      let fieldName = '';
      const header = Object.keys(this.errors).find(
        (key) => this.errors[key] === error,
      );

      if (header === 'use_female_ai_voice') {
        fieldName = 'Text-to-Speech Voice';
      }

      if (header === 'web_messenger_audio') {
        fieldName = 'Web Messenger Audio';
      }
      return fieldName;
    },
  },
};
</script>
