<template>
  <div
    class="d-flex mt-8 mb-4"
    :class="this.$vuetify.breakpoint.xl ? 'mx-16' : 'mx-4' "
  >
    <div
      class="d-flex flex-column"
      :class="this.$vuetify.breakpoint.xl ? 'mr-16' : 'mr-13' "
      :style="this.$vuetify.breakpoint.xl ?
        'min-width: 440px; max-width: 660px' : 'min-width: 340px; max-width: 560px'"
    >
      <p
        class="primary-grey--text"
        :class="this.$vuetify.breakpoint.xl ? 'text-h2 font-weight-regular mb-4' : 'text-h4 mb-2'"
        data-qa="new-feature-alert-title"
      >
        {{ featureName }}
      </p>
      <p
        class="font-weight-regular primary-grey--text"
        :class="this.$vuetify.breakpoint.xl ? 'text-h5 mb-6' : 'text-h6 mb-4'"
        data-qa="new-feature-alert-subtitle"
      >
        {{ featureSubtitle }}
      </p>
      <p
        class="text-pre-line"
        :class="this.$vuetify.breakpoint.xl ? 'text-h6 font-weight-regular mb-16' : 'mb-6'"
        data-qa="new-feature-alert-body"
      >
        {{ featureDescription }}
      </p>
      <div
        class="align-end"
        :class="this.$vuetify.breakpoint.xl ? 'mt-15' : ''"
      >
        <v-btn
          v-if="featureLink"
          class="mr-4"
          color="primary"
          data-qa="new-feature-alert-go-to-feature-button"
          @click="goToFeature"
        >
          {{ goToFeatureButtonText }}
        </v-btn>
        <v-btn
          v-if="showVideoButton"
          data-qa="new-feature-alert-watch-video-button"
          @click="openVideoDialog"
        >
          See Tutorial Video
        </v-btn>
      </div>
    </div>
    <div
      class="w--100 ml-13 d-flex justify-center"
    >
      <img
        v-if="featureImage"
        :src="featureImage"
        :alt="featureName"
        class="elevation-3 rounded-lg banner-image"
      >
      <div
        v-else
        class="empty-image elevation-3 rounded-lg"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NewFeatureAlert',

  props: {
    featureName: {
      type: String,
      required: true,
    },

    featureSubtitle: {
      type: String,
      required: true,
    },

    featureDescription: {
      type: String,
      required: true,
    },

    featureImage: {
      type: String,
      required: true,
    },

    featureLink: {
      type: String,
      required: true,
    },

    showVideoButton: {
      type: Boolean,
      required: true,
    },

    goToFeatureButtonText: {
      type: String,
      default: 'Explore Tool',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    previewing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('user', ['picture', 'fullName']),
    ...mapState('launchDarkly', ['flags']),
  },

  methods: {
    goToFeature() {
      if (!this.readOnly) {
        this.$emit('feature');
        if (this.isExternalLink(this.featureLink)) {
          window.open(this.featureLink, '_blank');
        } else {
          window.location = this.featureLink;
        }
      }
    },

    isExternalLink(url) {
      try {
        const urlObject = new URL(url);
        return urlObject.origin !== window.location.origin;
      } catch (e) {
        return false;
      }
    },
    openVideoDialog() {
      if (!this.readOnly) {
        this.$emit('video');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "@/colors/colors";
.banner-image {
  max-height: 33vh;
  height: 100%;
  width: auto;
  max-width: 100%;
}
.empty-image {
  max-height: 33vh;
  height: 100%;
  width: 100%;
  background-color: colors.$greyLighten2;
  background-size: contain;
  background-position: center;
}
</style>
