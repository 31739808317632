const routes = [
  {
    path: '/dashboard/resource_hub/:slug?/',
    meta: {
      title: 'Resource Hub',
    },
  },
];

export default routes;
