const contextMenuWhitelistRoutes = [
  '/messenger/',
];

const printingWhitelistRoutes = [
  '/gma_module/',
];

const print = (e) => {
  const { key, ctrlKey, shiftKey } = e;
  return (ctrlKey && key === 'p')
  || (ctrlKey && shiftKey && key === 'P');
};

const source = (e) => {
  const { key, ctrlKey } = e;
  return (ctrlKey && key === 'u');
};

const devtools = (e) => {
  const { key, ctrlKey, shiftKey } = e;
  return key === 'F12'
  || (ctrlKey && shiftKey && key === 'I')
  || (ctrlKey && shiftKey && key === 'J')
  || (ctrlKey && shiftKey && key === 'C');
};

export default {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store');
    }

    Vue.prototype.$lockdown = {
      process(to = '') {
        const isDebug = store.getters['app/isDebug'];
        const shouldEnableContextMenu = isDebug || contextMenuWhitelistRoutes.includes(to);
        const shouldEnablePrinting = isDebug || printingWhitelistRoutes.includes(to);
        store.commit('app/setIsPrintingEnabled', shouldEnablePrinting);

        document.oncontextmenu = () => shouldEnableContextMenu;
        document.onkeydown = (e) => {
          if (print(e)) return shouldEnablePrinting;
          if (source(e)) return isDebug;
          if (devtools(e)) return isDebug;
          return true;
        };
      },
    };
  },
};
