<template>
  <v-card
    height="100%"
    :class="{'elevation-0 px-4': $isMobile}"
  >
    <v-card-title
      :class="!$isMobile ? 'px-6' : 'px-0 py-3'"
    >
      <span :class="!$isMobile ? 'text-h5' : 'font-size: 20px'">Farm Locations</span>
      <v-spacer />
      <v-btn
        v-if="canCreateFarms && !$isMobile"
        data-qa="farm-location-table-add-farm-button"
        color="primary"
        @click="handleAddClick"
      >
        <v-icon left>
          mdi-plus
        </v-icon> Farm Location
      </v-btn>
    </v-card-title>
    <v-row class="justify-end px-4 pt-4 pb-8">
      <v-btn
        v-if="canCreateFarms && $isMobile"
        color="primary"
        @click="handleAddClick"
      >
        <v-icon left>
          mdi-plus
        </v-icon> Farm Location
      </v-btn>
    </v-row>
    <v-data-table
      v-if="items.length && !$isMobile"
      class="pa-8"
      :items="items"
      :headers="tableHeaders"
      :loading="loading"
      :expanded.sync="expandedItems"
      disable-sort
    >
      <template #[`item.default`]="{ item }">
        <v-divider
          v-if="item.default"
          vertical
          class="default-farm primary"
        />
      </template>
      <template #[`item.name`]="{ item }">
        {{ item.name }} <span
          v-if="item.default"
          class="text--disabled"
        >(Primary Farm)</span>
      </template>
      <template #[`header.actions`]="{}">
        <div class="pr-11">
          <span>
            Actions
          </span>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <tooltip-left
          v-if="canUpdateFarms"
          :text="setPrimaryTooltipText"
        >
          <v-btn
            :disabled="isPrimary(item)"
            :color="primaryButtonColor(item)"
            :class="$isMobile || isPrimary(item) ? 'pointer-events-none': ''"
            icon
            @click="handleSetPrimaryFarmClick(item)"
          >
            <v-icon>mdi-barn</v-icon>
          </v-btn>
        </tooltip-left>
        <tooltip-left
          v-if="canUpdateFarms"
          :text="editTooltipText"
        >
          <v-btn
            icon
            @click="handleEditClick(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </tooltip-left>
        <tooltip-left
          v-if="canDeleteFarms"
          :text="deleteTooltipText"
        >
          <v-btn
            icon
            @click="handleDeleteClick(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </tooltip-left>
        <v-btn
          icon
          :disabled="item.description.length === 0"
          color="grey darken-3"
          @click="expandItem(item)"
        >
          <v-icon
            :class="{'rotate-180': isItemExpanded(item)}"
          >
            $expand
          </v-icon>
        </v-btn>
      </template>

      <template #expanded-item="{ item }">
        <td
          :colspan="tableHeaders.length"
          class="primary-grey--text"
        >
          {{ item.description }}
        </td>
      </template>
    </v-data-table>

    <!-- Mobile -->
    <v-data-table
      v-if="items.length && $isMobile"
      class="elevation-1 mb-4"
      :items="items"
      :items-per-page="5"
      :loading="loading"
      disable-sort
    >
      <template #[`item`]="{ item }">
        <div :class="item.default ? 'default-farm-mobile' :''">
          <tr class="w--100 d-flex justify-space-between align-center px-4 pt-4 pb-2">
            {{ item.name }}
            <button-collapse-menu
              v-if="$isMobile"
              :menu="true"
              :show-icons="true"
              :actions="collapseMenuActions"
            />
          </tr>
          <v-row class="w--100 d-flex  justify-space-between align-center px-4">
            <v-col class="font-weight-bold body-2 pt-4">
              Location
            </v-col>
            <v-col class="body-2">
              {{ regionName(item) }}
            </v-col>
          </v-row>
          <v-row class="w--100 d-flex justify-space-between align-center px-4 pb-4">
            <v-col class="font-weight-bold body-2">
              Description
            </v-col>
            <v-col class="body-2 primary-grey--text">
              {{ truncatedDescription(item.description) }}
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ButtonCollapseMenu from '../../generic/ButtonCollapseMenu.vue';

export default {
  components: {
    ButtonCollapseMenu,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      tableHeaders: [
        {
          value: 'default', align: 'start', cellClass: 'px-0', class: 'px-2', width: '0px',
        },
        {
          text: 'Farm Name', value: 'name', cellClass: 'pl-0', class: 'pl-0',
        },
        { text: 'Location', value: 'region.name' },
        {
          text: 'Actions', value: 'actions', align: 'end',
        },
      ],
      expandedItems: [],
      editTooltipText: 'Edit Location',
      deleteTooltipText: 'Delete Location',
      setPrimaryTooltipText: 'Set as primary Farm',
    };
  },

  computed: {
    ...mapGetters('permissions', ['checkPermission']),

    collapseMenuActions() {
      const result = [
        {
          name: 'DELETE',
          color: 'primary-grey',
          icon: 'mdi-trash-can',
          hide: !this.canDeleteFarms,
          method: this.handleDeleteClick(this.item),
        },
        {
          name: 'EDIT',
          color: 'primary-grey',
          icon: 'mdi-pencil',
          hide: !this.canUpdateFarms,
          method: this.handleEditClick(this.item),
        },
        {
          name: 'SET AS PRIMARY FARM',
          color: this.primaryButtonColor(this.item),
          icon: 'mdi-barn',
          disabled: this.isPrimary(this.item),
          method: this.handleSetPrimaryFarmClick(this.item),
        },
      ];

      return result;
    },

    canUpdateFarms() {
      return this.checkPermission('farm_locations', 'update');
    },

    canCreateFarms() {
      return this.checkPermission('farm_locations', 'create');
    },

    canDeleteFarms() {
      return this.checkPermission('farm_locations', 'delete');
    },
  },

  methods: {
    ...mapActions('farmLocations', ['openFarmLocationDialog', 'updateFarmLocation']),

    handleAddClick() {
      this.openFarmLocationDialog();
    },

    handleEditClick(item) {
      this.openFarmLocationDialog(item.id, true);
    },

    isPrimary(item) {
      return item.default;
    },

    primaryButtonColor(item) {
      if ((this.isPrimary(item) && !this.$isMobile) || (!this.isPrimary(item) && this.$isMobile)) {
        return 'primary';
      }
      return '';
    },

    handleDeleteClick(item) {
      this.openFarmLocationDialog({
        id: item.id,
        delete: true,
      });
    },

    async handleSetPrimaryFarmClick(item) {
      try {
        const newFarmData = {
          ...item,
          default: true,
        };
        await this.updateFarmLocation(newFarmData);
        this.$snackbar.custom('primary-grey', `${item.name} is now your primary farm`);
        this.$emit('primary-farm-updated');
      } catch (e) {
        this.$snackbar.error(e);
      }
    },

    isItemExpanded(item) {
      return this.expandedItems.some((expandedItem) => expandedItem.id === item.id);
    },

    expandItem(item) {
      if (this.isItemExpanded(item)) {
        this.expandedItems = this.expandedItems.filter((i) => i.id !== item.id);
      } else {
        this.expandedItems.push(item);
      }
    },

    truncatedDescription(description) {
      return description.length >= 75 ? `${description.substring(0, 75)}...` : description;
    },

    regionName(item) {
      return item.region ? item.region.name : '';
    },
  },
};
</script>
<style scoped lang='scss'>

@use "@/colors/colors";

.default-farm {
  border-width: 4px;
  vertical-align: bottom;
}
.default-farm-mobile {
  border-left: solid 4px colors.$primary;
  border-top-left-radius: 4px;
}

::v-deep.v-data-table {
  .v-data-table__expanded__row > td {
    border-bottom: none !important;
  }
  .v-data-table__expanded__content {
    box-shadow: none !important;
  }
}

::v-deep.v-data-table--mobile{
  .v-data-footer__select {
    display: none;
  }
  .v-data-footer{
    height: 58px;
  }
}

</style>
