<template>
  <g-dialog
    v-model="dialogOpen"
    width="580"
    title="Terms of Use"
    :show-back-button="false"
    :show-close-button="false"
    :action-buttons="buttons"
    fixed-buttons-for-overflow
  >
    <TermsOfUseDocument />
    <intersect-observer @intersected="enableAccept" />
  </g-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import IntersectObserver from '@/components/generic/IntersectObserver.vue';
import GDialog from '../generic/GDialog.vue';
import TermsOfUseDocument from './TermsOfUseDocument.vue';

export default {
  name: 'TermsOfUseDialog',

  components: {
    TermsOfUseDocument,
    GDialog,
    IntersectObserver,
  },

  data() {
    return {
      dialogOpen: false,
      loading: false,
      acceptable: false,
    };
  },

  computed: {
    ...mapState('user', ['shouldShowTermsOfUsePrompt']), // Adjust state mapping
    ...mapState('app', ['isMimic']),

    buttons() {
      return [
        {
          name: 'I DO NOT ACCEPT',
          side: 'right',
          type: 'default',
          onClick: this.handleCancelClick,
          loading: this.loading,
        },
        {
          name: 'I ACCEPT',
          side: 'right',
          type: 'primary',
          full: this.$isMobile ? null : true,
          disabled: !this.acceptable,
          onClick: this.handleSubmit,
          loading: this.loading,
        },
      ];
    },
  },

  mounted() {
    this.dialogOpen = this.shouldShowTermsOfUsePrompt && !this.isMimic;
  },

  methods: {
    ...mapActions('user', ['toggleShouldShowTermsOfUsePrompt']),

    handleCancelClick() {
      this.loading = true;
      window.location = '/logout/';
    },

    enableAccept() {
      this.acceptable = true;
    },

    async handleSubmit() {
      this.loading = true;
      try {
        await this.toggleShouldShowTermsOfUsePrompt(); // Adjust action name
        this.dialogOpen = false;
      } catch (e) {
        this.$snackbar.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
