<template>
  <TakeActionCard
    :header="title"
    :message="body"
    icon="$vuetify.icons.emptyStateUpgradeAccount"
    :flat="false"
    :class="!this.$isMobile ?
      'mt-6 sales-recommendation-cta-desktop mx-auto' :
      'mt-6 my-auto'"
    :show-button="showUpgradeLink"
  >
    <v-btn
      v-if="showUpgradeLink"
      color="primary"
      href="/commerce/tiers/"
    >
      {{ buttonText }}
    </v-btn>
  </TakeActionCard>
</template>
<script>
import { mapGetters } from 'vuex';
import TakeActionCard from '@/components/farm_profile/shared/TakeActionCard.vue';

export default {
  name: 'SalesRecommendationsResourceHubCTA',

  components: {
    TakeActionCard,
  },

  computed: {
    ...mapGetters('permissions', ['isOwnerOrBilling']),
    ...mapGetters('chargebee', ['isCommercial']),
    showUpgradeLink() {
      return this.isOwnerOrBilling('update');
    },

    title() {
      if (this.titleOne !== '') {
        return `${this.titleOne} \n ${this.titleTwo}`;
      }
      return this.titleTwo;
    },

    titleOne() {
      if (!this.showUpgradeLink) {
        return 'Woops!';
      }
      if (!this.$isMobile) {
        return 'Oops!';
      }
      return '';
    },

    titleTwo() {
      if (this.showUpgradeLink) {
        return 'Your current plan doesn’t include this feature.';
      }
      return 'This feature isn\'t included in your current plan.';
    },
    body() {
      if (this.showUpgradeLink) {
        if (this.isAmerican) {
          return 'Upgrade your plan to get sales recommendations right on your GrainFox Dashboard.';
        }
        return 'Upgrade to Smart Plan to get sales recommendations right on your GrainFox Dashboard.';
      }
      return 'Talk to your account administrator about getting access.';
    },
    buttonText() {
      if (this.isAmerican) {
        return 'UPGRADE PLAN';
      }
      if (this.isCommercial) {
        return 'UPGRADE TO COMMERCIAL PLAN';
      }
      return 'UPGRADE TO SMART PLAN';
    },
  },
};
</script>
<style scoped lang='scss'>
.white-space-break {
  white-space: break-spaces;
}

.sales-recommendation-cta-desktop {
  max-width: 546px;
}
</style>
