<!-------------------------------->
<!-- LocationPicker.vue

  To use this component, make sure that
  the template in which this is placed
  has the necessary script to use Leaflet,
  otherwise the Map won't render since L
  won't be defined.
 --->
<!-------------------------------->
<template>
  <div>
    <v-btn
      v-if="alternate"
      class=""
      text
      small
      @click="openLocationMap"
    >
      Select on map
    </v-btn>
    <v-btn
      v-else-if="transparent"
      color="primary"
      text
      large
      @click="openLocationMap"
    >
      <v-icon>
        mdi-plus
      </v-icon>
      Select on map
    </v-btn>
    <v-btn
      v-else
      color="primary"
      @click="openLocationMap"
    >
      Select on map
    </v-btn>
    <v-dialog
      v-model="mapDialog"
      eager
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        tile
        class="d-flex flex-column"
      >
        <v-toolbar
          class="flex-grow-0 w--100 z--1"
        >
          <v-btn
            v-if="isMobile"
            icon
            color="primary"
            class="mr-2"
            @click="closeLocationMap"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            @click="closeLocationMap"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Select a Location</v-toolbar-title>
          <v-fade-transition>
            <v-btn
              v-show="markerExists"
              data-qa="location-picker-save-button"
              color="primary"
              rounded
              right
              fixed
              @click="updateMapLocation"
            >
              Save
            </v-btn>
          </v-fade-transition>
        </v-toolbar>
        <v-img
          width="100%"
          height="100%"
        >
          <div
            ref="map"
            style="height: 100%"
          >
            <v-container
              class="leaflet-top leaflet-left"
              style="pointer-events: auto;"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="7"
                >
                  <v-autocomplete
                    ref="search"
                    v-model="select"
                    data-qa="location-picker-search-text-field"
                    :loading="loading"
                    :items="items"
                    item-text="text"
                    return-object
                    :search-input.sync="search"
                    hide-no-data
                    hide-details
                    filled
                    clearable
                    label="Search Location"
                    prepend-inner-icon="mdi-magnify"
                    background-color="#F5F5F5"
                    dense
                    solo
                    append-icon=""
                  />
                  <v-skeleton-loader
                    v-if="loading"
                    type="list-item, list-item, list-item"
                    class="white px-4"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container
              class="leaflet-bottom leaflet-left"
              style="pointer-events: auto;"
            >
              <div class="pb-8">
                <v-btn
                  ref="geoCenter"
                  data-qa="location-picker-geocenter-button"
                  class="rounded"
                  fab
                  elevation="1"
                  small
                  @click="pinLocation"
                >
                  <v-icon>
                    mdi-crosshairs-gps
                  </v-icon>
                </v-btn>
              </div>
              <div
                class="pb-8"
              >
                <v-btn
                  ref="zoomIn"
                  class="d-block rounded rounded-b-0 mb-1"
                  fab
                  elevation="1"
                  small
                  @click.stop="zoomIn"
                  @dblclick.stop="zoomIn"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  ref="zoomOut"
                  class="d-block rounded rounded-t-0"
                  fab
                  elevation="1"
                  small
                  @click.stop="zoomOut"
                  @dblclick.stop="zoomOut"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </div>
              <v-card
                class="d-inline-block rounded"
                elevation="1"
                @click="switchMaps"
              >
                <v-switch
                  v-model="toggleSatellite"
                  readonly
                  label="Satellite View"
                  hide-details
                  class="px-3 py-2 mt-0"
                />
              </v-card>
            </v-container>
          </div>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
/* eslint-disable new-cap */
/* eslint-disable no-undef */
export default {
  props: {
    farmLocation: {
      type: Object,
      required: false,
      default: () => ({
        latitude: 49.887060,
        longitude: -97.131287,
      }),
    },
    locations: {
      type: Array,
      required: false,
      default: () => ([{
        latitude: 49.887060,
        longitude: -97.131287,
      }]),
    },
    alternate: {
      type: Boolean,
      required: false,
      default: false,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      mapDialog: false,
      map: null,
      locationMarker: null,
      isFormValid: false,
      toggleSatellite: true,
      satelliteView: null,
      streetView: null,
      loading: false,
      items: [],
      search: null,
      session: null,
      select: null,

      nameRule(value) {
        if (!value?.trim()) return 'Name should not be blank';
        return true;
      },

      generateNumberRule(name) {
        return (value) => {
          if (Number.isNaN(parseFloat(value))) return `${name} should be a number`;
          return true;
        };
      },

      generateRangeRule(name, min, max) {
        return (value) => {
          if (value < min || value > max) return `${name} should be between ${min} and ${max}`;
          return true;
        };
      },
    };
  },

  computed: {
    markerExists() {
      return this.locationMarker != null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },

  watch: {
    search(val) {
      if (!val) {
        this.items = [];
      } else {
        const trimmedVal = val.trim();
        if (trimmedVal && (!this.select || trimmedVal !== this.select.text)) {
          this.debounceQueryAutocomplete(trimmedVal);
        }
        this.search = trimmedVal;
      }
    },
    select(item, oldItem) {
      if (item) {
        this.handleSelected(oldItem);
      }
    },
  },

  methods: {
    // eslint-disable-next-line func-names
    debounceQueryAutocomplete: _.debounce(function (val) {
      this.queryAutocomplete(val);
    }, 500),
    generateMapMarker(primary = true) {
      const colour = primary ? this.$vuetify.theme.themes.light.primary : '#ffffff';
      const outline = !primary ? this.$vuetify.theme.themes.light.primary : '#ffffff';
      const mapIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path fill="${colour}" stroke="${outline}" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" /></svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(mapIcon)}`;
      return L.icon({
        iconUrl,
        iconSize: [48, 48],
        iconAnchor: [24, 48],
        popupAnchor: [0, -48],
      });
    },
    createSelectionMarker(latlng) {
      if (this.locationMarker == null) {
        const icon = this.generateMapMarker();
        const markerOptions = {
          draggable: true,
          icon,
        };
        this.locationMarker = new L.marker(latlng, markerOptions);
      }
    },
    openLocationMap() {
      this.mapDialog = true;
      const currentLocation = L.latLng(this.farmLocation.latitude, this.farmLocation.longitude);

      if (this.map == null) {
        L.DomEvent.disableClickPropagation(this.$refs.search.$el);
        this.toggleSatellite = true;
        // default zoomControl is false to use custom zoom button
        this.map = L.map(this.$refs.map, { zoomControl: false });
        const mapboxAccessToken = 'pk.eyJ1IjoiZmFybWxpbmsiLCJhIjoiY2tiNnZwajl6MDJsajJwcDY3cXc1dWo2cCJ9.l_BwWIQCiYRLYVMAN_a-cw';
        this.streetView = L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`, {
          id: 'mapbox/light-v9',
          attribution: '',
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 1,
          center: currentLocation,
        });
        this.satelliteView = L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`, {
          id: 'mapbox/satellite-streets-v9',
          attribution: '',
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 1,
          center: currentLocation,
        });
        if (this.toggleSatellite) {
          this.satelliteView.addTo(this.map);
        } else {
          this.streetView.addTo(this.map);
        }
        this.map.on('click', this.handleMapClick);
      }
      const bounds = [];
      // Set markers for other locations
      this.locations.forEach((location) => {
        const loc = location;
        const icon = this.generateMapMarker(false);
        const markerOptions = {
          draggable: false,
          icon,
        };
        const latLng = new L.LatLng(loc.latitude, loc.longitude);
        const locationMarker = new L.marker(latLng, markerOptions);
        locationMarker.bindPopup(`<b>${loc.name}</b><br>${loc.description}`);
        locationMarker.addTo(this.map);
        bounds.push(latLng);
      });
      if ('id' in this.farmLocation && this.farmLocation.id) {
        // Existing farmLocation
        this.map.setView(currentLocation, 15);
        if (this.locationMarker == null) {
          this.createSelectionMarker(currentLocation);
          this.locationMarker.addTo(this.map);
        }
      } else if (bounds.length !== 0) {
        // set the bounds to the existing locations
        let mapBounds = new L.LatLngBounds(bounds);

        setTimeout(() => {
          this.map.fitBounds(mapBounds);
          if (bounds.length === 1 && !('id' in this.farmLocation)) {
            mapBounds = this.map.getBounds().pad(10.5);
            this.map.fitBounds(mapBounds);
          }
        }, 100);
      } else {
        this.setDefaultLocation();
      }
      setTimeout(() => { this.map.invalidateSize(); }, 100);
    },
    setDefaultLocation() {
      let loc = [50, -97]; // this will use get_default_farm_location endpoint
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            loc = [pos.coords.latitude, pos.coords.longitude];
            this.map.setView(loc, 9);
          },
          () => {
            this.map.setView(loc, 5);
          },
        );
      }
    },
    switchMaps(e) {
      // prevent the map click event from firing
      e.stopPropagation();
      this.toggleSatellite = !this.toggleSatellite;
      if (this.toggleSatellite) {
        this.streetView.remove();
        this.satelliteView.addTo(this.map);
      } else {
        this.satelliteView.remove();
        this.streetView.addTo(this.map);
      }
    },
    pinLocation(e) {
      e.stopPropagation();
      this.$refs.geoCenter.$el.blur(); // unfocus fab after clicking
      this.createSelectionMarker(e.latlng);
      let loc = { lat: 50, lng: -97 }; // this will use get_default_farm_location endpoint
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            if (this.map === null) return;
            loc = { lat: pos.coords.latitude, lng: pos.coords.longitude };
            this.map.setView([pos.coords.latitude, pos.coords.longitude], 11);
            this.locationMarker.setLatLng(loc);
            this.locationMarker.addTo(this.map);
          },
          () => {
            if (this.map === null) return;
            this.map.setView([loc.lat, loc.lng], 5);
            this.locationMarker.setLatLng(loc);
            this.locationMarker.addTo(this.map);
          },
        );
      }
    },
    handleMapClick(e) {
      this.addSelectionMarker(e.latlng);
    },
    addSelectionMarker(latlng) {
      this.createSelectionMarker(latlng);
      this.locationMarker.setLatLng(latlng);
      this.locationMarker.addTo(this.map);
    },
    updateMapLocation() {
      // update on the form
      const location = this.locationMarker.getLatLng();
      this.$emit('on-change', [{
        latitude: location.lat,
        longitude: location.lng,
      }]);
      this.closeLocationMap();
    },
    closeLocationMap() {
      this.mapDialog = false;
      if (this.locationMarker != null) {
        this.map.removeLayer(this.locationMarker);
        this.locationMarker = null;
      }
      this.resetSearchInfo();

      this.map.off();
      this.map.remove();
      this.map = null;
    },
    async handleSelected(oldItem) {
      if (oldItem && this.select.place_id === oldItem.place_id) {
        this.map.setView(this.locationMarker.getLatLng(), 11);
        return;
      }

      try {
        const response = await API.getPlaceDetails({
          place_id: this.select.place_id,
          session: this.session,
        });

        this.addSelectionMarker({
          lat: response.latitude,
          lng: response.longitude,
        });
        this.map.setView(this.locationMarker.getLatLng(), 11);

        this.session = null;
        this.items = [];
      } catch (e) {
        this.$snackbar.error('Failed to select location');
      }
    },
    resetSearchInfo() {
      this.search = null;
      this.select = null;
      this.items = [];
    },
    async queryAutocomplete(val) {
      this.loading = true;
      try {
        const response = await API.getPlaceAutocomplete({
          input_text: val,
          session: this.session,
        });

        this.items = response.results;
        this.session = response.session;
      } catch (e) {
        this.$snackbar.error('Failed to search the list');
      } finally {
        this.loading = false;
      }
    },
    zoomIn() {
      this.$refs.zoomIn.$el.blur();
      this.map.zoomIn();
    },
    zoomOut() {
      this.$refs.zoomOut.$el.blur();
      this.map.zoomOut();
    },
  },
};
</script>
