<template>
  <v-text-field
    ref="inputField"
    v-model="formattedNumber"
    v-bind="$attrs"
    :label="label"
    :rules="wrappedRules"
    :required="required"
    type="tel"
  >
    <slot
      v-for="(_, name) in $slots"
      :slot="name"
      :name="name"
    />
  </v-text-field>
</template>
<script>
import { AsYouType, isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { getDigitsFromString } from '@/helpers/formatting';

export default {
  name: 'PhoneNumberInput',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Phone Number',
    },
    required: {
      type: Boolean,
      default: false,
    },
    validCountries: {
      type: Array,
      default: () => ['CA', 'US'],
    },
    useStandardRule: {
      type: Boolean,
      default: true,
    },
    useStrictRule: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formattedNumber: {
      get() {
        if (!this.value) {
          this.$emit('formatted', '');
          return '';
        }
        const ayt = new AsYouType(this.defaultCountry);
        const out = ayt.input(this.value);
        this.$emit('formatted', out);
        return out;
      },
      set(newValue) {
        if (!newValue) {
          this.$emit('input', newValue);
        } else {
          const outValue = getDigitsFromString(newValue);
          this.$emit('input', outValue);
        }
      },
    },
    defaultCountry() {
      return this.validCountries[0] ?? 'CA';
    },
    rule() {
      return (v) => {
        if (!v) {
          return !this.required || `${this.label} is required`;
        }
        let nationalNumber = v;
        if (v.startsWith('1')) { // No canadian/us number has an area code starting with 1
          nationalNumber = v.slice(1);
        }
        if (this.validCountries.length === 0) {
          const p = isPossiblePhoneNumber(nationalNumber, this.defaultCountry);
          return p || `"${v}" is not a valid phone number`;
        }
        const p = this.validCountries
          .map((country) => isPossiblePhoneNumber(nationalNumber, country))
          .reduce((acc, valid) => acc || valid, false);
        return p || `"${v}" is not a valid phone number`;
      };
    },
    strict() {
      return (v) => {
        if (!v) {
          return !this.required || `${this.label} is required`;
        }
        if (this.validCountries.length === 0) {
          const p = isValidPhoneNumber(v, this.defaultCountry);
          return p || `"${v}" is not a valid phone number`;
        }
        const p = this.validCountries
          .map((country) => isValidPhoneNumber(v, country))
          .reduce((acc, valid) => acc || valid, false);
        return p || `"${v}" is not a valid phone number`;
      };
    },
    wrappedRules() {
      const out = [...this.rules];
      if (this.useStandardRule) {
        out.push(this.rule);
      }
      if (this.useStrictRule) {
        out.push(this.strict);
      }
      return out;
    },
  },
  methods: {
    validate() {
      return this.$refs.inputField.valid;
    },
  },
};
</script>
