import { datadogRum } from '@datadog/browser-rum';
import { initialize } from 'launchdarkly-js-client-sdk';

function initializeLaunchDarkly(store, context) {
  const sdkKey = store.state.app.launchDarklyKey;
  const client = initialize(sdkKey, context, {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  });
  client.on('ready', () => {
    store.commit('launchDarkly/setFlags', client.allFlags());
  });

  client.on('change', (changes) => {
    const flags = Object.keys(changes).reduce((acc, current) => {
      acc[current] = changes[current].current;
      return acc;
    }, {});
    store.commit('launchDarkly/setFlags', { ...store.state.launchDarkly.$unobserved, ...flags });
  });
}

export default {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store');
    }

    /**
     * App state is loaded prior to the user state,
     * so we can trust launchDarklyKey will be set.
     */
    const unsubscribe = store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'user/loadUserState') {
          let context = {};
          if (state.user.hash && state.user.email) {
            context = {
              kind: 'user',
              key: store.state.user.hash,
              email: store.state.user.email,
            };
          } else {
            context = {
              kind: 'user',
              email: 'anonymous@anonymous.ca',
              anonymous: true,
            };
          }
          initializeLaunchDarkly(store, context);
          unsubscribe();
        }
      },
    });
  },
};
