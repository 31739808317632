import {
  MONTHLY,
  ANNUAL,
  FRIENDLY_ITEM_NAMES, SEMIANNUAL, BASIC_PLAN,
} from './constants';

/**
 * Constructs a Chargebee Plan/Addon ID
 * @param {string} name The name of a plan or addon
 * @param {string} currency Three-letter currency code
 * @param {boolean} isMonthly Monthly or annual frequency
 * @returns {string} Chargebee ID e.g. 'Smart-Plan-CAD-Monthly'
 */
export function getChargebeeId(name, currency, isMonthly) {
  const frequency = isMonthly ? MONTHLY : ANNUAL;
  return [name, currency, frequency].join('-');
}

/**
 * Deconstructs a Chargebee Plan/Addon ID into its constituent parts
 * @param {string} id The ID to trip
 * @returns {{id: string, currency: string, frequency: string} | null} Chargebee ID parts
 */
export function getIdParts(id) {
  const match = /^([A-Za-z0-9\-_]+)-(CAD|USD)-([A-Za-z0-9\-_]+)$/i.exec(id);
  if (!match || !match[1] || !match[2] || !match[3]) {
    return null;
  }
  const groups = {
    id: match[1],
    currency: match[2],
    frequency: match[3],
  };
  return groups;
}

/**
 * Deconstructs a Chargebee Plan/Addon ID into its constituent parts
 * @param {string} id The ID to trip
 * @returns {{id: string, currency: string, isMonthly: boolean} | null} Chargebee ID parts
 */
export function stripExtrasFromId(id) {
  const parts = getIdParts(id);
  if (!parts) {
    return null;
  }
  const { id: planId, currency, frequency } = parts;
  return {
    id: planId,
    currency,
    isMonthly: frequency === MONTHLY,
  };
}

/**
 * Converts a PlanID to a humanized version
 * @param {string} planId The ID to humanize (with or without extras)
 * eg: Smart-Plan/Smart-Plan-CAD-Monthly
 * @returns {string} Humanized plan name
 */
export function humanizePlan(planId) {
  const parts = stripExtrasFromId(planId);
  if (parts) {
    return FRIENDLY_ITEM_NAMES[parts.id];
  }
  return planId.replace('-', ' ');
}

/**
 * Checks if user is on Basic Plan
 * @param {object} user The user to check
 * @returns {boolean} this user is on Basic Plan or not
 */
export function isBasicPlan(user) {
  if (!user.chargebee_info?.plan_id) {
    return false;
  }
  if (user.chargebee_info.plan_id === BASIC_PLAN) {
    return true;
  }
  const idParts = getIdParts(user.chargebee_info.plan_id);
  return idParts && idParts.id === BASIC_PLAN;
}
