<template>
  <v-card
    v-if="showWidget"
    class="mb-6 pb-3"
  >
    <v-card-title
      class="justify-space-between"
    >
      <span>Ag News</span>
      <v-btn
        text
        tag="a"
        color="primary"
        href="/news/"
        data-qa="news-widget-dtn-all-news-button"
      >
        All News
      </v-btn>
    </v-card-title>
    <NewsWidgetData />
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import NewsWidgetData from './NewsWidgetData.vue';

export default {
  name: 'NewsWidgetDtn',

  components: {
    NewsWidgetData,
  },
  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('subscription', ['isAmerican']),

    showWidget() {
      return this.$isDesktop && !this.isAmerican;
    },
  },
};
</script>
