<template>
  <v-card
    class="mx-6 elevation-0 pb-6"
  >
    <v-card-text class="d-flex flex-center">
      <v-divider
        class="mr-2 custom-divider"
        :class="{'show-recommendation' : showRecommendation}"
        vertical
      />
      <span
        v-if="showRecommendation || !isSmartAdvisorSelected"
        class="text-h5"
      >
        Move to
        <span class="text-color">
          {{ formattedRecommendation }}
        </span>
        sold on {{ selectedYearLongName }}
        <span class="text-color">
          {{ commodityDisplayName }}.
        </span>
      </span>
      <v-row v-else>
        <v-col class="text-h5">
          <span class="primary--text">
            Hold
          </span>
          {{ commodityDisplayName }}.
        </v-col>
      </v-row>
    </v-card-text>
    <div
      v-if="!isSmartAdvisorSelected"
      class="d-flex justify-center"
    >
      <v-btn
        class="primary text-button-bold text-wrap"
        :href="priceMapLink"
      >
        <v-icon small>
          mdi-currency-usd
        </v-icon>
        FIND PRICES NEAR YOU
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { roundUpTo, formatUnitTemplateWithDelimiters } from '@/helpers/formatting';

export default {
  name: 'NewSalesRecommendations',

  props: {
    selectedYear: {
      type: Object,
      required: true,
    },

    selectedCommodity: {
      type: Object,
      required: true,
    },

    isSmartAdvisorSelected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('smartAdvisor', ['productionsSorted', 'soldPercentageByAADCommodity', 'bestSaleData']),
    ...mapState('analysisDashboard', ['advancedAnalysisCommodities', 'priceMapLinks', 'salesRecommendations']),
    ...mapGetters('shared', ['unitByAbbreviation', 'cropYearById', 'currentCropYear', 'nextCropYear']),

    selectedYearLongName() {
      return this.selectedYear?.long_name;
    },

    commodityDisplayName() {
      return this.selectedCommodity?.display_name;
    },

    bestSaleDataForSelectedCommodity() {
      return this.bestSaleData[this.selectedCommodity?.name];
    },

    findProductionBySelectedCommodity() {
      return this.productionsSorted.find((p) => p.commodity === this.selectedCommodity?.id);
    },

    quantityToSell() {
      const salePercent = this.bestSaleDataForSelectedCommodity?.salePercentage || 0;
      const totalProduction = this.findProductionBySelectedCommodity?.production || 0;
      return roundUpTo((salePercent / 100.0) * totalProduction, 1);
    },

    showRecommendation() {
      return Number(this.quantityToSell)
       && ((this.soldPercentageByAADCommodity[this.selectedCommodity?.name] || 0) < 100);
    },

    percentUnit() {
      return this.unitByAbbreviation('%');
    },

    recommendedPercentage() {
      const salePercent = this.bestSaleDataForSelectedCommodity?.salePercentage || 0;
      const sold = this.soldPercentageByAADCommodity[this.selectedCommodity?.name] || 0;
      const total = salePercent + sold;
      const value = roundUpTo(total, 1);

      if (value > 99) {
        return 100;
      }
      return roundUpTo(value, 1);
    },

    formattedRecommendation() {
      const numberOfDecimals = this.recommendedPercentage === 100 ? 0 : 1;
      if (this.isSmartAdvisorSelected) {
        return formatUnitTemplateWithDelimiters(this.percentUnit, this.recommendedPercentage, numberOfDecimals);
      }
      const recommendation = this.salesRecommendations.find(
        (rec) => rec.commodity_id === this.selectedCommodity?.id
      && rec.crop_year_id === this.selectedYear?.id,
      )?.sold_percent || null;
      return formatUnitTemplateWithDelimiters(this.percentUnit, recommendation, numberOfDecimals);
    },

    priceMapLink() {
      const url = new URL(
        '/bids/map',
        window.location.origin,
      );
      url.searchParams.set('crop', this.selectedCommodity.display_name);
      url.searchParams.set('commodity', this.selectedCommodity.name);
      const reference = this.priceMapLinks?.[this.selectedCommodity.id];
      if (reference) {
        url.searchParams.set('crop', reference.crop);
        url.searchParams.set('commodity', reference.commodity);
      }
      url.searchParams.set('sr', true);
      return url.toString();
    },
  },

  methods: {
    roundUpTo,
    formatUnitTemplateWithDelimiters,
  },
};

</script>

<style lang="scss" scoped>
@use "@/colors/colors";

.custom-divider {
  border-color: colors.$primary;
  border-width: 2px;
  height: 30px;
}

@media (max-width: 767px) {
  .show-recommendation {
    height: 60px;
  }
}

.text-color {
  color: colors.$positive;
}
</style>
